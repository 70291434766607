export const GameList = [ 
  {date: "2025-03-31",brand: "Yandex",orientation: 1,title: "Left or Right Fashion Dress – The Ultimate Dress up Game",
    name: ["Left or Right Fashion Dress", "from-nerd-to-fab-prom-edition"],
    thumb: "https://static.playhop.com/images/1f123_11385414_5fb28/f44389fa7/2a0000018ca2bba7_52c3df8/ab20e3c54e26eaf78efe_601a55/pjpg350x209",
    thumb_big: "https://static.playhop.com/images/1f123_11385414_5fb28/f44389fa7/2a0000018ca2bba7_52c3df8/ab20e3c54e26eaf78efe_601a55/pjpg350x209",
    path: ["/game/from-nerd-to-fab-prom-edition", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/281050?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:"Play Left or Right Fashion Dress, the ultimate Dress up Game! Mix and match outfits, showcase your style, and enjoy endless fashion fun in this Girl Game.",
    video:"https://video-preview.s3.yandex.net/ugc/dc2af036c81b7d77eee09179654d5cbc_vmaf-preview-10s-360.mp4",
    l_dis:
      `
    <p>Step into the world of fashion with <strong>Left or Right Fashion Dress</strong>, the ultimate <strong>Dress up Game</strong> for style lovers! Mix and match outfits, experiment with different looks, and create the perfect ensemble. If you love <strong>Girl Games</strong>, this stylish adventure is just for you!</p>

    <h2 class="H22">✨ How to Play Left or Right Fashion Dress</h2>
    <p>Fashion is all about choices! In this exciting <strong>Dress up Game</strong>, you can pick stylish garments from the <strong>left and right sides</strong> to create the ultimate look. Once you’ve crafted your perfect outfit, gain approvals and unlock even more fashion options!</p>

    <h2 class="H22">💃 Choose Your Perfect Look</h2>
    <p>With a vast collection of dresses, tops, skirts, shoes, and accessories, you can design an outfit that suits your style. Whether you go for a trendy, casual, or glamorous look, the choice is yours!</p>

    <h2 class="H22">🎭 Hit the Performance Floor</h2>
    <p>After selecting your outfit, place your dancer on the performance floor and let her shine! Show off your styling skills and enjoy the excitement of fashion creativity.</p>

    <h2 class="H22">🌟 Why Play Left or Right Fashion Dress?</h2>
    <ul>
        <li>👗 A fun and interactive <strong>Dress up Game</strong> for all ages.</li>
        <li>💖 Explore a massive selection of fashionable outfits.</li>
        <li>🎭 Create unique looks and place your dancer on stage.</li>
        <li>✨ Unlock fresh attire choices with each successful approval.</li>
        <li>🎀 Perfect for <strong>Girl Games</strong> enthusiasts who love fashion challenges.</li>
    </ul>
      `,
    tag: ["Dress up","Make Up"],
    rating:[4.8,15102,1014],
    
  },
  {date: "2025-03-31",brand: "Yandex",orientation: 0,title: "From Nerd To Fab: Prom Edition - The Ultimate Makeover Experience",
    name: ["From Nerd To Fab: Prom Edition", "from-nerd-to-fab-prom-edition"],
    thumb: "https://static.playhop.com/images/6b7f4_2977039_f1da1/f9e9c0fee/2a0000017a84fcba_e68e1f7/fee0fb0dab0a2f06eba5_048dfd/pjpg350x209",
    thumb_big: "https://static.playhop.com/images/6b7f4_2977039_f1da1/f9e9c0fee/2a0000017a84fcba_e68e1f7/fee0fb0dab0a2f06eba5_048dfd/pjpg350x209",
    path: ["/game/from-nerd-to-fab-prom-edition", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/98897?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:"Play Nerd To Fab Game and help the princess transform for prom night! A fun and creative makeover experience for Girl Games fans.",
    l_dis:
      ` 
       <p>Get ready for a magical transformation in <strong>Nerd To Fab Game</strong>! Help our princess turn heads at prom night with a full makeover. From skincare to stunning outfits, this game lets you unleash your creativity and styling skills. If you love <strong>Girl Games</strong>, this is your chance to shine!</p>

    <h2 class="H22">🎀 Give the Princess a Gorgeous Glow-Up</h2>
    <p>The prom is the most important night of her life, and she needs your help to look stunning. Start with a relaxing facial treatment to clear her skin, then choose the perfect <strong>makeup, hairstyle, and dress</strong> to complete her transformation.</p>

    <h2 class="H22">💅 Makeup & Skincare – Prepare for the Big Night</h2>
    <p>Before the glam, the princess needs a fresh and flawless face! Apply <strong>cleansers, masks, and moisturizers</strong> to give her a radiant glow. Then, use the best makeup to highlight her beauty, including lipstick, eyeshadow, blush, and mascara.</p>

    <h2 class="H22">👗 Dress Up in Stunning Prom Fashion</h2>
    <p>It’s time to pick the perfect dress! Browse through a variety of <strong>glamorous gowns, stylish accessories, and dazzling shoes</strong> to create the ultimate prom queen look. Every choice you make will shape her final appearance!</p>

    <h2 class="H22">💃 Transform from Nerd To Fab!</h2>
    <p>Watch as the princess goes from a shy nerd to the most stylish girl at prom! <strong>Nerd To Fab Game</strong> gives you full control over her transformation, making it a fun and exciting challenge for all fashion lovers.</p>

    <h2 class="H22">🎉 Why Play Nerd To Fab Game?</h2>
    <ul>
        <li>✨ Fun and creative <strong>Girl Game</strong> for all ages.</li>
        <li>💖 Experience a complete <strong>makeover journey</strong> from start to finish.</li>
        <li>👗 Choose from a variety of <strong>dresses, accessories, and hairstyles</strong>.</li>
        <li>💅 Apply <strong>realistic makeup and skincare</strong> techniques.</li>
        <li>🎀 Enjoy a stunning transformation in this interactive beauty game.</li>
    </ul>
      `,
    tag: ["Dress up","Make Up"],
    rating:[4.8,15102,1014],
    
  },
  {date: "2025-03-21",brand: "Yandex",orientation: 0,title: "Club Guard - Play the Best Simulation Game Unblocked",
    name: ["Club Guard", "club-guard"],
    thumb: "https://static.playhop.com/images/a5706_12797757_29232/7ea681e64/2a00000195a02717_bc406f0/57f18dc5dbfae445f0b5_e8bc7b/pjpg350x209",
    thumb_big: "https://static.playhop.com/images/a5706_12797757_29232/7ea681e64/2a00000195a02717_bc406f0/57f18dc5dbfae445f0b5_e8bc7b/pjpg350x209",
    path: ["/game/club-guard", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/420444?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://video-preview.s3.yandex.net/ugc/74142a10352467a030d4e56680f8138a/3f86bb92-6289-42a2-84ec-5dd6e92359a2/previews/vmaf-preview-10s-360.mp4",
    s_dis:"Play Club Guard, a thrilling Simulation Game where you control nightclub security. Enjoy interactive gameplay in this Unblocked Game anytime, anywhere!",
    l_dis:
      ` 
      <p>Step into the role of a nightclub bouncer in <strong>Club Guard</strong>, a thrilling <strong>Simulation Game</strong> where every decision matters. Examine guests, detect banned items, and decide who gets access to the hottest club in town. Packed with humor, unique scenarios, and interactive mechanics, <strong>Club Guard</strong> is an engaging experience that keeps you coming back for more.</p>

    <h2 class="H22">🎮 Interactive Gameplay – Be the Best Bouncer</h2>
    <p>As a nightclub security officer, your job is to <strong>inspect guests</strong>, search for prohibited items, and maintain order. Use your keen eye to <strong>spot weapons, alcohol, and other restricted objects</strong> before allowing entry. Your choices shape the club’s reputation, so be ready for unexpected situations!</p>

    <h2 class="H22">😂 Unusual Humor and Entertaining Characters</h2>
    <p>Each guest in <strong>Club Guard</strong> has their own personality, quirks, and hilarious interactions. From celebrities trying to sneak in banned items to wild partygoers causing chaos, the game delivers <strong>non-stop fun and surprises</strong>.</p>

    <h2 class="H22">🤔 Freedom of Choice – How Will You Handle It?</h2>
    <p>In this <strong>Simulation Game</strong>, you can choose to be <strong>strict, fair, or even a little corrupt</strong>. Will you accept bribes, enforce the rules, or let VIPs through without a check? Every action has consequences, affecting your reputation and career progression.</p>

    <h2 class="H22">🌟 Diverse Scenarios – No Two Nights Are the Same</h2>
    <p>Each night at the club presents <strong>new challenges</strong>. Deal with rowdy guests, fake IDs, and even celebrity scandals. Stay sharp, as unexpected events will keep you on your toes.</p>

    <h2 class="H22">📱 User-Friendly Design – Play with Ease</h2>
    <p><strong>Club Guard</strong> features <strong>simple yet engaging graphics</strong> and <strong>intuitive controls</strong>, making it accessible for all players. Whether you’re a casual gamer or a simulation enthusiast, you’ll enjoy the easy-to-learn mechanics and immersive gameplay.</p>

    <h2 class="H22">🚀 Unblocked Games – Play Anytime, Anywhere</h2>
    <p>Looking for a <strong>Simulation Game</strong> that’s also an <strong>Unblocked Game</strong>? <strong>Club Guard</strong> is fully accessible from school, work, or home. No downloads needed—just jump into your browser and start playing!</p>

    <h2 class="H22">🔥 Why Play Club Guard?</h2>
    <ul>
        <li>🎭 Unique and engaging nightclub security experience.</li>
        <li>🔍 Interactive guest inspections with hilarious interactions.</li>
        <li>⚖️ Freedom to choose your approach—strict, fair, or corrupt.</li>
        <li>🎉 New challenges every night with exciting scenarios.</li>
        <li>🆓 Fully <strong>Unblocked Game</strong> for easy access anywhere.</li>
    </ul>
      `,
    tag: ["Skill","Action"],
    rating:[4.8,15102,1014],
    
  },
  {
    date: "2024-03-05",
    brand: "Yandex",
    orientation: 1,
    title: "Art Salon – Make-up and Nails - playcutegames.com",
    name: ["Art Salon – Make-up and Nails", "art-salon-make-up-and-nails"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/art-salon-make-up-and-nails/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/art-salon-make-up-and-nails/250x142.png",
    path: ["/game/art-salon-make-up-and-nails", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/285558?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://youtu.be/MXTXN1pCwEo",
    s_dis:
    "Art Salon – Make-up and Nails Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Welcome to Art Salon – Make-up and Nails! Oh, no, girl, how did you even get here, you can’t just walk around with a face like this? Let’s help this poor thing out – with a skincare routine one could only dream of! Sparkly and soothing mask, deep clean for those pesky blackheads… Treat yourself to a relaxing ASMR experience with a creative exercise on the side!",
      tag: ["3D","Nail","ASMR","Manicure","Girl games","Avatar"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 0,
    title: "Drive Mad 2 - playcutegames.com",
    name: ["Drive Mad 2", "drive-mad-2"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/drive-mad-2/250x142.png",
    path: ["/game/drive-mad-2", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/254924?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/drive-mad-2.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Drive Mad 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `
    Drive Mad 2 is an exciting and challenging car game that tests the player's driving skills and agility. The game features a series of levels, each with its own unique track filled with obstacles and stunts. The objective of the game is to drive your car to the finish line while avoiding obstacles and performing stunts.
    <br><br>
    Players must use their driving skills to navigate the track, avoid obstacles, and maintain control of their car. The game also includes exciting stunts such as jumps, loops, and flips, which add an extra layer of challenge and excitement to the game.
    <br><br>
    As the player progresses through the levels, the difficulty increases, and the obstacles become more challenging to avoid. Players must also balance their speed and maneuverability to avoid crashing or flipping their car.
    <br><br>
    Overall, Drive Mad is a fun and challenging game that will test your driving skills and reflexes.
    `,
    tag: ["Arcade","Boys","Car",],
    hint: `
    
    <ul>
     <li>You have to balance your speed so that your car does not overturn.</li>
     <li>Touch the screen to play and control the car.</li>
     <li>Complete the current level and then move to the next one.</li>
     </ul>
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2024-10-19",
    brand: "Yandex",
    orientation: 0,
    title: "Drive Mad – Adrenaline-Packed Racing Game Online!",
    name: ["Drive Mad", "drive-mad"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/drive-mad/250x142.png",
    path: ["/game/drive-mad", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/350779?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/drive-mad.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Welcome to Drive Mad! Race powerful cars, dodge obstacles in Drive Mad. Play free online and test your skills on dynamic, high-speed tracks. Can you win it all?",
    l_dis:
    `
    Drive Mad is an adrenaline-pumping racing game that will get your heart racing and your adrenaline soaring! Step into the driver’s seat of powerful sports cars and take on high-speed races across a variety of challenging and dynamic tracks. Featuring stunning graphics, realistic physics, and intense gameplay, Drive Mad delivers an immersive racing experience that will keep you on the edge of your seat. Whether you're dodging obstacles or speeding past your opponents, every race will test your reflexes and strategic driving skills. Can you handle the speed, outmaneuver your rivals, and claim the title of the ultimate racing champion?
    <br><br>
    For even more racing thrills, check out <a href="/game/drive-mad-2">Drive Mad 2</a>, the highly-anticipated sequel, and take your racing to the next level!

    `,
    tag: ["Arcade","Boys","Car",],
    hint: `
    
    <ul>
     <li>You have to balance your speed so that your car does not overturn.</li>
     <li>Touch the screen to play and control the car.</li>
     <li>Complete the current level and then move to the next one.</li>
     </ul>
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2024-09-13",
    brand: "Yandex",
    orientation: 1,
    title: "Snake: Modern - An Exciting Take on Classic Snake Games",
    name: ["Snake: Modern", "snake-modern"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/snake-modern/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/snake-modern/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/snake-modern.mp4",
    path: ["/game/snake-modern", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/195667?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
    "Think you can master Snake: Modern? Play the game online for free at PlayCuteGames! Navigate your snake through fun challenges and watch it grow longer!",
    l_dis:
      `Snake Modern brings a fresh and exciting take on the classic snake game. In this modern version, you control a sleek snake in a dynamic, fast-paced arena, where your goal is to grow longer by eating scattered food and outmaneuvering other snakes. Simple to play, but with new challenges and stunning visuals, Snake Modern redefines the timeless gameplay of snake games with a stylish twist.

         
`,
      tag: ["Snake"],
    hint: `
    Playing Snake Modern is all about mastering your movement and planning ahead. You start small, but with every piece of food you eat, your snake grows longer and faster. But be careful—the more you grow, the harder it becomes to avoid obstacles and other snakes in the arena.
    <br>
    <b>Key Tip:</b> Avoid colliding with your own tail or other snakes! The last snake standing wins, so stay sharp and react quickly to the ever-changing challenges around you.
    
    <h2 class="H2">Power-Ups and Boosts</h2>
    Snake Modern takes things up a notch with strategic power-ups scattered throughout the arena. These power-ups provide your snake with special abilities:
    <br>
    <br>
    <ul>
    <li><b>Speed Boost:</b> Zoom ahead of opponents to grab food before they do or escape tricky situations.</li>
    <li><b>Shield:</b> Protect your snake from crashing into obstacles or other players for a limited time.</li>
    <li><b>Growth Boost:</b> Collect this and instantly grow longer, putting you ahead of the competition.</li>
    </ul>
    <br>

    Mastering these power-ups is the key to dominating the game.
    
    <h2 class="H2">Compete and Climb the Leaderboards</h2>
    Every match in Snake Modern is a fierce battle for survival. With real-time global leaderboards, you can compete against players worldwide. Will you rise to the top and claim the title of the longest snake? Each game offers new opportunities to outmaneuver opponents and secure your place as a true snake master.

    <h2 class="H2">Exciting Features of Snake Modern</h2>
    <ul>
    <li><b>Futuristic Visuals:</b></b> Experience a sleek and modern design that sets it apart from other snake games.</li>
    <li><b>Simple but Addictive Gameplay:</b> Easy to pick up but hard to put down—classic snake mechanics with added excitement.</li>
    <li><b>Power-Ups for Strategic Play:</b> Use special abilities to gain an advantage over your rivals.</li>
    <li><b>Competitive Multiplayer:</b> Face off against players from around the globe in thrilling snake battles.</li>
    <li><b>Global Leaderboards:</b> See how you stack up against other players and climb the ranks.</li>
    </ul>
    <br>
    <b>Are you ready to slither your way to victory?</b> Play Snake Modern now and experience the next evolution of the classic snake game. With modern graphics, exciting power-ups, and intense multiplayer action, this is the ultimate game for snake fans everywhere!
    `,
    rating:[4.8,15102,1014],  
  },
  {
    date: "2024-09-13",
    brand: "Yandex",
    orientation: 1,
    title: "Red Snake 3D: Fast-Paced Fun in Challenging Snake Games",
    name: ["Red Snake 3D", "red-snake-3d"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/red-snake-3d/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/red-snake-3d/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/red-snake-3d.mp4",
    path: ["/game/red-snake-3d", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/367742?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
    "Play Red Snake 3D, a fast-paced free snake game where you navigate icy obstacles and dodge barricades in 20 challenging levels.",
    l_dis:
      `Red Snake 3D - is a fast-paced free game where you control a snake that slithers through icy obstacles. Your goal is to reach the finish line in each of the 20 challenging levels while dodging barricades and walls. The game requires quick reflexes and determination, offering an addictive gameplay experience that you will definitely enjoy.
`,
      tag: ["Snake"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],  
  },
  {
    date: "2024-09-13",
    brand: "Yandex",
    orientation: 0,
    title: "Clash of Snakes: Battle, Customize, and Conquer the Arena!",
    name: ["Clash of Snakes", "clash-of-snakes"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/clash-of-snakes/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/clash-of-snakes/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/clash-of-snakes.mp4",
    path: ["/game/clash-of-snakes", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/199208?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
    "Join the battle in Clash of Snakes! Customize your snake, conquer challenging levels, and compete against players in the arena. Can you take the top spot on the leaderboard?",
    l_dis:
      `An exciting game where you have to play as a snake! In this game you are waiting for Campaign of interesting levels with increasing difficulty! Battle against other players in the arena! Ability to choose the look of your snake from many different options! Compete with other players and try to take first place on the leaderboard!.
`,
      tag: ["Snake"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],  
  },
  { date: "2025-02-26",brand: "playcutegames",title: "Roblox Couple Dress-up – The Ultimate Dress up Game",
    orientation: 0,
    hot:"2x2",
    name: ["Roblox Couple Dress-up", "roblox-couple-dressup"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/roblox-couple-dressup/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/roblox-couple-dressup/520x295.png",
    path: ["/game/roblox-couple-dressup", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/roblox-couple-dressup/",
    Walkthrough: "https://www.youtube.com/embed/QsCCt8zLiDY",
    s_dis:
    "Play Roblox Couple Dress-up, the ultimate Dress up Game! Style Roblox avatars with trendy outfits, accessories, and hairstyles in this fun Roblox Girl Game.",
    l_dis:
      `
      <p>Unleash your fashion creativity with <strong>Roblox Couple Dress-up</strong>, the ultimate <strong>Dress up Game</strong> for Roblox fans! Design trendy outfits, mix and match accessories, and craft the perfect couple look. If you love <strong>Roblox Girl Games</strong>, this stylish adventure is for you!</p>

    <h2 class="H22">✨ How to Play Roblox Couple Dress-up</h2>
    <p>Dressing up has never been this fun! In this interactive <strong>Dress up Game</strong>, follow these steps to create stunning Roblox couple looks:</p>
    <ul>
        <li>👫 <strong>Select Your Characters:</strong> Choose two Roblox avatars to dress up.</li>
        <li>👗 <strong>Pick Outfits:</strong> Explore a variety of stylish clothing options.</li>
        <li>💎 <strong>Customize Accessories:</strong> Add hats, jewelry, glasses, and bags.</li>
        <li>💇‍♀️ <strong>Change Hairstyles:</strong> Experiment with different haircuts and colors.</li>
        <li>🎭 <strong>Showcase Your Style:</strong> Save and share your fashion creations!</li>
    </ul>

    <h2 class="H22">🌟 Why Play Roblox Couple Dress-up?</h2>
    <ul>
        <li>👗 <strong>Fashion Fun:</strong> Dress up Roblox avatars with trendy outfits.</li>
        <li>🎨 <strong>Endless Creativity:</strong> Mix and match accessories and hairstyles.</li>
        <li>💖 <strong>Couple Themes:</strong> Create stylish outfits for Roblox couples.</li>
        <li>🚀 <strong>Unblocked Version:</strong> Play anytime, anywhere for unlimited fun!</li>
    </ul>

    <h2 class="H22">🎭 New Features in Roblox Couple Dress-up</h2>
    <p>Stay ahead of fashion trends with the latest updates! Enjoy fresh outfits, seasonal themes, and new customization options. Whether you prefer casual, glam, or fantasy styles, there’s something for everyone.</p>
 `,
      tag: ["Roblox","Love","Exclusive","Girl games"],
    rating:[4.8,1210,254],
    faq: [
      {
        question: "Is Roblox Couple Dress-up free to play?",
        answer: "Yes! This Dress up Game is free to play and offers unlimited outfit customization."
      },
      {
        question: "Can I play Roblox Couple Dress-up on mobile?",
        answer: "Absolutely! The game is optimized for smartphones, tablets, and desktops for a smooth experience anywhere."
      },
    ],
      
  },
  { date: "2025-02-26",brand: "playcutegames",title: "Lovie Chic’s Coachella Festival - The Ultimate Fashion Game",
    orientation: 0,
    name: ["Lovie Chic’s Coachella Festival", "lovie-chics-coachella-festival"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-coachella-festival/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-coachella-festival/520x295.png",
    path: ["/game/lovie-chics-coachella-festival", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/lovie-chics-coachella-festival/",
    Walkthrough: "https://www.youtube.com/embed/1bouJhr9udA",
    s_dis:
    "Play Lovie Chic’s Coachella Festival, the ultimate Coachella game for fashion lovers! Style outfits, apply makeup, and shine at the biggest festival.",
    l_dis:
      `
    <p>Step into the vibrant world of <strong>Lovie Chic’s Coachella Festival</strong>, the ultimate <strong>Coachella game</strong> where fashion meets music and creativity. Dress up your character, choose trendy accessories, and get ready to turn heads at the biggest festival of the year!</p>

    <h2 class="H22">🎭 Express Your Style in the Coachella Game</h2>
    <p>Coachella is all about fashion, and in this game, you can create stunning festival outfits with bohemian dresses, edgy accessories, and bold makeup looks. Mix and match to find the perfect festival style!</p>

    <h2 class="H22">✨ What’s New in Lovie Chic’s Coachella Festival?</h2>
    <p>The latest update brings new outfits, glamorous accessories, and exciting festival-themed makeup options. With improved controls and stunning visuals, every player can create show-stopping looks effortlessly.</p>

    <h2 class="H22">🎨 Customize Every Detail</h2>
    <p>From hair and makeup to jewelry and festival essentials, personalize every part of your character. Whether you prefer a boho-chic, rockstar, or neon-rave style, the possibilities are endless!</p>

    <h2 class="H22">🎶 Experience the Festival Vibes</h2>
    <p>Enjoy the lively atmosphere of Coachella with a virtual experience filled with lights, music, and energy. Play solo or share your stunning fashion looks with friends for a fun and interactive experience.</p>

    <h2 class="H22">🌟 Why Play Lovie Chic’s Coachella Festival?</h2>
    <ul>
        <li>👗 A fashion lover’s dream with endless outfit choices.</li>
        <li>💄 Create unique festival makeup looks.</li>
        <li>🎸 Enjoy a virtual Coachella festival experience.</li>
        <li>📸 Share your stylish outfits with friends.</li>
    </ul>

    <h2 class="H22">🎯 Tips for Creating the Perfect Festival Look</h2>
    <ul>
        <li>🌸 Experiment with different fashion styles to find your signature look.</li>
        <li>💎 Use accessories like sunglasses, jewelry, and hats to enhance your outfit.</li>
        <li>🎨 Play with vibrant colors and bold makeup choices to stand out.</li>
    </ul>

    <h2 class="H22">🆓 Play Anywhere, Anytime</h2>
    <p>As an <strong>unblocked fashion game</strong>, you can play <strong>Lovie Chic’s Coachella Festival</strong> anywhere without restrictions. Enjoy stylish fun at school, work, or home!</p>  
      `,
      tag: ["Lovie Chic","Coachella","Exclusive","Girl games"],
    rating:[4.4,1210,114],
    faq: [
      {
        question: "Is Lovie Chic’s Coachella Festival free to play?",
        answer: "Yes! You can play Lovie Chic’s Coachella Festival for free and enjoy all the exciting fashion and festival features."
      },
      {
        question: "Can I customize my character’s outfit and makeup?",
        answer: "Absolutely! You can mix and match outfits, hairstyles, accessories, and makeup to create your perfect Coachella-inspired look."
      },
    ],
      
  },
  {date: "2025-03-21",brand: "Yandex",orientation: 0,title: "What's the difference? - Ultimate Car Game with Stunts & Action",
    name: ["What's the difference?", "whats-the-difference"],
    thumb: "https://avatars.mds.yandex.net/get-games/3006389/2a00000191dfe2bb83701743be18f2212d1e/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/3006389/2a00000191dfe2bb83701743be18f2212d1e/pjpg350x209",
    path: ["/game/whats-the-difference", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/283410?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/nxjwpy46L-Q",
    video:"https://video-preview.s3.yandex.net/ugc/1c600f753357f85e3766690134d31f81_vmaf-preview-10s-360.mp4",
    s_dis:"Play What's the Difference?, the best Find the Different Game with merge mechanics, puzzle challenges, and unblocked gameplay. Test your skills now!",
    l_dis:
      ` 
      <p>Join over <strong>6,000,000 players worldwide</strong> in the legendary <strong>Find the Different Game</strong> challenge! "What's the Difference?" is an exciting puzzle game where you compare two images and spot subtle differences before your opponent. With competitive tournaments, special events, and engaging levels, this game is perfect for puzzle lovers.</p>

    <h2 class="H22">🕵️‍♂️ How to Play: Spot the Differences</h2>
    <p>The rules are simple—find all the differences between two seemingly identical images before time runs out. The faster you spot them, the more points and rewards you earn. Compete against friends or online players in thrilling head-to-head challenges!</p>

    <h2 class="H22">🔄 Merge Games: Earn Rewards & Upgrade</h2>
    <p>Unlike traditional puzzle games, "What's the Difference?" introduces a fun <strong>merge mechanic</strong>. Collect and upgrade rewards as you progress through levels. Unlock new images, customize your experience, and participate in special events for extra bonuses.</p>

    <h2 class="H22">🧩 Puzzle Games with Online Competition</h2>
    <p>Test your observation skills in competitive <strong>Puzzle Games</strong> where you race against other players. Take part in weekly tournaments, complete missions, and climb the global leaderboard to become the ultimate spot-the-difference champion.</p>

    <h2 class="H22">🚀 Unblocked Games: Play Anywhere, Anytime</h2>
    <p>Looking for <strong>Unblocked Games</strong> that work at school or the office? "What's the Difference?" is fully accessible on any device with no downloads or restrictions. Just open your browser and start playing instantly!</p>

    <h2 class="H22">🔥 Game Features</h2>
    <ul>
        <li>👀 Hundreds of stunning images with hidden differences.</li>
        <li>🏆 Compete against friends and global players in real-time challenges.</li>
        <li>🔄 Unique merge mechanics to upgrade rewards and unlock new levels.</li>
        <li>⏳ Timed puzzles to test your speed and accuracy.</li>
        <li>🆓 Play as an <strong>Unblocked Game</strong> from anywhere.</li>
    </ul>

    <h2 class="H22">💡 Pro Tips for Mastering Find the Different Game</h2>
    <ul>
        <li>👓 Take a moment to scan the entire image before clicking.</li>
        <li>⏰ Focus on edges, colors, and small details that might be altered.</li>
        <li>🏆 Compete in daily events to earn extra rewards and power-ups.</li>
    </ul>
      `,
      faq: [
        {
          question: "Is What's the Difference? free to play?",
          answer: "Yes! The game is completely free to play, and you can enjoy all features without any cost."
        },
        {
          question: "Can I play What's the Difference? on mobile?",
          answer: "Absolutely! The game works smoothly on all devices, including mobile phones, tablets, and desktops."
        },
      ],  
    tag: ["Car","Action"],
    rating:[4.8,15102,1014],
    
  },
  {date: "2025-03-21",brand: "Yandex",orientation: 0,title: "Criminal Russia 3D - Ultimate Car Game with Stunts & Action",
    name: ["Criminal Russia 3D", "criminal-russia-3d"],
    thumb: "https://avatars.mds.yandex.net/get-games/1890793/2a0000018fb66065172134b9308ef7bf07a0/cover1",
    thumb_big: "https://avatars.mds.yandex.net/get-games/1890793/2a0000018fb66065172134b9308ef7bf07a0/cover1",
    path: ["/game/criminal-russia-3d", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/283410?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://video-preview.s3.yandex.net/ugc/956e437dcaab1551ae41fd3a3af563c5/dafec20f-4d75-4d55-a053-af43038aff8d_vmaf-preview-10s-360.mp4",
    s_dis:"Play Empire City, the ultimate fashion game where style meets action! Stack skirts, glide over obstacles, and create the perfect look. Play now!",
    l_dis:
      ` 
      <p>Step into the thrilling world of <strong>Criminal Russia 3D</strong>, an action-packed <strong>car game</strong> where you take on the role of Boris, a criminal navigating the dangerous streets of Russia. Whether you love high-speed police chases, extreme <strong>stunt games</strong>, or customizing cars, this open-world adventure has it all.</p>

    <h2 class="H22">🚗 Open-World Exploration</h2>
    <p>Roam through a massive, detailed city where you can fight, steal cars, ride motorcycles, and even hop on public transport. The realistic environment makes every moment feel immersive.</p>

    <h2 class="H22">🚦 Detailed Road Traffic System</h2>
    <p>Experience a dynamic traffic system with bustling streets, traffic lights, and public transportation. Navigate through realistic urban landscapes and interact with different aspects of city life.</p>

    <h2 class="H22">🚨 Police Chases and Crime</h2>
    <p>Test your driving and escape skills by outrunning the cops in thrilling car chases. Be strategic—if you get caught, you’ll have to face the consequences!</p>

    <h2 class="H22">🚘 Massive Vehicle Collection</h2>
    <p>Drive and steal over 100 different vehicles, including sports cars, trucks, and even military vehicles. Whether you’re racing, exploring, or escaping, there’s a perfect ride for every mission.</p>

    <h2 class="H22">🎭 Full Character Customization</h2>
    <p>Make Boris your own with various outfits, hairstyles, and accessories. Stand out in the criminal underworld with your unique style.</p>

    <h2 class="H22">🔫 40+ Weapons for Ultimate Action</h2>
    <p>Arm yourself with a massive arsenal, from simple knives and pistols to sniper rifles and heavy artillery. Take control of the streets with the perfect weapon for any situation.</p>

    <h2 class="H22">💰 Part-Time Jobs for Extra Cash</h2>
    <p>Earn money by working as a taxi driver, professional car thief, or by completing side missions. Use your earnings to buy better weapons, vehicles, and upgrades.</p>

    <h2 class="H22">🏎️ Vehicle Tuning and Customization</h2>
    <p>Upgrade your cars with body color, tinting, suspension adjustments, spoilers, and over 20 different wheel rim options. Make your ride stand out and perform at its best.</p>

    <h2 class="H22">🔥 Why Play Criminal Russia 3D?</h2>
    <p>If you love high-speed <strong>car games</strong> and extreme <strong>stunt games</strong>, this is the perfect choice. It blends action, strategy, and customization for an unforgettable experience.</p>

    <h2 class="H22">🎯 Tips for Mastering Criminal Russia 3D</h2>
    <ul>
        <li>🕵️ Plan Your Heists – Avoid police detection by choosing the right moment to strike.</li>
        <li>🚗 Upgrade Your Vehicles – Improve speed and durability for better escapes.</li>
        <li>💼 Experiment with Jobs – Earn extra cash to invest in weapons and cars.</li>
        <li>🎭 Customize Boris – Stand out with stylish outfits and accessories.</li>
    </ul>

    <h2 class="H22">🆓 Play Unblocked Games Anywhere</h2>
    <p>As an <strong>unblocked game</strong>, you can enjoy <strong>Criminal Russia 3D</strong> anytime, anywhere. Whether you’re at school, work, or home, dive into the open world and start your crime adventure without restrictions.</p>

      `,
      faq: [
        {
          question: "Is Criminal Russia 3D free to play?",
          answer: "Yes! Criminal Russia 3D is completely free to play, and the unblocked version ensures you can access it anywhere."
        },
        {
          question: "Can I customize my cars in Criminal Russia 3D?",
          answer: "Absolutely! You can tune and modify your cars with new colors, wheels, spoilers, and more to enhance both style and performance."
        },
      ],  
    tag: ["Car","Action"],
    rating:[4.8,15102,1014],
    
  },
  {date: "2025-03-21",brand: "Yandex",orientation: 0,title: "Empire City - Build & Expand Your Ultimate Civilization",
    name: ["Empire City", "empire-city"],
    thumb: "https://avatars.mds.yandex.net/get-games/13012056/2a0000019570c86e9757518b1a2697fa92a3/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/13012056/2a0000019570c86e9757518b1a2697fa92a3/pjpg350x209",
    path: ["/game/empire-city", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/271135?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/LKsu_Qlz_3U",
    video:"https://video-preview.s3.yandex.net/ugc/9aaa7d710597fb83b5d4b1064b03e491/7f052451-9165-4f6a-9e93-ee828ff00e85_vmaf-preview-10s-360.mp4",
    s_dis:"Play Criminal Russia 3D, the ultimate car game with open-world action, police chases, and vehicle stunts. Experience the best in stunt games now!",
    l_dis:
      `
       <p>Have you ever dreamed of ruling a vast empire? In <strong>Empire City</strong>, you can construct grand cities, manage resources, and lead a civilization to greatness. Experience a perfect blend of <strong>empire game</strong> strategy and <strong>building games</strong> creativity.</p>

    <h2 class="H22">🏗️ Build and Expand Your Empire</h2>
    <p>From small settlements to thriving metropolises, shape your empire with stunning architecture. Gather resources, manage populations, and make crucial decisions that influence the future of your civilization.</p>

    <h2 class="H22">🔄 Merge Games with Strategic Development</h2>
    <p>Unlock powerful structures by merging resources and planning strategically. Every decision impacts your empire’s progress, making each game session unique and immersive.</p>

    <h2 class="H22">🧩 Puzzle Games for Creative City Planning</h2>
    <p>Think like a ruler and solve complex puzzles to optimize city layouts, build infrastructure, and ensure efficient resource management. Trade with other civilizations and advance through history.</p>

    <h2 class="H22">🎮 Unblocked Games for Endless Fun</h2>
    <p>Enjoy <strong>Empire City Unblocked</strong>, a limitless gaming experience that you can play anywhere, anytime. No downloads, no restrictions—just pure strategic fun!</p>

    <h2 class="H22">🌟 Game Features</h2>
    <ul>
        <li>🏛️ Construct stunning cities and legendary wonders.</li>
        <li>📈 Develop trade routes and establish a thriving economy.</li>
        <li>🔬 Discover new technologies and cultural advancements.</li>
        <li>🧠 Engaging and strategic gameplay for all ages.</li>
        <li>🆓 Completely unblocked for endless fun.</li>
    </ul>

    <h2 class="H22">🏺 Create Wonders of the Ancient World</h2>
    <p>From epic statues to intricate viaducts, construct legendary monuments that leave a lasting legacy. Lead your empire through different eras and shape the course of history!</p>


      `,
      faq: [
        {
          question: "Is Empire City free to play?",
          answer: "Yes! Empire City is completely free, and you can enjoy Empire City Unblocked without any restrictions."
        },
        {
          question: "Can I play Empire City on mobile?",
          answer: "Absolutely! Empire City is available on both desktop and mobile browsers, offering a seamless experience wherever you go."
        },
      ],  
    tag: ["Fashion","Run"],
    rating:[4.8,15102,1014],
    
  },
  {date: "2025-03-15",brand: "Yandex",orientation: 0,title: "Hover Skirt - Play the Ultimate Fashion Game Online",
    name: ["Hover Skirt", "hover-skirt"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/hover-skirt/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/hover-skirt/250x142.png",
    path: ["/game/hover-skirt", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/206851?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Hover Skirt, the ultimate fashion game where style meets action! Stack skirts, glide over obstacles, and create the perfect look. Play now!",
    l_dis:
      `
      <p>Welcome to <strong>Hover Skirt</strong>, the trendiest mix of fashion and arcade action! Glide across platforms, stack skirts, and dodge obstacles while creating the most stylish look. <strong>Hover Skirt Unblocked</strong> lets you enjoy the fun anywhere, anytime. Ready to stack and soar? Start your fashion adventure now!</p>

    <h2 class="H22">🔥 What is Hover Skirt?</h2>
    <p><strong>Hover Skirt</strong> is an exciting <strong>fashion game</strong> where you control a stylish character, collecting skirts to hover over gaps and challenges. Each level tests your reflexes and style sense, making it a unique blend of arcade fun and dress-up creativity.</p>

    <h2 class="H22">🎮 How to Play Hover Skirt</h2>
    <ul>
        <li><strong>Run & Collect:</strong> Move your character forward while collecting skirts.</li>
        <li><strong>Hover Over Obstacles:</strong> Stack enough skirts to glide over tricky gaps.</li>
        <li><strong>Style Your Look:</strong> Unlock new outfits, accessories, and shoes.</li>
        <li><strong>Reach the Finish Line:</strong> Avoid dangers and complete each level in style!</li>
    </ul>
    <h2 class="H22">✨ Features of Hover Skirt</h2>
    <ul>
        <li><strong>Unique Fashion Gameplay:</strong> A perfect mix of dress-up and platform racing.</li>
        <li><strong>Challenging Levels:</strong> Test your timing and agility while stacking skirts.</li>
        <li><strong>Stylish Customization:</strong> Unlock new outfits, high heels, and accessories.</li>
        <li><strong>Unblocked Version:</strong> Play <strong>Hover Skirt Unblocked</strong> anywhere, with no downloads!</li>
    </ul>

    <h2 class="H22">🆕 What’s New in Hover Skirt?</h2>
    <p>Recent updates bring new stylish outfits, improved gliding mechanics, and tougher levels! <strong>Hover Skirt Unblocked</strong> now offers more vibrant colors, bonus gems, and thrilling challenges to keep every run exciting.</p>

    <h2 class="H22">🚀 Play Hover Skirt Unblocked</h2>
    <p>Looking for a <strong>fun girl game</strong> that combines fashion and action? <strong>Hover Skirt</strong> is the perfect choice! Play online now and experience the ultimate styling and gliding challenge.</p>

    <h2 class="H22">👗 Why Play Hover Skirt?</h2>
    <p>Hover Skirt is perfect for players who love both fashion and arcade challenges. With endless outfit combinations and thrilling gameplay, it’s a must-play for fashion lovers and gamers alike!</p>
      `,
    tag: ["Fashion","Run"],
    rating:[4.8,15102,1014],
    faq: [
      {
        question: "Is Hover Skirt free to play?",
        answer: "Yes! Hover Skirt is completely free to play, and you can enjoy <strong>Hover Skirt Unblocked</strong> without restrictions.</p>"
      },
      {
        question: "Can I play Hover Skirt on mobile?",
        answer: "Absolutely! Hover Skirt is playable on both desktop and mobile browsers, so you can enjoy the fun anytime!"
      },
    ],  
  },
  {date: "2025-02-24",brand: "Yandex",orientation: 0,title: "Whopper Clicker - Play Unblocked Clicker Game Online",
    name: ["Whopper Clicker", "whopper-clicker"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/whopper-clicker/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/whopper-clicker/250x142.png",
    path: ["/game/whopper-clicker", "https://playcutegames.com"],
    iframe: "https://scratch.mit.edu/projects/834840279/embed",
    Walkthrough: "https://www.youtube.com/embed/KXbOjdjZTZI",
    s_dis:
    "Play Whopper Clicker, the ultimate unblocked clicker game! Stack burgers, earn upgrades, and grow your fast-food empire. Click your way to success now!",
    l_dis:
      `
    <p>Welcome to <strong>Whopper Clicker</strong>, the ultimate burger-building clicker game! Tap, stack, and grow your fast-food empire as you earn virtual cash and unlock wild upgrades. <strong>Whopper Clicker Unblocked</strong> ensures endless fun anytime, anywhere. Are you ready to create the most legendary Whopper ever? Start clicking now!</p>

    <h2 class="H22">🔥 What is Whopper Clicker?</h2>
    <p><strong>Whopper Clicker</strong> is an engaging idle game where your goal is to build the biggest burger empire by clicking, stacking, and upgrading your fast-food business. From simple patties to outrageous toppings, every tap helps expand your delicious empire!</p>

    <h2 class="H22">🎮 How to Play Whopper Clicker</h2>
    <ul>
        <li><strong>Click to Stack:</strong> Tap the screen to stack juicy Whoppers and earn cash.</li>
        <li><strong>Upgrade & Expand:</strong> Unlock toppings, tools, and special bonuses.</li>
        <li><strong>Automate Earnings:</strong> Invest in auto-clickers for non-stop stacking.</li>
        <li><strong>Build Your Empire:</strong> Keep upgrading to dominate the fast-food world!</li>
    </ul>

    <h2 class="H22">✨ Features of Whopper Clicker</h2>
    <ul>
        <li><strong>Endless Burger Stacking:</strong> Keep clicking to create the biggest burger tower.</li>
        <li><strong>Crazy Upgrades:</strong> Unlock better ingredients, faster stacking, and more cash.</li>
        <li><strong>Idle Clicker Fun:</strong> Earn money even when you're not playing.</li>
        <li><strong>Unblocked Version:</strong> Play <strong>Whopper Clicker Unblocked</strong> anywhere, no downloads needed!</li>
    </ul>

    <h2 class="H22">🆕 What’s New in Whopper Clicker?</h2>
    <p>Recent updates bring new burger flavors, improved stacking mechanics, and upgraded auto-clickers. <strong>Whopper Clicker Unblocked</strong> now offers faster gameplay and bigger rewards!</p>

    <h2 class="H22">🚀 Play Whopper Clicker Unblocked</h2>
    <p>Looking for an addictive <strong>unblocked clicker game</strong>? <strong>Whopper Clicker</strong> is the perfect choice for casual gamers who love stacking challenges. Play online now and start growing your burger empire!</p>

    <h2 class="H22">🍔 Why Play Whopper Clicker?</h2>
    <p>Whether you're a clicker pro or just looking for some fast-paced fun, <strong>Whopper Clicker</strong> is a must-play. Stack, upgrade, and dominate the burger world one tap at a time!</p>
     `,
    tag: ["Action","Relief"],
    rating:[4.8,15102,1014],
    faq: [
      {
        question: "Is Whopper Clicker free to play?",
        answer: "Yes! Whopper Clicker is completely free to play, and you can enjoy <strong>Whopper Clicker Unblocked</strong> without any restrictions."
      },
      {
        question: "Can I play Whopper Clicker on my phone?",
        answer: "Absolutely! Whopper Clicker is available on both desktop and mobile browsers, so you can stack burgers anytime!"
      },
    ],  
  },
  {date: "2025-02-24",brand: "Yandex",orientation: 0,title: "Kick The Buddy - Play Unblocked for Ultimate Stress Relief",
    name: ["Kick The Buddy", "kick-the-buddy"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/kick-the-buddy/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/kick-the-buddy/250x142.png",
    path: ["/game/kick-the-buddy", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/162140?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/JuwrB8BlQEE",
    s_dis:
    "Play Kick The Buddy Unblocked and relieve stress with endless fun! Smash, blast, and test wild weapons in this addictive game.",
    l_dis:
      `
   <p>Dive into <strong>Kick The Buddy</strong>, the ultimate stress-relief game that lets you unleash your wild side! Take on Buddy, a tough ragdoll who’s ready for anything, and hammer him with an arsenal of crazy weapons, from knives to nukes. Developed by Playgendary, <strong>Kick The Buddy Unblocked</strong> is a fan-favorite hit with non-stop action. Whether you’re blowing off steam or just having a laugh, this game keeps the fun flowing anywhere. Ready to smash? Start kicking now!</p>
    
    <h2 class="H22">✨ What’s New in Kick The Buddy?</h2>
    <p>Recent updates bring wilder weapons, smoother physics, and fresh ways to customize Buddy! With <strong>Kick The Buddy Unblocked</strong>, enjoy bigger explosions, zanier tools, and endless chaos that keeps every session a blast.</p>
    
    <h2 class="H22">🎮 How to Play Kick The Buddy</h2>
    <ul>
        <li><strong>Select Your Weapon:</strong> Choose from an insane arsenal of tools and explosives.</li>
        <li><strong>Smash & Blast:</strong> Click, drag, and tap to unleash destruction on Buddy.</li>
        <li><strong>Earn Rewards:</strong> Unlock new weapons and skins by racking up damage.</li>
        <li><strong>Customize Your Buddy:</strong> Change his outfits, reactions, and more.</li>
    </ul>
    
    <h2 class="H22">🌟 Why Play Kick The Buddy?</h2>
    <ul>
        <li><strong>Stress-Relief Gameplay:</strong> Smash and blast to release tension.</li>
        <li><strong>Unblocked Access:</strong> Play <strong>Kick The Buddy Unblocked</strong> anytime, anywhere.</li>
        <li><strong>Hilarious Reactions:</strong> Watch Buddy’s comical responses to every hit.</li>
        <li><strong>Endless Customization:</strong> Change weapons, backgrounds, and Buddy’s looks.</li>
    </ul>
    
    <h2 class="H22">🆓 Play Kick The Buddy Unblocked</h2>
    <p>Looking for a game that delivers nonstop fun and destruction? <strong>Kick The Buddy Unblocked</strong> lets you test crazy weapons and laugh at Buddy’s over-the-top reactions. Play now and enjoy unrestricted mayhem!</p>
     
      `,
    tag: ["Action","Relief"],
    rating:[4.8,15102,1014],
    faq: [
      {
        question: "Is Kick The Buddy free to play?",
        answer: "Yes! You can play Kick The Buddy Unblocked for free online without downloads."
      },
      {
        question: "Can I play Kick The Buddy on mobile?",
        answer: "Yes! Kick The Buddy is available on mobile devices, making it easy to play anytime."
      },
    ],  
  },
  { date: "2025-02-26",brand: "playcutegames",title: "BFFs Cherry Blossom Celebration - Play the Best Dress Up Games Online",
    orientation: 0,
    name: ["BFFs Cherry Blossom Celebration", "bffs-cherry-blossom-celebration"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-cherry-blossom-celebration/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-cherry-blossom-celebration/520x295.png",
    path: ["/game/bffs-cherry-blossom-celebration", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bffs-cherry-blossom-celebration/",
    Walkthrough: "https://www.youtube.com/embed/2oBDisutQoc",
    s_dis:
    "Play BFFs Cherry Blossom Celebration, one of the best Celebrity Games! Dress up your favorite stars in trendy outfits and enjoy this fun Winter Game online.",
    l_dis:
      `
       
    <h2 class="H22">🌸 Step into the World of Cherry Blossoms</h2>
    <p>Welcome to <strong>BFFs Cherry Blossom Celebration</strong>, the ultimate <strong>Barbie dress-up game</strong> where best friends come together to shine under the breathtaking cherry blossoms! Choose your favorite squad, dress them up in gorgeous spring-themed outfits, and capture the magic of this seasonal festival.</p>
    <h2 class="H22">🎮 How to Play BFFs Cherry Blossom Celebration</h2>
    <ul>
        <li>Pick your favorite BFFs and customize their looks.</li>
        <li>Choose stylish spring outfits, accessories, and hairstyles.</li>
        <li>Take group photos under the cherry blossoms.</li>
        <li>Complete festive challenges and unlock new outfits!</li>
    </ul>
    <h2 class="H22">✨ Features of BFFs Cherry Blossom Celebration</h2>
    <ul>
        <li><strong>Beautiful Spring-Themed Outfits:</strong> Enjoy a wardrobe filled with pastel dresses, floral crowns, and cherry blossom-inspired fashion.</li>
        <li><strong>Interactive Dress-Up:</strong> Mix and match accessories to create stunning looks.</li>
        <li><strong>Unblocked Game:</strong> Play <strong>BFFs Cherry Blossom Celebration unblocked</strong> anywhere, anytime.</li>
        <li><strong>Photo Mode:</strong> Capture and share your favorite fashion moments with friends.</li>
    </ul>
    <h2 class="H22">🌟 What’s New in BFFs Cherry Blossom Celebration?</h2>
    <p>Recent updates bring dazzling new outfits, cherry-inspired accessories, and party-ready hairstyles! Enjoy fresh looks, group photo ops, and festive challenges to make every styling session a blast.</p>
    <h2 class="H22">🚀 Play BFFs Cherry Blossom Celebration Unblocked</h2>
    <p>Looking for a stylish and fun <strong>unblocked game</strong>? <strong>BFFs Cherry Blossom Celebration</strong> is perfect for dress-up fans who love creativity and fashion. Play now and bring your fashion dreams to life!</p>
    <h2 class="H22">💖 Start Your Fashion Adventure Today!</h2>
    <p>Are you ready to bloom in style? Play <strong>BFFs Cherry Blossom Celebration</strong> and create unforgettable fashion moments with your best friends!</p>
    `,
      tag: ["Barbie","Fashion","Exclusive","Girl games"],
    rating:[4.4,1210,114],
    faq: [
      {
        question: "Can I play BFFs Cherry Blossom Celebration unblocked?",
        answer: "Yes! BFFs Cherry Blossom Celebration is an unblocked game, meaning you can enjoy it anywhere, anytime, without restrictions."
      },
      {
        question: "What makes BFFs Cherry Blossom Celebration different from other Barbie games?",
        answer: "This game focuses on seasonal fashion, cherry blossom themes, and interactive dress-up mechanics, making it a unique experience for fans of Barbie games and fashion games."
      },
    ],
      
  },
  {date: "2025-02-24",brand: "Y8",orientation: 0,title: "Bartender: The Right Mix - Play Unblocked Bartender Games Online",
    name: ["Bartender: The Right Mix", "bartender-the-right-mix"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bartender-the-right-mix/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bartender-the-right-mix/250x142.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/bartender-the-right-mix.mp4",
    path: ["/game/bartender-the-right-mix", "https://playcutegames.com"],
    iframe: "https://storage.y8.com/y8-studio/html5/akeemywka/bartender_make_right_mix_v6/",
    Walkthrough: "https://www.youtube.com/embed/JV-Dxs9jSpI",
    s_dis:
    "Play Bartender: The Right Mix, the ultimate bartending simulation game! Mix drinks, serve customers, and master your bartending skills in this fun unblocked game.",
    l_dis:
      `
     <h2 class="H22">Step into the World of Bartender Games</h2>
    <p>Welcome to <strong>Bartender: The Right Mix</strong>, the ultimate <strong>bartender game</strong> where you can mix drinks, serve customers, and become a master mixologist. With exciting challenges and creative freedom, this game offers an immersive bartending experience like no other.</p>
    <h2 class="H22">How to Play Bartender: The Right Mix</h2>
    <ul>
        <li>Select ingredients and mix them to create the perfect cocktail.</li>
        <li>Adjust the proportions carefully to avoid disastrous results.</li>
        <li>Serve drinks to customers and get feedback on your skills.</li>
        <li>Experiment with new recipes to unlock hidden combinations.</li>
    </ul>
    <h2 class="H22">Exciting Features of Bartender: The Right Mix</h2>
    <ul>
        <li><strong>Realistic Mixing:</strong> Create unique cocktails with different ingredients.</li>
        <li><strong>Fun Reactions:</strong> Watch customers’ hilarious responses to your creations.</li>
        <li><strong>Challenging Levels:</strong> Improve your skills with increasing difficulty.</li>
        <li><strong>Unblocked Fun:</strong> Play <strong>Bartender: The Right Mix unblocked</strong> anywhere, anytime.</li>
    </ul>   <h2 class="H22">Start Mixing Today!</h2>
    <p>Are you ready to shake things up? Play <strong>Bartender: The Right Mix</strong> now and become the ultimate bartender!</p>
      `,
    tag: ["Skill","Puzzle","Brain"],
    rating:[4.8,15102,1014],
    faq: [
      {
        question: "What’s New in Bartender: The Right Mix?",
        answer: "The latest update introduces new drink recipes, upgraded visuals, and exciting customization options. Whether you're a beginner or a seasoned bartender, Bartender: The Right Mix keeps the fun fresh with every round."
      },
      {
        question: "Play Bartender: The Right Mix Unblocked",
        answer: "Looking for unblocked games that challenge your creativity and skills? Bartender: The Right Mix is the perfect game to test your mixology talents and enjoy endless fun!"
      },
    ],
    
  },
  {date: "2025-02-24",brand: "Yandex",orientation: 0,title: "Toca Life World - Play Unblocked Toca Boca Games Online",
    name: ["Toca Life World", "toca-life-world"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-life-world/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-life-world/250x142.png",
    path: ["/game/toca-life-world", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/364663?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/7uyciR_9a0s",
    s_dis:
    "Play Hidden Objects: Sweet Home, a relaxing hidden object game. Search for items in cozy home interiors and unlock new levels. Play unblocked now!",
    l_dis:
      `
    
    <h2 class="H22">Step into the World of Toca Boca Games</h2>
    <p>Welcome to <strong>Toca Life World</strong>, the ultimate <strong>Toca Boca game</strong> where you can explore different locations, create unique stories, and unleash your imagination. With endless possibilities, this game offers a fun and interactive experience like no other.</p>
    <h2 class="H22">How to Play Toca Life World</h2>
    <ul>
        <li>Explore different locations and interact with various characters.</li>
        <li>Create your own stories and customize your world.</li>
        <li>Unlock new areas and expand your adventures.</li>
        <li>Share your creativity with friends and family.</li>
    </ul>
    <h2 class="H22">Exciting Features of Toca Life World</h2>
    <ul>
        <li><strong>Endless Creativity:</strong> Build, decorate, and design your own world.</li>
        <li><strong>Interactive Gameplay:</strong> Meet characters, discover surprises, and explore freely.</li>
        <li><strong>Customizable Characters:</strong> Personalize outfits, hairstyles, and accessories.</li>
        <li><strong>Unblocked Fun:</strong> Play <strong>Toca Life World unblocked</strong> anytime, anywhere.</li>
    </ul>
    <h2 class="H22">What’s New in Toca Life World?</h2>
    <p>The latest update introduces new locations, characters, and customization options. Enjoy smoother gameplay, enhanced graphics, and even more ways to express your creativity!</p>
    <h2 class="H22">Play Toca Life World Unblocked</h2>
    <p>Looking for a <strong>fun unblocked game</strong> that sparks creativity? <strong>Toca Life World</strong> is the perfect game to explore, create, and tell amazing stories.</p>
    <h2 class="H22">Start Your Adventure Today!</h2>
    <p>Are you ready to dive into a world of imagination? Play <strong>Toca Life World</strong> now and bring your stories to life!</p>
       
      `,
    tag: ["Toca boca","Teens"],
    rating:[4.8,15102,1014],  
  },
  { date: "2025-02-26",brand: "playcutegames",title: "Celebrity Snow Style - Play the Best Dress Up Games Online",
    orientation: 0,
    name: ["Celebrity Snow Style", "celebrity-snow-style"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-snow-style/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-snow-style/520x295.png",
    path: ["/game/celebrity-snow-style", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/celebrity-snow-style/",
    Walkthrough: "https://www.youtube.com/embed/cLrRYLXpk1s",
    s_dis:
    "Play Celebrity Snow Style, one of the best Celebrity Games! Dress up your favorite stars in trendy outfits and enjoy this fun Winter Game online.",
    l_dis:
      `
      <h2 class="H22">Step into the World of Celebrity Games</h2>
    <p>Welcome to <strong>Celebrity Snow Style</strong>, the ultimate <strong>dress-up game</strong> where you can style your favorite stars with trendy outfits, accessories, and makeup. This game is perfect for those who love <strong>fashion and creativity</strong>!</p>
    <h2 class="H22">How to Play Celebrity Snow Style</h2>
    <ul>
        <li>Select your favorite celebrity and start the styling process.</li>
        <li>Choose from a wide range of winter outfits, accessories, and hairstyles.</li>
        <li>Customize the makeup to create a unique winter glam look.</li>
        <li>Share your designs with friends or save them for inspiration.</li>
    </ul>
    <h2 class="H22">Exciting Features of Celebrity Snow Style</h2>
    <ul>
        <li><strong>Trendy Outfits:</strong> Choose from a stylish winter wardrobe.</li>
        <li><strong>Creative Freedom:</strong> Mix and match outfits, accessories, and makeup.</li>
        <li><strong>Vibrant Graphics:</strong> Enjoy beautifully designed visuals and smootsh animations.</li>
        <li><strong>Unblocked Fun:</strong> Play <strong>Celebrity Snow Style unblocked</strong> anywhere, anytime.</li>
    </ul>
    <h2 class="H22">What’s New in Celebrity Snow Style?</h2>
    <p>The latest update brings fresh outfits, accessories, and customization options! Experience improved controls and new ways to style your favorite stars. Whether you’re a beginner or a pro, <strong>Celebrity Snow Style</strong> keeps the fun going!</p>
    <h2 class="H22">Play Celebrity Snow Style Unblocked</h2>
    <p>Looking for <strong>unblocked dress-up games</strong>? <strong>Celebrity Snow Style</strong> is the perfect game for fashion lovers who want to enjoy seamless gameplay anywhere!</p>
    <h2 class="H22">Start Styling Today!</h2>
    <p>Are you ready to shine like a star? Play <strong>Celebrity Snow Style</strong> now and enjoy the best <strong>celebrity dress-up experience</strong> online!</p>
   
       `,
      tag: ["Celebrity"," Winter","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {date: "2025-02-24",brand: "Yandex",orientation: 0,title: "Hidden Objects: Sweet Home - Play Unblocked Online",
    name: ["Hidden Objects: Sweet Home", "hidden-objects-sweet-home"],
    thumb: "https://avatars.mds.yandex.net/get-games/11374519/2a000001931b00a2a071d79b5493b65cf118/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/11374519/2a000001931b00a2a071d79b5493b65cf118/pjpg350x209",
    video:"https://video-preview.s3.yandex.net/ugc/65400df65cdce7da5cd583e243637153/dcfce518-f269-4627-a77a-ffb874c7bd0f_vmaf-preview-10s-360.mp4",
    path: ["/game/hidden-objects-sweet-home", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/281150?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Hidden Objects: Sweet Home, a relaxing hidden object game. Search for items in cozy home interiors and unlock new levels. Play unblocked now!",
    l_dis:
      `
      <p><strong>Hidden Objects: Sweet Home</strong> is a relaxing and immersive <strong>hidden object game</strong> that takes you through cozy home interiors and scenic outdoor locations. Train your observation skills and complete fun mini-games while exploring beautifully designed environments.</p>
    
    <h2 class="H22">What is Hidden Objects: Sweet Home?</h2>
    <p>In <strong>Hidden Objects: Sweet Home</strong>, you'll search and find items in various houses, courtyards, and natural locations. Each setting has a unique atmosphere, packed with hidden objects to uncover. The game offers an engaging mix of <strong>puzzles, mini-games</strong>, and a customizable room feature where you can buy decor items and furniture.</p>
    
    <h2 class="H22">Game Genre</h2>
    <p>This game belongs to the <strong>hidden object, puzzle, and casual gaming</strong> genres, offering a relaxing yet engaging experience.</p>
    
    <h2 class="H22">Why You’ll Love Hidden Objects: Sweet Home</h2>
    <ul>
        <li><strong>Beautiful Graphics:</strong> Enjoy stunning visuals and detailed environments.</li>
        <li><strong>Engaging Puzzles:</strong> Challenge yourself with fun mini-games.</li>
        <li><strong>Customization:</strong> Decorate your room with furniture and stylish decor.</li>
        <li><strong>Relaxing Gameplay:</strong> Unwind with satisfying and stress-free hidden object gameplay.</li>
        <li><strong>Unblocked Gameplay:</strong> Play anywhere, anytime, without restrictions.</li>
    </ul>
    
    <h2 class="H22">How to Play</h2>
    <ul>
        <li>Look at the bottom of the screen to see the list of hidden objects to find.</li>
        <li>Carefully search the scene and click on the correct objects.</li>
        <li>Complete levels to unlock new areas and mini-games.</li>
        <li>Use hints if you get stuck!</li>
    </ul>
    
    <h2 class="H22">Why Play Hidden Object Games?</h2>
    <ul>
        <li><strong>Boost Focus:</strong> Improve attention to detail and concentration.</li>
        <li><strong>Fun for All Ages:</strong> Enjoy a game suitable for players of all skill levels.</li>
        <li><strong>Relaxing and Addictive:</strong> A perfect way to unwind and have fun.</li>
    </ul>
    
    <h2 class="H22">Start Playing Hidden Objects: Sweet Home Now!</h2>
    <p>Embark on a delightful hidden object adventure today! Play <strong>Hidden Objects: Sweet Home</strong> unblocked and discover cozy places filled with secrets waiting to be found.</p>

    
      `,
    tag: ["Hidden","Puzzle","Brain"],
    rating:[4.8,15102,1014],  
  },
  {date: "2022-11-01",brand: "Yandex",orientation: 0,title: "Fairyland Merge & Magic - The Ultimate Merge Games Experience",name: ["Fairyland Merge & Magic", "fairyland-merge-and-magic"],
    thumb:"https://avatars.mds.yandex.net/get-games/11374519/2a0000019594ae6af0bb289ba4806d6b5545/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/11374519/2a0000019594ae6af0bb289ba4806d6b5545/pjpg350x209",
    path: ["/game/fairyland-merge-and-magic", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/193645?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video: "https://video-preview.s3.yandex.net/ugc/56280a1ae4e24c68694d0a5fac5ac3e1/5e1ebee0-aafa-4b9b-b58f-f6c00d8f45d8_vmaf-preview-10s-360.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play the best Merge Games and Merge Unblocked Games. Discover magical lands, merge dragons, and solve exciting merge puzzles in this thrilling adventure!",
    l_dis:
    `  <h2 class="H22">Welcome to the World of Merge Games</h2>
    <p>Step into the enchanting world of <strong>Merge Games</strong> with <em>Fairyland Merge & Magic</em>. This is no ordinary fairy tale—it’s a mesmerizing adventure filled with magical creatures, exciting discoveries, and powerful merging magic!</p>
    <h2 class="H22">Create Your Own Merge World</h2>
    <p>Have you ever wanted to own a dragon? Now you can! Merge dragons, unicorns, leprechauns, and many other mythical creatures. Build your ultimate merge kingdom and set your own rules. Let the magic unfold as you complete incredible merge challenges.</p>
    <h2 class="H22">Key Features of Fairyland Merge & Magic</h2>
    <ul>
        <li><strong>Familiar Characters:</strong> Enjoy well-loved fairy tale characters in brand-new adventures.</li>
        <li><strong>Exciting Storyline:</strong> Experience an intriguing main story with thrilling side quests.</li>
        <li><strong>Magical Lands:</strong> Explore countless new islands filled with mysteries and discoveries.</li>
        <li><strong>Merge Puzzle Quests:</strong> Solve challenging merge puzzles and earn fantastic rewards.</li>
    </ul>
    <h2 class="H22">How to Play Fairyland Merge & Magic</h2>
    <p>Master the art of merging with these simple steps:</p>
    <ul>
        <li>Merge three grape sprouts to create ripened grapes.</li>
        <li>Do the same with other beds to help the grapes ripen.</li>
        <li>Harvest resources and use them to craft the magic key.</li>
        <li>Unlock the fog and reveal new parts of the island.</li>
    </ul>
    <h2 class="H22">Why Play Merge Unblocked Games?</h2>
    <p><strong>Merge Unblocked Games</strong> offer a seamless gaming experience anytime, anywhere. No downloads required—just open your browser and dive into the magical world of merging!</p>
    <h2 class="H22">Start Your Merge Adventure Today!</h2>
    <p>Are you ready for an enchanting journey filled with magic, mystery, and merge puzzles? Play <strong>Fairyland Merge & Magic</strong> today and enjoy the best <strong>Merge Games</strong> online!</p>
   
    `,
    tag: ["Casual","Simulation","Economic",],
    hint: `
    <ul>
     <li>Build a village and upgrade farm buildings!</li>
     <li>Grow fruit trees and plants in fields and gardens!</li>
     <li>Breed animals: feed a chicken flock, buy a cow or two and shear the sheep!</li>
     <li>Make hay while the sun shines: take part in seasonal and daily in-game events to gain prizes and become the best farmers!</li>
     <li>Explore diamond mines below the farm! The Golden rush is contagious!</li>
     <li>Produce and trade all sorts of your fazenda goods: from dairy to jewelry!</li>
     <li>Challenge other farmers at the Fair of Achievements!</li>
     <li>Create your own farm community to achieve better results!</li>
     <li>Manage a top grade delivery service: by car, train or even airship!</li>
     <li>Color up your farm! Choose from tons of furniture, decor and flower items to make your funky mansion look trendy!</li>
     <li>Equip an expedition and travel to a tropical island: exotic animals are waiting!</li>
     <li>Run a Zoo and an Amusement park and decorate them to your taste!</li>
     <li>Bring some cute pets from the island and fill your meadows with life!</li>
     </ul>
    `,
    rating:[4.9,350,18],
  }, 
  {date: "2022-11-01",brand: "Yandex",orientation: 0,title: "Golden Farm - playcutegames.com",name: ["Golden Farm", "golden-farm"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/golden-farm/250x142.png",
    path: ["/game/golden-farm", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/196268?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video: "https://video-preview.s3.yandex.net/ugc/6f7ca6bbba8531c0e2d63af0ae14a6e6_vmaf-preview-10s-360.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Golden Farm Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `
    If you ever dreamed to escape everyday routine and spend some idle time to play farm in a quiet town or village - this farm life simulator is for you.

    <br><br>
    Imagine yourself starting the day at a beautiful fazenda: stacks of hay, fruitful fields and a blooming garden seem like a pure paradise for a city person like you.
    Your village friends are always there to help at your farmland harvest fruits and vegetables, care and feed the animals and trade on the market. Hey, by the way your family can join the game, too, to have more fun together!
    `,
    tag: ["Casual","Simulation","Economic",],
    hint: `
    <ul>
     <li>Build a village and upgrade farm buildings!</li>
     <li>Grow fruit trees and plants in fields and gardens!</li>
     <li>Breed animals: feed a chicken flock, buy a cow or two and shear the sheep!</li>
     <li>Make hay while the sun shines: take part in seasonal and daily in-game events to gain prizes and become the best farmers!</li>
     <li>Explore diamond mines below the farm! The Golden rush is contagious!</li>
     <li>Produce and trade all sorts of your fazenda goods: from dairy to jewelry!</li>
     <li>Challenge other farmers at the Fair of Achievements!</li>
     <li>Create your own farm community to achieve better results!</li>
     <li>Manage a top grade delivery service: by car, train or even airship!</li>
     <li>Color up your farm! Choose from tons of furniture, decor and flower items to make your funky mansion look trendy!</li>
     <li>Equip an expedition and travel to a tropical island: exotic animals are waiting!</li>
     <li>Run a Zoo and an Amusement park and decorate them to your taste!</li>
     <li>Bring some cute pets from the island and fill your meadows with life!</li>
     </ul>
    `,
    rating:[4.9,350,18],
  }, 
  {date: "2025-02-24",brand: "Yandex",orientation: 0,title: "DesignVille: Merge & Design - Play Unblocked Online",
    name: ["DesignVille: Merge & Design", "designville-merge-and-design"],
    thumb: "https://avatars.mds.yandex.net/get-games/6238841/2a000001951e28d565bdd83c6afac7572ef0/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/6238841/2a000001951e28d565bdd83c6afac7572ef0/pjpg350x209",
    video:"https://video-preview.s3.yandex.net/ugc/b7ebd0af81d2f4ca5892448a553b9ea2/04e00f12-7a8c-45a9-89a8-6de1b23be1ef_vmaf-preview-10s-360.mp4",
    path: ["/game/designville-merge-and-design", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/281150?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play DesignVille: Merge & Design, the ultimate merge puzzle game. Match, merge, and design stunning interiors. Play unblocked now!",
    l_dis:
      `  <p><strong>DesignVille: Merge & Design</strong> is a captivating <strong>merge puzzle game</strong> where you match and merge tiles to unlock tools, craft rare items, and design breathtaking interiors. Fulfill customer orders, upgrade your studio, and bring dream spaces to life!</p>
    
    <h2 class="H22">Why You'll Love DesignVille: Merge & Design</h2>
    <ul>
        <li><strong>Awesome Graphics:</strong> Enjoy stunning visuals and detailed animations that bring your designs to life.</li>
        <li><strong>Easy to Learn Gameplay:</strong> Match, merge, and combine tiles to create rare and unique items.</li>
        <li><strong>Relaxing & Satisfying:</strong> Clean up the game board and rearrange tiles for a stress-free experience.</li>
        <li><strong>Generous Rewards:</strong> Level up and unlock exciting new combinations as you progress.</li>
        <li><strong>Creative Freedom:</strong> Decorate interiors, design gardens, and renovate mansions however you like.</li>
        <li><strong>Engaging Storyline:</strong> Meet fascinating characters, complete design orders, and build your own design studio.</li>
    </ul>
    
    <h2 class="H22">Game Genre</h2>
    <p><strong>DesignVille: Merge & Design</strong> is a blend of <strong>merge puzzle, interior design, and simulation</strong>, making it an engaging and rewarding experience.</p>
    
    <h2 class="H22">How to Play</h2>
    <ul>
        <li><strong>Merge Items:</strong> Combine tiles to unlock new tools and exclusive design materials.</li>
        <li><strong>Complete Orders:</strong> Fulfill customer requests to upgrade your design studio.</li>
        <li><strong>Decorate Interiors:</strong> Choose stylish furniture and create stunning room designs.</li>
        <li><strong>Earn Rewards:</strong> Participate in special in-game events to win exclusive items.</li>
    </ul>
    
    <h2 class="H22">Why Play Merge Games Unblocked?</h2>
    <ul>
        <li><strong>Play Anywhere:</strong> Access <strong>Merge Games Unblocked</strong> at school or work without restrictions.</li>
        <li><strong>No Downloads:</strong> Enjoy instant gameplay directly from your web browser.</li>
        <li><strong>Relaxing Gameplay:</strong> Merge, decorate, and unwind in a beautifully designed virtual world.</li>
    </ul>
    
    <h2 class="H22">More Merge Games to Explore</h2>
    <ul>
        <li><strong>Merge Mansion:</strong> Solve mysteries and renovate a grand mansion.</li>
        <li><strong>Merge Dragons:</strong> Merge objects to restore a magical land.</li>
        <li><strong>Merge Unblocked Games:</strong> Discover more fun and creative merge games online.</li>
    </ul>
    
    <h2 class="H22">Start Playing DesignVille: Merge & Design Now!</h2>
    <p>Unleash your creativity, merge items, and create beautiful interiors. Play <strong>DesignVille: Merge & Design</strong> now and experience the best of <strong>Merge Games Unblocked</strong>!</p>
 `,
    tag: ["Merge","Brain","Block","Puzzle"],
    rating:[4.8,15102,1014],  
  },
  {date: "2025-02-24",brand: "Yandex",orientation: 1,title: "Block Dropping Merge - Play Unblocked Online",
    name: ["Block Dropping Merge", "block-dropping-merge"],
    thumb: "https://avatars.mds.yandex.net/get-games/1890793/2a00000194e408f8cb854a13753047739df6/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/1890793/2a00000194e408f8cb854a13753047739df6/pjpg350x209",
    video:"https://video-preview.s3.yandex.net/ugc/3dee7b0e85f46b5d40c528b579ba3793/5f1d55ac-33aa-47a2-a710-59a29281a422_vmaf-preview-10s-360.mp4",
    path: ["/game/block-dropping-merge", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/295183?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Block Dropping Merge, the ultimate number merging game! Drop, merge, and strategize to achieve the highest score. Enjoy unblocked gameplay now!",
    l_dis:
      `  
       <p><strong>Block Dropping Merge</strong> is a unique yet classic <strong>number merging puzzle game</strong>. It is easy to play but difficult to master, offering a fun and strategic challenge to players of all ages.</p>
    
    <h2 class="H22">What is Block Dropping Merge?</h2>
    <p><strong>Block Dropping Merge</strong> is an engaging <strong>block game</strong> where players drop and merge number blocks to form bigger numbers. The goal is to merge strategically and keep playing as long as possible.</p>
    
    <h2 class="H22">Game Genre</h2>
    <p><strong>Block Dropping Merge</strong> falls under multiple genres, including <strong>puzzle, number merging, and strategy</strong>. It challenges players to think ahead and maximize their score.</p>
    
    <h2 class="H22">How to Play Block Dropping Merge</h2>
    <ul>
        <li><strong>Drop Number Blocks:</strong> Place numbers at the bottom or beside another to merge.</li>
        <li><strong>Plan Your Moves:</strong> Check the next number block and position it wisely.</li>
        <li><strong>Merge & Score:</strong> Combine identical numbers to create larger ones.</li>
        <li><strong>Avoid Running Out of Space:</strong> Keep merging to prevent the grid from filling up.</li>
    </ul>
    
    <h2 class="H22">Exciting Features</h2>
    <ul>
        <li><strong>Next Number Preview:</strong> Helps plan your next move strategically.</li>
        <li><strong>Leaderboard Rankings:</strong> Compete with players worldwide.</li>
        <li><strong>Simple Yet Addictive Gameplay:</strong> A perfect balance of fun and challenge.</li>
        <li><strong>No Downloads Needed:</strong> Play instantly on your browser.</li>
    </ul>
    
    <h2 class="H22">Why Play Merge Unblocked Games?</h2>
    <ul>
        <li><strong>Accessible Anywhere:</strong> Play <strong>Merge Unblocked Games</strong> at school or work without restrictions.</li>
        <li><strong>Quick and Engaging:</strong> Perfect for short or long play sessions.</li>
        <li><strong>Brain Training:</strong> Improves strategic thinking and problem-solving skills.</li>
    </ul>
    
    <h2 class="H22">More Games Like Block Dropping Merge</h2>
    <p>If you enjoy <strong>Block Games</strong>, check out these similar games:</p>
    <ul>
        <li><strong>2048 Merge:</strong> The classic number merge game.</li>
        <li><strong>Hexa Merge:</strong> A hexagonal twist on merging gameplay.</li>
        <li><strong>Merging Games:</strong> Discover a variety of fun merging challenges.</li>
    </ul>
    
    <h2 class="H22">Start Playing Block Dropping Merge Now!</h2>
    <p>Challenge yourself in the ultimate <strong>merging game</strong>. Drop, merge, and climb the leaderboard in <strong>Block Dropping Merge</strong>. Play unblocked now and show your skills!</p>

      `,
    tag: ["Merge","Brain","Block","Puzzle"],
    rating:[4.8,15102,1014],  
  },
  { date: "2025-02-26",brand: "playcutegames",title: "Toca Teens Cozy Couture - Play Dress-Up Online",
    orientation: 0,
    hot:"2x2",
    name: ["Toca Teens Cozy Couture", "toca-teens-cozy-couture"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-teens-cozy-couture/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-teens-cozy-couture/520x295.png",
    path: ["/game/toca-teens-cozy-couture", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/toca-teens-cozy-couture/",
    Walkthrough: "https://www.youtube.com/embed/0_Njd9pFwKQ",
    s_dis:
    "Play Toca Teens Cozy Couture, the ultimate dress-up game! Explore trendy outfits, style your character, and enjoy unblocked gameplay.",
    l_dis:
      ` <p>Welcome to <strong>Toca Teens Cozy Couture</strong>, the ultimate <strong>dress-up game</strong> where fashion and creativity meet! Whether you love cozy winter outfits or trendy casual styles, this game lets you design stunning looks and express your unique style.</p>
    
    <h2 class="H22">What is Toca Teens Cozy Couture?</h2>
    <p><strong>Toca Teens Cozy Couture</strong> is a stylish <strong>fashion dress-up game</strong> inspired by the beloved <strong>Toca Boca</strong> universe. Players can mix and match trendy outfits, accessories, and hairstyles to create their perfect fashion look.</p>
    
    <h2 class="H22">Why Play Toca Teens Cozy Couture?</h2>
    <ul>
        <li><strong>Endless Styling Possibilities:</strong> Experiment with different aesthetics and fashion styles.</li>
        <li><strong>Exciting Fashion Challenges:</strong> Complete styling missions to unlock exclusive outfits.</li>
        <li><strong>Relaxing & Fun Gameplay:</strong> Enjoy a stress-free dress-up experience anytime, anywhere.</li>
        <li><strong>Unblocked Access:</strong> Play <strong>Toca Teens Cozy Couture</strong> on various platforms without restrictions.</li>
    </ul>
    
    <h2 class="H22">How to Play Toca Teens Cozy Couture</h2>
    <ol>
        <li><strong>Choose Your Character:</strong> Pick a stylish teen model to dress up.</li>
        <li><strong>Select Outfits:</strong> Browse a vast wardrobe of trendy and cozy fashion pieces.</li>
        <li><strong>Customize the Look:</strong> Add accessories, change hairstyles, and complete the perfect outfit.</li>
        <li><strong>Complete Challenges:</strong> Take on fun styling missions to earn rewards.</li>
        <li><strong>Showcase Your Style:</strong> Save and share your best looks with friends!</li>
    </ol>
    
    <h2 class="H22">Toca Teens Cozy Couture Unblocked</h2>
    <p>Want to play <strong>Toca Teens Cozy Couture Unblocked</strong>? Enjoy the game anywhere, anytime without restrictions! Whether you're at school or work, experience uninterrupted fashion fun.</p>
    
    <h2 class="H22">More Dress-Up Games Like Toca Boca</h2>
    <p>If you love <strong>Toca Boca</strong> games, try these similar <strong>dress-up games</strong>:</p>
    <ul>
        <li><strong>Gacha Life:</strong> Create unique characters and design stylish outfits.</li>
        <li><strong>Fashion Star:</strong> A glamorous game for aspiring fashionistas.</li>
        <li><strong>Unblocked Games:</strong> Discover more fun and free games online.</li>
    </ul>
    
    <h2 class="H22">Game Genre</h2>
    <p><strong>Toca Teens Cozy Couture</strong> falls under multiple genres, including <strong>dress-up, simulation, and casual games</strong>. It’s a fun and interactive way to explore fashion trends and express creativity.</p>

    <h2 class="H22">Start Playing Toca Teens Cozy Couture Now!</h2>
    <p>Get ready to unleash your inner fashion designer! Play <strong>Toca Teens Cozy Couture Unblocked</strong> and dive into the world of stylish outfits and fun challenges.</p>
 `,
      tag: ["Toca boca"," Winter","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {date: "2025-02-24",brand: "Yandex",orientation: 1,title: "Mansion Tale: Merge Secrets - Play Unblocked Merging Game Online",
    name: ["Mansion Tale: Merge Secrets", "mansion-tale-merge-secrets"],
    thumb: "https://avatars.mds.yandex.net/get-games/11385414/2a0000019528e36fd358da738bd50b805286/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/11385414/2a0000019528e36fd358da738bd50b805286/pjpg350x209",
    video:"https://video-preview.s3.yandex.net/ugc/569088801fda1ee54c6c2b68953683b5/f1a1c704-066f-4396-a6e3-aa093cbe07ff_vmaf-preview-10s-360.mp4",
    path: ["/game/mansion-tale-merge-secrets", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/296216?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Mansion Tale: Merge Secrets, the ultimate merging game where you restore a grand mansion, uncover mysteries, and explore hidden secrets. Play unblocked now!",
    l_dis:
      ` 
      <p>Step into the enchanting world of <strong>Mansion Tale: Merge Secrets</strong>, where you restore a grand mansion, merge items, and uncover deep family secrets! Help Ashley bring the Grayson estate back to life while solving thrilling mysteries.</p>
    
    <h2 class="H22">What is Mansion Tale: Merge Secrets?</h2>
    <p><strong>Mansion Tale: Merge Secrets</strong> is a unique <strong>merging game</strong> that blends home restoration with puzzle-solving. Merge various objects, renovate rooms, and piece together hidden stories to reveal the truth about the Grayson family.</p>
    
    <h2 class="H22">How to Play Mansion Tale</h2>
    <ul>
        <li><strong>Merge Objects:</strong> Combine items to create new tools and furniture for mansion restoration.</li>
        <li><strong>Decorate & Restore:</strong> Upgrade and design rooms to give the mansion a fresh new look.</li>
        <li><strong>Uncover Secrets:</strong> Solve puzzles and follow the intriguing storyline to reveal hidden mysteries.</li>
        <li><strong>Complete Exciting Quests:</strong> Help Ashley and her friends overcome challenges and learn more about the mansion’s past.</li>
        <li><strong>Collect Artifacts:</strong> Discover and restore ancient relics that hold significant meaning.</li>
    </ul>
    
    <h2 class="H22">Features of Mansion Tale: Merge Secrets</h2>
    <ul>
        <li><strong>Addictive Merging System:</strong> Merge items to unlock powerful tools and progress in the game.</li>
        <li><strong>Engaging Storyline:</strong> Follow Ashley’s journey as she restores the mansion and unravels family secrets.</li>
        <li><strong>Beautiful Graphics:</strong> Enjoy stunning animations and immersive visuals.</li>
        <li><strong>Strategic Gameplay:</strong> Plan your merges wisely to make the most of your resources.</li>
        <li><strong>Unblocked Version:</strong> Play <strong>Mansion Tale Unblocked</strong> anywhere, no downloads required.</li>
    </ul>
    
    <h2 class="H22">Play Mansion Tale: Merge Secrets Unblocked</h2>
    <p>Looking for a fun and strategic <strong>merging game</strong>? <strong>Mansion Tale Unblocked</strong> is the perfect game for puzzle lovers and home design enthusiasts. Play online now and bring the mansion back to life!</p>
    
    <h2 class="H22">More Games Like Mansion Tale</h2>
    <p>If you enjoy <strong>merging games</strong>, check out these similar titles:</p>
    <ul>
        <li><strong>Merge Mansion</strong> - Solve mysteries while restoring an old estate.</li>
        <li><strong>EverMerge</strong> - A fantasy kingdom-building game with merging mechanics.</li>
        <li><strong>Homescapes</strong> - A fun home renovation game with match-3 puzzles.</li>
    </ul>
    
    <h2 class="H22">Start Your Mansion Tale Adventure Now!</h2>
    <p>Uncover secrets, restore a magnificent mansion, and enjoy endless merging fun in <strong>Mansion Tale: Merge Secrets</strong>. Play now and dive into an engaging puzzle adventure!</p>

    `,
    tag: ["Merge","Brain","Puzzle"],
    rating:[4.8,15102,1014],  
  },
  {date: "2025-02-24",brand: "Yandex",orientation: 0,title: "Castle Craft - Play Unblocked Merging Game Online",
    name: ["Castle Craft", "castle-craft"],
    thumb: "https://avatars.mds.yandex.net/get-games/2977039/2a00000194bb9c4742d415112b6ef3c3f2ff/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/2977039/2a00000194bb9c4742d415112b6ef3c3f2ff/pjpg350x209",
    video:"https://video-preview.s3.yandex.net/ugc/922ad19cf33fdd43fa194cc629944c62/861b4c73-014a-4209-97eb-5f843ea4b035_vmaf-preview-10s-360.mp4",
    path: ["/game/castle-craft", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/335914?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Castle Craft, the ultimate merging game where you build a medieval kingdom, unlock secrets, and explore hidden territories. Play unblocked now!",
    l_dis:
      ` <p>Step into the mystical world of <strong>Castle Craft</strong>, where you merge resources, unlock hidden lands, and uncover the secrets of time! Transform a desolate land into a grand medieval kingdom and embark on an unforgettable journey.</p>
    
    <h2 class="H22">What is Castle Craft?</h2>
    <p><strong>Castle Craft</strong> is a unique <strong>merging game</strong> that combines kingdom-building with puzzle-solving. Use merging mechanics to grow resources, construct buildings, and unlock hidden territories. Follow the traces of your lost family through time and reshape history!</p>
    
    <h2 class="H22">How to Play Castle Craft</h2>
    <ul>
        <li><strong>Merge Resources:</strong> Combine wood, stone, and crops to create powerful tools and magnificent structures.</li>
        <li><strong>Unlock New Lands:</strong> Use mystical keys to dispel the fog and reveal hidden territories.</li>
        <li><strong>Build Your Kingdom:</strong> Evolve from a small village into a grand medieval empire.</li>
        <li><strong>Complete Heroic Quests:</strong> Interact with historical figures and solve puzzles to uncover ancient secrets.</li>
        <li><strong>Host Grand Feasts:</strong> Celebrate events and invite other players to your flourishing kingdom.</li>
    </ul>
    
    <h2 class="H22">Features of Castle Craft</h2>
    <ul>
        <li><strong>Dynamic Merging System:</strong> Merge items to craft new tools and build structures.</li>
        <li><strong>Time Travel Adventure:</strong> Explore different eras and uncover mysteries of the past.</li>
        <li><strong>Expansive World:</strong> Discover new lands and expand your kingdom.</li>
        <li><strong>Strategic Gameplay:</strong> Plan your resources wisely to maximize growth.</li>
        <li><strong>Unblocked Version:</strong> Play <strong>Castle Craft Unblocked</strong> from anywhere, no downloads required.</li>
    </ul>
    
    <h2 class="H22">Play Castle Craft Unblocked</h2>
    <p>Looking for a fun and strategic <strong>merging game</strong>? <strong>Castle Craft Unblocked</strong> is the perfect game for kingdom-building enthusiasts. Play online now and build your medieval empire!</p>
    
    <h2 class="H22">More Games Like Castle Craft</h2>
    <p>If you enjoy <strong>merging games</strong>, check out these similar titles:</p>
    <ul>
        <li><strong>Merge Dragons</strong> - Merge items to create magical creatures and landscapes.</li>
        <li><strong>EverMerge</strong> - A kingdom-building game with fun merging mechanics.</li>
        <li><strong>Townscaper</strong> - A relaxing city-building puzzle game.</li>
    </ul>
    
    <h2 class="H22">Start Your Castle Craft Adventure Now!</h2>
    <p>Embark on an unforgettable journey in <strong>Castle Craft</strong>. Merge, build, and explore to create the ultimate kingdom. Play now and shape your legacy!</p>
 `,
    tag: ["Merge","Brain","Puzzle"],
    rating:[4.8,15102,1014],  
  },
  {date: "2025-02-24",brand: "Yandex",orientation: 1,title: "Words from Words - Play Unblocked Word Puzzle Game Online",
    name: ["Words from Words", "words-from-words"],
    thumb: "https://avatars.mds.yandex.net/get-games/6238841/2a00000193472afcf952324e87628c255931/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/6238841/2a00000193472afcf952324e87628c255931/pjpg350x209",
    video:"https://video-preview.s3.yandex.net/ugc/7de807d7f5dcb3697eade4d3b8f0f301_vmaf-preview-10s-360.mp4",
    path: ["/game/words-from-words", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/99195?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Words from Words, the ultimate word puzzle game! Create as many words as possible from given letters and test your vocabulary skills in this unblocked game.",
    l_dis:
      `<p>Welcome to <strong>Words from Words</strong>, the exciting puzzle game where your goal is to create as many different words as possible from a given set of letters! Challenge your vocabulary and test your word-building skills in this fun and engaging unblocked game.</p>
    
    <h2 class="H22">What is Words from Words?</h2>
    <p><strong>Words from Words</strong> is a word puzzle game where you need to form multiple words from a single word or set of letters. For example, you can create 29 words from "example," 71 words from "maverick," and an astonishing 142 words from "chalkboard." Can you find them all?</p>
    
    <h2 class="H22">How to Play Words from Words</h2>
    <ul>
        <li><strong>Select Letters:</strong> Click on the letters to create different words.</li>
        <li><strong>Find as Many Words as Possible:</strong> The more words you create, the higher your score!</li>
        <li><strong>Use Only Common Nouns:</strong> The game only accepts commonly used nouns.</li>
        <li><strong>Click for Definitions:</strong> Click on a word to see its meaning and expand your vocabulary.</li>
    </ul>
    
    <h2 class="H22">Features of Words from Words</h2>
    <ul>
        <li><strong>Challenging Word Puzzles:</strong> Test your vocabulary with hundreds of levels.</li>
        <li><strong>Brain-Boosting Gameplay:</strong> Improve your word-building skills.</li>
        <li><strong>Simple Yet Addictive:</strong> Easy to play, hard to master!</li>
        <li><strong>Unblocked Version:</strong> Play <strong>Words from Words Unblocked</strong> anytime, anywhere.</li>
    </ul>
    
    <h2 class="H22">Play Words from Words Unblocked</h2>
    <p>If you're looking for a fun and educational way to improve your vocabulary, <strong>Words from Words Unblocked</strong> is the perfect game for you. Play now and see how many words you can find!</p>
    
    <h2 class="H22">More Games Like Words from Words</h2>
    <p>Love word puzzles? Try these similar games:</p>
    <ul>
        <li><strong>Scrabble Online</strong> - A classic word-building challenge.</li>
        <li><strong>Crossword Puzzles</strong> - Solve daily crossword puzzles.</li>
        <li><strong>Word Connect</strong> - A fun swipe-and-match word game.</li>
    </ul>
    
    <h2 class="H22">Start Playing Words from Words Now!</h2>
    <p>Put your vocabulary skills to the test with <strong>Words from Words</strong>. Play now and see how many words you can create!</p>
 `,
    tag: ["Crossword","Brain","Puzzle"],
    rating:[4.8,15102,1014],  
  },
  {date: "2025-02-24",brand: "Yandex",orientation: 1,title: "Words Crossword Puzzle - Play Unblocked Crossword Game Online",
    name: ["Words Crossword Puzzle", "words-crossword-puzzle"],
    thumb: "https://avatars.mds.yandex.net/get-games/6300668/2a0000018fd9041df4841ae00859e4ce977b/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/6300668/2a0000018fd9041df4841ae00859e4ce977b/pjpg350x209",
    // video:"https://video-preview.s3.yandex.net/ugc/4aff99ac866329226366e17bd8b8423d_vmaf-preview-10s-360.mp4",
    path: ["/game/words-crossword-puzzle", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/340683?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Enjoy the best word puzzles game! Solve crosswords, find hidden words, and test your vocabulary skills in this fun unblocked game.",
    l_dis:
      `
     <p>Dive into the world of <strong>word puzzles</strong> with this exciting and challenging game! Your task is to find all the hidden words in a crossword puzzle using the provided letters. With hundreds of levels, increasing difficulty, and endless words to discover, this game will put your vocabulary and puzzle-solving skills to the test!</p>
    <h2 class="H22">What is Word Puzzles?</h2>
    <p><strong>Word Puzzles</strong> is an engaging word game where you must form words using a given set of letters to complete a crossword puzzle. The more words you find, the higher your score!</p>
    <h2 class="H22">How to Play Word Puzzles</h2>
    <ul>
        <li><strong>Form Words:</strong> Use the provided letters to create words that fit into the crossword.</li>
        <li><strong>Swipe to Connect:</strong> Drag your finger or mouse to connect letters and create words.</li>
        <li><strong>Earn Rewards:</strong> Gain coins by completing levels and finding bonus words.</li>
        <li><strong>Use Hints:</strong> If you get stuck, use hints to reveal letters, but use them wisely!</li>
        <li><strong>Advance to New Levels:</strong> Complete each crossword to unlock new challenging puzzles.</li>
    </ul>
    <h2 class="H22">Features of Word Puzzles</h2>
    <ul>
        <li><strong>Hundreds of Levels:</strong> Play through countless levels of increasing difficulty.</li>
        <li><strong>Brain-Training Fun:</strong> Enhance your vocabulary and problem-solving skills.</li>
        <li><strong>Simple Yet Addictive Gameplay:</strong> Easy to play but challenging to master!</li>
        <li><strong>Unblocked Version:</strong> Play <strong>Word Puzzles Unblocked</strong> anywhere, anytime.</li>
    </ul>
    <h2 class="H22">Play Word Puzzles Unblocked</h2>
    <p>Looking for a fun and challenging way to improve your vocabulary? <strong>Word Puzzles Unblocked</strong> is the perfect game for word lovers and puzzle enthusiasts. Start playing now and enjoy endless word-finding fun!</p>
    <h2 class="H22">More Games Like Word Puzzles</h2>
    <p>If you love <strong>Word Puzzles</strong>, check out these other popular games:</p>
    <ul>
        <li><strong>Scrabble Online</strong> - A classic word-building game.</li>
        <li><strong>Crossword Puzzles</strong> - Solve challenging crosswords daily.</li>
        <li><strong>Word Connect</strong> - A fun swipe-and-match word game.</li>
    </ul>
    <h2 class="H22">Start Playing Word Puzzles Now!</h2>
    <p>Challenge yourself with <strong>Word Puzzles</strong> and test your vocabulary skills! Play now and see how many words you can find in this exciting crossword game!</p>

      `,
    tag: ["Crossword","Puzzle"],
    rating:[4.8,15102,1014],  
  },
  {date: "2025-02-24",brand: "Yandex",orientation: 1,title: "Hedgies - Play Unblocked Farming Game Online",
    name: ["Hedgies", "hedgies"],
    thumb: "https://avatars.mds.yandex.net/get-games/3006389/2a00000193bfd3dad4c312626b2b73c82a0f/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/3006389/2a00000193bfd3dad4c312626b2b73c82a0f/pjpg350x209",
    video:"https://video-preview.s3.yandex.net/ugc/4aff99ac866329226366e17bd8b8423d_vmaf-preview-10s-360.mp4",
    path: ["/game/hedgies", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/96967?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Hedgies, the ultimate farming simulation game! Grow crops, cook meals, fish, and compete in fun challenges. Play Hedgies Unblocked now!",
    l_dis:
      `
      <p><strong>Hedgies</strong> is an exciting farming simulation game where you take control of a Hedgehog and transform an abandoned land into a thriving farm. Discover the joys of farming, cooking, crafting, and socializing with other Hedgies!</p>
    <h2 class="H22">What is Hedgies?</h2>
    <p>In <strong>Hedgies</strong>, you play as an adorable Hedgehog capable of running a farm, cooking delicious meals, and even participating in fun competitions. Make friends, help neighbors, and turn your farm into a paradise!</p>
    <h2 class="H22">How to Play Hedgies</h2>
    <ul>
        <li><strong>Grow Crops:</strong> Cultivate fruits and vegetables to expand your farm.</li>
        <li><strong>Cook Delicious Meals:</strong> Learn and master new recipes.</li>
        <li><strong>Make Friends:</strong> Interact with other Hedgies and help them with tasks.</li>
        <li><strong>Fishing Adventures:</strong> Become a skilled fisherman and catch rare fish.</li>
        <li><strong>Compete in Events:</strong> Join the ‘Battle of Tailors’ and win unique prizes.</li>
        <li><strong>Customize Your Farm:</strong> Decorate and design your farm to reflect your style.</li>
    </ul>
    <h2 class="H22">Features of Hedgies</h2>
    <ul>
        <li><strong>Engaging Farming Gameplay:</strong> Plant, harvest, and trade goods.</li>
        <li><strong>Multiplayer Social Features:</strong> Visit friends’ farms and celebrate events together.</li>
        <li><strong>Exciting Competitions:</strong> Participate in tailoring contests and earn rewards.</li>
        <li><strong>Unblocked Version Available:</strong> Play <strong>Hedgies Unblocked</strong> anywhere.</li>
        <li><strong>Beautiful Graphics:</strong> Enjoy vibrant, colorful visuals and adorable characters.</li>
    </ul>
    <h2 class="H22">Play Hedgies Unblocked</h2>
    <p>Looking for a relaxing yet engaging farm game? <strong>Hedgies Unblocked</strong> allows you to play freely without restrictions. Join the fun today!</p>
    <h2 class="H22">More Games Like Hedgies</h2>
    <p>If you enjoy <strong>Hedgies</strong>, check out these other fun games:</p>
    <ul>
        <li><strong>Basketball Stars GitHub Io</strong> - A fast-paced multiplayer basketball game.</li>
        <li><strong>FarmVille</strong> - A classic farming simulation game.</li>
        <li><strong>Stardew Valley</strong> - A deep and immersive farming RPG.</li>
    </ul>
    <h2 class="H22">Start Playing Hedgies Now!</h2>
    <p>Jump into <strong>Hedgies</strong> and begin your adventure today! Other Hedgies are waiting to meet you and explore the farming world together. Start playing now!</p>

      `,
    tag: ["Economy","Simulation","Multiplayer"],
    rating:[4.8,15102,1014],  
  },
  {date: "2025-02-20",brand: "Yandex",orientation: 1,title: "Camouflage and Sniper - Play Unblocked Sniper Shooting Game Online",
    name: ["Camouflage and Sniper", "camouflage-and-sniper"],
    thumb: "https://avatars.mds.yandex.net/get-games/12797757/2a0000019302efb49685fe369560b4bdde3d/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/12797757/2a0000019302efb49685fe369560b4bdde3d/pjpg350x209",
    video:"https://video-preview.s3.yandex.net/ugc/1442d89b8d0bb8bdb584eb5dc464864b_vmaf-preview-10s-360.mp4",
    path: ["/game/camouflage-and-sniper", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/277409?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Camouflage and Sniper, the ultimate sniper game where you find hidden enemies and eliminate them with precision. Enjoy this thrilling unblocked shooting game now!",
    l_dis:
      ` 
         <p>Get ready to test your precision and observation skills in <strong>Camouflage and Sniper</strong>, one of the most exciting <strong>sniper games</strong>! Your mission is to find and eliminate skillfully hidden enemies before they strike. Stay focused, take aim, and become the ultimate sniper!</p>

    <h2 class="H22">🔍 What is Camouflage and Sniper?</h2>
    <p><strong>Camouflage and Sniper</strong> is a challenging <strong>shooting game</strong> that requires sharp eyesight and quick reflexes. Your enemies are disguised and hidden within the environment, making it difficult to spot them. Only the best snipers can track them down and eliminate them before it's too late!</p>

    <h2 class="H22">🎮 How to Play Camouflage and Sniper</h2>
    <ul>
        <li><strong>Search for Enemies:</strong> Look carefully to spot hidden enemies.</li>
        <li><strong>Aim & Shoot:</strong> Use your mouse to aim at the enemy and release the button to fire.</li>
        <li><strong>Eliminate Targets:</strong> Take out all hidden enemies to progress.</li>
        <li><strong>Upgrade Your Camp:</strong> Earn rewards and improve your base.</li>
    </ul>

    <h2 class="H22">✨ Features of Camouflage and Sniper</h2>
    <ul>
        <li><strong>Realistic Sniper Gameplay:</strong> Experience thrilling shooting mechanics.</li>
        <li><strong>Challenging Hidden Targets:</strong> Enemies are camouflaged in the environment.</li>
        <li><strong>Strategic Shooting:</strong> Think before you shoot—some enemies are harder to find!</li>
        <li><strong>Upgrade System:</strong> Improve your sniper gear and camp.</li>
        <li><strong>Unblocked Game:</strong> Play <strong>Camouflage and Sniper unblocked</strong> anywhere, no downloads needed!</li>
    </ul>

    <h2 class="H22">🆕 What’s New in Camouflage and Sniper?</h2>
    <p>The latest update includes new challenging levels, improved enemy AI, and enhanced sniper rifle mechanics for a more immersive experience. Get ready for the ultimate sniper challenge!</p>

    <h2 class="H22">🚀 Play Camouflage and Sniper Unblocked</h2>
    <p>Looking for an intense <strong>unblocked shooting game</strong>? <strong>Camouflage and Sniper</strong> is the perfect choice for sniping enthusiasts who love a challenge. Play online now and test your precision!</p>

    <h2 class="H22">🏆 Become the Ultimate Sniper!</h2>
    <p>Do you have what it takes to find and eliminate all hidden threats? Play <strong>Camouflage and Sniper</strong> today and prove your skills in the most exciting sniper game! 🎯🔫</p>

      `,
    tag: ["Shooting","Action"],
    rating:[4.8,15102,1014],  
  },
  {date: "2025-02-20",brand: "Yandex",orientation: 1,title: "Mega Ramp Stunt - Play Unblocked Stunt Car Game Online",
    name: ["Mega Ramp Stunt", "mega-ramp-stunt"],
    thumb: "https://avatars.mds.yandex.net/get-games/11374519/2a00000190829c19e3182520ba8880a26905/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/11374519/2a00000190829c19e3182520ba8880a26905/pjpg350x209",
    video:"https://video-preview.s3.yandex.net/ugc/c0984e01d0d8a4c05e7db1cbaf41c6ce_vmaf-preview-10s-360.mp4",
    path: ["/game/mega-ramp-stunt", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/289200?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Mega Ramp Stunt, the ultimate stunt-driving experience! Perform insane car stunts on impossible tracks and challenge yourself in this thrilling car game. Play unblocked now!",
    l_dis:
      ` 
         <p>Get ready for heart-pounding action in <strong>Mega Ramp Stunt</strong>, one of the most thrilling <strong>car games</strong> designed for adrenaline junkies! Perform breathtaking stunts, race through impossible tracks, and push the limits of your driving skills. Take control of high-performance vehicles and conquer extreme stunt challenges!</p>

    <h2 class="H22">🏎️ What is Mega Ramp Stunt?</h2>
    <p><strong>Mega Ramp Stunt</strong> is an action-packed <strong>stunt game</strong> that challenges players to perform daring tricks across a variety of ramps and obstacle-filled tracks. Whether you're jumping off massive ramps or flipping through the air, every level is designed to test your driving precision and skill.</p>

    <h2 class="H22">🎮 How to Play Mega Ramp Stunt</h2>
    <ul>
        <li><strong>Perform Stunts:</strong> Execute insane tricks to complete levels.</li>
        <li><strong>Master the Controls:</strong> Use WASD or arrow keys for movement.</li>
        <li><strong>Use Nitro Boost:</strong> Press Shift for acceleration.</li>
        <li><strong>Flip & Recover:</strong> Press R to reset your car if you crash.</li>
        <li><strong>Switch Camera Views:</strong> Adjust angles for the best driving experience.</li>
        <li><strong>Mobile Controls:</strong> Swipe left or right to maneuver your car.</li>
    </ul>

    <h2 class="H22">✨ Features of Mega Ramp Stunt</h2>
    <ul>
        <li><strong>Luxury Racing Cars:</strong> Drive powerful and stylish vehicles.</li>
        <li><strong>Extreme Stunt Tracks:</strong> Challenge yourself on impossible ramps.</li>
        <li><strong>Realistic Driving Physics:</strong> Experience smooth and dynamic car handling.</li>
        <li><strong>Multiple Camera Angles:</strong> Adjust perspectives for optimal gameplay.</li>
        <li><strong>Unblocked Game:</strong> Play <strong>Mega Ramp Stunt unblocked</strong> anywhere, anytime!</li>
    </ul>

    <h2 class="H22">🆕 What’s New in Mega Ramp Stunt?</h2>
    <p>The latest update brings new levels, enhanced car physics, and better controls for a smoother driving experience. Get ready for even more exhilarating stunts and challenges!</p>

    <h2 class="H22">🚀 Play Mega Ramp Stunt Unblocked</h2>
    <p>Looking for an action-packed <strong>unblocked stunt game</strong>? <strong>Mega Ramp Stunt</strong> is available online with no downloads required. Jump into the game and start performing insane tricks now!</p>

    <h2 class="H22">🏆 Take on the Stunt Challenge!</h2>
    <p>Are you ready to push your stunt-driving skills to the limit? Play <strong>Mega Ramp Stunt</strong> today and become the ultimate stunt master! 🚗💨</p>

      `,
    tag: ["Car","Race","Stunt"],
    rating:[4.8,15102,1014],  
  },
  {date: "2025-02-20",brand: "Yandex",orientation: 1,title: "Goods Sort: Matching Items by Shelves - Play Unblocked Sorting Game",
    name: ["Goods Sort: Matching Items by Shelves", "goods-sort-matching-items-by-shelves"],
    thumb: "https://avatars.mds.yandex.net/get-games/6300668/2a00000191b845767903a187cef96360569e/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/6300668/2a00000191b845767903a187cef96360569e/pjpg350x209",
    video:"https://video-preview.s3.yandex.net/ugc/889eb536ac479b2b39022ac32c2331fb/620bbf37-2c95-4354-b1e4-c5631620d545_vmaf-preview-10s-360.mp4",
    path: ["/game/goods-sort-matching-items-by-shelves", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/297752?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Goods Sort: Matching Items by Shelves, a fun and relaxing matching game. Sort and organize items, merge 3 identical objects, and complete levels. Play unblocked now!",
    l_dis:
      ` 
        <p>Love organizing? <strong>Goods Sort: Matching Items by Shelves</strong> is a fun and relaxing <strong>matching game</strong> where you sort and arrange items on shelves. Drag and drop objects, match three identical items, and clear the shelves to progress. Enjoy the soothing gameplay and create a perfectly organized space!</p>

    <h2 class="H22">🛒 What is Goods Sort: Matching Items by Shelves?</h2>
    <p><strong>Goods Sort</strong> is an engaging <strong>sort game</strong> where players tidy up spaces by placing identical items together. Whether it’s a refrigerator, bookshelf, or closet, your goal is to match three of the same items and remove them from the shelves. It’s the perfect game for those who love organizing and puzzle-solving!</p>

    <h2 class="H22">🎮 How to Play Goods Sort: Matching Items by Shelves</h2>
    <ul>
        <li><strong>Drag and Drop:</strong> Move items onto shelves.</li>
        <li><strong>Match Three:</strong> Place three identical items together to merge them.</li>
        <li><strong>Clear the Shelves:</strong> Complete levels by removing all items.</li>
        <li><strong>Unlock New Items:</strong> Progress through levels to discover more fun objects!</li>
    </ul>

    <h2 class="H22">✨ Features of Goods Sort: Matching Items by Shelves</h2>
    <ul>
        <li><strong>Relaxing Gameplay:</strong> A stress-free sorting and organizing experience.</li>
        <li><strong>Simple & Fun Controls:</strong> Easy drag-and-drop mechanics.</li>
        <li><strong>Beautiful Visuals:</strong> Aesthetic graphics for a cozy atmosphere.</li>
        <li><strong>Challenging Levels:</strong> Progress through exciting puzzles.</li>
        <li><strong>Unblocked Game:</strong> Play <strong>Goods Sort unblocked</strong> anytime, anywhere!</li>
    </ul>

    <h2 class="H22">🆕 What’s New in Goods Sort?</h2>
    <p>The latest update features smoother gameplay, new items to sort, and enhanced graphics. Dive into a more polished and engaging sorting experience today!</p>

    <h2 class="H22">🚀 Play Goods Sort: Matching Items by Shelves Unblocked</h2>
    <p>Looking for a fun and relaxing <strong>unblocked sorting game</strong>? <strong>Goods Sort</strong> is available online without downloads or registration. Organize, match, and clear the shelves effortlessly!</p>

    <h2 class="H22">🏆 Start Sorting and Enjoy the Fun!</h2>
    <p>Ready to put your organizing skills to the test? Play <strong>Goods Sort: Matching Items by Shelves</strong> now and experience the joy of sorting! 🧸🛍️</p>

      `,
    tag: ["Sort","Merge","Puzzle"],
    rating:[4.8,15102,1014],  
  },
  {date: "2025-02-19",brand: "Yandex",orientation: 0,title: "2248 Merge Puzzle - Play Unblocked Number Merge Game",
    name: ["2248 Merge Puzzle", "2248-merge-puzzle"],
    thumb: "https://avatars.mds.yandex.net/get-games/12797757/2a000001900ef7e972f92f8a24cd046c974e/cover1",
    thumb_big: "https://avatars.mds.yandex.net/get-games/12797757/2a000001900ef7e972f92f8a24cd046c974e/cover1",
    video:"https://video-preview.s3.yandex.net/ugc/c4487e0d8cfaa808e4b055d7ddbd2d5d/95ea6732-eed1-409f-b173-af95aacaad96_vmaf-preview-10s-360.mp4",
    path: ["/game/2248-merge-puzzle", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/347406?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play 2248 Merge Puzzle, a fun and challenging number merge game! Combine blocks to reach higher numbers and test your puzzle-solving skills. Play unblocked now!",
    l_dis:
      ` 
       <p>Love number puzzles? <strong>2248 Merge Puzzle</strong> is a fun and addictive <strong>merge game</strong> where you combine number blocks to reach the highest possible value. Test your brain, strategize your moves, and aim for the infinity tile in this engaging <strong>puzzle game</strong>. If you enjoy logic-based challenges, this game is perfect for you!</p>

    <h2 class="H22">🔍 What is 2248 Merge Puzzle?</h2>
    <p><strong>2248 Merge Puzzle</strong> is a simple yet highly addictive <strong>puzzle game</strong> where players merge identical number blocks to form larger numbers. Start with small values and keep merging to reach 1024, 2048, 4096, and beyond! Can you reach the elusive infinity tile?</p>

    <h2 class="H22">🎮 How to Play 2248 Merge Puzzle</h2>
    <ul>
        <li><strong>Swipe to Merge:</strong> Connect and merge identical number blocks.</li>
        <li><strong>Plan Your Moves:</strong> Strategically place numbers to maximize merging.</li>
        <li><strong>Reach Higher Numbers:</strong> Merge up to 1024, 2048, 4096, and beyond.</li>
        <li><strong>Aim for Infinity:</strong> Challenge yourself to reach the highest tile possible.</li>
        <li><strong>Compete Globally:</strong> Climb the leaderboard and compare scores with players worldwide.</li>
    </ul>

    <h2 class="H22">✨ Features of 2248 Merge Puzzle</h2>
    <ul>
        <li><strong>Minimalistic & Elegant Design:</strong> A clean and visually appealing interface.</li>
        <li><strong>Smooth & Simple Controls:</strong> Swipe and merge with ease.</li>
        <li><strong>Easy to Learn, Challenging to Master:</strong> Suitable for beginners and experts.</li>
        <li><strong>Automatic Save:</strong> Continue your game anytime.</li>
        <li><strong>Global Leaderboards:</strong> Compete with players from around the world.</li>
        <li><strong>No Time Limits:</strong> Play at your own pace with no pressure.</li>
        <li><strong>Unblocked Game:</strong> Play <strong>2248 Merge Puzzle unblocked</strong> on any device, anywhere!</li>
    </ul>

    <h2 class="H22">🆕 What’s New in 2248 Merge Puzzle?</h2>
    <p>The latest update brings improved performance, smoother gameplay, and new challenges. Experience an even more exciting number-merge adventure!</p>

    <h2 class="H22">🚀 Play 2248 Merge Puzzle Unblocked</h2>
    <p>Looking for a fun and brain-teasing <strong>unblocked puzzle game</strong>? <strong>2248 Merge Puzzle</strong> is accessible anytime without downloads or registration. Play instantly and enjoy endless number-merging fun!</p>

    <h2 class="H22">🏆 Start Merging and Climb to Infinity!</h2>
    <p>Ready to test your number-merging skills? Play <strong>2248 Merge Puzzle</strong> today and see how far you can go! 🧩🔢</p>

      `,
    tag: ["Merge","Puzzle"],
    rating:[4.8,15102,1014],  
  },
  {date: "2025-01-28",brand: "Yandex",orientation: 1,title: "Race Survival: Arena King - Play Unblocked Arcade Racing Game",
    name: ["Race Survival: Arena King", "race-survival-arena-king"],
    thumb: "https://avatars.mds.yandex.net/get-games/11385414/2a0000018d6530b20c984e8b962260096fe0/cover1",
    thumb_big: "https://avatars.mds.yandex.net/get-games/11385414/2a0000018d6530b20c984e8b962260096fe0/cover1",
    video:"https://video-preview.s3.yandex.net/ugc/8e9a9b984d60ecdf1fe8f0af65d24e70_vmaf-preview-10s-360.mp4",
    path: ["/game/race-survival-arena-king", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/241733?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Race Survival: Arena King is an intense arcade game where you race on hexagon arenas, eliminate opponents, and survive till the end! Play unblocked now.",
    l_dis:
      ` 
      <p>Get ready for an adrenaline-fueled challenge in <strong>Race Survival: Arena King</strong>, a thrilling <strong>arcade game</strong> where survival is just as important as speed. Compete in a dynamic arena made of hexagons that disappear as you drive over them. Knock opponents into water, lava, or acid while avoiding elimination yourself. Can you be the last racer standing?</p>

    <h2 class="H22">🚗 What is Race Survival: Arena King?</h2>
    <p><strong>Race Survival: Arena King</strong> is an exciting <strong>unblocked race game</strong> that combines intense racing with survival mechanics. Drive across a crumbling hexagon arena, perform tricks, collect coins, and take down rivals to claim the crown. Only the most skilled racers will dominate the arena!</p>

    <h2 class="H22">🎮 How to Play Race Survival: Arena King</h2>
    <ul>
        <li><strong>Steer and Jump:</strong> Control your car with precision to stay on solid ground.</li>
        <li><strong>Knock Out Opponents:</strong> Ram into rival cars and push them into hazards like lava, water, or acid.</li>
        <li><strong>Survive the Arena:</strong> Avoid falling into the gaps as hexagons disappear beneath your wheels.</li>
        <li><strong>Collect Coins and Crowns:</strong> Earn rewards for eliminating opponents and performing stunts.</li>
        <li><strong>Climb the Ranks:</strong> The better you perform, the tougher your competition gets!</li>
    </ul>

    <h2 class="H22">🔥 Key Features of Race Survival: Arena King</h2>
    <ul>
        <li><strong>Fast-Paced Racing:</strong> Experience high-speed, action-packed gameplay.</li>
        <li><strong>Dynamic Arenas:</strong> Hexagon platforms disappear, making each race unpredictable.</li>
        <li><strong>Survival Mechanics:</strong> Eliminate opponents while avoiding your own downfall.</li>
        <li><strong>Exciting Upgrades:</strong> Collect coins to unlock new cars and customization options.</li>
        <li><strong>Unblocked Arcade Game:</strong> Play <strong>Race Survival: Arena King unblocked</strong> anytime, anywhere.</li>
    </ul>

    <h2 class="H22">🆕 What’s New in Race Survival: Arena King?</h2>
    <p>The latest update includes new car skins, improved controls, and even more challenging arenas! Jump into the action and experience the best version of the game yet.</p>

    <h2 class="H22">🚀 Play Race Survival: Arena King Unblocked</h2>
    <p>Looking for a thrilling <strong>unblocked race game</strong>? <strong>Race Survival: Arena King</strong> is accessible on any device without downloads or registration. Get into the action and prove your skills in this high-stakes arena!</p>

    <h2 class="H22">🏁 Join the Ultimate Racing Survival Challenge!</h2>
    <p>Are you ready to dominate the arena? Play <strong>Race Survival: Arena King</strong> now and claim your place as the king of survival racing! 🏆🔥</p>

      `,
      tag: ["Race","Survival","Match"],
    rating:[4.8,15102,1014],  
  },
  {date: "2025-01-28",brand: "Yandex",orientation: 1,title: "Pirate Treasures - Play Match 3 Puzzle Game Online",
    name: ["Pirate Treasures", "pirate-treasures"],
    thumb: "https://avatars.mds.yandex.net/get-games/6300668/2a00000193cf5856e24e9a54ccba8c4f3684/pjpg350x209",
    thumb_big: "https://avatars.mds.yandex.net/get-games/6300668/2a00000193cf5856e24e9a54ccba8c4f3684/pjpg350x209",
    video:"https://video-preview.s3.yandex.net/ugc/6b490f4ea2b4c73976d4ae8e512f1bf7_vmaf-preview-10s-360.mp4",
    path: ["/game/pirate-treasures", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/241733?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Pirate Treasures, an exciting Match 3 puzzle game! Solve challenging levels, collect treasures, and enjoy this unblocked match 3 adventure.",
    l_dis:
      ` 
      <p>Welcome aboard, Captain! Get ready for an exciting <strong>Match 3</strong> adventure in <strong>Pirate Treasures</strong>. Set sail with your crew, explore hidden islands, and discover legendary riches by solving challenging <strong>puzzle games</strong>. Do you have what it takes to become the greatest pirate of all time?</p>

    <h2 class="H22">🗺️ What is Pirate Treasures?</h2>
    <p><strong>Pirate Treasures</strong> is a thrilling <strong>match 3 puzzle game</strong> where you must swap and match gems to collect valuable loot. Solve challenging levels, uncover ancient maps, and compete with friends to find the greatest pirate treasures!</p>

    <h2 class="H22">🎮 How to Play Pirate Treasures</h2>
    <ul>
        <li><strong>Match 3 Gems:</strong> Swap and match three or more gems of the same color to clear them from the board.</li>
        <li><strong>Complete Level Goals:</strong> Each level has unique challenges, such as collecting treasures or breaking obstacles.</li>
        <li><strong>Unlock Power-ups:</strong> Use powerful boosters and special moves to clear difficult levels.</li>
        <li><strong>Explore New Islands:</strong> Progress through the game to unlock hidden islands filled with rare treasures.</li>
        <li><strong>Compete with Friends:</strong> See who can collect the most treasures and complete the most levels!</li>
    </ul>

    <h2 class="H22">✨ Features of Pirate Treasures</h2>
    <ul>
        <li><strong>Thousands of Levels:</strong> Enjoy an endless variety of <strong>match 3</strong> puzzles.</li>
        <li><strong>Stunning Graphics:</strong> Immerse yourself in beautiful pirate-themed visuals.</li>
        <li><strong>Exciting Soundtrack:</strong> Feel the adventure with thrilling music and effects.</li>
        <li><strong>Challenging Gameplay:</strong> Test your skills with unique level objectives and tricky obstacles.</li>
        <li><strong>Unblocked Match 3 Game:</strong> Play <strong>Pirate Treasures unblocked</strong> anytime, anywhere without restrictions.</li>
    </ul>

    <h2 class="H22">🆕 What’s New in Pirate Treasures?</h2>
    <p>The latest update includes new levels, exciting treasure hunts, and improved gameplay mechanics. Get ready for even more fun as you explore deeper into the pirate world!</p>

    <h2 class="H22">💎 Play Pirate Treasures Unblocked</h2>
    <p>Looking for a fun and accessible <strong>unblocked match 3 game</strong>? <strong>Pirate Treasures</strong> is available to play online with no downloads or registration needed. Enjoy the adventure without restrictions!</p>

    <h2 class="H22">🚢 Set Sail on Your Pirate Adventure Today!</h2>
    <p>Are you ready to embark on an unforgettable journey? Play <strong>Pirate Treasures</strong> now and collect legendary riches! ⚓💰</p>

      `,
      tag: ["Match3","Puzzle","Match"],
    hint: ``,
    rating:[4.8,15102,1014],  
  },
  {date: "2025-01-28",brand: "Yandex",orientation: 0,title: "Melon Sandbox - The Ultimate Unblocked Sandbox Game",
    name: ["Melon Sandbox", "melon-sandbox"],
    thumb: "https://avatars.mds.yandex.net/get-games/3006389/2a0000018e31d8a990352ab914a6b2e34c66/cover1",
    thumb_big: "https://avatars.mds.yandex.net/get-games/3006389/2a0000018e31d8a990352ab914a6b2e34c66/cover1",
    video:"https://video-preview.s3.yandex.net/ugc/5cc02f9c8b55b13e1e9b0a87a02f44fd/b52909f0-8c73-44bf-9507-32845a8a28ae_vmaf-preview-10s-720.mp4",
    path: ["/game/melon-sandbox", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/260481?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Melon Sandbox, the ultimate sandbox game where you can experiment, create, and explore endless possibilities. Unblocked and free to play online!",
    l_dis:
      ` 
      <p>Unleash your imagination in <strong>Melon Sandbox</strong>, a dynamic and interactive <strong>sandbox game</strong> that lets you experiment with weapons, vehicles, and characters. Whether you want to organize chaotic battles, conduct scientific experiments, or build incredible scenarios, this game offers endless creative freedom!</p>

    <h2 class="H22">🛠️ What is Melon Sandbox?</h2>
    <p><strong>Melon Sandbox</strong> is a physics-based <strong>sandbox game</strong> where players can interact with various objects, weapons, and characters in an open environment. Use different tools, test out creative ideas, and craft your own unique gameplay experiences.</p>

    <h2 class="H22">🎮 How to Play Melon Sandbox</h2>
    <ul>
        <li><strong>Choose Your Tools:</strong> Select from a variety of weapons, vehicles, and interactive objects.</li>
        <li><strong>Experiment Freely:</strong> Test out different combinations to create unique scenarios.</li>
        <li><strong>Organize Races:</strong> Drive armored vehicles and challenge your friends to exciting races.</li>
        <li><strong>Use Mods:</strong> Upload and install mods from other players to expand your sandbox experience.</li>
        <li><strong>Save and Share:</strong> Capture your best creations and share them with the community.</li>
    </ul>

    <h2 class="H22">✨ Features of Melon Sandbox</h2>
    <ul>
        <li><strong>Huge Selection of Items:</strong> Use melee weapons, guns, barrels, and even magical syringes.</li>
        <li><strong>Interactive Physics:</strong> Enjoy realistic physics that make every interaction unique.</li>
        <li><strong>Customization & Mods:</strong> Create your own world or use mods from other players.</li>
        <li><strong>Endless Creativity:</strong> Build, destroy, and experiment with no limits.</li>
        <li><strong>Unblocked Sandbox Game:</strong> Play <strong>Melon Sandbox unblocked</strong> at school or work without restrictions.</li>
    </ul>

    <h2 class="H22">🆕 What’s New in Melon Sandbox?</h2>
    <p>The latest update introduces new weapons, enhanced physics, and improved mod support. With smoother gameplay and additional customization options, you can enjoy an even more immersive <strong>sandbox game</strong> experience.</p>

    <h2 class="H22">🌍 Play Melon Sandbox Unblocked</h2>
    <p>Want to access <strong>Melon Sandbox unblocked</strong>? Enjoy the full game with no downloads or registration required. Play anytime, anywhere, and let your creativity run wild!</p>

    <h2 class="H22">🚀 Start Your Sandbox Adventure Today!</h2>
    <p>Ready to create your own unique experiences? Play <strong>Melon Sandbox</strong> now and explore a world of endless possibilities! 🌍🔧</p>

      `,
      tag: ["Action","Fight","Weapons","Physics"],
    rating:[4.8,15102,1014],  
  },
  { date: "2025-02-14",brand: "playcutegames",title: "Runway Fashion Icon - The Ultimate Dressup Game & Unblocked Fun",
    orientation: 0,
    name: ["Runway Fashion Icon", "runway-fashion-icons"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/runway-fashion-icons/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/runway-fashion-icons/520x295.png",
    path: ["/game/runway-fashion-icons", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/runway-fashion-icons/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Runway Fashion Icon, the ultimate dress-up and runway game! Style your model, walk the catwalk, and compete in fashion challenges. Unblocked and free to play!",
    l_dis:
      `
      <p>Step into the glamorous world of <strong>Runway Fashion Icon</strong>, where you can create stunning outfits, walk the runway, and compete to become the ultimate fashion queen! Whether you love elegant gowns, trendy streetwear, or high-fashion couture, this <strong>dressup game</strong> lets you express your style and creativity in the most exciting way.</p>

    <h2 class="H22">👗 What is Runway Fashion Icon?</h2>
    <p><strong>Runway Fashion Icon</strong> is a fun and interactive <strong>fashion dress-up game</strong> where players design unique outfits, style their models, and participate in dazzling runway shows. With countless clothing options, accessories, and hair styles, every catwalk becomes a chance to shine!</p>

    <h2 class="H22">🎮 How to Play Runway Fashion Icon</h2>
    <ul>
        <li><strong>Choose Your Model:</strong> Select a character to dress up.</li>
        <li><strong>Pick Outfits:</strong> Browse through stylish wardrobes filled with dresses, shoes, and accessories.</li>
        <li><strong>Style Your Look:</strong> Experiment with different hairstyles, makeup, and accessories.</li>
        <li><strong>Walk the Runway:</strong> Show off your fashion sense in exciting runway competitions.</li>
        <li><strong>Compete & Win:</strong> Challenge other players and earn rewards for the best looks.</li>
    </ul>

    <h2 class="H22">✨ Features of Runway Fashion Icon</h2>
    <ul>
        <li><strong>Endless Fashion Choices:</strong> Mix and match outfits to create the perfect runway look.</li>
        <li><strong>Real Runway Experience:</strong> Feel like a supermodel as you strut down the catwalk.</li>
        <li><strong>Competitive Challenges:</strong> Join fashion battles and showcase your styling skills.</li>
        <li><strong>Trendy Themes:</strong> Dress up for different occasions like red carpet events, casual outings, and fashion weeks.</li>
        <li><strong>Unblocked Game:</strong> Play <strong>Runway Fashion Icon unblocked</strong> anywhere, anytime—no downloads required!</li>
    </ul>

    <h2 class="H22">🆕 What’s New in Runway Fashion Icon?</h2>
    <p>Fashion enthusiasts will love the latest update! Enjoy brand-new outfits, improved runway animations, and even more stylish themes. With smoother gameplay and enhanced customization options, you can take your fashion game to the next level.</p>

    <h2 class="H22">🌍 Play Runway Fashion Icon Unblocked</h2>
    <p>Want to play at school or work? <strong>Runway Fashion Icon unblocked</strong> lets you enjoy the game without restrictions. No downloads, no registration—just pure fashion fun!</p>

    <h2 class="H22">👑 Step Onto the Runway Today!</h2>
    <p>Are you ready to become the next fashion superstar? Play <strong>Runway Fashion Icon</strong> now and let your creativity shine on the catwalk! 💫</p>

      `,
      tag: ["Runway","Fashion","Exclusive","Dressup"],
    rating:[4.4,1210,114],  
  },
  { date: "2025-02-15",brand: "Others",title: "Mr. Mine - The Ultimate Mine Game & Unblocked Adventure",
    orientation: 0,
    name: ["Mr. Mine", "mr-mine"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mr-mine/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mr-mine/520x295.png",
    path: ["/game/mr-mine", "https://playcutegames.com"],
    iframe: "https://mrmine.com/game/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Mr. Mine, the ultimate mining game! Dig deep, upgrade your equipment, and build your mining empire in this unblocked game adventure.",
    l_dis:
      
    `
     <p>Step into the world of <strong>Mr. Mine</strong>, where you can dig, drill, and discover hidden treasures deep underground. Start with a basic drill and work your way down, unearthing valuable resources, hiring miners, and expanding your operation. The deeper you dig, the greater the rewards! With addictive gameplay and endless upgrades, <strong>Mr. Mine</strong> offers an exciting and immersive <strong>mine game</strong> experience.</p>

    <h2 class="H22">🎮 What is Mr. Mine?</h2>
    <p><strong>Mr. Mine</strong> is a highly engaging <strong>idle mining game</strong> that lets players build their own mining empire. You begin with a simple drill and gradually upgrade your tools, hire workers, and uncover rare gems and minerals. As you progress, you'll unlock new depths filled with secrets, challenges, and surprises!</p>

    <h2 class="H22">⛏️ How to Play Mr. Mine</h2>
    <ul>
        <li><strong>Start Digging:</strong> Click to mine resources and collect valuable minerals.</li>
        <li><strong>Upgrade Equipment:</strong> Improve your tools and drills to mine faster.</li>
        <li><strong>Hire Miners:</strong> Expand your team and increase efficiency.</li>
        <li><strong>Explore the Depths:</strong> Unlock new levels and discover hidden treasures.</li>
        <li><strong>Earn Riches:</strong> Sell resources and reinvest in your mining empire.</li>
    </ul>

    <h2 class="H22">🔥 Features of Mr. Mine</h2>
    <ul>
        <li><strong>Addictive Gameplay:</strong> A perfect mix of idle and strategy mechanics.</li>
        <li><strong>Endless Exploration:</strong> Dig deeper to uncover rare and valuable items.</li>
        <li><strong>Upgrades & Customization:</strong> Improve your equipment and optimize your mining strategy.</li>
        <li><strong>Unblocked Game:</strong> Enjoy <strong>Mr. Mine unblocked</strong> at school, work, or anywhere without restrictions.</li>
        <li><strong>Engaging Graphics:</strong> A visually appealing game with smooth animations.</li>
    </ul>

    <h2 class="H22">✨ What’s New in Mr. Mine?</h2>
    <p>The latest update introduces exciting new mines, powerful upgrades, and even more secrets to uncover! With optimized graphics, improved balancing, and smoother gameplay, your mining adventure just got better.</p>

    <h2 class="H22">🌍 Play Mr. Mine Unblocked</h2>
    <p>Looking for an <strong>unblocked game</strong>? <strong>Mr. Mine unblocked</strong> lets you enjoy unlimited mining fun anytime, anywhere—no downloads required!</p>

    <h2 class="H22">🏆 Start Digging Today!</h2>
    <p>Are you ready to become a mining tycoon? Play <strong>Mr. Mine</strong> now and embark on an adventure filled with riches, strategy, and endless discoveries!</p>

       `,
      tag: ["Mine","Arcade"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  { date: "2025-02-15",brand: "Others",title: "Car Drawing Game - Fun Drawing Games & Unblocked Car Game",
    orientation: 0,
    name: ["Car Drawing Game?", "car-drawing-game"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/car-drawing-game/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/car-drawing-game/520x295.png",
    path: ["/game/car-drawing-game", "https://playcutegames.com"],
    iframe: "https://kdata1.com/2019/07/1515541/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Car Drawing Game, the ultimate physics-based drawing game! Design your own car, tackle obstacles, and enjoy an unblocked car game experience.",
    l_dis:
      
    `
     <p>Unleash your creativity in the <strong>Car Drawing Game</strong>, a unique physics-based <strong>drawing game</strong> where your artistic skills determine your success! Instead of choosing a pre-made vehicle, you get to draw your own car and test its performance on various terrains. Whether you sketch a sleek sports car or a wacky ride, your creation will face ramps, hills, and challenging obstacles. Can you build the ultimate car to win each level? Start drawing and driving today!</p>

    <h2 class="H22">🎮 What is Car Drawing Game?</h2>
    <p>The <strong>Car Drawing Game</strong> is a fun mix of creativity and physics-based gameplay. Players use their imagination to draw a vehicle, which then comes to life and races through dynamic levels. The shape and structure of your car influence how well it handles different terrains, making every round a unique challenge!</p>

    <h2 class="H22">🚀 How to Play Car Drawing Game</h2>
    <ul>
        <li><strong>Draw Your Car:</strong> Use your finger or mouse to sketch a car shape.</li>
        <li><strong>Watch it Come to Life:</strong> Once drawn, your car will transform into a moving vehicle.</li>
        <li><strong>Navigate Obstacles:</strong> Drive through ramps, steep hills, and tricky challenges.</li>
        <li><strong>Experiment with Designs:</strong> Try different car shapes to see which works best.</li>
        <li><strong>Beat Levels:</strong> Complete each stage by successfully driving your car to the finish line.</li>
    </ul>

    <h2 class="H22">🔥 Features of Car Drawing Game</h2>
    <ul>
        <li><strong>Creative Gameplay:</strong> Draw and test different vehicle designs.</li>
        <li><strong>Physics-Based Challenges:</strong> Experience realistic driving mechanics.</li>
        <li><strong>Exciting Levels:</strong> Tackle various terrains filled with ramps and obstacles.</li>
        <li><strong>Unblocked Car Game:</strong> Play <strong>Car Drawing unblocked</strong> anytime, anywhere—no downloads required.</li>
        <li><strong>Fun for All Ages:</strong> Perfect for kids and adults who love <strong>drawing games</strong> and racing games.</li>
    </ul>

    <h2 class="H22">✨ What’s New in Car Drawing Game?</h2>
    <p>The latest update introduces brand-new levels, exciting vehicle customization options, and smoother gameplay mechanics! Enjoy enhanced physics, better controls, and fun new obstacles that take the challenge to the next level.</p>

    <h2 class="H22">🌍 Play Car Drawing Game Unblocked</h2>
    <p>Looking for an <strong>unblocked car game</strong>? <strong>Car Drawing unblocked</strong> allows you to enjoy the game at school, work, or anywhere with no restrictions. No downloads, no installation—just pure fun!</p>

    <h2 class="H22">🏁 Start Drawing & Racing Now!</h2>
    <p>Are you ready to create the ultimate car and take on thrilling challenges? Start playing <strong>Car Drawing Game</strong> today and test your design skills in this exciting <strong>drawing game</strong> adventure!</p>

       `,
      tag: ["Car","Drawing"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  { date: "2025-02-15",brand: "Others",title: "Duck Duck Clicker - Fun Clicker Game & Unblocked Clicker Game",
    orientation: 0,
    name: ["Duck Duck Clicker?", "duck-duck-clicker"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/duck-duck-clicker/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/duck-duck-clicker/520x295.png",
    path: ["/game/duck-duck-clicker", "https://playcutegames.com"],
    iframe: "https://kdata1.com/2024/08/duck-duck-clicker/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Duck Duck Clicker, the ultimate idle clicker game! Hatch ducks, collect rewards, and unlock upgrades in this fun and unblocked clicker game.",
    l_dis:
      `
      <p>Get ready to click your way to victory in <strong>Duck Duck Clicker</strong>, the most exciting and addictive <strong>clicker game</strong>! Hatch adorable ducks, earn rewards, and unlock powerful upgrades to progress through levels. Whether you're a casual gamer or a clicker game enthusiast, this game promises endless fun and engaging gameplay.</p>

    <h2 class="H22">🦆 What is Duck Duck Clicker?</h2>
    <p><strong>Duck Duck Clicker</strong> is a thrilling <strong>idle clicker game</strong> where players click to hatch ducks and collect points. The faster you click, the more ducks you hatch, leading to greater rewards and exciting new upgrades. It’s a perfect mix of fun, strategy, and idle gameplay.</p>

    <h2 class="H22">🎮 How to Play Duck Duck Clicker</h2>
    <ul>
        <li><strong>Click to Hatch Ducks:</strong> Tap or click rapidly to hatch as many ducks as possible.</li>
        <li><strong>Earn Rewards:</strong> Collect points and coins for every duck you hatch.</li>
        <li><strong>Upgrade Your Ducks:</strong> Use your earnings to unlock power-ups and boost your clicking power.</li>
        <li><strong>Discover New Ducks:</strong> Unlock rare and unique duck species as you progress.</li>
        <li><strong>Keep Clicking to Win:</strong> The more you click, the faster you grow your duck empire!</li>
    </ul>

    <h2 class="H22">🔥 Features of Duck Duck Clicker</h2>
    <ul>
        <li><strong>Endless Clicker Fun:</strong> Play as much as you want with no limits.</li>
        <li><strong>Addictive Gameplay:</strong> Simple yet highly engaging mechanics keep you entertained.</li>
        <li><strong>Upgrades & Boosters:</strong> Improve your clicking power and discover new features.</li>
        <li><strong>Unblocked Clicker Game:</strong> Play <strong>Duck Duck Clicker unblocked</strong> anytime, anywhere—no downloads needed.</li>
        <li><strong>Perfect for All Ages:</strong> Whether you’re a casual gamer or a clicker pro, this game is for everyone.</li>
    </ul>

    <h2 class="H22">🚀 Why Play Duck Duck Clicker?</h2>
    <p><strong>Duck Duck Clicker</strong> stands out from other <strong>clicker games</strong> thanks to its unique duck-hatching concept, fun visuals, and rewarding progression system. Whether you love idle games or fast-paced clicking challenges, this game has something for everyone.</p>

    <h2 class="H22">🌍 Play Duck Duck Clicker Unblocked</h2>
    <p>Looking for an <strong>unblocked clicker game</strong>? <strong>Duck Duck Clicker unblocked</strong> lets you enjoy the game at school, work, or anywhere without restrictions. No downloads, no installations—just pure clicking fun!</p>

    <h2 class="H22">🎉 Start Clicking Now!</h2>
    <p>Are you ready to become the ultimate duck collector? Start playing <strong>Duck Duck Clicker</strong> today and see how many ducks you can hatch! Challenge your friends and climb the leaderboard in this exciting <strong>clicker game</strong> adventure!</p>

    `,
      tag: ["Clicker"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  { date: "2025-02-14",brand: "playcutegames",title: "How Romantic Are You? – Fun Love Quiz!",
    orientation: 0,
    name: ["How Romantic Are You?", "how-romantic-you-are"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/how-romantic-you-are/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/how-romantic-you-are/520x295.png",
    path: ["/game/how-romantic-you-are", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bestgamespot/how-romantic-you-are/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Take the ultimate Valentine Quiz! Answer fun love-themed questions to discover your romantic personality. Play this love quiz and enjoy fun trivia games!",
    l_dis:
      ` <p>Love comes in many forms—some wear their hearts on their sleeves, while others take a more practical approach. Which one are you? Take this exciting <strong>Valentine Quiz</strong> to discover your true romantic side! 💕</p>

    <h2 class="H22">❓ What is the Valentine Quiz?</h2>
    <p>The <strong>Valentine Quiz</strong> is a fun and engaging way to explore your love personality. Answer 15 random love-themed questions, each with four choices, to determine how romantic you truly are. Every answer carries a score that shapes your final romance percentage.</p>

    <h2 class="H22">🎯 How to Play the Love Quiz</h2>
    <ul>
        <li><strong>Answer 15 Love Questions:</strong> Each question explores your romantic preferences and personality.</li>
        <li><strong>Choose from 4 Options:</strong> Every answer influences your final romance score.</li>
        <li><strong>See Your Romance Score:</strong> Get a percentage that defines your level of romance.</li>
        <li><strong>Enjoy a Fun GIF Reaction:</strong> Your result comes with a GIF that perfectly matches your love style!</li>
        <li><strong>Share with Friends:</strong> Compare your scores and have fun discussing the results!</li>
    </ul>

    <h2 class="H22">💌 Why Play This Fun Quiz?</h2>
    <ul>
        <li><strong>Entertaining & Insightful:</strong> Discover how romantic you are in a playful way.</li>
        <li><strong>Perfect for Couples & Friends:</strong> A great way to bond and compare love styles.</li>
        <li><strong>Engaging Trivia Game:</strong> Love-themed questions that make you think and laugh.</li>
        <li><strong>Interactive & Shareable:</strong> Fun results with GIFs make it perfect for sharing on social media.</li>
    </ul>

    <h2 class="H22">💘 Are You a Hopeless Romantic or a Practical Lover?</h2>
    <p>Whether you’re a dreamer who believes in fairy tale love or a realist who values logic, this <strong>love quiz</strong> will reveal your true romantic side. Answer each question honestly and find out where you stand!</p>

    <h2 class="H22">🎉 Play the Valentine Quiz Now!</h2>
    <p>Are you ready to test your romance level? Play this exciting <strong>Valentine Quiz</strong> now and uncover your love personality. Share your results with friends and see who is the biggest romantic in your group! ❤️</p>

    `,
      tag: ["Love","Quiz","Exclusive","Trivia"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  
  
  { date: "2025-01-22",brand: "playcutegames",title: "Kitty Squad Winter Dress Up - Play Fun Dress Up Games Online",
    orientation: 0,
    name: ["Kitty Squad Winter Dress Up", "kitty-squad-winter-dress-up"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/kitty-squad-winter-dress-up/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/kitty-squad-winter-dress-up/520x295.png",
    path: ["/game/kitty-squad-winter-dress-up", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/kitty-squad-winter-dress-up/",
    Walkthrough: "https://www.youtube.com/embed/reZ7OC7ATDk",
    s_dis:
    "Play Kitty Squad Winter Dress Up online! Style your kitty with cozy winter outfits and cute accessories. No download needed—enjoy fun dress-up games now!",
    l_dis:
      `<p>Welcome to <strong>Kitty Squad Winter Dress Up</strong>, the ultimate game for cat lovers and fashion enthusiasts! Dive into a world of cozy outfits, adorable accessories, and festive winter styles as you dress up your furry friend for the chilly season. With endless customization options and charming graphics, this is one of the cutest <strong>dress-up games</strong> you’ll find online. Start styling your kitty today!</p>

    <h2 class="H22">🎯 What is Kitty Squad Winter Dress Up?</h2>
    <p><strong>Kitty Squad Winter Dress Up</strong> is a fun and creative game where you can dress your kitty in stylish winter outfits. From cozy sweaters and scarves to cute hats and boots, you have everything you need to create the perfect winter look. Whether you're preparing your kitty for a snowy adventure or a cozy night by the fireplace, the possibilities are endless!</p>

    <h2 class="H22">✨ Key Features of Kitty Squad Winter Dress Up</h2>
    <ul>
        <li><strong>Winter-Themed Outfits:</strong> Choose from cozy sweaters, scarves, and festive attire.</li>
        <li><strong>Cute Accessories:</strong> Add hats, boots, mittens, and more to complete your kitty’s look.</li>
        <li><strong>Endless Customization:</strong> Mix and match outfits to create unique styles for your kitty.</li>
        <li><strong>Adorable Graphics:</strong> Enjoy colorful visuals and charming designs that bring your kitty to life.</li>
        <li><strong>Free to Play:</strong> Enjoy the full game with no downloads required, with optional in-game purchases.</li>
    </ul>

    <h2 class="H22">🎮 How to Play Kitty Squad Winter Dress Up</h2>
    <ul>
        <li><strong>Choose Your Kitty:</strong> Select your favorite kitty to dress up.</li>
        <li><strong>Pick Outfits:</strong> Browse through a wide range of winter-themed outfits and choose the perfect look.</li>
        <li><strong>Add Accessories:</strong> Complete the style with hats, scarves, boots, and more.</li>
        <li><strong>Save Your Creation:</strong> Save your kitty’s winter look and share it with friends!</li>
    </ul>

    <h2 class="H22">🐾 Why You’ll Love Kitty Squad Winter Dress Up</h2>
    <p>If you love <strong>dress-up games</strong> and cute kitties, this game is perfect for you! The charming graphics, customizable outfits, and creative options make <strong>Kitty Squad Winter Dress Up</strong> a delightful experience for all ages. Whether you're a cat lover or a fashionista, this game will keep you entertained for hours.</p>

    <h2 class="H22">❄️ Play Kitty Squad Winter Dress Up Now!</h2>
    <p>Get ready to dress your kitty in the cutest winter outfits! Play <strong>Kitty Squad Winter Dress Up</strong> online for free—no downloads, no registration needed. Explore endless fashion possibilities and share your kitty’s winter look with friends. Start styling today and make your kitty the most fashionable feline in town!</p>
 `,
      tag: ["Kitty"," Winter","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
 
  {date: "2025-01-28",brand: "Yandex",orientation: 0,title: "Drift Racing in the Open World – Ultimate Driving Adventure",
    name: ["Drift Racing in the open world", "drift-racing-in-the-open-world"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/drift-racing-in-the-open-world/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/drift-racing-in-the-open-world/520x295.png",
    // video:"https://cdn.cutedressup.in/games/cutedressup-video/drift-racing-in-the-open-world.mp4",
    path: ["/game/drift-racing-in-the-open-world", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/264036?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Experience the thrill of Drift Racing in an expansive open world. Customize your car, explore multiple cities, and test your skills with challenging gameplay.",
    l_dis:
      ` 
        <p>Get ready for an adrenaline-packed experience with <strong>Drift Racing in the Open World</strong>! This game offers the perfect blend of speed, customization, and exploration. Whether you're a fan of high-speed chases or precision drifting, this game has something for every racing enthusiast. Race across expansive cities, customize your vehicle, and push your limits with exciting challenges!</p>

      <h2 class="H22">About Drift Racing in the Open World</h2>
      <p><strong>Drift Racing in the Open World</strong> takes racing games to the next level with an open-world environment full of exciting opportunities. Choose from a wide selection of cars, each offering unique driving characteristics, and customize your ride to perfection. From classic sports cars to custom racing machines, you can tweak everything from engine power to aerodynamics for maximum performance. Explore multiple cities, find the best drifting spots, and test your skills in crash mode for a real challenge!</p>

      <h2 class="H22">Key Features of Drift Racing in the Open World</h2>
      <ul>
          <li><strong>Wide Range of Cars:</strong> Select from a massive variety of cars, each with unique characteristics and styles.</li>
          <li><strong>Extensive Customization:</strong> Upgrade your engine, adjust suspension, tweak aerodynamics, and choose your car’s color to suit your racing needs.</li>
          <li><strong>Expansive Open World:</strong> Race across multiple cities, uncovering hidden locations and perfect drifting spots for ultimate fun.</li>
          <li><strong>Crash Mode Challenges:</strong> Test your driving skills with a series of demanding challenges and push your limits under extreme conditions.</li>
      </ul>

      <h2 class="H22">How to Play</h2>
      <p>In <strong>Drift Racing in the Open World</strong>, you'll take control of a car and race through different environments. The primary controls for playing the game are as follows:</p>
      <ul>
          <li><strong>Move:</strong> Use the arrow keys to drive the car in different directions.</li>
          <li><strong>Handbrake:</strong> Press the space bar to activate the handbrake, perfect for executing tight turns and drifting.</li>
      </ul>

      <h2 class="H22">Why Play Drift Racing in the Open World?</h2>
      <p><strong>Drift Racing in the Open World</strong> offers a unique combination of fast-paced racing, immersive environments, and intense customization. Whether you're a casual player or a competitive racer, the game allows you to experience the thrill of drifting and high-speed racing with a personalized twist. Explore vast cities, test your skills in different challenges, and upgrade your car to dominate the streets. Plus, with the <strong>Unblocked</strong> version available, you can enjoy the game anywhere!</p>

      <ul>
          <li><strong>Explore New Locations:</strong> Race through exciting new cities, uncovering hidden spots for the best drifting experiences.</li>
          <li><strong>Master the Drift:</strong> Hone your drifting skills with precise control over your car and strategic use of the handbrake.</li>
          <li><strong>Customize Your Ride:</strong> Tune and upgrade your car to fit your playstyle and dominate the streets.</li>
          <li><strong>Unblocked Play:</strong> Access the game from anywhere, including school or work, with the unblocked version.</li>
      </ul>

      <h2 class="H22">Conclusion</h2>
      <p>If you're a fan of <strong>Escape Games</strong>, <strong>Puzzle Games</strong>, or <strong>Unblocked Games</strong>, <strong>Drift Racing in the Open World</strong> is the perfect game for you. Enjoy the thrill of high-speed racing, explore new cities, and customize your car to dominate the streets. Play now and experience the ultimate drifting adventure!</p>

      `,
      tag: ["Car","Race","Brain"],
    hint: ``,
    rating:[4.8,15102,1014],  
  },
  {date: "2025-01-28",brand: "Yandex",orientation: 0,title: "Deadly Descent – Thrilling Car Adventure",
    name: ["Deadly Descent", "deadly-descent"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/deadly-descent/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/deadly-descent/520x295.png",
    // video:"https://cdn.cutedressup.in/games/cutedressup-video/deadly-descent.mp4",
    path: ["/game/deadly-descent", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/274415?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play Deadly Descent, a fun and exciting Car Game. Drive down mountains, avoid obstacles, and compete with players in Unblocked Car Games. Enjoy now!",
    l_dis:
      ` <p><strong>Deadly Descent</strong> is an exciting racing game where players must navigate a car down a dangerous mountain filled with obstacles. The goal is simple: avoid crashing and reach the finish line without breaking down. Perfect for fans of <strong>Car Games</strong> and <strong>Unblocked Car Games</strong>, this game offers an adrenaline-pumping experience with realistic physics and exciting gameplay.</p>

    <h2 class="H22">About Deadly Descent</h2>
    <p>In <strong>Deadly Descent</strong>, you control a car on a rugged mountain track, where danger lurks at every corner. The objective is to travel as far as possible without damaging your car. With advanced physics and realistic graphics, the game offers a thrilling driving experience that runs smoothly on any device, whether on your phone or computer. Race against other players and try to beat their high scores to become the ultimate champion!</p>

    <h2 class="H22">Key Features of Deadly Descent</h2>
    <ul>
        <li><strong>Realistic Physics:</strong> Experience real-life car damage and driving physics as you navigate treacherous terrain.</li>
        <li><strong>Unique Tracks:</strong> Explore a variety of mountain tracks, each with its own set of challenges.</li>
        <li><strong>Compete Globally:</strong> Challenge other players to beat your high score and dominate the leaderboards.</li>
        <li><strong>Accessible Gameplay:</strong> Play on both mobile and PC without high resource requirements.</li>
        <li><strong>Unblocked Access:</strong> Play anywhere, even on restricted networks, with the unblocked version of the game.</li>
    </ul>

    <h2 class="H22">How to Play</h2>
    <p>Your goal in <strong>Deadly Descent</strong> is to cover as much ground as possible while avoiding obstacles that could damage your car. Here’s how to play:</p>
    <h3 class="H22">PC Controls</h3>
    <ul>
        <li><strong>Move:</strong> Use WASD or the arrow keys to control the car.</li>
        <li><strong>Turbo Boost:</strong> Press F to activate a turbo boost.</li>
        <li><strong>Change Camera View:</strong> Press C to adjust the camera angle.</li>
    </ul>

    <h3 class="H22">Mobile Controls</h3>
    <ul>
        <li><strong>Move:</strong> Use on-screen buttons or the steering wheel mode for better control.</li>
        <li><strong>Switch Modes:</strong> Tap the button at the top of the screen to toggle between control modes.</li>
    </ul>

    <h2 class="H22">Why Play Deadly Descent?</h2>
    <p><strong>Deadly Descent</strong> offers more than just a thrilling driving experience. Whether you're a fan of <strong>Car Games</strong> or <strong>Race Games Unblocked</strong>, this game provides endless fun with its competitive aspects, including the global leaderboard and high scores. Test your skills on different tracks, avoid obstacles, and see how far you can go in this action-packed racing adventure.</p>
    <ul>
        <li><strong>Competitive Leaderboard:</strong> Record your best distance on each track and compare it with other players.</li>
        <li><strong>Realistic Driving Mechanics:</strong> Enjoy a realistic driving experience with advanced car physics and intuitive controls.</li>
        <li><strong>Endless Fun:</strong> With various tracks and obstacles, the game offers hours of replay value.</li>
        <li><strong>Unblocked Play:</strong> Enjoy the game on any network with the unblocked version available for schools and workplaces.</li>
    </ul>

    <h2 class="H22">Tips for Mastering Deadly Descent</h2>
    <ul>
        <li><strong>Master the Controls:</strong> Practice using both the keyboard and mobile controls to improve your driving skills.</li>
        <li><strong>Use Turbo Boost Wisely:</strong> Save the turbo boost for tough obstacles or steep hills to gain an edge.</li>
        <li><strong>Avoid Damage:</strong> Focus on smooth and careful driving to prevent damaging your car.</li>
        <li><strong>Learn the Tracks:</strong> Familiarize yourself with the tracks to anticipate obstacles and plan your route better.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>Deadly Descent</strong> is a perfect game for fans of <strong>Car Games</strong> and <strong>Unblocked Car Games</strong>. With its combination of thrilling gameplay, challenging tracks, and competitive elements, it offers endless hours of fun. Whether you are playing on your phone or computer, <strong>Deadly Descent</strong> guarantees an unforgettable racing experience. Try it now and see how far you can go!</p>
`,
      tag: ["Car","Race","Brain"],
    hint: ``,
    rating:[4.8,15102,1014],  
  },
  {date: "2024-09-13",brand: "Yandex",orientation: 0,title: "Grand Extreme Racing – The Ultimate Racing Adventure",
    name: ["Grand Extreme Racing", "grand-extreme-racing"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/grand-extreme-racing/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/grand-extreme-racing/520x295.png",
    // video:"https://cdn.cutedressup.in/games/cutedressup-video/grand-extreme-racing.mp4",
    path: ["/game/grand-extreme-racing", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/195426?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/hNSWlpwOmiY",
    s_dis:
    "Play Grand Extreme Racing and experience thrilling Car Games. Compete in championships, upgrade cars, and enjoy Race Games Unblocked. Join now!",
    l_dis:
      ` 
       <p><strong>Grand Extreme Racing</strong> is an action-packed game that puts you in the driver’s seat of high-speed championship races. Whether you compete in solo challenges or face off with a friend in 2-player mode, this game offers endless excitement for fans of <strong>Car Games</strong> and <strong>Race Games Unblocked</strong>. With ten maps, five unique race cars, and five drivers, the thrill of the track awaits!</p>

    <h2 class="H22">About Grand Extreme Racing</h2>
    <p>Join an extended championship journey and strive to claim the coveted world championship cups. Pick your favorite car and driver, then showcase your racing skills across multiple tracks. The rewards in <strong>Grand Extreme Racing</strong> are generous, enabling you to upgrade your car for better performance. Challenge yourself in single-player mode or compete head-to-head with friends in two-player mode.</p>

    <h2 class="H22">Game Features</h2>
    <ul>
        <li><strong>Championship Mode:</strong> Race through an epic series of challenges and earn rewards to upgrade your car.</li>
        <li><strong>Two-Player Mode:</strong> Compete with a friend and see who is the ultimate racer.</li>
        <li><strong>Ten Unique Maps:</strong> Test your skills on a variety of tracks, each offering a unique racing experience.</li>
        <li><strong>Customizable Cars:</strong> Choose from five race cars and drivers, and upgrade them to dominate the competition.</li>
        <li><strong>Unblocked Gameplay:</strong> Play <strong>Grand Extreme Racing</strong> anytime, anywhere, even in restricted networks like schools or workplaces.</li>
    </ul>

    <h2 class="H22">How to Play</h2>
    <p>Mastering the controls is the key to victory. Below are the controls for both single-player and two-player modes:</p>

    <h3 class="H22">Player 1 Controls</h3>
    <ul>
        <li><strong>Move:</strong> Arrow Keys</li>
        <li><strong>NOS:</strong> M</li>
        <li><strong>Restart Car Position:</strong> O</li>
        <li><strong>Look Back:</strong> P</li>
        <li><strong>Change Camera View:</strong> K</li>
    </ul>

    <h3 class="H22">Player 2 Controls</h3>
    <ul>
        <li><strong>Move:</strong> W, A, S, D</li>
        <li><strong>NOS:</strong> Left Shift</li>
        <li><strong>Restart Car Position:</strong> R</li>
        <li><strong>Look Back:</strong> T</li>
        <li><strong>Change Camera View:</strong> C</li>
    </ul>

    <h3 class="H22">Single-Player Mode Controls</h3>
    <ul>
        <li><strong>Move:</strong> W, A, S, D or Arrow Keys</li>
        <li><strong>NOS:</strong> Left Shift or Right Shift</li>
        <li><strong>Restart Car Position:</strong> R</li>
        <li><strong>Look Back:</strong> T</li>
        <li><strong>Change Camera View:</strong> C</li>
    </ul>

    <h2 class="H22">Why Play Grand Extreme Racing?</h2>
    <p><strong>Grand Extreme Racing</strong> isn’t just another racing game; it’s a complete experience for <strong>Car Games</strong> enthusiasts. With its stunning visuals, competitive gameplay, and the thrill of upgrades, it keeps you coming back for more. The unblocked version ensures you can play without restrictions, making it ideal for gamers of all ages.</p>
    <ul>
        <li><strong>Endless Fun:</strong> Enjoy dynamic maps and continuous challenges.</li>
        <li><strong>Multiplayer Mode:</strong> Share the excitement with friends in two-player mode.</li>
        <li><strong>Upgrade System:</strong> Use rewards to enhance your car’s performance and dominate the track.</li>
        <li><strong>Unblocked Access:</strong> Perfect for playing in schools, workplaces, or anywhere else!</li>
    </ul>

    <h2 class="H22">Tips to Excel in the Game</h2>
    <ul>
        <li><strong>Master the Controls:</strong> Practice both single-player and two-player controls to improve your skills.</li>
        <li><strong>Use NOS Wisely:</strong> Save your NOS for straight stretches or to overtake opponents during critical moments.</li>
        <li><strong>Upgrade Strategically:</strong> Focus on upgrades that enhance speed and control for better results.</li>
        <li><strong>Learn the Tracks:</strong> Familiarize yourself with each map to anticipate turns and obstacles.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>Grand Extreme Racing</strong> is the ultimate choice for fans of <strong>Car Games</strong> and <strong>Race Games Unblocked</strong>. With its immersive gameplay, variety of maps, and customization options, this game guarantees endless fun. Whether you’re playing solo or with friends, the excitement never stops. Play the unblocked version and take your racing skills to the next level!</p>

    `,
      tag: ["Car","Race"],
    hint: ``,
    rating:[4.8,15102,1014],  
  },
  {
    date: "2024-09-13",
    brand: "Yandex",
    orientation: 1,
    title: "Ludo King Online: Play the Classic Game Anytime, Anywhere",
    name: ["Ludo King Online", "ludo-king"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ludo-king/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ludo-king/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/ludo-king.mp4",
    path: ["/game/ludo-king", "https://playcutegames.com"],
    iframe: "https://ludoking.com/play/",
    Walkthrough: "https://www.youtube.com/embed/8ik2V1z_z8o",
    s_dis:
    "Enjoy Ludo King Online, the digital version of the classic Ludo board game. Play Ludo Games, including Ludo Unblocked, and relive the nostalgia anytime!",
    l_dis:
      ` 
      <p><strong>Ludo King Online</strong> transforms the beloved traditional Ludo board game into a digital masterpiece. Whether you’re reliving cherished childhood memories or competing with players worldwide, this game offers an immersive experience for everyone. With the convenience of <strong>Ludo Unblocked</strong>, you can enjoy seamless gameplay anywhere, even on restricted networks like schools or offices.</p>

    <h2 class="H22">About Ludo King Online</h2>
    <p><strong>Ludo King Online</strong> stays true to the traditional rules of <strong>Ludo Games</strong> while adding exciting features and modern conveniences. The objective is simple: race your tokens to the finish line while outsmarting your opponents. With multiplayer modes, offline play, and customizable themes, Ludo King Online caters to casual and competitive players alike.</p>

    <h2 class="H22">Features of Ludo King</h2>
    <ul>
        <li><strong>Classic Gameplay:</strong> Experience the timeless rules of <strong>Ludo Games</strong>, where strategy and luck collide.</li>
        <li><strong>Multiplayer Options:</strong> Compete in real-time matches with friends, family, or global players.</li>
        <li><strong>Unblocked Access:</strong> Enjoy <strong>Ludo King Unblocked</strong>, ensuring playability on restricted networks.</li>
        <li><strong>Offline Mode:</strong> Play offline against AI or in local multiplayer mode for uninterrupted fun.</li>
        <li><strong>Custom Themes:</strong> Personalize your board with vibrant and colorful themes.</li>
        <li><strong>Snakes and Ladders Mode:</strong> Switch to the classic Snakes and Ladders game for added variety.</li>
    </ul>

    <h2 class="H22">How to Play Ludo King Online</h2>
    <p>The gameplay is straightforward yet captivating:</p>
    <ol>
        <li>Roll the dice to move your tokens along the board.</li>
        <li>Strategically plan your moves to block opponents or send their tokens back to the start.</li>
        <li>Race all your tokens to the finish line to claim victory.</li>
        <li>Play in different modes, including 2-player, 4-player, or Snakes and Ladders for variety.</li>
    </ol>

    <h2 class="H22">Why Play Ludo King Online?</h2>
    <p><strong>Ludo King Online</strong> offers a perfect blend of tradition and modern features, making it one of the best <strong>Puzzle Games</strong> for all ages:</p>
    <ul>
        <li><strong>Nostalgic Fun:</strong> Relive childhood memories with classic Ludo gameplay.</li>
        <li><strong>Global Connection:</strong> Compete with players from around the world in real-time.</li>
        <li><strong>Convenience:</strong> Play anytime, anywhere with <strong>Ludo Unblocked</strong>.</li>
        <li><strong>Versatile Modes:</strong> Enjoy online multiplayer, local multiplayer, or offline play.</li>
        <li><strong>Engaging Themes:</strong> Customize your gaming experience with unique board themes.</li>
    </ul>

    <h2 class="H22">Tips for Mastering Ludo King</h2>
    <ul>
        <li><strong>Strategic Movement:</strong> Plan your moves carefully to block opponents and protect your tokens.</li>
        <li><strong>Utilize Safe Zones:</strong> Place your tokens in safe zones to avoid being sent back to the start.</li>
        <li><strong>Focus on the Goal:</strong> Prioritize moving tokens toward the finish line instead of chasing opponents.</li>
        <li><strong>Adapt to Opponents:</strong> Observe your opponents’ strategies and adjust your moves accordingly.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>Ludo King Online</strong> offers an engaging and versatile way to enjoy the classic <strong>Ludo Games</strong>. With its nostalgic charm, exciting multiplayer modes, and accessibility through <strong>Ludo Unblocked</strong>, this game is perfect for players of all ages. Whether you’re playing with friends, family, or global opponents, Ludo King ensures endless entertainment and friendly competition. Start rolling the dice and enjoy the ultimate digital Ludo experience today!</p>

    `,
      tag: ["Ludo","Multiplayer","Brain"],
    hint: ``,
    rating:[4.8,15102,1014],  
  },
  {
    date: "2024-09-13",
    brand: "Yandex",
    orientation: 0,
    title: "The Visitor: An Unforgettable Alien Adventure",
    name: ["The Visitor", "the-visitor"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/the-visitor/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/the-visitor/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/the-visitor.mp4",
    path: ["/game/the-visitor", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/the-visitor/",
    Walkthrough: "https://www.youtube.com/embed/8dK7nKAO0TU",
    s_dis:
    "Play The Visitor, a thrilling alien-themed escape and puzzle game filled with dark humor and surprises. Enjoy The Visitor Unblocked anywhere!",
    l_dis:
      `  <p><strong>The Visitor</strong> is a unique point-and-click <strong>Puzzle Game</strong> that offers an unforgettable experience. Taking control of a small alien parasite, players embark on a thrilling journey filled with dark humor, unexpected twists, and challenging scenarios. Whether you’re a fan of <strong>Escape Games</strong> or looking for something out of the ordinary, <strong>The Visitor</strong> delivers an engaging and one-of-a-kind adventure.</p>

    <h2 class="H22">Game Overview</h2>
    <p>In <strong>The Visitor</strong>, players guide a cunning extraterrestrial creature as it explores Earth. The objective is to grow and evolve by consuming other creatures, solving puzzles, and navigating through intricate scenarios. With every interaction, the alien becomes stronger, unlocking new abilities that allow it to overcome obstacles and progress further into its journey.</p>

    <h2 class="H22">Key Features of The Visitor</h2>
    <ul>
        <li><strong>Thrilling Gameplay:</strong> Engage in a point-and-click adventure filled with surprises and dark humor.</li>
        <li><strong>Puzzle Challenges:</strong> Solve intricate puzzles to progress through the game.</li>
        <li><strong>Unique Evolution System:</strong> Consume creatures to grow, adapt, and unlock new abilities.</li>
        <li><strong>Dark Humor:</strong> Experience a storyline filled with unexpected twists and clever humor.</li>
        <li><strong>Unblocked Version:</strong> Play <strong>The Visitor Unblocked</strong> anywhere, including restricted networks at schools or workplaces.</li>
    </ul>

    <h2 class="H22">How to Play The Visitor</h2>
    <p>Playing <strong>The Visitor</strong> is simple but requires strategic thinking and attention to detail. Follow these steps to start your adventure:</p>
    <ol>
        <li>Click on objects in the environment to interact with them.</li>
        <li>Solve puzzles by experimenting with different combinations and actions.</li>
        <li>Help the alien consume creatures to grow and gain new abilities.</li>
        <li>Overcome obstacles and challenges to progress through the story.</li>
    </ol>

    <h2 class="H22">Why Play The Visitor?</h2>
    <p><strong>The Visitor</strong> stands out from other <strong>Puzzle Games</strong> and <strong>Escape Games</strong> for several reasons:</p>
    <ul>
        <li><strong>Engaging Storyline:</strong> Dive into a dark and humorous narrative that keeps you hooked until the end.</li>
        <li><strong>Creative Gameplay:</strong> Enjoy unique mechanics that combine puzzle-solving with strategic evolution.</li>
        <li><strong>Accessible Anywhere:</strong> The unblocked version ensures you can play anytime, even on restricted networks.</li>
        <li><strong>Replay Value:</strong> Discover new ways to solve puzzles and uncover hidden surprises in each playthrough.</li>
    </ul>

    <h2 class="H22">Tips and Tricks for The Visitor</h2>
    <p>Master <strong>The Visitor</strong> with these helpful tips:</p>
    <ul>
        <li><strong>Experiment:</strong> Interact with all objects in the environment to uncover new possibilities.</li>
        <li><strong>Observe Closely:</strong> Pay attention to visual cues and hints in the surroundings.</li>
        <li><strong>Plan Your Moves:</strong> Think strategically to solve puzzles and progress efficiently.</li>
        <li><strong>Stay Patient:</strong> Some puzzles require trial and error, so don’t give up too quickly.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>The Visitor</strong> offers a thrilling blend of <strong>Escape Games</strong> and <strong>Puzzle Games</strong>, making it a must-play for fans of challenging and unique experiences. With its dark humor, engaging mechanics, and captivating storyline, this game promises hours of entertainment. Plus, with <strong>The Visitor Unblocked</strong>, you can enjoy the adventure from anywhere. Step into the world of this unforgettable alien parasite and start your journey today!</p>
  `,
      tag: ["Escape","Skill","Brain"],
    hint: ``,
    rating:[4.8,15102,1014],  
  },
  {
    date: "2024-09-13",
    brand: "Yandex",
    orientation: 0,
    title: "Cube Snake 2048: Combine, Grow, and Dominate in Snake Games",
    name: ["Cube Snake 2048", "cube-snake-2048"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cube-snake-2048/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/cube-snake-2048/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/cube-snake-2048.mp4",
    path: ["/game/cube-snake-2048", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/304104?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
    "Play Cube Snake 2048, an exciting twist on classic snake games! Collect cubes, combine numbers, and grow your snake in a massive arena.",
    l_dis:
      `A great Cube Snake 2048 game begins with cube snakes and cubes in a huge arena. You will start with a low number of snakes, collect lower numbered cubes around the map, and try to make your snake bigger by combining numbers. Collect power-ups and give your snake more advantages.
      <br><br>
      Get ready for a fresh take on the beloved Snake game with Cube Snake 2048! This unique blend of Snake and 2048 challenges you to grow your snake by collecting and merging numbered cubes in a lively 3D arena. Outplay your opponents, avoid the bigger snakes, and rise to the top by creating the biggest snake in the game.
      
      `,
      tag: ["Snake"],
    hint: `
      In this fast-paced game, you’ll collect cubes scattered across the arena. When two identical cubes collide, they merge into a single, larger number. As your snake grows longer, you’ll gain more power to swallow smaller snakes—but be careful! Bigger opponents are lurking around every corner, waiting to take your cubes.
      <h2 class="H2">Boost Your Strategy with Power-Ups</h2>
      Scattered across the arena are power-ups that can give your cube snake the edge it needs. Whether it’s boosting your speed or making you more maneuverable, these abilities can turn the tide of battle. Use them wisely to outpace your opponents or to escape a dangerous situation!
      <h2 class="H2">Stay Ahead of the Competition</h2>
      In Cube Snake 2048, survival is key. You’ll need to avoid bigger snakes while gobbling up smaller ones to grow stronger. Collect power-ups, merge your cubes, and climb the leaderboard to become the biggest snake on the field. The competition is fierce, and only the smartest snakes will survive!
      <h2 class="H2">Tips for Victory</h2>
      <ul>
      <li>Merge Cubes Quickly: Combining cubes of the same number makes you grow, so keep collecting and merging for a size advantage.</li>
      <li>Target Smaller Snakes: Take down your competition by absorbing snakes with lower-numbered cubes, but avoid larger ones.</li>
      <li>Use Power-Ups to Gain an Edge: Keep an eye out for speed boosts and other power-ups that can help you escape or chase down foes.</li>
      <li>Watch Out for Halving Blocks: Some blocks will reduce your snake’s length—so dodge them to keep growing!</li>
      </ul>
      <h2 class="H2">Features That Set Cube Snake 2048 Apart</h2>
      <ul>
      <li>Real-Time Multiplayer Arena: Compete with players from around the world in a massive arena.</li>
      <li>Addictive Merging Gameplay: The fusion of Snake and 2048 makes for a unique and engaging experience.</li>
      <li>Strategic Power-Ups: Collect special abilities to help you on your journey to becoming the largest snake.</li>
      <li>Leaderboard Challenges: Stay on top of the leaderboard by consistently growing your snake and outsmarting opponents.</li>
      </ul>
      <h2 class="H2">Dominate the Cube Snake 2048 Arena!</h2>
      Every match in <b>Cube Snake 2048</b> is an opportunity to grow bigger and challenge players from across the globe. Whether you’re speeding through the arena or merging cubes for maximum points, this game will keep you hooked. Become the biggest cube snake and see your name rise to the top of the leaderboard!
`,
    rating:[4.8,15102,1014],  
  },
  { date: "2025-01-22",brand: "playcutegames",title: "Love Tester: Fun Love Calculator Game",
    orientation: 0,
    hot: "1x1",
    name: ["Love Tester", "love-tester"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/love-tester/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/love-tester/520x295.png",
    path: ["/game/love-tester", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bestgamespot/love-tester/",
    Walkthrough: "",
    s_dis:
    "Discover your love percentage with Love Tester, the ultimate love calculator game. Perfect for Valentine Games fans, even on blocked networks!",
    l_dis:
      ` <p><strong>Love Tester</strong> is a delightful game where you can test the percentage of love between two names. Enter the names, and watch as the game calculates your love percentage with entertaining <strong>GIF animations</strong> for each result. Whether you’re looking to have fun with friends, test your compatibility, or enjoy a Valentine-themed game, Love Tester offers an engaging and amusing experience.</p>

    <h2 class="H22">What is Love Tester?</h2>
    <p><strong>Love Tester</strong> is an interactive <strong>love calculator</strong> game that uses names to generate a love percentage. Each result is accompanied by an animated GIF that reflects the percentage value, adding a fun and visual twist to the classic love calculator concept. It’s perfect for fans of <strong>Valentine Games</strong> or anyone looking to add a bit of lighthearted fun to their day.</p>

    <h2 class="H22">Key Features of Love Tester</h2>
    <ul>
        <li><strong>Love Percentage Calculator:</strong> Enter two names to see the calculated compatibility percentage.</li>
        <li><strong>Animated Results:</strong> Each percentage is paired with a fun and expressive GIF animation.</li>
        <li><strong>User-Friendly Interface:</strong> Simple controls make the game easy to play for all ages.</li>
        <li><strong>Valentine’s Theme:</strong> Perfect for celebrating love and fun on Valentine’s Day or any day.</li>
        <li><strong>Unblocked Access:</strong> Love Tester is accessible on blocked networks, ensuring you can play anytime, anywhere.</li>
    </ul>

    <h2 class="H22">How to Play Love Tester</h2>
    <p>Playing <strong>Love Tester</strong> is simple and enjoyable. Here’s how you can get started:</p>
    <ol>
        <li>Enter two names into the provided input fields.</li>
        <li>Click the “Calculate” button to generate the love percentage.</li>
        <li>Watch the animated GIF that appears, reflecting the love percentage.</li>
        <li>Share the results with friends or use them to spark laughter and conversation!</li>
    </ol>

    <h2 class="H22">Why Play Love Tester?</h2>
    <p><strong>Love Tester</strong> is more than just a <strong>love calculator</strong>. It’s a fun way to engage with friends, celebrate love, or enjoy a quick and lighthearted game. Here’s why it stands out:</p>
    <ul>
        <li><strong>Perfect for Valentine Games Fans:</strong> Enjoy a themed experience ideal for the season of love.</li>
        <li><strong>Entertaining Animations:</strong> Each result comes with a unique GIF that adds humor and excitement.</li>
        <li><strong>Accessible Anywhere:</strong> Play <strong>Love Tester</strong> even on restricted networks, thanks to its unblocked version.</li>
        <li><strong>Great for All Ages:</strong> Simple and playful, it’s suitable for kids, teens, and adults alike.</li>
    </ul>

    <h2 class="H22">Tips for Enjoying Love Tester</h2>
    <p>To get the most out of your <strong>Love Tester</strong> experience, keep these tips in mind:</p>
    <ul>
        <li><strong>Try Different Names:</strong> Experiment with friends’, family members’, or even fictional characters’ names for fun results.</li>
        <li><strong>Capture the Moment:</strong> Take screenshots of the funniest or most surprising results to share with others.</li>
        <li><strong>Play with a Group:</strong> Turn it into a party game and see who gets the highest compatibility score!</li>
    </ul>
    <h2 class="H22">Conclusion</h2>
    <p><strong>Love Tester</strong> is a fun and interactive <strong>Valentine Game</strong> that blends humor, creativity, and a touch of romance. Whether you’re curious about love compatibility, looking for a playful game to enjoy with friends, or just love <strong>love calculators</strong>, this game delivers. And with its unblocked version, you can enjoy the fun anytime, anywhere!</p>

      `,
      tag: ["Love","Valentine","Calculator","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  
  { date: "2025-01-22",brand: "playcutegames",title: "Kitty Couple Lovely Valentine – A Purr-fect Romantic Adventure",
    orientation: 0,
    hot: "2x2",
    name: ["Kitty Couple Lovely Valentine", "kitty-couple-lovely-valentine"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/kitty-couple-lovely-valentine/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/kitty-couple-lovely-valentine/520x295.png",
    path: ["/game/kitty-couple-lovely-valentine", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/kitty-couple-lovely-valentine/",
    Walkthrough: "",
    s_dis:
    "Celebrate love with Kitty Couple Lovely Valentine. Help Angela and Tom prepare for Valentine’s Day in this fun Talking Tom game. Play Valentine Games Unblocked!",
    l_dis:
      `<p>Celebrate love with the adorable kitty duo Angela and Tom in <strong>Kitty Couple Lovely Valentine</strong>. This charming game takes players on a delightful journey filled with fashion, hidden surprises, and puzzles. Help the cute couple prepare for their special Valentine’s Day by dressing them up, uncovering hidden hearts, and completing their couple photo puzzle.</p>

    <h2 class="H22">Game Overview</h2>
    <p><strong>Kitty Couple Lovely Valentine</strong> is a heartwarming game that combines dress-up, hidden object challenges, and jigsaw puzzles. With three unique levels, players can dive into the world of Angela and Tom, bringing their love story to life.</p>

    <h2 class="H22">Game Levels</h2>
    <ul>
        <li><strong>Dress-Up for Kitty Couples:</strong> Style Angela and Tom in the perfect Valentine outfits. Choose from a variety of adorable clothing and accessories to create the ultimate romantic look.</li>
        <li><strong>Find the Hidden Hearts:</strong> Explore the background scenes and search for hidden hearts. Put your observation skills to the test and uncover all the hearts to move forward.</li>
        <li><strong>Solve the Jigsaw Puzzle:</strong> Piece together their lovely couple photo. Solve the puzzle to reveal a heartwarming image of Angela and Tom, perfect for Valentine’s Day.</li>
    </ul>

    <h2 class="H22">Why Play Kitty Couple Lovely Valentine?</h2>
    <p><strong>Kitty Couple Lovely Valentine</strong> is the perfect way to celebrate love and enjoy a variety of fun challenges. Whether you’re a fan of <strong>Talking Tom</strong> games, Valentine’s Day-themed games, or hidden object puzzles, this game offers something for everyone:</p>
    <ul>
        <li><strong>Adorable Characters:</strong> Help the lovable couple Angela and Tom get ready for a magical Valentine’s Day.</li>
        <li><strong>Fashion Fun:</strong> Style the kitties in cute, romantic outfits for the perfect celebration look.</li>
        <li><strong>Challenging Puzzles:</strong> Test your observation skills by finding hidden hearts and solving jigsaw puzzles.</li>
        <li><strong>Unblocked Access:</strong> Play <strong>Kitty Couple Lovely Valentine</strong> anywhere, even in restricted environments, with the unblocked version.</li>
    </ul>

    <h2 class="H22">Tips for Completing the Game</h2>
    <ul>
        <li><strong>Be Thorough:</strong> Pay close attention to the details of each scene when searching for hidden hearts.</li>
        <li><strong>Experiment with Outfits:</strong> Try different combinations of clothing and accessories to create the perfect look for Angela and Tom.</li>
        <li><strong>Take Your Time on the Puzzle:</strong> The jigsaw puzzle may take a bit of time, but enjoy piecing together the cute couple’s photo for a heartwarming reward.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>Kitty Couple Lovely Valentine</strong> is a delightful blend of fashion, puzzles, and fun, making it the perfect Valentine’s Day-themed game for fans of <strong>Talking Tom</strong> and hidden object games. Whether you’re dressing up the adorable couple, searching for hidden hearts, or solving puzzles, you’ll enjoy every minute of this charming adventure. Play the unblocked version anytime, anywhere, and join Angela and Tom on their romantic journey this Valentine’s Day!</p>
    `,
      tag: ["Love","Valentine","Calculator","Angela","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  
  { date: "2025-01-22",brand: "playcutegames",title: "Santa’s XO Wonderland: A Festive Tic-Tac-Toe Adventure",
    orientation: 0,
    name: ["Santa’s XO Wonderland", "santas-xo-wonderland"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/santas-xo-wonderland/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/santas-xo-wonderland/520x295.png",
    path: ["/game/santas-xo-wonderland", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bestgamespot/santas-xo-wonderland/",
    Walkthrough: "",
    s_dis:
    "Celebrate the holidays with Santa’s XO Wonderland! Play festive Tic-Tac-Toe featuring Santa and Grinch heads. Enjoy Christmas Games unblocked anytime!",
    l_dis:
      `  <p>Get ready to bring holiday cheer to your gaming sessions with <strong>Santa’s XO Wonderland</strong>, a delightful holiday-themed twist on the classic <strong>Tic-Tac-Toe</strong>. Featuring vibrant, festive boards adorned with Santa heads and Grinch heads, this game is perfect for spreading joy and fun during the Christmas season. Whether you’re playing with friends or challenging AI, <strong>Santa’s XO Wonderland</strong> offers an entertaining and strategic way to celebrate the holidays.</p>

    <h2 class="H22">What is Santa’s XO Wonderland?</h2>
    <p><strong>Santa’s XO Wonderland</strong> is a holiday-themed <strong>Tic-Tac-Toe</strong> game that transforms the traditional grid into a magical Christmas experience. Players choose between Team Santa and Team Grinch, using their wits and strategy to outsmart their opponents. The game is designed for all ages, combining festive visuals with timeless gameplay mechanics to deliver endless holiday fun.</p>

    <h2 class="H22">Key Features of Santa’s XO Wonderland</h2>
    <ul>
        <li><strong>Festive Themes:</strong> Play on colorful boards featuring Santa and Grinch-themed tokens.</li>
        <li><strong>Multiplayer Mode:</strong> Challenge friends or family for some holiday rivalry.</li>
        <li><strong>AI Opponents:</strong> Test your skills against computer opponents with varying difficulty levels.</li>
        <li><strong>Unblocked Version:</strong> Play <strong>Santa’s XO Wonderland</strong> anytime, even on restricted networks.</li>
        <li><strong>Holiday Spirit:</strong> Immerse yourself in the magic of Christmas with every move.</li>
    </ul>

    <h2 class="H22">How to Play Santa’s XO Wonderland</h2>
    <p>Playing <strong>Santa’s XO Wonderland</strong> is as simple as it is fun. Here’s how to get started:</p>
    <ol>
        <li>Select your team: Choose between Team Santa or Team Grinch.</li>
        <li>Take turns placing your tokens (Santa or Grinch heads) on the grid.</li>
        <li>Try to align three tokens in a row, column, or diagonal to win the game.</li>
        <li>Enjoy the festive animations and celebrate your victories!</li>
    </ol>

    <h2 class="H22">Why Play Santa’s XO Wonderland?</h2>
    <p><strong>Santa’s XO Wonderland</strong> offers a unique and joyful twist on the classic <strong>Tic-Tac-Toe</strong>. Here’s why it’s a must-play this holiday season:</p>
    <ul>
        <li><strong>Perfect Christmas Game:</strong> Enjoy the festive vibes with Santa and Grinch-themed gameplay.</li>
        <li><strong>Fun for All Ages:</strong> Simple mechanics make it accessible and entertaining for players of all skill levels.</li>
        <li><strong>Unblocked Access:</strong> Play <strong>Santa’s XO Wonderland</strong> anywhere, even in schools or workplaces.</li>
        <li><strong>Engaging Strategy:</strong> Sharpen your tactics and decision-making skills while having fun.</li>
        <li><strong>Great for Gatherings:</strong> Add a dose of friendly competition to your holiday parties and events.</li>
    </ul>

    <h2 class="H22">Tips for Winning at Santa’s XO Wonderland</h2>
    <p>Maximize your chances of victory with these tips:</p>
    <ul>
        <li><strong>Start Strong:</strong> Take the center square when possible to increase your winning combinations.</li>
        <li><strong>Think Ahead:</strong> Anticipate your opponent’s moves to block their winning opportunities.</li>
        <li><strong>Stay Flexible:</strong> Adapt your strategy based on the game’s progress to maintain control.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p>Celebrate the magic of Christmas with <strong>Santa’s XO Wonderland</strong>, a charming and festive take on <strong>Tic-Tac-Toe</strong>. Whether you’re enjoying some downtime, hosting a holiday gathering, or looking for a fun and strategic game, this delightful <strong>Christmas Game</strong> delivers endless entertainment. Best of all, the unblocked version ensures you can join the fun anywhere, anytime. Play now and let the holiday rivalry begin!</p>

      `,
      tag: ["Santa","Christmas","Tic Tac Toe","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
    { date: "2025-01-20",brand: "playcutegames",title: "Nightmare Couple Eternal Love: A Spooky Love Story Unfolds",
    orientation: 0,
    // hot: "2x2",
    name: ["Nightmare Couple Eternal Love", "nightmare-couple-eternal-love"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/nightmare-couple-eternal-love/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/nightmare-couple-eternal-love/520x295.png",
    path: ["/game/nightmare-couple-eternal-love", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/nightmare-couple-eternal-love/",
    Walkthrough: "https://www.youtube.com/embed/bwJOjGXIGqM",
    s_dis:
    "Explore the enchanting world of Nightmare Couple Eternal Love. Dive into gothic fashion, eerie settings, and a captivating love story. Perfect for fans of Valentine Games and Dress Up Games.",
    l_dis:
      ` 
 <p>Step into the hauntingly beautiful world of <strong>Nightmare Couple Eternal Love</strong>, where romance and spookiness collide. This unique dress-up game lets you explore gothic fashion, eerie backdrops, and the enchanting love between a mysterious couple. Whether you’re a fan of Halloween aesthetics or captivating storytelling, this game promises an unforgettable experience.</p>

    <h2 class="H22">About Nightmare Couple Eternal Love</h2>
    <p>In <strong>Nightmare Couple Eternal Love</strong>, players dress up a hauntingly charming couple who share an eternal bond. Customize their looks with stunning gothic outfits, intricate accessories, and spooky hairstyles to create the ultimate romantic vibe. The game offers a rich experience that combines fashion, creativity, and storytelling.</p>

    <h2 class="H22">Key Features of Nightmare Couple Eternal Love</h2>
    <ul>
        <li><strong>Gothic Fashion:</strong> Explore a wide range of gothic outfits and accessories to dress the couple in hauntingly beautiful styles.</li>
        <li><strong>Creative Customization:</strong> Mix and match clothing, hairstyles, and accessories to craft the perfect spooky look.</li>
        <li><strong>Captivating Story:</strong> Immerse yourself in the romantic tale of an eternal love that transcends time.</li>
        <li><strong>Eerie Backdrops:</strong> Choose from various gothic and spooky settings to set the scene for your creations.</li>
        <li><strong>Accessible Gameplay:</strong> Intuitive controls make it easy for players of all ages to enjoy the game.</li>
    </ul>

    <h2 class="H22">Why Play Nightmare Couple Eternal Love?</h2>
    <p><strong>Nightmare Couple Eternal Love</strong> is a perfect blend of romance, fashion, and a touch of the macabre. Here’s why you should dive into this enchanting game:</p>
    <ul>
        <li><strong>Unique Theme:</strong> Stand out from typical dress-up games with its gothic and spooky aesthetic.</li>
        <li><strong>Perfect for Valentine Games Fans:</strong> Offers a romantic twist with a dark and enchanting storyline.</li>
        <li><strong>Creative Freedom:</strong> Allows endless customization, letting you express your style and creativity.</li>
        <li><strong>Great for Dress Up Games Enthusiasts:</strong> Provides a fresh and exciting take on traditional dress-up games.</li>
    </ul>

    <h2 class="H22">Tips for Enjoying Nightmare Couple Eternal Love</h2>
    <p>Maximize your enjoyment of <strong>Nightmare Couple Eternal Love</strong> with these tips:</p>
    <ul>
        <li><strong>Experiment with Styles:</strong> Don’t be afraid to mix different elements to create a unique gothic look.</li>
        <li><strong>Explore All Options:</strong> Try out various backdrops and accessories to enhance your characters’ stories.</li>
        <li><strong>Share Your Creations:</strong> Show off your styled couple by sharing screenshots with friends or on social media.</li>
    </ul>
    
    <h2 class="H22">Conclusion</h2>
    <p><strong>Nightmare Couple Eternal Love</strong> offers a captivating mix of romance and gothic fashion. Perfect for fans of <strong>Valentine Games</strong> and <strong>Dress Up Games</strong>, it provides a unique and enchanting experience that’s sure to delight. Dive into the spooky love story and unleash your creativity!</p>


      `,
      tag: ["Love","Valentine","Dress up","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2025-01-20",
    brand: "playcutegames",
    orientation: 0,
    title: "Ellie and Ben Christmas Eve: A Festive Celebration",
    name: ["Ellie and Ben Christmas Eve", "ellie-and-ben-christmas-eve"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-ben-christmas-eve/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-ben-christmas-eve/520x295.png",
    path: ["/game/ellie-and-ben-christmas-eve", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ellie-and-ben-christmas-eve/",
    Walkthrough: "https://www.youtube.com/embed/zhNfPzcehrM",
    s_dis:
    "Join Ellie and Ben in a festive celebration with this charming Christmas game. Enjoy decorating, dressing up, and preparing for a magical Christmas Eve.",
    l_dis:
      ` <p>Celebrate the magic of the holiday season with <strong>Ellie and Ben Christmas Eve</strong>, a delightful game where players join Ellie and Ben as they prepare for the most wonderful night of the year. From decorating the tree to picking the perfect outfits, this game brings the charm of Christmas to life in a fun and interactive way.</p>

    <h2 class="H22">About Ellie and Ben Christmas Eve</h2>
    <p><strong>Ellie and Ben Christmas Eve</strong> is a dress-up and decoration game that immerses players in the festive spirit. Help Ellie and Ben create a memorable Christmas Eve by completing holiday-themed activities, such as decorating their home, preparing gifts, and choosing stylish outfits for the celebration.</p>

    <h2 class="H22">Key Features of Ellie and Ben Christmas Eve</h2>
    <ul>
        <li><strong>Festive Decoration:</strong> Decorate the Christmas tree and home with a variety of ornaments and festive items.</li>
        <li><strong>Stylish Dress-Up:</strong> Choose from a range of holiday-themed outfits to dress Ellie and Ben for the celebration.</li>
        <li><strong>Interactive Activities:</strong> Engage in fun tasks like gift wrapping and preparing for the Christmas Eve party.</li>
        <li><strong>Charming Graphics:</strong> Enjoy beautiful visuals that capture the essence of the holiday season.</li>
        <li><strong>User-Friendly Gameplay:</strong> Easy-to-use controls make it perfect for players of all ages.</li>
    </ul>

    <h2 class="H22">Why Play Ellie and Ben Christmas Eve?</h2>
    <p><strong>Ellie and Ben Christmas Eve</strong> is more than just a game; it’s a way to experience the joy of Christmas in an interactive and engaging format. Here’s why you should give it a try:</p>
    <ul>
        <li><strong>Immersive Holiday Experience:</strong> Get into the Christmas spirit with festive activities and decorations.</li>
        <li><strong>Creative Freedom:</strong> Customize Ellie and Ben’s outfits and home decorations to match your holiday style.</li>
        <li><strong>Fun for All Ages:</strong> The simple gameplay makes it accessible and enjoyable for both kids and adults.</li>
        <li><strong>Perfect for Fans of Barbie Games:</strong> If you love <strong>Barbie Games</strong>, you’ll adore this festive addition.</li>
    </ul>

    <h2 class="H22">Tips for Enjoying Ellie and Ben Christmas Eve</h2>
    <p>Maximize your fun in <strong>Ellie and Ben Christmas Eve</strong> with these tips:</p>
    <ul>
        <li><strong>Explore All Options:</strong> Take your time to explore the different decoration and outfit choices available.</li>
        <li><strong>Get Creative:</strong> Mix and match outfits and decorations to create a unique holiday look.</li>
        <li><strong>Complete All Activities:</strong> Make sure to complete all the festive tasks to fully enjoy the Christmas Eve celebration.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>Ellie and Ben Christmas Eve</strong> is a charming and interactive way to celebrate the holiday season. With its delightful blend of dress-up and decoration activities, it’s the perfect game for fans of <strong>Barbie Games</strong> and <strong>Christmas Games</strong>. Dive into the festive fun and help Ellie and Ben create an unforgettable Christmas Eve!</p>

      `,
      tag: ["Barbie","Christmas","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  { date: "2025-01-22",brand: "playcutegames",title: "Ellie and Friends Venice Carnival – Join the Festivities with Style!",
    orientation: 0,
    name: ["Ellie and Friends Venice Carnival", "ellie-and-friends-venice-carnival"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-venice-carnival/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-venice-carnival/520x295.png",
    path: ["/game/ellie-and-friends-venice-carnival", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ellie-and-friends-venice-carnival/",
    Walkthrough: "",
    s_dis:
    "Join Ellie and her friends in Venice Carnival Dressup! Create magical looks with stunning costumes, masks, and accessories. Play unblocked now!",
    l_dis:
      `<p>Step into the glamorous world of the <strong>Venice Carnival</strong> with <strong>Ellie and Friends Venice Carnival</strong>, a delightful dress-up game that brings the magic of this iconic celebration to life. Join Ellie and her friends as they prepare for one of the most extravagant events of the year. From stunning masks to luxurious costumes, it’s all about creating the perfect look for the big night. Whether you’re a fan of <strong>Barbie games</strong>, <strong>dressup games</strong>, or simply love the elegance of the Venice Carnival, this game offers a dazzling experience.</p>

    <h2 class="H22">What is Ellie and Friends Venice Carnival?</h2>
    <p><strong>Ellie and Friends Venice Carnival</strong> is an exciting dress-up game that lets you immerse yourself in the vibrant atmosphere of the Venice Carnival. Your task is to design stunning looks for Ellie and her friends as they get ready to join the festivities. Choose from a wide variety of glamorous costumes, beautiful masks, and elegant accessories to create unique carnival styles that shine.</p>

    <h2 class="H22">Features of Ellie and Friends Venice Carnival</h2>
    <ul>
        <li><strong>Stunning Costumes:</strong> Choose from a collection of luxurious dresses and outfits inspired by the Venice Carnival.</li>
        <li><strong>Elegant Masks:</strong> Add a touch of mystery with beautifully designed masks in different colors and patterns.</li>
        <li><strong>Accessories Galore:</strong> Complete the look with sparkling jewelry, stylish hats, and chic shoes.</li>
        <li><strong>Creative Freedom:</strong> Mix and match clothing and accessories to create magical and unique styles.</li>
        <li><strong>Unblocked Access:</strong> Play the game anytime, anywhere, even on school or office networks.</li>
    </ul>

    <h2 class="H22">How to Play Ellie and Friends Venice Carnival</h2>
    <p>Playing <strong>Ellie and Friends Venice Carnival</strong> is simple and fun. Follow these steps to get started:</p>
    <ul>
        <li><strong>Start the Game:</strong> Select Ellie or one of her friends to style for the Venice Carnival.</li>
        <li><strong>Pick Outfits:</strong> Browse through the wardrobe to find the perfect dresses, skirts, or gowns.</li>
        <li><strong>Choose Accessories:</strong> Add masks, jewelry, shoes, and other accessories to complete the look.</li>
        <li><strong>Save Your Style:</strong> Capture your favorite designs and share them with friends.</li>
        <li><strong>Repeat:</strong> Style all the characters and create as many carnival-ready looks as you like!</li>
    </ul>

    <h2 class="H22">Why Play Ellie and Friends Venice Carnival?</h2>
    <p>If you love <strong>dressup games</strong>, <strong>Barbie games</strong>, or creative challenges, <strong>Ellie and Friends Venice Carnival</strong> is the perfect choice. Here’s why:</p>
    <ul>
        <li><strong>Immerse in Carnival Glamour:</strong> Experience the magic and excitement of the <strong>Venice Carnival</strong>.</li>
        <li><strong>Unleash Creativity:</strong> Experiment with endless costume combinations and designs.</li>
        <li><strong>Play Anywhere:</strong> With <strong>unblocked games</strong>, you can enjoy this game anytime, anywhere.</li>
        <li><strong>Perfect for All Ages:</strong> Whether you’re a kid or a fashion enthusiast, this game offers hours of fun and creativity.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>Ellie and Friends Venice Carnival</strong> is your ticket to a world of elegance, creativity, and celebration. Whether you’re designing stunning outfits or experimenting with glamorous accessories, this game lets you live out your fashion dreams. Perfect for fans of <strong>Venice Carnival</strong>, <strong>dressup games</strong>, and <strong>Barbie games</strong>, it promises endless fun and creativity. Play <strong>Ellie and Friends Venice Carnival</strong> unblocked and join the festivities today!</p>
    `,
      tag: ["barbie"," Venice","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2025-01-16",
    brand: "Yandex",
    orientation: 0,
    title: "Slap Battles: The Ultimate Slapping Showdown",
    name: ["Slap Battles.io", "slap-battles-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/slap-battles-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/slap-battles-io/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/slap-battles-io.mp4",
    path: ["/game/slap-battles-io", "https://playcutegames.com"],
    iframe: "https://slapbattles.io",
    Walkthrough: "https://www.youtube.com/embed/riilLCDgf-s",
    s_dis:"Join Slap Battles.io for hilarious multiplayer slap duels. Play the unblocked version anywhere and experience the ultimate slapping showdown.",
    l_dis:`<p><strong>Slap Battles</strong> is a unique, fast-paced multiplayer game where players engage in hilarious slap duels. Compete with friends or strangers online, test your reflexes, and strategize to out-slap your opponents. With a variety of slap gloves, unique abilities, and dynamic gameplay, <strong>Slap Battles.io</strong> offers endless fun and challenges.</p>
    <p>Enjoy unrestricted gameplay with <strong>Slap Battles Unblocked</strong>, which allows you to access the game from anywhere, even in places with restrictions.</p>

    <h2 class="H22">What is Slap Battles?</h2>
    <p><strong>Slap Battles</strong> is an exciting online multiplayer game that pits players against each other in humorous slap battles. The game’s objective is simple: use your slap glove to slap opponents off the platform while avoiding being slapped off yourself. Each match is filled with laughter and chaos as players use different gloves, each with special abilities, to gain the upper hand.</p>

    <h2 class="H22">Key Features of Slap Battles</h2>
    <ul>
        <li><strong>Hilarious Gameplay:</strong> Engage in over-the-top slapping battles that are both fun and competitive.</li>
        <li><strong>Unique Slap Gloves:</strong> Unlock and use gloves with special abilities to gain a strategic edge over opponents.</li>
        <li><strong>Multiplayer Mode:</strong> Challenge friends or other players in exciting online battles.</li>
        <li><strong>Dynamic Arenas:</strong> Compete in a variety of interactive environments that add to the fun and challenge.</li>
        <li><strong>Unblocked Version:</strong> Play <strong>Slap Battles Unblocked</strong> on restricted networks like schools or workplaces.</li>
    </ul>

    <h2 class="H22">Why Play Slap Battles?</h2>
    <p><strong>Slap Battles</strong> offers a fresh take on multiplayer <strong>IoGames</strong> with its unique blend of humor and action. Here’s why you should give it a try:</p>
    <ul>
        <li><strong>Easy to Learn, Fun to Master:</strong> The simple controls make it easy to jump in, but mastering the game requires skill and strategy.</li>
        <li><strong>Customizable Gameplay:</strong> With a variety of gloves and abilities, each game feels different and exciting.</li>
        <li><strong>Community Interaction:</strong> Compete with players worldwide and enjoy the vibrant community of <strong>Battles Games</strong>.</li>
        <li><strong>Accessible Anywhere:</strong> The unblocked version ensures you can play anytime, no matter where you are.</li>
    </ul>

    <h2 class="H22">Tips for Winning in Slap Battles</h2>
    <p>Mastering <strong>Slap Battles</strong> requires a mix of strategy and quick reflexes. Here are some tips to help you become the ultimate slapper:</p>
    <ul>
        <li><strong>Choose the Right Glove:</strong> Each glove has unique abilities; find one that suits your playstyle.</li>
        <li><strong>Stay Mobile:</strong> Keep moving to avoid being an easy target for slaps.</li>
        <li><strong>Use the Environment:</strong> Take advantage of the dynamic arenas to gain an edge over your opponents.</li>
        <li><strong>Practice Timing:</strong> Perfect your timing to land the most effective slaps and avoid counterattacks.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>Slap Battles</strong> is a hilarious and engaging addition to the world of <strong>IoGames</strong> and <strong>Battles Games</strong>. With its unique mechanics, customizable gameplay, and unblocked access, it’s a game that offers endless entertainment. Whether you’re looking to challenge friends or unwind with some lighthearted competition, <strong>Slap Battles.io</strong> has something for everyone. Start slapping and join the fun today!</p>
 `,
    tag: ["Io","Slap","Action","Battle",],
    rating:[4.4,2512,142],  
  },
  {
    date: "2025-01-16",
    brand: "Yandex",
    orientation: 0,
    title: "Watermelon Game: Fun, Addictive, and Refreshing",
    name: ["Watermelon Game", "watermelon-game"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/watermelon-game/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/watermelon-game/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/watermelon-game.mp4",
    path: ["/game/watermelon-game", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/277092?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/riilLCDgf-s",
    s_dis:"Dive into the Watermelon Game, a fun and addictive slicing adventure. Enjoy the unblocked version and play anytime, anywhere.",
    l_dis:`
    <p>The <strong>Watermelon Game</strong> is a delightful, engaging online game that lets you slice and dice juicy watermelons in creative and exciting ways. The game is simple yet addictive, with you controlling a blade to cut watermelons while avoiding obstacles and aiming for the perfect slice. Whether you’re looking to pass the time or improve your reflexes, <strong>Watermelon Game Unblocked</strong> offers an opportunity to enjoy the game anywhere, even in restricted environments like schools or workplaces.</p>

    <h2 class="H22">What is the Watermelon Game?</h2>
    <p>The <strong>Watermelon Game</strong> is a casual, arcade-style game where your main objective is to slice watermelons as they appear on the screen. Using precise timing and quick reflexes, you need to slice through the fruit while avoiding bombs and other obstacles. The game’s simple mechanics and vibrant graphics make it an enjoyable experience for players of all ages. Its unblocked version ensures you can play without restrictions, adding to its appeal as a quick and fun pastime.</p>

    <h2 class="H22">Key Features of Watermelon Game</h2>
    <ul>
        <li><strong>Simple Controls:</strong> Easy-to-use controls make the game accessible for all players, regardless of skill level.</li>
        <li><strong>Addictive Gameplay:</strong> The challenge of achieving high scores keeps players coming back for more.</li>
        <li><strong>Vibrant Graphics:</strong> Colorful and engaging visuals make the slicing action satisfying and fun.</li>
        <li><strong>Unblocked Access:</strong> Enjoy the game anywhere, even on restricted networks, with the <strong>Watermelon Game Unblocked</strong> version.</li>
        <li><strong>Multiple Levels:</strong> Progress through various levels, each with increasing difficulty and new challenges.</li>
    </ul>

    <h2 class="H22">Why Play the Watermelon Game?</h2>
    <p>There are several reasons why the <strong>Watermelon Game</strong> has become a favorite among <strong>addictive games</strong>:</p>
    <ul>
        <li><strong>Relaxing Fun:</strong> The game’s simple yet engaging mechanics make it a great way to unwind and have fun.</li>
        <li><strong>Quick Sessions:</strong> Perfect for short gaming sessions, whether you’re on a break or looking to pass the time.</li>
        <li><strong>Challenge Yourself:</strong> Compete against your own high scores or challenge friends to see who can slice the most watermelons.</li>
        <li><strong>Universal Appeal:</strong> Suitable for players of all ages, making it a family-friendly game.</li>
    </ul>

    <h2 class="H22">Tips for Mastering the Watermelon Game</h2>
    <p>Boost your skills and achieve high scores with these tips:</p>
    <ul>
        <li><strong>Practice Timing:</strong> Focus on your timing to make precise cuts and avoid bombs.</li>
        <li><strong>Stay Focused:</strong> Keep your eyes on the screen to anticipate incoming watermelons and obstacles.</li>
        <li><strong>Use Power-Ups:</strong> Take advantage of any power-ups available to maximize your slicing potential.</li>
        <li><strong>Stay Calm:</strong> Avoid rushing, as panicking can lead to mistakes and missed opportunities.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p>The <strong>Watermelon Game</strong> is a refreshing and fun addition to the world of <strong>watermelon games</strong> and <strong>addictive games</strong>. With its simple controls, vibrant graphics, and engaging gameplay, it offers endless entertainment. Whether you’re a casual player or a dedicated gamer, the <strong>Watermelon Game Unblocked</strong> version ensures you can enjoy this slicing adventure anytime, anywhere. Start slicing and experience the fun today!</p>


    `,
    tag: ["Watermelon","Merg","2048",],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "Others",
    orientation: 0,
    title: "BasketBros: A Fast-Paced Basketball Game",
    name: ["BasketBros", "basketbros"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/basketbros/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/basketbros/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/basketbros.mp4",
    path: ["/game/basketbros", "https://playcutegames.com"],
    iframe: "https://soccer-random.github.io/a7/basket-bros",
    Walkthrough: "https://www.youtube.com/embed/0t0VC2bBNII",
    s_dis:"Dive into BasketBros, a fast-paced 1v1 basketball game with quirky characters and smooth animations. Play unblocked anywhere!",
    l_dis:`
    <p><strong>BasketBros</strong> is an exciting, fast-paced basketball game that brings intense 1v1 action to your fingertips. With its quirky characters, smooth animations, and competitive gameplay, BasketBros has become a favorite among sports gaming enthusiasts. The game offers a mix of skill, strategy, and fun, making it an enjoyable experience for both casual and competitive players. The <strong>BasketBros Unblocked</strong> version allows you to enjoy this energetic basketball game anywhere, including school or work, without restrictions.</p>

    <h2 class="H22">What is BasketBros?</h2>
    <p><strong>BasketBros</strong> is a 1v1 basketball game that combines fast-paced action with quirky, animated characters. The goal is to outscore your opponent in a high-energy match that tests your basketball skills and strategic thinking. The game features simple controls, making it easy to pick up, but offers plenty of depth for players who want to master the game.</p>

    <h2 class="H22">Key Features of BasketBros</h2>
    <ul>
        <li><strong>Fast-Paced Gameplay:</strong> Engage in quick, intense basketball matches that keep you on your toes.</li>
        <li><strong>Unique Characters:</strong> Choose from a variety of quirky characters, each with their own style and abilities.</li>
        <li><strong>Simple Controls:</strong> Easy-to-learn controls make the game accessible for players of all skill levels.</li>
        <li><strong>Competitive Multiplayer:</strong> Challenge friends or compete against players worldwide in thrilling 1v1 matches.</li>
        <li><strong>Unblocked Access:</strong> The <strong>BasketBros Unblocked</strong> version ensures you can play the game on restricted networks.</li>
    </ul>

    <h2 class="H22">Why Play BasketBros?</h2>
    <p><strong>BasketBros</strong> stands out among <strong>basketball games</strong> for several reasons:</p>
    <ul>
        <li><strong>Engaging Matches:</strong> Each match is packed with excitement, making it a perfect choice for quick gaming sessions.</li>
        <li><strong>Accessible Fun:</strong> Whether you’re a seasoned gamer or new to basketball games, BasketBros offers fun for everyone.</li>
        <li><strong>Strategic Depth:</strong> Master the game’s mechanics to outmaneuver opponents and climb the leaderboards.</li>
        <li><strong>Play Anywhere:</strong> With the unblocked version, you can enjoy BasketBros at school, work, or any place with network restrictions.</li>
    </ul>

    <h2 class="H22">Tips for Mastering BasketBros</h2>
    <p>Boost your skills and dominate the court with these tips:</p>
    <ul>
        <li><strong>Practice Makes Perfect:</strong> Spend time mastering the controls to improve your in-game performance.</li>
        <li><strong>Know Your Character:</strong> Each character has unique strengths—choose one that matches your playstyle.</li>
        <li><strong>Stay Agile:</strong> Use quick movements and smart plays to keep your opponent guessing.</li>
        <li><strong>Compete Online:</strong> Playing against real players in multiplayer mode is a great way to test and refine your skills.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>BasketBros</strong> is a must-try for fans of <strong>basketball games</strong> and anyone looking for a fun, fast-paced gaming experience. With its quirky characters, smooth gameplay, and unblocked access, BasketBros offers endless entertainment for players everywhere. Jump into the action and see if you have what it takes to become the ultimate BasketBro!</p>

    `,
    tag: ["Multiplayer","Soccer", "Football","Sports"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "Others",
    orientation: 0,
    title: "Soccer Bros: A Unique Soccer Adventure",
    name: ["Soccer Bros", "soccer-bros"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/soccer-bros/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/soccer-bros/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/soccer-bros.mp4",
    path: ["/game/soccer-bros", "https://playcutegames.com"],
    iframe: "https://soccerbros.gg/",
    Walkthrough: "https://www.youtube.com/embed/7Rwz-1DaeQ8",
    s_dis:"Dive into Soccer Bros, a physics-based soccer game with fun twists. Play unblocked for thrilling soccer action anywhere!",
    l_dis:`
       <p><strong>Soccer Bros</strong> takes soccer gaming to an exciting new level, blending quirky physics, fast-paced action, and hilarious gameplay. Whether you’re a soccer enthusiast or just looking for a fun way to pass the time, Soccer Bros delivers an entertaining and challenging experience. Perfect for solo play or multiplayer fun, this game lets you kick, flip, and score goals like never before. With the <strong>Soccer Bros Unblocked</strong> version, you can enjoy the thrill of soccer gaming from anywhere, even in places with access restrictions.</p>

    <h2 class="H22">What is Soccer Bros?</h2>
    <p><strong>Soccer Bros</strong> is a physics-based soccer game where players control characters in a chaotic and entertaining soccer match. The objective is to score more goals than your opponent, but with a twist—unpredictable physics and unique mechanics make every game an exciting challenge. Whether playing solo or in multiplayer mode, Soccer Bros offers endless fun and replayability.</p>

    <h2 class="H22">Key Features of Soccer Bros</h2>
    <ul>
        <li><strong>Fun Physics-Based Gameplay:</strong> Master the unpredictable physics to outsmart your opponents and make every match a hilarious challenge.</li>
        <li><strong>Multiplayer Mode:</strong> Play with friends or compete against others in exhilarating matches that test your skills and strategy.</li>
        <li><strong>Unique Soccer Mechanics:</strong> Perform crazy kicks, spins, and even somersaults to score goals in the most creative ways possible.</li>
        <li><strong>Variety of Characters:</strong> Choose from different players, each with unique traits and abilities, adding variety to every game.</li>
        <li><strong>Unblocked Access:</strong> The <strong>Soccer Bros Unblocked</strong> version ensures the game is accessible on restricted networks, so you can play anytime, anywhere.</li>
    </ul>

    <h2 class="H22">Why Play Soccer Bros?</h2>
    <p>Here’s why <strong>Soccer Bros</strong> stands out among <strong>soccer games</strong>:</p>
    <ul>
        <li><strong>Engaging Gameplay:</strong> The combination of soccer and physics creates a unique and captivating experience that keeps players coming back for more.</li>
        <li><strong>Accessible Fun:</strong> Whether you’re a casual player or a hardcore soccer fan, Soccer Bros offers gameplay that’s easy to learn but hard to master.</li>
        <li><strong>Competitive Edge:</strong> Challenge friends or face off against players worldwide in competitive multiplayer matches.</li>
        <li><strong>Unblocked Version:</strong> Enjoy the game without restrictions, making it perfect for gaming during school breaks or at work.</li>
    </ul>

    <h2 class="H22">Tips for Mastering Soccer Bros</h2>
    <p>Maximize your fun and improve your skills with these tips:</p>
    <ul>
        <li><strong>Practice Makes Perfect:</strong> Spend time getting used to the physics and controls to master the unique gameplay mechanics.</li>
        <li><strong>Stay Agile:</strong> Keep moving and experimenting with different moves to find the best strategies for scoring goals.</li>
        <li><strong>Utilize Character Abilities:</strong> Each character has unique traits; experiment with them to find the one that best suits your playstyle.</li>
        <li><strong>Play Multiplayer:</strong> Multiplayer mode offers a dynamic and competitive environment that can help sharpen your skills.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>Soccer Bros</strong> is a must-play for fans of <strong>soccer games</strong> and anyone looking for a fun, chaotic, and entertaining gaming experience. With its physics-based gameplay, unique characters, and unblocked access, Soccer Bros is perfect for both casual gaming sessions and intense multiplayer battles. Step onto the field and enjoy the unique soccer adventure that Soccer Bros has to offer!</p>

    `,
    tag: ["Multiplayer","Soccer", "Football","Sports"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "Others",
    orientation: 0,
    title: "Wrestle Bros: A Wild Wrestling Showdown",
    name: ["Wrestle Bros", "wrestle-bros"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/wrestle-bros/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/wrestle-bros/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/wrestle-bros.mp4",
    path: ["/game/wrestle-bros", "https://playcutegames.com"],
    iframe: "https://wrestlebros.io/",
    Walkthrough: "https://www.youtube.com/embed/G51qMnqptZw",
    s_dis:"Dive into Wrestle Bros, a physics-based wrestling game filled with hilarious moves and unpredictable gameplay. Play unblocked anywhere!",
    l_dis:`
    <p><strong>Wrestle Bros</strong> is an action-packed, physics-based wrestling game that brings chaos and laughter to your screen. With its hilarious moves, unpredictable gameplay, and quirky characters, Wrestle Bros offers a unique and entertaining experience for players of all ages. Whether you’re playing solo or challenging friends, the fun never stops in this wrestling arena. The <strong>Wrestle Bros Unblocked</strong> version ensures you can enjoy the game anywhere, including in places with restricted access, such as schools or workplaces.</p>

    <h2 class="H22">What is Wrestle Bros?</h2>
    <p><strong>Wrestle Bros</strong> is a physics-based wrestling game where players control quirky characters in a chaotic wrestling match. The objective is simple: outmaneuver and overpower your opponents using a variety of hilarious and unpredictable moves. The game’s dynamic physics system adds an element of surprise to every match, making each encounter unique and exciting.</p>

    <h2 class="H22">Key Features of Wrestle Bros</h2>
    <ul>
        <li><strong>Hilarious Moves:</strong> Execute a wide range of wacky and unpredictable wrestling moves that keep the gameplay fresh and entertaining.</li>
        <li><strong>Physics-Based Gameplay:</strong> The game’s physics engine ensures no two matches are the same, adding a fun, chaotic twist to traditional wrestling games.</li>
        <li><strong>Quirky Characters:</strong> Choose from a variety of unique and colorful characters, each with their own personality and style.</li>
        <li><strong>Multiplayer Mode:</strong> Challenge friends in local multiplayer mode for endless fun and friendly competition.</li>
        <li><strong>Unblocked Access:</strong> Play Wrestle Bros anytime, anywhere with the unblocked version, perfect for gaming at school or work.</li>
    </ul>

    <h2 class="H22">Why Play Wrestle Bros?</h2>
    <p>Here’s why <strong>Wrestle Bros</strong> stands out among <strong>wrestling games</strong>:</p>
    <ul>
        <li><strong>Fun and Laughter:</strong> The game’s humorous moves and unpredictable outcomes guarantee endless laughs and entertainment.</li>
        <li><strong>Accessible for All Ages:</strong> Easy to pick up and play, Wrestle Bros appeals to both casual gamers and wrestling enthusiasts.</li>
        <li><strong>Competitive Spirit:</strong> Engage in friendly competition with friends or test your skills in solo matches.</li>
        <li><strong>Unblocked Version:</strong> Enjoy the game without restrictions, making it accessible in various environments, including schools and workplaces.</li>
    </ul>

    <h2 class="H22">Tips for Mastering Wrestle Bros</h2>
    <p>Maximize your fun and improve your skills with these tips:</p>
    <ul>
        <li><strong>Master the Controls:</strong> Spend time getting familiar with the controls to execute moves more effectively.</li>
        <li><strong>Adapt to Physics:</strong> Embrace the unpredictability of the physics engine and use it to your advantage in matches.</li>
        <li><strong>Play with Friends:</strong> Multiplayer mode offers a more engaging and competitive experience, making it a great way to improve your skills.</li>
        <li><strong>Stay Agile:</strong> Keep your character moving and avoid getting trapped to maintain an upper hand in battles.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>Wrestle Bros</strong> is a must-play for fans of <strong>wrestling games</strong> and anyone looking for a fun, chaotic, and entertaining gaming experience. With its physics-based gameplay, quirky characters, and unblocked access, it’s perfect for casual gaming sessions or intense multiplayer battles. Step into the ring and enjoy the wild wrestling showdown that Wrestle Bros has to offer!</p>
    `,
    tag: ["Multiplayer","Boxing", "Fight"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "GD",
    orientation: 0,
    title: "Golf Orbit: A Classic Twist on a Timeless Game",
    name: ["Golf Orbit", "golf-orbit"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/golf-orbit/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/golf-orbit/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/golf-orbit.mp4",
    path: ["/game/golf-orbit", "https://playcutegames.com"],
    iframe: "https://html5.gamedistribution.com/02ff01e6e9f7451ba28d889a2a55eec9/?gd_sdk_referrer_url=https://playcutegames.com/game/golf-orbit/",
    Walkthrough: "https://www.youtube.com/embed/tk2VvDmnHYw",
    s_dis:"Retro Ping Pong offers a nostalgic twist on classic ping pong games. Enjoy addictive gameplay with the Retro Ping Pong unblocked version.",
    l_dis:`
    <p><strong>Golf Orbit</strong> is an addictive and fun golf game that takes a creative spin on the sport. Instead of focusing on precision putting or courses, the game challenges you to see how far you can launch the ball! Combining elements of golf and physics-based mechanics, Golf Orbit tests your timing and skill as you send the ball soaring into the air, aiming for record-breaking distances. The <strong>Golf Orbit unblocked</strong> version allows players to enjoy the game without restrictions, no matter where they are.</p>

    <h2 class="H22">What is Golf Orbit?</h2>
    <p><strong>Golf Orbit</strong> is a physics-based golf game where players aim to hit the ball as far as possible. The objective is not just about distance but also about strategically bouncing off various objects in the environment to extend the ball’s flight. With upgrades to your equipment and power, the game becomes even more exciting as you progress and achieve higher scores. It’s a perfect blend of skill and fun for fans of <strong>golf games</strong> and <strong>sport games</strong>.</p>

    <h2 class="H22">Key Features of Golf Orbit</h2>
    <ul>
        <li><strong>Unique Gameplay:</strong> Focus on launching the ball for maximum distance, adding a fresh twist to traditional golf games.</li>
        <li><strong>Physics-Based Mechanics:</strong> Utilize the environment to bounce and extend the ball’s flight, incorporating strategy into your shots.</li>
        <li><strong>Upgrades and Power-ups:</strong> Enhance your equipment and power to achieve greater distances and higher scores.</li>
        <li><strong>Engaging Graphics:</strong> Enjoy vibrant visuals and smooth animations that make the gameplay experience immersive and enjoyable.</li>
        <li><strong>Unblocked Access:</strong> Play anytime, anywhere with the unblocked version, perfect for school or work environments.</li>
    </ul>

    <h2 class="H22">Why Play Golf Orbit?</h2>
    <p><strong>Golf Orbit</strong> offers a unique take on the classic sport, making it an exciting choice for gamers looking for something different. Here’s why it stands out:</p>
    <ul>
        <li><strong>Fun and Addictive:</strong> The simple yet challenging gameplay keeps you coming back for more, striving to beat your previous records.</li>
        <li><strong>Perfect for All Ages:</strong> Easy to learn but hard to master, Golf Orbit appeals to both casual players and gaming enthusiasts.</li>
        <li><strong>Competitive Edge:</strong> Challenge friends or aim for the top of the leaderboards with your best shots.</li>
        <li><strong>Accessibility:</strong> The unblocked version ensures you can enjoy the game without restrictions, making it accessible in various environments.</li>
    </ul>

    <h2 class="H22">Tips for Mastering Golf Orbit</h2>
    <p>To make the most of your Golf Orbit experience, keep these tips in mind:</p>
    <ul>
        <li><strong>Time Your Shots:</strong> Precision is key. Hit the ball at the right moment to maximize its launch distance.</li>
        <li><strong>Utilize the Environment:</strong> Bounce off objects strategically to extend your ball’s flight and reach further distances.</li>
        <li><strong>Invest in Upgrades:</strong> Regularly upgrade your equipment to enhance your power and achieve better results.</li>
        <li><strong>Practice Makes Perfect:</strong> The more you play, the better you’ll understand the mechanics and improve your performance.</li>
    </ul>
    
    <h2 class="H22">Conclusion</h2>
    <p><strong>Golf Orbit</strong> is a must-play for fans of <strong>golf games</strong> and <strong>sport games</strong> who are looking for a fun, challenging, and addictive experience. With its unique gameplay, engaging graphics, and unblocked access, it’s perfect for casual gaming sessions or competitive play. Launch your ball into space and see how far you can go in this exciting twist on a classic sport!</p>

    `,
    tag: ["Golf","Sports"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2025-01-08",
    brand: "playcutegames",
    orientation: 0,
    title: "Barbie Chinese New Year Celebration: Embrace the Festive Spirit",
    name: ["Barbie Chinese New Year Celebration", "barbie-chinese-new-year-celebration"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/ellie-chinese-new-year-celebration/250x142.png",
    thumb_big:
      "https://cdn.cutedressup.in/games/cutedressup_new/ellie-chinese-new-year-celebration/520x295.png",
    path: ["/game/barbie-chinese-new-year-celebration", "https://playcutegames.com"],
    iframe:"https://games.cutedressup.net/ellie-chinese-new-year-celebration/",
    Walkthrough: "https://www.youtube.com/embed/Ef4xFEftoVg",
    s_dis:
    "Celebrate with Barbie in the Chinese New Year Celebration game! Enjoy dress-up fun and festive activities in one of the perfect Barbie games for holiday excitement.",
    l_dis:
      `
      <p>Celebrate the beauty and traditions of the Chinese New Year with Barbie in this fun and engaging game! Known as the Lunar New Year, this festival is marked by vibrant decorations, delicious food, and exciting cultural customs. Join Barbie as she prepares for the celebration by dressing up in stunning traditional outfits, decorating her space, and participating in festive activities. With <strong>Barbie Chinese New Year Celebration</strong>, players can dive into the festive spirit and enjoy a unique cultural experience.</p>

    <h2 class="H22">What is Barbie Chinese New Year Celebration?</h2>
    <p><strong>Barbie Chinese New Year Celebration</strong> is a delightful game that lets players explore the rich traditions of the Lunar New Year. From choosing elegant cheongsams to decorating with red lanterns and festive symbols, the game offers an immersive experience for players to enjoy the festivities virtually. It’s an ideal game for those who love <strong>Barbie games</strong>, <strong>Chinese New Year</strong>, and <strong>dress-up games</strong>.</p>

    <h2 class="H22">Key Features of Barbie Chinese New Year Celebration</h2>
    <ul>
        <li><strong>Traditional Dress-Up:</strong> Choose from a variety of traditional Chinese outfits, including beautiful cheongsams and festive accessories.</li>
        <li><strong>Festive Decorations:</strong> Decorate Barbie’s space with iconic Lunar New Year symbols like red lanterns, dragons, and firecrackers.</li>
        <li><strong>Cultural Activities:</strong> Participate in fun activities that reflect the customs of the Chinese New Year celebration.</li>
        <li><strong>Interactive Gameplay:</strong> Enjoy a blend of dress-up and interactive tasks that bring the festive atmosphere to life.</li>
        <li><strong>Unblocked Access:</strong> Play the game anytime, anywhere, even on restricted networks.</li>
    </ul>

    <h2 class="H22">Why Play Barbie Chinese New Year Celebration?</h2>
    <p><strong>Barbie Chinese New Year Celebration</strong> offers a unique way to learn about and enjoy the traditions of the Lunar New Year. Here’s why it’s a must-play:</p>
    <ul>
        <li><strong>Cultural Learning:</strong> Gain insight into Chinese New Year customs through engaging gameplay.</li>
        <li><strong>Creative Expression:</strong> Showcase your fashion sense by dressing Barbie in traditional attire and decorating her surroundings.</li>
        <li><strong>Fun and Festive:</strong> Enjoy a cheerful and colorful game that brings the spirit of the New Year to life.</li>
        <li><strong>Accessible Gameplay:</strong> The unblocked version ensures you can enjoy the game anywhere, even in places with restricted access to gaming sites.</li>
    </ul>

    <h2 class="H22">Tips for Enjoying Barbie Chinese New Year Celebration</h2>
    <p>Make the most of your experience with these helpful tips:</p>
    <ul>
        <li><strong>Explore All Options:</strong> Take time to try different outfits and decorations to create the perfect festive look.</li>
        <li><strong>Learn the Traditions:</strong> Pay attention to the cultural elements in the game to enhance your understanding of the Chinese New Year.</li>
        <li><strong>Share Your Creations:</strong> Capture your decorated space and outfits to share with friends and family, spreading the festive cheer.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>Barbie Chinese New Year Celebration</strong> offers a joyful and educational experience that combines fashion, culture, and festive fun. Whether you’re a fan of <strong>Barbie games</strong>, interested in the <strong>Chinese New Year</strong>, or love <strong>dress-up games</strong>, this game has something special for you. With the unblocked version, you can immerse yourself in the celebration anytime, making it a perfect way to enjoy the holiday spirit.</p>

      `,
    tag: ["Baribe","Dress up","New year","Chinese new year","Exclusive","Girl games","Holiday","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
    platform:"both",
  },
  {
    date: "2024-12-04",
    brand: "GD",
    orientation: 0,
    title: "Retro Ping Pong: A Classic Twist on a Timeless Game",
    name: ["Retro Ping Pong", "retro-ping-pong"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/retro-ping-pong/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/retro-ping-pong/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/retro-ping-pong.mp4",
    path: ["/game/retro-ping-pong", "https://playcutegames.com"],
    iframe: "https://html5.gamedistribution.com/7a673308bc3b4585b9f70436462c2051/?gd_sdk_referrer_url=https://playcutegames.com/game/retro-ping-pong/",
    Walkthrough: "https://www.youtube.com/embed/7FAyQIVWp-I",
    s_dis:"Retro Ping Pong offers a nostalgic twist on classic ping pong games. Enjoy addictive gameplay with the Retro Ping Pong unblocked version.",
    l_dis:`
    
     <p><strong>Retro Ping Pong</strong> combines the charm of classic arcade games with modern gameplay mechanics. Inspired by the iconic 2D pong games, this version brings a nostalgic twist to the traditional ping pong experience. Whether you’re a fan of retro games or discovering the genre for the first time, <strong>Retro Ping Pong</strong> offers simple yet addictive gameplay that keeps players coming back for more. With the <strong>Retro Ping Pong unblocked</strong> version, you can enjoy the game anytime, anywhere, even in restricted environments.</p>

    <h2 class="H22">What is Retro Ping Pong?</h2>
    <p><strong>Retro Ping Pong</strong> is an arcade-style ping pong game that draws inspiration from the classic 2D pong games. Players control paddles on either side of the screen, aiming to bounce the ball past the opponent's paddle to score points. The game features both single-player and multiplayer modes, offering a versatile gaming experience whether you’re playing alone or with friends.</p>

    <h2 class="H22">Key Features of Retro Ping Pong</h2>
    <ul>
        <li><strong>Classic Gameplay:</strong> Relive the classic ping pong experience with a modern twist.</li>
        <li><strong>Single and Multiplayer Modes:</strong> Play solo against AI or challenge a friend in multiplayer mode.</li>
        <li><strong>Simple Controls:</strong> Easy-to-learn controls make it accessible to players of all skill levels.</li>
        <li><strong>Unblocked Access:</strong> Enjoy the game on any network, including schools and workplaces, without restrictions.</li>
        <li><strong>Nostalgic Graphics:</strong> Retro visuals enhance the classic gaming atmosphere.</li>
    </ul>

    <h2 class="H22">Why Play Retro Ping Pong?</h2>
    <p><strong>Retro Ping Pong</strong> offers a fun and engaging way to enjoy a timeless game. Here’s why it stands out:</p>
    <ul>
        <li><strong>Relive the Past:</strong> Experience the nostalgia of classic arcade games with updated features.</li>
        <li><strong>Accessible Gameplay:</strong> Simple mechanics make it easy for anyone to pick up and play.</li>
        <li><strong>Competitive Fun:</strong> Challenge friends or improve your skills by playing against AI opponents.</li>
        <li><strong>Play Anywhere:</strong> The unblocked version ensures you can play the game without limitations, even in restricted environments.</li>
    </ul>

    <h2 class="H22">Tips for Playing Retro Ping Pong</h2>
    <p>Maximize your enjoyment of <strong>Retro Ping Pong</strong> with these helpful tips:</p>
    <ul>
        <li><strong>Master the Controls:</strong> Spend time practicing the controls to improve your paddle movement and precision.</li>
        <li><strong>Stay Focused:</strong> Keep your eyes on the ball and anticipate your opponent’s moves to react quickly.</li>
        <li><strong>Experiment with Angles:</strong> Use different paddle angles to make your shots unpredictable and harder to return.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>Retro Ping Pong</strong> offers a delightful blend of classic and modern gaming elements. Whether you’re reliving the golden age of arcade games or experiencing them for the first time, this game provides endless entertainment. The <strong>Retro Ping Pong unblocked</strong> version allows you to enjoy the game wherever you are, making it a perfect choice for fun and relaxation. Grab your paddle and start bouncing your way to victory!</p>

    `,
    tag: ["Skill","Retro"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-09-13",
    brand: "Yandex",
    orientation: 0,
    title: "Cubes 2048.io – A Strategic Puzzle Adventure",
    name: ["Cubes 2048.io", "cubes-2048-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cubes-2048-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/cubes-2048-io/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/cubes-2048-io.mp4",
    path: ["/game/cubes-2048-io", "https://playcutegames.com"],
    iframe: "https://www.dailytoolz.com/cubes-2048/iframe.html",
    Walkthrough: "https://www.youtube.com/embed/8dK7nKAO0TU",
    s_dis:
    "Cubes 2048.io blends the addictive fun of 2048 puzzles with multiplayer challenges. Play this unblocked game and dominate the leaderboard!",
    l_dis:
      ` <p><strong>Cubes 2048.io</strong> takes the classic <strong>2048 puzzle</strong> concept to an entirely new level by combining it with dynamic multiplayer gameplay. In this exciting game, you guide numbered cubes to merge, grow, and climb the leaderboard. Whether you’re a fan of <strong>Puzzle Games</strong>, strategy games, or <strong>Io Games</strong>, <strong>Cubes 2048.io</strong> offers an addictive and engaging experience that keeps you coming back for more.</p>

    <h2 class="H22">What is Cubes 2048.io?</h2>
    <p><strong>Cubes 2048.io</strong> is a unique game that combines the strategic merging mechanics of 2048 with competitive multiplayer elements. Players control cubes with numbers, merging identical cubes to form higher-numbered cubes while avoiding obstacles and opponents. The objective is to grow your cube and dominate the leaderboard by outscoring other players.</p>

    <h2 class="H22">Key Features of Cubes 2048.io</h2>
    <ul>
        <li><strong>Addictive Gameplay:</strong> Merge cubes with the same number to create higher-numbered cubes and increase your score.</li>
        <li><strong>Multiplayer Fun:</strong> Compete against players from around the world to claim the top spot on the leaderboard.</li>
        <li><strong>Dynamic Challenges:</strong> Navigate through a vibrant environment filled with obstacles and competing cubes.</li>
        <li><strong>Customizable Strategies:</strong> Plan your moves and timing to outsmart opponents and maximize your score.</li>
        <li><strong>Unblocked Access:</strong> Play <strong>Cubes 2048.io</strong> anytime and anywhere with the unblocked version, perfect for schools or workplaces.</li>
    </ul>

    <h2 class="H22">How to Play Cubes 2048.io</h2>
    <p>Getting started with <strong>Cubes 2048.io</strong> is easy, but mastering the game requires strategy and quick thinking. Here’s how to play:</p>
    <ol>
        <li>Control your cube by dragging it across the screen.</li>
        <li>Merge cubes with identical numbers to form a higher-numbered cube.</li>
        <li>Avoid larger opponent cubes and obstacles that can destroy your cube.</li>
        <li>Use power-ups and clever moves to gain an edge over opponents.</li>
        <li>Climb the leaderboard by growing your cube and scoring points.</li>
    </ol>

    <h2 class="H22">Why Play Cubes 2048.io?</h2>
    <p><strong>Cubes 2048.io</strong> stands out as an innovative and entertaining game for several reasons:</p>
    <ul>
        <li><strong>Blends Puzzle and Strategy:</strong> Enjoy the best of both worlds with challenging puzzles and competitive gameplay.</li>
        <li><strong>Multiplayer Excitement:</strong> Compete with real players worldwide, adding unpredictability and thrill to the game.</li>
        <li><strong>Accessible Gameplay:</strong> Easy to learn but challenging to master, making it suitable for players of all skill levels.</li>
        <li><strong>Unblocked Version:</strong> Play the game freely in schools, workplaces, or other restricted environments.</li>
        <li><strong>Replay Value:</strong> With constantly changing challenges and competitive leaderboards, the fun never ends.</li>
    </ul>

    <h2 class="H22">Tips and Strategies for Cubes 2048.io</h2>
    <p>Maximize your success in <strong>Cubes 2048.io</strong> with these tips:</p>
    <ul>
        <li><strong>Plan Ahead:</strong> Anticipate your next moves to avoid collisions and maximize merges.</li>
        <li><strong>Use the Edges:</strong> Stick to the edges of the map to avoid larger opponents and stay safe.</li>
        <li><strong>Be Patient:</strong> Wait for the right moment to merge cubes and create higher numbers.</li>
        <li><strong>Watch Opponents:</strong> Keep an eye on other players to dodge threats and exploit their mistakes.</li>
    </ul>

    <h2 class="H22">Conclusion</h2>
    <p><strong>Cubes 2048.io</strong> is a fresh take on <strong>Puzzle Games</strong> that combines strategic merging with multiplayer competition. Whether you’re playing for fun or aiming to dominate the leaderboard, this game offers endless entertainment. Plus, with its unblocked version, you can enjoy <strong>Cubes 2048.io</strong> anytime and anywhere. Dive into this exciting world of cubes and strategy today!</p>

      `,
      tag: ["Snake","Io"],
    hint: ``,
    rating:[4.8,15102,1014],  
  },
  {
    date: "2025-01-03",
    brand: "Others",
    orientation: 0,
    title: "Elastic Man: Stretch, Squash, and Have Fun!",
    name: ["Elastic Man", "elastic-man"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/elastic-man/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/elastic-man/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/elastic-man.mp4",
    path: ["/game/elastic-man", "https://playcutegames.com"],
    iframe: "https://d21u3ic0kp9e91.cloudfront.net/elasticman/0/index.html",
    Walkthrough: "https://www.youtube.com/embed/BM5sKJmkil0",
    s_dis:"Elastic Man is a fun physics-based game where you stretch and squash a rubbery character. Enjoy the Elastic Man unblocked version",
    l_dis:`
    <p><strong>Elastic Man</strong> is a quirky and entertaining game that lets you stretch, squash, and manipulate a rubbery character in various amusing ways. This game offers a unique blend of relaxation and humor, as you explore the character's flexibility and see how far you can stretch them. With the <strong>Elastic Man unblocked</strong> version, you can enjoy the fun anytime, anywhere, even in places where access to gaming sites is limited.</p>
    
    <h2 class="H22">What is Elastic Man?</h2>
    <p><strong>Elastic Man</strong> is a physics-based game where the main objective is to stretch and distort a rubbery humanoid character. The game features simple controls that allow players to pull and manipulate the character’s face and body into various shapes. The fun lies in the endless possibilities of stretching and squashing, making it a perfect game for relaxation and laughter.</p>
    
    <h2 class="H22">Key Features of Elastic Man</h2>
    <ul>
        <li><strong>Simple Controls:</strong> Easy-to-use controls make the game accessible to players of all ages.</li>
        <li><strong>Physics-Based Gameplay:</strong> Realistic physics allow for a highly interactive and amusing experience.</li>
        <li><strong>Hilarious Outcomes:</strong> Stretch and squash the character into funny and unexpected shapes.</li>
        <li><strong>Unblocked Access:</strong> Play the game on any network without restrictions, including schools and workplaces.</li>
        <li><strong>Relaxing Entertainment:</strong> A perfect way to unwind and have fun without any competitive pressure.</li>
    </ul>
    
    <h2 class="H22">Why Play Elastic Man?</h2>
    <p><strong>Elastic Man</strong> offers a unique and stress-free gaming experience. Here’s why you should give it a try:</p>
    <ul>
        <li><strong>Stress Relief:</strong> The game's relaxing nature makes it a great way to unwind after a long day.</li>
        <li><strong>Fun and Laughter:</strong> The unpredictable results of stretching the character provide endless amusement.</li>
        <li><strong>Creative Exploration:</strong> Discover how far you can stretch and reshape the character in various ways.</li>
        <li><strong>Accessible Anywhere:</strong> With the unblocked version, enjoy the game wherever you are, even in restricted environments.</li>
    </ul>
    
    <h2 class="H22">Tips for Playing Elastic Man</h2>
    <p>Want to make the most out of your <strong>Elastic Man</strong> experience? Here are some tips:</p>
    <ul>
        <li><strong>Experiment Freely:</strong> Don’t be afraid to pull and stretch in all directions to see the funny results.</li>
        <li><strong>Use Both Hands:</strong> If you’re on a touchscreen, use both hands to manipulate the character for more control.</li>
        <li><strong>Take Your Time:</strong> The game is meant to be relaxing, so enjoy the process without rushing.</li>
    </ul>
    
    <h2 class="H22">Conclusion</h2>
    <p><strong>Elastic Man</strong> is a delightful and entertaining game that combines humor and relaxation. Whether you’re looking to de-stress or have a good laugh, the game’s simple yet engaging mechanics provide endless fun. With the <strong>Elastic Man unblocked</strong> version, you can play anytime, anywhere, without restrictions. Stretch, squash, and enjoy the hilarious antics of Elastic Man!</p>

    `,
    tag: ["Clicker","ASRM"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2025-01-03",
    brand: "Others",
    orientation: 0,
    title: "Spacebar Clicker – Test Your Speed with This Fun Clicker Game!",
    name: ["Spacebar Clicker", "spacebar-clicker"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/spacebar-clicker/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/spacebar-clicker/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/spacebar-clicker.mp4",
    path: ["/game/spacebar-clicker", "https://playcutegames.com"],
    iframe: "https://www.hoodamath.com/mobile/games/spacebar-clicker/game.html",
    Walkthrough: "https://www.youtube.com/embed/0kLybxSQhgc",
    s_dis:"Spacebar Clicker is a fun clicker game where you test how fast you can press the spacebar. Play the unblocked version and challenge your reflexes anywhere!",
    l_dis:`
    <p><strong>Spacebar Clicker</strong> is an addictive and simple game that challenges your reflexes and speed. Designed to measure how quickly and efficiently you can press the spacebar within a set time, it’s a perfect choice for players of all ages. Whether you’re competing with friends or trying to beat your personal best, <strong>Spacebar Clicker</strong> offers endless fun. The unblocked version allows you to enjoy the game anywhere, even on restricted networks like schools or workplaces.</p>
    
    <h2 class="H22">What is Spacebar Clicker?</h2>
    <p><strong>Spacebar Clicker</strong> is a fast-paced game focused on pressing the spacebar as many times as possible within a given time limit. Its simple mechanics make it easy to pick up, yet the thrill of beating high scores keeps players coming back for more. Whether you’re looking for casual fun or want to test your reflexes, this game delivers an engaging experience.</p>
    
    <h2 class="H22">Key Features of Spacebar Clicker</h2>
    <ul>
        <li><strong>Simple Gameplay:</strong> Press the spacebar repeatedly to score points within a time limit.</li>
        <li><strong>Progressive Challenges:</strong> Increase difficulty levels to test your speed and endurance.</li>
        <li><strong>Score Tracking:</strong> Keep track of your high scores and aim for improvement.</li>
        <li><strong>Multiplayer Competitions:</strong> Challenge friends to see who can click the fastest.</li>
        <li><strong>Unblocked Access:</strong> Play the game anytime, anywhere, including in restricted environments like schools or offices.</li>
    </ul>
    
    <h2 class="H22">Why Play Spacebar Clicker?</h2>
    <p><strong>Spacebar Clicker</strong> isn’t just a game; it’s a fun way to improve your reflexes and coordination. Here’s why it’s worth playing:</p>
    <ul>
        <li><strong>Stress Relief:</strong> The repetitive action of clicking can be surprisingly relaxing and therapeutic.</li>
        <li><strong>Skill Development:</strong> Improve your typing speed and hand-eye coordination through practice.</li>
        <li><strong>Social Fun:</strong> Compete with friends and family to create a fun and competitive environment.</li>
        <li><strong>Quick Entertainment:</strong> Ideal for short breaks or casual gaming sessions.</li>
    </ul>
    
    <h2 class="H22">Tips to Excel at Spacebar Clicker</h2>
    <p>Want to set new records in <strong>Spacebar Clicker</strong>? Follow these tips:</p>
    <ul>
        <li><strong>Warm Up:</strong> Practice pressing the spacebar before starting the timed session.</li>
        <li><strong>Use Both Hands:</strong> If allowed, alternate between fingers for faster clicking.</li>
        <li><strong>Focus:</strong> Eliminate distractions and concentrate solely on your clicking speed.</li>
        <li><strong>Challenge Yourself:</strong> Gradually increase the time limits to build stamina and endurance.</li>
    </ul>
    
    <h2 class="H22">Conclusion</h2>
    <p><strong>Spacebar Clicker</strong> is a fun and engaging way to test your reflexes, improve your typing speed, and enjoy some lighthearted entertainment. Its simplicity, combined with the thrill of beating high scores, makes it a favorite among <strong>clicker games</strong>. With the unblocked version, you can play anytime, anywhere, ensuring uninterrupted fun. Start clicking and see how fast you can go!</p>

    `,
    tag: ["Clicker","Skill","Action"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "GD",
    orientation: 0,
    title: "Stumble Guys: A Fun-Filled Multiplayer Knockout Game",
    name: ["Stumble Guys", "stumble-guys"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/stumble-guys/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/stumble-guys/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/stumble-guys.mp4",
    path: ["/game/stumble-guys", "https://playcutegames.com"],
    iframe: "https://html5.gamedistribution.com/10df126f655d44f2aed35067e206c7e0/?gd_sdk_referrer_url=https://playcutegames.com/game/stumble-guys/",
    Walkthrough: "https://www.youtube.com/embed/7FAyQIVWp-I",
    s_dis:"Stumble Guys is a hilarious battle royale game where players race through obstacle courses. Enjoy this survival game unblocked for endless fun anywhere!",
    l_dis:`
    
    <p><strong>Stumble Guys</strong> is a fast-paced and entertaining <strong>battle royale game</strong> that takes the concept of survival to a whole new level. Compete against dozens of players in chaotic obstacle courses filled with unpredictable challenges. The game’s wacky physics, colorful visuals, and engaging mechanics make it a must-play for fans of <strong>survival games</strong>. With the <strong>Stumble Guys unblocked</strong> version, you can dive into the fun from anywhere, ensuring endless entertainment.</p>
    
    <h2 class="H22">What is Stumble Guys?</h2>
    <p><strong>Stumble Guys</strong> is a multiplayer party game inspired by traditional <strong>battle royale games</strong>. Players must navigate through obstacle courses filled with traps, hurdles, and other hilarious challenges. The goal is to survive each round and avoid elimination until only one player remains victorious. The game’s lighthearted tone and simple controls make it accessible for players of all skill levels.</p>
    
    <h2 class="H22">Key Features of Stumble Guys</h2>
    <ul>
        <li><strong>Multiplayer Chaos:</strong> Compete against up to 32 players in exciting and unpredictable matches.</li>
        <li><strong>Unique Obstacle Courses:</strong> Navigate through creative levels packed with fun challenges.</li>
        <li><strong>Hilarious Physics:</strong> Enjoy wacky, unpredictable character movements that add to the fun.</li>
        <li><strong>Customizable Characters:</strong> Personalize your avatar with a variety of outfits and accessories.</li>
        <li><strong>Unblocked Version:</strong> Play anywhere, including schools or workplaces, with no restrictions.</li>
    </ul>
    
    <h2 class="H22">Why Play Stumble Guys?</h2>
    <p><strong>Stumble Guys</strong> offers a unique twist on the traditional <strong>battle royale game</strong> formula, focusing on fun and chaos over serious competition. Here’s why it stands out:</p>
    <ul>
        <li><strong>Non-Stop Laughs:</strong> The game’s absurd physics and humorous gameplay create a joyful experience.</li>
        <li><strong>Quick Matches:</strong> Each round is fast-paced, perfect for quick gaming sessions.</li>
        <li><strong>Social Fun:</strong> Compete with friends or players worldwide in multiplayer mode.</li>
        <li><strong>Accessible Gameplay:</strong> Simple controls make it easy for anyone to jump in and have fun.</li>
    </ul>
    
    <h2 class="H22">Tips to Master Stumble Guys</h2>
    <p>Want to improve your chances of surviving the chaos in <strong>Stumble Guys</strong>? Here are some useful tips:</p>
    <ul>
        <li><strong>Stay Alert:</strong> Keep an eye on your surroundings to avoid sudden obstacles and traps.</li>
        <li><strong>Time Your Jumps:</strong> Precision is key when navigating platforms and avoiding pitfalls.</li>
        <li><strong>Stick to the Pack:</strong> Use other players to gauge safe routes and avoid risky areas.</li>
        <li><strong>Customize Your Strategy:</strong> Adapt to each course and find the best path to the finish line.</li>
    </ul>
    
    <h2 class="H22">Conclusion</h2>
    <p>If you’re looking for a lighthearted, fast-paced, and hilariously fun <strong>battle royale game</strong>, <strong>Stumble Guys</strong> is the perfect choice. Its creative obstacle courses, engaging multiplayer action, and accessible gameplay make it a standout in the genre of <strong>survival games</strong>. With the unblocked version, you can enjoy the fun anywhere, anytime. Start stumbling, jumping, and laughing your way to victory today!</p>

    `,
    tag: ["Multiplayer","Io","2Player","Action"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "GD",
    orientation: 0,
    title: "Survivor.io: A Thrilling Zombie Survival Adventure",
    name: ["Survivor.io", "survivor-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/survivor-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/survivor-io/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/survivor-io.mp4",
    path: ["/game/survivor-io", "https://playcutegames.com"],
    iframe: "https://html5.gamedistribution.com/f1c451e586c04b4c8cba01b0c50d9090/?gd_sdk_referrer_url=https://playcutegames.com/game/survivor-io",
    Walkthrough: "https://www.youtube.com/embed/Tyt3XD3FNoc",
    s_dis:"Play Survivor.io, the ultimate free Io game with zombie survival action. Fend off hordes of zombies and upgrade your skills in this unblocked survival game.",
    l_dis:`
    <p><strong>Survivor.io</strong> is an exhilarating survival game that challenges players to combat endless waves of zombies while upgrading weapons and skills to stay alive. Combining action-packed gameplay, strategic decision-making, and immersive challenges, it has become a top choice among fans of <strong>free Io games</strong>. The <strong>Survivor io unblocked</strong> version ensures you can enjoy this gripping game without restrictions, making it accessible from anywhere.</p>
    
    <h2 class="H22">What is Survivor.io?</h2>
    <p><strong>Survivor.io</strong> is a survival-action game set in a zombie-infested city where players assume the role of a heroic survivor. The objective is to fend off increasingly difficult waves of zombies using collected resources, upgraded weapons, and powerful skills. Each match offers a fresh experience with varied challenges, ensuring endless replayability. With the unblocked version, you can enjoy the game anytime, even in restricted environments like schools or workplaces.</p>
    
    <h2 class="H22">Key Features of Survivor.io</h2>
    <ul>
        <li><strong>Fast-Paced Gameplay:</strong> Engage in intense battles against relentless zombie waves.</li>
        <li><strong>Upgrade System:</strong> Enhance your weapons, skills, and abilities to increase your survival chances.</li>
        <li><strong>Dynamic Challenges:</strong> Each round introduces unique enemies, obstacles, and opportunities.</li>
        <li><strong>Unblocked Access:</strong> Play without restrictions, ensuring seamless enjoyment on any network.</li>
        <li><strong>Free-to-Play:</strong> Enjoy this action-packed <strong>free Io game</strong> without any cost.</li>
    </ul>
    
    <h2 class="H22">Why Play Survivor.io?</h2>
    <p>Whether you’re a seasoned gamer or a casual player, <strong>Survivor.io</strong> offers something for everyone. Here’s why you should give it a try:</p>
    <ul>
        <li><strong>Adrenaline-Pumping Action:</strong> Non-stop zombie attacks keep you on edge and fully immersed.</li>
        <li><strong>Strategic Depth:</strong> Decide when and how to upgrade your weapons and skills for maximum impact.</li>
        <li><strong>Replayability:</strong> Each match is unique, ensuring the game never feels repetitive.</li>
        <li><strong>Accessibility:</strong> The <strong>Survivor io unblocked</strong> version makes it playable anytime, anywhere.</li>
    </ul>
    
    <h2 class="H22">Tips for Surviving in Survivor.io</h2>
    <p>Master <strong>Survivor.io</strong> with these effective tips:</p>
    <ul>
        <li><strong>Prioritize Upgrades:</strong> Focus on upgrading your damage and health to handle tougher waves.</li>
        <li><strong>Keep Moving:</strong> Constant movement helps avoid getting overwhelmed by zombie swarms.</li>
        <li><strong>Collect Resources:</strong> Gather coins and power-ups to boost your abilities during each match.</li>
        <li><strong>Adapt Your Strategy:</strong> Experiment with different weapon and skill combinations to find what works best.</li>
    </ul>
    
    <h2 class="H22">Conclusion</h2>
    <p><strong>Survivor.io</strong> stands out as one of the most engaging <strong>free Io games</strong> in the survival genre, offering fast-paced action, strategic gameplay, and endless entertainment. Its unblocked version makes it accessible to players worldwide, ensuring uninterrupted fun. Dive into the zombie apocalypse and test your survival skills in <strong>Survivor.io</strong> today!</p>

    `,
    tag: ["Multiplayer","Io","2Player","Action"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "Others",
    orientation: 0,
    title: "Surviv.io: A Thrilling Multiplayer Survival Game",
    name: ["Surviv.io", "surviv-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/surviv-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/surviv-io/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/surviv-io.mp4",
    path: ["/game/surviv-io", "https://playcutegames.com"],
    iframe: "https://survev.io/",
    Walkthrough: "https://www.youtube.com/embed/30H_LZ57eTE",
    s_dis:"Play Surviv.io, the free Io game that delivers intense survival action. Scavenge, fight, and outlast opponents in this unblocked multiplayer battle royale.",
    l_dis:`
    <p><strong>Surviv.io</strong> is an adrenaline-pumping multiplayer survival game where strategy, quick reflexes, and resource management are key to victory. Compete against other players in an ever-shrinking arena, gather weapons and supplies, and fight to become the last one standing. With the <strong>free Io game</strong> unblocked version, you can dive into the action anytime, anywhere.</p>
    
    <h2 class="H22">What is Surviv.io?</h2>
    <p><strong>Surviv.io</strong> is an online battle royale game that challenges players to survive on a shrinking map. Starting with no equipment, players must scavenge for weapons, armor, and health packs to outlast their opponents. The game’s fast-paced mechanics and strategic elements make it a standout title in the <strong>survival games</strong> genre. The unblocked version ensures accessibility, allowing you to play without restrictions in schools or workplaces.</p>
    
    <h2 class="H22">Key Features of Surviv.io</h2>
    <ul>
        <li><strong>Battle Royale Gameplay:</strong> Fight to be the last player or team standing on a shrinking map.</li>
        <li><strong>Resource Scavenging:</strong> Search for weapons, armor, and supplies to gain an edge over opponents.</li>
        <li><strong>Dynamic Map:</strong> The map shrinks over time, forcing players into closer encounters and strategic battles.</li>
        <li><strong>Team Modes:</strong> Play solo, in duos, or as part of a squad for added excitement.</li>
        <li><strong>Unblocked Access:</strong> Enjoy the game anywhere with the unblocked version, ensuring unrestricted fun.</li>
    </ul>
    
    <h2 class="H22">Why Play Surviv.io?</h2>
    <p>If you’re looking for an engaging and competitive <strong>free Io game</strong>, <strong>Surviv.io</strong> is the perfect choice. Here’s why:</p>
    <ul>
        <li><strong>Intense Action:</strong> The fast-paced gameplay keeps you on your toes as you battle for survival.</li>
        <li><strong>Strategic Depth:</strong> Balance offense and defense to outwit your opponents and emerge victorious.</li>
        <li><strong>Customizable Play:</strong> Choose from solo, duo, or squad modes to match your preferred playstyle.</li>
        <li><strong>Accessibility:</strong> The unblocked version ensures you can play anytime, anywhere, without limitations.</li>
    </ul>
    
    <h2 class="H22">Tips for Success in Surviv.io</h2>
    <p>Master <strong>Surviv.io</strong> with these helpful tips:</p>
    <ul>
        <li><strong>Land Strategically:</strong> Start in areas with plenty of loot to gear up quickly.</li>
        <li><strong>Stay in the Safe Zone:</strong> Keep an eye on the shrinking map to avoid taking damage from the red zone.</li>
        <li><strong>Be Prepared:</strong> Always carry health packs and armor to survive intense firefights.</li>
        <li><strong>Choose Battles Wisely:</strong> Avoid unnecessary conflicts and engage only when you have an advantage.</li>
    </ul>
    
    <h2 class="H22">Conclusion</h2>
    <p><strong>Surviv.io</strong> is a standout title in the world of <strong>survival games</strong>, offering fast-paced and strategic gameplay that appeals to players of all skill levels. Its accessibility as a <strong>free Io game</strong> and the availability of the unblocked version make it a must-play for anyone looking for competitive multiplayer action. Jump into the battlefield, prove your skills, and survive to claim victory in <strong>Surviv.io</strong>!</p>

    `,
    tag: ["Multiplayer","Io","2Player","Action"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "Yandex",
    orientation: 0,
    title: "Madalin Cars Multiplayer: The Ultimate Racing and Stunt Experience",
    name: ["Madalin Cars Multiplayer", "madalin-cars-multiplayer"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/madalin-cars-multiplayer/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/madalin-cars-multiplayer/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/madalin-cars-multiplayer.mp4",
    path: ["/game/madalin-cars-multiplayer", "https://playcutegames.com"],
    iframe: "https://www.madalin-cars-multiplayer.com/webgl/M/MCM-files/035/mcm-webgl/index.html",
    Walkthrough: "https://www.youtube.com/embed/j9WijC9MHzE",
    s_dis:"Play Madalin Cars Multiplayer, the ultimate cars game unblocked! Race players worldwide, perform stunts, and enjoy realistic driving fun online.",
    l_dis:`
    <p><strong>Madalin Cars Multiplayer</strong> is a high-octane online game that combines the thrill of racing with the creativity of stunts. Featuring an expansive selection of cars, customizable options, and multiplayer action, this game is perfect for racing enthusiasts of all ages. The <strong>cars game unblocked</strong> version ensures you can play from anywhere, offering unrestricted access to the excitement.</p>
    
    <h2 class="H22">What is Madalin Cars Multiplayer?</h2>
    <p><strong>Madalin Cars Multiplayer</strong> is an online racing game that allows players to compete in real-time with others from around the world. Drive high-performance vehicles, customize their appearance, and race across vast open-world environments. Whether you’re testing your speed on intricate tracks or enjoying a free-driving experience, this game guarantees non-stop fun. The unblocked version ensures seamless gameplay, even in restricted environments like schools or workplaces.</p>
    
    <h2 class="H22">Key Features of Madalin Cars Multiplayer</h2>
    <ul>
        <li><strong>Wide Range of Cars:</strong> Choose from a variety of vehicles, from sleek sports cars to powerful muscle cars.</li>
        <li><strong>Multiplayer Racing:</strong> Compete against players worldwide in real-time multiplayer mode.</li>
        <li><strong>Customizable Options:</strong> Personalize your car’s color and style to stand out on the track.</li>
        <li><strong>Open-World Maps:</strong> Explore vast maps filled with ramps, loops, and stunt-friendly areas.</li>
        <li><strong>Unblocked Access:</strong> Play the game anywhere with the unblocked version, ensuring unrestricted enjoyment.</li>
    </ul>
    
    <h2 class="H22">Why Play Madalin Cars Multiplayer?</h2>
    <p><strong>Madalin Cars Multiplayer</strong> stands out as one of the best <strong>cars game unblocked</strong> options for several reasons:</p>
    <ul>
        <li><strong>Real-Time Competition:</strong> Test your racing skills against players from around the globe.</li>
        <li><strong>Freedom to Explore:</strong> The open-world maps let you roam freely, perform stunts, and discover hidden areas.</li>
        <li><strong>Immersive Gameplay:</strong> Realistic physics and stunning graphics create a highly engaging experience.</li>
        <li><strong>Accessibility:</strong> The unblocked version ensures you can play anytime, anywhere.</li>
    </ul>
    
    <h2 class="H22">Tips for Winning in Madalin Cars Multiplayer</h2>
    <p>Want to dominate the tracks in <strong>Madalin Cars Multiplayer</strong>? Follow these tips:</p>
    <ul>
        <li><strong>Choose the Right Car:</strong> Experiment with different vehicles to find the one that matches your racing style.</li>
        <li><strong>Master the Tracks:</strong> Learn the layout of each map to identify shortcuts and stunt opportunities.</li>
        <li><strong>Customize for Performance:</strong> Use the customization options to enhance your car’s visual appeal and performance.</li>
        <li><strong>Practice Stunts:</strong> Perfect your stunt skills to impress other players and maximize your score.</li>
    </ul>
    
    <h2 class="H22">Conclusion</h2>
    <p><strong>Madalin Cars Multiplayer</strong> is the ultimate choice for fans of <strong>cars game unblocked</strong> and <strong>race games</strong>. With its dynamic multiplayer mode, extensive car selection, and freedom to explore, this game offers endless entertainment. Whether you’re racing competitively or simply enjoying a casual drive, the unblocked version ensures uninterrupted fun. Start your engines and experience the thrill of Madalin Cars Multiplayer today!</p>

    `,
    tag: ["Multiplayer","Car","2Player","Racing","Driving","Crazy games"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "Yandex",
    orientation: 0,
    title: "Madalin Stunt Cars 2: The Ultimate Driving Experience",
    name: ["Madalin Stunt Cars 2", "madalin-stunt-cars-2"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/madalin-stunt-cars-2/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/madalin-stunt-cars-2/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/madalin-stunt-cars-2.mp4",
    path: ["/game/madalin-stunt-cars-2", "https://playcutegames.com"],
    iframe: "https://www.madalingames.com/madalingames/wp-content/uploads/games/webgl/M/MSC2-WEBGL/index.html",
    Walkthrough: "https://www.youtube.com/embed/fQpot_k4cSI",
    s_dis:"Play Madalin Stunt Cars 2, the ultimate cars game unblocked! Perform stunts, explore vast maps, and race high-performance cars in this thrilling driving game.",
    l_dis:`
    <p><strong>Madalin Stunt Cars 2</strong> delivers a high-octane driving adventure for fans of cars and racing. With its wide selection of vehicles, open-world maps, and stunt opportunities, this game is perfect for anyone looking to combine speed and creativity. The <strong>cars game unblocked</strong> version ensures that you can enjoy the thrill from anywhere without restrictions, making it an ideal choice for both casual gamers and car enthusiasts.</p>
    
    <h2 class="H22">What is Madalin Stunt Cars 2?</h2>
    <p><strong>Madalin Stunt Cars 2</strong> is a 3D car simulation game that allows players to explore expansive maps and perform stunts in a variety of high-performance vehicles. Unlike traditional racing games, this title emphasizes freedom and creativity, letting you choose how you want to enjoy the game. From daring flips to precision drifts, the possibilities are endless in this exciting <strong>race game</strong>.</p>
    
    <h2 class="H22">Key Features of Madalin Stunt Cars 2</h2>
    <ul>
        <li><strong>Wide Range of Vehicles:</strong> Choose from an extensive selection of cars, including sports cars, muscle cars, and exotic supercars.</li>
        <li><strong>Open-World Maps:</strong> Explore detailed environments filled with ramps, loops, and stunt-friendly terrain.</li>
        <li><strong>Realistic Physics:</strong> Experience lifelike car handling and physics-based stunts.</li>
        <li><strong>Multiplayer Mode:</strong> Play with friends or join other players online for competitive fun.</li>
        <li><strong>Unblocked Access:</strong> Enjoy the game at school, work, or anywhere else with the unblocked version.</li>
    </ul>
    
    <h2 class="H22">Why Play Madalin Stunt Cars 2?</h2>
    <p><strong>Madalin Stunt Cars 2</strong> stands out among <strong>cars game unblocked</strong> and <strong>race games</strong> for its unique combination of freedom, fun, and stunning visuals. Here’s why it’s a must-play:</p>
    <ul>
        <li><strong>Endless Possibilities:</strong> Perform stunts, explore maps, or simply cruise around at your own pace.</li>
        <li><strong>Multiplayer Fun:</strong> Challenge friends or meet new players in the online multiplayer mode.</li>
        <li><strong>Customizable Experience:</strong> Choose your favorite car and create your own driving adventure.</li>
        <li><strong>Seamless Accessibility:</strong> Play the unblocked version anytime, anywhere, without restrictions.</li>
    </ul>
    
    <h2 class="H22">Tips for Mastering Madalin Stunt Cars 2</h2>
    <p>Want to make the most out of your driving experience? Follow these tips:</p>
    <ul>
        <li><strong>Experiment with Cars:</strong> Try out different vehicles to find one that suits your driving style.</li>
        <li><strong>Master the Physics:</strong> Learn how to control your car during jumps and landings for flawless stunts.</li>
        <li><strong>Explore Every Map:</strong> Each map offers unique features and challenges, so take your time to discover them all.</li>
        <li><strong>Play with Friends:</strong> Multiplayer mode adds an extra layer of fun and competition.</li>
    </ul>
    
    <h2 class="H22">Conclusion</h2>
    <p><strong>Madalin Stunt Cars 2</strong> is a must-try for fans of <strong>cars game unblocked</strong> and <strong>race games</strong>. Its open-world maps, realistic physics, and diverse vehicles provide a thrilling experience that will keep you entertained for hours. Whether you’re a casual gamer or a dedicated car enthusiast, this game offers something for everyone. Get behind the wheel and start your stunt-filled adventure today!</p>

    `,
    tag: ["Car","2Player","Racing","Driving"],
    rating:[4.4,2512,142],  
  },
  
  {
    date: "2023-12-16",
    brand: "playcutegames",
   
    orientation: 0,
    title: "Ellie NYE Sequin Party - playcutegames.com",
    name: ["Ellie NYE Sequin Party", "ellie-nye-sequin-party"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-nye-sequin-party/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-nye-sequin-party/520x295.png",
    path: ["/game/ellie-nye-sequin-party", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ellie-nye-sequin-party/",
    Walkthrough: "https://www.youtube.com/embed/_vq69fIIoyA",
    s_dis:
    "Ellie NYE Sequin Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Step into the glamour of Ellie NYE Sequin Party! Explore dazzling sequined ensembles, sparkling accessories, and celebrate the countdown in style. Join the glitzy festivities as Ellie sets the trend for the perfect New Year’s Eve look. Elevate your party style and shine into the new year with Ellie’s dazzling inspiration. Unleash the magic of sequins and make your NYE celebration a shimmering sensation!",
      tag: ["Barbie","Dress up","Exclusive","New year","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2022-12-07",
    brand: "playcutegames",
   
    orientation: 0,
    title: "New Years Eve Cruise Party - playcutegames.com",
    name: ["New Years Eve Cruise Party", "new-years-eve-cruise-party"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/new-years-eve-cruise-party/250x142.png",
    thumb_big:
      "https://cdn.cutedressup.in/games/cutedressup_new/new-years-eve-cruise-party/250x142.png",
    path: ["/game/new-years-eve-cruise-party", "https://playcutegames.com"],
    iframe:
      "https://games.cutedressup.net/partner/new-years-eve-cruise-party/",
    Walkthrough: "https://www.youtube.com/embed/6nHqN3Bud7w",
    s_dis:
    "New Years Eve Cruise Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Welcome to New Year’s Eve Cruise Party. New Year is a great time to get together with your closest friends and party the night away to blow off steam and welcome the new year. Here are celebrity plans to celebrate this new year’s eve on the cruise. They are so excited to travel on New year’s eve. Help them to pick a perfect party costume and make this New year more memorable.",
    tag: ["Celebrity","Dress up","New year","Exclusive","Girl games","Holiday","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
    platform:"both",
  },
  {
    date: "2024-12-27",
    brand: "playcutegames",
    orientation: 0,
    title: "Black Pink New Year's Eve Concert – Celebrate in Style",
    name: ["Black Pink New Year’s Eve Concert", "black-pink-new-year-eve-concert"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/black-pink-new-year-eve-concert/250x142.png",
    thumb_big:
      "https://cdn.cutedressup.in/games/cutedressup_new/black-pink-new-year-eve-concert/520x295.png",
    path: ["/game/black-pink-new-year-eve-concert", "https://playcutegames.com"],
    iframe:"https://games.cutedressup.net/black-pink-new-year-eve-concert/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Dress up the members of Black Pink for their epic New Year's Eve concert! Celebrate in style with fashionable outfits and accessories. Play Black Pink New Year’s Eve Concert unblocked!",
    l_dis:
      ` <p><strong>Black Pink New Year's Eve Concert</strong> is an exciting and glamorous dress-up game where you get the chance to style the members of the world-famous K-pop group, Black Pink, for their epic New Year’s Eve performance. Perfect for fans of both fashion and K-pop, this game lets you experiment with various stunning outfits, accessories, and hairstyles to create the perfect concert look.</p>
    <h2 class="H22">What is Black Pink New Year's Eve Concert?</h2>
    <p>In <strong>Black Pink New Year's Eve Concert</strong>, players immerse themselves in the world of Black Pink by styling the group’s members for a dazzling New Year’s Eve concert. The game features an array of fashionable outfits, jewelry, hairstyles, and makeup options, giving you full creative control over each member’s concert look. Whether you’re a K-pop enthusiast or love styling characters in dress-up games, this game offers endless fun and creativity.</p>
    <h2 class="H22">Key Features of Black Pink New Year's Eve Concert</h2>
    <p><strong>Black Pink New Year's Eve Concert</strong> offers exciting features that will keep you hooked:</p>
    <ul>
        <li><strong>Customizable Outfits:</strong> Choose from a variety of glamorous outfits and accessories to style Black Pink for the concert.</li>
        <li><strong>Hairstyle & Makeup:</strong> Experiment with trendy hairstyles and makeup to complete the concert look.</li>
        <li><strong>K-pop Magic:</strong> Bring the magic of a Black Pink concert to life with stylish choices and stunning designs.</li>
        <li><strong>Creative Fun:</strong> Use your fashion sense to create unique looks for each of the Black Pink members.</li>
        <li><strong>Unblocked Access:</strong> Play the game without restrictions at school, work, or home with the unblocked version.</li>
    </ul>
    <h2 class="H22">How to Play Black Pink New Year's Eve Concert</h2>
    <p>Getting started with the game is easy. Here's how to play:</p>
    <ul>
        <li><strong>Select Your Member:</strong> Choose which Black Pink member you’d like to dress up for the concert.</li>
        <li><strong>Pick Outfits and Accessories:</strong> Browse through various glamorous outfits, accessories, and jewelry to style your member.</li>
        <li><strong>Choose Hairstyles and Makeup:</strong> Add the perfect finishing touches by selecting hairstyles and makeup.</li>
        <li><strong>Create Your Look:</strong> Once you’re satisfied, you can see how your Black Pink member looks in her complete concert outfit.</li>
    </ul>
    <h2 class="H22">Why Play Black Pink New Year's Eve Concert Unblocked?</h2>
    <p>The <strong>Black Pink New Year's Eve Concert unblocked</strong> version ensures that you can play this exciting fashion game anytime and anywhere, without restrictions. Here’s why it’s a great choice:</p>
    <ul>
        <li><strong>Access Anywhere:</strong> Play without limitations at school, work, or home.</li>
        <li><strong>No Downloads Required:</strong> Enjoy the game directly from your browser, with no downloads necessary.</li>
        <li><strong>Perfect for Fashion Lovers:</strong> If you love dressing up characters and exploring fashion, this game is perfect for you.</li>
    </ul>
    <h2 class="H22">Tips for Styling the Perfect Black Pink Concert Look</h2>
    <p>Want to create the ultimate concert outfit for Black Pink? Here are some tips:</p>
    <ul>
        <li><strong>Mix Glamour with Edge:</strong> Combine bold outfits with glamorous accessories for a striking look.</li>
        <li><strong>Consider Color Schemes:</strong> Choose complementary colors to create a cohesive and stylish ensemble.</li>
        <li><strong>Experiment with Accessories:</strong> Don’t be afraid to add a bold necklace, earrings, or a shiny bracelet to complete the look.</li>
        <li><strong>Be Creative:</strong> Play around with different styles until you find the perfect match for each Black Pink member.</li>
    </ul>
    <h2 class="H22">Celebrate with Black Pink New Year's Eve Concert</h2>
    <p>Celebrate the New Year in style with the <strong>Black Pink New Year's Eve Concert</strong> game! Whether you’re a die-hard Blinks fan or a fashion lover, this game lets you immerse yourself in the glitz and glamour of a Black Pink concert. Try out different looks, create stunning outfits, and get ready to shine with the K-pop superstars!</p>
   `,
    tag: ["Blackpink","New year","Holiday","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
    platform:"both",
  },
  {
    date: "2023-02-01",
    brand: "playcutegames",
   
    orientation: 1,
    title: "Angela valentine Story Deep Water - playcutegames.com",
    name: ["Angela valentine Story Deep Water", "angela-valentine-story-deep-water"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/angela-valentine-story-deep-water/230x131.png",
    thumb_big:
      "https://cdn.cutedressup.in/games/cutedressup_new/angela-valentine-story-deep-water-300.jpg",
    path: ["/game/angela-valentine-story-deep-water", "https://playcutegames.com"],
    iframe:
      "https://games.cutedressup.net/angela-valentine-story-deep-water/",
    Walkthrough: "https://www.youtube.com/embed/hgg4vM9cKu4",
    s_dis:
    "Join Angela Valentine Story: Deep Water at PlayCuteGames! Play online for free and help Angela find a love potion to win back her boyfriend's heart",
    l_dis:
      "Welcome to Angela valentine Story Deep Water. Angela feels lonely after her BF hates her so much in recent days. She asked help to her friend. Her friend gave her the gain his love back using a love potion. But she needs to go on an adventure hunt to get the love potion. Help her to join back to her BF.",
    tag: ["Angela","Dress up","Girl games","Fashion","Valentine","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
    platform:"both",
  },
  
  {
    id: 20211125,
    brand: "cutedressup",
   
    orientation: 0,
    title: "Frozen Princess New year's Eve - playcutegames.com",
    name: ["Frozen Princess New year's Eve", "frozen-princess-new-years-eve"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/frozen-princess-new-years-eve/250x142.png",
    path: ["/game/frozen-princess-new-years-eve", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/frozen-princess-new-years-eve/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
      "Welcome to the Frozen Princess on New year’s Eve. Princess Elsa and Anna arrange a party at Arendelle. They invited other princesses Ariel, Moana, Snow White, and Jasmine for the party.",
    l_dis:
      `Welcome to the Frozen Princess on New year’s Eve. Princess Elsa and Anna arrange a party at Arendelle. They invited other princesses Ariel, Moana, Snow White, and Jasmine for the party. They all are so excited to visit Arendelle on New Year. Help the princesses to get ready for the New Year celebration.
      <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
    tag: [
      "Princess",
      "Dress up",
      "Make Up",
      "New year",
      "cutedressup",
      "Exclusive",
    ],
    hint: `Use your left mouse button and keyboard`,
    platform:"both",
  },
  {
    date: "2022-12-25",
    brand: "playcutegames",
   
    orientation: 0,
    title: "My New Year’s Sparkling Outfits - playcutegames.com",
    name: ["My New Year’s Sparkling Outfits", "my-new-years-sparkling-outfits"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/my-new-years-sparkling-outfits/250x142.png",
    thumb_big:
      "https://cdn.cutedressup.in/games/cutedressup_new/my-new-years-sparkling-outfits/250x142.png",
    path: ["/game/my-new-years-sparkling-outfits", "https://playcutegames.com"],
    iframe:
      "https://games.cutedressup.net/partner/my-new-years-sparkling-outfits/",
    Walkthrough: "https://www.youtube.com/embed/pex6YWXMeic",
    s_dis:
    "My New Year’s Sparkling Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Welcome to My New Year’s Sparkling Outfits. BFF girls plan for a new year surprise party. They are so excited to try new fashion trends for this new year. Help the girls to pick a cute sparkling outfit and pick cute matching accessories for each girl. Don’t forget to choose a unique hairstyle.",
    tag: ["Princess","Dress up","New year","Girl games","Holiday","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
    platform:"both",
  },
  {
    date: "2024-12-04",
    brand: "Yandex",
    orientation: 0,
    title: "Rooftop Snipers 2 – Intense Multiplayer Sniper Battles Online!",
    name: ["Rooftop Snipers 2", "rooftop-snipers-2"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/rooftop-sniper-2/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/rooftop-sniper-2/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/rooftop-sniper-2.mp4",
    path: ["/game/rooftop-snipers-2", "https://playcutegames.com"],
    iframe: "https://htmlxm.github.io/h6/rooftop-snipers-2/",
    Walkthrough: "https://www.youtube.com/embed/dDJ6M1bADa4",
    s_dis:"Play Rooftop Snipers 2, the ultimate multiplayer sniper game! Engage in chaotic battles, knock opponents off rooftops, and enjoy endless fun.",
    l_dis:`<p><strong>Rooftop Snipers 2</strong> brings back the thrilling duels of the original game with even more excitement. Battle your way to victory in this physics-based shooter where every shot and move counts. Whether you’re playing against the AI or challenging a friend, <strong>Rooftop Snipers 2</strong> offers a hilarious and chaotic experience that will keep you hooked for hours.</p>
    
    <h2 class="H22">What is Rooftop Snipers 2?</h2>
    <p><strong>Rooftop Snipers 2</strong> is a fast-paced action game that combines shooting, dodging, and physics-based mechanics. The objective is simple: knock your opponent off the rooftop before they do the same to you. Armed with rifles and quirky characters, players face unpredictable challenges that make every match unique and entertaining. The game offers both solo and local multiplayer modes, ensuring endless fun for everyone.</p>
    
    <h2 class="H22">Key Features of Rooftop Snipers 2</h2>
    <ul>
        <li><strong>Multiplayer Mode:</strong> Compete with friends or play solo against the AI in exciting duels.</li>
        <li><strong>Quirky Characters:</strong> Choose from a variety of fun and unique avatars.</li>
        <li><strong>Physics-Based Gameplay:</strong> Experience unpredictable battles with hilarious outcomes.</li>
        <li><strong>Simple Controls:</strong> Easy-to-learn mechanics make the game accessible for all players.</li>
        <li><strong>Challenging Rooftops:</strong> Fight on precarious platforms that add to the tension.</li>
    </ul>
    
    <h2 class="H22">Why Play Rooftop Snipers 2?</h2>
    <p><strong>Rooftop Snipers 2</strong> stands out among <strong>snipers games, shooting games,</strong> and <strong>fighting games</strong> for its unique blend of chaos and strategy. Here’s why it’s a must-play:</p>
    <ul>
        <li><strong>Exciting Duels:</strong> Test your timing and accuracy in one-on-one rooftop battles.</li>
        <li><strong>Unpredictable Physics:</strong> Every match feels fresh and challenging due to the game’s dynamic mechanics.</li>
        <li><strong>Multiplayer Fun:</strong> Compete with a friend locally and settle scores in the ultimate showdown.</li>
        <li><strong>Quick Matches:</strong> Perfect for short gaming sessions with endless replayability.</li>
    </ul>
    
    <h2 class="H22">Tips for Winning in Rooftop Snipers 2</h2>
    <p>Want to dominate the rooftops? Follow these tips:</p>
    <ul>
        <li><strong>Master the Timing:</strong> Learn to shoot and dodge at the perfect moments to gain an edge.</li>
        <li><strong>Use the Environment:</strong> Leverage the rooftops to your advantage by positioning yourself strategically.</li>
        <li><strong>Stay Calm:</strong> Quick reflexes are important, but staying composed under pressure is key to victory.</li>
        <li><strong>Practice Makes Perfect:</strong> Spend time honing your skills in solo mode before challenging friends.</li>
    </ul>
    
    <h2 class="H22">Conclusion</h2>
    <p><strong>Rooftop Snipers 2</strong> is a fantastic blend of action, humor, and strategy. Whether you’re a fan of <strong>snipers games, shooting games,</strong> or <strong>fighting games</strong>, this title offers something for everyone. With its engaging gameplay, quirky characters, and unpredictable mechanics, Rooftop Snipers 2 guarantees endless fun. Try it today and prove you’re the king of the rooftops!</p>
 `,
    tag: ["Shooting","Minecraft","Action","Skill","Sniper","Addictive","Roblox"],
    rating:[4.4,2512,142],  
  },

  {
    date: "2024-12-04",
    brand: "Yandex",
    orientation: 0,
    title: "Rocket League – Play the Ultimate Soccer and Car Mashup Online!",
    name: ["Rocket League", "rocket-league"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/rocket-league/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/rocket-league/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/rocket-league.mp4",
    path: ["/game/rocket-league", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/195671?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/riilLCDgf-s",
    s_dis:"Play Rocket League, the ultimate mix of soccer and high-speed cars. Enjoy thrilling gameplay, competitive matches, and unblocked access anytime, anywhere!",
    l_dis:`  <p><strong>Rocket League</strong> combines the thrill of soccer with the excitement of rocket-powered cars, creating a gaming experience like no other. Whether you’re a casual player or a competitive gamer, <strong>Rocket League</strong> offers endless fun and challenges. The <strong>unblocked version</strong> ensures you can enjoy this action-packed game anytime, anywhere, without restrictions.</p>
    
    <h2 class="H22">What is Rocket League?</h2>
    <p><strong>Rocket League</strong> is a fast-paced sports-action game where players control rocket-powered cars to score goals in a soccer-style arena. Developed by Psyonix, it features physics-based mechanics that add a layer of strategy and skill to the gameplay. Players can engage in solo matches, team competitions, or even participate in online tournaments. Its unique concept and addictive gameplay have made it a global sensation.</p>
    
    <h2 class="H22">Key Features of Rocket League</h2>
    <ul>
        <li><strong>Unique Gameplay:</strong> Experience a blend of soccer and high-speed car stunts.</li>
        <li><strong>Customizable Cars:</strong> Personalize your vehicle with a variety of skins, decals, and accessories.</li>
        <li><strong>Multiplayer Modes:</strong> Play solo, in teams, or compete in online tournaments.</li>
        <li><strong>Aerial Maneuvers:</strong> Master thrilling stunts and gravity-defying moves to dominate the game.</li>
        <li><strong>Unblocked Access:</strong> Enjoy the game without restrictions, whether at school, work, or home.</li>
    </ul>
    
    <h2 class="H22">Why Play Rocket League Unblocked?</h2>
    <p>The <strong>unblocked version of Rocket League</strong> allows players to access the game seamlessly, regardless of location. Here’s why you should try it:</p>
    <ul>
        <li><strong>Play Anywhere:</strong> Access the game even in restricted environments like schools or workplaces.</li>
        <li><strong>No Installation Required:</strong> Enjoy Rocket League directly from your browser without downloading.</li>
        <li><strong>Endless Fun:</strong> Engage in matches and tournaments anytime, anywhere.</li>
    </ul>
    
    <h2 class="H22">Tips for Mastering Rocket League</h2>
    <p>Want to level up your Rocket League skills? Follow these tips:</p>
    <ul>
        <li><strong>Practice Ball Control:</strong> Spend time learning how to maneuver the ball with precision.</li>
        <li><strong>Master Aerial Moves:</strong> Perfect your aerial stunts to outplay opponents and score goals.</li>
        <li><strong>Position Strategically:</strong> Always be aware of your position on the field to anticipate plays.</li>
        <li><strong>Communicate with Your Team:</strong> Coordinate with teammates to execute effective strategies.</li>
        <li><strong>Upgrade Your Skills:</strong> Play regularly and participate in online tournaments to improve.</li>
    </ul>
    
    <h2 class="H22">Rocket League: A Global Phenomenon</h2>
    <p>Since its release, <strong>Rocket League</strong> has captivated millions of players worldwide with its innovative concept and competitive gameplay. Its dynamic matches, stunning graphics, and community-driven features have earned it a spot as one of the most popular games in the world. Whether you’re a casual gamer or a pro, Rocket League has something for everyone.</p>
    
    <h2 class="H22">Conclusion</h2>
    <p>If you’re looking for an adrenaline-pumping gaming experience, <strong>Rocket League</strong> is the perfect choice. From scoring epic goals to mastering jaw-dropping aerial stunts, this game offers endless excitement. Try the <strong>unblocked version</strong> today and join the global community of players who can’t get enough of this ultimate soccer and car mashup!</p>
`,
    tag: ["Multiplayer","2Player","Car","Skill","Soccer","Football","Poki"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "Yandex",
    orientation: 0,
    title: "House of Hazards – Play the Thrilling Arcade Game Online",
    name: ["House of Hazards", "house-of-hazards"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/house-of-hazards/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/house-of-hazards/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/house-of-hazards.mp4",
    path: ["/game/house-of-hazards", "https://playcutegames.com"],
    iframe: "https://kdata1.com/2020/10/House_of_Hazards/",
    Walkthrough: "https://www.youtube.com/embed/VmNt5ogrReE",
    s_dis:"Play House of Hazards, the chaotic multiplayer game filled with traps and obstacles. Enjoy hilarious challenges and compete with friends online.",
    l_dis:``,
    tag: ["Multiplayer","Skill","Skill","Poki"],
    rating:[4.4,2512,142],  
  },
  
  {
    date: "2024-12-04",
    brand: "Yandex",
    orientation: 0,
    title: "Bounce Master Unblocked – Play the Thrilling Arcade Game Online",
    name: ["Bounce Master", "bounce-master"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bounce-master/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bounce-master/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/bounce-master.mp4",
    path: ["/game/bounce-master", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/172686?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/sBW_5IRgNSw",
    s_dis:"Play Bounce Master Unblocked, a fun and action-packed arcade game. Control the bounce, avoid obstacles, and reach new heights in this addictive game!",
    l_dis:`<p><strong>Bounce Master</strong> is an exhilarating arcade game that challenges your reflexes and timing as you guide a bouncing character through dynamic levels. With fast-paced gameplay, exciting rewards, and plenty of challenges, this game offers endless entertainment for players of all skill levels. The unblocked version ensures that you can enjoy <strong>Bounce Master</strong> anytime, anywhere.</p>
    <h2 class="H22">What is Bounce Master?</h2>
    <p><strong>Bounce Master</strong> is a fun and interactive game where you control a character’s bounce to navigate obstacles and collect rewards. The game’s simplicity makes it easy to pick up, but its increasing difficulty ensures a consistently engaging experience. Whether you’re a casual gamer or a hardcore arcade enthusiast, <strong>Bounce Master</strong> delivers non-stop excitement.</p>
    <h2 class="H22">Key Features of Bounce Master</h2>
    <p>Here are the standout features that make <strong>Bounce Master</strong> a must-play game:</p>
    <ul>
        <li><strong>Dynamic Gameplay:</strong> Bounce through challenging levels filled with obstacles and rewards.</li>
        <li><strong>Simple Controls:</strong> Intuitive mechanics allow players of all ages to enjoy the game.</li>
        <li><strong>Challenging Obstacles:</strong> Test your reflexes and precision by overcoming various hazards.</li>
        <li><strong>Vibrant Graphics:</strong> Colorful visuals create an engaging and immersive experience.</li>
        <li><strong>Unblocked Access:</strong> Enjoy the game freely, even in restricted environments like schools or workplaces.</li>
    </ul>
    <h2 class="H22">How to Play Bounce Master</h2>
    <p>Getting started with <strong>Bounce Master</strong> is easy, but mastering it requires skill and practice. Follow these steps to play:</p>
    <ul>
        <li><strong>Control Your Character:</strong> Use your mouse or keyboard to control the character’s bounce.</li>
        <li><strong>Avoid Obstacles:</strong> Steer clear of barriers and hazards to keep bouncing forward.</li>
        <li><strong>Collect Rewards:</strong> Gather coins and power-ups to boost your score and performance.</li>
        <li><strong>Reach New Heights:</strong> Bounce as far as possible to achieve high scores and unlock new challenges.</li>
    </ul>
    <h2 class="H22">Why Play Bounce Master Unblocked?</h2>
    <p>The unblocked version of <strong>Bounce Master</strong> ensures seamless access and uninterrupted fun. Here’s why it’s the ideal choice for players:</p>
    <ul>
        <li><strong>Play Anywhere:</strong> Enjoy the game at school, work, or any location without restrictions.</li>
        <li><strong>No Downloads Required:</strong> Play directly in your browser for a hassle-free experience.</li>
        <li><strong>Perfect for Breaks:</strong> Quick levels make it ideal for short gaming sessions during free time.</li>
    </ul>
    <h2 class="H22">Tips to Excel in Bounce Master</h2>
    <p>Want to master <strong>Bounce Master</strong>? Use these tips to enhance your gameplay:</p>
    <ul>
        <li><strong>Time Your Bounces:</strong> Focus on timing your jumps to avoid hazards and maximize distance.</li>
        <li><strong>Plan Ahead:</strong> Keep an eye on upcoming obstacles and plan your movements accordingly.</li>
        <li><strong>Use Power-Ups:</strong> Take advantage of power-ups to gain boosts and overcome difficult sections.</li>
        <li><strong>Stay Consistent:</strong> Practice regularly to improve your reflexes and precision.</li>
    </ul>
    <h2 class="H22">Play Bounce Master Unblocked Today</h2>
    <p>Ready to bounce your way to victory? Dive into the <strong>Bounce Master Unblocked</strong> version and enjoy this thrilling arcade game without any limitations. Whether you’re aiming for the highest score or just looking for a fun way to pass the time, <strong>Bounce Master</strong> promises endless entertainment. Start playing now and see how far you can go!</p>
 `,
    tag: ["Tap","Skill","Addictive"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "YAD",
    orientation: 0,
    title: "Blumgi Rocket Unblocked – Play the Thrilling Physics Game Online",
    name: ["Blumgi Rocket", "blumgi-rocket"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/blumgi-rocket/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/blumgi-rocket/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/blumgi-rocket.mp4",
    path: ["/game/blumgi-rocket", "https://playcutegames.com"],
    iframe: "https://23azostore.github.io/s2/blumgi-rocket/",
    Walkthrough: "https://www.youtube.com/embed/L6QdbAebpWk",
    s_dis:"Play Blumgi Rocket Unblocked, a thrilling physics-based rocket game with exciting levels, challenging obstacles, and endless fun. Start your adventure today!",
    l_dis:` <p><strong>Blumgi Rocket</strong> is an engaging physics-based game that challenges players to pilot a rocket through levels filled with ramps, obstacles, and puzzles. The game’s blend of skill, timing, and precision ensures a captivating experience as you navigate through each challenge. Perfect for fans of physics-based games and puzzles, <strong>Blumgi Rocket</strong> provides hours of fun and excitement. The unblocked version makes it accessible anytime, anywhere.</p>
    <h2 class="H22">What is Blumgi Rocket?</h2>
    <p><strong>Blumgi Rocket</strong> is a physics-driven game where you control a rocket to complete intricate levels. Each level presents unique obstacles and puzzles that test your skills and creativity. With vibrant visuals, smooth controls, and challenging gameplay, this game is ideal for players who love thrilling adventures and engaging mechanics.</p>
    <h2 class="H22">Key Features of Blumgi Rocket</h2>
    <p>Here are the features that make <strong>Blumgi Rocket</strong> an unforgettable gaming experience:</p>
    <ul>
        <li><strong>Physics-Based Gameplay:</strong> Master the art of controlling your rocket using realistic physics mechanics.</li>
        <li><strong>Challenging Levels:</strong> Navigate through increasingly difficult levels filled with obstacles and puzzles.</li>
        <li><strong>Vibrant Graphics:</strong> Enjoy colorful and immersive visuals that enhance the gameplay experience.</li>
        <li><strong>Simple Controls:</strong> Intuitive controls make the game accessible to players of all skill levels.</li>
        <li><strong>Unblocked Access:</strong> Play the game freely in any environment, including schools and workplaces.</li>
    </ul>
    <h2 class="H22">How to Play Blumgi Rocket</h2>
    <p>Playing <strong>Blumgi Rocket</strong> is simple, but mastering it requires precision and timing. Follow these steps to get started:</p>
    <ul>
        <li><strong>Control Your Rocket:</strong> Use the on-screen controls or keyboard to navigate your rocket.</li>
        <li><strong>Avoid Obstacles:</strong> Steer clear of ramps, barriers, and other hazards to reach the finish line.</li>
        <li><strong>Complete Levels:</strong> Aim to solve puzzles and complete each level as quickly as possible.</li>
        <li><strong>Earn Points:</strong> Collect bonuses and aim for the highest score in every level.</li>
    </ul>
    <h2 class="H22">Why Play Blumgi Rocket Unblocked?</h2>
    <p>Playing the unblocked version of <strong>Blumgi Rocket</strong> ensures you can enjoy the game without restrictions. Here’s why it’s a must-play:</p>
    <ul>
        <li><strong>Accessible Anywhere:</strong> Play the game at school, work, or home without interruptions.</li>
        <li><strong>No Downloads:</strong> Enjoy seamless gameplay directly in your browser.</li>
        <li><strong>Perfect for Short Breaks:</strong> Quick levels make it ideal for casual gaming sessions.</li>
    </ul>
    <h2 class="H22">Tips to Master Blumgi Rocket</h2>
    <p>Want to excel in <strong>Blumgi Rocket</strong>? Use these tips to maximize your performance:</p>
    <ul>
        <li><strong>Practice Timing:</strong> Learn to time your movements and boosts for optimal navigation.</li>
        <li><strong>Plan Your Route:</strong> Study the level layout before starting to avoid unnecessary obstacles.</li>
        <li><strong>Upgrade Your Skills:</strong> Focus on improving control to tackle advanced levels effectively.</li>
        <li><strong>Stay Patient:</strong> Some levels require trial and error, so don’t give up too soon.</li>
    </ul>
    <h2 class="H22">Play Blumgi Rocket Unblocked Now</h2>
    <p>Ready to take on the exciting challenges of <strong>Blumgi Rocket</strong>? Dive into the unblocked version today and enjoy this thrilling physics-based game from any location. With engaging levels, vibrant visuals, and endless fun, <strong>Blumgi Rocket</strong> is the perfect game for casual and dedicated players alike. Start playing now and see how far your rocket can go!</p>
 `,
    tag: ["Shooting","Action","Platform","2Player","Car","Poki"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "YAD",
    orientation: 0,
    title: "GunSpin Unblocked – Play the Exciting Shooting Game Online",
    name: ["GunSpin", "gunspin"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/gunspin/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/gunspin/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/gunspin.mp4",
    path: ["/game/gunspin", "https://playcutegames.com"],
    iframe: "https://gun-spin.github.io/file/",
    Walkthrough: "https://www.youtube.com/embed/FINvRGBis2k",
    s_dis:"Play GunSpin Unblocked and master the art of shooting and spinning! Propel your gun forward, upgrade, and achieve maximum distance in this addictive game.",
    l_dis:`<p><strong>GunSpin</strong> is an exciting and addictive physics-based shooting game where players combine strategy and skill to propel a gun as far as possible. With each shot, your goal is to spin the gun and reach greater distances. Collect coins, unlock powerful upgrades, and experiment with a variety of guns to enhance your gameplay. Whether you’re a casual gamer or a shooting game enthusiast, <strong>GunSpin</strong> offers endless entertainment. The unblocked version ensures that players can enjoy this thrilling game anytime, anywhere.</p>
    <h2 class="H22">What is GunSpin?</h2>
    <p><strong>GunSpin</strong> is a physics-driven online game that challenges players to use their shooting skills creatively. Unlike traditional shooting games, the objective here is to use the recoil of a gun to propel it forward. As you progress, you can unlock new weapons, upgrade your firepower, and compete to achieve record-breaking distances. With simple controls and engaging mechanics, <strong>GunSpin</strong> is a game that players of all ages can enjoy.</p>
    <h2 class="H22">Key Features of GunSpin</h2>
    <p><strong>GunSpin</strong> stands out with its unique gameplay and exciting features:</p>
    <ul>
        <li><strong>Physics-Based Gameplay:</strong> Use recoil mechanics to propel the gun and cover maximum distance.</li>
        <li><strong>Weapon Variety:</strong> Unlock and experiment with different guns, each offering unique mechanics and power.</li>
        <li><strong>Upgradeable Features:</strong> Improve your gun's firepower, ammunition, and efficiency to achieve better results.</li>
        <li><strong>Addictive Challenges:</strong> Set new distance records and strive for perfection.</li>
        <li><strong>Unblocked Access:</strong> Enjoy the game in any environment, including schools and workplaces.</li>
    </ul>
    <h2 class="H22">How to Play GunSpin</h2>
    <p>Getting started with <strong>GunSpin</strong> is easy, but mastering it requires skill and strategy. Here’s how to play:</p>
    <ul>
        <li><strong>Shoot to Propel:</strong> Click or tap to fire the gun and use the recoil to move forward.</li>
        <li><strong>Strategize Shots:</strong> Aim carefully to maximize momentum and conserve ammunition.</li>
        <li><strong>Collect Coins:</strong> Pick up coins during your run to purchase upgrades and new weapons.</li>
        <li><strong>Upgrade and Progress:</strong> Use collected coins to enhance your gun’s abilities and set higher records.</li>
    </ul>
    <h2 class="H22">Why Play GunSpin Unblocked?</h2>
    <p>The unblocked version of <strong>GunSpin</strong> ensures seamless gameplay in any setting, including schools or workplaces. Here’s why you should try it:</p>
    <ul>
        <li><strong>Accessible Anywhere:</strong> Enjoy uninterrupted fun without restrictions.</li>
        <li><strong>No Downloads:</strong> Play directly in your browser without any installations.</li>
        <li><strong>Perfect for Short Breaks:</strong> Quick gameplay sessions make it ideal for casual players.</li>
    </ul>
    <h2 class="H22">Tips for Success in GunSpin</h2>
    <p>Want to maximize your distance and dominate the leaderboard? Follow these tips:</p>
    <ul>
        <li><strong>Time Your Shots:</strong> Fire strategically to maintain momentum and maximize distance.</li>
        <li><strong>Upgrade Wisely:</strong> Focus on enhancing ammo capacity and gun power for better results.</li>
        <li><strong>Experiment with Weapons:</strong> Each gun offers unique mechanics, so find the one that suits your playstyle.</li>
        <li><strong>Practice Consistently:</strong> Regular play improves your timing and strategy over time.</li>
    </ul>
    <h2 class="H22">Play GunSpin Unblocked Now</h2>
    <p>Ready to test your shooting and spinning skills? Dive into <strong>GunSpin Unblocked</strong> and experience the thrill of physics-based gameplay. Whether you’re aiming for casual fun or high scores, this game delivers endless excitement. Start playing now and see how far you can spin!</p>
    `,
    tag: ["Shooting","Action","Skill","Addictive"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "GD",
    orientation: 0,
    title: "Blumgi Slime – Play Online and Master Slime Adventures",
    name: ["Blumgi Slime", "blumgi-slime"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/blumgi-slime/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/blumgi-slime/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/blumgi-slime.mp4",
    path: ["/game/blumgi-slime", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/blumgi-slime/",
    Walkthrough: "https://www.youtube.com/embed/3m4iX5s_WQM",
    s_dis:"Play Blumgi Slime online and navigate through fun-filled, physics-based slime adventures. Bounce, leap, and explore exciting levels now!",
    l_dis:`
      <p><strong>Blumgi Slime</strong> is a quirky and addictive online platforming game that combines unique physics mechanics with engaging gameplay. Players control a charming blob of slime that bounces and leaps through diverse levels, packed with obstacles and collectibles. This game is perfect for players who love a mix of skill, strategy, and fun. Whether you're tackling levels solo or competing with friends, <strong>Blumgi Slime</strong> promises hours of entertainment with its playful visuals and dynamic challenges.</p>
    <h2 class="H22">What is Blumgi Slime?</h2>
    <p><strong>Blumgi Slime</strong> is an innovative online game where players take control of a bouncy slime character. The goal is to navigate through intricate levels filled with traps, gaps, and collectibles, using the slime’s unique physics to your advantage. The game stands out for its colorful design, intuitive controls, and a perfect balance of challenge and fun.</p>
    <h2 class="H22">Key Features of Blumgi Slime</h2>
    <p>Blumgi Slime offers a variety of features that make it a must-play game:</p>
    <ul>
        <li><strong>Physics-Based Gameplay:</strong> Master the unique bouncing mechanics of the slime character.</li>
        <li><strong>Challenging Levels:</strong> Navigate through obstacles and tricky platforms in increasingly complex stages.</li>
        <li><strong>Colorful Visuals:</strong> Enjoy vibrant graphics that enhance the overall experience.</li>
        <li><strong>Multiplayer Mode:</strong> Compete with friends for the best scores and fastest completion times.</li>
        <li><strong>Easy to Play:</strong> Simple controls make it accessible for players of all ages.</li>
    </ul>
    <h2 class="H22">How to Play Blumgi Slime</h2>
    <p>Playing <strong>Blumgi Slime</strong> is simple, yet mastering it requires practice. Here’s how to get started:</p>
    <ul>
        <li><strong>Control the Slime:</strong> Use your keyboard or touch controls to move and jump.</li>
        <li><strong>Navigate Obstacles:</strong> Plan your bounces carefully to avoid traps and gaps.</li>
        <li><strong>Collect Items:</strong> Gather stars or other collectibles scattered throughout the levels.</li>
        <li><strong>Reach the Finish Line:</strong> Complete each level by reaching the designated endpoint.</li>
    </ul>
    <h2 class="H22">Why Play Blumgi Slime?</h2>
    <p><strong>Blumgi Slime</strong> is more than just a platformer; it’s an adventure that challenges your reflexes and problem-solving skills. Here’s why you should try it:</p>
    <ul>
        <li><strong>Fun Physics Mechanics:</strong> The bouncing slime adds a layer of excitement and strategy to the gameplay.</li>
        <li><strong>Engaging Levels:</strong> Each stage offers new challenges to keep the experience fresh.</li>
        <li><strong>Casual and Relaxing:</strong> It’s a great game for unwinding or enjoying short gaming sessions.</li>
        <li><strong>Unblocked Access:</strong> Play anytime, anywhere without restrictions.</li>
    </ul>
    <h2 class="H22">Tips for Success in Blumgi Slime</h2>
    <p>Want to ace every level in <strong>Blumgi Slime</strong>? Follow these tips:</p>
    <ul>
        <li><strong>Master the Timing:</strong> Precise timing of jumps and bounces is crucial to avoid hazards.</li>
        <li><strong>Plan Ahead:</strong> Study the level layout before making moves to ensure a smooth path to the finish.</li>
        <li><strong>Collect Strategically:</strong> Go for collectibles when it’s safe to avoid unnecessary risks.</li>
        <li><strong>Practice Makes Perfect:</strong> Replaying levels helps improve your control and strategy.</li>
    </ul>
    <h2 class="H22">Play Blumgi Slime Online Now</h2>
    <p>Ready for a bouncing adventure? Dive into <strong>Blumgi Slime</strong> and experience the thrill of physics-based platforming. With its charming design, engaging gameplay, and endless challenges, this game is sure to keep you entertained. Start playing now and see how far you can bounce!</p>

            `,
    tag: ["Skill","2Player","Platform","Addictive"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "GD",
    orientation: 0,
    title: "Jacksmith Unblocked – Forge Weapons and Lead Warriors",
    name: ["Jacksmith", "jacksmith"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/jacksmith/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/jacksmith/520x295.png",
    path: ["/game/jacksmith", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/jacksmith/",
    Walkthrough: "https://www.youtube.com/embed/GsL4Ad6JSs4",
    s_dis:"Play Jacksmith Unblocked online! Forge powerful weapons, lead your warriors, and explore exciting adventures in this tactical blacksmithing game.",
    l_dis:`
     <p><strong>Jacksmith</strong> is an engaging blacksmithing and tactical combat game developed by Flipline Studios. Set in a vibrant fantasy world, players take on the role of Jacksmith, a skilled blacksmith who forges weapons for brave heroes. Combining creativity in crafting with strategic planning for battles, Jacksmith offers a unique gaming experience. For uninterrupted fun, <strong>Jacksmith Unblocked</strong> allows players to enjoy the game anywhere, even in restricted environments.</p>
    <h2 class="H22">What is Jacksmith?</h2>
    <p>Jacksmith is a one-of-a-kind game that blends weapon crafting and battle strategy. Players design and forge custom weapons for a team of adventurers, ensuring they are well-equipped to face dangerous enemies. The game’s dynamic gameplay, colorful graphics, and engaging storyline make it a standout among <strong>Papa’s Games</strong>.</p>
    <h2 class="H22">How to Play Jacksmith</h2>
    <p>Jacksmith offers a seamless combination of crafting and strategy. Here’s how you can get started:</p>
    <ul>
        <li><strong>Gather Materials:</strong> Collect ores, metals, and other crafting materials during your journey.</li>
        <li><strong>Forge Weapons:</strong> Design swords, bows, and other equipment tailored to your warriors’ needs.</li>
        <li><strong>Equip Heroes:</strong> Assign the forged weapons to your team to prepare them for battle.</li>
        <li><strong>Lead the Fight:</strong> Strategically guide your warriors in real-time combat, using their skills and weapons effectively.</li>
    </ul>
    <h2 class="H22">Why Play Jacksmith Unblocked?</h2>
    <p>Playing <strong>Jacksmith Unblocked</strong> ensures you can access this fantastic game without restrictions. Here’s why it’s a must-play:</p>
    <ul>
        <li><strong>Accessible Anywhere:</strong> Enjoy the game at school, work, or home without interruptions.</li>
        <li><strong>No Downloads Needed:</strong> Play directly in your browser without installation.</li>
        <li><strong>Free to Play:</strong> Dive into the adventure without any costs.</li>
    </ul>
    <h2 class="H22">Features of Jacksmith</h2>
    <p>Jacksmith stands out for its unique blend of gameplay elements and exciting features:</p>
    <ul>
        <li><strong>Crafting Mechanics:</strong> Design and forge a variety of weapons with precision and creativity.</li>
        <li><strong>Real-Time Battles:</strong> Command your team in combat and make strategic decisions to ensure victory.</li>
        <li><strong>Resource Management:</strong> Collect materials and manage resources effectively to craft better weapons.</li>
        <li><strong>Immersive Storyline:</strong> Follow Jacksmith’s journey to rescue a kidnapped princess from an evil wizard.</li>
    </ul>
    <h2 class="H22">Tips for Success in Jacksmith</h2>
    <p>Master Jacksmith with these helpful tips:</p>
    <ul>
        <li><strong>Focus on Quality:</strong> Pay attention to the crafting process to create high-quality weapons.</li>
        <li><strong>Plan Ahead:</strong> Anticipate upcoming battles and craft weapons suitable for specific enemies.</li>
        <li><strong>Collect Resources:</strong> Gather as many materials as possible to unlock advanced crafting options.</li>
        <li><strong>Upgrade Skills:</strong> Improve your blacksmithing and strategic skills to tackle tougher challenges.</li>
    </ul>
    <h2 class="H22">Why Jacksmith is Popular Among Papa’s Games</h2>
    <p>Jacksmith’s creative gameplay and strategic depth make it a standout title in the <strong>Papa’s Games</strong> series. Its mix of crafting and combat appeals to a wide range of players, offering hours of engaging entertainment. Whether you’re a fan of strategy games or simply enjoy crafting, Jacksmith delivers a memorable experience.</p>
    <h2 class="H22">Start Playing Jacksmith Unblocked Today</h2>
    <p>Embark on an epic adventure with <strong>Jacksmith Unblocked</strong>. Forge powerful weapons, lead your warriors to victory, and experience the thrill of this one-of-a-kind blacksmithing game. Play now and unleash your creativity and strategic skills in the ultimate fantasy world!</p>

            `,
    tag: ["Papa","Management","Flash","Addictive"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "GD",
    orientation: 0,
    title: "Vex 3 Unblocked - Play the Ultimate Stickman Parkour Game",
    name: ["Vex 3", "vex-3"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/vex-3/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/vex-3/520x295.png",
    path: ["/game/vex-3", "https://playcutegames.com"],
    iframe: "https://html5.gamedistribution.com/762c932b4db74c6da0c1d101b2da8be6/?gd_sdk_referrer_url=https://cutedressup.com/game/vex-3/",
    Walkthrough: "https://www.youtube.com/embed/ioAQtX6Ep8g",
    s_dis:"Play Vex 3 Unblocked online and enjoy the thrilling stickman parkour adventure. Navigate challenging levels and master your skills in this exciting platformer.",
    l_dis:`
     <p><strong>Vex 3</strong> is a dynamic and thrilling stickman platformer game that has captured the attention of gamers worldwide. This third installment in the popular Vex series introduces more complex levels, dangerous traps, and exhilarating challenges. Whether you’re jumping over spikes, sliding through narrow gaps, or navigating intricate mazes, Vex 3 offers a heart-pounding adventure for players of all skill levels. If you’re looking for a way to enjoy uninterrupted fun, <strong>Vex 3 unblocked</strong> is your go-to solution for gaming anytime, anywhere.</p>
    <h2 class="H22">What Makes Vex 3 Special?</h2>
    <p>Vex 3 builds on the success of its predecessors by delivering a refined gaming experience. Players control a stickman character as they maneuver through increasingly difficult levels filled with obstacles like spinning saws, deadly spikes, and tricky jumps. The game’s precise controls and fluid animations create a seamless and immersive experience. With hidden secrets and bonus challenges, Vex 3 keeps players engaged and eager to master each level.</p>
    <h2 class="H22">How to Play Vex 3</h2>
    <p>Getting started with <strong>Vex 3</strong> is simple, but mastering it requires skill and strategy. Follow these steps to begin your parkour journey:</p>
    <ul>
        <li><strong>Use the Controls:</strong> Move with the arrow or WASD keys to run, jump, and slide through obstacles.</li>
        <li><strong>Navigate the Levels:</strong> Overcome hazards like spikes, moving platforms, and spinning blades to reach the end.</li>
        <li><strong>Checkpoints:</strong> Use checkpoints to save your progress within a level.</li>
        <li><strong>Explore and Challenge:</strong> Find hidden areas and aim for the fastest completion times.</li>
    </ul>
    <h2 class="H22">Why Play Vex 3 Unblocked?</h2>
    <p><strong>Vex 3 unblocked</strong> allows you to enjoy the game without restrictions, making it accessible at school, work, or any location with internet access. Here’s why it’s a great choice:</p>
    <ul>
        <li><strong>No Barriers:</strong> Play the game anywhere without limitations.</li>
        <li><strong>Browser-Based:</strong> No need for downloads or installations—just open and play.</li>
        <li><strong>Free to Play:</strong> Experience all the fun without any cost.</li>
    </ul>
    <h2 class="H22">Features of Vex 3</h2>
    <p>Vex 3 is packed with features that make it a standout platformer:</p>
    <ul>
        <li><strong>Challenging Levels:</strong> Test your skills with intricate designs and obstacles.</li>
        <li><strong>Hidden Secrets:</strong> Discover bonus areas and collectibles for extra rewards.</li>
        <li><strong>Responsive Controls:</strong> Enjoy smooth and precise gameplay for a satisfying experience.</li>
        <li><strong>Fast-Paced Action:</strong> Engage in high-speed parkour that keeps you on your toes.</li>
    </ul>
    <h2 class="H22">Tips and Strategies for Success in Vex 3</h2>
    <p>Mastering <strong>Vex 3</strong> requires practice and smart strategies. Here are some tips to help you excel:</p>
    <ul>
        <li><strong>Stay Calm:</strong> Take your time to learn each level’s layout before rushing.</li>
        <li><strong>Use Checkpoints:</strong> Take advantage of checkpoints to save your progress and reduce frustration.</li>
        <li><strong>Practice Timing:</strong> Perfect your timing to avoid traps and make precise jumps.</li>
        <li><strong>Replay Levels:</strong> Improve your skills by revisiting levels and aiming for better times.</li>
    </ul>
    <h2 class="H22">Why Vex 3 Continues to Shine</h2>
    <p>The enduring popularity of <strong>Vex 3</strong> lies in its balance of challenge and fun. The game’s minimalist design, fluid controls, and engaging level mechanics keep players coming back for more. Whether you’re competing for high scores or simply enjoying the adventure, Vex 3 delivers an unforgettable experience.</p>
    <h2 class="H22">Play Vex 3 Unblocked Now</h2>
    <p>Don’t miss the chance to dive into the thrilling world of <strong>Vex 3 unblocked</strong>. Perfect for fans of action-packed platformers, this game offers endless excitement and replay value. Start your stickman parkour journey today and see how far you can go!</p>

            `,
tag: ["Action","Skill","Platformer","Addictive"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-13",
    brand: "playcutegames",
    orientation: 0,
    title: "Santa Clicker Unblocked – Spread Holiday Cheer!",
    name: ["Santa Clicker", "santa-clicker"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/santa-clicker/250x142.png",
    thumb_big:
      "https://cdn.cutedressup.in/games/cutedressup_new/santa-clicker/520x295.png",
    path: ["/game/santa-clicker", "https://playcutegames.com"],
    iframe:"https://games.cutedressup.net/santa-clicker/",
    Walkthrough: "https://www.youtube.com/embed/Agdg1grklrI",
    s_dis:
    "Play Santa Clicker Unblocked and experience a fun holiday-themed clicker game. Help Santa Claus spread joy and unlock festive upgrades!",
    l_dis:
      `<p><strong>Santa Clicker</strong> is a delightful idle-clicker game that captures the magic of Christmas. In this festive adventure, you take on the role of Santa Claus, managing his holiday preparations by clicking to earn gifts and upgrading his operations. With simple mechanics and engaging gameplay, <strong>Santa Clicker</strong> is perfect for players of all ages looking to enjoy the holiday spirit. The <strong>Santa Clicker Unblocked</strong> version ensures you can play the game anywhere without restrictions, making it ideal for festive fun anytime, anywhere.</p>
    <h2 class="H22">What is Santa Clicker?</h2>
    <p><strong>Santa Clicker</strong> is a charming holiday-themed game where players help Santa by collecting gifts, upgrading his workshop, and spreading Christmas cheer. Combining the addictive nature of clicker games with a festive twist, it offers endless hours of fun. Whether you’re new to idle games or a seasoned player, Santa Clicker’s cheerful graphics and holiday theme make it a must-play during the season.</p>
    <h2 class="H22">How to Play Santa Clicker</h2>
    <p>Playing <strong>Santa Clicker</strong> is simple and enjoyable. Here’s how to get started:</p>
    <ul>
        <li><strong>Click to Earn Gifts:</strong> Tap or click to produce gifts and increase your earnings.</li>
        <li><strong>Upgrade Santa’s Workshop:</strong> Use the gifts earned to unlock exciting upgrades and increase production.</li>
        <li><strong>Automate Tasks:</strong> Invest in helpers and tools to automate gift production and maximize efficiency.</li>
        <li><strong>Unlock Achievements:</strong> Complete special milestones to earn bonuses and boost your progress.</li>
    </ul>
    <h2 class="H22">Why Play Santa Clicker Unblocked?</h2>
    <p><strong>Santa Clicker Unblocked</strong> ensures you can enjoy this festive game anywhere, even in environments with restricted access. Here’s why it’s a fantastic choice:</p>
    <ul>
        <li><strong>Accessible Anywhere:</strong> Play at school, work, or home without interruptions.</li>
        <li><strong>No Downloads Needed:</strong> Enjoy browser-based gameplay without installation.</li>
        <li><strong>Free to Play:</strong> Dive into the festive fun at no cost.</li>
    </ul>
    <h2 class="H22">Features of Santa Clicker</h2>
    <p>Santa Clicker offers an array of features that make it a standout among <strong>Santa games</strong>:</p>
    <ul>
        <li><strong>Festive Theme:</strong> Immerse yourself in a cheerful holiday setting with vibrant graphics and music.</li>
        <li><strong>Exciting Upgrades:</strong> Unlock new tools, helpers, and features to enhance your workshop.</li>
        <li><strong>Idle Mechanics:</strong> Progress even when you’re not actively playing, thanks to automated production.</li>
        <li><strong>Achievements:</strong> Earn rewards by reaching special milestones and goals.</li>
    </ul>
    <h2 class="H22">Tips for Success in Santa Clicker</h2>
    <p>Maximize your progress in <strong>Santa Clicker</strong> with these helpful tips:</p>
    <ul>
        <li><strong>Focus on Upgrades:</strong> Prioritize upgrades that increase gift production efficiency.</li>
        <li><strong>Invest in Automation:</strong> Unlock helpers to automate tasks and boost production while you’re away.</li>
        <li><strong>Complete Achievements:</strong> Aim for achievements to earn additional bonuses and perks.</li>
        <li><strong>Keep Clicking:</strong> Active clicking early on helps build momentum and unlock upgrades faster.</li>
    </ul>
    <h2 class="H22">Why Santa Clicker Stands Out Among Santa Games</h2>
    <p><strong>Santa Clicker</strong> is more than just an idle game—it’s a celebration of the holiday spirit. Its festive theme, engaging gameplay, and endless progression make it a standout choice for fans of <strong>Santa games</strong>. Whether you’re looking to relax or challenge yourself, Santa Clicker delivers a heartwarming gaming experience.</p>
    <h2 class="H22">Play Santa Clicker Unblocked Now</h2>
    <p>Spread the joy of Christmas with <strong>Santa Clicker Unblocked</strong>. Help Santa prepare for the holidays, unlock exciting upgrades, and enjoy the magic of the season. Start playing today and experience the ultimate holiday clicker game!</p>
 `,
    tag: ["Santa","Clicker","Christmas","Holiday","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
    platform:"both",
  },
  {
    date: "2024-12-10",
    brand: "Others",
    orientation: 0,
    title: "Duck Life 2 Unblocked - Play Duck Life Online",
    name: ["Duck Life 2", "duck-life-2"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/duck-life-2/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/duck-life-2/520x295.png",
    path: ["/game/duck-life-2", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/duck-life-2/",
    Walkthrough: "https://www.youtube.com/embed/mI2K-r8QQXI",
    s_dis:"Play Duck Life 2 Unblocked and train your duck for exciting races in thrilling worlds. Enjoy endless fun with no downloads needed!",
    l_dis:`
    <p><strong>Duck Life 2</strong> is the highly anticipated sequel to the original Duck Life game, featuring new challenges, engaging gameplay, and exciting upgrades. In this game, players take on the role of a duck trainer, honing their duck’s skills to compete in intense races. With <strong>Duck Life 2 Unblocked</strong>, you can enjoy this captivating game anywhere, including restricted environments like schools and workplaces, without limitations.</p>
    <h2 class="H22">What is Duck Life 2?</h2>
    <p>Duck Life 2 expands on the charm and mechanics of the original game, adding new worlds, improved graphics, and a variety of training modes. The goal is to develop your duck’s abilities—speed, endurance, and agility—through mini-games and strategic upgrades. As you progress, you’ll unlock unique customization options and conquer diverse racing tracks.</p>
    <h2 class="H22">How to Play Duck Life 2</h2>
    <p>Playing Duck Life 2 is both simple and fun. Follow these steps to get started:</p>
    <ul>
        <li><strong>Train Your Duck:</strong> Participate in mini-games to improve your duck’s skills like running, swimming, flying, and climbing.</li>
        <li><strong>Earn Coins:</strong> Collect coins during training to purchase upgrades, food, and cosmetic items.</li>
        <li><strong>Compete in Races:</strong> Enter your duck into thrilling races to earn rewards and progress through the game.</li>
        <li><strong>Explore Worlds:</strong> Unlock new regions with unique challenges and opportunities to upgrade your duck.</li>
    </ul>
    <h2 class="H22">Why Play Duck Life Unblocked?</h2>
    <p><strong>Duck Life Unblocked</strong> provides a seamless gaming experience by removing restrictions that might limit access. Here’s why it’s a great option:</p>
    <ul>
        <li><strong>Accessible Anywhere:</strong> Play the game at school, work, or home without interruptions.</li>
        <li><strong>Browser-Based:</strong> No downloads or installations required, making it convenient and hassle-free.</li>
        <li><strong>Free to Play:</strong> Enjoy all the features of Duck Life 2 without any cost.</li>
    </ul>
    <h2 class="H22">Features of Duck Life 2</h2>
    <p>Duck Life 2 offers an array of features that enhance the gaming experience:</p>
    <ul>
        <li><strong>Diverse Training Modes:</strong> Improve your duck’s abilities in four unique training categories.</li>
        <li><strong>Customization Options:</strong> Personalize your duck with fun cosmetic upgrades and accessories.</li>
        <li><strong>Expansive Worlds:</strong> Explore different regions with distinct challenges and races.</li>
        <li><strong>Engaging Mini-Games:</strong> Practice skills while enjoying fun and interactive mini-games.</li>
    </ul>
    <h2 class="H22">Tips for Success in Duck Life 2</h2>
    <p>Want to master Duck Life 2? Use these tips to optimize your gameplay:</p>
    <ul>
        <li><strong>Balance Training:</strong> Focus on all skills to ensure your duck performs well in various races.</li>
        <li><strong>Upgrade Smartly:</strong> Invest coins in both skills and cosmetic upgrades to boost performance and style.</li>
        <li><strong>Explore Thoroughly:</strong> Unlock all regions to maximize your duck’s potential and rewards.</li>
        <li><strong>Practice Regularly:</strong> Spend time in training mini-games to perfect your skills.</li>
    </ul>
    <h2 class="H22">Why Unblocked Games Are Popular</h2>
    <p><strong>Unblocked Games</strong> have gained immense popularity for their accessibility and convenience. They allow players to enjoy their favorite titles in restricted environments, such as schools and workplaces, without compromising on quality or features. Duck Life 2 Unblocked is a prime example, offering endless fun without the need for downloads or special permissions.</p>
    <h2 class="H22">Play Duck Life 2 Unblocked Now</h2>
    <p>Dive into the exciting world of <strong>Duck Life 2 Unblocked</strong> today! Train your duck, conquer thrilling races, and explore diverse worlds—all while enjoying unrestricted gameplay. Start your adventure now and become the ultimate duck trainer!</p>
    `,
    tag: ["Survival","Skill","Arcade","Pet"],
    rating:[4.4,1754,246],  
  },
  {
    date: "2024-12-04",
    brand: "Others",
    orientation: 0,
    title: "Crossy Road - Play Crossy Road Online",
    name: ["Crossy Road", "crossy-road"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/crossy-road/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/crossy-road/520x295.png",
    path: ["/game/crossy-road", "https://playcutegames.com"],
    iframe: "https://i.gamesgo.net/uploads/game/html5/4072/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:"Play Crossy Road Unblocked online and enjoy endless arcade fun. Guide your character across roads, rivers, and tracks in this engaging game.",
    l_dis:`
    <p><strong>Crossy Road</strong> is an iconic endless arcade game that challenges players to guide their character across roads, rivers, and train tracks without getting hit or falling into hazards. Known for its charming visuals, quirky characters, and addictive mechanics, Crossy Road has captivated millions worldwide. With <strong>Crossy Road Unblocked</strong>, players can now enjoy this classic game anytime, anywhere, without restrictions.</p>
    <h2 class="H22">What is Crossy Road?</h2>
    <p>Crossy Road is a vibrant and engaging game that reinvents the classic "Frogger" mechanics for modern audiences. Players control various characters, hopping forward to avoid vehicles, rivers, and other obstacles while collecting coins along the way. With its simple yet challenging gameplay, Crossy Road is perfect for players of all ages, offering hours of entertainment.</p>
    <h2 class="H22">How to Play Crossy Road</h2>
    <p>Playing Crossy Road is straightforward yet incredibly fun. Here’s how it works:</p>
    <ul>
        <li><strong>Control Your Character:</strong> Tap or click to move forward and swipe to change direction.</li>
        <li><strong>Avoid Obstacles:</strong> Dodge cars, trains, and other hazards while crossing roads and rivers.</li>
        <li><strong>Collect Coins:</strong> Gather coins to unlock new characters and bonuses.</li>
        <li><strong>Stay Alert:</strong> Keep moving forward to avoid getting caught by the scrolling screen.</li>
    </ul>
    <h2 class="H22">Why Play Crossy Road Unblocked?</h2>
    <p>The <strong>Unblocked Games</strong> version of Crossy Road ensures that players can enjoy the game without restrictions. Here’s why it’s an excellent choice:</p>
    <ul>
        <li><strong>Access Anywhere:</strong> Play the game at school, work, or home without limitations.</li>
        <li><strong>No Downloads Needed:</strong> Enjoy browser-based gameplay without installations.</li>
        <li><strong>Free to Play:</strong> Dive into the arcade fun without spending a penny.</li>
    </ul>
    <h2 class="H22">Features of Crossy Road</h2>
    <p>Crossy Road stands out with its unique features that keep players coming back for more:</p>
    <ul>
        <li><strong>Diverse Characters:</strong> Unlock and play as various quirky characters, from chickens to robots.</li>
        <li><strong>Endless Challenges:</strong> Test your reflexes with progressively harder levels.</li>
        <li><strong>Vibrant Graphics:</strong> Enjoy colorful visuals and charming animations.</li>
        <li><strong>Simple Controls:</strong> Intuitive mechanics make it easy to play for all ages.</li>
    </ul>
    <h2 class="H22">Tips and Strategies for Success</h2>
    <p>Want to improve your Crossy Road skills? Use these tips to achieve higher scores:</p>
    <ul>
        <li><strong>Plan Ahead:</strong> Look for safe paths and anticipate obstacles as you progress.</li>
        <li><strong>Be Patient:</strong> Don’t rush—wait for the right moment to move forward.</li>
        <li><strong>Collect Coins Wisely:</strong> Grab coins when it’s safe, but don’t risk getting hit.</li>
        <li><strong>Stay Calm:</strong> Focus on timing and avoid panic in fast-paced sections.</li>
    </ul>
    <h2 class="H22">Why Unblocked Games Are Popular</h2>
    <p><strong>Unblocked Games</strong> have gained immense popularity because they provide accessible and unrestricted entertainment. These games are browser-based, free, and require no downloads, making them ideal for casual gaming sessions. Crossy Road Unblocked perfectly fits this category, offering endless fun to players wherever they are.</p>
    <h2 class="H22">Play Crossy Road Unblocked Now</h2>
    <p>Experience the excitement of <strong>Crossy Road Unblocked</strong> today! Whether you’re looking to relive arcade nostalgia or discover a new favorite, this game delivers endless challenges and fun. Jump in now and see how far you can go while dodging obstacles and collecting coins!</p>
    `,
    tag: ["Action","Poki","Skill","Arcade","Animal"],
    rating:[4.4,1754,246],  
  },
  {
    date: "2024-12-04",
    brand: "Others",
    orientation: 1,
    title: "Google Snake - Play Google Snake Online",
    name: ["Google Snake", "google-snake"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/google-snake/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/google-snake/520x295.png",
    path: ["/game/google-snake", "https://playcutegames.com"],
    iframe: "https://ext.minijuegosgratis.com/google-snake/game.html",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:"Google Snake is a classic arcade game where you guide a growing snake to eat food, avoid obstacles, and survive as long as possible!",
    l_dis:`
           <p>Google Snake is a nostalgic arcade game that has captivated players for generations. Its simple yet engaging gameplay involves guiding a snake to eat food while avoiding obstacles and itself. This modern adaptation of the classic snake game brings new challenges and exciting features to keep players entertained for hours.</p>
    <h2 class="H22">What is Google Snake?</h2>
    <p>Google Snake is a digital remake of the classic arcade game that has been integrated into Google’s platform. It offers a fun and accessible way to experience the timeless snake game, featuring vibrant graphics and customizable gameplay options. Perfect for casual gamers and enthusiasts alike, Google Snake remains a favorite for all ages.</p>
    <h2 class="H22">How to Play Google Snake</h2>
    <p>The rules of Google Snake are straightforward, making it easy for anyone to enjoy. Here’s how to play:</p>
    <ul>
        <li><strong>Control the Snake:</strong> Use the arrow keys or swipe gestures to navigate the snake.</li>
        <li><strong>Eat Food:</strong> Guide the snake to consume food items, which increase its length.</li>
        <li><strong>Avoid Obstacles:</strong> Steer clear of walls and the snake’s own body to stay alive.</li>
        <li><strong>Challenge Yourself:</strong> Try to score as high as possible by eating more food without colliding.</li>
    </ul>
    <h2 class="H22">Why Play Google Snake Unblocked?</h2>
    <p>Google Snake Unblocked allows players to access the game anytime, anywhere, without restrictions. Here’s why it’s a great choice:</p>
    <ul>
        <li><strong>Play Anywhere:</strong> Whether at school, work, or home, enjoy uninterrupted gameplay.</li>
        <li><strong>Browser-Based:</strong> No downloads or installations are required.</li>
        <li><strong>Free to Play:</strong> Dive into the action without spending a penny.</li>
    </ul>
    <h2 class="H22">Features of Google Snake</h2>
    <p>Google Snake offers exciting features that enhance the classic gameplay experience:</p>
    <ul>
        <li><strong>Customizable Settings:</strong> Adjust game speed and grid size to match your skill level.</li>
        <li><strong>Modern Graphics:</strong> Enjoy colorful and visually appealing designs.</li>
        <li><strong>Easy Controls:</strong> Intuitive inputs make it accessible to players of all ages.</li>
        <li><strong>Endless Challenges:</strong> Test your skills with progressively harder levels.</li>
    </ul>
    <h2 class="H22">Tips and Strategies for Google Snake</h2>
    <p>Want to maximize your score in Google Snake? Use these tips to improve your gameplay:</p>
    <ul>
        <li><strong>Plan Your Moves:</strong> Think ahead to avoid trapping yourself as the snake grows longer.</li>
        <li><strong>Control the Speed:</strong> Start slow and increase speed as you gain confidence.</li>
        <li><strong>Stay in Open Spaces:</strong> Avoid corners to maintain flexibility in movement.</li>
        <li><strong>Practice Regularly:</strong> Consistent play helps you improve reaction times and strategies.</li>
    </ul>
    <h2 class="H22">Why Google Snake Remains Popular</h2>
    <p>Google Snake’s enduring appeal lies in its simplicity and addictive gameplay. The combination of nostalgia and modern updates has made it a go-to choice for casual gaming. Its accessibility and easy-to-learn mechanics ensure it remains a favorite for players worldwide.</p>
    <h2 class="H22">Play Google Snake Unblocked Now</h2>
    <p>Experience the fun and excitement of Google Snake Unblocked today! Whether you’re reliving childhood memories or discovering the game for the first time, Google Snake offers endless entertainment. Start playing now and see how high you can score!</p>
`,
    tag: ["Snake","Slither","Survival","Arcade","Collecting","Doodle"],
    rating:[4.4,1754,246],  
  },
  {
    date: "2024-12-04",
    brand: "GD",
    orientation: 0,
    title: "Red Ball Forever - Play Red Ball Forever Online",
    name: ["Red Ball Forever", "red-ball-forever"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/red-ball-forever/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/red-ball-forever/520x295.png",
    path: ["/game/red-ball-forever", "https://playcutegames.com"],
    iframe: "https://html5.gamedistribution.com/dc7eea27033344bcb0dd85686e16bc86/?gd_sdk_referrer_url=https://playcutegames.com/game/red-ball-forever",
    Walkthrough: "https://www.youtube.com/embed/bM-Uqx-LdBU",
    s_dis:"Red Ball Forever offers exciting adventures as you guide the cheerful red ball through challenging levels, dodging obstacles and defeating foes!",
    l_dis:`
             <p>Red Ball Forever is an engaging platformer game where players guide a cheerful red ball through a series of levels filled with exciting challenges. Whether you're solving puzzles, dodging enemies, or overcoming obstacles, this game promises endless fun and entertainment. Its vibrant graphics and easy-to-learn mechanics make it suitable for players of all ages.</p>
    <h2 class="H22">What is Red Ball Forever?</h2>
    <p>Red Ball Forever is a continuation of the popular Red Ball series, renowned for its fun and challenging gameplay. In this installment, you take control of the iconic red ball, navigating through diverse environments while collecting stars and avoiding hazards. The game’s combination of strategy, timing, and physics-based mechanics keeps players hooked from start to finish.</p>
    <h2 class="H22">How to Play Red Ball Forever</h2>
    <p>Red Ball Forever is easy to pick up but challenging to master. Here's how to play:</p>
    <ul>
        <li><strong>Move and Jump:</strong> Use the arrow keys or on-screen controls to roll and jump your way through levels.</li>
        <li><strong>Collect Stars:</strong> Gather as many stars as possible to maximize your score.</li>
        <li><strong>Avoid Obstacles:</strong> Navigate around spikes, pits, and enemies to stay alive.</li>
        <li><strong>Solve Puzzles:</strong> Use levers, switches, and other mechanisms to progress through each level.</li>
    </ul>
    <h2 class="H22">Why Play Red Ball Forever Unblocked?</h2>
    <p>Playing Red Ball Forever Unblocked lets you enjoy the game anytime, anywhere without any restrictions. Whether you're at school, work, or on the go, the unblocked version ensures uninterrupted fun. Here are the benefits:</p>
    <ul>
        <li><strong>Unlimited Access:</strong> Play from any device without worrying about restrictions.</li>
        <li><strong>Browser-Based Gameplay:</strong> No downloads or installations required—just launch the game in your browser.</li>
        <li><strong>Free to Play:</strong> Enjoy all the fun features of Red Ball Forever without any cost.</li>
    </ul>
    <h2 class="H22">Features of Red Ball Forever</h2>
    <p>Red Ball Forever offers a variety of features that keep players coming back for more:</p>
    <ul>
        <li><strong>Challenging Levels:</strong> Each level is packed with puzzles, obstacles, and surprises.</li>
        <li><strong>Intuitive Controls:</strong> Smooth and responsive controls make gameplay enjoyable.</li>
        <li><strong>Colorful Graphics:</strong> The bright visuals create a fun and immersive gaming experience.</li>
        <li><strong>Exciting Mechanics:</strong> Physics-based puzzles and platforming challenges add depth to the gameplay.</li>
        <li><strong>Replayability:</strong> Aim for a perfect score by collecting all stars and completing levels flawlessly.</li>
    </ul>
    <h2 class="H22">Tips for Mastering Red Ball Forever</h2>
    <p>Want to become a Red Ball Forever pro? Follow these tips:</p>
    <ul>
        <li><strong>Plan Your Moves:</strong> Take a moment to analyze the level before rushing forward.</li>
        <li><strong>Time Your Jumps:</strong> Precise timing is key to avoiding obstacles and enemies.</li>
        <li><strong>Use Momentum:</strong> Build momentum to roll past tricky sections smoothly.</li>
        <li><strong>Experiment with Puzzles:</strong> Test different solutions to figure out how to unlock new areas.</li>
    </ul>
    <h2 class="H22">Why Players Love Red Ball Forever</h2>
    <p>Fans of Red Ball Forever love its mix of challenging puzzles and platforming action. The game’s charming design, engaging mechanics, and satisfying level progression make it a hit among casual and hardcore gamers alike. Whether you're solving intricate puzzles or pulling off daring jumps, every moment is packed with fun and excitement.</p>
    <h2 class="H22">Play Red Ball Forever Unblocked Now</h2>
    <p>Ready to roll into action? Play Red Ball Forever Unblocked today and experience the thrill of this classic platformer. With no restrictions or barriers, you can enjoy the game anytime and anywhere. Start your adventure now and see how far you can go!</p>
`,
tag: ["Ball","Skill","Arcade","Platformer"],
    rating:[4.4,2512,142],  
  },
  {
    date: "2024-12-04",
    brand: "Poki games",
    orientation: 0,
    title: "Dreadhead Parkour - Play Dreadhead Parkour Online",
    name: ["Dreadhead Parkour", "dreadhead-parkour"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dreadhead-parkour/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dreadhead-parkour/520x295.png",
    path: ["/game/dreadhead-parkour", "https://playcutegames.com"],
    iframe: "https://bitlifeonline.github.io/class12523/dreadhead-parkour/",
    Walkthrough: "https://www.youtube.com/embed/YW0u_mYSSuc",
    s_dis:"Dreadhead Parkour lets you perform thrilling jumps, flips, and parkour stunts through challenging levels filled with obstacles and action.",
    l_dis:`
             <p>Dreadhead Parkour is an adrenaline-filled platformer that brings the excitement of parkour to your screen. Leap, run, and flip your way through challenging levels filled with obstacles and traps. With its vibrant graphics, fast-paced gameplay, and fluid controls, Dreadhead Parkour keeps you on your toes as you aim to complete each level with precision and style.</p>
    <h2 class="H22">What is Dreadhead Parkour?</h2>
    <p>Dreadhead Parkour is an exciting game that combines platforming and parkour. You take on the role of a daring runner navigating intricate levels filled with hurdles, gaps, and other challenges. The game tests your reflexes and timing as you perform incredible stunts and try to reach the end of each stage. Its dynamic gameplay and captivating visuals make it a standout in the parkour game genre.</p>
    <h2 class="H22">How to Play Dreadhead Parkour</h2>
    <p>Getting started with Dreadhead Parkour is easy, but mastering it requires skill and practice. Here’s how to play:</p>
    <ul>
        <li><strong>Run and Jump:</strong> Use the controls to sprint and jump over obstacles.</li>
        <li><strong>Perform Stunts:</strong> Execute flips and tricks to navigate tricky sections with style.</li>
        <li><strong>Avoid Hazards:</strong> Dodge traps and avoid falling off ledges to keep progressing.</li>
        <li><strong>Complete Levels:</strong> Reach the finish line as quickly and efficiently as possible to earn a high score.</li>
    </ul>
    <h2 class="H22">Why Play Dreadhead Parkour Unblocked?</h2>
    <p>Dreadhead Parkour Unblocked allows you to enjoy the game without restrictions, making it accessible anytime and anywhere. Here’s why the unblocked version is perfect for players:</p>
    <ul>
        <li><strong>Unlimited Access:</strong> Play the game even in environments where gaming sites might be restricted.</li>
        <li><strong>Browser-Based Gameplay:</strong> No downloads or installations required—just open your browser and start playing.</li>
        <li><strong>Quick Fun:</strong> Jump into action and enjoy parkour thrills whenever you have free time.</li>
    </ul>
    <h2 class="H22">Features of Dreadhead Parkour</h2>
    <p>Dreadhead Parkour offers a range of features that make it an engaging and fun game for players of all skill levels:</p>
    <ul>
        <li><strong>Challenging Levels:</strong> Each level is designed to test your reflexes and timing with intricate obstacles.</li>
        <li><strong>Stylish Parkour Moves:</strong> Perform flips, rolls, and other tricks to navigate the environment.</li>
        <li><strong>Colorful Graphics:</strong> The vibrant visuals create an immersive experience.</li>
        <li><strong>Fluid Controls:</strong> Enjoy smooth and responsive gameplay that makes parkour feel natural.</li>
        <li><strong>Competitive Scoring:</strong> Aim for the fastest time and highest score to challenge yourself and others.</li>
    </ul>
    <h2 class="H22">Tips for Mastering Dreadhead Parkour</h2>
    <p>Here are some tips to help you improve your performance in Dreadhead Parkour:</p>
    <ul>
        <li><strong>Time Your Jumps:</strong> Perfectly timed jumps can help you clear obstacles and avoid hazards with ease.</li>
        <li><strong>Keep Moving:</strong> Momentum is key in parkour, so try to maintain a steady pace.</li>
        <li><strong>Learn the Levels:</strong> Familiarize yourself with the layout of each level to plan your moves in advance.</li>
        <li><strong>Experiment with Tricks:</strong> Practice different stunts to find the most efficient ways to navigate obstacles.</li>
    </ul>
    <h2 class="H22">Why Players Love Dreadhead Parkour</h2>
    <p>Players love Dreadhead Parkour for its exhilarating gameplay and creative level designs. The game’s unique combination of parkour elements and platforming challenges keeps things fresh and exciting. Its intuitive controls and engaging visuals make it a favorite among casual gamers and parkour enthusiasts alike.</p>
    <h2 class="H22">Play Dreadhead Parkour Unblocked Now</h2>
    <p>Ready to take on the ultimate parkour challenge? Play Dreadhead Parkour Unblocked and enjoy the thrill of high-speed action without any restrictions. Jump, flip, and sprint your way to victory in this exciting and dynamic platformer. Start playing now and see how far your skills can take you!</p>
`,
tag: ["Parkour","Skill","Parkour","Platformer","Poki"],
    rating:[4.2,4273,396],  
  },
  {
    date: "2024-12-03",
    brand: "Yandex",
    orientation: 0,
    title: "Level Devil - Play Shooting Games Online",
    name: ["Level Devil", "level-devil"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/level-devil/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/level-devil/520x295.png",
    path: ["/game/level-devil", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/286369?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/uaEJIEKO2-o",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/level-devil.mp4",
    s_dis:"Level Devil is a challenging puzzle game where players navigate tricky levels, solve puzzles, and overcome obstacles to reach the next stage!",
    l_dis:`
             <p>Level Devil is an exciting puzzle game that challenges players to solve tricky levels while overcoming obstacles and using their wit. The goal is simple – navigate your character through intricate challenges, but only the cleverest players will succeed in overcoming all the tricky obstacles. With increasing difficulty, Level Devil keeps you engaged and thinking strategically every step of the way.</p>
    <h2 class="H22">What is Level Devil?</h2>
    <p>Level Devil is a puzzle-based game where each level presents a unique challenge that requires careful planning and skillful execution. As the levels progress, the difficulty increases, keeping players on their toes. The game tests your problem-solving abilities, reflexes, and creativity as you figure out how to bypass obstacles and reach the next stage.</p>
    <h2 class="H22">How to Play Level Devil</h2>
    <p>Playing Level Devil is easy to learn but difficult to master. Here’s a simple guide on how to play:</p>
    <ul>
        <li><strong>Start the Game:</strong> Launch the game and pick a level to begin your challenge.</li>
        <li><strong>Navigate Obstacles:</strong> Use the arrow keys or on-screen controls to move your character and avoid obstacles.</li>
        <li><strong>Reach the Goal:</strong> Solve each puzzle and reach the goal to proceed to the next level.</li>
        <li><strong>Repeat and Improve:</strong> Keep playing and improve your puzzle-solving skills to conquer harder levels.</li>
    </ul>
    <h2 class="H22">Why Play Level Devil Unblocked?</h2>
    <p>Playing Level Devil Unblocked gives you the freedom to enjoy this challenging puzzle game without restrictions. Whether you're at school, work, or just want to play anywhere, the unblocked version ensures seamless access to all the action.</p>
    <ul>
        <li><strong>Access Anywhere:</strong> Play Level Devil anywhere, anytime, without worrying about blocked websites.</li>
        <li><strong>No Download Needed:</strong> Play directly in your browser with no need to install anything.</li>
        <li><strong>Uninterrupted Fun:</strong> Enjoy the full experience with no disruptions or limitations.</li>
    </ul>
    <h2 class="H22">Features of Level Devil</h2>
    <p>Level Devil offers a variety of features that make it an enjoyable and addictive puzzle game:</p>
    <ul>
        <li><strong>Challenging Levels:</strong> Each level is designed with increasing difficulty to test your skills.</li>
        <li><strong>Creative Puzzles:</strong> Solve puzzles using logic, timing, and creativity to move past obstacles.</li>
        <li><strong>Engaging Gameplay:</strong> The game's mechanics keep you hooked as you progress through each stage.</li>
        <li><strong>Simple Controls:</strong> Easy-to-use controls make the game accessible for all players, regardless of experience level.</li>
        <li><strong>Immersive Design:</strong> Enjoy beautiful graphics and smooth gameplay that keep you focused and entertained.</li>
    </ul>
    <h2 class="H22">Tips and Tricks for Level Devil</h2>
    <p>Here are some tips to help you master Level Devil and conquer the toughest levels:</p>
    <ul>
        <li><strong>Think Ahead:</strong> Plan your moves before acting to avoid mistakes and optimize your path.</li>
        <li><strong>Learn from Failure:</strong> Each failed attempt is an opportunity to improve your strategy.</li>
        <li><strong>Focus on Timing:</strong> Precision and timing are key to overcoming certain obstacles.</li>
        <li><strong>Be Patient:</strong> Some levels require persistence, so take your time to figure them out.</li>
        <li><strong>Try Different Approaches:</strong> If a puzzle feels too difficult, consider different ways to solve it.</li>
    </ul>
    <h2 class="H22">Why Players Love Level Devil</h2>
    <p>Players love Level Devil for its addictive nature and clever puzzle designs. The combination of simple controls, challenging gameplay, and increasing difficulty makes it a game you can return to again and again. Whether you're a casual gamer or a puzzle enthusiast, Level Devil offers hours of entertainment with every level you conquer.</p>
    <h2 class="H22">Play Level Devil Unblocked Now</h2>
    <p>Ready to test your puzzle-solving skills? Play Level Devil Unblocked and see how far you can go. With each level offering new challenges and obstacles, it’s a game that will keep you coming back for more. Start playing now and prove that you have what it takes to master Level Devil!</p>
`,
tag: ["Retro","Action","Parkour","Platformer","Survival"],
    rating:[4.9,5148,1513],  
  },
  {
    date: "2024-12-03",
    brand: "Others",
    orientation: 0,
    title: "Getaway Shootout - Play Shooting Games Online",
    name: ["Getaway Shootout", "getaway-shootout"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/getaway-shootout/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/getaway-shootout/520x295.png",
    path: ["/game/getaway-shootout", "https://playcutegames.com"],
    iframe: "https://htmlxm.github.io/h4/getaway-shootout/",
    Walkthrough: "https://www.youtube.com/embed/oIzkI5NkZJY",
    s_dis:"Getaway Shootout is an action-packed multiplayer game where players battle through intense levels, aiming to escape while avoiding obstacles and enemies!",
    l_dis:`
            <p>Getaway Shootout is a fast-paced multiplayer game that combines hilarious physics-based movements with intense competitive action. Your goal is to reach the getaway point before your opponents while navigating obstacles, collecting power-ups, and using weapons to disrupt your rivals. With quirky characters, unpredictable gameplay, and plenty of laughs, Getaway Shootout offers a unique and entertaining gaming experience.</p>
    <h2 class="H22">What is Getaway Shootout?</h2>
    <p>Getaway Shootout is a side-scrolling game that puts you in thrilling races against AI or real players. The game features unique controls where you must strategically jump and move to outwit your opponents. Along the way, you’ll encounter various weapons and items that can be used to gain an advantage or sabotage others, adding layers of strategy and chaos to the game.</p>
    <h2 class="H22">How to Play Getaway Shootout</h2>
    <p>The gameplay of Getaway Shootout is simple yet highly engaging. Here’s how to play:</p>
    <ul>
        <li><strong>Choose Your Mode:</strong> Play solo against AI, or challenge a friend in 2-player mode.</li>
        <li><strong>Control Your Character:</strong> Use specific keys to jump left, jump right, and navigate through the level.</li>
        <li><strong>Collect Power-Ups:</strong> Pick up weapons, health packs, and boosters to enhance your chances of winning.</li>
        <li><strong>Reach the Getaway Point:</strong> Be the first to cross the finish line to claim victory.</li>
        <li><strong>Repeat the Fun:</strong> Play multiple levels with increasing difficulty and new challenges.</li>
    </ul>
    <h2 class="H22">Why Play Getaway Shootout Unblocked?</h2>
    <p>Playing Getaway Shootout Unblocked allows you to enjoy the game without restrictions, whether at school, work, or anywhere else. Here are the benefits:</p>
    <ul>
        <li><strong>Access Anywhere:</strong> Play the game directly in your browser without needing installations or downloads.</li>
        <li><strong>No Restrictions:</strong> Enjoy the full gameplay experience, bypassing any network blocks.</li>
        <li><strong>Quick Matches:</strong> Jump into action-packed matches anytime for quick and thrilling fun.</li>
    </ul>
    <h2 class="H22">Features of Getaway Shootout</h2>
    <p>Getaway Shootout stands out for its unique and entertaining features:</p>
    <ul>
        <li><strong>Quirky Controls:</strong> Master the unconventional jumping mechanics for precise movements.</li>
        <li><strong>Exciting Power-Ups:</strong> Use a variety of weapons and gadgets to outplay opponents.</li>
        <li><strong>Multiplayer Fun:</strong> Challenge friends or AI in competitive matches.</li>
        <li><strong>Creative Levels:</strong> Explore diverse maps with unique themes and obstacles.</li>
        <li><strong>Customizable Characters:</strong> Unlock and play with different characters for added variety.</li>
    </ul>
    <h2 class="H22">Tips and Tricks for Getaway Shootout</h2>
    <p>Improve your gameplay and dominate the competition with these tips:</p>
    <ul>
        <li><strong>Practice Jumping:</strong> Get comfortable with the unique controls to move more efficiently.</li>
        <li><strong>Use Power-Ups Wisely:</strong> Time your use of weapons and gadgets to maximize their impact.</li>
        <li><strong>Anticipate Opponents:</strong> Predict your rivals’ moves to block their progress or create opportunities.</li>
        <li><strong>Master the Maps:</strong> Familiarize yourself with each level’s layout to find shortcuts and avoid traps.</li>
        <li><strong>Stay Persistent:</strong> The unpredictable physics can be challenging, so keep practicing to improve your skills.</li>
    </ul>
    <h2 class="H22">Why Players Love Getaway Shootout</h2>
    <p>Getaway Shootout’s mix of chaotic gameplay, hilarious moments, and competitive action has made it a favorite among players. Whether you’re battling friends or testing your skills against AI, every match is filled with unexpected twists and laugh-out-loud moments. Its unique mechanics and fun challenges make it a standout in the multiplayer gaming genre.</p>
    <h2 class="H22">Play Getaway Shootout Unblocked Now</h2>
    <p>Ready to jump into the chaos? Play Getaway Shootout Unblocked today and experience the thrill of high-stakes races with crazy power-ups and unpredictable physics. Challenge yourself to reach the getaway point and prove your skills in this one-of-a-kind game. Start playing now and let the fun begin!</p>
`,
tag: ["Multiplayer","2Player","Ragdoll","Platformer","Kids"],
    rating:[4.5,2317,381],  
  },
  {
    date: "2024-12-13",
    brand: "playcutegames",
    orientation: 0,
    title: "The Grinch Couple Holiday Dress Up - Festive Christmas Games",
    name: ["The Grinch Couple Holiday Dress Up", "the-grench-couple-holiday-dress-up"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/the-grench-couple-holiday-dress-up/250x142.png",
    thumb_big:
      "https://cdn.cutedressup.in/games/cutedressup_new/the-grench-couple-holiday-dress-up/520x295.png",
    path: ["/game/the-grench-couple-holiday-dress-up", "https://playcutegames.com"],
    iframe:"https://games.cutedressup.net/the-grench-couple-holiday-dress-up/",
    Walkthrough: "https://www.youtube.com/embed/flJ4fVcwUP4",
    s_dis:
    "Style the Grinch and his partner in The Grinch Couple Holiday Dress Up! Create festive Christmas looks and enjoy the holiday dress-up game.",
    l_dis:
      `
       <p>Step into a magical holiday world with <strong>The Grinch Couple Holiday Dress Up</strong>, a delightful game that combines creativity, fashion, and festive fun. Perfect for fans of <strong>Christmas games</strong>, this game invites you to style the Grinch and his partner in holiday-themed outfits. With endless customization options, you can create unique looks that reflect the festive spirit, making it a must-play for anyone who loves dress-up games and holiday magic.</p>
    <h2 class="H22">What is The Grinch Couple Holiday Dress Up?</h2>
    <p><strong>The Grinch Couple Holiday Dress Up</strong> is a charming and interactive Christmas dress-up game where players get to design outfits for the Grinch and his partner. Featuring a variety of festive clothing, accessories, and hairstyles, this game allows you to express your creativity while immersing yourself in a joyful holiday atmosphere. Whether you prefer classic Christmas styles or quirky, Grinch-inspired looks, this game has something for everyone.</p>
    <h2 class="H22">How to Play The Grinch Couple Holiday Dress Up</h2>
    <p>Getting started with <strong>The Grinch Couple Holiday Dress Up</strong> is simple and fun. Follow these steps to create your perfect holiday look:</p>
    <ul>
        <li><strong>Select a Character:</strong> Choose between the Grinch or his partner to start designing.</li>
        <li><strong>Explore Outfit Options:</strong> Browse through a wide selection of festive clothing, from cozy sweaters to elegant dresses.</li>
        <li><strong>Add Accessories:</strong> Complete the look with hats, scarves, gloves, and other holiday-themed accessories.</li>
        <li><strong>Customize Hairstyles:</strong> Pick the perfect hairstyle to match your outfit.</li>
        <li><strong>Save Your Creation:</strong> Once satisfied, save your design and share it with friends!</li>
    </ul>
    <h2 class="H22">Why Play Christmas Dressup Games?</h2>
    <p><strong>Christmas games</strong> like The Grinch Couple Holiday Dress Up are perfect for capturing the festive spirit and spreading holiday cheer. Here’s why they’re so popular:</p>
    <ul>
        <li><strong>Creative Expression:</strong> Design unique outfits that showcase your holiday style.</li>
        <li><strong>Family-Friendly Fun:</strong> Suitable for all ages, making it a great activity to share with loved ones.</li>
        <li><strong>Seasonal Joy:</strong> Immerse yourself in the magic of Christmas with festive themes and visuals.</li>
    </ul>
    <h2 class="H22">Features of The Grinch Couple Holiday Dress Up</h2>
    <p>This game offers a range of features that enhance the festive dress-up experience:</p>
    <ul>
        <li><strong>Extensive Wardrobe:</strong> Access a variety of clothing options inspired by the holiday season.</li>
        <li><strong>Charming Graphics:</strong> Enjoy vibrant visuals that bring the Grinch and his partner to life.</li>
        <li><strong>Easy-to-Use Interface:</strong> Intuitive controls make the game accessible to players of all ages.</li>
        <li><strong>Customizable Looks:</strong> Mix and match items to create countless unique outfits.</li>
    </ul>
    <h2 class="H22">Tips for Styling in Christmas Dressup Games</h2>
    <p>Maximize your creativity and style with these tips:</p>
    <ul>
        <li><strong>Mix Patterns and Colors:</strong> Combine festive patterns and colors for a bold holiday look.</li>
        <li><strong>Accessorize Thoughtfully:</strong> Use accessories to add a finishing touch that elevates your outfit.</li>
        <li><strong>Try New Styles:</strong> Experiment with different combinations to discover unique holiday looks.</li>
        <li><strong>Embrace the Theme:</strong> Focus on the Christmas spirit with red, green, and gold accents.</li>
    </ul>
    <h2 class="H22">Why The Grinch Couple Holiday Dress Up is a Must-Play</h2>
    <p>With its festive charm and engaging gameplay, <strong>The Grinch Couple Holiday Dress Up</strong> stands out as a must-play for fans of <strong>Christmas games</strong>. The combination of creative freedom, family-friendly fun, and holiday-themed visuals ensures a memorable experience for players of all ages. Whether you’re a Grinch enthusiast or a dress-up game lover, this game offers something special.</p>
    <h2 class="H22">Play The Grinch Couple Holiday Dress Up Now</h2>
    <p>Don’t miss out on the holiday fun—play <strong>The Grinch Couple Holiday Dress Up</strong> today! Create stunning holiday outfits for the Grinch and his partner, and share the festive joy with friends and family. Start your Christmas dress-up adventure now and spread the holiday cheer!</p>

    `,
    tag: ["Dress up","Winter","Girl games","Christmas","Holiday","Villains","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
    platform:"both",
  },
  {
    date: "2024-12-04",
    brand: "playcutegames",
   
    orientation: 0,
    title: "Roblox Couple Autumn DressUp Unblocked - Stylish Roblox Games",
    name: ["Roblox Couple Autumn DressUp", "roblox-couple-autumn-dressup"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/roblox-couple-autumn-dressup/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/roblox-couple-autumn-dressup/520x295.png",
    path: ["/game/roblox-couple-autumn-dressup", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/roblox-couple-autumn-dressup/",
    Walkthrough: "https://www.youtube.com/embed/5LuZU7mUmwo",
    s_dis:"Play Roblox Couple Autumn DressUp Unblocked and explore creative styling in this interactive game. Enjoy Roblox games without restrictions.",
    l_dis:`
    <p>Autumn brings cozy vibes, vibrant fall colors, and endless fashion inspiration. <strong>Roblox Couple Autumn DressUp</strong> lets you channel this season’s charm by styling a Roblox couple in trendy fall outfits. Whether you’re layering cozy sweaters, pairing boots with scarves, or creating a chic look, this game combines creativity and fun for players who love dress-up challenges. With the <strong>DressUp Unblocked</strong> version, you can enjoy the game from anywhere, free of restrictions.</p>
    <h2 class="H22">What is Roblox Couple Autumn DressUp?</h2>
    <p>Roblox Couple Autumn DressUp is an interactive game that allows players to showcase their fashion skills. Set against a beautiful fall-themed backdrop, the game challenges you to style a Roblox couple with the trendiest autumn outfits and accessories. From casual streetwear to elegant looks, the possibilities are endless. It’s a perfect game for Roblox fans who love creative expression and seasonal fashion trends.</p>
    <h2 class="H22">How to Play Roblox Couple Autumn DressUp</h2>
    <p>Playing Roblox Couple Autumn DressUp is simple yet engaging. Here’s how you can dive into this fashionable world:</p>
    <ul>
        <li><strong>Choose Your Characters:</strong> Select a Roblox couple to style for the autumn season.</li>
        <li><strong>Explore Outfits:</strong> Browse through a variety of fall-inspired clothing, shoes, and accessories.</li>
        <li><strong>Create Unique Styles:</strong> Mix and match outfits to create the perfect autumn look.</li>
        <li><strong>Save and Share:</strong> Capture and share your styled couple with friends.</li>
    </ul>
    <h2 class="H22">Why Play Roblox Couple Autumn DressUp Unblocked?</h2>
    <p>The <strong>DressUp Unblocked</strong> version of Roblox Couple Autumn DressUp ensures that players can access the game without any limitations. Here’s why this is the ideal way to play:</p>
    <ul>
        <li><strong>Accessible Anywhere:</strong> Enjoy the game at school, work, or home without restrictions.</li>
        <li><strong>No Downloads Needed:</strong> Play directly in your browser with no installations required.</li>
        <li><strong>Free Gameplay:</strong> Enjoy endless fashion fun without spending a dime.</li>
    </ul>
    <h2 class="H22">Features of Roblox Couple Autumn DressUp</h2>
    <p>Roblox Couple Autumn DressUp stands out with its unique features that make the game enjoyable and engaging:</p>
    <ul>
        <li><strong>Seasonal Themes:</strong> Immerse yourself in a cozy autumn atmosphere with stunning visuals.</li>
        <li><strong>Fashion Variety:</strong> Choose from an extensive wardrobe of fall outfits and accessories.</li>
        <li><strong>Creative Freedom:</strong> Experiment with different styles to express your fashion sense.</li>
        <li><strong>Interactive Gameplay:</strong> Style both characters and see how their looks complement each other.</li>
    </ul>
    <h2 class="H22">Tips for Creating Stunning Styles</h2>
    <p>Want to excel in Roblox Couple Autumn DressUp? Use these tips to create fashionable looks:</p>
    <ul>
        <li><strong>Layer Smartly:</strong> Combine sweaters, jackets, and scarves for a chic autumn ensemble.</li>
        <li><strong>Focus on Details:</strong> Use accessories like hats, gloves, and boots to complete the look.</li>
        <li><strong>Experiment with Colors:</strong> Mix warm tones like reds, browns, and oranges for a true fall vibe.</li>
        <li><strong>Think as a Pair:</strong> Ensure the outfits of both characters complement each other.</li>
    </ul>
    <h2 class="H22">Why Roblox Games Are So Popular</h2>
    <p><strong>Roblox games</strong> have captured the hearts of players worldwide due to their endless creativity and community-driven gameplay. From dress-up challenges to adventurous quests, Roblox offers something for everyone. The ability to create, play, and share makes it a platform that fosters creativity and connection.</p>
    <h2 class="H22">Start Playing Roblox Couple Autumn DressUp Unblocked Today</h2>
    <p>Experience the fun and creativity of <strong>Roblox Couple Autumn DressUp Unblocked</strong>. Whether you’re a fashion enthusiast or a casual gamer, this game offers a perfect blend of seasonal charm and interactive fun. Start styling now and show off your autumn-inspired looks!</p>
    `,
    tag: ["Roblox","Autumn","Couple","Boyfriend","Exclusive"],
    rating:[4.4,1754,246],  
  },
  {
    date: "2024-12-13",
    brand: "playcutegames",
    orientation: 0,
    title: "Roblox Christmas Dressup – Style Your Avatar for the Holidays",
    name: ["Roblox Christmas Dressup", "roblox-christmas-dressup"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/roblox-christmas-dressup/250x142.png",
    thumb_big:
      "https://cdn.cutedressup.in/games/cutedressup_new/roblox-christmas-dressup/520x295.png",
    path: ["/game/roblox-christmas-dressup", "https://playcutegames.com"],
    iframe:"https://games.cutedressup.net/roblox-christmas-dressup/",
    Walkthrough: "https://www.youtube.com/embed/EjOn3kSAb7s",
    s_dis:
    "Play Roblox Christmas Dressup and customize your avatar with festive outfits. Explore Roblox unblocked games and unleash your holiday creativity.",
    l_dis:
      `
       <p><strong>Roblox Christmas Dressup</strong> is a festive treat for gamers who love to express their creativity. This delightful game lets you customize your Roblox avatar with Christmas-themed outfits and accessories, bringing the holiday spirit to the virtual world. Perfect for both fashion enthusiasts and Roblox fans, this game offers endless opportunities to design unique looks and participate in exciting fashion challenges. Celebrate the magic of Christmas in the Roblox universe!</p>
    <h2 class="H22">What is Roblox Christmas Dressup?</h2>
    <p>Roblox Christmas Dressup is a dress-up game that combines the creativity of fashion with the festive charm of Christmas. Players can explore a wide range of holiday-themed clothing, from cozy sweaters and scarves to glamorous party dresses and accessories. Whether you’re dressing up for fun or competing in challenges, the game provides a joyful and immersive experience for all.</p>
    <h2 class="H22">How to Play Roblox Christmas Dressup</h2>
    <p>Getting started with Roblox Christmas Dressup is easy and fun. Here’s how you can join in the holiday festivities:</p>
    <ul>
        <li><strong>Select Your Avatar:</strong> Choose your Roblox character to begin the makeover.</li>
        <li><strong>Browse Outfits:</strong> Explore a variety of Christmas-themed clothing and accessories.</li>
        <li><strong>Mix and Match:</strong> Combine different pieces to create your perfect holiday look.</li>
        <li><strong>Showcase Your Style:</strong> Save your designs or share them with friends to spread the festive cheer.</li>
    </ul>
    <h2 class="H22">Why Play Roblox Unblocked Games?</h2>
    <p>Roblox unblocked games allow players to access their favorite titles without restrictions, making them perfect for school, work, or anywhere else. Here’s why Roblox unblocked is a top choice:</p>
    <ul>
        <li><strong>Access Anywhere:</strong> Play your favorite games anytime, even in restricted environments.</li>
        <li><strong>No Downloads Needed:</strong> Enjoy games directly from your browser without installation.</li>
        <li><strong>Free to Play:</strong> Experience endless fun without spending a dime.</li>
    </ul>
    <h2 class="H22">Features of Roblox Christmas Dressup</h2>
    <p>This game stands out with its unique features that enhance the holiday dress-up experience:</p>
    <ul>
        <li><strong>Holiday-Themed Outfits:</strong> Choose from a vast selection of Christmas-inspired clothing.</li>
        <li><strong>Interactive Gameplay:</strong> Participate in fashion challenges or create unique looks for your avatar.</li>
        <li><strong>High-Quality Graphics:</strong> Enjoy visually appealing designs that capture the festive spirit.</li>
        <li><strong>Customizable Options:</strong> Mix and match outfits, hairstyles, and accessories for endless combinations.</li>
    </ul>
    <h2 class="H22">Tips for Creating the Perfect Festive Look</h2>
    <p>Want to design an avatar that stands out this holiday season? Here are some tips:</p>
    <ul>
        <li><strong>Coordinate Colors:</strong> Use classic holiday shades like red, green, and gold for a festive touch.</li>
        <li><strong>Layer Your Style:</strong> Add scarves, hats, and gloves for a cozy winter vibe.</li>
        <li><strong>Use Accessories:</strong> Incorporate festive items like reindeer antlers or holiday lights for extra flair.</li>
        <li><strong>Experiment Freely:</strong> Don’t be afraid to try bold combinations and unique styles.</li>
    </ul>
    <h2 class="H22">Why Roblox Christmas Dressup is a Must-Play</h2>
    <p>Roblox Christmas Dressup captures the essence of holiday joy and creativity. It’s more than just a dress-up game; it’s an interactive way to celebrate the season. With its vibrant visuals, festive theme, and endless styling options, this game is perfect for players of all ages. Whether you’re a fashion enthusiast or a Roblox fan, it’s a holiday experience you won’t want to miss.</p>
    <h2 class="H22">Start Playing Roblox Christmas Dressup Unblocked</h2>
    <p>Get into the holiday spirit with <strong>Roblox Christmas Dressup</strong> and explore the world of <strong>Roblox unblocked games</strong>. Design festive looks, participate in challenges, and celebrate the magic of Christmas with your Roblox avatar. Start playing now and make this holiday season unforgettable!</p>
`,
    tag: ["Roblox","Dress up","Girl games","Christmas","Holiday","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
    platform:"both",
  },
  {
    date: "2022-12-31",
    brand: "playcutegames",
   
    orientation: 1,
    title: "Mr and Mrs Santa Christmas Adventure - playcutegames.com",
    name: ["Mr and Mrs Santa Christmas Adventure", "mr-and-mrs-santa-christmas-adventure"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/mr-and-mrs-santa-christmas-adventure/250x142.png",
    thumb_big:
      "https://cdn.cutedressup.in/games/cutedressup_new/mr-and-mrs-santa-christmas-adventure/250x142.png",
    path: ["/game/mr-and-mrs-santa-christmas-adventure", "https://playcutegames.com"],
    iframe:
      "https://games.cutedressup.net/partner/mr-and-mrs-santa-christmas-adventure/",
    Walkthrough: "https://www.youtube.com/embed/LQi43kI3iKc",
    s_dis:
    "Mr and Mrs Santa Christmas Adventure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Welcome to Mr. and Mrs. Santa Christmas Adventure.  Christmas is around the corner. Mr. and Mrs. Santa preparing for the celebration. Unfortunately, Mr. Santa met an accident. Santa and his pet got severe injuries and also his sleigh was damaged totally. Help Santa and his pet for a quick recovery so that he can tour around the world to share his Christmas gift with the children.",
    tag: ["Santa","Dress up","Winter","Girl games","Christmas","Holiday","Decoration","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
    platform:"both",
  },
  {
    date: "2022-12-07",
    brand: "playcutegames",
   
    orientation: 0,
    title: "Angela Christmas Dress up Game - playcutegames.com",
    name: ["Angela Christmas Dress up Game", "angela-christmas-dress-up-game"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/angela-christmas-dress-up-game/250x142.png",
    thumb_big:
      "https://cdn.cutedressup.in/games/cutedressup_new/angela-christmas-dress-up-game/250x142.png",
    path: ["/game/angela-christmas-dress-up-game", "https://playcutegames.com"],
    iframe:
      "https://games.cutedressup.net/partner/angela-christmas-dress-up-game/",
    Walkthrough: "https://www.youtube.com/embed/D5r0O4e3YLg",
    s_dis:
    "Angela Christmas Dress up Game Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Welcome to Angela Christmas Dress Up Game. Christmas is around the corner. Angela is more excited than us to enjoy this Christmas with her friends. She has some crazy ideas to make this Christmas more fun! Join and help her to bake and decorate cakes, a Christmas tree, Cookies, and her house.",
    tag: ["Angela","Dress up","Christmas","Decoration","Cooking","Cake","Girl games","Holiday","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
    platform:"both",
  },{
    date: "2024-12-13",
    brand: "playcutegames",
    orientation: 0,
    title: "Black Pink Christmas Concert - Dress Up Games for Fashion Lovers",
    name: ["Black Pink Christmas Concert", "black-pink-christmas-concert"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/black-pink-christmas-concert/250x142.png",
    thumb_big:
      "https://cdn.cutedressup.in/games/cutedressup_new/black-pink-christmas-concert/520x295.png",
    path: ["/game/black-pink-christmas-concert", "https://playcutegames.com"],
    iframe:"https://games.cutedressup.net/black-pink-christmas-concert/",
    Walkthrough: "https://www.youtube.com/embed/14lVVOObOMQ",
    s_dis:
    "Style the Grinch and his partner in Black Pink Christmas Concert! Create festive Christmas looks and enjoy the holiday dress-up game.",
    l_dis:
      ` <p><strong>Black Pink Christmas Concert Dress-Up</strong> is a delightful fashion game designed for fans of Black Pink and dress-up games. This interactive experience allows players to style Jisoo, Jennie, Rosé, and Lisa in stunning outfits for their highly anticipated Christmas concert. Whether you’re a dedicated Blinks fan or just love creative games, this holiday-themed adventure is perfect for you.</p>
    <h2 class="H22">What Makes the Black Pink Christmas Concert Game Special?</h2>
    <p>The <strong>Black Pink Christmas Concert</strong> Dress-Up game brings together the excitement of K-pop and the festive cheer of Christmas. It’s more than just a dress-up game; it’s a way to immerse yourself in the glamorous world of Black Pink while celebrating the holiday season. From dazzling dresses to chic winter outfits, the game offers endless styling possibilities to showcase your creativity.</p>
    <h2 class="H22">How to Play the Black Pink Christmas Concert Dress-Up Game</h2>
    <p>The game is simple to play, making it accessible for players of all ages. Here’s how you can style your favorite Black Pink members:</p>
    <ul>
        <li><strong>Select a Member:</strong> Choose Jisoo, Jennie, Rosé, or Lisa to style first.</li>
        <li><strong>Pick Outfits:</strong> Browse through an array of Christmas-themed dresses, casual winter wear, and glamorous concert outfits.</li>
        <li><strong>Accessorize:</strong> Add festive touches like scarves, jewelry, hats, and shoes to complete the look.</li>
        <li><strong>Save and Share:</strong> Save your styled looks or share them with friends to spread holiday cheer.</li>
    </ul>
    <h2 class="H22">Why Fans Love Black Pink Dress-Up Games</h2>
    <p><strong>Dress-up games</strong> featuring Black Pink are incredibly popular among fans because they offer a unique way to engage with the iconic K-pop group. Here are a few reasons why players love these games:</p>
    <ul>
        <li><strong>Interactive Creativity:</strong> Players get to explore their fashion sense by styling their favorite idols.</li>
        <li><strong>Holiday Fun:</strong> The Christmas theme adds a magical touch to the gameplay.</li>
        <li><strong>Fan Connection:</strong> It’s a way for Blinks to feel closer to their idols by imagining themselves as their personal stylists.</li>
    </ul>
    <h2 class="H22">Features of the Black Pink Christmas Concert Dress-Up Game</h2>
    <p>This game offers several exciting features that enhance the dress-up experience:</p>
    <ul>
        <li><strong>Variety of Outfits:</strong> Choose from a wide range of festive and concert-ready attire.</li>
        <li><strong>High-Quality Graphics:</strong> Enjoy beautifully designed visuals that bring the Black Pink members to life.</li>
        <li><strong>Customizable Looks:</strong> Mix and match outfits, accessories, and hairstyles for endless possibilities.</li>
        <li><strong>Holiday Atmosphere:</strong> Immerse yourself in the festive cheer with Christmas-themed backdrops and music.</li>
    </ul>
    <h2 class="H22">Tips for Creating Stunning Looks</h2>
    <p>Want to create the perfect holiday outfits for Black Pink? Here are some tips to make your styling stand out:</p>
    <ul>
        <li><strong>Coordinate Colors:</strong> Use complementary colors to create harmonious looks.</li>
        <li><strong>Match the Theme:</strong> Incorporate festive elements like red, green, and gold to embrace the Christmas spirit.</li>
        <li><strong>Accessorize Smartly:</strong> Add just the right amount of accessories without overwhelming the outfit.</li>
        <li><strong>Experiment:</strong> Don’t be afraid to try bold combinations for unique and eye-catching styles.</li>
    </ul>
    <h2 class="H22">Why Black Pink Christmas Concert is Perfect for the Holidays</h2>
    <p>This game combines the charm of Christmas with the global phenomenon that is Black Pink. Fans can celebrate the season by expressing their love for fashion and K-pop, making it a must-play for anyone who enjoys festive fun. The engaging gameplay and creative freedom make it an ideal way to spend the holidays.</p>
    <h2 class="H22">Play Black Pink Christmas Concert Dress-Up Now</h2>
    <p>Don’t miss the chance to style your favorite Black Pink members for their Christmas concert. Dive into the festive fashion world and create dazzling looks that will shine on stage. Start playing the <strong>Black Pink Christmas Concert Dress-Up</strong> game now and make this holiday season unforgettable!</p>

       `,
    tag: ["Dress up","Blackpink","Girl games","Christmas","Holiday","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
    platform:"both",
  },
  
  {
    date: "2024-12-03",
    brand: "Others",
    orientation: 0,
    title: "Fall Guys - Play Fall Guys Online",
    name: ["Fall Guys", "fall-guys"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/fall-guys/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fall-guys/520x295.png",
    path: ["/game/fall-guys", "https://playcutegames.com"],
    iframe: "https://html5.gamedistribution.com/7b496d0fff414b2aa343b0c05556c02c/?gd_sdk_referrer_url=https://playcutegames.com/game/fall-guys",
    Walkthrough: "https://www.youtube.com/embed/Gm3Ypy_4k88",
    s_dis:"Fall Guys is a chaotic multiplayer party game where quirky characters compete in wild obstacle courses and hilarious challenges to win the crown!",
    l_dis:`
            <p>Fall Guys is an exhilarating multiplayer party game that combines wacky obstacle courses, hilarious physics-based gameplay, and intense competition. Players compete in a series of mini-games, striving to be the last one standing or the first to cross the finish line. Its vibrant graphics, entertaining challenges, and fast-paced gameplay make Fall Guys a standout choice for players of all ages.</p>
    <h2 class="H22">What is Fall Guys?</h2>
    <p>Fall Guys is a massively multiplayer online game where up to 60 players battle it out in a variety of quirky rounds. The game is known for its colorful arenas, unpredictable gameplay, and amusingly clumsy characters. From racing through obstacle courses to surviving elimination challenges, Fall Guys offers a mix of fun and competition that keeps players coming back for more.</p>
    <h2 class="H22">How to Play Fall Guys</h2>
    <p>In Fall Guys, the goal is simple: survive the chaos and win the crown. Here’s how you can get started:</p>
    <ul>
        <li><strong>Join a Match:</strong> Enter a lobby and join up to 59 other players in a series of challenges.</li>
        <li><strong>Compete in Mini-Games:</strong> Participate in races, team games, and survival challenges to advance to the next round.</li>
        <li><strong>Avoid Elimination:</strong> Stay in the game by not falling off platforms or failing objectives.</li>
        <li><strong>Win the Crown:</strong> Outlast or outrun all other players in the final round to claim victory.</li>
    </ul>
    <h2 class="H22">Why Play Fall Guys Unblocked?</h2>
    <p>Fall Guys Unblocked allows you to enjoy the game anytime, anywhere, without restrictions. Here are the key benefits:</p>
    <ul>
        <li><strong>Access Anytime:</strong> Play Fall Guys at school, work, or on the go, bypassing restrictions.</li>
        <li><strong>No Installations:</strong> Enjoy the game directly in your browser without downloading any software.</li>
        <li><strong>Full Experience:</strong> Dive into the complete Fall Guys gameplay with all its exciting rounds and features.</li>
    </ul>
    <h2 class="H22">Features of Fall Guys</h2>
    <p>Fall Guys is packed with unique features that make it an incredibly fun and engaging experience:</p>
    <ul>
        <li><strong>Chaotic Multiplayer Action:</strong> Compete against up to 59 other players in real-time.</li>
        <li><strong>Variety of Mini-Games:</strong> Enjoy dozens of challenges, including obstacle courses, team battles, and survival rounds.</li>
        <li><strong>Customizable Characters:</strong> Unlock skins, costumes, and emotes to personalize your Fall Guy.</li>
        <li><strong>Hilarious Physics:</strong> Laugh at the clumsy and unpredictable movements of your character.</li>
        <li><strong>Constant Updates:</strong> Regular updates bring new levels, themes, and features to the game.</li>
    </ul>
    <h2 class="H22">Tips and Tricks for Winning Fall Guys</h2>
    <p>Here are some tips to increase your chances of winning the coveted crown:</p>
    <ul>
        <li><strong>Stay Calm Under Pressure:</strong> Focus on your character’s movements and avoid panicking during crowded challenges.</li>
        <li><strong>Observe Other Players:</strong> Watch opponents to anticipate obstacles and avoid mistakes.</li>
        <li><strong>Team Game Strategies:</strong> Communicate and cooperate with teammates to achieve success in team-based rounds.</li>
        <li><strong>Master Timing:</strong> Perfect your timing on jumps, grabs, and dodges to excel in each mini-game.</li>
        <li><strong>Practice Makes Perfect:</strong> Familiarize yourself with different rounds to improve your performance over time.</li>
    </ul>
    <h2 class="H22">Why Gamers Love Fall Guys</h2>
    <p>Fall Guys has captured the hearts of gamers worldwide with its lighthearted gameplay, vibrant visuals, and endless replayability. Whether you’re competing against friends or strangers, every match is filled with unpredictable moments and hilarious mishaps. The game’s blend of skill and luck ensures that every player has a chance to win, making it accessible and enjoyable for everyone.</p>
    <h2 class="H22">Play Fall Guys Unblocked Now</h2>
    <p>Ready for some chaotic fun? Play Fall Guys Unblocked today and dive into the colorful world of obstacle courses, team challenges, and thrilling finales. Experience the joy of competing for the crown and laughing at every fall along the way. Don’t wait—start your Fall Guys adventure now!</p>
`,
tag: ["Multiplayer","2Player","3D","Platformer","Kids"],
    rating:[4.7,3854,597],  
  },
  {
    date: "2024-12-02",
    brand: "Others",
    orientation: 0,
    title: "Time Shooter 3: SWAT - Play Shooting Games Online",
    name: ["Time Shooter 3: SWAT", "time-shooter-3-swat"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/time-shooter-3-swat/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/time-shooter-3-swat/520x295.png",
    path: ["/game/time-shooter-3-swat", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/186705?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/TBGBOVQt-IM",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/time-shooter-3-swat.mp4",
    s_dis:"Time Shooter 3: SWAT is an action-packed shooter where you control time! Take on intense SWAT missions and defeat enemies with strategic slow-motion gameplay.",
    l_dis:`
            <p>Time Shooter 3: SWAT is an action-packed shooter that offers a blend of tactical gameplay, intense combat, and strategic decision-making. As a SWAT team member, you’ll navigate through high-stakes missions, fight enemy forces, and make quick decisions to survive. Whether you're new to the series or a veteran player, Time Shooter 3 delivers thrilling gameplay that keeps you on the edge of your seat.</p>
    <h2 class="H22">What is Time Shooter 3?</h2>
    <p>Time Shooter 3: SWAT is a third-person shooter that puts you in the role of a member of an elite SWAT team. Your mission is to eliminate enemy threats, protect hostages, and complete various tactical objectives. The game features slow-motion mechanics that give you a unique advantage to plan your moves, dodge bullets, and take down enemies with precision. Its immersive gameplay and intense action have made it a favorite among fans of tactical shooters.</p>
    <h2 class="H22">How to Play Time Shooter 3</h2>
    <p>Time Shooter 3 offers simple but challenging gameplay. Here's how to play:</p>
    <ul>
        <li><strong>Control Your Character:</strong> Use your mouse or keyboard to move and aim your character, preparing for strategic combat.</li>
        <li><strong>Engage Enemies:</strong> Face off against enemy forces using your weapons, taking advantage of slow-motion effects to outmaneuver them.</li>
        <li><strong>Complete Objectives:</strong> Complete various mission objectives, such as eliminating targets or rescuing hostages.</li>
        <li><strong>Unlock Weapons:</strong> Earn rewards to unlock new weapons and gear that help you survive tougher missions.</li>
    </ul>
    <h2 class="H22">Why Play Time Shooter 3 Unblocked?</h2>
    <p>Time Shooter 3 Unblocked allows you to enjoy this intense shooter from anywhere, no matter the restrictions. Here are some reasons to play the unblocked version:</p>
    <ul>
        <li><strong>Play Anywhere:</strong> Access the game anytime, whether you're at school, work, or home, with no restrictions.</li>
        <li><strong>No Downloads Required:</strong> Play instantly in your browser without the need to download or install any software.</li>
        <li><strong>Full Game Experience:</strong> Enjoy the complete version of Time Shooter 3 with all levels and features, without any limitations.</li>
    </ul>
    <h2 class="H22">Features of Time Shooter 3</h2>
    <p>Time Shooter 3 offers a variety of features that make it stand out from other shooters:</p>
    <ul>
        <li><strong>Slow-Motion Action:</strong> Use time manipulation mechanics to slow down time and carefully plan your moves in combat.</li>
        <li><strong>Realistic Combat:</strong> Engage in tactical shootouts with realistic weapon mechanics and enemy AI.</li>
        <li><strong>Multiple Missions:</strong> Take on different missions, each with unique objectives and enemy setups.</li>
        <li><strong>Weapon Customization:</strong> Unlock and upgrade a variety of weapons, including guns, grenades, and special equipment.</li>
        <li><strong>Strategic Gameplay:</strong> Time Shooter 3 encourages careful planning and strategic thinking to outsmart enemies and complete missions successfully.</li>
    </ul>
    <h2 class="H22">Tips and Tricks for Time Shooter 3</h2>
    <p>To succeed in Time Shooter 3, you’ll need to master your tactics. Here are some useful tips:</p>
    <ul>
        <li><strong>Utilize Slow Motion:</strong> Take advantage of the slow-motion mechanic to avoid enemy fire and land precise shots.</li>
        <li><strong>Take Cover:</strong> Use the environment to your advantage by hiding behind objects for protection during combat.</li>
        <li><strong>Upgrade Your Gear:</strong> Unlock and upgrade your weapons to increase your firepower and effectiveness in tougher missions.</li>
        <li><strong>Focus on Objectives:</strong> Stay focused on completing mission objectives to progress through the levels and earn rewards.</li>
    </ul>
    <h2 class="H22">Why Gamers Love Time Shooter 3</h2>
    <p>Time Shooter 3 has gained popularity due to its combination of tactical gameplay, strategic combat, and slow-motion mechanics. Players enjoy the challenge of completing complex missions while using time manipulation to gain an edge over enemies. The game’s intense action and rewarding progression system keep players engaged, making Time Shooter 3 a standout title for fans of shooting games.</p>
    <h2 class="H22">Play Time Shooter 3 Unblocked Now</h2>
    <p>Ready for an intense shooter experience? Play Time Shooter 3 Unblocked online today! Experience the thrill of slow-motion combat, take on challenging missions, and prove your tactical skills. Whether you're playing for fun or striving to master the game, Time Shooter 3 offers hours of action-packed entertainment.</p>
`,
tag: ["Shooting","Survival","Addictive","Shooting","Fight"],
    rating:[4.6,4752,671],  
  },
  {
    date: "2024-12-02",
    brand: "Yandex",
    orientation: 0,
    title: "Funny Shooter 2 - Play Shooting Games Online",
    name: ["Funny Shooter 2", "funny-shooter-2"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/funny-shooter-2/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/funny-shooter-2/520x295.png",
    path: ["/game/funny-shooter-2", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/193465?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/Z7SWoVrPUMk",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/funny-shooter-2.mp4",
    s_dis:"Funny Shooter 2 offers hilarious, action-packed shooting fun! Face quirky enemies, unlock upgrades, and enjoy endless chaos in this fun-filled game!",
    l_dis:`
            <p>Funny Shooter 2 brings a unique twist to traditional shooting games, combining action-packed gameplay with humor and quirky characters. This game offers fun mechanics, ridiculous weapons, and exciting challenges, making it a must-play for anyone looking for a laugh and some action-packed fun. Whether you're new to the series or a returning player, Funny Shooter 2 will keep you entertained for hours.</p>
    <h2 class="H22">What is Funny Shooter 2?</h2>
    <p>Funny Shooter 2 is a fun and engaging shooting game where players take control of quirky characters and battle against waves of enemies. With its over-the-top action, hilarious weapons, and comical graphics, the game provides an exciting break from more serious shooters. The goal is to shoot your way through various levels filled with unique challenges and absurd enemies, all while enjoying the laughter-inducing antics that make the game so fun.</p>
    <h2 class="H22">How to Play Funny Shooter 2</h2>
    <p>Playing Funny Shooter 2 is simple, but mastering it takes skill and quick reflexes. Here's how to play:</p>
    <ul>
        <li><strong>Control Your Character:</strong> Use your keyboard or mouse to move your character and aim your weapons.</li>
        <li><strong>Shoot Enemies:</strong> Eliminate the waves of quirky enemies that come your way with a variety of hilarious weapons.</li>
        <li><strong>Unlock New Weapons:</strong> Earn coins and points to unlock even crazier and more powerful weapons.</li>
        <li><strong>Complete Levels:</strong> Progress through increasingly difficult levels filled with more enemies and challenges.</li>
    </ul>
    <h2 class="H22">Why Play Funny Shooter 2 Unblocked?</h2>
    <p>Funny Shooter 2 Unblocked allows you to access the game from anywhere, without restrictions. Here are some reasons to play the unblocked version:</p>
    <ul>
        <li><strong>Access Anywhere:</strong> Play during breaks at school, at work, or at home, no matter where you are.</li>
        <li><strong>No Download Needed:</strong> Enjoy the game instantly in your browser without any downloads or installations required.</li>
        <li><strong>Complete Game Features:</strong> Play the full version of Funny Shooter 2 with all levels and weapons unlocked, without limitations.</li>
    </ul>
    <h2 class="H22">Features of Funny Shooter 2</h2>
    <p>Funny Shooter 2 is filled with fun features that keep players coming back for more:</p>
    <ul>
        <li><strong>Unique Weapons:</strong> Enjoy using a wide range of ridiculous and funny weapons, from oversized guns to bizarre projectiles.</li>
        <li><strong>Quirky Characters:</strong> Choose from a variety of comical characters, each with their own personality and abilities.</li>
        <li><strong>Challenging Levels:</strong> Face off against waves of increasingly difficult enemies across various levels filled with surprises.</li>
        <li><strong>Cartoonish Graphics:</strong> The colorful, cartoon-like visuals add to the game's lighthearted and fun vibe.</li>
    </ul>
    <h2 class="H22">Tips and Tricks for Playing Funny Shooter 2</h2>
    <p>Want to get better at Funny Shooter 2? Check out these tips to improve your gameplay:</p>
    <ul>
        <li><strong>Stay on the Move:</strong> Keep moving to avoid getting hit by enemy attacks, and use the environment for cover.</li>
        <li><strong>Upgrade Your Weapons:</strong> Use the coins you collect to unlock and upgrade your weapons, making it easier to take down enemies.</li>
        <li><strong>Master Each Level:</strong> Learn the enemy patterns in each level to anticipate their moves and plan your attacks.</li>
        <li><strong>Use Power-ups:</strong> Keep an eye out for power-ups that can boost your abilities or heal your character during tough fights.</li>
    </ul>
    <h2 class="H22">Why Gamers Love Funny Shooter 2</h2>
    <p>Funny Shooter 2 has gained a dedicated fanbase due to its unique blend of action, humor, and engaging gameplay. Players appreciate the fun visuals, diverse weapons, and challenging levels, all wrapped in a lighthearted package. It's the perfect game for anyone looking to unwind and enjoy some silly fun while still facing a good challenge.</p>
    <h2 class="H22">Play Funny Shooter 2 Unblocked Now</h2>
    <p>Ready for some hilarious shooting action? Play Funny Shooter 2 Unblocked online today! Dive into the chaotic world of comical characters and wild weapons, and challenge yourself to beat all the levels. Whether you're playing for fun or striving to be the best, Funny Shooter 2 guarantees a good time.</p>
`,
tag: ["Shooting","Survival","Addictive","Shooting","Fight"],
    rating:[4.7,3171,978],  
  },
  {
    date: "2024-12-02",
    brand: "Yandex",
    orientation: 0,
    title: "Pixel Shooter - Play Shooting Games Online",
    name: ["Pixel Shooter", "pixel-shooter"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/pixel-shooter/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/pixel-shooter/520x295.png",
    path: ["/game/pixel-shooter", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/215632?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/LL2pkvYEtRY",
    s_dis:"Pixel Shooter combines pixel-perfect action with exciting battles. Engage in dynamic shooting gameplay, conquer levels, and outsmart enemies!",
    l_dis:`
            <p>Pixel Shooter is a dynamic and fast-paced shooting game that combines retro aesthetics with modern gameplay mechanics. Packed with thrilling levels, challenging enemies, and customizable weaponry, this game offers endless hours of fun. Whether you're a fan of pixelated graphics or enjoy competitive shooting games, Pixel Shooter delivers on all fronts.</p>
    <h2 class="H22">What is Pixel Shooter?</h2>
    <p>Pixel Shooter is a classic-style shooter game designed for both casual and hardcore gamers. The game features vibrant pixelated visuals reminiscent of retro arcade games, paired with smooth and modern controls. Players take on waves of enemies in diverse environments, using an arsenal of weapons to clear each level.</p>
    <h2 class="H22">How to Play Pixel Shooter</h2>
    <p>Pixel Shooter offers simple yet addictive gameplay. Follow these steps to master the game:</p>
    <ul>
        <li><strong>Control Your Character:</strong> Use your keyboard or touch controls to move and aim.</li>
        <li><strong>Choose Your Weapon:</strong> Pick from a variety of weapons, each with unique capabilities.</li>
        <li><strong>Eliminate Enemies:</strong> Shoot down waves of enemies before they overwhelm you.</li>
        <li><strong>Upgrade and Progress:</strong> Earn points to unlock upgrades and enhance your arsenal.</li>
    </ul>
    <h2 class="H22">Why Play Pixel Shooter Unblocked?</h2>
    <p>Pixel Shooter Unblocked provides unrestricted access to the game, allowing you to enjoy it anywhere, anytime. Here’s why playing the unblocked version is a must:</p>
    <ul>
        <li><strong>Accessible Anywhere:</strong> Play from school, work, or home without restrictions.</li>
        <li><strong>No Downloads Required:</strong> Enjoy seamless gameplay directly from your browser.</li>
        <li><strong>Full Game Features:</strong> Access all levels, weapons, and features without limitations.</li>
    </ul>
    <h2 class="H22">Features of Pixel Shooter</h2>
    <p>Pixel Shooter stands out with its combination of retro charm and engaging mechanics. Key features include:</p>
    <ul>
        <li><strong>Retro Graphics:</strong> Nostalgic pixel art that appeals to fans of classic arcade games.</li>
        <li><strong>Diverse Levels:</strong> Play through a variety of levels, each with unique challenges and environments.</li>
        <li><strong>Customizable Weapons:</strong> Upgrade and choose weapons that suit your playstyle.</li>
        <li><strong>Dynamic Enemies:</strong> Face off against a wide range of enemy types, each with unique abilities.</li>
    </ul>
    <h2 class="H22">Tips and Tricks for Mastering Pixel Shooter</h2>
    <p>Want to excel in Pixel Shooter? Keep these tips in mind:</p>
    <ul>
        <li><strong>Stay Mobile:</strong> Constant movement helps you avoid enemy fire and stay alive longer.</li>
        <li><strong>Use Cover:</strong> Take advantage of obstacles in the environment to shield yourself.</li>
        <li><strong>Upgrade Wisely:</strong> Focus on upgrading weapons and abilities that complement your strategy.</li>
        <li><strong>Learn Enemy Patterns:</strong> Observe enemy behavior to anticipate their moves and counter effectively.</li>
    </ul>
    <h2 class="H22">Why Gamers Love Pixel Shooter</h2>
    <p>Pixel Shooter has gained popularity for its addictive gameplay and nostalgic appeal. The combination of retro visuals and challenging action keeps players coming back for more. Whether you’re a casual player or a seasoned gamer, Pixel Shooter offers something for everyone.</p>
    <h2 class="H22">Play Pixel Shooter Unblocked Now</h2>
    <p>Ready to dive into the action? Play Pixel Shooter Unblocked online for free and enjoy uninterrupted gameplay. Equip your favorite weapon, face challenging enemies, and prove your skills in this exciting retro shooter!</p>
`,
tag: ["Shooting","Roblox","Skill","Survival","Addictive","Shooting","Fight"],
    rating:[4.5,1991,327],  
  },
  {
    date: "2023-10-10",
    brand: "playcutegames",
    
    orientation: 1,
    title: "Spooky Halloween Makeover: Dress Up Game for Spooky Fun!",
    name: ["Spooky Halloween Makeover", "spooky-halloween-makeover"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/spooky-halloween-makeover/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/spooky-halloween-makeover/520x295.png",
    path: ["/game/spooky-halloween-makeover", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/spooky-halloween-makeover/",
    Walkthrough: "https://www.youtube.com/embed/JPOvh0HrbQs",
    s_dis:
    "Join Spooky Halloween Makeover and dress up Peppa, Daddy Pig, and Mummy Pig in creative costumes! Enjoy spooky fun in this exciting dress-up game for kids.",
    l_dis:
      `
       <p>Get ready to unleash your inner stylist and decorator with Spooky Halloween Makeover, the ultimate online game that brings together creativity, style, and eerie excitement! Step into a spooky world where you can transform a beauty into a Halloween sensation and craft the perfect haunted house vibes. Packed with 15 unique makeover styles and limitless room customization options, this game is your ticket to celebrating Halloween in the most stylish way possible. </p>
    
            <h3>1. Halloween Makeover Magic</h3>
            <p>Start your journey with a spine-chilling beauty makeover! Choose from various spooky styles like glamorous vampires, enchanting witches, or eerie ghosts. Use face paint, accessories, and costumes to create hauntingly beautiful looks.</p>
        
        
            <h3>2. Earn Rewards with Every Step</h3>
            <p>Complete each makeover segment to unlock exciting rewards. Use them to access premium items for decorating spooky Halloween-themed rooms.</p>
        
        
            <h3>3. Customize Four Unique Halloween Rooms</h3>
            <p>Show off your interior design skills by decorating four distinctive Halloween-themed rooms:</p>
            <ul>
                <li><strong>Haunted Mansion Room:</strong> Add cobwebs, candles, and vintage furniture for a ghostly vibe.</li>
                <li><strong>Pumpkin Patch Room:</strong> Brighten the space with jack-o’-lanterns, hay bales, and festive orange hues.</li>
                <li><strong>Witch’s Den Room:</strong> Incorporate spell books, cauldrons, and magical elements to create a mystical atmosphere.</li>
                <li><strong>Creepy Forest Room:</strong> Design a mysterious woodland scene with twisted trees, glowing mushrooms, and eerie lighting.</li>
            </ul>
        
        
            <h3>4. Unlock a Wide Variety of Items</h3>
            <p>Earn and unlock decorative items like skeletons, bats, and spooky lighting to create the ultimate Halloween haven.</p>
        
   
    <section>
        <h2>How to Play</h2>
        <ol>
            <li><strong>Start the Makeover:</strong> Select one of 15 unique Halloween-themed styles and apply makeup, hairstyles, and costumes.</li>
            <li><strong>Earn Rewards:</strong> Complete makeover steps to earn in-game rewards.</li>
            <li><strong>Unlock Room Items:</strong> Use rewards to unlock furniture and decor for your Halloween rooms.</li>
            <li><strong>Design Your Rooms:</strong> Decorate each room to match your Halloween vision.</li>
            <li><strong>Share Your Creations:</strong> Show off your makeovers and designs with friends to spread the Halloween spirit.</li>
        </ol>
    </section>
    <section>
        <h2>Why You’ll Love Spooky Halloween Makeover</h2>
        <ul>
            <li><strong>Free to Play:</strong> Enjoy the game with no downloads or registrations required.</li>
            <li><strong>Unblocked Gaming:</strong> Play anytime, anywhere.</li>
            <li><strong>Perfect for All Ages:</strong> A delightful experience for kids and adults alike.</li>
        </ul>
        <p>Celebrate Halloween like never before with <strong>Spooky Halloween Makeover</strong>. Play now and let your creativity run wild!</p>
   </section>
      `,
      tag: ["Halloween","Dress up","Makeover","Exclusive","Girl games"],
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-11-28",
    brand: "Others",
    orientation: 0,
    title: "A Small World Cup - Play Soccer Online",
    name: ["A Small World Cup", "a-small-world-cup"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/a-small-world-cup/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/a-small-world-cup/520x295.png",
    path: ["/game/a-small-world-cup", "https://playcutegames.com"],
    iframe: "https://asmallworldcup.io/iframe/index.html",
    Walkthrough: "https://www.youtube.com/embed/OVTJIYaemNc",
    s_dis:"A Small World Cup: Play a fun and competitive soccer game with unique teams and fast-paced action in this mini world cup tournament.",
    l_dis:`
            <p>A Small World Cup is a fun and engaging soccer game that offers a unique twist on traditional football games. This online game lets players compete in fast-paced matches with tiny, customizable teams, aiming for victory in the miniaturized World Cup. Whether you are a fan of soccer or just looking for a fun time, A Small World Cup has something to offer.</p>
    <h2 class="H22">What is A Small World Cup?</h2>
    <p>A Small World Cup is an action-packed soccer game where players control tiny teams to compete in thrilling matches. The gameplay is simple yet addictive, and it’s perfect for both casual gamers and soccer enthusiasts. With a focus on quick action and strategic play, A Small World Cup brings the excitement of soccer into a fun, miniature world.</p>
    <h2 class="H22">How to Play A Small World Cup</h2>
    <p>Playing A Small World Cup is easy, and the controls are designed for both new and experienced players. Here’s how you can get started:</p>
    <ul>
        <li><strong>Choose Your Team:</strong> Select from a variety of miniature teams, each with unique abilities.</li>
        <li><strong>Control Your Players:</strong> Use arrow keys or your mouse to move your players around the field.</li>
        <li><strong>Score Goals:</strong> Aim to score as many goals as possible within the time limit to win the match.</li>
    </ul>
    <h2 class="H22">Why Play A Small World Cup Unblocked?</h2>
    <p>Playing A Small World Cup Unblocked means you can enjoy the game anytime and anywhere, even if you’re at school or work. Here are a few reasons why it’s the preferred choice:</p>
    <ul>
        <li><strong>Unrestricted Access:</strong> Play A Small World Cup without any restrictions, whether at school, home, or work.</li>
        <li><strong>Simple Gameplay:</strong> No complicated downloads or installations are required. Just open your browser and play.</li>
        <li><strong>Full Experience:</strong> Play through all levels and enjoy every feature the game has to offer without interruptions.</li>
    </ul>
    <h2 class="H22">Game Features in A Small World Cup</h2>
    <p>A Small World Cup offers several exciting features that make it stand out:</p>
    <ul>
        <li><strong>Miniature Teams:</strong> Play with small, customizable teams that add a fun twist to traditional soccer games.</li>
        <li><strong>Fast-Paced Action:</strong> The game offers quick matches, ensuring non-stop action and excitement.</li>
        <li><strong>Unique Stadiums:</strong> Compete in different quirky stadiums, each offering a new challenge.</li>
    </ul>
    <h2 class="H22">Tips for Winning in A Small World Cup</h2>
    <p>Here are a few tips to help you dominate in A Small World Cup:</p>
    <ul>
        <li><strong>Master Controls:</strong> Practice your moves to perfect the controls and become more precise with your shots.</li>
        <li><strong>Use Power-Ups:</strong> Take advantage of any power-ups or special abilities that can give your team an edge.</li>
        <li><strong>Strategize Your Defense:</strong> Focus not only on attacking but also on defending your goal to prevent the opposition from scoring.</li>
    </ul>
    <h2 class="H22">Why Fans Love A Small World Cup</h2>
    <p>Fans love A Small World Cup for its unique take on soccer. The miniature teams, quick gameplay, and humorous design make it an enjoyable experience for players of all ages. Whether you’re playing solo or competing against friends, the game offers endless fun and challenges.</p>
    <h2 class="H22">Play A Small World Cup Unblocked Now</h2>
    <p>Ready to kick off the action? Play A Small World Cup Unblocked online today and experience the joy of mini soccer in a world full of surprises. Don’t wait—step into the quirky world of A Small World Cup and see if you can lead your team to victory!</p>
`,
tag: ["Soccer","Retro","Multiplayer","Sports"],
    rating:[4.4,7827,2945],  
  },
  {
    date: "2024-11-25",
    brand: "Others",
    orientation: 0,
    title: "Raft Wars 2 - Play Raft Wars 2 Online",
    name: ["Raft Wars 2", "raft-wars-2"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/raft-wars-2/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/raft-wars-2/520x295.png",
    path: ["/game/raft-wars-2", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/raft-wars-2/",
    Walkthrough: "https://www.youtube.com/embed/lMw46eKlpmg",
    s_dis:"Raft Wars 2 continues the fun with more action-packed levels! Aim, shoot, and defend your treasure while navigating thrilling water battles.",
    l_dis:`
            <p>Raft Wars 2 is the highly anticipated sequel to the original Raft Wars, offering even more excitement, challenges, and humor. This time, Simon and his brother must battle to reclaim their treasure after it’s been buried under a newly constructed waterpark. With enhanced gameplay and fresh obstacles, Raft Wars 2 is a must-play for fans of the series.</p>
    <h2 class="H22">What is Raft Wars 2?</h2>
    <p>Raft Wars 2 builds on the success of the first game by introducing new environments, enemies, and upgrades. Players navigate through waterpark-themed levels, using strategy and precision to overcome waves of opponents and reclaim Simon’s buried treasure. It’s a game that perfectly combines humor and action.</p>
    <h2 class="H22">How to Play Raft Wars 2</h2>
    <p>Getting started with Raft Wars 2 is simple and intuitive. Here’s how to play:</p>
    <ul>
        <li><strong>Aim and Shoot:</strong> Use your mouse to aim and adjust the power of your shots to hit enemies.</li>
        <li><strong>Clear Levels:</strong> Progress through various levels by defeating all enemies while protecting your raft.</li>
        <li><strong>Upgrade Equipment:</strong> Earn coins to purchase better weapons and stronger rafts for tougher battles.</li>
    </ul>
    <h2 class="H22">Why Play Raft Wars 2 Unblocked?</h2>
    <p>Raft Wars 2 Unblocked allows you to enjoy the game without restrictions, whether at school or work. Here’s why it’s the preferred choice for many:</p>
    <ul>
        <li><strong>Unlimited Access:</strong> Play anywhere without limitations.</li>
        <li><strong>Browser-Based:</strong> No downloads needed, making it easy to jump into the action.</li>
        <li><strong>Full Gameplay:</strong> Experience all levels and features without restrictions.</li>
    </ul>
    <h2 class="H22">Tips for Mastering Raft Wars 2</h2>
    <p>To excel in Raft Wars 2, keep these tips in mind:</p>
    <ul>
        <li><strong>Practice Your Aim:</strong> Adjust for angles and power to land accurate shots on enemies.</li>
        <li><strong>Upgrade Strategically:</strong> Use coins wisely to enhance your arsenal and increase your chances of success.</li>
        <li><strong>Use the Environment:</strong> Take advantage of waterpark features to gain an edge in battles.</li>
    </ul>
    <h2 class="H22">What’s New in Raft Wars 2?</h2>
    <p>Raft Wars 2 introduces several exciting features that set it apart from the original game:</p>
    <ul>
        <li><strong>Waterpark Levels:</strong> Battle through dynamic environments filled with slides and pools.</li>
        <li><strong>New Enemies:</strong> Face off against lifeguards, park guests, and other unique foes.</li>
        <li><strong>Enhanced Weapons:</strong> Unlock and use upgraded equipment for more powerful attacks.</li>
    </ul>
    <h2 class="H22">Why Fans Love Raft Wars 2</h2>
    <p>Raft Wars 2 has earned a loyal following thanks to its engaging gameplay, humorous story, and creative level design. Players love the challenge of strategizing each shot while navigating through increasingly difficult levels. Whether you’re a veteran of the series or new to the game, Raft Wars 2 offers endless fun.</p>
    <h2 class="H22">Play Raft Wars 2 Unblocked Now</h2>
    <p>Join Simon and his brother in their epic quest to reclaim their treasure in Raft Wars 2. Play Raft Wars 2 Unblocked online and enjoy unrestricted access to one of the most entertaining games available. Start your waterpark adventure today!</p>
`,
tag: ["Shooting","Strategy","Skill"],
    rating:[4.7,3587,987],  
  },
  {
    date: "2024-11-25",
    brand: "Others",
    orientation: 0,
    title: "Raft Wars - Play Raft Wars Online",
    name: ["Raft Wars", "raft-wars"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/raft-wars/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/raft-wars/520x295.png",
    path: ["/game/raft-wars", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/raft-wars/",
    Walkthrough: "https://www.youtube.com/embed/FPZMXwGSwpQ",
    s_dis:"Raft Wars is an exciting water-based strategy game where you aim, shoot, and defeat enemies to protect your treasure from being stolen!",
    l_dis:`
             <p>Raft Wars is a classic online game that combines strategy, adventure, and humor. Players join Simon, a young boy, and his brother in a thrilling battle to protect their hidden treasure from pirates, vikings, and other foes. With simple mechanics and addictive gameplay, Raft Wars has become a favorite among gamers of all ages.</p>
    <h2 class="H22">What is Raft Wars?</h2>
    <p>Raft Wars is a turn-based shooting game where players must aim and shoot projectiles to defeat enemies. The game is set on water, where Simon and his brother defend their raft from waves of attackers trying to steal their treasure. Its humorous storyline and engaging gameplay make it a timeless classic.</p>
    <h2 class="H22">How to Play Raft Wars</h2>
    <p>Playing Raft Wars is simple and fun. Here’s a quick guide:</p>
    <ul>
        <li><strong>Aim and Shoot:</strong> Use the mouse to aim and adjust the power of your shots to hit enemies.</li>
        <li><strong>Earn Coins:</strong> Defeat opponents to earn coins and upgrade your equipment for better performance.</li>
        <li><strong>Defend Your Treasure:</strong> Protect the treasure chest from being captured by your enemies.</li>
    </ul>
    <h2 class="H22">Why Play Raft Wars Unblocked?</h2>
    <p>Raft Wars Unblocked allows you to enjoy the game anytime, anywhere, without restrictions. Here’s why it’s a popular choice:</p>
    <ul>
        <li><strong>Access Anywhere:</strong> Play in schools or workplaces where gaming sites are typically blocked.</li>
        <li><strong>Browser-Based:</strong> No downloads or installations required, making it quick and convenient.</li>
        <li><strong>Full Gameplay:</strong> Experience the complete adventure without limitations.</li>
    </ul>
    <h2 class="H22">Tips and Strategies for Raft Wars</h2>
    <p>To excel in Raft Wars, keep these tips in mind:</p>
    <ul>
        <li><strong>Master Your Aim:</strong> Practice to perfect your aim and adjust to the game’s projectile physics.</li>
        <li><strong>Upgrade Wisely:</strong> Invest in better weapons and rafts to improve your chances of winning tougher battles.</li>
        <li><strong>Plan Your Shots:</strong> Target weaker enemies first to reduce incoming damage.</li>
    </ul>
    <h2 class="H22">What Makes Raft Wars Unique?</h2>
    <p>Raft Wars stands out for its combination of fun mechanics and a quirky storyline. Key features include:</p>
    <ul>
        <li><strong>Humorous Characters:</strong> Simon and his brother add charm and personality to the game.</li>
        <li><strong>Challenging Levels:</strong> Each level presents new obstacles and enemies, keeping the gameplay fresh.</li>
        <li><strong>Upgrades and Power-ups:</strong> Earn coins to unlock better weapons and stronger rafts.</li>
    </ul>
    <h2 class="H22">Why Players Love Raft Wars</h2>
    <p>Raft Wars has earned a loyal fanbase for its engaging gameplay and lighthearted humor. It offers a perfect mix of strategy and entertainment, making it ideal for casual gaming sessions. Whether you’re reliving childhood memories or playing for the first time, Raft Wars guarantees hours of fun.</p>
    <h2 class="H22">Play Raft Wars Unblocked Today</h2>
    <p>Dive into the exciting world of Raft Wars and protect the treasure from greedy foes. Play Raft Wars Unblocked now and enjoy an adventure filled with action, strategy, and endless laughs. Start your treasure defense journey today!</p>
`,
tag: ["Shooting","Strategy","Skill"],
    rating:[4.6,2158,697],  
  },
  {
    date: "2024-11-21",
    brand: "Others",
    orientation: 0,
    title: "Drift Boss - Play Drift Boss Online",
    name: ["Drift Boss", "drift-boss"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/drift-boss/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/drift-boss/520x295.png",
    path: ["/game/drift-boss", "https://playcutegames.com"],
    iframe: "https://www.hoodamath.com/mobile/games/drift-boss/game.html",
    Walkthrough: "https://www.youtube.com/embed/ss-g66r58yQ",
    s_dis:"Drift Boss: Show off your drifting skills in this exciting car game! Drift around curves, avoid obstacles, and score high points.",
    l_dis:`
              <p>Drift Boss is the ultimate online game for car enthusiasts who love the thrill of drifting. This game offers a unique combination of precision driving, skillful control, and fast-paced action. Whether you're a seasoned player or a beginner, Drift Boss promises endless fun and excitement. Play Drift Boss Unblocked and enjoy this thrilling experience from anywhere, at any time.</p>
    <h2 class="H22">What is Drift Boss?</h2>
    <p>Drift Boss is an online car racing game that challenges players to control a car and perform smooth drifts around sharp corners while navigating through different tracks. The objective of the game is simple: drive as far as you can while avoiding obstacles and maintaining control of your car. The game's physics system adds a layer of realism, making each drift feel dynamic and rewarding.</p>
    <h2 class="H22">How to Play Drift Boss</h2>
    <p>Playing Drift Boss is easy to learn but hard to master. Here’s a basic guide to help you get started:</p>
    <ul>
        <li><strong>Controls:</strong> Use the arrow keys or swipe on your mobile device to steer your car left or right. The car automatically accelerates, so all you need to do is steer and drift.</li>
        <li><strong>Avoid Obstacles:</strong> As you race through the track, you’ll encounter obstacles like barriers and sharp turns. Avoid hitting these obstacles to keep your streak going.</li>
        <li><strong>Master the Drift:</strong> Timing your drifts is essential to stay in control and earn more points. The longer and more controlled your drift, the higher your score.</li>
        <li><strong>Progression:</strong> As you progress, the tracks become more challenging, with tighter corners and faster speeds, making each level more exciting than the last.</li>
    </ul>
    <h2 class="H22">Why Play Drift Boss Unblocked?</h2>
    <p>Drift Boss Unblocked is the perfect option for those who want to play the game at school, work, or any other location where games might be restricted. With the unblocked version, you can access the game from any device and enjoy the endless drifting fun without any interruptions.</p>
    <ul>
        <li><strong>Access Anywhere:</strong> Play Drift Boss Unblocked on any device, whether it's your desktop, laptop, or smartphone.</li>
        <li><strong>No Restrictions:</strong> Enjoy uninterrupted gameplay without worrying about firewalls or content filters.</li>
        <li><strong>Play Anytime:</strong> Drift Boss Unblocked lets you jump into the action whenever you need a break or want to kill time.</li>
    </ul>
    <h2 class="H22">Tips for Mastering Drift Boss</h2>
    <p>To get the best experience and improve your skills in Drift Boss, here are some essential tips:</p>
    <ul>
        <li><strong>Practice Your Drifts:</strong> The key to success in Drift Boss is mastering your drifts. The more you practice, the better you'll get at handling sharp corners and maintaining speed.</li>
        <li><strong>Focus on Timing:</strong> The longer your drift, the more points you earn. However, timing is critical—drift too early or too late, and you'll lose control.</li>
        <li><strong>Watch Your Speed:</strong> Going too fast can make it harder to control your car, while going too slow can make the game feel boring. Find a balance to keep things exciting!</li>
        <li><strong>Stay Calm:</strong> Don’t let the fast pace get to you. Take your time to focus on your steering and enjoy the thrill of the drift.</li>
    </ul>
    <h2 class="H22">Why Players Love Drift Boss</h2>
    <p>Drift Boss has gained a massive following due to its exciting and addicting gameplay. Players love the challenge of mastering the drifting mechanics and navigating increasingly difficult tracks. The game’s simple controls make it easy for anyone to pick up, while its increasing difficulty ensures there's always a challenge, keeping players coming back for more.</p>
    <h2 class="H22">Play Drift Boss Now!</h2>
    <p>If you're ready for an exciting and challenging drifting experience, it’s time to try Drift Boss. Whether you're playing from home or accessing Drift Boss Unblocked, you're in for a thrilling ride. Test your drifting skills, unlock new levels, and see how far you can go!</p>
`,
tag: ["Car games","Driving","Racing"],
    rating:[4.4,1911,597],  
  },
  {
    date: "2024-11-21",
    brand: "Others",
    orientation: 0,
    title: "Penalty Kicks Online - Play Soccer Game Online",
    name: ["Penalty Kicks Online", "penalty-kicks-online"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/penalty-kicks/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/penalty-kicks/520x295.png",
    path: ["/game/penalty-kicks-online", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/179422?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/LSCGy8hcsXk",
    s_dis:"Penalty Kicks Online: Test your soccer skills by scoring penalty kicks! Aim for the goal and defeat your opponents in this exciting game.",
    l_dis:`
              <p>Get ready to test your football skills with <strong>Penalty Kicks Online</strong>, a fast-paced game where you take on the role of a penalty taker or goalkeeper. Whether you're aiming to score the perfect goal or stop every shot, the game offers exciting challenges. The <strong>Penalty Kicks Unblocked</strong> version allows you to enjoy the game anywhere, anytime, without restrictions!</p>
    <h2 class="H22">What is Penalty Kicks Online?</h2>
    <p><strong>Penalty Kicks Online</strong> is a thrilling online football game that focuses on the intense action of penalty kicks. You can play as either the striker or the goalkeeper, taking turns to score goals or defend shots. The goal is to outsmart your opponent with precise shots or clever saves, making it a competitive and fun experience for players of all ages.</p>
    <h2 class="H22">Features of Penalty Kicks Online</h2>
    <p>Here’s what makes <strong>Penalty Kicks Online</strong> an exciting game:</p>
    <ul>
        <li><strong>Realistic Gameplay:</strong> The game simulates penalty kicks with realistic physics, making each shot feel like a real football match.</li>
        <li><strong>Multiple Modes:</strong> Play as a striker or goalkeeper, and challenge your friends or AI opponents in various modes.</li>
        <li><strong>Simple Controls:</strong> The game is easy to play with intuitive controls, allowing players to focus on strategy and timing.</li>
        <li><strong>Unblocked Access:</strong> With the <strong>Penalty Kicks Unblocked</strong> version, you can play at school, work, or any place with internet access.</li>
    </ul>
    <h2 class="H22">How to Play Penalty Kicks Online</h2>
    <p>Playing <strong>Penalty Kicks Online</strong> is simple and fun. Here’s how to get started:</p>
    <ul>
        <li><strong>Select Your Role:</strong> Choose whether you want to take penalty shots as the striker or defend them as the goalkeeper.</li>
        <li><strong>Take the Shot:</strong> As a striker, aim for the goal and shoot with precision. As a goalkeeper, move to the right position to save the shots.</li>
        <li><strong>Win by Scoring More Goals:</strong> The player who scores the most goals or makes the most saves wins the match.</li>
    </ul>
    <h2 class="H22">Why Play Penalty Kicks Unblocked?</h2>
    <p>The <strong>Penalty Kicks Unblocked</strong> version gives you the freedom to enjoy this exciting game without limitations. Whether you’re at school, work, or any place with internet access, you can play Penalty Kicks Online anytime. The unblocked version ensures you can play on any device without worrying about firewalls or restrictions.</p>
    <h2 class="H22">Tips for Mastering Penalty Kicks Online</h2>
    <p>Here are some tips to help you become a Penalty Kicks Online pro:</p>
    <ul>
        <li><strong>Perfect Your Timing:</strong> The key to success as a striker is timing your shot correctly. Watch the goalkeeper’s movements and aim for the corners of the goal.</li>
        <li><strong>Be Quick as a Goalkeeper:</strong> As a goalkeeper, react quickly to the striker’s movements. Anticipate the shot and dive in the right direction to make a save.</li>
        <li><strong>Practice Your Strategy:</strong> Whether you're attacking or defending, practicing different strategies will improve your chances of winning.</li>
    </ul>
    <h2 class="H22">Why You Should Play Penalty Kicks Online</h2>
    <p><strong>Penalty Kicks Online</strong> is a fast and fun game that brings the excitement of penalty shootouts right to your screen. Whether you're playing alone or with friends, it offers an intense challenge and rewarding gameplay. With the <strong>Penalty Kicks Unblocked</strong> version, you can enjoy this thrilling game anytime, anywhere, making it perfect for quick gaming sessions or long hours of fun.</p>
    <h2 class="H22">Play Penalty Kicks Online Now</h2>
    <p>Ready to test your football skills? Play <strong>Penalty Kicks Online</strong> now and experience the excitement of penalty shootouts! Challenge yourself or your friends and see who can become the ultimate penalty kick champion.</p>
    <h2 class="H22">Conclusion</h2>
    <p><strong>Penalty Kicks Online</strong> is an exhilarating game that tests your football skills and provides hours of entertainment. Whether you want to score the perfect goal or stop every shot, it offers a variety of gameplay options. Don’t miss out on the <strong>Penalty Kicks Unblocked</strong> version, which lets you play anytime, anywhere. Join the fun and take your penalty kick abilities to the next level!</p>
`,
tag: ["Sports","Skill","Arcade","Crazy games"],
    
    rating:[4.4,1911,597],  
  },
  {
    date: "2024-11-21",
    brand: "Others",
    orientation: 0,
    title: "You vs 100 Skibidi Toilets - Play Shooting Games Online",
    name: ["You vs 100 Skibidi Toilets", "you-vs-100-skibidi-toilets"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/you-vs-100-skibidi-toilets/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/you-vs-100-skibidi-toilets/520x295.png",
    path: ["/game/you-vs-100-skibidi-toilets", "https://playcutegames.com"],
    iframe: "https://html5.gamedistribution.com/4d78b32a56d6422aa958e762e2fbec3e/?gd_sdk_referrer_url=https://playcutegames.com/game/you-vs-100-skibidi-toilets",
    Walkthrough: "https://www.youtube.com/embed/kzp1X1H_Omo",
    s_dis:"You vs 100 Skibidi Toilets: Face off against 100 Skibidi Toilets in this quirky challenge. Can you defeat them and save the day?",
    l_dis:`
             <p>Get ready for a wild ride with <strong>You vs 100 Skibidi Toilets</strong>, a fun and quirky game that challenges you to take on 100 of the most bizarre and hilarious Skibidi Toilets. If you love chaos, action, and humor, this is the game for you. The <strong>Skibidi Toilets unblocked</strong> version lets you enjoy this epic showdown anytime, anywhere, without restrictions!</p>
    <h2 class="H22">What is You vs 100 Skibidi Toilets?</h2>
    <p><strong>You vs 100 Skibidi Toilets</strong> is an action-packed, comical game where you are tasked with defeating 100 unique Skibidi Toilets that are out to get you. With hilarious animations, unpredictable behavior, and exciting gameplay, this game keeps you on your toes. Whether you’re fighting against one toilet or a hundred, the challenge is always entertaining!</p>
    <h2 class="H22">Features of You vs 100 Skibidi Toilets</h2>
    <p>The game stands out with its quirky features, providing a one-of-a-kind gaming experience:</p>
    <ul>
        <li><strong>Hilarious Gameplay:</strong> The Skibidi Toilets have outrageous movements and attacks, making each battle unpredictable and funny.</li>
        <li><strong>Challenging Levels:</strong> With 100 toilets to defeat, each level presents a new challenge and requires quick reflexes.</li>
        <li><strong>Simple Controls:</strong> Despite the craziness, the game is easy to play with simple controls that let you jump right into the action.</li>
        <li><strong>Unblockable Fun:</strong> With the <strong>Skibidi Toilets Unblocked</strong> version, you can play this game anywhere, even in places where gaming is restricted.</li>
    </ul>
    <h2 class="H22">How to Play You vs 100 Skibidi Toilets</h2>
    <p>Getting started with <strong>You vs 100 Skibidi Toilets</strong> is easy. Here’s how to play:</p>
    <ul>
        <li><strong>Select Your Character:</strong> Choose your character and prepare to face the Skibidi Toilets.</li>
        <li><strong>Fight the Toilets:</strong> Use your character’s abilities to defeat the Skibidi Toilets. Be ready for their unpredictable moves!</li>
        <li><strong>Defeat All 100:</strong> To win, you must defeat all 100 toilets. The challenge increases as you progress through levels.</li>
    </ul>
    <h2 class="H22">Why Play Skibidi Toilets Unblocked?</h2>
    <p>The <strong>Skibidi Toilets Unblocked</strong> version allows you to play the game at school, work, or anywhere gaming is restricted. With no need for downloads or installations, you can enjoy this fun-filled battle on any device with just a browser. The unblocked version ensures that the fun never ends, regardless of where you are.</p>
    <h2 class="H22">Tips for Winning You vs 100 Skibidi Toilets</h2>
    <p>Here are a few tips to help you defeat all 100 Skibidi Toilets:</p>
    <ul>
        <li><strong>Keep Moving:</strong> The Skibidi Toilets are fast and unpredictable, so always be on the move to avoid getting trapped.</li>
        <li><strong>Use Your Abilities Wisely:</strong> Use your character’s special abilities strategically to defeat large groups of toilets.</li>
        <li><strong>Stay Calm:</strong> With so many toilets attacking at once, staying calm and focused is key to winning.</li>
    </ul>
    <h2 class="H22">Why You Should Play You vs 100 Skibidi Toilets</h2>
    <p><strong>You vs 100 Skibidi Toilets</strong> is a unique and entertaining game that combines action with humor. The unexpected toilet attacks and crazy gameplay make it a fun experience for players of all ages. Whether you’re looking for a quick distraction or an exciting challenge, this game has it all. And with the <strong>Skibidi Toilets unblocked</strong> version, you can play anytime, anywhere!</p>
    <h2 class="H22">Play You vs 100 Skibidi Toilets Online</h2>
    <p>Play <strong>You vs 100 Skibidi Toilets Online</strong> and enjoy endless fun without any downloads required. The online version offers smooth gameplay and easy access from any device. Get ready to face the hilarious Skibidi Toilets and experience the chaos wherever you are!</p>
    <h2 class="H22">Conclusion</h2>
    <p><strong>You vs 100 Skibidi Toilets</strong> is an exciting and comical game that guarantees hours of entertainment. With its funny toilet battles and simple gameplay, it’s easy to get hooked. Don’t miss out on the <strong>Skibidi Toilets Unblocked</strong> version, which lets you enjoy the fun without limitations. Are you ready to face the ultimate toilet showdown? Play now and take on the Skibidi Toilets!</p>
`,
    tag: ["Shooting","Strategy","Skill"],
    
    rating:[4.8,7503,1978],  
  },
  {
    date: "2024-11-21",
    brand: "Others",
    orientation: 0,
    title: "Football Legends Ultimate - Play Soccer Game Online",
    name: ["Football Legends Ultimate", "football-legends-ultimate"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/football-legends-ultimate/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/football-legends-ultimate/520x295.png",
    path: ["/game/football-legends-ultimate", "https://playcutegames.com"],
    iframe: "https://football-legends.io/iframe/index.html",
    Walkthrough: "https://www.youtube.com/embed/EM5mvqjZr4A",
    s_dis:"Football Legends Ultimate: Play as legendary footballers in this action-packed game. Score goals, master skills, and become the ultimate champion!",
    l_dis:`
             <p><strong>Football Legends Ultimate</strong> takes the excitement of football games to the next level. Whether you’re a football enthusiast or just looking for some fast-paced action, this game delivers it all. Featuring iconic players, challenging matches, and exciting gameplay, Football Legends Ultimate is the ultimate football experience for both casual and hardcore gamers alike. With the <strong>Football Legends Ultimate unblocked</strong> version, you can enjoy this action-packed game anywhere, anytime.</p>
    <h2 class="H22">What is Football Legends Ultimate?</h2>
    <p><strong>Football Legends Ultimate</strong> is a dynamic online football game that allows players to control legendary football players from around the world. Choose your favorite teams, and compete in tournaments or one-on-one matches against AI or other players. The game’s fast-paced mechanics and entertaining animations make it a must-play for football fans and gamers alike.</p>
    <h2 class="H22">Features of Football Legends Ultimate</h2>
    <p>Football Legends Ultimate stands out with its thrilling gameplay and variety of exciting features:</p>
    <ul>
        <li><strong>Multiple Game Modes:</strong> Choose from various game modes such as tournaments, quick matches, and multiplayer mode.</li>
        <li><strong>Legendary Players:</strong> Play as famous football legends like Messi, Ronaldo, and more!</li>
        <li><strong>Realistic Controls:</strong> The game features smooth, easy-to-learn controls for a highly immersive experience.</li>
        <li><strong>Exciting Action:</strong> Enjoy fast-paced, action-packed football with impressive moves and goals.</li>
    </ul>
    <h2 class="H22">How to Play Football Legends Ultimate</h2>
    <p>Playing <strong>Football Legends Ultimate</strong> is easy and fun, whether you’re playing solo or with friends:</p>
    <ul>
        <li><strong>Select Your Team:</strong> Pick from a variety of legendary football players or teams to get started.</li>
        <li><strong>Choose Your Mode:</strong> Whether you want to play a quick match or enter a tournament, there’s a game mode for everyone.</li>
        <li><strong>Control the Action:</strong> Use intuitive controls to pass, shoot, and score goals with precision.</li>
        <li><strong>Score More Goals:</strong> Aim to score more goals than your opponent to win the match.</li>
    </ul>
    <h2 class="H22">Why Play Football Legends Ultimate Unblocked?</h2>
    <p><strong>Football Legends Ultimate Unblocked</strong> offers unrestricted access to the game, allowing players to enjoy it in environments where gaming is typically blocked. Whether you're at school, work, or using public Wi-Fi, the unblocked version ensures you can enjoy the game anytime, anywhere without any interruptions.</p>
    <h2 class="H22">Pro Tips for Winning Football Legends Ultimate</h2>
    <p>To master <strong>Football Legends Ultimate</strong> and come out on top, use these pro tips:</p>
    <ul>
        <li><strong>Master the Controls:</strong> Familiarize yourself with the controls to perform advanced moves and tricks on the field.</li>
        <li><strong>Time Your Shots:</strong> Timing is everything when it comes to scoring. Wait for the right moment to take the perfect shot.</li>
        <li><strong>Utilize Special Moves:</strong> Learn how to unleash special moves and tricks that can help you outplay your opponent.</li>
        <li><strong>Stay Defensive:</strong> Don’t just focus on attacking; make sure to play good defense to prevent your opponent from scoring.</li>
    </ul>
    <h2 class="H22">Why Football Legends Ultimate is a Must-Play Game</h2>
    <p><strong>Football Legends Ultimate</strong> has become a fan favorite due to its engaging gameplay, legendary players, and multiple modes. Its combination of fun, skill, and strategy makes it a standout in the world of online football games. Whether you’re a casual gamer or a football aficionado, this game has something for everyone. The action-packed gameplay and exciting matches make it one of the top choices for anyone looking to play football games online.</p>
    <h2 class="H22">Play Football Legends Ultimate Online</h2>
    <p><strong>Football Legends Ultimate Online</strong> brings the full football experience to your browser. Enjoy high-quality graphics and responsive controls, no downloads necessary. Whether you’re looking for a quick game or a longer tournament, Football Legends Ultimate provides endless fun with just a few clicks.</p>
    <h2 class="H22">Conclusion</h2>
    <p><strong>Football Legends Ultimate</strong> is the ultimate football game for fans of the sport. With legendary players, fast-paced action, and multiple game modes, this game offers hours of fun and competition. The <strong>Football Legends Ultimate Unblocked</strong> version allows you to play without limitations, making it accessible anytime, anywhere. Don’t miss out on the chance to compete as your favorite football legends. Get ready to play and score your way to victory!</p>
`,
       tag: ["Sports","Multiplayer","Skill","Arcade","Crazy games"],
    
    rating:[4.8,7503,1978],  
  },
  {
    date: "2024-11-20",
    brand: "Others",
    orientation: 0,
    title: "Duck Hunter - Play Duck Hunter Online",
    name: ["Duck Hunter", "duck-hunter"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/duck-hunter/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/duck-hunter/520x295.png",
    path: ["/game/duck-hunter", "https://playcutegames.com"],
    iframe: "https://html5.gamedistribution.com/101892a26e31401ab63e5c73aff0ff76/?gd_sdk_referrer_url=https://playcutegames.com/game/duck-hunter",
    Walkthrough: "https://www.youtube.com/embed/R5hSMTYUYZM",
    s_dis:"Duck Hunter is a fun shooting game where players aim to shoot ducks that fly across the screen. Test your accuracy and reflexes!",
    l_dis:`
             <p><strong>Duck Hunter</strong> is a timeless classic that brings the excitement of duck hunting to the digital world. Inspired by the iconic retro game, Duck Hunter challenges players to sharpen their aim and reflexes. With vibrant graphics and immersive gameplay, this game offers endless fun and a nostalgic journey for players of all ages. Whether you’re a seasoned hunter or a first-time player, Duck Hunter promises a thrilling experience.</p>
    <h2 class="H22">What is Duck Hunter?</h2>
    <p><strong>Duck Hunter</strong> is an arcade-style shooting game where players aim to shoot ducks as they fly across the screen. Using quick reflexes and precise timing, you can score points and advance to increasingly challenging levels. The game captures the spirit of traditional hunting while adding digital excitement, making it a favorite among retro gaming enthusiasts.</p>
    <h2 class="H22">Features of Duck Hunter</h2>
    <p>Duck Hunter stands out with its engaging gameplay and unique features:</p>
    <ul>
        <li><strong>Classic Arcade Feel:</strong> Relive the nostalgia of retro gaming with Duck Hunter’s design and mechanics.</li>
        <li><strong>Challenging Levels:</strong> Ducks fly faster and in unpredictable patterns as you progress.</li>
        <li><strong>Easy Controls:</strong> Aim and shoot with intuitive controls, perfect for players of all skill levels.</li>
        <li><strong>Relaxing Gameplay:</strong> Enjoy a game that balances fun with a casual, stress-free experience.</li>
    </ul>
    <h2 class="H22">How to Play Duck Hunter</h2>
    <p>Playing <strong>Duck Hunter</strong> is simple yet requires precision:</p>
    <ul>
        <li><strong>Start the Game:</strong> Launch Duck Hunter and get ready to take aim.</li>
        <li><strong>Use Your Cursor:</strong> Move the crosshair to target the ducks flying across the screen.</li>
        <li><strong>Click to Shoot:</strong> Fire at the ducks before they escape to score points.</li>
        <li><strong>Watch Your Ammo:</strong> Conserve ammunition to maximize your chances of advancing to the next level.</li>
    </ul>
    <h2 class="H22">Why Play Duck Hunter Unblocked?</h2>
    <p><strong>Duck Hunter Unblocked</strong> allows you to enjoy the game without restrictions, even on networks with gaming limitations. Whether you’re at school, work, or using public Wi-Fi, the unblocked version ensures uninterrupted access to this classic hunting adventure. Dive into the game anytime, anywhere!</p>
    <h2 class="H22">Tips to Improve Your Aim</h2>
    <p>Mastering <strong>Duck Hunter</strong> requires skill and strategy. Here are some tips to help:</p>
    <ul>
        <li><strong>Track the Ducks:</strong> Follow their movement carefully to predict their trajectory.</li>
        <li><strong>Stay Calm:</strong> Avoid rushing your shots; a steady hand ensures better accuracy.</li>
        <li><strong>Practice Patience:</strong> Wait for the perfect moment to take your shot.</li>
        <li><strong>Learn from Mistakes:</strong> Use each round to refine your aiming skills and timing.</li>
    </ul>
    <h2 class="H22">Why Duck Hunter Remains Popular</h2>
    <p>Duck Hunter’s enduring popularity stems from its nostalgic charm and engaging gameplay. The game appeals to a wide audience, from retro gaming fans to casual players seeking lighthearted fun. Its simple yet challenging mechanics provide endless replayability, making it a staple in the world of classic online games.</p>
    <h2 class="H22">Play Duck Hunter Online</h2>
    <p><strong>Duck Hunter Online</strong> offers the convenience of playing directly in your browser, no downloads required. The game is optimized for smooth performance on both desktop and mobile devices, allowing players to enjoy duck hunting on the go. Compete with friends or test your skills solo to become a master hunter.</p>
    <h2 class="H22">Conclusion</h2>
    <p><strong>Duck Hunter</strong> combines the charm of classic arcade games with the accessibility of online gaming. Whether you’re revisiting childhood memories or discovering this gem for the first time, Duck Hunter delivers hours of entertainment. With <strong>Duck Hunter Unblocked</strong>, you can experience the fun anytime, anywhere. Take aim, shoot, and relive the thrill of the hunt today!</p>
`,
       tag: ["Shooting","Strategy","Skill"],
    
    rating:[4.9,5673,923],  
  },
  {
    date: "2024-11-20",
    brand: "Others",
    orientation: 0,
    title: "Squid Game - Play Squid Game Online",
    name: ["Squid Game", "squid-game"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/squid-game/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/squid-game/520x295.png",
    path: ["/game/squid-game", "https://playcutegames.com"],
    iframe: "https://squid-game.pro/iframe/index.html",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:"Squid Game is an online game inspired by the hit series. Test your reflexes and strategy in thrilling challenges based on iconic scenes.",
    l_dis:`
            <p><strong>Squid Game</strong> brings the intense and suspenseful atmosphere of the popular series to your screen, allowing you to immerse yourself in thrilling survival challenges. Inspired by the viral TV show, this game tests your skills, strategy, and quick thinking. Whether you are competing in the classic 'Red Light, Green Light' or other iconic games, Squid Game online guarantees a gripping experience. With its rising popularity, fans of the series and new players alike are diving into the action-packed world of <strong>Squid Game</strong>.</p>
    <h2 class="H22">What is Squid Game?</h2>
    <p><strong>Squid Game</strong> is an online adaptation of the hit survival series that captivated audiences worldwide. Players participate in various mini-games based on traditional children's games, but with high-stakes consequences. The online version recreates this tension and excitement, challenging players to complete tasks without making mistakes. It’s a game of strategy, precision, and survival.</p>
    <h2 class="H22">Features of Squid Game Online</h2>
    <p><strong>Squid Game</strong> offers an array of features that keep players engaged and on edge:</p>
    <ul>
        <li><strong>Iconic Mini-Games:</strong> Play popular games like 'Red Light, Green Light' and 'Tug of War.'</li>
        <li><strong>Real-Time Competition:</strong> Face off against other players in multiplayer modes.</li>
        <li><strong>High-Stakes Gameplay:</strong> Every move counts; one mistake could mean elimination.</li>
        <li><strong>Immersive Design:</strong> Graphics and sound effects create a tense and thrilling atmosphere.</li>
        <li><strong>Progressive Challenges:</strong> Levels become increasingly difficult as you advance.</li>
    </ul>
    <h2 class="H22">How to Play Squid Game</h2>
    <p>Playing <strong>Squid Game</strong> requires focus and quick reflexes. Here’s how you can participate:</p>
    <ul>
        <li><strong>Choose Your Game Mode:</strong> Select from single-player or multiplayer options.</li>
        <li><strong>Follow the Rules:</strong> Adhere to the specific instructions of each mini-game to avoid elimination.</li>
        <li><strong>Stay Alert:</strong> Pay close attention to timers, signals, and your opponents' moves.</li>
    </ul>
    <h2 class="H22">Why Play Squid Game Unblocked?</h2>
    <p><strong>Squid Game Unblocked</strong> ensures that you can access the game from anywhere, even on restricted networks. Whether at school, work, or public Wi-Fi, the unblocked version allows uninterrupted gameplay. Dive into the high-stakes action anytime and anywhere!</p>
    <h2 class="H22">Tips to Excel at Squid Game</h2>
    <p>Here are some tips to increase your chances of success in <strong>Squid Game</strong>:</p>
    <ul>
        <li><strong>Practice Patience:</strong> Avoid rushing; precision is key to surviving each game.</li>
        <li><strong>Observe Carefully:</strong> Learn from your opponents' mistakes and adjust your strategy accordingly.</li>
        <li><strong>Stay Calm:</strong> Maintain composure during intense moments to make smart decisions.</li>
        <li><strong>Master Timing:</strong> For games like 'Red Light, Green Light,' perfect timing is essential to advance safely.</li>
    </ul>
    <h2 class="H22">Why Squid Game is So Popular</h2>
    <p><strong>Squid Game</strong> has gained massive popularity for its intense gameplay, connection to the globally acclaimed series, and the thrill of surviving against the odds. Its unique blend of strategy, competition, and suspense appeals to a wide audience. The online version adds the excitement of competing against real players, making every match unpredictable and engaging.</p>
    <h2 class="H22">Play Squid Game Online</h2>
    <p><strong>Squid Game Online</strong> is easily accessible, offering a seamless experience on various devices. No downloads are needed; just open your browser and jump straight into the action. The game’s lightweight design ensures smooth gameplay, whether you’re on a desktop or mobile device. Compete with players worldwide and prove your survival skills.</p>
    <h2 class="H22">Conclusion</h2>
    <p><strong>Squid Game</strong> combines strategy, skill, and excitement into a captivating online experience. Whether you’re a fan of the original series or a newcomer seeking thrilling gameplay, this game delivers. With <strong>Squid Game Unblocked</strong>, you can enjoy the adventure from anywhere without restrictions. Are you ready to face the challenges and emerge victorious? Play Squid Game today and put your survival instincts to the test!</p>
`,
       tag: ["Survival","Addictive","Strategy","Skill"],
    
    rating:[4.7,3578,587],  
  },
  {
    date: "2024-11-20",
    brand: "Others",
    orientation: 0,
    title: "Eggy Car - Play Eggy Car Online",
    name: ["Eggy Car", "eggy-car"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/eggy-car/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/eggy-car/520x295.png",
    path: ["/game/eggy-car", "https://playcutegames.com"],
    iframe: "https://eggy-car.io/iframe/index.html",
    Walkthrough: "https://www.youtube.com/embed/PRKGAa6dGr8",
    s_dis:"Drive the fragile Eggy Car across bumpy roads, balancing the egg carefully. Test your skills and see how far you can go without breaking it!",
    l_dis:`
            <p><strong>Eggy Car</strong> is an engaging online game that combines balance, strategy, and patience into an exciting adventure. Tasked with transporting a delicate egg across uneven and challenging terrains, players must navigate their car carefully to avoid cracking the egg. Loved by gamers worldwide, this game is not only fun but also tests your coordination and problem-solving skills. Whether you’re a casual player or a gaming enthusiast, <strong>Eggy Car</strong> promises endless entertainment.</p>
    <h2 class="H22">What is Eggy Car?</h2>
    <p><strong>Eggy Car</strong> is a physics-based balancing game where your objective is simple yet challenging: carry an egg on top of a car without letting it fall. The bumpy roads and sudden inclines make it difficult to maintain stability, requiring players to exercise precision and patience. With each level, the terrains become trickier, making the game increasingly addictive and rewarding.</p>
    <h2 class="H22">Features of Eggy Car</h2>
    <p><strong>Eggy Car</strong> is packed with features that keep players coming back for more. Some of the highlights include:</p>
    <ul>
        <li><strong>Challenging Gameplay:</strong> Navigate through uneven terrains while keeping the egg balanced.</li>
        <li><strong>Simple Controls:</strong> Intuitive controls make the game easy to pick up but hard to master.</li>
        <li><strong>Endless Levels:</strong> Continue playing as long as you can keep the egg intact.</li>
        <li><strong>Physics-Based Mechanics:</strong> Realistic physics add an extra layer of challenge and fun.</li>
        <li><strong>Leaderboard:</strong> Compete with other players and aim for the top score.</li>
    </ul>
    <h2 class="H22">How to Play Eggy Car</h2>
    <p>The controls for <strong>Eggy Car</strong> are straightforward but mastering them requires practice:</p>
    <ul>
        <li><strong>Accelerate and Decelerate:</strong> Use the forward and backward controls to navigate the car.</li>
        <li><strong>Balance the Egg:</strong> Adjust your speed to prevent the egg from sliding off the car.</li>
        <li><strong>Plan Your Moves:</strong> Anticipate road bumps and inclines to maintain control.</li>
    </ul>
    <h2 class="H22">Why Play Eggy Car Unblocked?</h2>
    <p>Playing <strong>Eggy Car Unblocked</strong> allows you to enjoy the game from anywhere, even on restricted networks. Whether you're at school, work, or any location with limited access, the unblocked version ensures uninterrupted fun. Test your skills and see how far you can go without cracking the egg!</p>
    <h2 class="H22">Tips to Master Eggy Car</h2>
    <p>To excel at <strong>Eggy Car</strong>, consider these helpful tips:</p>
    <ul>
        <li><strong>Start Slow:</strong> Begin at a steady pace to familiarize yourself with the controls and physics.</li>
        <li><strong>Focus on Balance:</strong> Keep a close eye on the egg’s movement and adjust your speed accordingly.</li>
        <li><strong>Use Momentum Wisely:</strong> Leverage downhill slopes to gain momentum but be cautious of bumps.</li>
        <li><strong>Practice Makes Perfect:</strong> The more you play, the better you'll become at navigating tricky terrains.</li>
    </ul>
    <h2 class="H22">Why Eggy Car is So Popular</h2>
    <p>The popularity of <strong>Eggy Car</strong> stems from its unique combination of simplicity and challenge. The game's physics-based mechanics, coupled with the thrill of keeping the egg intact, make it a favorite among players of all ages. It’s a perfect mix of fun and frustration, offering a sense of accomplishment with every level completed.</p>
    <h2 class="H22">Play Eggy Car Online</h2>
    <p><strong>Eggy Car</strong> is easily accessible online, allowing you to play from your browser without any downloads. The game’s lightweight design ensures smooth performance on various devices, including desktops, tablets, and smartphones. Dive into the fun and see how far you can go!</p>
    <h2 class="H22">Conclusion</h2>
    <p><strong>Eggy Car</strong> is a delightful game that challenges your skills and patience in a fun and entertaining way. Whether you’re looking for a quick gaming session or a competitive challenge, this game has something for everyone. Try <strong>Eggy Car Unblocked</strong> to enjoy uninterrupted gameplay and experience the thrill of balancing your way through tough terrains. Start your Eggy Car adventure today!</p>
`,
       tag: ["Car games","Driving","Racing"],
    
    rating:[4.6,7197,2497],  
  },
  {
    date: "2024-11-18",
    brand: "cutedressup",
    orientation: 0,
    title: "Bohemian Chic Dress-Up - Free Dress-Up Game",
    name: ["Bohemian Chic Dress-Up", "bohemian-chic-dress-up"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bohemian-chic-dress-up/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bohemian-chic-dress-up/520x295.png",
    path: ["/game/bohemian-chic-dress-up", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bohemian-chic-dress-up/",
    Walkthrough: "https://www.youtube.com/embed/fAUT6eSRf-I",
    s_dis:
    "Embrace boho fashion in Bohemian Chic Dress-Up! Style flowy dresses, bold patterns, and dreamy accessories to create unique looks!",
    l_dis:
      `
       <p>The <strong>Bohemian</strong> or <strong>Boho Style</strong> is more than just a fashion trend; it’s a way of life that celebrates creativity, individuality, and freedom. In <strong>Bohemian Chic Dress-Up</strong>, you can immerse yourself in the world of flowing fabrics, intricate patterns, and earthy tones. Whether you're new to the boho aesthetic or a seasoned enthusiast, this dress-up game lets you explore your creativity and style with an array of <strong>boho dresses</strong> and accessories.</p>
    <h2 class="H22">What is Boho Style?</h2>
    <p><strong>Boho Style</strong>, short for Bohemian style, is inspired by the free-spirited, artistic, and unconventional lifestyle. Rooted in the 1960s and 1970s, it incorporates natural elements, ethnic patterns, and a mix of textures. Boho fashion often features relaxed silhouettes, earthy tones, and accessories like beaded necklaces, fringed bags, and floral headbands. It’s all about expressing individuality and embracing a carefree, eclectic vibe.</p>
    <h2 class="H22">Explore Boho Dresses and Accessories</h2>
    <p><strong>Boho dresses</strong> are the cornerstone of the Bohemian aesthetic. They often feature flowing designs, lightweight fabrics, and intricate details. In <strong>Bohemian Chic Dress-Up</strong>, you can experiment with:</p>
    <ul>
        <li><strong>Maxi Dresses:</strong> Long, flowing dresses with vibrant prints and airy fabrics.</li>
        <li><strong>Peasant Tops:</strong> Loose-fitting tops with embroidered details and tassels.</li>
        <li><strong>Layered Skirts:</strong> Skirts with ruffles and layers, perfect for creating a whimsical look.</li>
        <li><strong>Bohemian Accessories:</strong> Add a touch of charm with chunky jewelry, wide-brim hats, and ankle boots.</li>
    </ul>
    <h2 class="H22">Features of Bohemian Chic Dress-Up</h2>
    <p>This interactive dress-up game brings the essence of Bohemian fashion to life. Here are some exciting features you’ll enjoy:</p>
    <ul>
        <li><strong>Variety of Outfits:</strong> Choose from a vast collection of <strong>boho dresses</strong>, tops, bottoms, and accessories.</li>
        <li><strong>Customizable Characters:</strong> Style your character with different hairstyles, makeup, and outfits to create the perfect boho look.</li>
        <li><strong>Mix and Match:</strong> Experiment with various combinations to craft a unique outfit that reflects your personality.</li>
        <li><strong>Creative Expression:</strong> Let your creativity shine as you explore the world of Bohemian fashion.</li>
    </ul>
    <h2 class="H22">Tips for Creating the Perfect Boho Look</h2>
    <p>Creating a flawless Bohemian look requires a balance of comfort and style. Here are some tips to help you get started:</p>
    <ul>
        <li><strong>Layering is Key:</strong> Combine different textures, patterns, and layers to achieve a signature boho vibe.</li>
        <li><strong>Choose Natural Fabrics:</strong> Opt for materials like cotton, linen, and wool for an authentic feel.</li>
        <li><strong>Accessorize Wisely:</strong> Add statement jewelry, scarves, and belts to elevate your outfit.</li>
        <li><strong>Go for Earthy Colors:</strong> Stick to neutral tones like beige, brown, and olive, but don’t shy away from adding pops of color with vibrant prints.</li>
    </ul>
    <h2 class="H22">Why Bohemian Fashion is Timeless</h2>
    <p>The appeal of <strong>Boho Style</strong> lies in its timeless nature and versatility. It effortlessly blends comfort with elegance, making it suitable for various occasions, from casual outings to formal events. The emphasis on individuality and sustainability aligns with modern values, ensuring that the boho aesthetic remains a favorite for generations to come.</p>
    <h2 class="H22">Play Bohemian Chic Dress-Up Online</h2>
    <p>Whether you're looking to explore <strong>boho dresses</strong> or simply enjoy a relaxing dress-up game, <strong>Bohemian Chic Dress-Up</strong> is the perfect choice. Dive into the world of free-spirited fashion and let your creativity run wild. Play online and experience the joy of crafting unique outfits inspired by the Bohemian aesthetic.</p>
    <h2 class="H22">Conclusion</h2>
    <p><strong>Bohemian Chic Dress-Up</strong> is more than just a game; it’s an opportunity to explore the beauty and versatility of <strong>Boho Style</strong>. With its emphasis on individuality and creativity, the game allows you to experiment with stunning <strong>boho dresses</strong> and accessories. Whether you’re a fan of the Bohemian aesthetic or looking for a new fashion adventure, this game is sure to captivate and inspire. Start playing today and embrace your inner free spirit!</p>
`,
      tag: ["Dress up","Exclusive","Girl games"],
    hint: `Use your left mouse button to play.`,
    rating:[4.2,1510,104],  
  },
  {
    date: "2024-11-18",
    brand: "Others",
    orientation: 0,
    title: "Google Solitaire - Play Google Solitaire Online",
    name: ["Google Solitaire", "google-solitaire"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/google-solitaire/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/google-solitaire/520x295.png",
    path: ["/game/google-solitaire", "https://playcutegames.com"],
    iframe: "https://www.google.co.in/logos/fnbx/solitaire/standalone.html",
    Walkthrough: "https://www.youtube.com/embed/25hy1Ew1bsk",
    s_dis:"Enjoy the classic Google Solitaire game! Stack cards in order from Ace to King for hours of fun and relaxation with no downloads needed.",
    l_dis:`
            <p><strong>Google Solitaire</strong> is the digital version of the classic card game, Solitaire, brought to you by Google. This game has been a favorite pastime for many players worldwide for decades. Whether you're a seasoned player or new to Solitaire, this simple yet engaging game offers endless entertainment. Additionally, with <strong>Google Solitaire Unblocked</strong>, you can enjoy the game anytime and anywhere, even in places where the game might be restricted.</p>
    <h2 class="H22">What is Google Solitaire?</h2>
    <p><strong>Google Solitaire</strong> is an online version of the iconic card game Solitaire, also known as Klondike. The objective of the game is to arrange a shuffled deck of cards into four foundation piles, one for each suit, starting from Ace and ending with King. The game provides a relaxing yet challenging experience as you use your strategic thinking to solve each puzzle. With its simple interface and intuitive gameplay, Google Solitaire appeals to players of all ages.</p>
    <h2 class="H22">How to Play Google Solitaire</h2>
    <p>Playing <strong>Google Solitaire</strong> is easy to learn but requires strategy to master. Here’s a basic guide to help you get started:</p>
    <ul>
        <li><strong>Deal the Cards:</strong> The game begins with a shuffled deck of 52 cards arranged into seven columns. The first column has one card, the second has two, and so on. The remaining cards form the stockpile.</li>
        <li><strong>Move Cards:</strong> You can move cards between columns following these rules: only Kings can be placed in empty columns, and cards must alternate between red and black suits in descending order (e.g., King of Hearts on Queen of Spades).</li>
        <li><strong>Foundation Piles:</strong> The goal is to move cards to the foundation piles, starting with Ace and following in ascending order to King, separated by suits (hearts, diamonds, clubs, spades).</li>
        <li><strong>Stockpile:</strong> If you're stuck, you can draw cards from the stockpile, which will be used to help you complete the game.</li>
    </ul>
    <h2 class="H22">Google Solitaire Unblocked: Play Anytime, Anywhere</h2>
    <p><strong>Google Solitaire Unblocked</strong> allows you to play this classic card game without any restrictions. Whether you’re at school, work, or anywhere with internet access, you can enjoy the game without worrying about firewalls or restrictions. Here's why <strong>Google Solitaire Unblocked</strong> is so popular:</p>
    <ul>
        <li><strong>Easy Access:</strong> You don’t need to download anything to play Google Solitaire. Simply open your browser and start playing instantly.</li>
        <li><strong>Play Across Devices:</strong> Google Solitaire works on all devices, including desktops, tablets, and smartphones, so you can enjoy it anywhere.</li>
        <li><strong>Perfect for Short Breaks:</strong> Whether you have a few minutes or longer, Google Solitaire is an excellent game for quick gaming sessions that fit into your schedule.</li>
    </ul>
    <h2 class="H22">Benefits of Playing Google Solitaire</h2>
    <p>There are many reasons why people love playing <strong>Google Solitaire</strong>, and it’s not just about having fun. Here are some benefits of playing this classic card game:</p>
    <ul>
        <li><strong>Improves Concentration:</strong> Solitaire requires focus and concentration, helping to improve attention span and decision-making skills.</li>
        <li><strong>Enhances Problem-Solving Skills:</strong> Each move in Solitaire requires careful thought and planning, enhancing your ability to think critically and strategically.</li>
        <li><strong>Relaxation:</strong> The simple rules and repetitive gameplay offer a relaxing experience that can help reduce stress and improve mood.</li>
    </ul>
    <h2 class="H22">Tips for Winning Google Solitaire</h2>
    <p>Winning <strong>Google Solitaire</strong> requires patience, strategy, and sometimes a bit of luck. Here are a few tips to help you improve your game:</p>
    <ul>
        <li><strong>Expose Hidden Cards:</strong> Always try to uncover hidden cards in the columns first. This will give you more options to play.</li>
        <li><strong>Use the Stockpile Wisely:</strong> Don’t rush to use the stockpile. Save it for when you’re stuck or need an extra card.</li>
        <li><strong>Plan Ahead:</strong> Think a few steps ahead before making a move. This helps you avoid mistakes and maximize your chances of winning.</li>
        <li><strong>Focus on One Suit:</strong> Initially, focus on moving cards of one suit to the foundation piles, as this will open up more possibilities in the columns.</li>
    </ul>
    <h2 class="H22">Why Play Google Solitaire?</h2>
    <p><strong>Google Solitaire</strong> is the perfect game for anyone looking to enjoy a relaxing yet stimulating experience. It’s simple to play, easy to access, and offers endless hours of fun. With <strong>Google Solitaire Unblocked</strong>, you can play it anywhere and anytime, ensuring that you never have to miss out on the fun. Whether you're playing for a quick break or aiming for a long gaming session, Google Solitaire offers the perfect balance of simplicity and challenge.</p>
    <h2 class="H22">Conclusion</h2>
    <p><strong>Google Solitaire</strong> is a timeless card game that continues to captivate players of all ages. Whether you’re a beginner or an experienced player, it offers hours of entertainment and mental exercise. With <strong>Google Solitaire Unblocked</strong>, you can play the game without restrictions, making it accessible to everyone, everywhere. Start playing today and enjoy the classic card game that never goes out of style!</p>
`,
       tag: ["Card","Doodle","Logic"],
    
    rating:[4.8,2197,897],  
  },
  {
    date: "2024-11-18",
    brand: "Others",
    orientation: 0,
    title: "Little Alchemy 2 - Play Little Alchemy 2 Online",
    name: ["Little Alchemy 2", "little-alchemy-2"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/little-alchemy-2/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/little-alchemy-2/520x295.png",
    path: ["/game/little-alchemy-2", "https://playcutegames.com"],
    iframe: "https://littlealchemy2.com/",
    Walkthrough: "https://www.youtube.com/embed/8yLBIcbmVCA",
    s_dis:"Explore thousands of combinations in Little Alchemy 2! Create unique items by mixing elements like air, water, fire, and earth in this fun game.",
    l_dis:`
            <p><strong>Little Alchemy 2</strong> is the highly anticipated sequel to the beloved puzzle game, Little Alchemy. This game brings even more creativity and discovery to the table, with hundreds of new elements to combine and create. Players can experiment with combinations to unlock over 700 different items and build their own virtual universe. Additionally, with <strong>Little Alchemy 2 Unblocked</strong>, you can enjoy this creative experience without restrictions, anywhere, anytime.</p>
    <h2 class="H22">What is Little Alchemy 2?</h2>
    <p><strong>Little Alchemy 2</strong> is a fun and engaging puzzle game that challenges players to combine various elements to create new ones. The game starts with a few basic elements like air, water, fire, and earth. By combining these elements in various ways, players can discover more complex items, creatures, and even entire ecosystems. The possibilities are endless, and the game rewards curiosity and creativity.</p>
    <h2 class="H22">How to Play Little Alchemy 2</h2>
    <p>Getting started with Little Alchemy 2 is easy, but the real fun comes from discovering new combinations and seeing what you can create. Here’s a simple guide to help you begin:</p>
    <ul>
        <li><strong>Start with the Basics:</strong> Begin with the basic elements such as fire, water, earth, and air. These simple ingredients will serve as the foundation for creating more complex items.</li>
        <li><strong>Combine Elements:</strong> Drag one element onto another to combine them. If they form a new element, it will appear in your collection. The more combinations you try, the more items you will unlock.</li>
        <li><strong>Use Hints:</strong> If you’re stuck, use the hint feature to get suggestions for possible combinations. However, the real fun lies in discovering the combinations on your own!</li>
        <li><strong>Unlock More Items:</strong> There are over 700 items to unlock in Little Alchemy 2. As you progress, you’ll discover animals, plants, and even mythical creatures.</li>
    </ul>
    <h2 class="H22">Little Alchemy 2 Unblocked: Play Without Limits</h2>
    <p><strong>Little Alchemy 2 Unblocked</strong> gives players the ability to enjoy the game anywhere, even in places where the game might be restricted. Here’s why <strong>Little Alchemy 2 Unblocked</strong> is so popular:</p>
    <ul>
        <li><strong>No Installation Needed:</strong> You can play Little Alchemy 2 directly in your browser without needing to download any software.</li>
        <li><strong>Accessible Across Devices:</strong> Whether you’re using a laptop, tablet, or phone, Little Alchemy 2 Unblocked works seamlessly on all devices.</li>
        <li><strong>Perfect for Quick Sessions:</strong> The game is ideal for short breaks or extended play, as it doesn’t require a lot of time commitment. You can dive in and play whenever you want!</li>
    </ul>
    <h2 class="H22">Tips for Mastering Little Alchemy 2</h2>
    <p>While Little Alchemy 2 is easy to pick up, it offers a deep challenge for those who want to unlock all the elements. Here are a few tips to help you become a master:</p>
    <ul>
        <li><strong>Start Simple:</strong> Begin by combining the basic elements and work your way up to more complex items. Simple combinations often lead to unlocking more advanced elements.</li>
        <li><strong>Experiment:</strong> Try combining everything you can think of! The more combinations you try, the more likely you are to discover new items.</li>
        <li><strong>Use Hints Wisely:</strong> If you’re stuck, use the hint feature. However, try to avoid relying on it too much to maintain the thrill of discovering things on your own.</li>
        <li><strong>Think Creatively:</strong> Some combinations are abstract, so think outside the box. For example, combining “human” and “fire” could lead to “cooking,” or combining “cloud” and “earth” might create “soil.”</li>
    </ul>
    <h2 class="H22">Why Players Love Little Alchemy 2</h2>
    <p>Players love <strong>Little Alchemy 2</strong> because it combines creativity, exploration, and a touch of challenge. The game is easy to learn but offers deep complexity as you try to unlock all the possible combinations. With hundreds of items to discover, there’s always something new to create, and the satisfaction of finding a new item is rewarding.</p>
    <h2 class="H22">Conclusion</h2>
    <p><strong>Little Alchemy 2</strong> is a fun and addictive puzzle game that appeals to players of all ages. Whether you’re a casual player looking for a quick break or a dedicated gamer aiming to unlock all the elements, Little Alchemy 2 offers hours of entertainment. With <strong>Little Alchemy 2 Unblocked</strong>, you can play without restrictions, giving you the freedom to enjoy the game wherever you are. Start experimenting, creating, and discovering today!</p>
`,
       tag: ["Learning","Brain","Puzzle","Logic"],
    
    rating:[4.8,2197,897],  
  },
  {
    date: "2024-11-18",
    brand: "Others",
    orientation: 0,
    title: "Little Alchemy - Play Little Alchemy Online",
    name: ["Little Alchemy", "little-alchemy"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/little-alchemy/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/little-alchemy/520x295.png",
    path: ["/game/little-alchemy", "https://playcutegames.com"],
    iframe: "https://littlealchemy.app/iframe/little-alchemy/",
    Walkthrough: "https://www.youtube.com/embed/W7jhewnL8cM",
    s_dis:"Combine air, water, fire, and earth in Little Alchemy! Create hundreds of items and discover endless possibilities in this creative game.",
    l_dis:`
            <p><strong>Little Alchemy</strong> is a popular and addictive puzzle game where players combine different elements to create new ones. With its simple mechanics and endless possibilities, Little Alchemy is a game that challenges your creativity and problem-solving skills. You can also play <strong>Little Alchemy Unblocked</strong>, giving you the freedom to enjoy the game without any restrictions, whether you’re at school, work, or home.</p>
    <h2 class="H22">What is Little Alchemy?</h2>
    <p>Little Alchemy is a puzzle game where players start with a few basic elements such as air, water, fire, and earth. The goal is to combine these elements to create new ones, which in turn can be combined further to make even more complex items. The game is all about experimentation and discovery, as you try to figure out how different elements interact with each other.</p>
    <h2 class="H22">How to Play Little Alchemy</h2>
    <p>Playing Little Alchemy is simple and intuitive. Here’s how you can get started:</p>
    <ul>
        <li><strong>Start with Basics:</strong> You begin with four basic elements—air, water, fire, and earth. Combine them to create new elements.</li>
        <li><strong>Drag and Drop:</strong> To create combinations, drag one element onto another. If they can be combined, a new element will appear.</li>
        <li><strong>Experiment:</strong> The key to progressing in the game is experimenting with different combinations. There are hundreds of potential combinations to discover!</li>
        <li><strong>Track Progress:</strong> As you discover new elements, they get added to your collection. The more elements you create, the more exciting combinations you'll uncover.</li>
    </ul>
    <h2 class="H22">Little Alchemy Unblocked: Play Anytime, Anywhere</h2>
    <p><strong>Little Alchemy Unblocked</strong> allows you to enjoy the game without restrictions, whether you're at school, work, or home. Here's why it’s so popular:</p>
    <ul>
        <li><strong>No Downloads Required:</strong> You can play directly in your web browser without needing to download any software.</li>
        <li><strong>Accessible Across Devices:</strong> Little Alchemy Unblocked works on any device with an internet connection, including laptops, tablets, and smartphones.</li>
        <li><strong>Perfect for Quick Sessions:</strong> The game is ideal for short gaming sessions, allowing you to play a few rounds during breaks or whenever you need a mental boost.</li>
    </ul>
    <h2 class="H22">Tips to Master Little Alchemy</h2>
    <p>While Little Alchemy is fun and simple, it can also be tricky at times. Here are a few tips to help you become a pro:</p>
    <ul>
        <li><strong>Start with Basic Combinations:</strong> Begin by combining the basic elements to unlock more complex ones.</li>
        <li><strong>Think Outside the Box:</strong> Try to think about how different elements could logically combine in the real world. For example, combining water and earth might create mud.</li>
        <li><strong>Use Hints Sparingly:</strong> If you’re stuck, use hints but try not to rely on them too much. The satisfaction of discovering new elements on your own is part of the fun!</li>
        <li><strong>Experiment with Everything:</strong> Don’t be afraid to mix seemingly unrelated elements. You might be surprised by the results.</li>
    </ul>
    <h2 class="H22">Why Players Love Little Alchemy</h2>
    <p>Little Alchemy has become a favorite among puzzle game lovers due to its simple yet highly engaging gameplay. Players love the freedom to experiment and create new elements, which leads to endless hours of fun. The challenge of discovering all the possible combinations keeps players coming back for more.</p>
    <h2 class="H22">Conclusion</h2>
    <p>Little Alchemy is a delightful and addictive puzzle game that stimulates creativity and logical thinking. Whether you’re just starting or have already unlocked a huge variety of elements, the game offers endless opportunities for discovery. Play <strong>Little Alchemy Unblocked</strong> anytime, anywhere, and enjoy a fun, stress-free gaming experience. Try it today and start creating your own virtual world!</p>
`,
       tag: ["Learning","Brain","Puzzle","Logic"],
    
    rating:[4.6,3566,1712],  
  },
  {
    date: "2024-11-16",
    brand: "GD",
    orientation: 0,
    title: "Basketball Legends - Play Basketball Legends Online",
    name: ["Basketball Legends", "basketball-legends"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/basketball-legends/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/basketball-legends/520x295.png",
    path: ["/game/basketball-legends", "https://playcutegames.com"],
    iframe: "https://html5.gamedistribution.com/3dbd133d0cd9468bafdf2c9812290f4b/?gd_sdk_referrer_url=https://playcutegames.com/game/basketball-legends",
    Walkthrough: "https://www.youtube.com/embed/YLFLrZgeVx0",
    s_dis:"Dominate the court in Basketball Legends! Play solo or with friends, unleash special moves, and become a basketball superstar.",
    l_dis:`
           <p><strong>Basketball Legends</strong> is a dynamic and action-packed sports game that puts you in control of some of the greatest basketball players. Whether playing solo or in multiplayer mode, this game delivers intense matches, exciting gameplay, and the thrill of dunking on your opponents. With <strong>Basketball Legends unblocked</strong>, you can enjoy the game anytime, anywhere, without restrictions.</p>
    <h2 class="H22">What is Basketball Legends?</h2>
    <p>Basketball Legends is an arcade-style basketball game where you compete in fast-paced one-on-one or two-on-two matches. Players can choose from a roster of legendary basketball stars, each with unique skills and abilities. The game features dynamic gameplay, including slam dunks, three-pointers, and special moves, making it a favorite for sports enthusiasts.</p>
    <h2 class="H22">How to Play Basketball Legends</h2>
    <p>Mastering Basketball Legends is about combining skill, strategy, and timing. Here’s how to play:</p>
    <ul>
        <li><strong>Controls:</strong> Use the keyboard to move, jump, and shoot. Special moves and blocks are activated with specific key combinations.</li>
        <li><strong>Game Modes:</strong> Choose between single-player, two-player, or tournament modes to test your skills against AI or friends.</li>
        <li><strong>Score Goals:</strong> The objective is to outscore your opponent by making baskets within the allotted time.</li>
        <li><strong>Special Abilities:</strong> Each player has a unique special move that can turn the tide of the game. Use it strategically to gain an advantage.</li>
    </ul>
    <h2 class="H22">Features of Basketball Legends</h2>
    <p>Basketball Legends is packed with features that keep players coming back for more:</p>
    <ul>
        <li><strong>Famous Players:</strong> Play as iconic basketball legends with distinct skills and appearances.</li>
        <li><strong>Multiplayer Mode:</strong> Challenge your friends in head-to-head matches for ultimate bragging rights.</li>
        <li><strong>Simple Controls:</strong> The intuitive controls make it easy for beginners to jump in while offering depth for seasoned players.</li>
        <li><strong>Exciting Gameplay:</strong> Perform high-flying dunks, buzzer-beating shots, and epic blocks.</li>
    </ul>
    <h2 class="H22">Basketball Legends Unblocked: Play Without Limits</h2>
    <p>With <strong>Basketball Legends unblocked</strong>, you can enjoy the game on any device, even in restricted environments like schools or workplaces. Here’s why it’s popular:</p>
    <ul>
        <li><strong>Instant Access:</strong> Play directly from your browser without downloads or installations.</li>
        <li><strong>Cross-Platform Compatibility:</strong> Enjoy seamless gameplay on desktops, laptops, and tablets.</li>
        <li><strong>Great for Quick Matches:</strong> Perfect for short gaming sessions or extended tournaments with friends.</li>
    </ul>
    <h2 class="H22">Tips to Master Basketball Legends</h2>
    <p>Enhance your gameplay and dominate the court with these tips:</p>
    <ul>
        <li><strong>Time Your Shots:</strong> Precision is key to landing three-pointers and avoiding blocks.</li>
        <li><strong>Use Special Moves:</strong> Save your special abilities for critical moments to gain an edge over your opponent.</li>
        <li><strong>Master Defense:</strong> Blocking your opponent’s shots can shift momentum in your favor.</li>
        <li><strong>Practice Makes Perfect:</strong> Spend time in single-player mode to hone your skills and strategies.</li>
    </ul>
    <h2 class="H22">Why Players Love Basketball Legends</h2>
    <p>Basketball Legends captures the excitement of basketball in a fun and accessible format. Its blend of fast-paced action, strategic gameplay, and competitive multiplayer mode has made it a fan favorite. Whether you’re a basketball enthusiast or just looking for a fun game to play with friends, Basketball Legends delivers endless entertainment.</p>
    <h2 class="H22">Conclusion</h2>
    <p>Basketball Legends is the ultimate game for basketball lovers and casual gamers alike. Its unblocked version ensures you can enjoy the action anytime, anywhere, making it a perfect choice for quick matches or competitive tournaments. Step onto the court and prove your skills in Basketball Legends!</p>
`,
       tag: ["Multiplayer","Sports","Skill"],
    
    rating:[4.8,6141,2512],  
  },
  {
    date: "2024-11-16",
    brand: "Crazy games",
    orientation: 0,
    title: "Sky Riders - Play Sky Riders Online",
    name: ["Sky Riders", "sky-riders"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/sky-riders/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/sky-riders/520x295.png",
    path: ["/game/sky-riders", "https://playcutegames.com"],
    iframe: "https://games.crazygames.com/en_US/sky-riders-buk/index.html",
    Walkthrough: "https://www.youtube.com/embed/Yaec_21dcro",
    s_dis:"Soar through thrilling tracks in Sky Riders! Perform daring stunts, dodge obstacles, and race to victory in this high-flying adventure.",
    l_dis:`
           <p><strong>Sky Riders</strong> is an exhilarating action-adventure game that takes you on a gravity-defying journey through challenging tracks high above the clouds. With its captivating visuals, fast-paced gameplay, and immersive mechanics, Sky Riders offers a unique experience for thrill-seekers and gamers alike. Dive into the excitement and enjoy the unblocked version to play anytime, anywhere.</p>
    <h2 class="H22">What is Sky Riders?</h2>
    <p>Sky Riders is a high-speed platforming game where players navigate challenging tracks suspended in mid-air. The goal is to steer your vehicle or character through loops, jumps, and sharp turns while collecting points and avoiding obstacles. Its stunning 3D graphics and intuitive controls make it a standout choice for fans of arcade-style games.</p>
    <h2 class="H22">How to Play Sky Riders</h2>
    <p>Mastering Sky Riders requires a mix of precision, timing, and strategy. Here’s how you can play:</p>
    <ul>
        <li><strong>Navigate the Track:</strong> Use arrow keys or on-screen controls to guide your character or vehicle along the track. Avoid falling off or crashing into obstacles.</li>
        <li><strong>Collect Points:</strong> Gather coins, stars, or other collectibles scattered across the track to boost your score.</li>
        <li><strong>Complete Levels:</strong> Progress through increasingly challenging levels by reaching the finish line without mishaps.</li>
        <li><strong>Master the Physics:</strong> Adjust your speed and trajectory to handle sharp turns, gravity-defying jumps, and tight corners.</li>
    </ul>
    <h2 class="H22">Why Play Sky Riders?</h2>
    <p>Sky Riders offers a combination of adrenaline-pumping action and strategic gameplay. Here’s why it’s a must-play:</p>
    <ul>
        <li><strong>Stunning Visuals:</strong> The high-altitude tracks and vibrant backgrounds create a visually captivating experience.</li>
        <li><strong>Challenging Gameplay:</strong> With each level, the difficulty increases, pushing your reflexes and strategy to the limit.</li>
        <li><strong>Quick Sessions:</strong> The fast-paced nature of Sky Riders makes it ideal for quick gaming breaks or extended sessions.</li>
        <li><strong>Replayability:</strong> Multiple tracks and challenges ensure no two runs feel the same.</li>
    </ul>
    <h2 class="H22">Sky Riders Unblocked: Unlimited Access</h2>
    <p>With <strong>Sky Riders unblocked</strong>, you can enjoy this thrilling game without restrictions. Here’s why the unblocked version is a favorite:</p>
    <ul>
        <li><strong>Play Anywhere:</strong> Whether at school, work, or home, Sky Riders unblocked lets you dive into the action from any location.</li>
        <li><strong>Browser-Based:</strong> No downloads or installations are needed—just launch the game in your browser and start playing.</li>
        <li><strong>Device-Friendly:</strong> The unblocked version works seamlessly on desktops, laptops, and tablets, ensuring accessibility for all players.</li>
    </ul>
    <h2 class="H22">Tips for Mastering Sky Riders</h2>
    <p>To excel in Sky Riders, you’ll need sharp reflexes and a good understanding of the game’s mechanics. Here are some tips:</p>
    <ul>
        <li><strong>Focus on Precision:</strong> Maintain control during turns and jumps to avoid losing momentum or falling off the track.</li>
        <li><strong>Learn the Track:</strong> Familiarize yourself with each level’s layout to anticipate challenges and strategize accordingly.</li>
        <li><strong>Adjust Speed:</strong> Know when to speed up for jumps and when to slow down for tight corners.</li>
        <li><strong>Practice Makes Perfect:</strong> Replay levels to improve your timing and master difficult sections.</li>
    </ul>
    <h2 class="H22">Why Gamers Love Sky Riders</h2>
    <p>Sky Riders combines stunning visuals, challenging gameplay, and an addictive progression system. Its ability to test your skills while delivering a visually immersive experience has made it a fan favorite. Whether you’re chasing high scores or simply enjoying the thrill of defying gravity, Sky Riders delivers endless entertainment.</p>
    <h2 class="H22">Conclusion: Take Flight with Sky Riders</h2>
    <p>Sky Riders is an adrenaline-filled adventure that offers something for every gamer. With its unblocked version providing unrestricted access, you can enjoy the game’s exciting challenges whenever and wherever you want. Gear up, hit the track, and soar to victory in Sky Riders!</p>
`,
       tag: ["3D","Adventure","Car games","Crazy games","Racing"],
    
    rating:[4.6,5241,1217],  
  },
  {
    date: "2024-11-15",
    brand: "Yandex",
    orientation: 0,
    title: "Stick Wars - Play Stick Wars Online",
    name: ["Stick Wars", "stick-wars"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/stick-wars/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/stick-wars/520x295.png",
    path: ["/game/stick-wars", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/204563?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/nwnim_ELhWU",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/stick-wars.mp4",
    s_dis:"Defend your empire in Stick Wars! Control your army, battle enemies, and strategize to conquer in this action-packed stick figure game.",
    l_dis:`
          <p><strong>Stick Wars</strong> is a popular strategy game that pits you against waves of enemies in an epic battle for dominance. Featuring stick figure characters, this game combines action, strategy, and fast-paced gameplay. As the leader of an army, you must train, upgrade, and deploy units to defeat your enemies and protect your territory. Whether you're a seasoned strategist or a casual gamer, <strong>Stick Wars</strong> offers an exciting experience with its simple controls and engaging gameplay.</p>
    <h2 class="H22">What is Stick Wars?</h2>
    <p><strong>Stick Wars</strong> is a strategy-based action game where you control a group of stick figure warriors and fight against rival armies. The objective is to defeat enemy forces while defending your base. You can upgrade your units, collect resources, and unlock new abilities as you progress through the game’s challenging levels. The game’s unique combat mechanics and fun visuals have made it a favorite among strategy enthusiasts.</p>
    <h2 class="H22">How to Play Stick Wars</h2>
    <p>Playing <strong>Stick Wars</strong> is all about strategic thinking and quick decision-making. Here’s a breakdown of how to play:</p>
    <ul>
        <li><strong>Manage Resources:</strong> Gather resources to train your army and upgrade your units. Resources are vital to advancing in the game.</li>
        <li><strong>Deploy Units:</strong> You can deploy different types of units, including warriors, archers, and specialized fighters, to battle enemy forces.</li>
        <li><strong>Upgrade Your Base:</strong> Keep upgrading your base to strengthen your defenses and unlock powerful abilities.</li>
        <li><strong>Defend and Attack:</strong> Balance between defending your territory and attacking the enemy base to take control of the battlefield.</li>
    </ul>
    <h2 class="H22">Why Play Stick Wars?</h2>
    <p><strong>Stick Wars</strong> offers a combination of fun and strategic depth that keeps players coming back. Here’s why you should give it a try:</p>
    <ul>
        <li><strong>Simple Yet Engaging:</strong> With easy-to-understand mechanics and a straightforward interface, <strong>Stick Wars</strong> is suitable for all types of players.</li>
        <li><strong>Strategic Gameplay:</strong> You need to think ahead, carefully manage resources, and plan your attacks to outsmart your enemies.</li>
        <li><strong>Endless Fun:</strong> Each level presents new challenges and opportunities to test your strategic skills.</li>
        <li><strong>Intense Battles:</strong> The thrill of the action, combined with tactical gameplay, makes for an exhilarating gaming experience.</li>
    </ul>
    <h2 class="H22">Stick Wars Unblocked: Play Anywhere, Anytime</h2>
    <p>With <strong>Stick Wars unblocked</strong>, you can enjoy the game without any restrictions. Here’s why the unblocked version is so popular:</p>
    <ul>
        <li><strong>Access Anywhere:</strong> You can play <strong>Stick Wars</strong> on any device, whether at school, work, or home, without facing blockages.</li>
        <li><strong>No Downloads Needed:</strong> The unblocked version allows you to play directly in your browser without the hassle of downloads or installations.</li>
        <li><strong>Cross-Platform Play:</strong> Whether you’re using a laptop, tablet, or phone, you can easily access <strong>Stick Wars unblocked</strong> and enjoy a smooth gaming experience.</li>
    </ul>
    <h2 class="H22">Tips for Mastering Stick Wars</h2>
    <p>To become a true <strong>Stick Wars</strong> champion, you’ll need more than just basic skills. Here are some tips to help you win:</p>
    <ul>
        <li><strong>Manage Your Resources:</strong> Don’t overspend on units. Make sure you have enough resources to upgrade your base and unlock new abilities.</li>
        <li><strong>Use the Right Units:</strong> Each unit has its strengths and weaknesses. Strategically deploy your units based on the enemy’s forces.</li>
        <li><strong>Upgrade Frequently:</strong> Constantly upgrade your units and base to stay ahead of your enemies and keep improving your defensive capabilities.</li>
        <li><strong>Balance Attack and Defense:</strong> Keep a good balance between attacking the enemy base and defending your own. Overcommitting to one can lead to your downfall.</li>
    </ul>
    <h2 class="H22">Why Players Love Stick Wars</h2>
    <p><strong>Stick Wars</strong> has captivated players due to its unique blend of action and strategy. The engaging gameplay, easy controls, and fun stick figure characters make it a game everyone can enjoy. Whether you're a casual gamer or a hardcore strategist, <strong>Stick Wars</strong> offers a rewarding experience that keeps you coming back for more.</p>
    <h2 class="H22">Conclusion: Dive Into Stick Wars</h2>
    <p>If you’re a fan of strategy and action, <strong>Stick Wars</strong> is the game for you. With its simple controls, engaging battles, and strategic depth, it offers a unique gaming experience. Whether you're playing the regular version or the unblocked version, <strong>Stick Wars</strong> promises hours of entertainment. Get ready to lead your stick figure army to victory!</p>
`,
       tag: ["Survival","Addictive","Shooting","Fight"],
    
    rating:[4.8,2153,897],  
  },
  {
    date: "2024-11-15",
    brand: "Others",
    orientation: 0,
    title: "Retro Bowl - Play Online for Free",
    name: ["Retro Bowl", "retro-bowl"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/retro-bowl/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/retro-bowl/520x295.png",
    path: ["/game/retro-bowl", "https://playcutegames.com"],
    iframe: "https://retrobowl.me/iframe/retro-bowl/latest2.html",
    Walkthrough: "https://www.youtube.com/embed/EDv7cgdM2EA",
    s_dis:"Lead your team to victory in Retro Bowl! Manage players, strategize plays, and experience the thrill of classic football action.",
    l_dis:`
          <p><strong>Retro Bowl</strong> is a widely loved football simulation game that combines strategic management with thrilling on-field action. With its retro-inspired pixel art and engaging gameplay, the game delivers a perfect blend of nostalgia and fun. Whether you’re leading your team to victory or managing their success off the field, Retro Bowl offers hours of entertainment for football enthusiasts and casual gamers alike.</p>

<h2 class="H22">What is Retro Bowl?</h2>
<p><strong>Retro Bowl</strong> is a unique take on football gaming, blending old-school graphics with modern gameplay mechanics. Developed by New Star Games, this title allows players to step into the shoes of a coach and lead their team to championship glory. The game’s strategic depth and fast-paced matches make it a standout experience, earning it a loyal fanbase worldwide.</p>
<p>What sets Retro Bowl apart is its dual focus: managing team rosters and executing plays on the field. You’ll recruit and train players, manage your budget, and call the shots during high-stakes matches, all while enjoying the simplicity of retro-style visuals and sound effects.</p>

<h2 class="H22">How to Play Retro Bowl</h2>
<p>Getting started with <strong>Retro Bowl</strong> is simple, but mastering it requires a mix of strategic planning and quick decision-making. Here’s a step-by-step guide:</p>
<ul>
    <li><strong>Team Management:</strong> Begin by building your team. Hire players with the right skills, manage contracts, and upgrade facilities to enhance your team’s performance.</li>
    <li><strong>Gameplay Controls:</strong> Use simple swipe and tap controls to pass, run, and score during matches. The intuitive controls make the game accessible for players of all skill levels.</li>
    <li><strong>Season Mode:</strong> Play through an entire season, aiming to win as many games as possible to secure your spot in the playoffs and ultimately win the Retro Bowl championship.</li>
    <li><strong>Strategic Decisions:</strong> Balance your team’s offense and defense, and make tactical decisions during critical moments in the game.</li>
</ul>

<h2 class="H22">Why Play Retro Bowl?</h2>
<p><strong>Retro Bowl</strong> has become a fan-favorite for several reasons:</p>
<ul>
    <li><strong>Accessible Gameplay:</strong> The easy-to-learn controls and straightforward mechanics make it a game anyone can pick up and enjoy.</li>
    <li><strong>Strategic Depth:</strong> Managing team rosters, making trades, and planning plays adds layers of strategy that keep the game engaging.</li>
    <li><strong>Retro Appeal:</strong> The pixel art graphics and chiptune-style soundtrack evoke a sense of nostalgia, appealing to fans of classic gaming.</li>
    <li><strong>High Replay Value:</strong> Every season presents new challenges, ensuring the game remains fresh and exciting.</li>
</ul>

<h2 class="H22">Retro Bowl Unblocked: Play Anytime, Anywhere</h2>
<p>For those who want to enjoy <strong>Retro Bowl</strong> without restrictions, the unblocked version is the perfect solution. Here’s why:</p>
<ul>
    <li><strong>No Downloads Needed:</strong> Play directly in your browser without the need for installations or updates.</li>
    <li><strong>Accessible from Anywhere:</strong> Whether you’re at school, work, or home, Retro Bowl unblocked allows you to dive into the game anytime.</li>
    <li><strong>Cross-Platform Compatibility:</strong> Enjoy the game on laptops, tablets, or smartphones without any hassle.</li>
</ul>

<h2 class="H22">Tips for Winning in Retro Bowl</h2>
<p>To excel in <strong>Retro Bowl</strong>, keep these tips in mind:</p>
<ul>
    <li><strong>Draft Wisely:</strong> Focus on building a balanced team with strong players on both offense and defense.</li>
    <li><strong>Upgrade Facilities:</strong> Investing in training and rehab facilities can significantly improve your players’ performance over time.</li>
    <li><strong>Plan Your Plays:</strong> Analyze your opponent’s defense and adjust your strategy accordingly to exploit weaknesses.</li>
    <li><strong>Manage Resources:</strong> Keep an eye on your budget and avoid overspending on players or upgrades.</li>
    <li><strong>Practice Timing:</strong> Master the timing for passes and runs to maximize your scoring opportunities.</li>
</ul>

<h2 class="H22">The Legacy of Retro Bowl</h2>
<p><strong>Retro Bowl</strong> has carved out a special place in the world of football games. Its combination of strategic gameplay, retro aesthetics, and accessible mechanics makes it a standout title. Fans of both classic and modern games continue to be drawn to its charm and depth.</p>
<p>The game’s enduring appeal lies in its ability to deliver a rewarding experience for players of all skill levels. Whether you’re a seasoned football fan or new to the genre, Retro Bowl offers something for everyone.</p>

<h2 class="H22">Conclusion: Dive Into the Retro Bowl Experience</h2>
<p><strong>Retro Bowl</strong> is more than just a game—it’s a celebration of football and retro gaming combined. With its engaging gameplay, strategic depth, and nostalgic charm, it’s a title that keeps players coming back for more. Whether you’re managing your team off the field or executing plays during intense matches, the thrill of the game never fades.</p>
<p>Take the challenge and experience the excitement of <strong>Retro Bowl</strong>. With the unblocked version, you can play anytime, anywhere. Are you ready to lead your team to victory? The Retro Bowl championship awaits!</p>
`,
    tag: ["Retro","Sports","Skill"],
    rating:[4.3,8714,5025],  
  },
  {
    date: "2024-11-15",
    brand: "Others",
    orientation: 0,
    title: "Getting Over It - Play Online for Free",
    name: ["Getting Over It", "getting-over-it"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/getting-over-it/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/getting-over-it/520x295.png",
    path: ["/game/getting-over-it", "https://playcutegames.com"],
    iframe: "https://gettingoverit.io/1.embed",
    Walkthrough: "https://www.youtube.com/embed/lexzguOQCS0",
    s_dis:"Conquer tough obstacles in Getting Over It! Use a hammer to climb, test your patience, and master the challenge in this unique game.",
    l_dis:`
          <p><strong>Getting Over It</strong> is a renowned physics-based climbing game that tests your patience, skill, and perseverance. Developed by Bennett Foddy, this unique game offers a surreal yet intensely challenging experience. Players control a character in a cauldron using only a hammer to climb obstacles, creating a journey that's as frustrating as it is rewarding. For those seeking a game that pushes the limits of determination, <strong>Getting Over It</strong> is a must-play.</p>
<h2 class="H22">What is Getting Over It?</h2>
<p><strong>Getting Over It</strong> is a game that defies conventions. Instead of guiding players through levels with ease, it throws them into a steep, almost insurmountable challenge. You play as Diogenes, a man stuck in a cauldron, armed only with a sledgehammer to climb various obstacles. The objective? Reach the top of a mountain.</p>
<p>The gameplay focuses on precise movements and physics-based mechanics, where every swing and push of the hammer can either propel you forward or send you plummeting back down. Along the way, the game features philosophical commentary from the developer, adding depth and humor to the experience.</p>
<h2 class="H22">How to Play Getting Over It</h2>
<p>The mechanics of <strong>Getting Over It</strong> are simple to understand but incredibly difficult to master. Here’s a guide to get started:</p>
<ul>
    <li><strong>Controls:</strong> Use your mouse or trackpad to maneuver the hammer. Swiping in different directions controls the hammer’s movement, allowing you to push, pull, or swing your character over obstacles.</li>
    <li><strong>Objective:</strong> Your goal is to climb as high as possible, overcoming the game’s unforgiving terrain. There are no checkpoints, meaning every fall is a significant setback.</li>
    <li><strong>Persistence is Key:</strong> The game is designed to test your resilience. Success comes from practice, learning the physics, and mastering the controls.</li>
    <li><strong>Listen to the Narration:</strong> Throughout the journey, you’ll hear philosophical musings from the developer. These insights provide both humor and a deeper understanding of the game’s themes.</li>
</ul>
<h2 class="H22">Why Play Getting Over It?</h2>
<p><strong>Getting Over It</strong> has earned a cult following due to its unique approach and unparalleled challenge. Here’s why it’s worth playing:</p>
<ul>
    <li><strong>Unforgettable Challenge:</strong> Few games offer the same level of difficulty and reward. Every small victory feels monumental.</li>
    <li><strong>Philosophical Depth:</strong> The developer’s commentary provides a mix of humor, insight, and encouragement, making the journey as thought-provoking as it is frustrating.</li>
    <li><strong>Replay Value:</strong> The unpredictable physics and lack of checkpoints ensure that every playthrough is a unique experience.</li>
    <li><strong>Satisfaction of Success:</strong> Reaching the top is an achievement like no other, giving players an immense sense of accomplishment.</li>
</ul>
<h2 class="H22">Tips for Playing Getting Over It</h2>
<p>Success in <strong>Getting Over It</strong> requires patience and practice. Here are some tips to help you climb higher:</p>
<ul>
    <li><strong>Practice Precision:</strong> Small, controlled movements of the hammer are often more effective than wild swings.</li>
    <li><strong>Find Your Rhythm:</strong> Repetition helps you develop muscle memory. Focus on mastering each section before moving forward.</li>
    <li><strong>Stay Calm:</strong> Frustration is part of the game. Take breaks when needed to avoid making impulsive mistakes.</li>
    <li><strong>Learn from Mistakes:</strong> Every fall is an opportunity to analyze what went wrong and improve your technique.</li>
</ul>
<h2 class="H22">The Legacy of Getting Over It</h2>
<p><strong>Getting Over It</strong> has become a cultural phenomenon, inspiring countless players and streamers to take on its seemingly impossible challenge. Its combination of minimalist gameplay, philosophical undertones, and punishing difficulty has cemented its place as a modern classic in the gaming world.</p>
<p>The game is not just about reaching the top—it’s about the journey, the setbacks, and the lessons learned along the way. It’s a testament to the human spirit and the determination to overcome even the most daunting obstacles.</p>
<h2 class="H22">Conclusion: Embrace the Challenge of Getting Over It</h2>
<p><strong>Getting Over It</strong> is more than a game—it’s an experience that tests your patience, perseverance, and skill. Whether you’re looking for a unique challenge or a game that provokes thought and reflection, <strong>Getting Over It</strong> delivers in spades.</p>
<p>Take on the climb, learn from every fall, and savor the sense of accomplishment when you finally reach the top. Are you ready to embrace the challenge? Try <strong>Getting Over It</strong> today and see how far you can go!</p>
`,
    tag: ["Platformer","Casual","Skill"],
    
    rating:[4.3,8714,5025],  
  },
  {
    date: "2024-11-14",
    brand: "Others",
    orientation: 0,
    title: "Bloons TD 6 - Play Tower Defence Online",
    name: ["Bloons TD 6", "bloons-td-6"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bloons-td-6/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bloons-td-6/520x295.png",
    path: ["/game/bloons-td-6", "https://playcutegames.com"],
    iframe: "https://iclouds.space/HTML5GAMES/BloonsTD6/index.html",
    Walkthrough: "https://www.youtube.com/embed/zKuCV8nhZSc",
    s_dis:"Defend against waves of bloons in Bloons TD 6! Strategize, upgrade towers, and pop bloons in this exciting tower defense game.",
    l_dis:`
         <p><strong>Bloons TD 6</strong> is a thrilling tower defense game where you strategically place various towers to pop waves of colorful bloons (balloons). Known for its engaging gameplay and diverse range of towers, heroes, and maps, <strong>Bloons TD 6</strong> offers an exciting challenge for both newcomers and seasoned players alike. You can also enjoy <strong>Bloons TD 6 Unblocked</strong>, allowing you to play the game anytime, anywhere, without the usual access restrictions!</p>

<h2 class="H22">What is Bloons TD 6?</h2>
<p><strong>Bloons TD 6</strong> is the sixth installment in the popular Bloons Tower Defense series, developed by Ninja Kiwi. It features classic tower defense gameplay with a unique twist. Players are tasked with preventing a variety of bloons from reaching the end of a path by strategically placing different types of towers that pop the bloons. Each tower has its own abilities, strengths, and weaknesses, and players must adapt their strategies as they progress through increasingly difficult levels.</p>
<p>The game includes a wide selection of towers, heroes, and upgrades, allowing for countless strategies and playstyles. With challenging levels, new bloon types, and evolving gameplay mechanics, Bloons TD 6 is perfect for players who enjoy strategic thinking and fast-paced action.</p>

<h2 class="H22">How to Play Bloons TD 6</h2>
<p>Playing <strong>Bloons TD 6</strong> is easy to understand, but difficult to master. Here’s a quick guide to help you get started:</p>
<ul>
    <li><strong>Objective:</strong> The goal is to stop waves of bloons from reaching the exit by placing various towers along the path. You’ll earn money for popping bloons, which you can use to upgrade or place new towers.</li>
    <li><strong>Types of Towers:</strong> There are many types of towers, including dart monkeys, tack shooters, wizard monkeys, and more. Each tower has different abilities, and some are better suited to dealing with specific types of bloons.</li>
    <li><strong>Upgrades:</strong> As you progress, you can upgrade your towers to increase their effectiveness. These upgrades can enhance range, attack speed, or add new abilities, making your defenses stronger as the bloon waves get tougher.</li>
    <li><strong>Heroes:</strong> New to Bloons TD 6, heroes are powerful units with special abilities. You can choose a hero to assist you in battle, and they level up as they pop bloons, unlocking new skills and powers.</li>
    <li><strong>Game Modes:</strong> Bloons TD 6 offers several game modes, including standard mode, advanced mode, and co-op mode, where you can team up with friends to tackle levels together.</li>
</ul>

<h2 class="H22">Why Play Bloons TD 6 Unblocked?</h2>
<p><strong>Bloons TD 6 Unblocked</strong> provides the perfect solution for those who want to enjoy the game without any limitations. Here’s why the unblocked version is so appealing:</p>
<ul>
    <li><strong>Access Anywhere:</strong> Whether you’re at school, work, or at home, you can play <strong>Bloons TD 6</strong> unblocked on any device with an internet connection. The unblocked version ensures you can enjoy the game without restrictions.</li>
    <li><strong>No Downloads Required:</strong> You don’t need to download any files or install additional software. Simply open your browser and start playing <strong>Bloons TD 6 Unblocked</strong> right away.</li>
    <li><strong>Cross-Platform Play:</strong> The unblocked version is accessible from various devices, including computers, tablets, and smartphones, allowing you to play on the go or during your breaks.</li>
    <li><strong>Unlimited Fun:</strong> Since the game is unblocked, there are no interruptions, allowing you to enjoy endless hours of strategy and action without worrying about restrictions or limitations.</li>
</ul>

<h2 class="H22">Tips for Mastering Bloons TD 6</h2>
<p>To truly excel in <strong>Bloons TD 6</strong>, you need to combine quick thinking with strategic planning. Here are some tips to help you become a Bloons TD 6 expert:</p>
<ul>
    <li><strong>Know Your Towers:</strong> Each tower has its own strengths and weaknesses, so it’s important to understand which towers work best against different types of bloons. For example, dart monkeys are great against basic bloons, but you may need a wizard monkey to deal with camo or lead bloons.</li>
    <li><strong>Upgrade Wisely:</strong> Don’t just focus on placing more towers. Upgrading your existing towers can be more effective. Try to plan ahead and strategically upgrade towers that will provide the most value in the long run.</li>
    <li><strong>Use Heroes Effectively:</strong> Heroes are incredibly powerful, but they require careful placement and management. Choose a hero that fits your overall strategy and make sure to level them up during the game.</li>
    <li><strong>Play in Co-op Mode:</strong> Co-op mode allows you to team up with friends and combine your strategies. Teaming up can make it easier to take down tough bloon waves, especially in advanced levels.</li>
    <li><strong>Know When to Use Powers:</strong> Bloons TD 6 features powers and abilities that can give you an edge in difficult situations. Use them sparingly, as they’re limited, but they can be game-changers when used at the right time.</li>
</ul>

<h2 class="H22">Why Players Love Bloons TD 6</h2>
<p>Players love <strong>Bloons TD 6</strong> because it combines strategic depth with engaging, action-packed gameplay. The variety of towers, heroes, and upgrades allows players to experiment with different strategies, while the constant challenge keeps the game exciting. Whether you're playing solo or with friends, Bloons TD 6 offers an immersive experience that’s perfect for both casual and hardcore gamers alike.</p>

<h2 class="H22">Play Bloons TD 6 Unblocked Now!</h2>
<p>If you’re ready to dive into the world of <strong>Bloons TD 6 Unblocked</strong>, get started today! Enjoy the thrill of popping bloons, upgrading your towers, and mastering each map without any restrictions. With its fun gameplay, strategic depth, and endless replay value, Bloons TD 6 is the perfect game for tower defense enthusiasts.</p>
`,
    tag: ["Survival","Strategy","Tower Defense","Arcade"],
    
    rating:[4.8,6103,2025],  
  },
  {
    date: "2024-11-14",
    brand: "GD",
    orientation: 0,
    title: "Rooftop Snipers - Play Rooftop Snipers Online",
    name: ["Rooftop Snipers", "rooftop-snipers"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/rooftop-snipers/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/rooftop-snipers/520x295.png",
    path: ["/game/rooftop-snipers", "https://playcutegames.com"],
    iframe: "https://html5.gamedistribution.com/c3a70ae98547407a92ebedca8b79fdfa/?gd_sdk_referrer_url=https://playcutegames.com/game/rooftop-snipers",
    Walkthrough: "https://www.youtube.com/embed/w1gw1-LDcRE",
    s_dis:"Engage in epic rooftop battles in Rooftop Snipers! Shoot, dodge, and knock your opponents off the edge in intense, physics-based duels.",
    l_dis:`
          <p><strong>Rooftop Snipers</strong> is an action-packed, competitive shooting game where two players face off on rooftops, armed with sniper rifles. The game’s simple controls, quirky physics, and fast-paced gameplay make it a thrilling experience. Whether you're playing against a friend or trying to beat your own high score, <strong>Rooftop Snipers</strong> offers plenty of fun. You can also play <strong>Rooftop Snipers Unblocked</strong> to enjoy the game anywhere without restrictions!</p>
    <h2 class="H22">What is Rooftop Snipers?</h2>
    <p><strong>Rooftop Snipers</strong> is a two-player game that challenges you to take down your opponent by shooting them off a rooftop. Each player controls a character with a sniper rifle, and the goal is to knock your opponent off the roof while avoiding getting hit yourself. The ragdoll physics, quirky controls, and dynamic environments add a layer of unpredictability and humor to every match. It’s easy to jump into but challenging to master, making it perfect for quick, fun gaming sessions.</p>
    <h2 class="H22">How to Play Rooftop Snipers</h2>
    <p>Playing <strong>Rooftop Snipers</strong> is simple, but mastering the game requires practice. Here’s a quick guide on how to get started:</p>
    <ul>
        <li><strong>Objective:</strong> The goal is to shoot your opponent off the rooftop. You need to be the last player standing by knocking the other player off the roof.</li>
        <li><strong>Controls:</strong> Each player uses a different set of controls. Player 1 moves with the <em>arrow keys</em> and shoots with the <em>spacebar</em>. Player 2 moves with the <em>W, A, S, D</em> keys and shoots with the <em>F</em> key. The shooting mechanics involve quick reflexes and aiming accurately at your opponent.</li>
        <li><strong>Ragdoll Physics:</strong> The ragdoll physics in <strong>Rooftop Snipers</strong> make the characters move in unpredictable ways when they are hit by bullets. This creates hilarious and often chaotic moments, where players may unexpectedly flip or fly off the rooftop!</li>
        <li><strong>Rounds:</strong> The game is played in rounds, with each round consisting of one short match. Players earn points for each round they win, and the first to reach a certain number of points wins the game.</li>
    </ul>
    <h2 class="H22">Why Play Rooftop Snipers Unblocked?</h2>
    <p><strong>Rooftop Snipers Unblocked</strong> allows you to play the game anywhere and anytime without facing any restrictions. Here's why the unblocked version is so popular:</p>
    <ul>
        <li><strong>Access Anywhere:</strong> Whether you're at school, work, or at home, you can play <strong>Rooftop Snipers</strong> on any device with an internet connection. The unblocked version removes any access barriers.</li>
        <li><strong>No Download Required:</strong> You don’t need to download anything to start playing. Just open your browser and begin playing <strong>Rooftop Snipers Unblocked</strong> instantly.</li>
        <li><strong>Perfect for Quick Sessions:</strong> The short rounds make <strong>Rooftop Snipers</strong> ideal for quick gaming sessions. You can play a few rounds during a break, making it a great choice for moments of downtime.</li>
    </ul>
    <h2 class="H22">Tips for Mastering Rooftop Snipers</h2>
    <p>To excel in <strong>Rooftop Snipers</strong>, you need a combination of timing, accuracy, and adaptability. Here are some tips to improve your gameplay:</p>
    <ul>
        <li><strong>Focus on Timing:</strong> Since the controls are simple, the key to success is timing your shots. Wait for the right moment to shoot and make sure you're lined up with your opponent before firing.</li>
        <li><strong>Use the Environment:</strong> Each rooftop has different obstacles and dynamics. Take advantage of the environment to protect yourself or to gain an advantage over your opponent.</li>
        <li><strong>Stay Calm:</strong> The ragdoll physics can be chaotic, so it’s important to stay calm and focused, even when things get unpredictable. Keep your cool, and you’ll have a better chance of winning.</li>
        <li><strong>Practice with a Friend:</strong> The best way to improve your skills is to play with a friend. Practicing with a real opponent helps you refine your strategy and get better at the game.</li>
    </ul>
    <h2 class="H22">Why Players Love Rooftop Snipers</h2>
    <p><strong>Rooftop Snipers</strong> is a fan-favorite because it combines fun, competitive gameplay with quirky physics and easy-to-learn controls. The unpredictability of the ragdoll movements, combined with the fast-paced shooting mechanics, makes each match entertaining. Whether you're playing solo or with a friend, the game offers endless laughter and excitement. The addition of <strong>Rooftop Snipers Unblocked</strong> makes it even better, allowing players to enjoy the game anytime, anywhere.</p>
    <h2 class="H22">Play Rooftop Snipers Unblocked Now!</h2>
    <p>If you're ready to dive into the fun, grab your sniper rifle and challenge a friend in <strong>Rooftop Snipers Unblocked</strong> today! The game is perfect for quick, action-packed matches, and with no restrictions, you can play wherever you are. Get ready for hilarious moments, intense competition, and plenty of ragdoll physics fun!</p>
`,
    tag: ["Survival","Ragdoll","Shooting","Action"],
    rating:[4.9,1233,1025],  
  },
  {
    date: "2024-11-14",
    brand: "GD",
    orientation: 0,
    title: "Bitlife - Play Bitlife Life Online",
    name: ["Bitlife", "bitlife"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bitlife/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bitlife/520x295.png",
    path: ["/game/bitlife", "https://playcutegames.com"],
    iframe: "https://html5.gamedistribution.com/ac6a950a44d144139dabe7837aa4aab9/?gd_sdk_referrer_url=https://playcutegames.com/game/bitlife",
    Walkthrough: "https://www.youtube.com/embed/-udnhMGfS-Q",
    s_dis:"Live out your virtual life in Bitlife! Make choices, build a career, and experience different paths in this life simulation game.",
    l_dis:`
         <p><strong>Bitlife</strong> is a popular life simulation game where you can experience the ups and downs of a virtual life. You control a character from birth to death, making important decisions along the way. Whether you're making career choices, forming relationships, or navigating tricky situations, Bitlife allows you to explore a multitude of life paths. Plus, with <strong>Bitlife unblocked</strong> or <strong>Bitlife online</strong>, you can enjoy the game without restrictions, anywhere and anytime.</p>

    <h2 class="H22">What is Bitlife?</h2>

    <p><strong>Bitlife</strong> is a life simulation game that allows you to live out a virtual life and make decisions that will affect your character’s destiny. You start the game as a newborn, and as you grow, you face different life stages where you make decisions about your education, relationships, career, health, and more.</p>

    <p>The game is text-based, and the decisions you make are presented in simple text form, allowing you to guide your character through various life events. From mundane daily choices to more significant life-altering decisions, Bitlife provides endless scenarios, each leading to different outcomes.</p>

    <p>As you progress, you unlock new opportunities and face random events that make each playthrough unique. Whether you aim for a perfect life or embrace chaos, Bitlife offers a world of endless possibilities to explore.</p>

    <h2 class="H22">How to Play Bitlife</h2>

    <p>Playing <strong>Bitlife</strong> is simple but engaging. Here’s a guide to get you started:</p>

    <ul>
        <li><strong>Starting Your Life:</strong> In Bitlife, you begin by choosing your character’s gender, name, and birthplace. From there, you progress through the different stages of life, from childhood to old age.</li>
        <li><strong>Making Choices:</strong> At each life stage, you’re presented with a variety of decisions. These choices could be related to education, relationships, career, health, finances, and more. Your choices will affect your character’s future.</li>
        <li><strong>Managing Stats:</strong> Your character has several stats to keep track of, including happiness, health, smarts, and looks. Balancing these stats while making the right decisions will help you achieve your goals.</li>
        <li><strong>Random Events:</strong> Throughout the game, your character will face unexpected events, such as accidents, inheritances, or random opportunities. These events can change the course of your virtual life.</li>
        <li><strong>End Game:</strong> You can play through your character’s entire life, and the game ends when they die. At that point, you get to see how well you did based on their achievements, happiness, and overall success.</li>
    </ul>

    <h2 class="H22">Why Play Bitlife Online?</h2>

    <p><strong>Bitlife online</strong> offers a seamless and enjoyable experience, especially if you prefer playing without any downloads. Here’s why playing Bitlife online is a great option:</p>

    <ul>
        <li><strong>No Download Required:</strong> You can start playing immediately in your browser. This means you can enjoy Bitlife on any device, whether it’s a computer, tablet, or smartphone, without having to install anything.</li>
        <li><strong>Play Anytime, Anywhere:</strong> Since Bitlife online is browser-based, you can access it from any location with an internet connection. Perfect for playing on breaks, during commutes, or whenever you have a few minutes to spare.</li>
        <li><strong>Easy to Access:</strong> The online version of Bitlife is always up-to-date, so you won’t miss out on new features, events, or improvements. It’s hassle-free and accessible right from your web browser.</li>
    </ul>

    <h2 class="H22">Bitlife Unblocked: Play Without Restrictions</h2>

    <p><strong>Bitlife unblocked</strong> is the perfect solution for players who want to access the game from environments where gaming sites are typically restricted. Whether it’s at school, work, or other places with strict internet access policies, you can enjoy the full Bitlife experience with no limitations.</p>

    <p>With <strong>Bitlife unblocked</strong>, you can live your virtual life without worrying about restrictions. Simply access the game online, and you're free to make decisions, explore new paths, and enjoy all the challenges that Bitlife has to offer. It’s the ultimate way to enjoy this addictive game, no matter where you are.</p>

    <h2 class="H22">Tips for Playing Bitlife</h2>

    <p>While Bitlife is a game of choices, there are strategies that can help you achieve the best outcomes. Here are some tips for mastering Bitlife:</p>

    <ul>
        <li><strong>Focus on Education:</strong> Early on, invest in your education to improve your character’s smarts. A well-educated character can secure higher-paying jobs and better opportunities later in life.</li>
        <li><strong>Maintain Health and Happiness:</strong> Keeping your character happy and healthy is key to living a successful life. Regular exercise, good relationships, and maintaining a healthy diet can positively affect your stats.</li>
        <li><strong>Be Strategic with Careers:</strong> Choosing the right career path can make a huge difference in your life. Aim for jobs with good salaries and benefits to build wealth and stability.</li>
        <li><strong>Make Smart Investments:</strong> Invest wisely in properties, businesses, and other assets to ensure financial security for your character’s future.</li>
        <li><strong>Balance Family and Work:</strong> Find the right balance between your character’s career and family life. Prioritize relationships to maintain happiness and avoid unnecessary stress.</li>
    </ul>

    <h2 class="H22">Why Bitlife Is So Popular</h2>

    <p><strong>Bitlife</strong> has gained popularity due to its open-ended gameplay, where players can live out any life they desire. The game’s simplicity allows players to immerse themselves in the experience, making it easy to jump into and play for hours. The randomness of events and the multitude of choices keep the game fresh, ensuring no two playthroughs are the same.</p>

    <p>Additionally, the ability to make both good and bad decisions gives players a sense of control and encourages experimentation. Whether you’re playing for fun or striving to achieve a specific goal, Bitlife offers a variety of ways to enjoy the game.</p>

    <h2 class="H22">Conclusion: Start Your Life Adventure in Bitlife</h2>

    <p><strong>Bitlife</strong> offers an engaging, thought-provoking experience where your choices determine your character’s fate. Whether you’re playing for fun or aiming to live your character’s best life, Bitlife provides endless entertainment. The accessibility of <strong>Bitlife unblocked</strong> and the ability to play <strong>Bitlife online</strong> means you can enjoy the game anytime, anywhere, with no restrictions.</p>

    <p>So why wait? Dive into the world of Bitlife, make your decisions, and see where your virtual life takes you. The possibilities are endless, and every new game is a new adventure!</p>
`,
       tag: ["Simulation","Rpg"],
    
    rating:[4.7,7655,4025],  
  },
  {
    date: "2024-11-09",
    brand: "Yandex",
    orientation: 0,
    title: "Ragdoll Archer - Play Ragdoll Archer Online",
    name: ["Ragdoll Archer", "ragdoll-archer"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ragdoll-archer/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ragdoll-archer/520x295.png",
    path: ["/game/ragdoll-archer", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/242252?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/lo9eX0T6bi4",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/ragdoll-archer.mp4",
    s_dis:"Master archery in Ragdoll Archer! Aim and shoot arrows, defeat enemies, and navigate ragdoll physics for hilarious and challenging gameplay.",
    l_dis:`
          <p><strong>Ragdoll Archer</strong> is an addictive archery-based game that combines skillful shooting mechanics with ragdoll physics. In this game, you control a ragdoll character equipped with a bow and arrows to shoot down opponents in various challenging scenarios. The fun lies in the unpredictable ragdoll movements and the increasing difficulty of the levels. Whether you're aiming to defeat enemies or complete various challenges, <strong>Ragdoll Archer</strong> provides an exciting and humorous experience. You can also enjoy the game with no restrictions by playing <strong>Ragdoll Archer Unblocked</strong>.</p>

    <h2 class="H22">What is Ragdoll Archer?</h2>

    <p><strong>Ragdoll Archer</strong> is a unique archery game where players control ragdoll characters with a bow and arrows, aiming to defeat enemies. The game features challenging levels with increasing difficulty, and the main objective is to hit targets or defeat enemies using your arrows. What makes the game stand out is the ragdoll physics, which creates fun and unpredictable character movements, adding a humorous and entertaining twist to traditional archery games.</p>

    <h2 class="H22">How to Play Ragdoll Archer</h2>

    <p>Playing <strong>Ragdoll Archer</strong> is easy to understand but difficult to master. Here’s how to get started:</p>

    <ul>
        <li><strong>Objective:</strong> The main goal of the game is to shoot arrows at the ragdoll enemies, knocking them down and completing each level. As you progress, new challenges and enemy types will appear.</li>
        <li><strong>Controls:</strong> You control your character using the mouse or keyboard. To aim, you move the mouse or use the keyboard arrows. To shoot, you pull back and release the arrow by clicking or pressing a key.</li>
        <li><strong>Ragdoll Physics:</strong> The ragdoll physics add a unique and funny element to the game, making your character’s movements unpredictable. When your arrow hits an enemy, they flop around in ragdoll fashion, adding an extra layer of humor and unpredictability.</li>
        <li><strong>Levels:</strong> As you progress through the levels, the difficulty increases. You'll face more enemies, tougher obstacles, and more complex environments that require quick reflexes and precision to overcome.</li>
    </ul>

    <h2 class="H22">Why Play Ragdoll Archer Unblocked?</h2>

    <p><strong>Ragdoll Archer Unblocked</strong> gives you the freedom to enjoy the game without any restrictions, allowing you to play whenever and wherever you want. Here’s why playing <strong>Ragdoll Archer Unblocked</strong> is a great choice:</p>

    <ul>
        <li><strong>No Download Required:</strong> Play instantly in your browser without needing to download anything or install software. Just click and start playing!</li>
        <li><strong>Accessible Anywhere:</strong> Whether you're on your laptop, smartphone, or tablet, <strong>Ragdoll Archer Unblocked</strong> works seamlessly on all devices, allowing you to enjoy the game anytime and anywhere.</li>
        <li><strong>Perfect for Quick Sessions:</strong> With short, fun levels and easy-to-pick-up gameplay, you can dive into <strong>Ragdoll Archer</strong> for a few minutes of entertainment during breaks, commutes, or whenever you need a quick gaming session.</li>
    </ul>

    <h2 class="H22">Tips for Mastering Ragdoll Archer</h2>

    <p>To excel in <strong>Ragdoll Archer</strong>, you need to combine precision, timing, and strategic planning. Here are some tips to help you become a master archer:</p>

    <ul>
        <li><strong>Master the Controls:</strong> Practice using the mouse or keyboard to aim and shoot. The more accurate your aim, the better your chances of hitting targets and defeating enemies.</li>
        <li><strong>Learn the Ragdoll Physics:</strong> Understanding how the ragdoll character moves will help you anticipate how the arrow will affect your enemies. The chaotic physics add an unpredictable element to the game, so embrace the fun!</li>
        <li><strong>Use Obstacles to Your Advantage:</strong> Many levels include obstacles or cover that you can use to block enemy shots. Take advantage of these elements to protect yourself and gain an edge over your opponents.</li>
        <li><strong>Upgrade Your Skills:</strong> As you progress through the game, you can improve your shooting skills. Keep practicing to unlock more challenging levels and increase your accuracy and speed.</li>
    </ul>

    <h2 class="H22">Why Players Love Ragdoll Archer</h2>

    <p><strong>Ragdoll Archer</strong> is a game that blends strategy, humor, and action. The ragdoll physics bring a sense of unpredictability and fun to the gameplay, while the challenges keep players engaged and coming back for more. Whether you're playing to beat your high score or simply enjoy the hilarious ragdoll reactions, this game has something for everyone. It’s the perfect mix of action and comedy that appeals to both casual players and those looking for a fun challenge.</p>

    <h2 class="H22">Play Ragdoll Archer Unblocked Now!</h2>

    <p>Ready to test your archery skills and laugh at the ragdoll physics? With <strong>Ragdoll Archer Unblocked</strong>, you can play this addictive and humorous game anytime, without restrictions. Whether you're playing for fun or aiming to beat your personal best, <strong>Ragdoll Archer</strong> offers endless entertainment. Grab your bow, aim carefully, and start shooting your way through exciting and challenging levels!</p>

`,
       tag: ["Ragdoll","Multiplayer","Sports"],
    
    rating:[4.6,4103,1025],  
  },
  {
    date: "2024-11-09",
    brand: "Yandex",
    orientation: 0,
    title: "Volley Random - Play Volley Random Online",
    name: ["Volley Random", "volley-random"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/volley-random/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/volley-random/520x295.png",
    path: ["/game/volley-random", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/197307?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/Jy2pKdeN3E0",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/volley-random.mp4",
    s_dis:"Enjoy chaotic volleyball action in Volley Random! Control quirky characters, score points, and have fun with unpredictable physics in every match!",
    l_dis:`
         <p><strong>Volley Random</strong> is an entertaining physics-based volleyball game that brings a humorous twist to traditional volleyball gameplay. With unpredictable movements, quirky animations, and simple controls, <em>Volley Random</em> is perfect for quick play sessions and multiplayer fun. Play <strong>Volley Random Unblocked</strong> to enjoy unrestricted access on any device, making it a great way to enjoy volleyball action wherever you are.</p>
    <h2 class="H22">What is Volley Random?</h2>
    <p><strong>Volley Random</strong> is an arcade-style volleyball game where players face off in chaotic volleyball matches with physics-based controls and random elements. Created by RHM Interactive, <em>Volley Random</em> features unique mechanics that make each match surprising and fun. With quirky character movements, unpredictable jumps, and constantly changing backgrounds, each round offers a fresh challenge that keeps players engaged and laughing.</p>
    <h2 class="H22">How to Play Volley Random</h2>
    <p>The controls in <strong>Volley Random</strong> are designed to be simple and accessible, making it easy for players of all skill levels to enjoy:</p>
    <ul>
        <li><strong>Controls:</strong> Each player has a single button to control their character's movements, which includes jumping and attempting to hit the ball. Timing is essential as the game’s physics can make each action unpredictable.</li>
        <li><strong>Objective:</strong> Score points by hitting the volleyball over the net and making it land in your opponent’s court. The first player to reach the target score wins the match.</li>
        <li><strong>Random Elements:</strong> Each round introduces different types of balls, backgrounds, and sometimes even varying player sizes, adding randomness and humor to the game.</li>
        <li><strong>Two-Player Mode:</strong> Play against a friend in two-player mode, where each player has their own button, or play against the computer for a single-player experience.</li>
    </ul>
    <h2 class="H22">Why Play Volley Random Unblocked?</h2>
    <p><strong>Volley Random Unblocked</strong> allows you to enjoy the game from any location without restrictions, making it easily accessible for everyone. Here’s why <em>Volley Random Unblocked</em> has become a popular choice:</p>
    <ul>
        <li><strong>No Download Needed:</strong> Start playing instantly in your browser without the need for downloads or installations.</li>
        <li><strong>Accessible Across Devices:</strong> Enjoy <em>Volley Random</em> on computers, tablets, or smartphones for a seamless experience on any device.</li>
        <li><strong>Perfect for Quick Play:</strong> With simple controls and quick rounds, <em>Volley Random</em> is ideal for short gaming sessions whenever you want a break.</li>
    </ul>
    <h2 class="H22">Tips for Winning in Volley Random</h2>
    <p>While <em>Volley Random</em> may look chaotic, there are a few tips to help you improve your chances of winning:</p>
    <ul>
        <li><strong>Focus on Timing:</strong> Since you only have one button for control, timing each jump and hit is essential. Aim to hit the ball at the right moment to send it over the net.</li>
        <li><strong>Adapt to Each Round:</strong> With the game’s random elements, such as changing ball types and player sizes, adjust your strategy for each new round.</li>
        <li><strong>Stay Close to the Net:</strong> Staying near the net can help you react quickly to incoming shots, but be cautious of high, bouncing shots.</li>
        <li><strong>Practice with Two-Player Mode:</strong> Playing with a friend can improve your timing and control, making you better prepared for the single-player mode.</li>
    </ul>
    <h2 class="H22">Why Players Love Volley Random</h2>
    <p>Fans of <strong>Volley Random</strong> enjoy the game’s mix of humor, skill, and unpredictability. The combination of simple controls and random elements makes each match unique, resulting in plenty of laughs and surprising moments. Whether playing solo or with a friend, <em>Volley Random</em> delivers lighthearted fun that keeps players coming back for more.</p>
    <h2 class="H22">Play Volley Random Unblocked Anytime!</h2>
    <p>Ready to experience the fun of <strong>Volley Random</strong>? With <strong>Volley Random Unblocked</strong>, you can jump into this exciting volleyball game from anywhere, without any restrictions. Perfect for casual gamers looking for a quick, fun challenge or for those who want to play with friends, <em>Volley Random</em> provides a fresh take on volleyball that you won’t want to miss. Get in the game and see if you can outsmart the unpredictable physics of <em>Volley Random</em>!</p>
`,
       tag: ["Ragdoll","Multiplayer","Sports"],
    
    rating:[4.5,1723,655],  
  }, 
  {
    date: "2024-11-09",
    brand: "Yandex",
    orientation: 0,
    title: "Boxing Random - Play Boxing Random Online",
    name: ["Boxing Random", "boxing-random"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/boxing-random/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/boxing-random/520x295.png",
    path: ["/game/boxing-random", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/197305?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/qqYMmHFiC9g",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/boxing-random.mp4",
    s_dis:"Step into chaotic boxing fun with Boxing Random! Control quirky fighters, land punches, and win unpredictable matches with wild physics!",
    l_dis:`
         <p><strong>Boxing Random</strong> is an entertaining, physics-based arcade game that brings a comical twist to traditional boxing. With unpredictable moves, unique obstacles, and amusing animations, <em>Boxing Random</em> challenges you to outsmart your opponent in fast-paced matches. For those looking for unrestricted play, <strong>Boxing Random Unblocked</strong> lets you enjoy the game from any location without limitations.</p>

    <h2 class="H22">What is Boxing Random?</h2>

    <p><strong>Boxing Random</strong> is a quirky two-player boxing game created by RHM Interactive. Unlike standard boxing games, <em>Boxing Random</em> introduces unusual physics and random elements that make every match a unique experience. Each round changes with new obstacles, equipment, and environmental factors that can either help or hinder your strategy. With each character's unpredictable movements, every punch, dodge, and block becomes a comedic challenge that keeps players coming back for more.</p>

    <h2 class="H22">How to Play Boxing Random</h2>

    <p>Playing <strong>Boxing Random</strong> is simple and designed to be accessible for players of all ages:</p>

    <ul>
        <li><strong>Controls:</strong> Each player has one button to control their character’s movements, including jumping and punching. Timing is key, as each action relies on well-timed button presses.</li>
        <li><strong>Objective:</strong> Knock out your opponent to win the round. The first player to win five rounds wins the game.</li>
        <li><strong>Random Gameplay Elements:</strong> Each round introduces different environments, obstacles, and even unusual equipment that can change the gameplay dynamics. Some rounds may feature moving platforms or oversized boxing gloves, adding an extra layer of chaos.</li>
        <li><strong>Multiplayer Mode:</strong> You can play against the computer or challenge a friend in two-player mode for a more competitive experience.</li>
    </ul>

    <h2 class="H22">Why Play Boxing Random Unblocked?</h2>

    <p>The unblocked version of <strong>Boxing Random</strong> allows players to access the game without restrictions, making it available anytime, anywhere. Here’s why <em>Boxing Random Unblocked</em> is so popular:</p>

    <ul>
        <li><strong>No Downloads Needed:</strong> Play instantly in your browser without needing to install any files or software.</li>
        <li><strong>Accessible on Multiple Devices:</strong> Whether on a computer, tablet, or smartphone, <em>Boxing Random Unblocked</em> works seamlessly across platforms.</li>
        <li><strong>Great for Quick Play Sessions:</strong> With its easy-to-learn controls and fast-paced rounds, <em>Boxing Random</em> is perfect for a quick break or short gaming session.</li>
    </ul>

    <h2 class="H22">Tips for Winning in Boxing Random</h2>

    <p>Though <em>Boxing Random</em> may appear chaotic, some strategies can help you win more rounds. Here are some tips:</p>

    <ul>
        <li><strong>Time Your Moves Carefully:</strong> Since you only have one button, timing each jump and punch is essential. Pressing too early or too late can leave you vulnerable to your opponent’s attack.</li>
        <li><strong>Adapt to Changing Rounds:</strong> Each round introduces new obstacles and conditions, so stay alert. Adjust your strategy based on the environment and equipment in play.</li>
        <li><strong>Stay Close to Your Opponent:</strong> Being close can make it easier to land punches, but be careful not to get knocked out in the process!</li>
        <li><strong>Use Two-Player Mode for Practice:</strong> Practicing with a friend can improve your reaction time and help you master the game’s controls.</li>
    </ul>

    <h2 class="H22">Why Players Love Boxing Random</h2>

    <p>Fans of <strong>Boxing Random</strong> appreciate its blend of skill and humor. The game’s unpredictable physics, random elements, and quirky animation make each match exciting and laugh-inducing. It’s a casual game that doesn’t take itself too seriously, which is why players enjoy it for quick sessions and competitive fun with friends.</p>

    <h2 class="H22">Play Boxing Random Unblocked Today!</h2>

    <p>Ready to experience the chaotic fun of <strong>Boxing Random</strong>? With <strong>Boxing Random Unblocked</strong>, you can dive into this hilarious boxing game from anywhere, with no restrictions. Perfect for gamers looking for a fun challenge or a way to pass the time, <em>Boxing Random</em> promises action-packed, laughter-filled rounds that will keep you coming back for more. Jump into the ring and see if you have what it takes to become the ultimate boxing champion!</p>
`,
       tag: ["Boxing","Ragdoll","Multiplayer","Sports"],
    
    rating:[4.5,1723,655],  
  }, 
  {
    date: "2024-11-09",
    brand: "Others",
    orientation: 0,
    title: "Run 3 - Play Run 3 Online",
    name: ["Run 3", "run-3"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/run-3/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/run-3/520x295.png",
    path: ["/game/run-3", "https://playcutegames.com"],
    iframe: "https://linh879.github.io/run333/tn6pS9dCf37xAhkJv/",
    Walkthrough: "https://www.youtube.com/embed/kibE48yZMa0",
    s_dis:"Dash through space in Run 3! Jump, dodge, and explore endless tunnels filled with challenges in this fast-paced, addictive game.",
    l_dis:`
         <p><strong>Run 3</strong> is a wildly popular endless runner game that transports players into outer space, challenging them to navigate complex tunnels filled with obstacles. Known for its simple controls, intuitive gameplay, and addictive nature, <em>Run 3</em> appeals to players of all ages. If you're looking to play without restrictions, <strong>Run 3 Unblocked</strong> is a great option, letting you enjoy the game anywhere and anytime.</p>

    <h2 class="H22">What is Run 3?</h2>

    <p><strong>Run 3</strong> is the third installment in the "Run" series, developed by Joseph Cloutier. Unlike traditional endless runners, <em>Run 3</em> takes place in a 3D space tunnel, with each level introducing new challenges, twists, and obstacles. Players control a small alien character running through an intricate tunnel in space, dodging gaps and blocks that require quick thinking and precise timing. With each level's progression, tunnels get increasingly complex, providing a fresh experience in every round.</p>

    <h2 class="H22">How to Play Run 3</h2>

    <p>Playing <strong>Run 3</strong> is straightforward but requires skill and focus as you advance:</p>

    <ul>
        <li><strong>Controls:</strong> Use the arrow keys to move left, right, and jump. Your character automatically runs forward, so your job is to guide them past obstacles without falling into the void.</li>
        <li><strong>Objective:</strong> Navigate through tunnels by jumping over gaps and avoiding falling off. The further you go, the higher your score.</li>
        <li><strong>Levels and Modes:</strong> <em>Run 3</em> offers several modes and levels, each presenting unique challenges. The "Explore Mode" lets you move through levels, encountering different tunnel designs and obstacles, while "Infinite Mode" lets you test your endurance in a never-ending tunnel.</li>
        <li><strong>Characters:</strong> <em>Run 3</em> includes a variety of characters, each with distinct abilities. Unlock new characters as you progress, which can give you strategic advantages.</li>
    </ul>

    <h2 class="H22">Why Play Run 3 Unblocked?</h2>

    <p>For players who want unrestricted access to the game, <strong>Run 3 Unblocked</strong> is the solution. The unblocked version allows you to play from any device, whether at school, work, or on shared networks that may otherwise limit access.</p>

    <ul>
        <li><strong>Accessible Anywhere:</strong> <em>Run 3 Unblocked</em> can be played directly in your browser without needing downloads or installations.</li>
        <li><strong>Quick Start:</strong> The game loads quickly, making it ideal for short breaks or quick gaming sessions.</li>
        <li><strong>Cross-Device Compatibility:</strong> <em>Run 3 Unblocked</em> runs seamlessly across laptops, desktops, tablets, and mobile devices, letting you enjoy the game whenever and wherever you like.</li>
    </ul>

    <h2 class="H22">Tips for Mastering Run 3</h2>

    <p><em>Run 3</em> combines skill with strategy, and here are a few tips to help you navigate those challenging tunnels:</p>

    <ul>
        <li><strong>Stay Centered:</strong> While it may be tempting to move quickly, keeping your character centered allows you to react faster to unexpected obstacles.</li>
        <li><strong>Mind the Gaps:</strong> Watch for gaps in the tunnel floor. Jumping early can save you from falling into the abyss.</li>
        <li><strong>Use Gravity to Your Advantage:</strong> In <em>Run 3</em>, you can switch walls by moving left or right as you fall, which allows for creative navigation around tricky obstacles.</li>
        <li><strong>Experiment with Characters:</strong> Each character in <em>Run 3</em> has special abilities. For example, the "Skater" is faster but harder to control, while the "Lizard" can climb walls.</li>
        <li><strong>Play Explore Mode First:</strong> Playing through the levels in Explore Mode helps you learn the tunnel layouts, which can improve your performance in Infinite Mode.</li>
    </ul>

    <h2 class="H22">Why Players Love Run 3</h2>

    <p><em>Run 3</em> combines simplicity with depth. The game is easy to pick up but challenging enough to keep players coming back for more. The changing tunnel designs, increasing difficulty, and different characters add layers of strategy and excitement, making <em>Run 3</em> a favorite for both new and seasoned players.</p>

    <h2 class="H22">Start Playing Run 3 Unblocked Today!</h2>

    <p>Ready to dive into an endless runner adventure like no other? With <strong>Run 3 Unblocked</strong>, you can enjoy the thrill of this addictive game anytime, anywhere. Perfect for quick gaming sessions or extended play, <em>Run 3</em> provides a unique challenge in a fun, cosmic setting. Whether you're navigating tunnels in Explore Mode or pushing your limits in Infinite Mode, <strong>Run 3</strong> is a game that keeps you on your toes.</p>
`,
       tag: ["Platformer","Running","Arcade","Skill"],
    
    rating:[4.8,4350,721],  
  },
  {
    date: "2024-11-09",
    brand: "Yandex",
    orientation: 0,
    title: "Soccer Random - Play Soccer Random Online",
    name: ["Soccer Random", "soccer-random"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/soccer-random/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/soccer-random/520x295.png",
    path: ["/game/soccer-random", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/197306?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/1sJF563coHY",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/soccer-random.mp4",
    s_dis:"Kick your way to victory in Soccer Random! Control quirky characters, score goals, and enjoy chaotic matches with random physics!",
    l_dis:`
        <p><strong>Soccer Random</strong> takes the traditional soccer game to a whole new level. With its quirky mechanics and simple controls, this online game brings a mix of fun and chaos that is perfect for both casual players and soccer enthusiasts. Plus, with <strong>Soccer Random Unblocked</strong>, you can play anytime, anywhere, straight from your browser without any downloads.</p>
        <h2 class="H22">Why Soccer Random Is So Addictive</h2>
        <p>Unlike conventional soccer games, <strong>Soccer Random</strong> brings a refreshing twist to the gameplay. Here's what makes it stand out:</p>
        <ul>
            <li><strong>Unpredictable Gameplay:</strong> Every match in Soccer Random is different. The game introduces random physical changes and elements, from bizarre player movements to unexpected obstacles, ensuring that no two games are alike.</li>
            <li><strong>Easy-to-Use Controls:</strong> The game uses simple one-touch controls, which makes it easy for anyone to pick up and play. However, there’s still room to hone your skills and make strategic plays.</li>
            <li><strong>Multiplayer Mode:</strong> You can enjoy the game solo against the computer or team up with a friend in a two-player match. Competing against another player adds excitement and friendly rivalry to the game.</li>
            <li><strong>Varying Environments:</strong> The game introduces different themes and backgrounds with every round. This constant change adds to the fun and keeps you on your toes.</li>
        </ul>
        <h2 class="H22">How to Play Soccer Random</h2>
        <p>The simplicity of <strong>Soccer Random</strong> makes it accessible and fun. Here’s how to get started:</p>
        <ul>
            <li><strong>Objective:</strong> Like any soccer game, the goal is to score more points than your opponent by getting the ball into their net.</li>
            <li><strong>Controls:</strong> Use just one button to control your player. This button will make your player jump and attempt to hit the ball. The catch? The unpredictable physics of the game make it a challenge to master, and timing your jumps is key.</li>
            <li><strong>Multiplayer Fun:</strong> In the two-player mode, each player has their own button to control their character. This allows for competitive gameplay where timing and quick reflexes are crucial.</li>
            <li><strong>Random Changes:</strong> Each round brings new challenges, whether it’s changing the environment, altering player dynamics, or introducing random obstacles. This keeps the gameplay fresh and exciting.</li>
        </ul>
        <h2 class="H22">Soccer Random Unblocked: Freedom to Play Anywhere</h2>
        <p>With <strong>Soccer Random Unblocked</strong>, you don’t have to worry about access restrictions. Here’s why the unblocked version is a game-changer:</p>
        <ul>
            <li><strong>Instant Access:</strong> No need to download anything. Simply play directly in your browser and jump straight into the action.</li>
            <li><strong>Play on Any Device:</strong> Whether you’re on a laptop, tablet, or smartphone, <strong>Soccer Random Unblocked</strong> works smoothly across all devices.</li>
            <li><strong>Great for Short Sessions:</strong> The quick rounds and easy-to-learn controls make it perfect for short gaming breaks or longer play sessions when you’re looking for some casual fun.</li>
        </ul>
        <h2 class="H22">Pro Tips for Mastering Soccer Random</h2>
        <p>Mastering <strong>Soccer Random</strong> requires more than just timing your jumps. Here are some tips to help you level up:</p>
        <ul>
            <li><strong>Focus on the Ball:</strong> The controls are simple, so the key to success is watching the ball carefully. Anticipate its movement and plan your jumps accordingly.</li>
            <li><strong>Adapt Quickly:</strong> Each round brings new challenges, so be ready to change your strategy based on the new obstacles or environments.</li>
            <li><strong>Play with Friends:</strong> Two-player mode is a great way to practice your timing and learn new techniques that will improve your single-player performance.</li>
            <li><strong>Embrace the Chaos:</strong> The random elements in the game are part of the fun. Stay relaxed and enjoy the wild moments that come with the unpredictability.</li>
        </ul>
        <h2 class="H22">What Makes Soccer Random So Fun?</h2>
        <p><strong>Soccer Random</strong> perfectly combines the thrill of soccer with wacky, physics-driven mechanics. Its quirky controls and unexpected events make each game exciting and different. Whether you’re playing solo or with friends, the game’s unpredictability and humor create a fun experience that you’ll want to repeat over and over again.</p>
        <h2 class="H22">Play Soccer Random Unblocked Now</h2>
        <p>Thanks to <strong>Soccer Random Unblocked</strong>, you can play this wild and funny soccer game anywhere and at any time. With no downloads or restrictions, you can jump into the action and enjoy quick, fun matches whether you’re at school, work, or at home. Perfect for any soccer fan or anyone looking for a quick gaming fix, <strong>Soccer Random</strong> is sure to entertain.</p>
    `,
       tag: ["Soccer","Ragdoll","Multiplayer","Sports"],
    
    rating:[4.9,2170,560],  
  },  
  
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Zumbly - Free Online Ball Shooting Game",
    name: ["Zumbly", "zumbly"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/zumbly/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/zumbly/520x295.png",
    path: ["/game/zumbly", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/225402?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/zumbly.mp4",
    s_dis:"Play Zumbly, the ultimate ball-shooting game with 1000 levels! Match and pop balls, enjoy exciting power-ups, and conquer challenging puzzles.",
    l_dis:`
       Get ready to dive into the world of Zumbly, the captivating game of shooting balls, featuring 1000 levels! 
       With its simple and intuitive gameplay, Zumbly is easy to learn, but challenging to master. 
       Your goal is to match and pop balls of the same color to clear the path and advance to the next level. 
       With exciting power-ups and bonus features, Zumbly will keep you entertained for hours. 
       So, join Zumbly now and become the ultimate ball shooter!`,
       tag: ["Zuma","Merge","Bubble shooter",],
    hint: `
       1️⃣ Aim and shoot the colored balls to match 3 or more of the same color.
       2️⃣ Clear the path to advance to the next level.
       3️⃣ Use power-ups and bonuses to help you along the way.
       4️⃣ Be strategic and plan your moves carefully to achieve the highest score.
       5️⃣ Don't let the balls reach the end of the path, or it's game over!
       6️⃣ Have fun and enjoy the Zumbly!`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Zumbla classic - playcutegames.com",
    name: ["Zumbla classic", "zumbla-classic"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/zumbla-classic/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/zumbla-classic/520x295.png",
    path: ["/game/zumbla-classic", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/205296?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/zumbla-classic.mp4",
    s_dis:"Zumbla classic Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       🍓 - Start easy and increase difficulty;
       🍍 - Freeze stone, Lighting stone, Bomb stone and many interesting props waiting for your exploration in shooting marbles;
       🍌 - Beautiful graphics;
       🍒 - More than 1400 levels.
       Play a quick game, challenge yourself with hundreds levels and become the king of marble shooting. 
       Your target is to eliminate all the marbles, but don’t let the chain reach the end.`,
       tag: ["Zuma","Merge","Bubble shooter",],
    hint: `
       1. Touch the screen where you want to shoot. 🎉
       2. Match 3 or more same color marbles to eliminate them. 💥
       3. Make more Combos and Chains to get the higher score. 🔥`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Zoo Boom - playcutegames.com",
    name: ["Zoo Boom", "zoo-boom"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/zoo-boom/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/zoo-boom/520x295.png",
    path: ["/game/zoo-boom", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/165392?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/zoo-boom.mp4",
    s_dis:"Zoo Boom Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       Match cute animal cubes in this colorful puzzle game! Tap at least two animals of the same color to collect them for your zoo and complete all tasks to progress. 
       Try to match multiple creatures of the same type to create special boosters and combine them for huge boom effects. 
       The further you progress, the more tricky the challenges will get.
       In case you are stuck, you can buy additional boosters in the shop - they will surely come in handy! Can you finish all levels with 3 stars?`,
       tag: ["Merge","Blocks",],
    hint: `
       Match multiple animals of the same type to create special boosters and combine them for huge boom effects. 
       Activate power ups to ease your progress and use them for even greater boom effects.`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 0,
    title: "Worms Zone - playcutegames.com",
    name: ["Worms Zone", "worms-zone"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/worms-zone/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/worms-zone/520x295.png",
    path: ["/game/worms-zone", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/96980?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/worms-zone.mp4",
    s_dis:"Worms Zone Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       Worms Zone a Slithery Snake is a game with a dynamic storyline. Start growing your worm right now. Having tried to get a real anaconda, a small worm never gets stuck in one place – he’s ready to bite everyone. However, there is a danger to be eaten by a more successful player.
       It is a very simple game: you have to grow your worm. To do that cut off one of the other worms out in front of you, you need to blow it up and eat the leftovers. Of course, while you’re at it have to crawl like a worm given as the name of the game.
       The Worms are real gourmets. They love trying various gelatinous goodies and everything they meet on their route.
       There are so many yummies around, that it seems the Worm lives in the arena full of yummy eatings. Therefore, the agile slither Worm grows much faster than in other relative games. So, getting to the leaderboard is possible within the first minutes of playing.`,
       tag: ["Snake","Slither","Survival",],
    hint: `
       Control gestures on your mobile device or the mouse on your computer.
       Collect sweets in the arena, collect bonuses and surround the enemy.`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 0,
    title: "Worm Hunt - playcutegames.com",
    name: ["Worm Hunt", "worm-hunt"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/worm-hunt/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/worm-hunt/520x295.png",
    path: ["/game/worm-hunt", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/192671?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/worm-hunt.mp4",
    s_dis:"Worm Hunt Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       Worm Hunt is a simple and fun game for all ages! Collect treats, become the biggest and dodge other players!
       In the game, you will have your own worm farm, where you can unlock and upgrade new worms to become #1! During special events, you can get exclusive worm skins just for completing tasks!
       Also don't forget to get Daily Reward in the game, as well as complete tasks to unlock worms and make them faster and stronger! 
       Because the game has Local and Global leaderboards, where you can follow your own records within your country and challenge players from all over the world!`,
       tag: ["Snake","Slither","Survival",],
    hint: `
       Choose a game mode right now and become the biggest worm in the arena!
       • Timed Mode! Other worms will be waiting for you in the arena, which you will have to defeat in the allotted 5 minutes. Be the last in the arena and prove that you are the best hunter;
       • In Endless mode the task is very simple! Eat all the treats in your path and get as big as possible to become #1;
       • Gems Fever…that says it all! A mode where you can find a lot of valuable gems to upgrade your favorite worms.
       On the arena you will find various bonuses that will help you with worm right during the game. But don't forget about other players who are also hunting for them! You can also improve your favorite worms so that bonuses during the game work much longer and that will bring you more points! And before each battle, you can also choose different boosters for your worm!
       It's time to go on an adventure! We’re sure that you with worm will manage to become real champions of Worm Hunt! Have a good hunt!`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Words of Wonders - playcutegames.com",
    name: ["Words of Wonders", "words-of-wonders"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/words-of-wonders/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/words-of-wonders/520x295.png",
    path: ["/game/words-of-wonders", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/218560?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/words-of-wonders.mp4",
    s_dis:"Words of Wonders Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       Get ready to put your vocabulary skills to the test in this addictively challenging crossword puzzle game. 
       With over 100000 levels to solve, each with a unique set of letters to connect, you'll never run out of brain-teasing fun. 
       But don't worry if you get stuck, you can always use hints to reveal random letters or hammer power-ups to reveal a letter of your choosing. 
       As you progress through the levels, you'll also have the chance to collect different pictures of the world wonders. 
       From the Great Wall of China to the Colosseum, you'll have the opportunity to learn about some of the most iconic landmarks in the world while improving your crossword-solving skills. 
       With its user-friendly interface and addictive gameplay, Words of Wonders is the perfect game for anyone looking to challenge their minds and expand their knowledge. 
       So what are you waiting for? Play Words of Wonders now and start your journey to becoming a crossword puzzle master!`,
       tag: ["Wordle","Words","Crossword",],
    hint: `
       Each level of Words of Wonders presents you with a set of letters that you must connect to form words that fit in the crossword puzzle. 
       If you get stuck, you can use hints to reveal random letters in the crossword puzzle. Keep in mind that you have a limited number of hints, so use them wisely. 
       You can also use hammer power-ups to reveal a letter of your choosing in the puzzle. Hammer power-ups can be earned by completing certain levels or purchased with in-game gems.
       With over 100000 levels to play, you'll have plenty of brain-teasing fun to keep you occupied for hours. 
       Each level gets progressively more challenging, so be prepared to put your thinking cap on! Words of Wonders is a great way to improve your vocabulary skills while having fun. 
       If you come across a word you don't know, look it up in a dictionary and add it to your repertoire.`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Words from Words - playcutegames.com",
    name: ["Words from Words", "words-from-words"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/words-from-words/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/words-from-words/520x295.png",
    path: ["/game/words-from-words", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/99195?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/words-from-words.mp4",
    s_dis:"Words from Words Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       "Words from Words" is an exciting game, the purpose of which is to create as many different words as possible from the letters given to you. 
       You can create 29 words from the word «example», «maverick» includes 71 words, and "chalkboard" stores as many as 142 words. Can you solve everything?`,
       tag: ["Wordle","Words","Crossword",],
    hint: `
       Click on the letters and create different words. The more words, the better!
       You can create only common nouns. Click on a word to get its definition!`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Words Crosswords - playcutegames.com",
    name: ["Words Crosswords", "words-crosswords"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/words-crosswords/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/words-crosswords/520x295.png",
    path: ["/game/words-crosswords", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/275307?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/words-crosswords.mp4",
    s_dis:"Words Crosswords Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       An exciting game about words from words. Find and form words from the given letters, and guess the hidden ones in the crossword.
       Welcoe to the Words Crosswords game - a captivating puzzle where you can enjoy a sea of words and interesting crosswords. Form words from letters, uncover new words from words, and immerse yourself in the fascinating world where words turn into wonders. Join us now and dive into the world of word games where you can find and create the most amazing games!
       Advanages of the word game:
       1. Ovr 8000 levels to dive into the sea of words
       2. Vaiety of crosswords and word puzzles for mental challenge
       3. Siple rules for a comfortable game
       4. Oportunity to compete with friends on the leaderboard
       5. Daly bonuses and gifts
       6. Reular updates and addition of new levels for endless entertainment
       A seaof engaging levels awaits you. Find all the words!`,
       tag: ["Wordle","Words","Crossword",],
    hint: `
       Playing and guessing words from letters is very simple:
       Look t the crossword and try to find words in it by forming them from the letters at the bottom of the screen.
       Combie the letters in the circle at the bottom by swiping your finger on mobile devices.
       On a omputer, click the first letter in the circle at the bottom, and without releasing the mouse button, move the mouse towards the next letter of the new word.`,
    rating:[4.4,1210,114],  
    },
    
    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 0,
    title: "Words and Mahjong - playcutegames.com",
    name: ["Words and Mahjong", "words-and-mahjong"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/words-and-mahjong/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/words-and-mahjong/520x295.png",
    path: ["/game/words-and-mahjong", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/191018?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/words-and-mahjong.mp4",
    s_dis:"Words and Mahjong Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       This game is an updated version of Mahjong. It combines principles of word games and rules of oriental Solitaire.
       Find a couple of tiles that form a whole word and remove them from the board.
       Keep your brain sharp while having fun with this puzzle game!
       The gameplay is slow like oriental meditation. 
       You are free to progress through the game levels at your own pace. 
       There are no time limits of any kind. 
       The game saves your progress automatically: you may close the browser window, reopen it again the next day and pick up where you left off.
       Please, use "hints" if necessary. They will help you if the current difficulty level is too high.`,
       tag: ["Mahjong","Words",],
    hint: `
       The game is played on a board, covered with overlapping tiles. 
       Each turn the player forms a word using two open tiles. 
       The list of game words includes nouns in the singular or plural forms.
       A tile is said to be open if it doesn't have adjacent tiles from above, left or right. 
       If the found word is valid, a pair of related tiles is removed from the playing board. 
       The final goal is to remove all tiles and clear the board.`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Wooden bolts and nuts - playcutegames.com",
    name: ["Wooden bolts and nuts", "wooden-bolts-and-nuts"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/wooden-bolts-and-nuts/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/wooden-bolts-and-nuts/520x295.png",
    path: ["/game/wooden-bolts-and-nuts", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/201834?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/wooden-bolts-and-nuts.mp4",
    s_dis:"Wooden bolts and nuts Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       As you play, you'll find tangled screws, nuts, bolts, plates, rings, ropes, and more. 
       Find out how to solve the riddle. 
       It's not just a game, it's a journey through the many fun nuts and bolts of the game.`,
       tag: ["Nuts and bolts","logic",],
    hint: `
       The goal of the game is to unscrew bolts and transfer them to empty cells! 
       Unscrew all the bolts from the planks so that they fall down. 
       Then you will pass the level`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "WoodBlocks - playcutegames.com",
    name: ["WoodBlocks", "woodblocks"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/woodblocks/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/woodblocks/520x295.png",
    path: ["/game/woodblocks", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/97277?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/woodblocks.mp4",
    s_dis:"WoodBlocks Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       A puzzle game in which you need to fill a 10x10 field with shapes. 
       Be careful and choose the right moves. Good game!`,
       tag: ["Blocks","Merge","Tetris",],
    hint: `
       The field size is 10x10, and you can freely place assigned blocks in it. 
       Once the line is successfully formed, it will disappear. 
       Place them in the correct positions so you can continue to form lines. 
       What's your best result? Have some fun!`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Wood Cubes - playcutegames.com",
    name: ["Wood Cubes", "wood-cubes"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/wood-cubes/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/wood-cubes/520x295.png",
    path: ["/game/wood-cubes", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/179646?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/wood-cubes.mp4",
    s_dis:"Wood Cubes Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       Feature game Wood cube puzzle:
       ✔ No time limit.
       ✔ Fun sound and stunning effects
       ✔ Entertaining game, can be played anywhere, anytime you want.
       Have fun and train your brain`,
       tag: ["Blocks","Merge","Tetris",],
    hint: `
       — On mobile devices, you need to use a touch screen.
       — On desktops, the game is controlled by the mouse.
       ✔ The gameplay is very simple, you just need to drag the wooden blocks and fill all the meshes horizontally or vertically to remove them.
       ✔ The game ends when there are no more spaces between the wooden blocks`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Wood Block Puzzle Classic - playcutegames.com",
    name: ["Wood Block Puzzle Classic", "wood-block-puzzle-classic"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/wood-block-puzzle-classic/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/wood-block-puzzle-classic/520x295.png",
    path: ["/game/wood-block-puzzle-classic", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/204298?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/wood-block-puzzle-classic.mp4",
    s_dis:"Wood Block Puzzle Classic Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       Come test your brain! Wood Block Puzzle Classic - Classic Puzzle Wood Block Elimination Game. This game is very challenging and you will become addicted to it soon!
       You need to fill the blocks in a 9×9 grid. Sound easy? This amazing game has two modes: "Classic Mode" and "Daily Challenge". 
       In "Daily Challenge" there are three interesting medals waiting for you to collect! In "Classic Mode"you need to clear as many blocks as possible, and there are also wonderful combos waiting for you. Play as long as you can, instead of taking up more space, to break your highest score!
       Features:
       1. Free download
       2. Suitable for all genders and ages
       3. There are a variety of interesting props to use
       4. No extra buttons, no time limit
       5. There are wonderful medals waiting for you to collect.
       6. Can be played offline. You can enjoy this classic "Wood Block Puzzle" anywhere and anytime`,
       tag: ["Blocks","Merge","Tetris",],
    hint: `
       1. Drag the block onto the 9×9 grid.
       2. Fill a column, row, or a 3×3 grid to clear the blocks on the board.
       3. Try to clear multiple lines or 3×3 grids at once to get a higher score.
       4. If there is no extra space, the game is over.`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Wood Block Journey - playcutegames.com",
    name: ["Wood Block Journey", "wood-block-journey"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/wood-block-journey/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/wood-block-journey/520x295.png",
    path: ["/game/wood-block-journey", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/181186?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/wood-block-journey.mp4",
    s_dis:"Wood Block Journey Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       Wood Block Journey is a woodblock puzzle game that meets a sudoku grid. It’s a calm but challenging puzzle that you’ll be addicted to in no time!
       Mode Endless: Place blocks in the 9x9 board and fill rows, columns, or squares to clear them from the game. Play for as long as you can without running out of space to beat your high score!
       Mode Level: Place blocks in the 9x9 board and fill rows, columns, or squares to clear them from the game and complete the mission of each level.
       Features:
       - Beautiful graphics and satisfying sound effects.
       - A tactile game experience with realistic wood tile design.
       - Relaxing gameplay with no pressure or time limit.
       - Over 60 puzzles are unique, full of fun, and amazing challenges!
       - A light, small game, no download, click and play game.
       - No register and free to play.`,
       tag: ["Blocks","Merge","Tetris",],
    hint: `
       How to play Wood Block Journey:
       - Drag shapes onto the board to place them in the grid.
       - Fill a row, column, or square to clear blocks from the board.
       - Clear multiple rows, regions, or squares to earn Combo points!
       - Earn as many points as you can to beat your high score!
       - Clear gem to complete mission of each level.
       - Have yourself a woody good time!`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Water Sort Puzzle: Color Game - playcutegames.com",
    name: ["Water Sort Puzzle: Color Game", "water-sort-puzzle-color-game"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/water-sort-puzzle-color-game/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/water-sort-puzzle-color-game/520x295.png",
    path: ["/game/water-sort-puzzle-color-game", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/225487?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/water-sort-puzzle-color-game.mp4",
    s_dis:"Water Sort Puzzle: Color Game Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       Water Sort Puzzle: Color Game is a fun and challenging water sort puzzle game! Try to sort the colors of water in the glasses. 
       Train your brain with one of the most addictive color sort puzzle games in the market.
       Main Features:
       -5000 unique water sort puzzles
       -Nice and colorful graphics
       - Fun sound effects
       - Easy to play, hard to master
       You need to pour liquid and sort water in glass bottles to solve puzzle.`,
       tag: ["Merge","Sort",],
    hint: `
       How to play:
       - Tap on one bottle and pour water from that bottle to another
       - You can only pour liquid if the two tubes have the same color on top
       - Keep pouring liquid until all colors are in the same glass.
       - Use hints if you need help to solve water puzzles`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 0,
    title: "Vortex 9 - playcutegames.com",
    name: ["Vortex 9", "vortex-9"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/vortex-9/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/vortex-9/520x295.png",
    path: ["/game/vortex-9", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/207623?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/vortex-9.mp4",
    s_dis:"Vortex 9 Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       Stylish heroes, insane weapons and explosive battles are here in the Vortex 9!
       - 8 amazing characters: John, Jane, Marvin McSpy, Mr Goodboy, Jess Purrfect, Hellen D. Mon, Mercydroid and Beelzebox.
       - Weaponry: dozens of crazy shooting guns - atypical melee weapons, heavy machine guns!
       - Game modes: Team battle, Deathmatch Solo, Capture point. And that’s not all! More and more modes are coming!
       - Various maps: fight in a variety of beautiful locations within the game universe!
       - Play with friends: make fights more fun and intense together! No boring heroes with simple pistols, just wacky atmosphere of combat!
       Show your individuality with the characters, it’s time for the new you!`,
       tag: ["Shooter","Third person",],
    hint: `
       Keyboard: WASD G for grenade M FOR map F for pick up item C for squat M1 for aim M2 for shooting SPACE for jump ESC for menu SCROLL WHEEL (or 1,2,3,4,5) for change weapon.`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 0,
    title: "Vega Mix Fairy town - playcutegames.com",
    name: ["Vega Mix Fairy town", "vega-mix-fairy-town"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/vega-mix-fairy-town/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/vega-mix-fairy-town/520x295.png",
    path: ["/game/vega-mix-fairy-town", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/250707?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/vega-mix-fairy-town.mp4",
    s_dis:"Vega Mix Fairy town Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       Join a girl named Vika and the mayor of a magical city in their quest to bring back the holiday spirit and ensure a successful New Year! Play challenging match 3 levels by connecting elements and solving puzzles to overcome various obstacles and help the mayor overcome problems.
       As the game progresses, Vika and the mayor will face various difficulties, such as lack of time, lack of necessary resources, and even the forces of evil trying to stop them. However, with your help and resourcefulness, they can bring back the holiday feeling and ensure that the New Year goes as planned.
       Game Features:
       ● Exciting match-3 gameplay: connect elements and solve puzzles to help Vika and the mayor overcome obstacles.
       ● Different levels of increasing difficulty: from simple tasks to complex puzzles that require strategic thinking.
       ● Story development: Unlock new tasks for Vicky and the mayor that will reveal the secrets of the frozen city.`,
       tag: ["Shooter","Third person",],
    hint: `
       How to play match-3 puzzle:
       ● Match 3 or more pieces in a line to explode them.
       ● Match 4 pieces to create the Rocket. The Rocket blows up the whole line!
       ● Match 4 pieces to form a square to create the Spinner. The Spinner destroys one of the targets!
       ● Make a T-shaped match of 5 pieces to create the Bomb. The Bomb makes a massive explosion on the board!
       ● Match 5 pieces in a line to create the Rainbow Flower. The Rainbow Flower explodes pieces of the same color!
       ● Match any 2 power-ups to get a variety of powerful effects that will make it easier to complete the level.
       ● Many obstacles on the board allow you to use different strategies and make the levels diverse.`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 0,
    title: "Tropical merge - playcutegames.com",
    name: ["Tropical merge", "tropical-merge"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/tropical-merge/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/tropical-merge/520x295.png",
    path: ["/game/tropical-merge", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/179757?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/tropical-merge.mp4",
    s_dis:"Tropical merge Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       Welcome to Tropical Merge! Prepare yourself for the family farm adventure full of mysteries and extraordinary characters. Help locals save their paradise bay while renovating the island and growing your tropical farm. Go on expeditions to explore other islands and solve even more riddles. Don’t miss your chance to build a perfect tropical farm!
       FEATURES:
       - Beautiful graphics and stunning landscapes;
       - Tons of objects to merge and combine;
       - Daily challenges and awesome rewards;
       - Dozens of buildings to make and resources to grow;
       - Commodity exchange with the best rates for your rich harvest;
       - Lots of quests and stories you can't miss!
       These and many other features make Tropical Merge an absolute klondike of adventures and funky bay experience! Start enjoying this free adventure game now and get the most out of your tropical escape!`,
       tag: ["Merge","Farming",],
    hint: `
       Found yourself in the thick of things and don't know where to start? Try to merge three identical pieces and see what happens!
       - Unlock new items by merging three or more objects on the field;
       - Unlock new territories by collecting ancient amulets;
       - Improve the island by cutting down weeds;
       - Collect the received resources and trade them on the Commodity Exchange;
       - Meet the inhabitants of the island by merging and matching items that belong to them!`,
    rating:[4.4,1210,114],  
    },

    {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Tile Match Puzzle - playcutegames.com",
    name: ["Tile Match Puzzle", "tile-match-puzzle"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/tile-match-puzzle/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/tile-match-puzzle/520x295.png",
    path: ["/game/tile-match-puzzle", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/207431?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/tile-match-puzzle.mp4",
    s_dis:"Tile Match Puzzle Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
       Are you a fan of match 3 and combination games?
       Tile Match Puzzle fulfills your desire for puzzle fun with new puzzle challenges every day! Simply match three tiles to reveal even more tiles underneath. Find more combinations until you've cleared everything. But beware: If you select too many tiles without at least three matching each other, you will have to start your journey from the beginning.
       Each level presents you with a new addictive puzzle challenge. Take a fun and relaxing getaway as you enter beautiful new landscapes to test your brain.
       Game features
       Unique Tile Puzzles:
       With tiles in over 20 styles, there's no limit to the number of combinations you can make.
       Flex your brain muscles
       Test and improve your memory and pattern recognition skills with this fun and relaxing puzzle game!`,
       tag: ["Merge","Tiles","Matching",],
    hint: `
       Simply match three tiles to reveal even more tiles underneath. 
       Find more combinations until you've cleared everything. 
       But beware: If you select too many tiles without at least three matching each other, you will have to start your journey from the beginning.`,
    rating:[4.4,1210,114],  
    },
    {
      date: "2024-10-24",
      brand: "Yandex",
      orientation: 1,
      title: "Airport Simulator - playcutegames.com",
      name: ["Airport Simulator", "airport-simulator"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/airport-simulator/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/airport-simulator/520x295.png",
      path: ["/game/airport-simulator", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/368383?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/airport-simulator.mp4",
      s_dis:"Airport Simulator Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         This is a casual game that simulates airport operations.
         In the game, you can operate various processes and unexpected events before the plane takes off.
         Through this game, you can learn a lot about airports!`,
         tag: ["Driving","Airport",],
      hint: `
         Touch the screen to complete most operations. The game is simple and easy to get started, but there are new challenges at each level.
         Complete the operation to get rewards, and use the rewards to unlock more beautiful planes.
         Pay attention to control the vehicle and the plane, and don't hit any obstacles.
         Come and experience this airport simulator!`,
      rating:[4.4,1210,114],  
      },
  
      {
      date: "2024-10-24",
      brand: "Yandex",
      orientation: 1,
      title: "Alice's Hotel: match story - playcutegames.com",
      name: ["Alice's Hotel: match story", "alices-hotel-match-story"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/alices-hotel-match-story/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/alices-hotel-match-story/520x295.png",
      path: ["/game/alices-hotel-match-story", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/342663?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/alices-hotel-match-story.mp4",
      s_dis:"Alice's Hotel: match story Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         Renovate the rooms and become your own interior designer. Decorate every corner until you feel at home! 
         Follow the story and meet new characters—life is in full swing in this hotel. Discover all the secrets that these walls hide! An adorable pet will keep you company!
         So, are you ready to have some fun?`,
         tag: ["Cleaning",],
      hint: `
         • exciting match 3 levels with explosive bonus combos!
         • a huge mansion with dozens of secrets!
         • a wide selection of decorations that allow you to spruce up the hotel according to your tastes!
         • daily events, challenges and great rewards!`,
      rating:[4.4,1210,114],  
      },
  
      {
      date: "2024-10-24",
      brand: "Yandex",
      orientation: 0,
      title: "Barry 2: Rob Robux Bank! - playcutegames.com",
      name: ["Barry 2: Rob Robux Bank!", "barry-2-rob-robux-bank"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/barry-2-rob-robux-bank/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/barry-2-rob-robux-bank/520x295.png",
      path: ["/game/barry-2-rob-robux-bank", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/355003?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/barry-2-rob-robux-bank.mp4",
      s_dis:"Barry 2: Rob Robux Bank! Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         ⚡Robbie's new incredible adventure!⚡
  
         🔴 You need to rob Barry's Bank from Roblox!👮👮👮
  
         🔵 Run away from Barry!🏃🏃🏃
  
         🔴 Complete challanges!😎😎😎
  
         🔵 Kill the cops!👿👿👿
  
         🔴Parkour and Hobbies!💎💎💎
  
         🔵Arrange a car race!💪💪💪
  
         🔴 Commit a cool robbery!!!🔥🔥🔥`,
         tag: ["Escape","Parkour","Roblox","Prison",],
      hint: `
         ⭐The goal of the game is to rob a Bank⭐
         Complete the tasks and run away from Barry!
         🎮Control
         💻PC: WASD (WASD) or arrows + mouse🎯
         📱 Phone: Joystick + Buttons on the screen🎯`,
      rating:[4.4,1210,114],  
      },
  
      {
      date: "2024-10-24",
      brand: "Yandex",
      orientation: 0,
      title: "Battlefeel - playcutegames.com",
      name: ["Battlefeel", "battlefeel"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/battlefeel/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/battlefeel/520x295.png",
      path: ["/game/battlefeel", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/328096?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/battlefeel.mp4",
      s_dis:"Battlefeel Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         This is a thrilling 3D first-person shooter that throws you into the midst of war. Choose your side - red or blue - and engage in an fight for dominance on the battlefield.
         Capture bases, eliminate enemies, dominate!
         💥 Dynamic battles: Fight side-by-side with your comrades, capturing strategically important points and repelling enemy attacks.
         💣 Vast arsenal: Choose from a wide range of weapons - from assault rifles and sniper rifles to grenade launchers and rocket launchers.
         ⭐ Upgrade and progress: Earn experience in battles and unlock new deadly weapons and powerful vehicles.
         💪 Conquer land and sky: Mount a steel monster - a tank, take to the skies in a helicopter, maneuver in a jeep or quad bike to gain an advantage over the enemy.
         This game is your ticket to the world of adrenaline, teamwork, and unforgettable victories! Are you ready to accept the challenge?`,
         tag: ["Shooter","First person","Battlefield",],
      hint: `
         The more bases are captured, the more experience is gained for destroying the enemy.
         WASD + Mouse - movement
         QE - tilt
         Enter - arsenal menu
         C - sit down
         Space - jump
         Shift - run
         Esc - menu
         Tab - battle statistics
         Mouse Wheel - weapon change
         1,2,3...6 - weapon change
         F - entering/exiting the Transport
         1,2,3 - change your seat in transport
         Helicopter:
         WS - climb/reset altitude
         AD - tilt left/right
         Mouse horizontally - turn left/right
         Mouse vertically - tilt the nose up/down`,
      rating:[4.4,1210,114],  
      },
  
      {
      date: "2024-10-24",
      brand: "Yandex",
      orientation: 1,
      title: "Big sorting - playcutegames.com",
      name: ["Big sorting", "big-sorting"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/big-sorting/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/big-sorting/520x295.png",
      path: ["/game/big-sorting", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/302238?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/big-sorting.mp4",
      s_dis:"Big sorting Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         Do you have a craving for order, symmetry, perfectionism? You've come to the right place.
         A relaxing game in which you need to organize objects on shelves. You have to put objects on shelves, place identical objects next to each other to connect 3 identical objects on the shelf.
         Connect objects three in a row and get satisfaction from the order you've brought!
         Sorting calms and organizes not only objects, but also your thoughts!
         Big Sorting - A simple and relaxing game for everyone.`,
         tag: ["Sort","Arrange","Matching",],
      hint: `
         The gameplay is very simple - drag and drop objects and place them on the shelves.
         - Collect 3 identical objects on one shelf to make them disappear.
         - Clear all the shelves to complete the level.
         - Increase your score by "COMBO", to do this you need to collect identical objects on the shelves until the "COMBO" scale reaches zero.
         - As you progress in the game, new objects will be added to the shelves.
         - Your best result is recorded in the leaderboard.
         - After each level, a table with your result and the results of your opponents is displayed.`,
      rating:[4.4,1210,114],  
      },
    {
      date: "2024-10-24",
      brand: "Yandex",
      orientation: 0,
      title: "Ball Sort Master - playcutegames.com",
      name: ["Ball Sort Master", "ball-sort-master"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ball-sort-master/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ball-sort-master/520x295.png",
      path: ["/game/ball-sort-master", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/212348?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/ball-sort-master.mp4",
      s_dis:"Ball Sort Master Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         Hints. Do you have doubts about what move you should make? Are you puzzled? Use hints! This is the most unique feature of Ball Sort Master, which you don’t find in most logical sorting games. Now you don't have to puzzle over what move to make for hours.
         Undo. We sometimes make mistakes while solving a puzzle, don’t we? Now, you don’t have to worry about that! Undo your move!
         Extra tube. This is a super-helpful feature for sorting and achieving the next puzzle level! Use an extra tube and make ball sort levels easier.
         Customization. Click the shopping cart and tailor your profile to suit you. You can customize anything you want. Choose between the great variety of theme colors, shapes of tubes, or the color of your sorting balls.`,
        tag: ["Color","Ball","Match",],
      hint: `
        How to play:
        - Tap a tube to select a ball.
        - Tap another tube to move the selected ball...
        ...and that’s all! Isn’t it easy?
        How many levels can you complete? This remains the only puzzle!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-10-24",
      brand: "Yandex",
      orientation: 0,
      title: "Backgammon Narde online - playcutegames.com",
      name: ["Backgammon Narde online", "backgammon-narde-online"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/backgammon-narde-online/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/backgammon-narde-online/520x295.png",
      path: ["/game/backgammon-narde-online", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/190906?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/backgammon-narde-online.mp4",
      s_dis:"Backgammon Narde online Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         Backgammon or Tavla is one of the oldest known board games. Its history can be traced back nearly 5,000 years to archeological discoveries in Mesopotamia.
         It is a two-player game where each player has fifteen pieces (checkers or men) that move between twenty-four triangles (points) according to the roll of two dice.
         The objective of the game is to be first to bear off, i.e. move all fifteen checkers off the board.`,
        tag: ["Backgammon","Board",],
      hint: `
        Each player begins the game by rolling a single die each. The person with the high number can use two dice for their first move. If a player rolls a double number with the pair of dice, they get a double move. For instance, you can move two points two times if you roll a double two.
        Each checker is moved forward independently. They can be moved onto any point that your opponent does not block.
        A point is blocked if your opponent has two or more checkers occupying the point. However, a point is accessible if:
        - It doesn't have any checkers on it,
        - There is only one of your opponent’s checkers on it, or
        - It’s occupied by one or more of your checkers.
        If you move one of your checkers onto a point occupied by one of your opponent’s checkers, your opponent’s checker is moved to the middle bar. They must move that checker before they can make any other moves.`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-10-24",
      brand: "Yandex",
      orientation: 0,
      title: "Backgammon Multiplayer - playcutegames.com",
      name: ["Backgammon Multiplayer", "backgammon-multiplayer"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/backgammon-multiplayer/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/backgammon-multiplayer/520x295.png",
      path: ["/game/backgammon-multiplayer", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/96501?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/backgammon-multiplayer.mp4",
      s_dis:"Backgammon Multiplayer Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         Roll the dice and move your piece.
         The winner is the one who removes all of his chips from the board before the opponent.
         Be careful! It's not just luck. In the long term, strategy plays a more important role.`,
        tag: ["Backgammon","Board",],
      hint: `Roll the dice and tap or click on the chip you want to move and place it in one of the available slots.`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-10-24",
      brand: "Yandex",
      orientation: 1,
      title: "Around the Word - playcutegames.com",
      name: ["Around the Word", "around-the-word"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/around-the-word/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/around-the-word/520x295.png",
      path: ["/game/around-the-word", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/166049?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/around-the-word.mp4",
      s_dis:"Around the Word Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         Welcome to the amazing journey with "Around the Word" game!
         This beautiful crossword puzzle game will help you improve your vocabulary.
         Traveling with "Around the Word", you will find the magnificent wonders of nature.
         Just combine letters, find words and increase your vocabulary!
         In this game you need to make words from the given letters and solve a crossword puzzle. Sometimes it will be easy, but sometimes not.
         What do you think - can you find all the right words, or maybe just guess? :)
         THIS CROSSWORD PUZZLE GAME WILL IMPROVE YOUR VOCABULARY!
         How many words do you know? And how much can you make up of a few letters? Everything is not as simple as it seems at first time, and to go further in this game you need to constantly replenish your vocabulary and learn new things!
         Correctly combine letters, find words and discover the amazing nature of our world!`,
        tag: ["Crossword","Learning","Brain",],
      hint: `
        Around the Word - a word game in which you have to make words from letters.
        At each level, a certain set of letters is given and a crossword puzzle.
        To win, you must form words from these letters by simply moving your finger from one letter to another.
        Each letter can only be used once.
        If the composed word is in the crossword, it will automatically fit into it.
        If the word that you have composed is not in the crossword, it will be included in the bonus words, for which you will receive additional coins.
        To make the game easier, you can buy various tips for coins by clicking on the corresponding buttons.
        Possible hints: open one random letter, open five random letters, open a letter of your choice.
        The level is completed when all the words from the crossword puzzle are found.
        In the main menu, you can see the leaderboard, as well as open a gallery of completed levels.
        While in the gallery, you can click on any photo and select a level if you want to replay it.`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-10-24",
      brand: "Yandex",
      orientation: 1,
      title: "8 Ball Billiards Classic - playcutegames.com",
      name: ["8 Ball Billiards Classic", "8-ball-billiards-classic"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/8-ball-billiards-classic/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/8-ball-billiards-classic/520x295.png",
      path: ["/game/8-ball-billiards-classic", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/233709?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/8-ball-billiards-classic.mp4",
      s_dis:"8 Ball Billiards Classic Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         There is no other sport where knowledge about angles and ball spin is as essential as it is in Billiards. And 8 Ball Billiards reproduces these important features of the game in a realistic way. Depending on if you give the cue ball an undercut or where you hit one of the other balls, each of them will react physically accurate and move across the table accordingly.
         As a standard option reference lines are activated to show you where the ball you hit will go and how the cue ball will bounce off of it. But if you want the real eight ball pool experience you can disable this option in the menu and depend only on your skills and experience.
         Disable this function, play against a friend on the same device and you will have the closest experience to real Billiards that you can have on a mobile device.
         Only the background noise of a real bar or tavern we can’t provide. Everything else is as close as it gets.`,
        tag: ["Sports","8 Ball pool",],
      hint: `
        Rotate your input around the ball to rotate the cue to aim.
        Drag down the cue at the side to adjust the strength. Release to hit the ball.
        You play by the well-known and simple 8 Ball rules. You have to pocket all of your balls, either the striped balls or the solid-colored ones. If you have successfully removed all your balls from the pool table, it’s time to down the black eight ball in any pocket you want.
        But be aware that if you pocket the eight ball before you have removed all other balls, you will instantly lose the game. Take that into consideration when you try to hit the other balls.`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-10-24",
      brand: "Yandex",
      orientation: 1,
      title: "2048 Cubes - playcutegames.com",
      name: ["2048 Cubes", "2048-cubes"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/2048-cubes/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/2048-cubes/520x295.png",
      path: ["/game/2048-cubes", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/180094?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/2048-cubes.mp4",
      s_dis:"2048 Cubes Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         Endless 2048 game with physical blocks.
         Drop and merge blocks to get bigger blocks.
         Play right now!`,
        tag: ["2048","Merge","Brain",],
      hint: `
        Swipe right and left to move the block and release to drop. Merge blocks with same number to get a bigger block.
        Increase your score with more blocks.
        Game ends when the board fills up to the dashed line.`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-10-24",
      brand: "Yandex",
      orientation: 1,
      title: "2048 Colored Balls - playcutegames.com",
      name: ["2048 Colored Balls", "2048-colored-balls"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/2048-colored-balls/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/2048-colored-balls/520x295.png",
      path: ["/game/2048-colored-balls", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/239715?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/2048-colored-balls.mp4",
      s_dis:"2048 Colored Balls Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         You have to combine balls of the same color to get new, larger ones!
         When two balls with the same number collide, they merge into a double value ball.
         Each ball has a certain size, from small to large.
         Each ball has physical properties that affect how they interact with other balls on the playing field.
         Larger balls can push smaller balls into collisions, resulting in complex and dynamic interactions.
         Features:
         - Endless gameplay;
         - Physical properties of balls;`,
         tag: ["2048","Merge","Brain",],
      hint: `
        Swipe/gesture right or left to move the ball and release.
        Match balls with the same numbers to get points.
        The game ends when the balls fill up to the dotted line.`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-10-25",
      brand: "Yandex",
      orientation: 1,
      title: "Ball Sort Puzzle - playcutegames.com",
      name: ["Ball Sort Puzzle", "ball-sort-puzzle"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ball-sort-puzzle/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ball-sort-puzzle/520x295.png",
      path: ["/game/ball-sort-puzzle", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/162893?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/ball-sort-puzzle.mp4",
      s_dis:"Ball Sort Puzzle Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         Someone mixed the balls in the flasks! Put things in order, sort the balls into flasks so that there are balls of the same color in the flask. This is not as easy as it might seem. In addition to ordinary flasks, there are special, harmful flasks in which you need to put balls of one specific color, others will not work.
         You will find many exciting levels, built from easy to difficult. A little logic, a little patience and order will be restored!`,
        tag: ["Ball",],
      hint: `
        The control is simple - click on the flask from which you need to get the ball, and then on the flask, where to send this ball.`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-10-25",
      brand: "Yandex",
      orientation: 1,
      title: "Balloons around! - playcutegames.com",
      name: ["Balloons around!", "balloons-around"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/balloons-around/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/balloons-around/520x295.png",
      path: ["/game/balloons-around", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/260264?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/balloons-around.mp4",
      s_dis:"Balloons around! Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         Good old-fashioned balloon shooting, but now they're coming at you from all sides!
         Shoot them with the right colours to score points and clear the field.
         There are 7 different colours. Link 3 or more balls of the same colour to pop them.
         Aim with the handy colour pointer.
         Strategise by knowing the next colour.
         Choose your preferred visual style in the game settings.
         The complexity of the game increases as you do so. Can you clear the board before the balloons take over?`,
        tag: ["Ball","Color",],
      hint: `
        Use the coloured cursor to choose a direction and shoot.
        Hit a group of two or more balloons of the same colour to pop them and earn points.
        Points are displayed in the top left corner of the screen.
        If the balloons do not burst, you get a foul.
        The number of fouls available is shown in the top right corner.
        When it reaches zero, the balloons move to the centre.
        The bottom left corner shows the colour of the next balloon.
        Destroy all balloons of the same colour to prevent them from reappearing.
        The effect corresponding to the destroyed colour will appear.
        Destroy all colours to win.
        Your score is multiplied by 5.
        You lose if you shoot a balloon near the centre.
        In the bottom right corner of the screen is the settings button.
        The settings menu allows you to turn the sound on or off, select a visual style and restart the level.
        The highest score is recorded when you win or lose.`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-10-25",
      brand: "Yandex",
      orientation: 0,
      title: "Barry: Robux Prison Break! - playcutegames.com",
      name: ["Barry: Robux Prison Break!", "barry-robux-prison-break"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/barry-robux-prison-break/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/barry-robux-prison-break/520x295.png",
      path: ["/game/barry-robux-prison-break", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/283822?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/barry-robux-prison-break.mp4",
      s_dis:"Barry: Robux Prison Break! Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         ⚡Robbie's new incredible adventure!⚡
         🔵 You ended up in PRISON with stupid Officer Barry from Roblox!👮👮👮
         🔴 Run away from Barry!🏃🏃🏃
         🔴 Pass the challanges!😎😎😎
         🔴 Kill Boss Barry!👿👿👿
         🔴 Parkour and Obby!💎💎💎
         🔴 Have a car race!💪💪💪
         🔵 Escape from prison Barry!!!🔥🔥🔥`,
        tag: ["Police","Prison","Parkour","Escape",],
      hint: `
        ⭐The goal of the game is to escape from Prison⭐
        Complete the tasks and run from Barry!
        🎮Control
        💻PC: WASD or arrows + mouse🎯
        📱 Phone: Joystick + Buttons on the screen🎯`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-10-25",
      brand: "Yandex",
      orientation: 1,
      title: "Block Puzzle - Blast Master - playcutegames.com",
      name: ["Block Puzzle - Blast Master", "block-puzzle-blast-master"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/block-puzzle-blast-master/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/block-puzzle-blast-master/520x295.png",
      path: ["/game/block-puzzle-blast-master", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/303169?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/block-puzzle-blast-master.mp4",
      s_dis:"Block Puzzle - Blast Master Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         We present you a fascinating Block Puzzle - Blast Master - a game in which you have to strategically place colored blocks, of different shapes, on the playing field.
         This colored block puzzle game is suitable for all ages with its simple and easy to understand gameplay.
         If you are a fan of block puzzles like "Tetris", then you will surely enjoy the exciting puzzle Block Puzzle - Blast Master.
         Also this online puzzle game provides an opportunity to compete with friends and other players, comparing scores.`,
         tag: ["Blocks","Merge",],
      hint: `
         In Block Puzzle - Blast Master your mission is simple: you have to try to place colored blocks of different shapes on the playing field, so that you get full rows.
         Filled without voids row or column, disappears from the field, making room for new blocks.
         The more rows you destroy simultaneously, the more points you get.
         The game will last until there is no space left on the field to place one of the three pieces.`,
      rating:[4.4,1210,114],  
      },
  
      {
      date: "2024-10-25",
      brand: "Yandex",
      orientation: 1,
      title: "Bolts and nuts - puzzle - playcutegames.com",
      name: ["Bolts and nuts - puzzle", "bolts-and-nuts-puzzle"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bolts-and-nuts-puzzle/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bolts-and-nuts-puzzle/520x295.png",
      path: ["/game/bolts-and-nuts-puzzle", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/284720?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/bolts-and-nuts-puzzle.mp4",
      s_dis:"Bolts and nuts - puzzle Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         An engaging educational game where you can test your logical and strategic thinking. Solve unique puzzles to pass the time and relax to excellent sound effects. This colorful and captivating game is not only suitable for children but also provides adults with the opportunity to challenge their problem-solving skills.
         Key features of the game "Bolts and Nuts - Puzzle":
         - Over 100 levels of varying difficulty, starting from the easiest where you can relax and prepare for the more challenging levels where you will need to give your best to solve the puzzle.
         - Helpful tools will assist you in tricky situations to overcome the task.
         - Ability to change the appearance of the bolts.
         Experience the excitement of the puzzle game right now!`,
         tag: ["Nuts and bolts","Physics",],
      hint: `
         Free all wooden parts to make them fall down!
         Unscrew the bolts by tapping on them and screw them into the free holes. When there are no wooden planks left - the level will be completed.
         Use helpful tools:
         - Screwdriver: removes the bolt you tap on.
         - Time Freeze: stops the timer for 30 seconds.`,
      rating:[4.4,1210,114],  
      },
  
      {
      date: "2024-10-25",
      brand: "Yandex",
      orientation: 1,
      title: "Break the Noob Completely! - playcutegames.com",
      name: ["Break the Noob Completely!", "break-the-noob-completely"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/break-the-noob-completely/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/break-the-noob-completely/520x295.png",
      path: ["/game/break-the-noob-completely", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/355317?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/break-the-noob-completely.mp4",
      s_dis:"Break the Noob Completely! Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         Launch the Noob, control him during the flight, and fly as far as possible! Upgrade and launch again!
         The game features realistic destruction, lots of pig trampolines, skins for the Noob, armor for the Noob, and many different locations!
         Try to achieve maximum upgrades and the best result!
         Easy to play, hard to master!`,
         tag: ["Minecraft","Physics","First person",],
      hint: `
         🖥️ On PC:
         Start flight - Space or Left Mouse Button
         Control during flight - WASD or Arrow Keys or Mouse with Left Button held
         📱 On phone:
         Start flight - Tap the screen
         Control during flight - Swipe left/right on the screen`,
      rating:[4.4,1210,114],  
      },
  
      {
      date: "2024-10-25",
      brand: "Yandex",
      orientation: 0,
      title: "BS Simulator - playcutegames.com",
      name: ["BS Simulator", "bs-simulator"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bs-simulator/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bs-simulator/520x295.png",
      path: ["/game/bs-simulator", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/313466?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/bs-simulator.mp4",
      s_dis:"BS Simulator Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         ❗This application is not developed or sponsored by Supercell.
         UNLOCK AND UPGRADE BRAWLERS
         Unlock and upgrade a variety of Brawlers! Level up your Brawlers and collect unique skins.
         TROPHY ROAD
         Collect trophies to earn rewards and advance to new leagues!
         BRAWL PASS
         - More than 140 rewards!
         - Complete daily quests and unlock exclusive rewards.
         LEADERBOARDS
         Compete with real players from all over the world!
         DAILY BONUSES
         Log in to the game every day and receive exclusive bonuses!
         In addition, the game has mini-modes.
         Attention:
         This is a non-commercial application created by a fan for fan content purposes only, limited to the display and identification of "Brawl Stars". 
         This app is unofficial and not endorsed by Supercell. We respect the Supercell brand and trademark infringement is not intended. 
         This content is not affiliated with, is not endorsed, sponsored or endorsed by Supercell, and Supercell is not responsible for it.`,
         tag: ["Brawl stars",],
      hint: `
         - Open Brawl Boxes to unlock Brawlers, earn coins and gems, and improve your progress!
         - Upgrade your Brawlers!
         - Complete quests and get tokens to complete the BRAWL PASS!
         - Earn trophies to advance your Trophy Road and win exclusive rewards!
         - Compete with other players on the leaderboards!
         - Log into the game every day to get daily bonuses!
         - Take advantage of special offers in the shop, they are updated several times a week!
         - Customize your profile: player icon, name, name color (if you have a Brawl Pass, you can put a gradient color!)
         - Play mini-games, you can earn good rewards in them!`,
      rating:[4.4,1210,114],  
      },
  
      {
      date: "2024-10-25",
      brand: "Yandex",
      orientation: 1,
      title: "Bubble Around - playcutegames.com",
      name: ["Bubble Around", "bubble-around"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-around/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-around/520x295.png",
      path: ["/game/bubble-around", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/363315?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/bubble-around.mp4",
      s_dis:"Bubble Around Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         Bubble Around takes the classic bubble shooting experience to a whole new level, with bubbles closing in from all directions! Your mission is to shoot bubbles with matching colors, strategically clear the field, and rack up points before the bubbles overwhelm you.
         Bubble Around offers a customizable experience with various visual styles available in the settings, allowing you to tailor the game’s appearance to your liking. As you progress, the difficulty ramps up, challenging you to clear the board before the bubbles take over.
         FEATURES:
         • The game lets you know the next bubble colors.
         • Aim with the convenient colored pointer.
         • A time-killer that also enhances your lateral thinking.
         • Displays your world ranking and high score on the leaderboard.
         • Simple and modern graphic design.
         • No downloads needed—just click and play!
         Do you have the skills and strategy to conquer Bubble Around before the bubbles close in?`,
         tag: ["Bubble shooter",],
      hint: `
         • Aim and shoot bubbles.
         • Match three or more bubbles of the same color.
         • Keep matching to avoid losing the game!
         • If it’s too tricky, check your next colors and plan your next move!`,
      rating:[4.4,1210,114],  
      },
  
      {
      date: "2024-10-25",
      brand: "Yandex",
      orientation: 1,
      title: "Bubble Hit Online - playcutegames.com",
      name: ["Bubble Hit Online", "bubble-hit-online"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-hit-online/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-hit-online/520x295.png",
      path: ["/game/bubble-hit-online", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/290493?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/bubble-hit-online.mp4",
      s_dis:"Bubble Hit Online Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         Welcome to the magical world of bubbles, where you become a master shooter and strive for perfection. Your goal is to arrange the bubbles so that they explode in bright flashes like fireworks!
         In this exciting game you will solve large levels of puzzle made up of bubbles, use your logic, plan moves and compete in the standings with other players. Immerse yourself in a world of colors where every shot is a chance to win! 🌟🔮🎈
         The cancel move button allows you to cancel only the last move.
         🏆🏆🏆
         BEST PLAYERS are the players who have wins in the current month.
         The format of the entries in the table is: YYMM.XXXX, where YY is the year, MM is the month, XXXX is the number of wins.
         For example, the entry 2402.0013 means: 24 year, 2 month and 13 wins.`,
         tag: ["Bubble shooter",],
      hint: `
         Shoot and shoot down groups of 3 or more bubbles of the same color.
         The more bubbles you hit at a time, the more points you get.
         If your shot doesn't hit a single balloon,you get a miss.
         After a few misses, a new row of bubbles will appear at the top of the screen a new row of bubbles will appear at the top of the screen.
         The game will end when you clear the field or when the bubbles reach the bottom of the field`,
      rating:[4.4,1210,114],  
      },
      {
      date: "2024-10-25",
      brand: "Yandex",
      orientation: 1,
      title: "Bubble Pop Master - Bubble Shoot - playcutegames.com",
      name: ["Bubble Pop Master - Bubble Shoot", "bubble-pop-master-bubble-shoot"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-pop-master-bubble-shoot/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-pop-master-bubble-shoot/520x295.png",
      path: ["/game/bubble-pop-master-bubble-shoot", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/346615?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/bubble-pop-master-bubble-shoot.mp4",
      s_dis:"Bubble Pop Master - Bubble Shoot Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         The only bubble shooter you will ever need! The classic bubble shooter was taken to a whole other level.
         FEATURE:
         - Awesome hand-made levels.
         - That bubble shooter is an entertaining, addictive, and time-killing game for you.
         - Quality image, colorful bubbles, beautiful sound, gorgeous special effects.
         - The game is suitable for all ages so you will have a good time with your friends and your family.
         What are you waiting for? Prepare yourself for a fresh round of bubble-shooting fun right now. 
         Hopefully, This bubble shooter will make your day become more interesting and colorful.`,
         tag: ["Bubble shooter",],
      hint: `
         HOW TO PLAY:
         - Use the guiding line to aim your shooter and blast bubbles!
         - Match 3+ bubbles & shoot to make them pop!
         - Swap the bubbles to match & pop more
         - Create powerful boosters & items to help you blast through levels.
         - Pop all bubbles or collect all gems to complete levels.`,
      rating:[4.4,1210,114],  
      },
      {
      date: "2024-10-25",
      brand: "Yandex",
      orientation: 1,
      title: "Empire City - playcutegames.com",
      name: ["Empire City", "empire-city"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/empire-city/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/empire-city/520x295.png",
      path: ["/game/empire-city", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/271135?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/empire-city.mp4",
      s_dis:"Empire City Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`
         Have you ever wondered what it would be like to run an entire Empire?
         Now with Empire City you will be able to:
         Build new beautiful cities, develop resource extraction,make your own unique culture, trade with other civilizations and make fantastic discoveries that will help you in the future.You have a chance to bring it all to life and become a real emperor with Empire City!
         You will have access to ancient civilizations and their advanced building technologies. It will be like a real flourishing of the empire and your civilization. Create the most unusual buildings and structures that posterity will call "Wonders of the Ancient World". Dig canals to help your peasants, create intricate and bizarre viaducts, erect epic statues of gods and heroes! In general, do what you like and what will help elevate your empire!
         `,
         tag: ["Building","Farming",],
      hint: `
         You will have to perform various tasks and develop your city. Extract resources, build buildings, produce your own products and make the inhabitants of your city happy.
         Expand, open new locations, manage your budget.
         To play from PC use mouse.
         To play from mobile devices use touch input.`,
      rating:[4.4,1210,114],  
      },
  {
    date: "2024-10-25",
    brand: "Others",
    orientation: 1,
    title: "Doodle Jump - Sweet Match 3 Puzzle Game Online!",
    name: ["Doodle Jump", "doodle-jump"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/doodle-jump/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/doodle-jump/520x295.png",
    path: ["/game/doodle-jump", "https://playcutegames.com"],
    iframe: "https://doodle-jump.co/game/doodle-jump/",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/doodle-jump.mp4",
    s_dis:"Play Doodle Jump Unblocked! Guide your bouncy character up endless platforms, dodge obstacles, and grab power-ups. Accessible online anywhere.!",
    l_dis:`
      In Doodle Jump Unblocked, players guide a cute, bouncy character up an endless series of platforms, avoiding tricky obstacles and encountering power-ups like jetpacks and springs to boost them even higher. Each level offers a unique set of challenges that test reflexes and timing, keeping the gameplay fresh and exciting. With its simple controls and instantly addictive mechanics, it’s easy to see why Doodle Jump remains one of the most popular platform games.
      <br><br>The unblocked version of Doodle Jump makes it accessible from anywhere, whether you’re at school, work, or home, with no downloads or installations needed. This free online game brings the nostalgia of classic arcade fun with a modern twist, allowing players to climb as high as they can without interruptions. Perfect for quick breaks or long sessions, Doodle Jump Unblocked invites everyone to aim for the highest scores and enjoy endless jumping action in their browser!`,
      tag: ["Doodle","Tap"],
    hint: `
    <li>Objective: Bounce upward on platforms and reach the highest score possible.</li>
    <li>Controls: Use arrow keys or tilt controls to move your character left and right.</li>
    <h2 class="H22">Gameplay:</h2>
    <li>Avoid obstacles and enemies while climbing.</li>
    <li>Collect power-ups like jetpacks and springs to boost higher.</li>
    <br>
    <li>Winning: Keep jumping as high as possible without falling to set a new high score.</li>
    <br>
    Enjoy endless jumping fun with Doodle Jump Unblocked—play online anytime! </li>
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Candy Riddles - Sweet Match 3 Puzzle Game Online!",
    name: ["Candy Riddles", "candy-riddles"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/candy-riddles/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/candy-riddles/520x295.png",
    path: ["/game/candy-riddles", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/173174?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/candy-riddles.mp4",
    s_dis:"Dive into Candy Riddles, the sweetest match 3 puzzle game! Connect colorful candies and cute cookies, tackle challenging levels, and collect fun prizes!",
    l_dis:`
      Enjoy Candy Riddles - the sweetest match 3 puzzle! Cute cookies and colorful candies with fun animations will certainly get you hooked! Jam through challenging levels matching scrumptious combos and collect great prizes! Connect delicious ice creams, pop cupcakes, match three pleasant characters in our addictive match3 game!
      We picked the best from the top of match 3 games to create the sweetest game ever: swap items to get 3 in a row - like in Saga or Blast, and receive extra sweeties to be the farm hero! If you like to splash sweets Candy Riddles is definitely for you. Our game is a real charm king among them!
      Meet with the Candyland inhabitants and travel together. Soda, Chocolate, Ice Cream, Jelly and more cute friends are waiting for you. Match and swap your own sweet road through the Ice Cream Forest and Candy Valley!`,
      tag: ["Match","Color","Brain","Candy",],
    hint: `
     Match three or more elements of the same color together to collect them and reach your goals. Collect enough elements to complete levels anв open new locations.`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Butterfly Match - Create & Match Wings",
    name: ["Butterfly Shimai", "butterfly-shimai"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/butterfly-shimai/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/butterfly-shimai/520x295.png",
    path: ["/game/butterfly-shimai", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/188347?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/butterfly-shimai.mp4",
    s_dis:"Match butterfly wings to create beautiful butterflies and clear the board. Use strategy to unblock wings and limited power-ups to help, then watch them fly away!",
    l_dis:`
      Match two of the same butterfly wings to create beautiful butterflies and make them disappear on the board. But beware! Other wings will block them from being matched, so you have to find the correct path and order to match them. But don't worry if you can't find a way to match them, since you can use power ups which will help you find the matches you are looking for. But keep in mind that those power ups are of limited use, so don't overuse them.
      So hop in and start creating beautiful Butterflies and watch them all fly to their new home!`,
      tag: ["Match","Color","Brain",],
    hint: `
     Click or touch two of the same butterfly wings to combine them and make them fly away. But check the path when combining them, since every wing in the way will block it.
     Your goal is to clear the board as fast as you can.`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Bubble: Pop Balloons - Free Online Puzzle Game | Start Popping!",
    name: ["Bubble: Pop Balloons", "bubble-pop-balloons"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-pop-balloons/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-pop-balloons/520x295.png",
    path: ["/game/bubble-pop-balloons", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/196051?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/bubble-pop-balloons.mp4",
    s_dis:"Bubble: Pop Balloons is a fun, relaxing puzzle game where you burst same-color bubbles in a row. Simple to play, yet highly addictive—start popping now!",
    l_dis:`
      "Bubble: Pop Balloons" is an addictive relaxing bubble bursting puzzle game. Click bubbles with the same colors in a row to burst them!
       <br><br>Match the longest chains of explosions to get more points!🏆
       <br><br>Challenge your mind and solve puzzles, and then they will seem easy and fun to you!
       <h2 class="H22">Features</h2>

       <li>Endless mode.</li>
       <li>Casual design.</li>
       <li>Relaxing sounds.</li>
       <br>
       All of them are ready for you right now! New gameplay, new joy!😀`,
      tag: ["Bubble","Color","Ball",],
    hint: `
      <li>Click vertically or horizontally on bubbles of the same color to burst them!</li>
      <li>Keep popping balloons to clear the field and get more points!</li>`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Bubble Tower 3D - Free Unblocked Bubble Game Online",
    name: ["Bubble Tower 3D", "bubble-tower-3d"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-tower-3d/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-tower-3d/520x295.png",
    path: ["/game/bubble-tower-3d", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/168757?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/bubble-tower-3d.mp4",
    s_dis:"Bubble Tower 3D is a fun, free online bubble-popping game! Enjoy it anywhere with no downloads or registration. Play on any device for instant fun and challenge!",
    l_dis:`
      Bubble Tower 3D offers an exciting and free online gaming experience that you can enjoy anywhere, anytime! This game is part of our collection of unblocked games, meaning you can play it at school, at home, or on any network without restrictions. No downloads or registration are required—just launch the game in your browser and start popping bubbles!
      <br><br>Perfect for all ages, Bubble Tower 3D combines the fun of classic bubble shooters with a modern, immersive 3D twist, adding extra layers of challenge and excitement. Whether you're competing for a high score or just looking to relax, this free online game brings hours of entertainment. Enjoy smooth, vibrant gameplay, and explore the many strategies you can use to conquer each level.
      <br><br>In Bubble Tower 3D, the unblocked games experience is elevated with unique power-ups like the fireball, which allows players to break through tricky spots and advance faster. As you aim to match at least three bubbles of the same color, you’ll work your way up an intricate Aztec-themed tower, testing both your skill and strategic thinking. The vibrant graphics and dynamic effects add excitement to every level, making this game as visually engaging as it is fun. Plus, since it’s an unblocked game, you can enjoy all this adventure without the hassle of downloads or installations, making it ideal for quick play sessions on any device.
      <br><br>Our site offers a wide selection of free online games like Bubble Tower 3D, bringing you countless options to unwind or challenge yourself. We prioritize making popular games accessible to all, so you can explore a diverse range of genres, from puzzle games to fast-paced action titles, right in your browser. If you’re a fan of bubble shooters, puzzle games, or simply looking for a fun and accessible game, Bubble Tower 3D is a top choice. Dive in, pop bubbles, and see how high you can climb the leaderboard!Perfect for all ages, Bubble Tower 3D combines the fun of classic bubble shooters with a modern, immersive 3D twist, adding extra layers of challenge and excitement. Whether you're competing for a high score or just looking to relax, this free online game brings hours of entertainment. Enjoy smooth, vibrant gameplay, and explore the many strategies you can use to conquer each level.
 
       `,
      tag: ["Bubble","Ball",],
    hint: `
     Experience this reinvented video games classic in a completely new dimension and an amazing aztec setting! Try to connect at least 3 bubbles of the same color to reach the top of the tower.`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "Crazy games",
    orientation: 0,
    title: "Holey.io - Unblocked Battle Royale IO Game | Play Now!",
    name: ["Holey.io Battle Royale", "holey-io-battle-royale"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/holey-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/holey-io-300.jpg",
    path: ["/game/holey-io-battle-royale", "https://playcutegames.com"],
    iframe: "https://games.crazygames.com/en_US/holey-io-battle-royale/index.html",
    Walkthrough: "https://www.youtube.com/embed/X30yqI2mIoM",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/holey-io-battle-royale.mp4",
    s_dis:
    "Join Holey.io Battle Royale, an exciting unblocked io game! Control a hole, consume objects and opponents, and compete to be the last hole standing in vibrant arenas!",
    l_dis:
      `
      Welcome to Holey.io, the exhilarating battle royale game where you step into the role of a hungry black hole, ready to consume everything in your path! In this unique and engaging gameplay experience, your objective is simple: devour various items, opponents, and even smaller holes to grow larger and dominate the arena. As you swallow objects ranging from furniture to vehicles, you'll become a massive force that other players will fear.

      <h2 class="H22">Key Features of Holey.io</h2>
      <ul>
      <li>Engaging Battle Royale Format: Experience the excitement of competing against players from around the globe in a fast-paced environment.</li>
      <li>Simple Mechanics: The controls are straightforward—just move your hole around the arena to consume objects and opponents.</li>
      <li>Dynamic Gameplay: With shrinking maps and a variety of objects to eat, every game is a unique challenge that requires quick thinking and strategy.</li>
      <li>Vibrant Graphics: Enjoy colorful and engaging visuals that enhance the gameplay experience.</li></ul>

      <h2 class="H22">Why Play Holey.io?</h2>
      <ul>
      <li>Fun and Competitive: Perfect for casual gamers and competitive players alike, Holey.io offers hours of entertainment and excitement.</li>
      <li>Easy Access: Play directly in your browser with no downloads required, making it easy to jump into the action whenever you want.</li>
      <li>Perfect for All Ages: The game is designed to be accessible, ensuring that players of all ages can enjoy the thrilling experience of becoming the ultimate black hole.</li>
      </ul>
      <br>
      Dive into Holey.io today and see if you have what it takes to become the last hole standing in this addictive and fun-filled battle royale!
      
      `,
      tag: ["Io","Multiplayer"],
    hint: `<ul>
      <li>Objective: Consume items to grow your hole while avoiding larger holes.</li>
      <li>Controls: Use the mouse to move your hole around the arena.</li></ul><br>
      <h3 class="H22">Gameplay:</h3><ul>
      <li>Start as a small hole and gobble up objects to increase in size.</li>
      <li>Avoid being swallowed by larger holes.</li>
      <li>The arena shrinks over time, bringing players closer together.</li>
      <li>Winning: Be the last player standing by outsmarting opponents and growing your hole.</li></ul><br>
      Dive into the action and see if you can become the biggest hole in Holey.io!
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "Crazy games",
    orientation: 0,
    title: "Skribbl.io - Unblocked Multiplayer Drawing Game Online!",
    name: ["Skribbl.io", "skribbl-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/skribbl-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/skribbl-io-300.jpg",
    path: ["/game/skribbl-io", "https://playcutegames.com"],
    iframe: "https://skribbl.io/",
    Walkthrough: "https://www.youtube.com/embed/87-PWbL8naQ",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/skribbl-io.mp4",
    s_dis:
    "Play Skribbl.io, the fun online drawing game! Draw and guess words with friends in this engaging unblocked io game, perfect for quick breaks and creative fun!",
    l_dis:
      `
      Welcome to Skribbl.io, the online multiplayer drawing game that brings out your inner artist while sparking friendly competition. In this creative and quick-thinking game, players take turns drawing a designated word while the rest of the group tries to guess it. Whether you're an experienced artist or simply love online games, Skribbl.io offers an engaging way to improve your drawing skills and exercise your creativity. And with the Skribbl.io unblocked option, you can enjoy the game from schools, offices, or any location, making it ideal for quick and fun breaks.

      <h2 class="H22">What is Skribbl.io?</h2>
      Skribbl.io is part of the popular io games genre, known for quick gameplay and multiplayer interaction. In this game, players join a room with friends or strangers to guess each other's drawings. Each player has the chance to draw a word given by the game, while the others guess the word in real time. As players guess correctly, they earn points based on their speed. Skribbl.io keeps players engaged across multiple rounds, ensuring everyone has a turn to showcase their drawing skills and test their guessing abilities.

      <h2 class="H22">Why Play Skribbl.io?</h2>
      <ul>
      <li>Fun for All Skill Levels: You don’t have to be an artist to enjoy Skribbl.io—its charm lies in the creativity and quick thinking involved.</li>
      <li>Unblocked Access for Easy Play: The Skribbl.io unblocked version means you can access the game from various locations, including school or work, for a quick mental break.</li>
      <li>Interactive Multiplayer: Play with friends, classmates, or meet new people online as you enjoy lighthearted competition.</li>
      </ul>
      <h2 class="H22">Tips for Winning Skribbl.io</h2>
      <ul>
      <li>Be Clear in Your Drawings: Use simple shapes and colors to convey your word quickly. Details can help, but keep it easy to recognize for fast guesses.</li>
      <li>Use Hints Wisely: When guessing, look for letters displayed in the word box, as they provide valuable hints.</li>
      <li>Think Creatively: Don’t always go with the first idea that comes to mind! Try drawing unique angles or perspectives to make it interesting.</li>
      </ul>
      <h2 class="H22">Skribbl.io – A Top Choice for Unblocked io Games</h2>
      Skribbl.io offers a refreshing blend of creativity and fun competition, making it a favorite in the unblocked games category. It’s perfect for short breaks, team-building, or virtual hangouts with friends. With its simple, browser-based setup, you can start playing immediately and enjoy the laughter and excitement that comes with each drawing. So, grab your digital canvas and see if you can guess your way to victory!

      `,
      tag: ["Io","Multiplayer","Draw",],
    hint: `<ul>
    <li>Objective: Guess the word based on the drawing made by another player.</li>
    <li>Controls: Use the mouse to draw and type your guesses in the chat box.</li>
    </ul><br>
    <h3 class="H22">Gameplay:</h3>
    <ul>
    <li>Take turns drawing a word assigned by the game.</li>
    <li>Other players try to guess the word in real time.</li>
    <li>Earn points for correct guesses, with faster guesses earning more points.</li>
    <br>
    <li>Rounds: Each player has multiple turns, ensuring everyone gets to draw and guess.</li></ul><br>
      
    Unleash your creativity and enjoy friendly competition in Skribbl.io!
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "Crazy games",
    orientation: 0,
    title: "Yohoho.io - Unblocked Pirate IO Game | Battle & Survive!",
    name: ["Yohoho.io", "yohoho-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/yohoho-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/yohoho-io-300.jpg",
    path: ["/game/yohoho-io", "https://playcutegames.com"],
    iframe: "https://games.crazygames.com/en_US/yohoho-io/index.html",
    Walkthrough: "https://www.youtube.com/embed/RofpkWfwnBQ",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yohoho-io.mp4",
    s_dis:
    "Play Yohoho.io, a thrilling unblocked io game! Battle as a pirate on shrinking islands, collect coins to grow stronger, and fight to be the last pirate standing!",
    l_dis:
      `
      In Yohoho.io, players dive into a competitive world where they play as pirates battling for supremacy on shrinking islands. Each game is a thrilling experience filled with action, as you navigate your pirate through treacherous terrain, avoid deadly traps, and challenge opponents for loot and dominance. The objective is simple yet challenging: be the last pirate standing and claim all the treasures!

      <h2 class="H22">Game Features</h2>
      <ul>
      <li>Classic Battle Royale Mechanic: Yohoho.io combines the exciting format of battle royales with a pirate theme, creating a unique gaming experience where players fight until only one remains.</li>
      <li>Dynamic Islands: Each map is packed with obstacles, treasures, and shrinking borders that force pirates into closer, more intense battles as the game progresses.</li>
      <li>Collect Coins and Grow Stronger: By defeating enemies and gathering scattered coins, you’ll grow in size and strength, giving you a competitive edge as you work to eliminate the rest of the competition.</li>
      <li>Pirate-Themed Gameplay: Play as a classic pirate character, complete with swords, eye patches, and treasure chests. The pirate theme adds a swashbuckling twist to the battle royale genre.</li>
      </ul>
     
      <h2 class="H22">Tips for Survival</h2>
      <ul>
      <li>Collect Loot Early: Right from the start, gather as many coins as possible. This will increase your strength and give you an edge in close encounters.</li>
      <li>lan Your Battles: Choose your battles wisely. While taking out smaller pirates can help you grow, avoid bigger, stronger pirates until you’re well-prepared.</li>
      <li>Stay Near the Safe Zone: As the island shrinks, make sure you’re always within the safe area to avoid losing health unnecessarily.</li>
      </ul>
      <h2 class="H22">Why Play Yohoho.io?</h2>
      Yohoho.io is perfect for players who enjoy competitive games with fast-paced action and simple mechanics. The game is easy to pick up but challenging enough to keep you engaged. Whether you’re a casual gamer or a battle royale enthusiast, Yohoho.io offers hours of entertainment and adventure.
      
      Join the game, don your pirate hat, and see if you have what it takes to be the ultimate pirate champion!

      `,
      tag: ["Io","Multiplayer","Battle","Snake",],
    hint: `
      <ul>
      <li>Control Your Pirate: Use the controls to move your pirate around the map and aim for loot. Coins and power-ups are scattered across each island, giving you plenty of opportunities to grow stronger.</li>
      <li>Battle Other Pirates: Engage in sword fights with other players. Every enemy you eliminate increases your score and makes your pirate even more powerful.</li>
      <li>Watch the Map: As time goes on, the map shrinks, pushing pirates closer together and forcing confrontations. Stay alert and strategize to avoid being trapped.</li>
      </ul>
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "Crazy games",
    orientation: 0,
    title: "Snake.io - Play Unblocked Snake & IO Games Online Free!",
    name: ["Snake.io", "snake-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/snake-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/snake-io-300.jpg",
    path: ["/game/snake-io", "https://playcutegames.com"],
    iframe: "https://games.crazygames.com/en_US/snake-io/index.html",
    Walkthrough: "https://www.youtube.com/embed/ ",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/snake-io.mp4",
    s_dis:
    "Play Snake.io, the ultimate in unblocked snake games! Dive into fast-paced io gameplay, slither to grow bigger, and compete to be the top snake in the arena!",
    l_dis:
      `
      Welcome to Snake.io, the ultimate multiplayer game where survival is key, and only the most skillful players make it to the top! In this modern take on the classic snake game, you’ll start as a tiny worm and eat your way to greatness by consuming food and growing longer. The objective is simple yet addictive: survive, grow, and climb the leaderboard by outmaneuvering your opponents and gobbling up everything in sight.

      <h2 class="H22">Game Features</h2>
      Classic Gameplay with a Modern Twist: Enjoy the nostalgic snake game mechanics blended with exciting new elements, trendy visuals, and dynamic sound effects that keep every session fresh and exhilarating.
      Competitive Multiplayer Arena: Battle it out against friends or online players from around the globe in a thrilling, fast-paced environment. With every move you make, there’s a risk of colliding with other players, so strategize to avoid becoming their next meal!
      Simple Controls: With easy-to-use controls, players of all skill levels can quickly adapt and start competing. Use intuitive touch controls on mobile or keyboard controls on desktop to slither through the arena with precision.
   
      <h2 class="H22">Survival Strategies</h2>
      <ol>
      <li>Eat to Grow: Always be on the lookout for orbs to eat. The more you eat, the bigger you grow, which gives you an advantage over smaller opponents.</li>
      <li>Outsmart Your Opponents: Utilize strategic movements to corner other snakes and make them collide with your body. This will eliminate them and give you a chance to consume their remains.</li>
      <li>Be Patient and Strategic: Especially when you're larger, use patience and positioning to trap smaller snakes, and aim to stay away from crowded areas where risks are higher.</li>
      </ol>
      <h2 class="H22">Why Snake.io?</h2>
      <ul>
      <li>Instant Gameplay: No complicated setup required. Jump right into the action from your browser or mobile device.</li>
      <li>Global Leaderboard: Compete with players worldwide and aim to secure your spot on the leaderboard.</li>
      <li>Fun with Friends: Challenge your friends and see who can survive the longest and grow the biggest worm.</li>
      </ul><br>
      Snake.io is perfect for casual gamers and competitive players alike. With quick rounds, engaging mechanics, and a classic feel, this game will have you hooked from your first slither. Can you reach the top and become the biggest snake in the arena? Dive into Snake.io and find out!
      `,
      tag: ["Io","Multiplayer","Snake",],
    hint: `
    Begin as a small worm and consume orbs scattered throughout the arena to grow larger. The more you eat, the bigger you become, but beware—your opponents are out to get you! Dodge enemy snakes and trap them to collect their remains, boosting your score and size. To make it to the top of the leaderboard, you’ll need quick reflexes, tactical positioning, and a strong survival instinct.
    <br><br>
    <ul>
    <li>Objective: Eat orbs to grow your worm and avoid enemy snakes.</li>
    <li>Controls: Use the mouse to steer and spacebar to boost speed.</li>
    <li>Strategy: Dodge opponents, trap smaller snakes, and collect their remains to boost your score.</li>
    <li>Goal: Grow as large as possible and climb the leaderboard.</li>
    </ul><br>
    Survive, strategize, and become the biggest worm in the arena!
    
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "Others",
    orientation: 0,
    title: "Narrow One - Play Free Online Multiplayer Game | Grow Your Family!",
    name: ["Narrow One", "narrow-one"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/narrow-one/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/narrow-one-300.jpg",
    path: ["/game/narrow-one", "https://playcutegames.com"],
    iframe: "https://narrow.one/",
    Walkthrough: "https://www.youtube.com/embed/whrw9aEGAfQ",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/narrow-one.mp4",
    s_dis:
    "Play Narrow One now! Control a cute duck, collect ducklings, and build your family while avoiding dangers and outsmarting other players in this relaxing multiplayer game!",
    l_dis:
      `
      Dive into the thrilling world of Narrow One, a captivating multiplayer archery game that blends fast-paced action with strategic gameplay. Set in stunningly crafted medieval landscapes, this game is perfect for players who thrive on competitive team-based battles. Whether you're capturing flags, defending your base, or engaging in exhilarating shootouts, Narrow One offers endless entertainment with its skill-based archery combat and immersive environments.

      <h2 class="H22">Gameplay Mechanics</h2>
      At its core, Narrow One is designed for players who enjoy tactical shooting. Master the art of archery as you engage in epic battles against players from around the globe. The intuitive controls make it easy to aim and shoot, while the varying difficulty levels cater to both newcomers and seasoned archers. Engage in thrilling matches where precision and strategy are crucial to achieving victory.
      
      Players can choose from a variety of game modes, including team battles and capture the flag, each presenting unique challenges and opportunities for strategic play. Work with your teammates to outsmart opponents, coordinate attacks, and defend your territory. Every match is a test of skill and teamwork, ensuring that no two games are ever the same.
      
      <h2 class="H22">Stunning Environments</h2>
      The beautifully designed medieval settings enhance the overall gaming experience. Explore diverse arenas filled with intricate details, from sprawling castles to lush forests, all while you hone your archery skills. The visually appealing graphics combined with immersive sound effects create an engaging atmosphere that draws players in and keeps them hooked for hours.
      
      <h2 class="H22">Play Anytime, Anywhere</h2>
      One of the standout features of Narrow One is its accessibility. With no downloads required, you can jump right into the action from your web browser. This makes it incredibly convenient for quick matches with friends or casual play during your breaks. The game’s unblocked status allows you to enjoy the excitement without restrictions, whether at school, home, or anywhere with an internet connection.
      
      <h2 class="H22">Join the Community</h2>
      Narrow One fosters a vibrant community of players who share a passion for archery and competitive gaming. Participate in friendly competitions, join clans, and connect with others who share your interests. The game’s chat feature allows for real-time communication, enhancing teamwork and camaraderie among players.
      
      <h2 class="H22">Why You’ll Love Narrow One</h2>
      <ul>
      <li>Dynamic Gameplay: Experience fast-paced action and strategic combat in every match.</li>
      <li>Immersive Environments: Explore beautifully crafted medieval settings that enhance your gaming experience.</li>
      <li>Accessibility: Play anytime, anywhere, with no downloads required.</li>
      <li>Community Engagement: Join a vibrant community of players and participate in friendly competitions.</li>
      </ul>
      <h2 class="H22">Are You Ready to Aim and Fire?</h2>
      What are you waiting for? Grab your bow, gather your friends, and jump into the thrilling world of Narrow One! With its perfect blend of competitive gameplay, beautiful environments, and accessible platform, this game promises to keep you entertained for hours. Experience the excitement of archery battles like never before and prove your skills on the battlefield! Play now and let the adventure begin!
      `,
      tag: ["Io","Multiplayer","Fight","Archery","Shooting"],
    hint: ` 
    <ul>
    <li>Engage in multiplayer archery battles in stunning medieval landscapes.</li>
    <li>Controls: Use the mouse to aim and left-click to shoot arrows.</li>
    <li>Choose from various game modes like team battles and capture the flag.</li>
    <li>Work with teammates to outsmart opponents and defend your base.</li>
    <li>Master precision and strategy to achieve victory in every match.</li>
    </ul>
    <br>
    Dive into the action and enjoy the thrill of Narrow One!
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Bubble Pop Classic - Free Online Bubble Shooter Game!",
    name: ["Bubble Pop Classic", "bubble-pop-classic"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-pop-classic/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-pop-classic/520x295.png",
    path: ["/game/bubble-pop-classic", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/200573?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/bubble-pop-classic.mp4",
    s_dis:"Play Bubble Pop Classic, an addictive bubble shooter game! Aim, shoot, and pop bubbles to clear the board through hundreds of challenging levels!",
    l_dis:`
      Step into the vibrant world of Bubble Pop Classic, the ultimate bubble shooter game that has captivated players of all ages. This engaging game combines classic mechanics with stunning graphics and exciting challenges, making it a must-have on your gaming list. Whether you’re a casual player looking for a quick distraction or a dedicated gamer aiming for high scores, Bubble Pop Classic has something for everyone.

      <h2 class="H22">Gameplay Overview</h2>
      In Bubble Pop Classic, your objective is simple yet addictive: aim, shoot, and pop bubbles to clear the board! With intuitive controls, you’ll quickly get the hang of launching colorful bubbles towards matching clusters. The more bubbles you pop in a single shot, the higher your score will be, so strategizing your moves becomes crucial as you progress through the game.
      
      With hundreds of meticulously designed levels, Bubble Pop Classic keeps you entertained for hours. Each level introduces new challenges, obstacles, and special bubbles that require strategic thinking and quick reflexes. The gameplay becomes increasingly complex, making it essential to analyze your shots carefully and plan your next moves.
      
      <h2 class="H22">Stunning Graphics and Sound Effects</h2>
      One of the standout features of Bubble Pop Classic is its vibrant graphics. The game is filled with beautifully designed, colorful bubbles and captivating backgrounds that bring the bubble-popping experience to life. Coupled with delightful sound effects and cheerful music, every shot feels satisfying, making your gameplay experience even more enjoyable.
      
      <h2 class="H22">Family-Friendly Fun</h2>
      Bubble Pop Classic is designed for players of all ages, making it a fantastic choice for family game nights or casual play with friends. The straightforward mechanics mean that anyone can join in on the fun, while the increasing difficulty ensures that even seasoned gamers will find the challenge engaging. Create lasting memories as you compete for the highest scores or work together to clear levels!
      
      <h2 class="H22">Play Anytime, Anywhere</h2>
      In today’s fast-paced world, having access to games that you can play on the go is essential. Bubble Pop Classic is available as an unblocked game, meaning you can enjoy it at school, work, or anywhere with an internet connection. Its quick and casual nature allows you to jump in for a few minutes or settle in for an extended gaming session.
      
      <h2 class="H22">Daily Challenges and Events</h2>
      To keep the excitement going, Bubble Pop Classic regularly features daily challenges and special events. These limited-time opportunities allow you to earn unique rewards, compete against players globally, and showcase your skills on leaderboards. Stay engaged and test your skills against friends and family while aiming for the top!
      
      <h2 class="H22">Community and Social Features</h2>
      Engagement doesn’t stop at gameplay! Join a thriving community of players who share tips, tricks, and strategies to conquer challenging levels. Compete in friendly competitions or engage in social events organized within the game. With the ability to connect with fellow players, Bubble Pop Classic creates a sense of camaraderie and encourages social interaction, enhancing your gaming experience.
      
      <h2 class="H22">Why You’ll Love Bubble Pop Classic</h2>
      <ul>
      <li>Addictive Gameplay: With simple mechanics but plenty of room for strategy, you’ll find yourself coming back for more.</li>
      <li>Engaging Levels: Hundreds of levels offer new challenges, ensuring you’ll never get bored.</li>
      <li>Beautiful Visuals: Enjoy colorful graphics and satisfying sound effects that enhance your gaming experience.</li>
      <li>Family-Friendly: Perfect for all ages, making it ideal for gatherings or casual play.</li>
      <li>Unblocked Access: Play anytime, anywhere, and experience the fun without restrictions.</li>
      </ul>
      <h2 class="H22">Join the Bubble-Popping Adventure!</h2>
      What are you waiting for? Dive into the delightful world of Bubble Pop Classic today! With its perfect blend of fun, strategy, and competition, this game promises hours of entertainment. Grab your bubble shooter, aim for the clusters, and start popping bubbles like never before. Experience the joy of Bubble Pop Classic, where every shot counts and the adventure never ends!
      <br><br>
      Unlock the potential of bubble shooting and become the ultimate bubble-popping champion. Download or play online now, and let the fun begin!
      `,
      tag: ["Bubble",],
    hint: `
      <ul>
      <li>Use the guiding line to aim your shooter and blast bubbles!</li>
      <li>Match 3+ bubbles & shoot to make them pop!</li>
      <li>Swap the bubbles to match & pop more</li>
      <li>Create powerful boosters & items to help you blast through levels.</li>
      <li>Pop all bubbles or collect all gems to complete levels.</li>
      </ul>`,
    rating:[4.4,1210,114],  
  },
 
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Bubble Fall - Addictive 3D Bubble Popping Puzzle Game!",
    name: ["Bubble Fall", "bubble-fall"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-fall/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-fall/520x295.png",
    path: ["/game/bubble-fall", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/200593?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/bubble-fall.mp4",
    s_dis:"Bubble Fall, an addictive bubble shooter unblocked game with vibrant 3D graphics! Perfect for all ages, it sharpens your strategy while offering endless fun!",
    l_dis:`
     Get ready for a brain-boosting adventure with Bubble Fall, the addictive and exciting puzzle game that combines vibrant 3D graphics with challenging bubble-popping gameplay. Perfect for players of all ages, Bubble Fall is designed to entertain while sharpening your strategic skills.
     <h2 class="H22">Game Highlights:</h2>
     <ul>
     <li>Addictive Bubble Puzzle Gameplay: Pop and match colorful bubbles to clear the board and complete each level.</li>
     <li>Stunning 3D Effects: Enjoy vivid, eye-catching graphics and smooth animations that bring the bubbles to life.</li>
     <li>Brain-Training Fun: Each level challenges you to think tactically, testing your puzzle-solving skills and precision.</li>
     <li>Play Anytime, Anywhere: Bubble Fall is completely free to play and available for hours of endless fun.</li>
     </ul>
     <br>
     Whether you're looking for a relaxing game or a challenging puzzle, Bubble Fall provides a fun journey for puzzle fans of all ages. Join the bubble-popping journey today and enjoy brain-teasing excitement that will keep you coming back for more!
     <br><br>
     If you’re a fan of unblocked games and looking for a fun puzzle experience, Bubble Fall is the perfect choice. Available to play for free, this exciting bubble-popping journey offers the thrill of traditional arcade games with modern 3D effects and vibrant visuals. The unblocked version of Bubble Fall means you can play at school, work, or anywhere without restrictions, making it easy to pop bubbles and conquer levels whenever you need a break.
     <br><br>
     Challenge yourself to beat each level with unique strategies while enjoying the endless fun and variety that only free unblocked puzzle games can offer. Bubble Fall’s combination of skill-based gameplay and strategic thinking keeps you engaged as levels progress, making it an ideal choice for casual gamers and puzzle enthusiasts alike. Join the world of unblocked games today with Bubble Fall, where every bubble popped brings you closer to becoming a true puzzle master!
     
    `,
      tag: ["Bubble","Ball",],
    hint: `
    <ul>
     <li>Aim to pop bubbles and clear the board.</li>
     <li>Use arrow keys or tap and drag to aim, then shoot bubbles.</li>
     <li>Match three or more bubbles of the same color to pop them.</li>
     <li>Progress through levels as they get more challenging, testing your strategy and skill.</li>
     <br>
     </ul>Enjoy the colorful, 3D bubble-popping fun of Bubble Fall!
`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Bubble Blast Unblocked - Play Free Online Bubble Shooter Game",
    name: ["Bubble Blast", "bubble-blast"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-blast/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bubble-blast/520x295.png",
    path: ["/game/bubble-blast", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/197352?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/bubble-blast.mp4",
    s_dis:"Dive into Bubble Blast, the ultimate bubble shooter game! Pop colorful bubbles, clear the field, and test your aim and strategy as levels become more challenging!",
    l_dis:`
      Dive into the addictive world of Bubble Blast, the ultimate bubble shooter game where you pop colorful bubbles to clear the entire field! This fun and challenging game starts easy, but as you progress, each level requires more tactical skills and precision. Your goal? To burst all the bubbles before they reach the bottom of the screen, testing your aim and strategy with every shot.
      <h2 class="H22">Key Features:</h2>
      <ul>
      <li>Endless Bubble Popping Fun: Perfect your aim and timing to pop all the bubbles and advance to higher levels.</li>
      <li>Unblocked for School & Work: Play Bubble Blast Unblocked anytime, anywhere for a quick break or just to sharpen your strategic thinking skills.</li>
      <li>Simple Yet Addictive: Easy to start, but challenging to master as new levels require smarter tactics and faster moves.</li>
      <li>High-Quality Graphics & Smooth Gameplay: Enjoy vibrant visuals and satisfying sound effects that enhance your gaming experience.</li>
      </ul><br>
      Bubble Blast Unblocked offers hours of bubble-popping fun that’s perfect for relaxing and improving your tactical thinking. Great for both kids and adults, this free online bubble shooter game is unblocked and ready to play anywhere.
      `,
      tag: ["Bubble",],
    hint: `
    <ul>
      <li>Aim to pop all bubbles before they reach the bottom.</li>
      <li>Use arrow keys and spacebar, or tap and drag to aim and shoot.</li>
      <li>Match three or more bubbles of the same color to clear them.</li>
      <li>Levels get harder, challenging your aim and strategy.</li>
      </ul>
`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Bricks Breaker: Deluxe Crusher - Free Online Ball Game!",
    name: ["Bricks Breaker: Deluxe Crusher", "bricks-breaker-deluxe-crusher"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bricks-breaker-deluxe-crusher/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bricks-breaker-deluxe-crusher/520x295.png",
    path: ["/game/bricks-breaker-deluxe-crusher", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/98401?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/bricks-breaker-deluxe-crusher.mp4",
    s_dis:"Relax your brain with Bricks Breaker: Deluxe Crusher! Touch and shoot balls to break all bricks before they hit the bottom. Enjoy this addictive and challenging game!",
    l_dis:`
       Relax your brain and play the best bricks breaker game this year.
       Touch and shoot balls to break all bricks. You must clear all bricks to finish each level. Find the best aim angle to break a maximum of bricks.
       The bricks are destroyed when their score reach 0. Break the bricks before they hit the bottom of the screen.<br><br>
       Bricks Breaker: Deluxe Crusher is a addictive and challenging brick game.<br>
       Just play it to relax your brain. Be focus on breaking bricks and you will find it more funny and exciting.

       <h2 class="H22">Features</h2>
       <ul>
       <li>Easy to play</li>
       <li>Colorful glow skins.</li>
       <li>Easy game controls with one finger.</li>
       <li>Thousands of stages! Endless game mode.</li>
       <li>More props to get more fun!</li>
       <li>Achievements & leaderboard supported.</li>
       </ul><br>
       Shoot and break bricks!
       Are you ready to raise the challenge?`,
      tag: ["Bricks","Ball",],
    hint: `
    <ul>
     <li>Hold the screen with your finger and move to aim.</li>
     <li>Find best positions and angles to hit all bricks.</li>
     <li>When the durability of brick reaches 0, destroyed.</li>
     <li>Never let bricks reach the bottom or game is over.</li>
     <li>The ball flies to wherever you touched.</li>
     <li>Clear the stages by removing bricks on the board.</li>
     <li>Break the bricks and never let them hit the bottom.</li>
     <li>Find best positions and angles to hit every brick.</li>
     </ul>`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Brawl Stars Mega Simulator - Open Boxes & Level Up!",
    name: ["Brawl Stars Mega Simulator", "brawl-stars-mega-simulator"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/brawl-stars-mega-simulator/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/brawl-stars-mega-simulator/520x295.png",
    path: ["/game/brawl-stars-mega-simulator", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/223558?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/brawl-stars-mega-simulator.mp4",
    s_dis:"Play Brawl Stars Mega Simulator, a fun app for fans! Open boxes to level up fighters and farm resources without real battles for fresh, rewarding gameplay!",
    l_dis:`
       Brawl Stars Mega Simulator is like a playground for Brawl Stars fans, allowing you to dive into the action of opening boxes, leveling up fighters, and farming resources—all without the need for a real battle! Picture it as a treasure chest where every box you open could lead to a new character or exciting upgrade, making your experience feel fresh and rewarding.

      <h2 class="H22">Game Features:</h2>
      <ul>
      <li>All Brawlers Available: Just like having a complete collection of trading cards, you can access every brawler in the game, ensuring you can create the ultimate team.</li>
      <li>Nice Graphics: The visuals are like a vibrant comic book, bringing each character to life with colorful designs that make the game engaging.</li>
      <li>Multilingual Options: With several languages available, it’s like having a tour guide who can speak your language, making it easy for everyone to enjoy.</li>
      <li>Quests and Mini Games: Think of these as side quests in an adventure; they add extra layers of fun and challenge, keeping the gameplay dynamic.</li>
      <li>Exclusive Boxes: These are like rare collectibles, offering unique rewards that spice up the game and enhance your experience.</li>
      <li>Flexible Orientation: You can play both vertically and horizontally, much like adjusting a picture frame to fit your space, allowing for a comfortable gameplay experience.</li>
      </ul>
      <br>
      Keep in mind that Brawl Stars Mega Simulator isn’t a hacking tool for the real game; rather, it's a fan-created guide that simulates the thrilling actions you love. It’s perfect for those times when you want to engage with the Brawl Stars universe while relaxing. Enjoy your time in this visual playground!
`,
      tag: ["Brawl","Simulator",],
    hint: `
    <ul>
          <li>Collect Fighters: Gather various fighters from the game.</li>
     <li>Battle Bots: Fight against bots to earn rewards and practice your skills.</li>
     <li>Farm Cups: Compete in matches to earn cups that help improve your fighters.</li>
     <li>Complete Quests: Finish quests to earn gems for upgrades.</li>
     <li>Mini-Game: Click on crystals faster than your opponents to collect them.</li>
     <li>Upgrade Fighters: Use gems to enhance your fighters' abilities and stats.</li>
     </ul>
`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 0,
    title: "Box Simulator for Brawl Stars - Open Boxes & Upgrade!",
    name: ["Box Simulator for Brawl Stars", "box-simulator-for-brawl-stars"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/box-simulator-for-brawl-stars/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/box-simulator-for-brawl-stars/520x295.png",
    path: ["/game/box-simulator-for-brawl-stars", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/201572?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/box-simulator-for-brawl-stars.mp4",
    s_dis:"Play Box Simulator for Brawl Stars, an exciting app for fans! Open boxes to discover and upgrade fighters, enhancing your gameplay with thrilling new characters!",
    l_dis:`
       Box Simulator for Brawl Stars is an exciting app designed for fans of the popular game Brawl Stars! Dive into the thrill of opening various boxes to discover and upgrade your favorite fighters. With this app, you'll get the chance to open numerous boxes, each offering new and thrilling fighters to enhance your gameplay experience.

       <h2 class="H22">Key Features:</h2>
       <ul>
       <li>Unique Box Opening System: Choose from a variety of boxes to open, strategically increasing your chances of obtaining legendary fighters. The anticipation of what you might get adds to the excitement!</li>
       <li>Upgrade Your Fighters: It’s not just about opening boxes; you can also upgrade your fighters. Use the coins you earn to enhance their health, attack power, and special abilities, making them more formidable in battles.</li>
       <li>Engaging Gameplay: Experience the thrill of discovery as you collect new fighters and improve your existing roster. Each upgrade makes a difference in your strategy and performance.</li>
       <li>Intuitive Interface: The app is designed to be user-friendly, allowing you to navigate effortlessly while focusing on the excitement of unlocking and upgrading fighters.</li>
       </ul>
       <br>Box Simulator for Brawl Stars offers a perfect blend of strategy and fun, making it an essential app for any Brawl Stars enthusiast. Start opening boxes, upgrading your fighters, and preparing for epic battles today!</li>
       
     `,
      tag: ["Brawl","Simulator",],
    hint: `
     Use your mouse for control and navigation.
     What this app is for:
     - Collect all the brawlers in the Box Simulator for Brawl Stars
     - You cannot transfer characters or other valuables from this app to Brawl Stars
     - To show the chances of opening boxes and getting brawlers
     - Complete achievements in the simulation of cool games and get rewards
     - Get an unlimited amount of gems and coins
     - And much more in the Box Simulator for Brawl Stars.`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Block Shooting Merge - Free Online Merging Game ",
    name: ["Block Shooting Merge", "block-shooting-merge"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/block-shooting-merge/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/block-shooting-merge/520x295.png",
    path: ["/game/block-shooting-merge", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/263758?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/block-shooting-merge.mp4",
    s_dis:"Block Shooting Merge, is an online free 2048 game that mixes classic merging with strategy! Reach impressive numbers like 2048 and challenge your skills!",
    l_dis:`
       Block Shooting Merge is an innovative twist on the classic number merge puzzle genre that combines easy gameplay with challenging strategy. Show off your skills and compete against other puzzlers as you aim to reach impressive numbers like 1024, 2048, 4096, 8192, and even 16384! It’s simple to pick up but tough to master, making it a perfect challenge for players of all skill levels.

      <h2 class="H22">Features of Block Shooting Merge:</h2>
      <ul>
      <li>Next Number Preview: The game provides hints by displaying the next number puzzle blocks, helping you strategize your moves.</li>
      <li>Swap Blocks: If you find a better arrangement, easily swap blocks to optimize your gameplay.</li>
      <li>Brain Training: While it’s a fun time-killer, the game also sharpens your lateral thinking skills and enhances your problem-solving abilities.</li>
      <li>Global Leaderboard: Compete with players worldwide, showcasing your high score and world ranking to see how you stack up against others.</li>
      <li>Sleek Graphics: Enjoy a simple yet modern graphic design that keeps your focus on the gameplay.</li>
      <li>No Download Required: Just click and play! Dive right into the action without the hassle of downloads.</li>
      </ul>
      <br>
      Challenge yourself with Block Shooting Merge and share your best scores with friends. It’s time to merge those numbers and show just how smart you are!
       `,
      tag: ["Brain","2048","Merge",],
    hint: `
    <ul>
     <li>You will be given numbers to play and count</li>
     <li>Shoot the number block at bottom or beside to merge</li>
     <li>Keep merging to avoid losing the game!</li>
     <li>If it is too tricky, check your next number block and plan your next move!</li>
     </ul>`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Block Puzzle Sudoku - Free Online Logic Puzzle Game",
    name: ["Block Puzzle Sudoku", "block-puzzle-sudoku"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/block-puzzle-sudoku/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/block-puzzle-sudoku/520x295.png",
    path: ["/game/block-puzzle-sudoku", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/192462?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/block-puzzle-sudoku.mp4",
    s_dis:"Play Block Puzzle Sudoku, an online free puzzle game that blends traditional block puzzles with Sudoku! Fill a 9x9 grid to clear lines and beat your high score!",
    l_dis:`
    Block Puzzle Sudoku combines the best elements of traditional block puzzles with the strategic thinking of Sudoku, creating a calming yet challenging experience that will keep you hooked! This easy-to-play game invites you to place blocks on a 9x9 grid, aiming to fill rows, columns, or squares to clear them from the board. Test your skills and play as long as you can without running out of space to beat your high score!

    <h2 class="H22">Features of Block Puzzle Sudoku:</h2>
    <ul>
    <li>Stunning Graphics: Enjoy beautiful visuals and satisfying sound effects that enhance your gaming experience.</li>
    <li>Tactile Experience: Immerse yourself in the game with a realistic classic-blue tile design that feels great to interact with.</li>
    <li>Relaxing Gameplay: Play at your own pace without any pressure or time limits, making it perfect for unwinding after a long day.</li>
    <li>Lightweight Design: A small game that won’t take up much space on your device, ensuring you can enjoy it anywhere.</li>
    <li>Train Your Brain: This classic-blue style block game is perfect for relaxing while also engaging your mind.</li>
    </ul>
    <br>
    Whether you're at home or on the go, Block Puzzle Sudoku is the ideal game for anyone looking to enjoy a blend of strategy and relaxation. Dive in and experience the fun today!   
    
    `,
      tag: ["Puzzle","Block","Sudoku","Logic",],
    hint: `
    <ul>
      <li>Drag shapes onto the board to place them in the grid.</li>
      <li>Fill a row, column, or square to clear blocks from the board.</li>
      <li>Clear multiple rows, regions, or squares to earn Combo points!</li>
      <li>Clear blocks on every turn to earn Streak points!</li>
      <li>Earn as many points as you can to beat your high score!</li>
      <li>Have yourself a classic-blue good time!</li>
      </ul>`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Block Puzzle Adventure - Play Free Online Block Game Now",
    name: ["Block Puzzle Adventure", "block-puzzle-adventure"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/block-puzzle-adventure/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/block-puzzle-adventure/520x295.png",
    path: ["/game/block-puzzle-adventure", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/188408?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/block-puzzle-adventure.mp4",
    s_dis:"Play Block Puzzle Adventure, an engaging online free puzzle game! Crush blocks and strategize to fit pieces perfectly while racking up high scores for endless fun!",
    l_dis:`
       Block Puzzle Adventure offers an easy-to-learn yet challenging gameplay experience that will keep you engaged for hours! This wood-style block game invites players to crush blocks and rack up high scores as they strategize to fit pieces into the perfect spots. The more blocks you eliminate, the more satisfying your gaming experience becomes!

       <h2 class="H22">Features of Block Puzzle Adventure:</h2>
       <ul>
         <li>Endless Fun: Immerse yourself in hours of exciting gameplay, designed to captivate puzzle enthusiasts.</li>
         <li>Challenging Gameplay: While the mechanics are simple, mastering the game requires skill and strategy, making every victory rewarding.</li>
         <li>Classic Meets Innovative: This isn’t just a typical wood puzzle; it enhances classic brick gameplay with fresh twists that keep things interesting.</li>
         <li>No Time Limits: Play at your own pace without the pressure of time constraints, allowing for a more relaxed experience.</li>
         <li>Completely Free: Enjoy this classic wood block puzzle game without any costs, making it accessible to everyone.</li>
         <li>Play Anywhere, Anytime: Perfect for on-the-go gaming, you can dive into a session of Block Puzzle Adventure whenever you feel like it.</li>
       </ul>
       <br>
       Whether you’re looking to relax or sharpen your brain, Block Puzzle Adventure is the perfect game for you. So grab your blocks and let the fun begin!`,
      tag: ["Board","Brain","Block","Puzzle",],
    hint: `
    <ul>
      <li>Place the pieces into the board. Once you fill in a vertical or horizontal line, it will disappear, freeing up space for new pieces.</li>
      <li>The game will be over if there is no space for any of the given blocks below the board.</li>
      </ul>`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 1,
    title: "Bird Sort Puzzle - Play Free Online Sorting Game",
    name: ["Bird Sort Puzzle", "bird-sort-puzzle"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bird-sort-puzzle/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bird-sort-puzzle/520x295.png",
    path: ["/game/bird-sort-puzzle", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/192404?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/bird-sort-puzzle.mp4",
    s_dis:"Play Bird Sort Puzzle, an engaging online free sorting game! Arrange colorful birds in this charming puzzle to challenge your brain and improve your IQ!",
    l_dis:`
       Bird Sort Puzzle is an engaging and unique sorting puzzle game that adds a fresh twist with colorful birds and a charming art style. Designed to be fun and mentally stimulating, Bird Sort Puzzle challenges you to arrange and sort vibrant birds, making it a delightful way to train your brain and test your IQ. With simple, one-finger controls, this game is accessible for players of all ages.
      <h2 class="H22">Features:</h2>
       <ul>
       <li>Unlimited playtime: Enjoy Bird Sort Puzzle anytime without limits or costs.</li>
       <li>Easy to learn, hard to master: Its simplicity is inviting, but each level brings new challenges that require strategy and skill.</li>
       <li>High-quality graphics and sound: Each level is packed with vivid visuals and pleasing sounds for an immersive experience.</li>
       <li>Innovative flying bird sorting concept: Unlike typical sorting games, Bird Sort features a dynamic twist as you manage flying birds.</li>
       <li>Varied levels: Take on increasingly challenging puzzles that require strategy and planning.</li>
       </ul>
       <br>
       Enjoy super-fun challenges and brain-teasing puzzles by playing Bird Sort Puzzle for free today!
    `,
      tag: ["Birds","Sort","Match",],
    hint: `
      <ul>
      <li>Move the bird by touching it and then touching the branch you want it to move to.</li>
      <li>The rule is that you can only move the birds if it has same color and there's enough space on the tree branch.</li>
      <li>Try not to get stuck. If you get stuck you can just go back step by step using the back button or restart the level at any time using the restart button.</li>
      </ul>
      `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-25",
    brand: "Yandex",
    orientation: 0,
    title: "Basket Random - Play Free Online Basketball Game | 1 & 2 Player",
    name: ["Basket Random", "basket-random"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/basket-random/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/basket-random/520x295.png",
    path: ["/game/basket-random", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/197304?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/yandex/basket-random.mp4",
    s_dis:"Play Basket Random, an online free sports game with fast-paced, unpredictable basketball action! Outscore your opponent solo or in unblocked 2-player mode!",
    l_dis:`
       Basket Random is a quirky, fast-paced basketball game where you only need a single key to control your player! With each round, the court, players, and even the ball change, keeping you on your toes and adding a layer of fun unpredictability.
       <br><br>
       The objective is simple: outscore your opponent by reaching 5 points first. You can take on the CPU for a solo challenge, or face off against a friend in 2-player mode for some head-to-head action. Each match is filled with wild moments as you adapt to the constantly shifting elements, making every playthrough unique.
       <br><br>
       Get ready for some hilarious, chaotic basketball fun in Basket Random—prove your skill and become the champion!
       `,
      tag: ["Sports","Ball","Player","Ragdoll"],
    hint: `
    <ul>
    
      <li>Player 1: "W"</li>
      <li>Player 2: "UP ARROW KEY"</li>
      <li>You can play the game by touch controls on mobile devices or tablets.</li>
      </ul>`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-24",
    brand: "Yandex",
    orientation: 1,
    title: "Alternation Solitaire - playcutegames.com",
    name: ["Alternation Solitaire", "alternation-solitaire"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/alternation-solitaire/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/alternation-solitaire/520x295.png",
    path: ["/game/alternation-solitaire", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/97729?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/alternation-solitaire.mp4",
    s_dis:"Alternation Solitaire Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`
    Alternation is a unique type of solitaire card game that uses two decks of playing cards and bears some resemblance to the popular game of Klondike. What sets Alternation apart is the arrangement of the cards on the tableau: half of the cards are placed face-up while the other half remain face-down, and they alternate in this specific pattern across the tableau.
    <br><br>
    The objective of the game is similar to other solitaire variants—you need to build foundations starting from Ace and ascending to King for each suit. However, the alternating face-up and face-down layout adds an extra layer of challenge and strategy to the game. Players must carefully consider which face-up cards to move, while working to reveal the face-down cards to progress further.
    <br><br>
    The game’s alternating format requires strategic thinking and patience as you work to uncover the hidden cards and build the foundation piles in sequence. If you're looking for a fun and challenging twist on classic solitaire games, Alternation offers an intriguing and enjoyable experience!
    `,
      tag: ["Card",],
    hint: `
      Move all the cards to the Foundations.
      <br><br>
      <ul>
      <li>The Foundations are built up by suit from Ace to King.</li>
      <li>The tableau piles can be built down regardless of suit. Every face-up card in a partial pile, or a complete pile, can be moved, as a unit. Any empty piles can be filled with any card.</li>
      <li>You may only pass through the deck once.</li>
      <li>Double-click on a card to move it into its place.</li>
      <li>Double-click or right-click on the game field to move all available cards into its place.</li>
      </ul>
      `,
    rating:[4.4,1210,114],  
  },
    
  {
    date: "2023-10-10",
    brand: "playcutegames",
    orientation: 0,
    title: "Peppa Pig's Halloween Party: Dress Up Game for Spooky Fun!",
    name: ["Peppa Pig’s Halloween Party", "peppa-pigs-halloween-party"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/peppa-pigs-halloween-party/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/peppa-pigs-halloween-party/520x295.png",
    path: ["/game/peppa-pigs-halloween-party", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/peppa-pigs-halloween-party/",
    Walkthrough: "https://www.youtube.com/embed/WX9e1yLEpgk",
    s_dis:
    "Join Peppa Pig's Halloween Party and dress up Peppa, Daddy Pig, and Mummy Pig in creative costumes! Enjoy spooky fun in this exciting dress-up game for kids.",
    l_dis:
      `Welcome to Peppa Pig’s Halloween Party, a delightful and playful dress-up game where you get to style Peppa Pig, Daddy Pig, and Mummy Pig for the spookiest event of the year! Join the fun as Peppa and her family get ready for a night filled with tricks, treats, and tons of Halloween fun. It's your job to make sure they look their best for the party, with plenty of creative costume options to choose from!
      <h2 class="H2">Dress Up for Halloween Fun</h2>
      In Peppa Pig’s Halloween Party, you’re the family stylist! Use your creativity to dress up Peppa, Daddy Pig, and Mummy Pig in the most exciting and fun Halloween costumes. With a range of outfits inspired by witches, wizards, pumpkins, ghosts, and more, you can mix and match pieces to create the perfect Halloween look for each character.
      <h2 class="H2">Game Features</h2>
      <ul>
      <li>Peppa’s Wardrobe: Peppa Pig has a whole collection of Halloween costumes, from a classic witch to a cute pumpkin or something spooky and mysterious. Help her stand out at the party!</li>
      <li>Daddy Pig’s Outfit: Make Daddy Pig the star of the show with funny, quirky, and creative costumes that will bring a smile to everyone’s face.</li>
      <li>Mummy Pig’s Style: Add a touch of elegance to the spooky fun with Mummy Pig’s costume options. Whether it's glamorous or spooky, Mummy Pig will be party-ready!</li>
      </ul>
      <h2 class="H2">Why You'll Love It</h2>
      Peppa Pig’s Halloween Party is perfect for kids who love Peppa Pig and Halloween! With colorful costumes, fun characters, and endless possibilities for dress-up creativity, this game provides hours of festive entertainment. Whether you want to create a spooky or silly look, this dress-up game is perfect for getting into the Halloween spirit!
      <br><br>
      Start your styling adventure today and make Peppa and her family the best-dressed characters at the Halloween party!
      <br><br>
      If you’re enjoying Peppa Pig’s Halloween Party, make sure to check out other <a href="/categories/halloween-games">Halloween Games</a> in the library.
      `,
      tag: ["Peppa","Dress up","Halloween","Exclusive","Girl games"],
    hint: `
    Use your Mouse on the desktop or tap to select the best outfit for Peppa, Daddy Pig, and Mummy Pig. Once you’ve dressed up the whole family, watch as they get ready for the party in their new Halloween costumes!
    <br><br>
    Join Peppa and her family in Peppa Pig’s Halloween Party and let the spooky celebration begin! Whether you’re going for fun or fright, the perfect Halloween look is just a click away.`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "Others",
    orientation: 0,
    title: "Ducklings.io - Play Free Online Multiplayer Game | Grow Your Family!",
    name: ["Ducklings.io", "ducklings-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ducklings-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ducklings-io-300.jpg",
    path: ["/game/ducklings-io", "https://playcutegames.com"],
    iframe: "https://ducklings.io/",
    Walkthrough: "https://www.youtube.com/embed/4fZjNhZijfw",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/ducklings-io.mp4",
    s_dis:
    "Play Ducklings.io now! Control a cute duck, collect ducklings, and build your family while avoiding dangers and outsmarting other players in this relaxing multiplayer game!",
    l_dis:
      `
      Ducklings.io is one of the most popular and engaging multiplayer games on the web, combining classic snake gameplay with modern online competition. This thrilling twist has captivated millions of players worldwide, offering an exciting mix of strategy, quick reflexes, and competitive gameplay.
      <br><br>
      In Slither.io, you take control of a vibrant snake (or “slither”) that grows longer as it consumes glowing orbs scattered throughout the arena. As you navigate the map, your primary goal is to grow as large as possible while avoiding collisions with other snakes. The mechanics are straightforward: if your snake’s head collides with another snake, you’ll be eliminated, but if you manage to outmaneuver opponents, you can trap them, causing them to crash and leave behind a bounty of orbs for you to feast on.
      <br><br>
      The game’s appeal lies in its simplicity and addictive nature. Whether you’re aiming for a quick round or striving to dominate the leaderboard, Slither.io offers endless hours of fun. You can choose from various skins to personalize your snake, and the vibrant graphics create a lively gaming experience. Join players from around the globe, showcase your skills, and see if you can become the longest snake in this fast-paced online arena!
      `,
      tag: ["Io","Multiplayer","Snake"],
    hint: ` 
    <ul>
    <li>Objective: Grow your snake by eating glowing orbs while avoiding collisions with other snakes.</li>
    </ul>
    <br>
    <h3 class="H22">Controls:</h3>
    <ul>
    <li>Move: Use your mouse to navigate your snake.</li>
    <li>Boost Speed: Press the spacebar to temporarily speed up, but it will reduce your length.</li>
    </ul>
    <br>
    <h3 class="H22">Tips:</h3>
    <ul>
    <li>Stay agile and keep moving to avoid being caught by larger snakes.</li>
    <li>Use boosts strategically to escape danger or close in on prey.</li>
    <li>Observe other snakes' movements to find opportunities for growth.</li>
    </ul>
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "Others",
    orientation: 0,
    title: "Slither.io - Play Free Online io Snake Game | Grow & Compete!",
    name: ["Slither.io", "slither-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/slither-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/slither-io-300.jpg",
    path: ["/game/slither-io", "https://playcutegames.com"],
    iframe: "https://gulper.io/",
    Walkthrough: "https://www.youtube.com/embed/4fZjNhZijfw",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/slither-io.mp4",
    s_dis:
    "Play Slither.io now! Control a snake that grows by eating glowing orbs while avoiding other snakes. Can you grow as large as possible without crashing?",
    l_dis:
      `
      Slither.io is one of the most popular and engaging multiplayer games on the web, combining classic snake gameplay with modern online competition. This thrilling twist has captivated millions of players worldwide, offering an exciting mix of strategy, quick reflexes, and competitive gameplay.
      <br><br>
      In Slither.io, you take control of a vibrant snake (or “slither”) that grows longer as it consumes glowing orbs scattered throughout the arena. As you navigate the map, your primary goal is to grow as large as possible while avoiding collisions with other snakes. The mechanics are straightforward: if your snake’s head collides with another snake, you’ll be eliminated, but if you manage to outmaneuver opponents, you can trap them, causing them to crash and leave behind a bounty of orbs for you to feast on.
      <br><br>
      The game’s appeal lies in its simplicity and addictive nature. Whether you’re aiming for a quick round or striving to dominate the leaderboard, Slither.io offers endless hours of fun. You can choose from various skins to personalize your snake, and the vibrant graphics create a lively gaming experience. Join players from around the globe, showcase your skills, and see if you can become the longest snake in this fast-paced online arena!
      `,
      tag: ["Io","Multiplayer",],
    hint: ` 
    <ul>
    <li>Objective: Grow your snake by eating glowing orbs while avoiding collisions with other snakes.</li>
    </ul>
    <br>
    <h3 class="H22">Controls:</h3>
    <ul>
    <li>Move: Use your mouse to navigate your snake.</li>
    <li>Boost Speed: Press the spacebar to temporarily speed up, but it will reduce your length.</li>
    </ul>
    <br>
    <h3 class="H22">Tips:</h3>
    <ul>
    <li>Stay agile and keep moving to avoid being caught by larger snakes.</li>
    <li>Use boosts strategically to escape danger or close in on prey.</li>
    <li>Observe other snakes' movements to find opportunities for growth.</li>
    </ul>
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "Others",
    orientation: 0,
    title: "Diep.io - Free Online io Survival Game | Destroy & Upgrade!",
    name: ["Diep.io", "diep-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/diep.io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/diep-io-300.jpg",
    path: ["/game/diep-io", "https://playcutegames.com"],
    iframe: "https://games.crazygames.com/en_US/diepio/index.html",
    Walkthrough: "https://www.youtube.com/embed/4fZjNhZijfw",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/diep-io.mp4",
    s_dis:
    "Diep.io is a survival io game where you control a cannon-armed circle. Move around the map, destroy opponents, and upgrade your circle to dominate the game!",
    l_dis:
      `
      Diep.io is an exciting survival .io game where you take control of a customizable circle armed with a powerful cannon. Your mission is straightforward yet thrilling: navigate the expansive map, destroy other players, and upgrade your tank to become the ultimate champion.
      <br><br>
      As you move around the battlefield, you'll encounter various enemies and obstacles. Using your cannon, you can shoot at opponents to eliminate them and gain valuable experience points. Each enemy you destroy contributes to your progression, allowing you to level up and unlock a range of upgrades. Choose from different tank classes, each with unique abilities and strengths, to tailor your gameplay to your style.
      <br><br>
      Strategy plays a crucial role in Diep.io. Positioning, timing, and understanding your tank's capabilities are key to outsmarting your opponents. As you gain experience, you can invest in upgrades such as increased firepower, speed, and durability, making your tank more formidable in combat.
      <br><br>
      With its engaging mechanics, competitive multiplayer environment, and visually appealing graphics, Diep.io offers endless fun for players of all skill levels. Whether you’re a casual gamer or a competitive strategist, jump into the arena, unleash your cannon, and prove your dominance in this captivating survival game!
      `,
      tag: ["Io","Multiplayer",],
    hint: ` 
    <ul>
    <li>Objective: Survive by destroying other players and upgrading your tank.</li>
    </ul>
    <br>
    <h3 class="H22">Controls:</h3>
    <ul>
    <li>Move: Use the WASD keys or arrow keys to navigate your tank.</li>
    <li>Aim and Shoot: Move your mouse to aim, and left-click to fire your cannon.</li>
    </ul>
    <br>
    <h3 class="H22">Tips:</h3>
    <ul>
    <li>Shoot at other players to earn experience points (XP) and level up your tank.</li>
    <li>When you level up, you can choose upgrades to enhance your tank's abilities (e.g., health, damage, speed).</li>
    <li>Destroy objects (like squares and triangles) on the map to gain XP and improve your tank.</li>
    <li>Avoid getting hit by other players' shots and be aware of your surroundings to survive.</li>
    
    <li>Focus on upgrading your tank strategically based on your play style.</li>
    <li>Use the environment for cover while attacking other players.</li>
    <li>Practice aiming to improve your shooting accuracy.</li>
    </ul>
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "Others",
    orientation: 0,
    title: "Hole.io - Play Free Multiplayer Survival Game Online",
    name: ["Hole.io", "hole-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/hole-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/hole-io-300.jpg",
    path: ["/game/hole-io", "https://playcutegames.com"],
    iframe: "https://hole-io.com/",
    Walkthrough: "https://www.youtube.com/embed/4fZjNhZijfw",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/hole-io.mp4",
    s_dis:
    "Welcome to Hole.io, a thrilling multiplayer survival game blending strategy, exploration, and resource management in a vibrant, animated world. Test your skills today!",
    l_dis:
      `
      Welcome to the thrilling universe of Hole.io, a fast-paced multiplayer game that immerses you in the exciting role of a black hole. Your mission? Consume everything in sight to grow larger while competing against other players in a dynamic, ever-changing environment.
      <br><br>
      In Hole.io, you’ll start small, but as you devour objects, cars, buildings, and even entire landscapes, your black hole will expand in size and power. However, you’ll need to stay alert, as other players are racing to grow their own black holes and can swallow you if they become larger! It’s a fast-paced competition where only the biggest and most strategic will dominate.
      <br><br>
      The game features vibrant graphics and a variety of environments, from bustling cities to open landscapes, giving you endless possibilities for destruction and growth. The addictive gameplay keeps you coming back for more as you aim to outmaneuver and outconsume your opponents to climb the leaderboard.
      <br><br>
      Whether you’re looking for a quick, casual game or intense multiplayer competition, Hole.io offers a fun, competitive experience that challenges your reflexes and strategy skills. Jump in and see if you have what it takes to become the biggest black hole in the game!
      `,
      tag: ["Io","Multiplayer",],
    hint: ` 
    <ul>
    <li>Objective: Grow your black hole by consuming objects and competing against other players</li>
    <li>Move: Use your mouse or arrow keys to navigate your black hole.</li>
    <li>Start by devouring small items (like trash cans and cars) to grow larger.</li>
    <li>As you increase in size, you can consume larger objects, including buildings and landscapes.</li>
    <li>Stay alert! Other players can consume you if they are larger, so always be on the move.</li>
    <li>The goal is to be the biggest black hole by the end of the round and climb the leaderboard.</li>
    </ul>
    <br><br>
    Stay aware of your surroundings, use splitting tactics, and always adapt to the fast-paced action.
    
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "Others",
    orientation: 0,
    title: "Taming.io - Play Free Multiplayer Survival Game Online",
    name: ["Taming.io", "taming-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/taming-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/taming-io-300.jpg",
    path: ["/game/taming-io", "https://playcutegames.com"],
    iframe: "https://taming.io/",
    Walkthrough: "https://www.youtube.com/embed/ppa8YpWz6j0",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/taming-io.mp4",
    s_dis:
    "Welcome to Taming.io, a thrilling multiplayer survival game blending strategy, exploration, and resource management in a vibrant, animated world. Test your skills today!",
    l_dis:
      `
      Welcome to Taming.io, an exhilarating multiplayer survival game where strategy, exploration, and resource management collide in a colorful, animated universe. In this dynamic game, your objective is to tame wild creatures, gather resources, and build defenses to survive against both the environment and other players.
      <br><br>
      As you explore the vast world of Taming.io, you'll encounter various animals that you can tame to assist in your survival. From ferocious wolves to mythical beasts, each creature brings unique abilities that can aid you in battle or gathering resources. But be prepared! The world is full of dangers, including rival players who will challenge you in your quest to become the ultimate survivor.
      <br><br>
      With an intuitive crafting system, you can gather materials to construct buildings, tools, and defenses, allowing you to fortify your base and protect yourself from enemies. Strategy is key as you balance resource gathering, creature taming, and combat tactics to stay alive in this thrilling adventure.
      <br><br>
      Whether you're a seasoned survival gamer or a newcomer to the genre, Taming.io offers a unique and captivating experience that will test your skills and immerse you in a beautifully animated world.
      `,
      tag: ["Io","Multiplayer",],
    hint: ` 
    <ul>
    <li>Objective: Survive by taming creatures, gathering resources, and defending yourself.</li>
    <li>Move: Use arrow keys or WASD to explore.</li>
    <li>Attack: Left-click or press the spacebar.</li>
    <li>Interact: Click to tame creatures or collect resources.</li>
    <li>Find and tame wild animals to help in battles or resource gathering.</li>
    <li>Collect materials like wood and stone to craft tools, buildings, and defenses.</li>
    </ul>
    <br><br>
    Stay aware of your surroundings, use splitting tactics, and always adapt to the fast-paced action.
    
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "Others",
    orientation: 0,
    title: "Agar.io - Play Free Online IO Game Instantly | No Download",
    name: ["Agar.io", "agar-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/agar-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/agar-io-300.jpg",
    path: ["/game/agar-io", "https://playcutegames.com"],
    iframe: "https://agar.cc/",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/agar-io.mp4",
    s_dis:
    "Play Agar.io and other free online IO games instantly—no download needed! Jump into fast-paced multiplayer action and compete with players worldwide!",
    l_dis:
      `
      Play Agar.io online with players from around the world and strive to become the largest cell in the game! Starting as a tiny cell, your objective is simple: eat other players to grow bigger while avoiding being eaten by those larger than you. It’s a thrilling survival game where strategy and quick reflexes are crucial to becoming the dominant force in the arena.
      <br><br>
      With easy-to-use controls tailored for both touchscreens and desktop, Agar.io offers the same addictive gameplay that millions have enjoyed on PC. As you play, you can use various tactics like splitting your cell to cover more ground, shrinking to dodge enemies, or strategically positioning yourself to trap smaller cells.
      <br><br>
      The free-for-all action creates an ever-changing and exciting dynamic, where players must constantly adapt to their surroundings. To make things even more fun, you can unlock special secret skins by choosing the right username, giving you a unique look as you dominate the game.
      <br><br>
      Can you outsmart and outmaneuver other players to survive and grow? Jump into the action, and see if you can become the biggest cell in the game!
      `,
      tag: ["Io","Multiplayer",],
    hint: ` 
    <ul>
    <li>Move: Use your mouse or touch to guide your cell.</li>
    <li>Split: Press the spacebar to split your cell and cover more ground.</li>
    <li>Eject Mass: Press W to shoot out some mass.</li>
    <li>Winning: Survive, grow, and become the biggest cell in the arena!</li>
    </ul>
    <br><br>
    Stay aware of your surroundings, use splitting tactics, and always adapt to the fast-paced action.
    
    `,
    rating:[4.4,1210,114],  
  },
  
  {
    date: "2023-10-10",
    brand: "Others",
    orientation: 0,
    title: "ShellShock.io: Battle-Packed Kart Racing Multiplayer Io Game",
    name: ["ShellShock.io", "shellshock-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/shellshock-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/shellshock-io-300.jpg",
    path: ["/game/shellshock-io", "https://playcutegames.com"],
    iframe: "https://shellshock.io/",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/shellshock-io.mp4",
    s_dis:
    "Play ShellShock.io, a fast-paced multiplayer kart racing game. Power up, outsmart opponents, and dominate the action-packed arena online for free!",
    l_dis:
      `
      If you’re looking for a fast-paced, adrenaline-pumping online shooter with a unique twist, ShellShock.io is the game for you. This multiplayer browser-based game has taken the shooter genre to a whole new level by putting players in control of—you guessed it—battle-hardened eggs. Armed with an arsenal of powerful weapons and explosive tactical skills, you’ll dive into chaotic combat against players from around the world.
      <br><br>
      <h2 class="H2">Gameplay Overview: Crack Your Enemies Before They Crack You</h2>
      In ShellShock.io, the objective is simple: survive and eliminate your opponents in a variety of game modes. Whether you're playing in a classic free-for-all or working with a team to capture objectives, the game is always action-packed. Each match places you in a vibrant, dynamic arena where every egg is fighting for survival.
      <br><br>
      With fluid controls and precise shooting mechanics, the gameplay feels crisp and responsive. The egg-based characters add a layer of humor, but don’t be fooled—the combat is intense, requiring quick reflexes and tactical thinking to dominate.
      <h2 class="H2">Weapons, Customization, and Power-Ups</h2>
      ShellShock.io offers a wide selection of weapons to suit your playstyle. Whether you prefer the precision of a sniper rifle or the raw power of a rocket launcher, there's something for everyone. You can also customize your egg character with skins and accessories, giving your egg a unique look while you rule the battlefield.
      <br><br>
      Power-ups scattered across the map give you an extra edge, whether it’s a speed boost, health recovery, or extra ammo to take down your rivals.
      <h2 class=H2>Multiplayer Mayhem and Community Fun</h2>

      At the heart of ShellShock.io is its thriving multiplayer community. Matchmaking ensures you’ll always find an exciting battle, whether you're playing with friends or competing against random players. The community also contributes with custom skins, strategies, and even live streams, adding to the game’s lively and engaging atmosphere.

      <h2 class=H2>Final Thoughts: A Must-Play Multiplayer Shooter</h2>

      ShellShock.io is the perfect combination of fast-paced action and quirky fun, appealing to both casual gamers and competitive shooter fans. With its accessible gameplay, tactical depth, and humorous egg-based theme, it’s a standout game that’s easy to jump into but hard to master. Best of all, it's free to play in your browser, making it a great way to dive into quick, exciting matches without needing to download anything.
      <br><br>
      So what are you waiting for? Load up your favorite weapon, customize your egg, and crack into the action with ShellShock.io today!
      `,
      tag: ["Io","Multiplayer","Shooting",],
    hint: `
    The controls in ShellShock.io are straightforward and easy to grasp, even for beginners:
    <br><br>
    <ul>
    <li>WASD keys: Move your character around the map.</li>
    <li>Mouse: Aim your weapon.</li>
    <li>Left-click: Fire your weapon.</li>
    <li>Right-click: Zoom in for precision aiming (for weapons like sniper rifles).</li>
    <li>Spacebar: Jump.</li>
    <li>Shift: Crouch for more stability when shooting.</li>
    <li>Q: Switch between weapons.</li>
    </ul>
    <br>
    Mastering these controls is key to staying mobile and outsmarting your enemies.
    <h2 class="H2">Game Modes</h2>
    ShellShock.io offers several game modes, including:
    <br><br>
    <ul>
    <li>Free-For-All (FFA): Every player is on their own. The goal is to score the most eliminations before the time runs out.</li>
    <li>Team-Based Matches: Two teams face off in combat, and the team with the most points at the end wins.</li>
    <li>Capture the Spatula: This mode mimics a "Capture the Flag" style of play, where teams must capture the enemy’s spatula and return it to their base while protecting their own.</li>
    </ul>
    <br>Choose the game mode that best fits your playstyle or mood.

    <h2 class="H2">Choosing Your Weapons</h2>
    As you play, you’ll have access to a variety of weapons, each with its strengths and weaknesses:
    <br><br>
    <ul>
    <li>Assault Rifles: Balanced for mid-range combat, offering a good rate of fire and accuracy.</li>
    <li>Sniper Rifles: Perfect for long-distance shots but slower to reload.</li>
    <li>Shotguns: Great for close-quarters combat, delivering high damage up close.</li>
    <li>Rocket Launchers: Powerful weapons that deal splash damage, ideal for taking out multiple enemies at once.</li>
    </ul>
    <br>As a beginner, it’s a good idea to experiment with different weapons to find what suits you best.
    
    <h2 class="H2">Power-Ups and Health</h2>
    Throughout the maps, you’ll find various power-ups that can give you a competitive edge, such as:
    <br><br>
    <ul>
    <li>Health packs: Replenish your health during battle.</li>
    <li>Ammo refills: Ensure you don’t run out of bullets in the middle of a fight.</li>
    <li>Speed boosts: Help you outmaneuver opponents.</li>
    </ul>
    <br>Grab these power-ups whenever you can to stay ahead of your enemies.

    <h2 class="H2">Tips for Success</h2>
    <ul>
    <li>Stay Mobile: Standing still makes you an easy target. Keep moving, jumping, and dodging to avoid enemy fire.</li>
    <li>Learn the Maps: Knowing the map layout helps you find power-ups, good hiding spots, and ambush points.</li>
    <li>Use Cover: Take advantage of walls and obstacles to shield yourself from enemy fire.</li>
    <li>Customize Your Egg: Personalize your egg with skins and accessories to stand out in battle and give your character a unique look.</li>
    </ul>
    <h2 class="H2">Multiplayer and Matchmaking</h2>
    You can either play solo or invite friends to join you in private matches. The game’s matchmaking system ensures that players of similar skill levels are matched together, offering a balanced and fun experience.
    `,
    rating:[4.4,1210,114],  
  },
 
  {
    date: "2024-03-05",
    brand: "playcutegames",
    orientation: 0,
    hot:"1x1",
    title: "Roblox Halloween Costume Party – Ultimate Roblox Dress-Up Game",
    name: ["Roblox Halloween Costume Party", "roblox-halloween-costume-party"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/roblox-halloween-costume-party/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/roblox-halloween-costume-party/520x295.png",
    path: ["/game/roblox-halloween-costume-party", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/roblox-halloween-costume-party/",
    Walkthrough: "https://www.youtube.com/embed/DcXvkNvuQnk",
    s_dis:
    "Create unique Halloween costumes in Roblox! Compete in contests, mix and match outfits, and show off your style in this festive dress-up game.",
    l_dis:
      "Roblox Halloween Costume Party is the ultimate dress-up experience that brings out your creativity! Dive into a world of Roblox-inspired costumes and accessories, where you can create your spookiest, funniest, or most imaginative Halloween looks. Mix and match a variety of outfits to make sure you stand out at the party. Whether you're going for scary, cute, or quirky, the possibilities are endless! Compete with friends in costume contests, show off your unique style, and immerse yourself in the festive Halloween spirit. Get ready for fun-filled fashion in this exciting holiday-themed game!",
      tag: ["Halloween","Dress up","Exclusive","Party","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },

  
  {
    date: "2023-10-10",
    brand: "Others",
    orientation: 0,
    title: "Smash Karts: Battle-Packed Kart Racing Multiplayer Io Game",
    name: ["Smash Karts", "smash-karts"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/smash-karts/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/smash-karts-300.jpg",
    path: ["/game/smash-karts", "https://playcutegames.com"],
    iframe: "https://smashkarts.io/",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/smash-karts.mp4",
    s_dis:
    "Play Smash Karts, a fast-paced multiplayer kart racing game. Power up, outsmart opponents, and dominate the action-packed arena online for free!",
    l_dis:
      `
      Welcome to Smash Karts, a thrilling online multiplayer game that perfectly combines fast-paced kart racing with chaotic battle mechanics. Strap in and get ready to rev your engines, power up your weapons, and outmaneuver your opponents in vibrant, action-packed arenas.
      <br><br>
      In Smash Karts, the goal is simple: race around the arena, collect power-ups, and unleash mayhem on your rivals. With a variety of weapons at your disposal—ranging from missiles to bombs—you’ll need quick reflexes and sharp strategy to outsmart and outlast the competition. The game’s smooth controls and dynamic visuals make for an immersive experience, where every race is packed with adrenaline-fueled excitement.
      <br><br>
      Whether you're a casual gamer looking for some quick fun or a competitive player seeking to dominate the leaderboards, Smash Karts delivers an exhilarating experience that will keep you coming back for more. Get ready for non-stop action, unlock new karts and characters, and prove you have what it takes to be the ultimate karting champion.

      `,
      tag: ["Io","Multiplayer",],
    hint: `
    <ul>
    <li><b>Objective:</b> Collect power-ups and eliminate opponents.</li>
    <li><b>Move:</b> Arrow keys or WASD.</li>
    <li><b>Fire Weapon:</b> Spacebar.</li>
    </li>
    <li><b>Collect Power-ups:</b> Grab mystery boxes for random weapons.</li>
    <li><b>Eliminate Opponents:</b> Use weapons strategically to take out rivals.</li>
    <li><b>Survive:</b> Avoid enemy attacks and stay in the race.</li>
    <li><b>Unlock:</b> Earn rewards for new karts and characters.</li>
    <li><b>Winning:</b> Get the most eliminations or survive the longest.</li>
    <li><b>Tips:</b> Stay mobile, use power-ups wisely, and master the controls.</li>
    </ul>
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "GD",
    orientation: 0,
    title: "Bloxd.io: Build, Compete, and Conquer in This Block-Based Multiplayer Io Game",
    name: ["Bloxd.io", "bloxd-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bloxd-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bloxd-io-300.jpg",
    path: ["/game/bloxd-io", "https://playcutegames.com"],
    iframe: "https://bloxd.io/",
    Walkthrough: "https://www.youtube.com/embed/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/bloxd-io.mp4",
    s_dis:
    "Explore Bloxd.io, a creative multiplayer game with obstacle courses, parkour, and building challenges. Play free online with no downloads needed!",
    l_dis:
      `Welcome to Bloxd.io, a dynamic multiplayer game that merges creativity, strategy, and competition into one immersive experience. Step into a world constructed from blocks, where your imagination and tactical skills are key to thriving in this exciting environment.
      <br><br> 
      In Bloxd.io, you can explore a wide array of game modes that cater to different play styles. Whether you're into obstacle courses, parkour challenges, or testing your ability to build and strategize, there's something here for everyone. The creative aspect of the game lets you design and construct unique structures, while the competitive modes push you to outwit opponents and emerge victorious.
       <br><br>
       From casual players looking for a fun, laid-back experience to seasoned gamers seeking to challenge themselves in fast-paced competitions, Bloxd.io offers endless opportunities for adventure. Its block-based world allows for boundless creativity, whether you're navigating tricky parkour courses or crafting masterpieces with friends.
       <br><br>
       Are you ready to conquer the challenges and show off your building prowess? Jump into Bloxd.io and embark on an unforgettable journey where strategy, creativity, and competition collide!
      `,
      tag: ["Io","Multiplayer",],
    hint: `
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "GD",
   
    orientation: 0,
    title: "Paper IO 2 : Conquer Territory in This Exciting Multiplayer Io Game",
    name: ["Paper IO 2", "paper-io-2"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/paper-io-2/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/paper-io-2-300.jpg",
    path: ["/game/paper-io-2", "https://playcutegames.com"],
    iframe: "https://html5.gamedistribution.com/c5d4966b176246748d6c847103ebfd51/?gd_sdk_referrer_url=https://playcutegames.com/game/paperio-2",
    Walkthrough: "https://www.youtube.com/embed/tjnyIiCkp9Q",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/paper-io-2.mp4",
    s_dis:
    "Join Paper IO 2, an action-packed online game! Expand your territory, outsmart rivals, and climb the leaderboard. Play free with no downloads required!",
    l_dis:
      `
      Welcome to the world of Paper IO 2, an exciting online multiplayer game where your goal is to conquer as much territory as possible! With its simple mechanics and fast-paced gameplay, Paper IO has quickly become a favorite among fans of arcade and strategy games alike.
      <br><br>
      In this thrilling game, you control a small square of paper, navigating a colorful map to expand your territory by creating trails and closing loops. Your objective is to capture as much land as possible while avoiding elimination from rival players. However, it’s not just about offense; you need to be strategic and cautious! If an opponent crosses your trail before you successfully close a loop, you’ll lose a life, adding a layer of tension that keeps every match exciting.
      <br><br>
      As you play, you'll encounter various opponents who will challenge your skills and tactics. The game rewards quick thinking and fast reflexes, making each session unique. Additionally, the vibrant graphics and smooth controls enhance the overall experience, keeping you engaged for hours.
      <br><br>
      Whether you're a casual player looking to have fun or a competitive gamer aiming for the top of the leaderboard, Paper IO 2 offers endless enjoyment. Can you outsmart your opponents and dominate the map? Jump in and find out in this exhilarating battle for territory!
`,
      tag: ["Io","Multiplayer",],
    hint: `
    
    <h2 class="H22">Objective:</h2>

    Your goal is to capture as much territory as possible by moving your square on the map, leaving a trail, and creating closed loops to claim land. The more land you capture, the higher your score!
    <h2 class="H2">Controls:</h2>
    
    <h2 class="H22">Movement:</h2> 
    <ul>
    <li>Use the arrow keys or swipe your screen (on mobile) to control the direction of your square.</li>
    <li>You can move up, down, left, or right, but you can’t go diagonally.</li>
    </ul>
    <h2 class="H22">Capturing Territory:</h2>
    <ul>
    <li>Move your square out of your colored territory to leave a trail behind.</li>
    <li>Complete a loop by re-entering your territory to capture the enclosed area.</li>
    <li>The captured land will change to your color, expanding your area on the map.</li>
    </ul>
    <h2 class="H2">Avoiding Elimination:</h2>
    
    <h2 class="H22">Watch out for other players:</h2> 
    <ul>
    <li>If an opponent crosses your trail before you close a loop, you'll be eliminated and lose the match.</li>
    <li>Stay cautious and don’t venture too far away from your territory without a plan!</li>
    </ul>
    <h2 class="H22">Eliminating Opponents:</h2>
    <ul>
    <li>You can eliminate other players by crossing over their trail before they close their loop.</li>
    <li>Timing and strategy are key!</li>
    </ul>
    <h2 class="H22">Defending Your Territory:</h2>
    <ul>
    <li>Be alert and defend your area from rivals trying to take over your land.</li>
    <li>Expanding too fast can leave you vulnerable, so balance your offense and defense.</li>
    </ul>
    <h2 class="H22">Power-ups (if available):</h2>
    
    Look for power-ups or special items that can help you move faster or gain an advantage over your opponents.
    <h2 class="H22">Winning the Game:</h2>
    <ul>
    <li>The player who captures the largest territory by the end of the match will be crowned the winner.</li>
    <li>Aim for the top of the leaderboard by claiming the most land and surviving as long as possible.</li>
    </ul>
    <h2 class="H22">Tips:</h2>
    <ul>
    <li>Start small and expand slowly to avoid being eliminated early.</li>
    <li>Keep an eye on opponents and predict their moves.</li>
    <li>Don't be greedy—closing loops quickly is better than risking elimination.</li>
    </ul>
    <br>
    Jump in and see how much territory you can conquer in Paper IO 2!

    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "playcutegames",
   
    orientation: 0,
    title: "BFFs Unique Halloween Costumes - playcutegames.com",
    name: ["BFFs Unique Halloween Costumes", "bffs-unique-halloween-costumes"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-unique-halloween-costumes/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-unique-halloween-costumes-300.jpg",
    path: ["/game/bffs-unique-halloween-costumes", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bffs-unique-halloween-costumes/",
    Walkthrough: "https://www.youtube.com/embed/9hQ3K5AVjpk",
    s_dis:
    "BFFs Unique Halloween Costumes Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Join the BFFs Unique Halloween Costumes adventure! These best friends are on a mission to find the most creative and distinctive Halloween outfits. Get ready for a one-of-a-kind Halloween experience as they plan, choose, and showcase their unique costumes. Unleash your creativity and celebrate Halloween like never before with the trendiest and most original looks!",
      tag: ["LOL Surprise","Dress up","Halloween","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },

  {
    date: "2023-10-10",
    brand: "playcutegames",
    orientation: 0,
    title: "Blackpink Black Friday Fever - Free Dress-Up Game",
    name: ["Blackpink Black Friday Fever", "blackpink-black-friday-fever"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/blackpink-black-friday-fever/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/blackpink-black-friday-fever/520x295.png",
    path: ["/game/blackpink-black-friday-fever", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/blackpink-black-friday-fever/",
    Walkthrough: "https://www.youtube.com/embed/DcgpggjHOnU",
    s_dis:
    "Style Jisoo, Jennie, Rosé, and Lisa in Blackpink Black Friday Fever! A free unblocked online dress-up game for K-pop fans.",
    l_dis:
      `
       <p><strong>Blackpink Black Friday Fever</strong> is the ultimate dress-up game designed for fans of the world’s biggest K-pop girl group. Dive into the glitz and glamour as you style Jisoo, Jennie, Rosé, and Lisa in jaw-dropping outfits inspired by Black Friday’s hottest fashion trends. With an extensive collection of clothing, shoes, and accessories to choose from, this game is perfect for fashion lovers and <em>K-pop</em> enthusiasts alike.</p>
       <p>From chic casual wear to dazzling performance outfits, each member of Blackpink has a unique style that you can bring to life. Whether you want to recreate their iconic looks from music videos or try something completely fresh and new, the possibilities are endless!</p>
       <h2 class="H2">Why Fans Love Blackpink Black Friday Fever</h2>
       <p>Blackpink is more than just a music group; they are global fashion icons known for their bold and trendsetting styles. With <strong>Blackpink Black Friday Fever</strong>, fans can channel their inner stylist and curate outfits that showcase each member’s individuality. The game also incorporates a fun Black Friday theme, adding an extra layer of excitement as you hunt for the best "deals" in the virtual wardrobe.</p>
       <p>Fans adore the attention to detail in this game, from the hairstyles inspired by Blackpink’s music videos to the intricate accessories that reflect their real-world red carpet appearances. The game allows you to mix and match styles to create unique looks for each member. Whether you’re a BLINK or just love dress-up games, this game is sure to captivate your imagination.</p>
       <h2 class="H2">Play Blackpink Black Friday Fever for Free</h2>
       <p><strong>Blackpink Black Friday Fever</strong> is available as a <em>free unblocked game</em>, meaning you can play it without any downloads or registration needed. Simply open the game in your browser and start styling. Whether you’re at home, school, or work, you can enjoy hours of creative fun without any restrictions. As one of the top <em>K-pop games</em>, it’s perfect for BLINKs and fashion enthusiasts looking for a unique gaming experience.</p>
       <p>Explore the world of <strong>unblocked games</strong> and enjoy dressing up your favorite idols in the most fashionable outfits. Share your designs with friends and see who can create the most iconic Blackpink look. It’s time to show off your styling skills and celebrate your love for <em>K-pop</em> in the most fashionable way possible!</p>
      `,
      tag: ["Blackpink","Dress up","Exclusive","Girl games"],
    hint: `Use your left mouse button to play.`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-25",
    brand: "playcutegames",
   
    orientation: 0,
    title: "Angela Halloween Preparation - playcutegames.com",
    name: ["Angela Halloween Preparation", "angela-halloween-preparation"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/angela-halloween-preparation/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/angela-halloween-preparation-300.jpg",
    path: ["/game/angela-halloween-preparation", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/angela-halloween-preparation/",
    Walkthrough: "https://www.youtube.com/embed/_WfHISbh1II",
    s_dis:
    "Get festive with Angela Halloween Preparation! Find the best tips for spooky decorations and fun costumes in this online game. Play free at PlayCuteGames",
    l_dis:
      "Welcome to Angela Halloween Preparation. Join Angela in her Halloween Preparation journey! From spooky decorations to creative costumes, get inspired for the spookiest night of the year. Discover Angela’s tips and tricks for the ultimate Halloween celebration and make this year’s festivities unforgettable! Play and have fun with this new halloween game!",
      tag: ["Angela","Dress up","Halloween","Exclusive","Holiday","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-17",
    brand: "Yandex",
    orientation: 0,
    title: "Vega Mix 2 - Play Free Online | No Download Needed",
    name: ["Vega Mix 2", "vega-mix-2"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/vega-mix-2/250x142.png",
    path: ["/game/vega-mix-2", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/232649?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Join Vega Mix 2 and embark on an adventure! Solve thrilling match-3 puzzles, explore a mysterious island, and uncover hidden secrets. No download needed",
    l_dis:
    `
    Welcome to Vega Mix 2, where an exciting adventure awaits you on a mysterious island! Dive deep into the engaging storyline, solve thrilling match-3 puzzles, and uncover the island's hidden secrets. Your journey is just beginning, filled with twists, humor, and unforgettable moments!
    <br><br>
    <h2 class="H2">Features of Vega Mix: Adventures include:</h2>
    The allure of Starr Drops lies in the element of randomness, where every opening brings the anticipation of discovering something extraordinary. Whether it’s a rare skin, an epic power-up, or even a legendary item, the excitement of unlocking something special is unmatched. With categories like rare, superrare, epic, mythic, and legendary Starr Drops, each tier offers progressively more exciting and valuable rewards.
    <br><br>
    <ul>
    <li>Over 2,000 match-3 levels with exciting power-up combinations, with new levels added weekly.</li>
    <li>A twisted plot and charming characters who add a touch of humor to every situation.</li>
    <li>Explore a mysterious island, uncovering its treasures and solving its many secrets.</li>
    <li>Fun and challenging mini-games and puzzles to keep you entertained.</li>
    <li>Daily bonuses, gifts, quests, and special events to keep the adventure fresh.</li>
    <li>Play without internet—completely free, with no Wi-Fi required and no annoying ads!</li>
    </ul>
    <br>
    In Vega Mix 2, embark on a thrilling expedition through the wild jungle. Test your puzzle-solving skills, beat various levels, and unlock new chapters of the story by matching three pieces in a line. Whether you’re a casual player or a seasoned puzzle lover, this game will keep you engaged with its fun gameplay, exciting challenges, and intriguing storyline. Get ready to become a true adventurer—start your journey in Vega Mix 2 today!
    `,
    tag: ["Match 3","Casual","20","50"],
    hint: `
    <ul>
    <li>Match 3 or more pieces in a line to explode them.</li>
    <li>Match 4 pieces to create the Rocket. The Rocket blows up the whole line!</li>
    <li>Match 4 pieces to form a square to create the Spinner. The Spinner destroys one of the targets!</li>
    <li>Make a T-shaped match of 5 pieces to create the Bomb. The Bomb makes a massive explosion on the board!</li>
    <li>Match 5 pieces in a line to create the Rainbow Flower. The Rainbow Flower explodes pieces of the same color!</li>
    <li>Match any 2 power-ups to get a variety of powerful effects that will make it easier to complete the level</li>
    <li>Many obstacles on the board allow you to use different strategies and make the levels diverse.</li>
    </ul>
    `,
    rating:[4.9,350,18],
  },
  {date: "2024-10-17",brand: "Yandex",orientation: 0,title: "Vegamix - Play Free Online | No Download Needed",
    name: ["Vegamix", "vegamix"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/vegamix/250x142.png",
    path: ["/game/vegamix", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/189498?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Welcome to Vega Mix 2! Solve match-3 puzzles and match colored pieces to unveil the mysteries of the island. Your breathtaking journey starts now!",
    l_dis:
    `
    It’s simple—we’re passionate about the "three in a row" genre, and we wanted to create a game that we’d love to play ourselves. That passion shines through in every aspect of the game. We’ve designed a captivating plot where players join the lovable characters, Vika and her friend Enotik, as they travel to different countries and compete in exciting gardening championships to earn the title of the best gardener.
    <br><br>
    What truly sets Vega Mix apart is the combination of beautiful graphics and thoughtfully designed, moderately challenging levels. Unlike many other match-3 games, we don’t have timed levels that force you to move quickly without thinking. Instead, our levels are designed to let you strategize and play at your own pace, offering a more relaxing experience. Plus, we feature two unique game modes—day and night—so you can switch up your playstyle and enjoy the game’s cozy atmosphere in different ways.
    <br><br>
    Our players love to unwind after a long day, immersing themselves in a world full of adventure, relaxing gameplay, and the satisfaction of solving puzzles. It’s more than just a game; it’s a relaxing escape into a charming, well-crafted world that’s always waiting for you.
    `,
    tag: ["Match 3","Casual","20","50"],
    hint: `
    <ul>
    <li>Match 3 tiles of the same color in a row to get them out!</li>
    <li>Complete various tasks on the levels!</li>
    <li>Discover new countries and locations!</li>
    </ul>
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2024-10-17",
    brand: "Yandex",
    orientation: 0,
    title: "Wood Block Classic - Play Free Online | No Download Needed",
    name: ["Wood Block Classic", "wood-block-classic"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/wood-block-classic/250x142.png",
    path: ["/game/wood-block-classic", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/182665?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Enjoy the free woodblock puzzle game! Simple interface, beautiful graphics, and No sownload needed. Compete on the leaderboard with friends worldwide!",
    l_dis:
    `
    Blast the woodblocks and enjoy the perfect blend of relaxation and fun with our captivating puzzle game!
    <br>
    <h2 class="H2">🌕 Why choose this puzzle game?</h2>
    <ul>
    <li>Experience a simple and natural wood-style interface that enhances your gameplay.</li>
    <li>Discover new fun from classic puzzle games that will keep you entertained for hours.</li>
    <li>Challenge your friends and beat their high scores for added excitement.</li>
    <li>Immerse yourself in beautiful graphics and enjoy the satisfying sound effects that make every move a pleasure.</li>
    <li>Enjoy a tactile game experience with a realistic wood tile design that feels just right.</li>
    </ul>
    <h2 class="H2">🌕 FREE game! NO time limit!</h2>
    <ul>
    <li>This is a free wood block game suitable for everyone—no hidden costs!</li>
    <li>Compete on the leaderboard against your friends or players from around the globe to see who’s the best.</li>
    <li>It’s a light, small game that requires no downloads—just click and play!</li>
    <li>No registration is required; jump right into the fun and start playing for free!</li>
    </ul>
    <br>
    Whether you're looking to unwind or challenge yourself, this game promises endless entertainment and relaxation. Get ready to blast those woodblocks and enjoy a delightful puzzle experience!
    `,
    tag: ["Puzzle","Casual","20","50"],
    hint: `
    <ul>
    <li>Tap the woodblocks to move them.</li>
    <li>Place wood blocks in a vertical or horizontal line to clear them.</li>
    <li>Make the score as high as you can and be the best block blaster.</li>
    </ul>
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 0,
    title: "Battle of the red and blue agents - playcutegames.com",
    name: ["Battle of the red and blue agents", "battle-of-the-red-and-blue-agents"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/battle-of-the-red-and-blue-agents/250x142.png",
    path: ["/game/battle-of-the-red-and-blue-agents", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/283309?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video: "",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Battle of the red and blue agents Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `
    Battle of the red and blue agents" is a game in which you will have to take part in a duel with your opponent. Choose from a variety of locations and weapons to fight not for life, but for death. The game is perfect to brighten up your free time, and spend it fun, while participating in a duel on scissors or sledgehammers, for example. Get a lot of positive emotions from spending time in this game.
    
    <h2 class="H2">Game features:</h2>
    <ul>
    <li>Is a realistic fight-duel</li>
    <li>Simple but interesting game</li>
    <li>Large variety of locations in the game</li>
    <li>Has a simple and easy gameplay</li>
    <li>Great for your leisure time</li>
    </ul>
    `,
    tag: ["Arcade","Boys","Car",],
    hint: `
    The game has three game modes: single player, multiplayer and survival. In single player mode you will face other stickmen in exciting battles, here the scene changes with every fight. To win the game, just defeat your opponent..... before he defeats you! On the other hand, two players can fight on the same device in multiplayer mode. While playing in this mode, two joysticks appear on mobile devices, each on opposite sides of the screen, and players use them to attack each other and try to defeat each other. Finally, there's a survival mode where you fight hordes of other stickmen and try to defeat as many of them as possible.
    <br><br>
    To control your character on mobile devices, there is a virtual joystick on the right side of the screen. There are no special action or attack buttons, instead your big guy automatically attacks in any direction you move the joystick.
    <br><br>
    For control on PC use "WASD" or arrows.
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 1,
    title: "Skydom - Free Match 3 Game Online | Play Without Download",
    name: ["Skydom - Match 3", "skydom-match-3"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/skydom-match-3/250x142.png",
    path: ["/game/skydom-match-3", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/96786?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Play free online Skydom Match 3 puzzle game! Compete with players and solve tricky puzzles, and challenge friends in real-time across hundreds of levels.",
    l_dis:
    `
    Welcome to the magical Skydom sky kingdoms! Skydom is probably the most colorful and interesting puzzle game with really unique game modes!
    <br><br>
    Have you already passed thousands of levels in other "three in a row"? Then you can join us... only in Skydome can you compete with other players and find out who is really the master of "three in a row"!
    <br><br>
    Show all your skills and abilities in the best game of the genre "three in a row"! Challenge your friends in real time and win through hundreds of unique levels with tricky puzzles and gorgeous effects! New exciting modes and amazing experiences.
    <br><br>
    Complete exciting tasks on your way to the top of Skydom! Charming piggy will accompany you on an exciting and challenging journey.
    <br><br>
    The taste of victory in Skydom is sweeter than any candy!
    `,
    tag: ["Match 3","Puzzle"],
    hint: `
    <ul>
    <li>Match 3 pieces of the same color in a row to clear them!</li>
    <li>Complete various tasks on levels and win against opponents!</li>
    <li>Explore magical sky kingdoms of Skydom!</li>
    </ul>
    `,
    rating:[4.9,350,18],
  },
  
  {
    date: "2024-10-10",
    brand: "best",
    orientation: 0,
    title: "Halloween Merge Mania - Merge Spooky Halloween",
    name: ["Halloween Merge Mania", "halloween-merge-mania"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/halloween-merge-mania/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/halloween-merge-mania/250x142.png",
    path: ["/game/halloween-merge-mania", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bestgamespot/halloween-merge-mania/",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Play Halloween Merge Mania, spooky puzzle game where you can merge 11 Halloween characters like pumpkins, bat. Can you reach the ultimate merge? Try it now!",
    l_dis:
    `Halloween is the season for thrills, chills, and of course, fun games! If you’re a fan of puzzle games with a spooky twist, <a href="/game/halloween-merge-mania">Halloween Merge Mania</a> is the perfect game for you. Inspired by the popular gameplay style of Suikagame, Halloween Merge Mania lets you merge creepy Halloween characters to unlock higher-level creatures. From classic pumpkins and bats to Frankenstein and Jack Skellington, this game is packed with eerie fun and excitement.
    <h2 class="H2">Game Overview</h2>
    In Halloween Merge Mania, the objective is simple yet addictive: merge matching Halloween-themed objects to create new ones, and continue merging to unlock more powerful characters. The game features 11 iconic Halloween figures such as pumpkins, bats, mummies, Frankenstein, and more. The challenge is to keep merging without running out of space on the board!
    <br><br>
    With each merge, you create more advanced and spooky characters, pushing the limits of how far you can go. Can you discover all 11 characters and master the art of merging in this spooky Halloween adventure?
    `,
    tag: ["Halloween","Merge","2048"],
    hint: `
    <ol>
    <li><b>Merge Identical Objects:</b> You start with basic Halloween-themed items, like pumpkins. Combine two matching items to create a higher-level object.</li>
    <li><b>Unlock New Characters:</b> As you keep merging, you’ll unlock new and exciting Halloween characters such as bats, mummies, Frankenstein, and even Jack Skellington!</li>
    <li><b>Strategize Your Moves:</b> Be mindful of your limited board space. Keep merging efficiently to prevent the board from filling up, or you’ll run out of room!</li>
    <li><b>Aim for the Ultimate Merge:</b> The more you merge, the more challenging it becomes. Can you unlock the final spooky character and become the master of Halloween merges?</li>
    </ol>
    <h2 class="H2">Features</h2>
    <ul>
    <li><b>11 Spooky Halloween Characters:</b> Merge classic Halloween figures such as pumpkins, bats, Frankenstein, mummies, Jack Skellington, and more!</li>
    <li><b>Addictive Puzzle Gameplay:/b> Inspired by the popular Suikagame, this merge game offers easy-to-learn mechanics but keeps you hooked with its strategic depth.</li>
    <li><b>Progressive Difficulty:</b> As you advance, the challenge increases as you aim to unlock all 11 characters while managing your limited board space.</li>
    <li><b>Perfect for Halloween Lovers:</b> The Halloween theme, complete with spooky characters and eerie visuals, makes it the ideal game to enjoy during the spooky season.</li>
    <li><b>Suitable for All Ages:</b> Whether you're a casual player or a seasoned puzzle fan, Halloween Merge Mania offers fun for both kids and adults.</li>
    </ul>
    <h2 class="H2">Why You'll Love It</h2>
    Halloween Merge Mania combines the excitement of merging games with the festive atmosphere of Halloween. The thrill of unlocking new and spooky characters keeps you coming back for more, as you try to discover all 11 unique Halloween creatures. The game's simple yet challenging mechanics make it easy to pick up, but hard to put down. Plus, the Halloween theme adds a layer of fun that’s perfect for the season!
    <br><br>
    The game’s strategic element keeps you thinking about your next move, as you carefully merge characters while managing the limited space on the board. Each successful merge feels rewarding, and the anticipation of unlocking the final spooky character keeps you on the edge of your seat.
    <h2 class="H2">Tips for Success</h2>
    <ul>
    <li><b>Plan Your Merges:</b> Don’t rush! Think a few moves ahead to avoid filling up the board too quickly.</li>
    <li><b>Aim for High-Level Characters: The higher the character level, the more space-efficient your board will be. Focus on unlocking the higher-level spooky creatures!</b> Whenever possible, try to merge in larger chains for a higher score and quicker progress.</li>
    <li><b>Aim for High-Level Characters:</b> The higher the character level, the more space-efficient your board will be. Focus on unlocking the higher-level spooky creatures!.</li>
    </ul>
    <h2 class="H2">The Perfect Halloween Puzzle Experience</h2>
    Whether you’re a fan of puzzle games or simply looking for something fun to play during the Halloween season, Halloween Merge Mania is sure to keep you entertained. Its simple mechanics, combined with the spooky theme and progressively challenging gameplay, make it a must-play for anyone who enjoys Halloween fun and puzzle-solving excitement.
    <br><br>
    Are you ready to merge your way through the Halloween season? Start playing Halloween Merge Mania now and see how many spooky characters you can unlock!
    `,
    rating:[4.4,1210,114],  
  },
 
  {
    date: "2024-10-10",
    brand: "best",
    orientation: 0,
    title: "Spooky Halloween Hidden Pumpkin - Find 10 Hidden Pumpkins",
    name: ["Spooky Halloween Hidden Pumpkin", "spooky-halloween-hidden-pumpkin"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/spooky-halloween-hidden-pumpkin/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/spooky-halloween-hidden-pumpkin/250x142.png",
    path: ["/game/spooky-halloween-hidden-pumpkin", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bestgamespot/spooky-halloween-hidden-pumpkin/",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Play Spooky Halloween Hidden Pumpkin, a thrilling game with 15 eerie levels! Find 10 hidden pumpkins in each level to complete the Halloween adventure.",
    l_dis:
    `As the air turns chilly and the night grows longer, Halloween brings a season of mystery and excitement. If you’re a fan of hidden object games and the Halloween spirit, Spooky Halloween Hidden Pumpkin is the perfect treat for you! Dive into a spine-chilling adventure across 15 spooky levels, where your task is to find 10 hidden pumpkins cleverly concealed in each scene. But beware—the eerie atmosphere and tricky hiding spots will test your observation skills!
    <h2 class="H2">Game Overview</h2>
    In Spooky Halloween Hidden Pumpkin, you’ll explore haunted locations like creepy graveyards, abandoned houses, foggy forests, and more. Each level is uniquely designed with eerie Halloween-themed settings full of tricks and illusions. Your challenge is to locate 10 pumpkins hidden in plain sight within each scene. The faster you find them, the better your score!
    <br><br>
    This game is perfect for both kids and adults who love the thrill of a good hidden object puzzle combined with the excitement of Halloween. With each level, the difficulty increases as the pumpkins become harder to find, making it a captivating experience for puzzle enthusiasts of all ages.
    `,
    tag: ["Hidden","Halloween","Skill"],
    hint: `
    <ul>
    <li><b>Explore Each Scene:</b> Every level introduces a new Halloween-themed location full of details. Use your sharp eyes to scan the scene and uncover the pumpkins.</li>
    <li><b>Find the Hidden Pumpkins:</b> There are 10 pumpkins hidden in each level. They may be cleverly disguised among other spooky elements, so pay close attention!</li>
    <li><b>Beat the Clock:</b> Each level is timed, adding an extra layer of challenge. The faster you find all the pumpkins, the higher your score will be.</li>
    <li><b>Advance Through 15 Levels:</b> As you progress, each level gets more intricate, with pumpkins hidden in more difficult spots. Can you find them all and complete the spooky challenge?</li>
    </ul>
    <h2 class="H2">Features</h2>
    <ul>
    <li><b>15 Unique Halloween Levels:</b> Explore eerie, Halloween-inspired locations such as haunted houses, dark forests, and mysterious cemeteries.</li>
    <li><b>Increasing Difficulty:</b> Each level brings more challenging hiding spots for the pumpkins, making the game progressively harder and more fun.</li>
    <li><b>Perfect for Halloween Fans:</b> The spooky atmosphere, combined with the joy of finding hidden objects, makes this game perfect for getting into the Halloween spirit.</li>
    <li><b>For All Ages:</b> Easy enough for kids to enjoy but challenging enough to keep adults entertained, this game offers fun for the whole family.</li>
    </ul>
    <h2 class="H2">Why You'll Love It</h2>
    <a href="/game/spooky-halloween-hidden-pumpkin">Spooky Halloween Hidden Pumpkin</a> brings together the excitement of Halloween with the satisfaction of finding hidden objects. Whether you’re looking for a relaxing game to unwind with or a fun challenge to test your observation skills, this game is sure to keep you hooked. The Halloween-themed settings and increasing difficulty make it perfect for players who enjoy hidden object games with a festive twist.
    <br><br>
    Are you ready to embrace the spooky fun and find all the hidden pumpkins? Start your Halloween adventure today with Spooky Halloween Hidden Pumpkin!
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 1,
    title: "Skydom Reforged | Play Without Download",
    name: ["Skydom Reforged", "skydom-reforged"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/skydom-reforged/250x142.png",
    path: ["/game/skydom-reforged", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/203211?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Dive into Skydom Reforged! Enjoy live PvP match 3 battles, and tackle exciting challenges with friends across hundreds of unique levels. Play without download",
    l_dis:
    `
    From the creators of the Match Arena… Welcome to Skydom Reforged! A new super-duper mod of the classical Skydom in a unique fresh visual design. Your cute-o-meter will shoot through the roof and directly into the stratosphere! Most beautiful characters are waiting for you!
    <br><br>
    Welcome to the magical heavenly kingdoms! Skydom Reforged is a bright and exciting puzzle with truly unique game modes!
    <br><br>
    Went through thousands of different match 3 levels and want something refreshing? Have a look inside...only in Skydom Reforged you can face off with different players to determine the best at match 3!
    <br><br>
    Show off your skill against real opponents or connect with friends in live Match 3 action on hundreds of levels with unique settings, gorgeous effects and unexpected turns. New super PvP mechanics and super experiences.
    <br><br>
    Complete exciting challenges on your way up to the heights of Skydom Reforged! Let the cutest piggy-magic accompany you in your exciting and challenging journey.
    `,
    tag: ["Match 3","Puzzle"],
    hint: `
    <ul>
    <li>Match 3 pieces of the same color in a row to clear them!</li>
    <li>Complete various tasks on levels and win against opponents!</li>
    <li>Explore magical sky kingdoms of Skydom!</li>
    </ul>
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2024-10-17",
    brand: "Yandex",
    orientation: 0,
    title: "Starr Drops - Play Free Online | No Download Needed",
    name: ["Starr Drops", "starr-drops"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/starr-drops/250x142.png",
    path: ["/game/starr-drops", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/253517?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Welcome to Starr Drops! Enjoy Brawl Stars-inspired gameplay, unlock rare rewards, and battle for survival in a world filled with captivating randomness!",
    l_dis:
    `
    Starr Drops offers a captivating and immersive experience for those who love the thrill of unlocking rewards and battling for glory. Inspired by the popular mechanics of Brawl Stars, this game introduces a unique twist, blending the excitement of opening loot boxes with intense survival battles. As a player, you will embark on a journey to unlock a wide variety of Starr Drops, ranging from rare to legendary, each containing valuable rewards and powerful items to enhance your gameplay.
    <br><br>
    The allure of Starr Drops lies in the element of randomness, where every opening brings the anticipation of discovering something extraordinary. Whether it’s a rare skin, an epic power-up, or even a legendary item, the excitement of unlocking something special is unmatched. With categories like rare, superrare, epic, mythic, and legendary Starr Drops, each tier offers progressively more exciting and valuable rewards.
    <br><br>
    In addition to unlocking Starr Drops, players can participate in adrenaline-pumping survival battles where strategy and skill are key to outlasting opponents. The blend of reward-chasing and competitive combat creates a dynamic gameplay experience that keeps players engaged and eager for more.
    <br><br>
    Whether you're drawn to the thrill of opening rare items or love the intensity of survival battles, Starr Drops delivers an unparalleled adventure filled with excitement, randomness, and unique rewards.
    `,
    tag: ["Brawl","Arcade","Casual","20","50"],
    hint: `
    To play, simply tap the screen to initiate the Starr Drops opening process. Upgrade the rarity of the prize to increase the chances of obtaining more valuable rewards. Enhance your prize opening skills gradually and enjoy the unfolding possibilities of the game!
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 0,
    title: "Battle Arena: RPG online - playcutegames.com",
    name: ["Battle Arena: RPG online", "battle-arena-rpg-online"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/battle-arena-rpg-online/250x142.png",
    path: ["/game/battle-arena-rpg-online", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/184531?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video: "",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Battle Arena: RPG online lets you create a powerful hero team for epic PvP battles! Join the best free game at PlayCuteGames and prove your skills!",
    l_dis:
    `Battle Arena is an incredible combination of RPG and MOBA: a mobile game in the action/tactical RPG genre. Test your abilities as a commander and create an unbeatable team of heroes! Defeat opponents in online PvP battles and destroy hordes of them in captivating missions.
     <h2 class="H2">Game features:</h2>
     <ul>
     <li>Real-time PvP battles!</li>
     <li>Challenge players around the world. Train and enter the battle Arena</li>
     <li>defeat opponents and become the best of the best</li>
     </ul>
     <h2 class="H2">Create a team!</h2>
      <ul>
     <li>Dive into this incredible role-playing game and choose from over 80 different heroes.</li>
     <li>defeat opponents and become the best of the best</li>
     </ul>
     <h2 class="H2">Enjoy the beautiful graphics!</h2>
     Over 60 amazing locations: Elven Forests, Burning Lands of the orcs, chasms deeper and more beautiful than the Grand Canyon, a floating Arena, and the Cold Rocks of the gnomes.
     <h2 class="H2">Play in teams!</h2>
     Play online with friends! Create an alliance with other players or join an existing one. Trade heroes and equipment, chat with friends, share tactics, and take part in friendly fights.
    `,
    tag: ["Rpg","Midcore",],
    hint: `
    Battle Arena - test your abilities as a commander and create an unbeatable team of heroes!
     <h2 class="H2">Real-time battles:</h2>
    <ul>
     <li>You'll love training and developing heroes to get a team that dominates in PvP battles.</li>
     <li>You must make the right decisions at the right moment.</li>
     <li>Every hero has a unique set of skills that you can perfect gradually.</li>
     <li>Make combinations of heroes by testing their mettle in the Arena and on missions to create a perfectly-balanced squad.</li>
     <li>A wide variety of attacks: snowstorms, fireballs, poison arrows or rockets, blasters, and blinding grenades.</li>
     <li>Choose different amazing hero skills from different universes and every PvP battle will be unique!</li>
     </ul>
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 1,
    title: "Ball Sort Puzzle - Color Sort - playcutegames.com",
    name: ["Ball Sort Puzzle - Color Sort", "ball-sort-puzzle-color-sort"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/ball-sort-puzzle-color-sort/250x142.png",
    path: ["/game/ball-sort-puzzle-color-sort", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/193301?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Ball Sort Puzzle - Color Sort Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `Ball Sort Puzzle - Color Sort is a challenging puzzle game that will keep you entertained for hours! Sort the colored balls in the tubes until all of the same-hued balls are in the same tube. A brain-teasing game that is both tough and relaxing! 
    <h2 class=H2>FEATURES: </h2>
    <ul>
    <li>Control with just one finger. </li>
    <li>Multiple unique levels. </li>
    <li>Completely free and easy to play. </li>
    <li>Use hints for the best move to solve the puzzle. </li>
    <li>There are no penalties or time limits, so you can play Ball Sort Puzzle </li>
    <li>Color Sort whenever you want!</li>
    </ul>
    `,
    tag: ["Sort","Puzzles","Casual"],
    hint: `
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 1,
    title: "Match Arena - playcutegames.com",
    name: ["Match Arena", "match-arena"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/match-arena/250x142.png",
    path: ["/game/match-arena", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/96787?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Match Arena Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `Welcome to the Arena! The first "three in a row" game with real opponents! A completely new approach to three-in-a-row games. You will definitely like it! Exciting gameplay: shuffle and match chips, find new friends on an exciting and adventurous path! Show all your skills and abilities in the best game of the genre "three in a row"! Challenge your friends in real time and win through hundreds of unique levels with tricky puzzles and gorgeous effects! New exciting modes and amazing experiences. Complete exciting challenges on your way to the Golden League Match Arena! Charming piggy will accompany you on an exciting and challenging journey. The taste of victory in Match Arena is sweeter than any candy!`,
    tag: ["Puzzle","Match3","Skill","Kids","Crazy games"],
    hint: `Match 3 pieces of the same color by swapping adjacent ones!`,
    rating:[4.9,350,18],
  },
  {
    date: "2022-12-16",
    brand:"Poki games",
    orientation: 0,
    title: "Play Hilarious Monkey Mart Game Online at Playcutegames",
    name: ["Monkey Mart", "monkey-mart"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/monkey-mart/250x142.png",
    path: ["/game/monkey-mart", "https://playcutegames.com"],
    iframe:
      "https://monkey-mart.io/iframe/index.html",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/monkey-mart.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Monkey Mart is an addictive online simulation game With challenging gameplay and cute, cartoon graphics, Monkey Mart is sure to provide hours of fun.",
    l_dis:`<a href="/game/monkey-mart">Monkey Mart</a> is an idle/management game made by TinyDobbins. You control a cute monkey character that just created their supermarket. Plant fruits, move around from station to station to fill the stands with bananas or corn, or more. Clients will pick them up and wait for you at the cashier desk. You can upgrade your character, unlock new work stations, or even recruit other workers to help you manage the store !`,
    tag: ["Poki","Managment","Simulation","Shopping","Animal","Arcade"],
    hint: `Move your player monkey inside the mart - Use W A S D or Arrow keys or Drag.`,
    rating:[4.9,350,18],
    platform:"both",
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 1,
    title: "Skydom - playcutegames.com",
    name: ["Skydom", "skydom"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/skydom/250x142.png",
    path: ["/game/skydom", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/96786?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Skydom Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    "Welcome to the magical high kingdoms! Skydom is a bright and exciting puzzle with truly unique game modes! Went through thousands of different match 3 levels and want something refreshing? Have a look inside...only in Skydom you can face off with different players to determine the best at match 3! Show off your skill against real opponents or connect with friends in live Match 3 action on hundreds of levels with unique settings, gorgeous effects and unexpected turns.",
    tag: ["Puzzle","Match3","Skill","Kids","Crazy games"],
    hint: `Match 3 pieces of the same color by swapping adjacent ones!`,
    rating:[4.9,350,18],
  },
  {
    date: "2024-03-05",
    brand: "Nes",
    orientation: 0,
    title: "Super Mario Bros - playcutegames.com",
    name: ["Super Mario Bros", "super-mario-bros"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/super-mario-bros/250x142.png",
    path: ["/game/super-mario-bros", "https://playcutegames.com"],
    iframe: "https://www.retrogames.cc/embed/21929-super-mario-bros-europe-rev-0a.html",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/super-mario-bros.mp4",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Super Mario Bros Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `
      <h2 class="H2">Jump, Run, and Conquer!</h2>
      Super Mario Bros., a legendary Nintendo title, has captivated gamers for decades with its simple yet addictive gameplay. As the iconic plumber, Mario, your mission is to navigate through treacherous worlds, defeat enemies, and rescue Princess Peach from the clutches of Bowser.
     
      `,
      tag: ["Mario","Nes","Platformer"],
    hint: ` <h2 class="H2">Key Controls:</h2>
      <ul>
      <li><b>Movement:</b> Use the directional pad to move left, right, up, and down.</li>
      <li><b>Jumping:</b> Press the jump button to leap over obstacles and enemies.</li>
      <li><b>Running:</b> Hold the run button to move faster.</li>
      <li><b>Crouching:</b> Press down to crouch under obstacles.</li>
      </ul>
       <h2 class="H2">Power-Ups:</h2>
      <ul>
      <li><b>Red Mushroom:</b>Grows Mario to Super Mario size.</li>
      <li><b>Fire Flower:</b>Gives Mario the ability to throw fireballs.</li>
      <li><b>Green Mushroom:</b>Grants an extra life.</li>
      </ul>
       <h2 class="H2">Hidden Secrets:</h2>
      <ul>
      <li>Level Skipping: Discover hidden pipes and blocks to access later worlds without completing earlier ones.</li>
      <li>Time Trials: Race against the clock to achieve faster completion times and unlock bonuses.</li>
      </ul>
       <h2 class="H2">A Legacy of Fun</h2>
       Super Mario Bros. has left an indelible mark on the gaming industry. Its innovative level design, memorable characters, and catchy soundtrack have made it a timeless classic. Whether you're a seasoned gamer or new to the series, this iconic title offers endless hours of fun and adventure.
       <br><br>
       Ready to embark on your Mario adventure?

      `,
    rating:[4.4,1210,114],  
  }, {
    date: "2022-08-04",
    brand: "Yandex",
   
    orientation: 0,
    title: "Time Shooter 2 - playcutegames.com",
    name: ["Time Shooter 2", "time-shooter-2"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/time-shooter-2/250x142.png",
    path: ["/game/time-shooter-2", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/182594?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/time-shooter-2.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Time Shooter 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Time Shooter 2 is a first-person shooter where time stands still until you move. Plan your shots and moves carefully. Dodge enemy fire in epic slo-mo combat and make your way around the map using time to your advantage.
      <h2 class="H2">Features:<h2>
      <ul>
      <li>unique gameplay</li>
      <li>a variety of weapons</li>
      <li>minimalistic graphics</li>
      <li>interactive items</li>
      <li>realistic physics of damage to enemies</li>
      </ul>
      `,
    tag: ["Crazy games","Shooting","Action","Adventure"],
    hint: `Walk around with the miner to collect as much gold as you can. <br><br>
    For one level only, you can also receive endless ammo or invulnerability by watching a short ad.
    
    <ul>
      <li>Left Mouse Button - pick up / shoot weapon</li>
      <li>Right Mouse Button - throw weapon</li>
      <li>WASD - movement</li>
      <li>interactive items</li>
      <li>Speed up time by moving.</li>
      </ul>
    
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-03-05",
    brand: "Miniplay",
    orientation: 0,
    title: "Plants Vs Zombies - playcutegames.com",
    name: ["Plants Vs Zombies", "plants-vs-zombies"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/plants-vs-zombies/250x142.png",
    path: ["/game/plants-vs-zombies", "https://playcutegames.com"],
    iframe: "https://ext.minijuegosgratis.com/pvz-modded/game/index.html",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Plants Vs Zombies Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Plants vs. Zombies Online is a real-time strategy game that pits a horde of undead against a botanical army. Players must strategically plant defensive plants to thwart the zombie invasion and protect their home. This popular online game offers a unique blend of tower defense and card collecting elements, making it a captivating experience for gamers of all ages.
      
      `,
      tag: ["Merge","Addictive"],
    hint: ` 
      <ul>
      <li><b>Plant Selection:</b>Players choose from a vast array of plants, each with distinct abilities and strengths. From the classic Peashooter to the powerful Chomper, there's a plant for every situation.</li>
      <li><b>Zombie Horde:</b>Zombies come in various types, each with unique characteristics and attack patterns. From the basic zombie to the elusive Gargantuar, players must be prepared to face a diverse undead threat.</li>
      <li><b>Level Progression:</b>As players advance through the game, they unlock new plants and zombies, increasing the complexity and challenge of each level.</li>
      <li><b>Multiplayer Battles:</b>Engage in competitive multiplayer matches against other players, showcasing your strategic skills and plant combinations.</li>
      <li><b>Card Collection:</b>Collect cards representing different plants and zombies to build your deck and customize your gameplay experience.</li>
      </ul>
      <div class="Empty"></div>
      <h2 class="H2">Plants vs. Zombies</h2>
      Plants vs. Zombies is a popular tower defense video game developed by PopCap Games. The game pits a horde of undead against a botanical army, challenging players to strategically plant defensive plants to thwart the zombie invasion and protect their home. With its unique blend of humor, addictive gameplay, and colorful art style, Plants vs. Zombies has captured the hearts of gamers worldwide.
      <h2 class="H2">A Brief History</h2>
      The original Plants vs. Zombies was released in 2009 for personal computers and consoles. Its success spawned a series of sequels and spin-offs, including Plants vs. Zombies 2: It's About Time, Plants vs. Zombies Garden Warfare, and Plants vs. Zombies Heroes. Each installment introduced new plants, zombies, and gameplay mechanics, expanding the Plants vs. Zombies universe.
     <h2 class="H2">Why Play Plants vs. Zombies?</h2>
     <ol>
     <li><b>Addictive Gameplay:</b>The game's simple yet strategic gameplay is easy to learn but difficult to master. Players must carefully choose and place plants to defend against the relentless zombie onslaught.</li>
     <li><b>Unique Characters:</b>The game features a diverse cast of quirky plants and zombies, each with their own unique abilities and personalities.</li>
     <li><b>Humor and Charm:</b>Plants vs. Zombies is filled with humor and charm, from the witty dialogue to the comical animations.</li>
     <li><b>Endless Replayability:</b>With numerous levels, challenges, and unlockables, Plants vs. Zombies offers endless hours of entertainment.</li>
     </ol>
      <h2 class="H2">Key Features of Plants vs. Zombies:</h2>
      <ul>
      <li><b>Strategic Gameplay:</b>Carefully plant defensive plants to block zombie advances.</li>
      <li><b>Diverse Plants:</b>Choose from a wide variety of plants with different abilities, such as shooting peas, chomping zombies, and producing sunflowers.</li>
      <li><b>Challenging Zombies:</b>Face off against a variety of zombies, including basic zombies, bucketheads, and gargantuars.</li>
      <li><b>Power-Ups:</b>Collect power-ups to boost your plants' abilities or gain temporary advantages.</li>
      </ul>

     <h2 class="H2">Conclusion</h2>
      Plants vs. Zombies is a timeless classic that continues to captivate players of all ages. With its addictive gameplay, charming characters, and endless replayability, it's no wonder that this game has become a beloved franchise. Whether you're a casual gamer or a strategy enthusiast, Plants vs. Zombies offers a fun and engaging experience that will keep you coming back for more.
     
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "Yandex",
    orientation: 0,
    title: "Cute Monsters - playcutegames.com",
    name: ["Cute Monsters", "cute-monsters"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cute-monsters/250x142.png",
    path: ["/game/cute-monsters", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/367661?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Cute Monsters Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Immerse yourself in an exciting game world where you will find evil monsters, incredible leveling opportunities, as well as a huge number of unique and cute creatures. Show everyone your skills in creating stronger monsters in "Cute Monsters"!`,
      tag: ["Merge","Addictive"],
    hint: `Click on the monsters to earn the elixir. Combine two identical monsters to get a stronger monster that will give more elixir`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "best",
    orientation: 1,
    title: "Spooky Halloween Jigsaw Puzzle - playcutegames.com",
    name: ["Spooky Halloween Jigsaw Puzzle", "spooky-halloween-jigsaw-puzzle"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/spooky-halloween-jigsaw-puzzle/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/spooky-halloween-jigsaw-puzzle/250x142.png",
    path: ["/game/spooky-halloween-jigsaw-puzzle", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bestgamespot/spooky-halloween-jigsaw-puzzle/",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Spooky Halloween Jigsaw Puzzle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `Spooky Halloween Jigsaw Puzzle offers a thrilling challenge for puzzle enthusiasts with a Halloween twist. Piece together hauntingly beautiful images featuring ghosts, witches, pumpkins, and eerie nightscapes. Choose from various difficulty levels to suit your skill, and enjoy the spooky atmosphere as you complete each puzzle. Perfect for getting into the Halloween spirit, this game provides hours of fun and entertainment for all ages!
    <h2 class="H2">Spooky Halloween Jigsaw Puzzle: A Hauntingly Good Time</h2>
    Are you ready for a spooky challenge? The Spooky Halloween Jigsaw Puzzle offers a thrilling experience for puzzle enthusiasts of all ages. With its hauntingly beautiful images and varying difficulty levels, this game is perfect for getting into the Halloween spirit.
    <h2 class="H2">What You'll Find:</h2>
    <ul>
    <li><b>Spooky Scenes:</b> Piece together images featuring ghosts, witches, pumpkins, and eerie nightscapes.</li>
    <li><b>Multiple Difficulty Levels:</b> Choose from easy, medium, and hard levels to find the perfect challenge for you.</li>
    <li><b>15 Levels of Spooky Fun:</b> Enjoy 15 unique puzzles, each with its own spooky charm.</li>
    <li><b>Hours of Entertainment:</b> The Spooky Halloween Jigsaw Puzzle offers endless hours of fun and relaxation.</li>
    </ul>
    <h2 class="H2">Perfect for Halloween:</h2>
    Whether you're looking for a fun family activity or a solo challenge, the Spooky Halloween Jigsaw Puzzle is the perfect way to get into the Halloween spirit. Gather your loved ones, turn off the lights, and enjoy the spooky atmosphere as you piece together these haunting images.
    <br><br>
    Are you ready to take on the challenge? Grab your puzzle pieces and get ready for a frightfully good time with the Spooky Halloween Jigsaw Puzzle!
    `,
    tag: ["Halloween","Jigsaw","Puzzle"],
    hint: `
    <ul>
    <li><b>Find the Edges:</b>Begin by identifying the edge pieces. These pieces have one or more sides that are not connected to other pieces.</li>
    <li><b>Assemble the Borders:</b>Start building the borders of the puzzle by connecting the edge pieces together.</li>
    <li><b>Fill in the Center:</b>Once you have the borders in place, start filling in the center of the puzzle. Look for pieces that fit together based on their shapes and patterns.</li>
    <li><b>Use Hints:</b>If you're stuck, you can use hints to get a clue about where a particular piece might go.</li>
    <li><b>Celebrate Your Victory:</b>Once you've successfully completed the puzzle, you'll be rewarded with a spooky message and a sense of accomplishment!</li>
    </ul>
    <br>
    <b>Enjoy the Spooky Fun:</b> The Spooky Halloween Jigsaw Puzzle offers a thrilling and challenging experience. With its hauntingly beautiful images and varying difficulty levels, it's perfect for puzzle enthusiasts of all ages. So, gather your loved ones, turn off the lights, and enjoy the spooky atmosphere as you piece together these terrifying puzzles!`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "best",
    orientation: 0,
    title: "Devilish Hairdresser - playcutegames.com",
    name: ["Devilish Hairdresser", "devilish-hairdresser"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/devilish-hairdresser/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/devilish-hairdresser/250x142.png",
    path: ["/game/devilish-hairdresser", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/devilish-hairdresser/",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Devilish Hairdresser Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `In the Devilish Hairdresser game, you get to be a mischievous hairstylist and create the wildest and craziest haircuts ever! You’ll be up against an angelic hairdresser, and your goal is to cut and dye your client’s hair in your own unique devilish style. With each haircut, you’ll become more devilish, but you need to be careful not to get caught by the angel. Earn stars for your devilish haircuts and unlock new trophies to become a super devil! Start choosing a photo from the album and get to work. Just use your mouse to click and hold on the hair to cut and dye it in your devilish style. The longer you hold down the mouse button, the more devilish you become. However, you need to keep an eye on the angelic hairdresser who will be looking out for any mischievous behavior. If she catches you, you’ll be sent to Angel Prison.
    <h2 class="H2">The Devilish Hairdresser: Unleash Your Inner Mischief</h2>
    Are you ready to embrace your inner devil? The Devilish Hairdresser game invites you to step into the shoes of a mischievous stylist, where your goal is to create the wildest, most outrageous haircuts imaginable. But beware, the angelic hairdresser is watching your every move!
    `,
    tag: ["Slacking","Hair","Skill"],
    hint: `
    <ol>
    <li><b>Choose Your Client:</b> Start by selecting a photo from your album to serve as your canvas.</li>
    <li><b>Cut and Dye with Devilish Flair:</b> Use your mouse to click and hold on the hair, then drag to cut and dye it in your desired style. The longer you hold the mouse button, the more devilish your actions become.</li>
    <li><b>Evade the Angelic Watch:</b> Keep a watchful eye on the angelic hairdresser. If she catches you being too mischievous, you'll be sent to Angel Prison!</li>
    <li><b>Aim for Devilish Perfection:</b> The longer you can hold the mouse button without getting caught, the higher your devilishness level will rise.</li>
    </ol>
    <h2 class="H2">What You'll Discover:</h2>
    <ul>
    <li>Unlock New Levels and Trophies: As you progress, you'll unlock new levels and earn trophies for your devilish creations.</li>
    <li>Earn Stars and Become a Super Devil: The more outrageous your haircuts, the more stars you'll earn. Collect enough stars to become a super devil!</li>
    <li>Face Increasing Challenges: Each level brings new challenges and obstacles, testing your skills and creativity.</li>
    </ul>
    <h2 class="H2">Let Your Imagination Run Wild:</h2>

    With The Devilish Hairdresser, the possibilities are endless. Experiment with different hair lengths, colors, and styles. Create intricate designs, outrageous mohawks, or even hair sculptures. The only limit is your imagination!
    <br><br>Are you ready to unleash your inner devil and become the ultimate mischievous hairdresser? Start playing today and discover the joy of creating the wildest haircuts imaginable!`,
    rating:[4.4,1210,114],  
  },
  
  {
    date: "2024-03-05",
    brand: "best",
    orientation: 1,
    title: "Find The Correct Shadow - playcutegames.com",
    name: ["Find The Correct Shadow", "find-the-correct-shadow"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/find-the-correct-shadow/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/find-the-correct-shadow/250x142.png",
    path: ["/game/find-the-correct-shadow", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bestgamespot/find-the-correct-shadow/",
    Walkthrough: "https://youtu.be/MXTXN1pCwEo",
    s_dis:
    "Find The Correct Shadow Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `Find The Correct Shadow is a fun and educational puzzle game designed to challenge your visual skills! In each round, you'll be presented with a picture and three shadow options. Only one of the shadows matches the picture perfectly—can you find the correct one?
<br><br>
With three exciting game modes to choose from:<br>
<ul>
<li><b>Streak Mode:</b> Test how long you can maintain a perfect streak of correct answers!</li>
<li><b>Timed Mode:</b> Race against the clock and see how many correct shadows you can find in a limited time!</li>
<li><b>Learning Mode:</b> A relaxed mode designed to help you practice and improve your visual recognition skills.</li>
</ul>`,
    tag: ["Clicker","Skill","Puzzle","Endless","Exclusive"],
    hint: `
    <div class="Pad"> </div>
    <b>Start the Game:</b> Select your preferred language from the options available (English, Spanish, Turkish, Portuguese, Russian, or German).<br><br>
    <b>Choose a Mode:</b>
    <ul>
    <li>Streak Mode: Try to select the correct shadow as many times in a row as possible without making a mistake.</li>
    <li>Timed Mode: Find the correct shadows within the given time limit and score as high as you can.</li>
    <li>Learning Mode: Play at your own pace, with no pressure, perfect for practicing and learning.</li>
    </ul>
    <b>Gameplay:</b>
    <ul>
    <li>A picture will appear at the top of the screen.</li>
    <li>Below the picture, you will see three shadow options.</li>
    <li>Only one of the shadows matches the picture exactly. Tap or click on the correct shadow.</li>
    </ul>
    <b>Scoring:</b>
    <ul>
    <li>Each correct answer earns you points or adds to your streak.</li>
    <li>In timed mode, try to find as many correct shadows as you can before the timer runs out.</li>
    </ul>
    <b>Winning the Game:</b> Keep selecting the correct shadows to earn high scores, maintain streaks, or practice in learning mode to improve!
    `,
    rating:[4.4,1210,114],  
  },
 
  {
    date: "2024-03-05",
    brand: "Yandex",
    orientation: 0,
    title: "Gacha Club - playcutegames.com",
    name: ["Gacha Club", "gacha-club"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/gacha-club/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/gacha-club/250x142.png",
    path: ["/game/gacha-club", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/285558?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://youtu.be/MXTXN1pCwEo",
    s_dis:
    "Gacha Club awaits at PlayCuteGames! Play online for free and dress your characters in the best anime styles with endless customization options available!",
    l_dis:
      "Welcome to Gacha Club! Bring the latest anime fashion trends to life by dressing your characters in stylish outfits! You will find a huge selection of clothes, weapons, hats and much more in our gacha wardrobe. Now you have the opportunity to create up to 10 characters and give each of them a unique look! Perfect your look! Experiment with different hairstyles, eyes, mouths and more to create the most memorable and stylish look for each character.",
      tag: ["Anime","Dress up","Gocha","Girl games","Avatar"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "Yandex",
    orientation: 0,
    title: "Fish Eat Getting Big - playcutegames.com",
    name: ["Fish Eat Getting Big", "fish-eat-getting-big"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/fish-eat-getting-big/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fish-eat-getting-big/250x142.png",
    path: ["/game/fish-eat-getting-big", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/196487?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://youtu.be/MXTXN1pCwEo",
    s_dis:
    "Experience the thrill of Fish Eat Getting Big at PlayCuteGames! Play this free online game and enjoy the best fish-eating adventure as you grow bigger!",
    l_dis:
      "Welcome to Fish Eat Getting Big. Many fishes and various fish types in the huge ocean are waiting for you. Try to hunt smaller fishes which are specified with their numbers and try to reach the biggest size. Each level will bring you some coins and you will be able to buy new fish types from the market. You can also dive into different oceans and keep your adventure in different places.",
      tag: ["Fish","Skill","Survivor"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "playcutegames",
    orientation: 0,
    title: "My Musical Love Story - playcutegames.com",
    name: ["My Musical Love Story", "my-musical-love-story"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-musical-love-story/230x131.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/my-musical-love-story/520x295.png",
    path: ["/game/my-musical-love-story", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/my-musical-love-story/",
    Walkthrough: "https://www.youtube.com/embed/pdiHTYE8lXA",
    s_dis:
    "My Musical Love Story Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Dive into ‘My Musical Love Story,’ an interactive romance game where melodies meet emotions. Navigate your character’s journey through love, heartbreak, and the rhythm of music. Make choices, unlock chapters, and let the music of passion guide you. Immerse yourself in a symphony of emotions as you create your own love story. Will it be a harmonious melody or a poignant tune?",
      tag: ["Love","Valentine","Dress up","Exclusive","Music","Story","Role","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-04-25",
    brand: "playcutegames",
   
    orientation: 0,
    title: "Fashion Intern A Journey into Style - playcutegames.com",
    name: ["Fashion Intern A Journey into Style", "fashion-intern-a-journey-into-style"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/230x131.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
    path: ["/game/fashion-intern-a-journey-into-style", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/fashion-intern-a-journey-into-style/",
    Walkthrough: "https://www.youtube.com/embed/WipPwffGnpw",
    s_dis:
    "Fashion Intern A Journey into Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Embark on a transformative journey into the world of style with Fashion Intern! From learning the ropes to curating chic ensembles, immerse yourself in the fast-paced, glamorous world of fashion. With each task, hone your skills and unlock new opportunities to shine. Are you ready to make your mark in the industry? Join Fashion Intern and let your style journey begin!",
      tag: ["Story","Dress up","Fashion","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-02-17",
    brand: "playcutegames",
    orientation: 0,
    title: "Lovie Chics In Fantasy World - playcutegames.com",
    name: ["Lovie Chics In Fantasy World", "lovie-chics-in-fantasy-world"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-in-fantasy-world/230x131.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-in-fantasy-world/520x295.png",
    path: ["/game/lovie-chics-in-fantasy-world", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/lovie-chics-in-fantasy-world/",
    Walkthrough: "https://www.youtube.com/embed/c_WAphzz5FE",
    s_dis:
    "Lovie Chics In Fantasy World Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Embark on a magical journey with ‘Lovie Chics In Fantasy World’! Explore enchanted realms, mythical styles, and whimsical accessories. Join the chic adventure as Lovie Chics set trends in a world of fantasy. Elevate your style with ethereal fashion, from fairy-inspired ensembles to magical accessories. Let the fantasy world inspire your wardrobe and make every day an enchanting fashion moment. Immerse yourself in the magic of fashion!",
      tag: ["Lovie Chic","Fantasy","Dress up","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 0,
    title: "Zombotron Re-Boot - playcutegames.com",
    name: ["Zombotron Re-Boot", "zombotron-re-boot"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/zombotron-re-boot/250x142.png",
    path: ["/game/zombotron-re-boot", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/290564?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Zombotron Re-Boot Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `Fight with zombies, with evil robots and other undead creatures to survive in the crazy world of Zombotron Re-Boot. The action takes place on an unknown one day colonized planet, which over time was abandoned and forgotten by people. Find and rescue survivals to discover the mystery of the mysterious planet together.
    <br><br>
    Zombotron Re-Boot is a remaster of the original Zombotron Flash game series, with updated graphics, an improved physics engine and new, incredibly rich effects!
    <h2 class="H2">Key Features:</h2>
    <ul>
    <li>Well-developed destructible physical world;</li>
    <li>Lots of different weapons;</li>
    <li>You can destroy enemies using the environment;</li>
    <li>Various enemies with battle-of-the-red-and-blue-agents abilities;</li>
    <li>Use the gamepad and headphones for best experience.</li>
    </ul>
    `,
    tag: ["Shooting","Action","Halloween","Boys"],
    hint: `Clear game locations from enemies, explore and find secrets to get new types of weapons.
    <ul>
    <li>Control the hero, aim and shoot at enemies;</li>
    <li>Interact with the environment to activate interactive objects;</li>
    <li>Discover the levels to find new weapons and keep track of your ammunition;</li>
    <li>Just take a break for healing - the hero is healed automatically.</li>
    </ul>
    `,
    rating:[4.9,350,18],
  }, 
  {
    date: "2024-08-12",
    brand: "playcutegames",
    orientation: 0, 
    title: "American Doll In Princess Style - playcutegames.com",
    name: ["American Doll In Princess Style", "american-doll-in-princess-style"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/american-doll-in-princess-style/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/american-doll-in-princess-style/520x295.png",
    path: ["/game/american-doll-in-princess-style", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/american-doll-in-princess-style/",
    Walkthrough: "https://www.youtube.com/embed/zIEzcNSn4ZM",
    s_dis:
    "American Doll In Princess Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Embark on a royal journey with “American Doll in Princess Style”! Dress up your beloved American Doll in enchanting princess outfits, complete with sparkling tiaras, elegant gowns, and magical accessories. Create a fairy tale look that will make your doll shine at any royal event. Let your creativity soar as you explore various styles, from classic princess attire to modern regal trends. Perfect for anyone who loves fashion, fantasy, and fun!",
      tag: ["American doll","Girl games","Dress up","Exclusive"],
    hint: `Use your left mouse button `,
    rating:[4.4,1210,114],  
  },
    {
      date: "2024-07-25",
      brand: "Yandex",
     
      orientation: 0, 
      title: "Cross Stitch Masters - playcutegames.com",
      name: ["Cross Stitch Masters", "cross-stitch-masters"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cross-stitch-masters/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/cross-stitch-masters/520x295.png",
      path: ["/game/cross-stitch-masters", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/312008?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Cross Stitch Masters Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Immerse yourself in the world of cross stitching art and feel like a master of embroidery in Cross Stitch Masters! Color by number, relax, enjoy and have fun! Create fun and colorful pictures and share with your friends, easy and stress free.
  This game is for adults and kids any age who love cross-stitching, knitting, puzzles, nonograms, jigsaws, diamond paintings, mosaics and coloring books. Join us and unleash your creativity!
  Create a handmade gift and send it to your friends, family and loved ones for their birthday, celebration or without occasion, it’s that fun and easy!`,
      tag: ["Puzzle","Girl","Yandex"],
      hint: `Immerse yourself in the world of cross stitching and feel like a master of embroidery in Cross Stitch Masters! Color by number, relax, enjoy and have fun! Create fun and colorful pictures and share with your friends, easy and stress free. This game is for adults and kids any age who loves cross-stitching, knitting, puzzles, nonograms, jigsaws and coloring books. Join us and unleash your creativity!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-06-26",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Count And Tracing Number Games - playcutegames.com",
      name: ["Count And Tracing Number Games", "count-and-tracing-number-games"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/count-and-tracing-number-games/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/count-and-tracing-number-games/520x295.png",
      path: ["/game/count-and-tracing-number-games", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/count-and-tracing-number-games/",
      Walkthrough: "https://www.youtube.com/embed/BkOP4GwUSSo",
      s_dis:
      "Count And Tracing Number Games Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "“Count and Tracing Number Games” offers an interactive way for kids to learn numbers. This game combines fun and education, helping children to trace numbers, count objects, and recognize numerals. With vibrant graphics and engaging gameplay, kids can practice their math skills while enjoying a playful environment. Perfect for early learners, it fosters number recognition, fine motor skills, and counting abilities.",
        tag: ["Education","Kids","Math"],
      hint: `Use your left mouse button `,
      rating:[4.4,1210,114],  
    },  {
      date: "2024-08-12",
      brand: "playcutegames",
      orientation: 0, 
      title: "Celebrity Aesthetic Challenge - playcutegames.com",
      name: ["Celebrity Aesthetic Challenge", "celebrity-aesthetic-challenge"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-aesthetic-challenge/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-aesthetic-challenge/520x295.png",
      // video:"https://cdn.cutedressup.in/games/cutedressup-video/rivals-whos-gonna-be-my-girlfriend.mp4",
      path: ["/game/celebrity-aesthetic-challenge", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-aesthetic-challenge/",
      Walkthrough: "https://www.youtube.com/embed/Nsi2lAXAKDY",
      s_dis:
      "Celebrity Aesthetic Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Celebrity Aesthetic Challenge lets you explore a range of unique celebrity-inspired styles! Compete in fun fashion challenges as you mix and match outfits, accessories, and hairstyles to recreate iconic looks. From E-girl and Soft-girl vibes to edgy streetwear and glam red carpet ensembles, showcase your creativity and win the ultimate fashion battle. Can you rise to the challenge and become the trendsetting fashion icon? Let the style showdown begin!",
        tag: ["Celebrity","Girl games","Dress up","Exclusive"],
      hint: `Use your left mouse button `,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-07-25",
      brand: "Yandex",
     
      orientation: 1, 
      title: "Face Chart – Makeup Guru - playcutegames.com",
      name: ["Face Chart – Makeup Guru", "face-chart-makeup-guru"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/face-chart-makeup-guru/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/face-chart-makeup-guru/520x295.png",
      path: ["/game/face-chart-makeup-guru", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/327632?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Face Chart – Makeup Guru Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Do you dream of creating wild, fabulous looks like your favorite artists on social media? 💞<br><br>
  
  Dive into the enchanting realm of Face Chart - Makeup Guru! 💋<br><br>
  
  Wondering what a face chart is? It's your chic canvas to plan the next jaw-dropping, glamorous look. Grab a piece of paper and let it be your pocket-sized playground—experiment with new colors you hesitated to wear, pick and mix diverse designs for an endless array of possibilities.<br><br>
  
  Ready to transform a flat sketch into a charming summer makeover? Grab your brushes, unwind, and let your creativity bloom with Face Chart - Makeup Guru – an easy, DIY coloring book. Elevate the experience with soft ASMR sounds, turning up the volume for maximum relaxation.<br><br>
  
  Turn your artistic endeavors into likes, and those likes into vibrant designs for your future salon! Infuse lively colors into your lobby, revamp the kitchen!<br><br>
  
  Eager to embark on your ultimate fashion journey? Let's build the hype together 🎀🔥`,
      tag: ["Make Up","Girl","Yandex"],
      hint: ` Don't waste time: controls are easy and intuitive 💖<br>
   Explore a variety of inclusive options: diverse skin tones, a plethora of flattering colors, and on-trend looks 💖<br>
   Revel in never-ending fun: the possibilities are limitless, in perfect harmony with your creative spirit 💖<br>
   Share your creative work: with friends, your cherished makeup artists, and fellow fashion enthusiasts 💖<br>
   Take a break: whether painting on the go or sitting, let inspiration conquer the world. 💖<br>
   Change your pace: have a blast decorating your salon for added flair 💖<br><br>
  
  Indulge in a girl's bravest dream unleash your inner diva and be authentically yourself. Step into your dazzling avatar, choose bold beauty options, and make your mark in the ultimate fashion game. Become the super stylist, creating satisfying makeovers that merge fashion, beauty, and endless choices. It's not just a game; it's a super stylist's dream come true!`,
      rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "playcutegames",
    orientation: 0,
    title: "American Doll Halloween Night - Fun Dress-Up Game Online!",
    name: ["American Doll Halloween Night", "american-doll-halloween-night"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/american-doll-halloween-night/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/american-doll-halloween-night/520x295.png",
    path: ["/game/american-doll-halloween-night", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/american-doll-halloween-night/",
    Walkthrough: "https://www.youtube.com/embed/iHNnTNcR6wA",
    s_dis:
    "Join American Doll Halloween Night, a fun dress-up game! Transform your American Girl dolls into Halloween icons with stylish outfits and accessories!",
    l_dis:
      `
      Step into a world where your beloved American Girl dolls transform into the ultimate Halloween icons. Get ready to experience the thrill and magic of Halloween in a whole new dimension with American Doll Halloween Night. This dress-up game offers an extensive selection of festive outfits, accessories, and hairstyles, allowing you to create the spookiest, most stylish looks for your dolls.
      <h2 class="H22">Customize Your Doll's Look</h2>
      Dive into a wardrobe bursting with creativity. From classic witch hats and eerie vampire capes to charming pumpkin costumes and mystical fairy wings, there's no limit to the imaginative outfits you can assemble. Mix and match accessories to find the perfect combination that screams Halloween spirit. Whether your doll is heading to a costume party or getting ready for an evening of trick-or-treating, every detail counts.
      <h2 class="H22">Endless Creativity and Fun</h2>
      The game is designed to spark your creativity. You can experiment with various looks, creating a unique Halloween persona for each doll. The interactive interface makes it easy to navigate through the wardrobe and accessories, ensuring a fun and engaging experience. Want your doll to have a hauntingly beautiful makeup? Go ahead! Feel like adding a touch of sparkle to her witch's hat? Absolutely!
      <h2 class="H22">Magical Environments</h2>
      Once your doll is dressed to impress, choose from enchanting backdrops to complete the Halloween experience. Imagine your doll standing in a spooky graveyard under a full moon or posing in front of a haunted house with flickering jack-o'-lanterns. The backgrounds are designed to enhance the Halloween atmosphere, making each scene more immersive and magical.
      <h2 class="H22">Share the Spooky Fun</h2>
      What’s Halloween without sharing the fun with friends? Capture your doll's spooky look and share it with fellow fans. Get inspired by others’ creations and participate in community events where you can showcase your doll's unique style. The game encourages a sense of community and creativity, making Halloween a shared experience.
      <h2 class="H22">Join the Adventure</h2>
      Ready to bring your American Girl dolls to life in a spooky and stylish adventure? American Doll Halloween Night is your go-to game for all things Halloween and fashion. Whether you're a long-time fan of American Dolls or new to the world of dress-up games, this Halloween-themed adventure promises hours of entertainment and endless creative possibilities.
      Unleash your imagination, dress up your dolls, and dive into the spooky fun of American Doll Halloween Night. The perfect blend of fashion, creativity, and Halloween magic awaits! 🎃👗👻
      `,
      tag: ["American doll","Halloween","Dress up","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  
    {
      date: "2024-03-05",
      brand: "playcutegames",
      orientation: 0,
      title: "My Pet Care Salon - playcutegames.com",
      name: ["My Pet Care Salon", "my-pet-care-salon"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-pet-care-salon/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/my-pet-care-salon/520x295.png",
      path: ["/game/my-pet-care-salon", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-pet-care-salon/",
      Walkthrough: "",
      s_dis:
      "Run your own pet spa in My Pet Care Salon! Groom, style, and pamper adorable pets with fun activities, manage your salon, and become the ultimate pet stylist!",
      l_dis:
        "My Pet Care Salon invites you to run your very own pet spa! Groom, pamper, and style a variety of adorable pets with fun, interactive activities. From bath time and nail trimming to trendy haircuts and accessory styling, provide the best care for your furry clients. Manage your salon, meet different pets, and earn rewards as you create a welcoming environment for pets and their owners. Transform your pet care dreams into reality and become the ultimate pet stylist!",
        tag: ["Cleaning","Salon","Animal","Exclusive","Doctor","Story","Role","kid"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-07-25",
      brand: "Yandex",
     
      orientation: 1, 
      title: "Merge Coin - playcutegames.com",
      name: ["Merge Coin", "merge-coin"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/merge-coin/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/merge-coin/520x295.png",
      path: ["/game/merge-coin", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/272810?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Merge Coin Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "This is a simple and relaxing puzzle game. 10 coins of the same number can be upgraded to a higher number when put together. Let’s challenge to merge the largest numbers together.",
        tag: ["Merge","Puzzle","Casual"],
      hint: `Moved coins can only be placed in empty slots or after the same number. When 10 coins with the same number are put together, remember to click the merge button first. The game has two modes, one is to challenge the highest number, and the other is to arrange the same color. The game is very relaxing, come and experience it together`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-07-25",
      brand: "Yandex",
     
      orientation: 1, 
      title: "Draw Drive - playcutegames.com",
      name: ["Draw Drive", "draw-drive"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/draw-drive/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/draw-drive/520x295.png",
      path: ["/game/draw-drive", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/223500?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Draw Drive Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "You have to compete with rivals in the skill of drawing and speed. Think about which car is best suited for the passage of the track. And be first at the finish line.",
        tag: ["Racing","Casual"],
      hint: `Mouse control on the computer and finger on the phone. On the allotted panel, draw a car and it will appear in the game.`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-06-26",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Celebrity E-Girl vs Soft-Girl - playcutegames.com",
      name: ["Celebrity E-Girl vs Soft-Girl", "celebrity-e-girl-vs-soft-girl"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-e-girl-vs-soft-girl/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-e-girl-vs-soft-girl/520x295.png",
      path: ["/game/celebrity-e-girl-vs-soft-girl", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-e-girl-vs-soft-girl/",
      Walkthrough: "https://www.youtube.com/embed/MnKGsT6LlHw",
      s_dis:
      "Explore styles in Celebrity E-Girl vs Soft-Girl at PlayCuteGames! Play this free online game to create the best looks with edgy and soft fashion vibes.",
      l_dis:
        "Choose your style path in Celebrity E-Girl vs Soft-Girl! Explore the contrasting worlds of edgy E-Girl fashion and soft, pastel-inspired Soft-Girl trends. Mix and match outfits, and accessories to express your unique fashion statement. Compete in fashion challenges, socialize with friends, and become a trendsetter in this dynamic virtual fashion showdown!",
      tag: ["Celebrity","E-Girl","Party","Exclusive","Dress up","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-06-26",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Baby Zara Outdoor Cleaning - playcutegames.com",
      name: ["Baby Zara Outdoor Cleaning", "baby-zara-outdoor-cleaning"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/baby-zara-outdoor-cleaning/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/baby-zara-outdoor-cleaning/520x295.png",
      path: ["/game/baby-zara-outdoor-cleaning", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/baby-zara-outdoor-cleaning/",
      Walkthrough: "https://www.youtube.com/embed/7OX5qj1wgec",
      s_dis:
      "Baby Zara Outdoor Cleaning Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Join Baby Zara in her Outdoor Cleaning adventure! Help her tidy up the backyard, pick up trash, and plant flowers. With your assistance, the garden will look fresh and beautiful. Enjoy a fun and educational experience as you teach Baby Zara the importance of keeping the environment clean and green.",
      tag: ["Kids","Cleaning","Decoration","Dl-Girls","Girl games","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-08-12",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Ellie And Friends Art Hoe Aesthetic - playcutegames.com",
      name: ["Ellie And Friends Art Hoe Aesthetic", "ellie-and-friends-art-hoe-aesthetic"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-art-hoe-aesthetic/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-art-hoe-aesthetic/520x295.png",
      path: ["/game/ellie-and-friends-art-hoe-aesthetic", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/ellie-and-friends-art-hoe-aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/LPXMCq2iwAg",
      s_dis:
      "Ellie And Friends Art Hoe Aesthetic Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Ellie And Friends Art Hoe Aesthetic is all about exploring creativity through unique fashion and art! Dress them up in colorful outfits, mix and match funky accessories, and dive into the world of artistic expression. From painting and drawing to choosing the perfect look, this game lets you celebrate individuality and artistic flair with Ellie and her friends. Get ready to unleash your inner artist!",
        tag: ["Barbie","Ellie","Girl games","Dress up","Fashion","Exclusive"],
      hint: `Use your left mouse button `,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-06-16",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Incredible Princesses and Villains Puzzle - playcutegames.com",
      name: ["Incredible Princesses and Villains Puzzle", "incredible-princesses-and-villains-puzzle"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/incredible-princesses-and-villains-puzzle/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/incredible-princesses-and-villains-puzzle/520x295.png",
      path: ["/game/incredible-princesses-and-villains-puzzle", "https://playcutegames.com"],
      iframe: "https://www.dl-girls.com/content/folder_1715790017/",
      Walkthrough: "https://www.youtube.com/embed/HGm4OT-m0uY",
      s_dis:
      "Incredible Princesses and Villains Puzzle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Incredible Princesses and Villains Puzzle is an exciting game for girls where the player has to collect colorful and bright puzzles with images of princesses and villains. The game features 50 puzzles, the difficulty of which is gradually increasing. Each puzzle is unique, which contributes to the development of logical thinking and imagination among players. By collecting puzzles, you will immerse yourself in the fascinating world of fairy-tale characters, where each figure has its own role and story. The game will give you and your children hours of exciting pastime and help improve fine motor skills and attention. Lets start collecting puzzles and immerse ourselves in a world of magic and adventure with princesses and villains!",
      tag: ["Find","jigsaw","Dl-Girls","Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-06-07",
      brand: "playcutegames",
     
      orientation: 1, 
      title: "Find The 3 Difference - playcutegames.com",
      name: ["Find The 3 Difference", "find-the-3-difference"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/find-the-3-difference/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/find-the-3-difference/520x295.png",
      path: ["/game/find-the-3-difference", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bestgamespot/find-the-3-difference/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Find The 3 Difference Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Embark on an immersive visual challenge with “Find The 3 Difference” – a captivating puzzle game that puts your attention to detail to the test! Explore intricate images filled with subtle variations and discover three unique differences hidden within each pair of pictures across 80 exciting levels.",
      tag: ["Find","Hidden","Puzzle","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-06-18",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Celebrity Coachella Vibe Outfits - playcutegames.com",
      name: ["Celebrity Coachella Vibe Outfits", "celebrity-coachella-vibe-outfits"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-coachella-vibe-outfits/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-coachella-vibe-outfits/520x295.png",
      path: ["/game/celebrity-coachella-vibe-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-coachella-vibe-outfits/",
      Walkthrough: "https://www.youtube.com/embed/cPUe0eZeaSE",
      s_dis:
      "Celebrity Coachella Vibe Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Celebrity Coachella Vibe Outfits lets you dress up your favorite celebrities in trendy festival outfits. Explore a wide range of boho-chic styles, from flowy dresses to edgy accessories. Create stunning looks perfect for the Coachella vibe and make your celebs stand out at the festival. Get ready to unleash your inner fashionista!",
      tag: ["Celebrity","Coachella","Party","Exclusive","Dress up","Girl games"],
      hint: `Use your left mouse button`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-10-25",
      brand: "Yandex",
      orientation: 0,
      title: "Five Nights at Freddy’s 2 Remaster - Free Online Bubble Shooter Game!",
      name: ["Five Nights at Freddy’s 2 Remaster", "five-nights-at-freddys-2-remaster"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/five-nights-at-freddys-2-remaster/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/five-nights-at-freddys-2-remaster/520x295.png",
      path: ["/game/five-nights-at-freddys-2-remaster", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/187273?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/five-nights-at-freddys-2-remaster.mp4",
      s_dis:"Play Five Nights at Freddy’s 2 Remaster, an addictive bubble shooter game! Aim, shoot, and pop bubbles to clear the board through hundreds of challenging levels!",
      l_dis:`
      Five Nights at Freddy’s 2 Remaster brings back the fear with both old, aging animatronics and a new set of characters. The updated animatronics are equipped with cutting-edge facial recognition technology and connected to local criminal databases, designed to ensure a "safe and entertaining" experience for all ages. But even with these upgrades, the nightshift as the security guard quickly takes a dark turn.
      <br><br>
      Throughout each shift, you’ll encounter unexpected dangers as both the old and new animatronics roam the restaurant after hours. These kid-friendly figures hide sinister secrets, and it’s your job to survive each night from midnight to 6:00 am while managing limited resources like flashlights and power. Five Nights at Freddy’s 2 Remaster enhances the original game’s thrill with refined graphics and even creepier encounters. Are you ready to face the eerie world of Freddy Fazbear’s Pizza once more?
      `,
        tag: ["Fnaf",],
      hint: `
      <ul>
      <li>Objective: Survive each night from midnight to 6:00 am, avoiding attacks from old and new animatronics.</li>
      <li>Controls: Use the mouse to check cameras, control the flashlight, and put on the Freddy mask.</li></ul>
      <h2 class="H22">Gameplay:</h2><ul>
      <li>Monitor cameras to track animatronics around the restaurant.</li>
      <li>Use the flashlight and Freddy mask to ward off threats.</li>
      <li>Manage limited power and resources carefully.</li><br>
      <li>Winning: Survive all five nights to uncover the secrets hidden in Freddy Fazbear’s Pizza.</li></ul>
      <br>
      Prepare for a scarier, refined experience in Five Nights at Freddy’s 2 Remaster!
      
      `,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-10-25",
      brand: "Yandex",
      orientation: 0,
      title: "Five Nights at Freddy’s 2 Remaster - FNAF Unblocked Horror",
      name: ["Five Nights at Freddy’s 3", "five-nights-at-freddys-3"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/five-nights-at-freddys-3/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/five-nights-at-freddys-3/520x295.png",
      path: ["/game/five-nights-at-freddys-3", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/207685?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/five-nights-at-freddys-3.mp4",
      s_dis:"Five Nights at Freddy’s 2 Remaster amplifies the terror with upgraded animatronics. Survive the night from midnight to 6 AM in Freddy Fazbear’s Pizza",
      l_dis:`
      Five Nights at Freddy’s 3 brings a chilling continuation to the popular horror game series, immersing players in a new, spine-tingling environment. Set in 2015, 30 years after the initial closure of Freddy Fazbear's Pizzeria, this installment unfolds in a horror attraction known as "Fazbear's Fright". With memories of the notorious pizzeria looming large, the game transforms terrifying tales into reality, creating an intense experience for horror enthusiasts.
      <br><br>As the night guard, your goal is to survive from 12:00 am to 6:00 am, fending off eerie animatronics lurking in the shadows. This time, you must manage limited resources, repair systems, and face new threats in a creepy, deteriorating setting. Five Nights at Freddy’s 3 takes the suspense and jump-scare elements to a whole new level, challenging players to stay vigilant and strategic. Can you survive the night and uncover the mysteries hidden within Fazbear's Fright?
      `,
        tag: ["Fnaf",],
      hint: `
      <ul>
      <li>Objective: Survive each night from 12:00 am to 6:00 am by avoiding animatronic attacks.</li>
      <li>Controls: Use the mouse to check cameras, manage systems, and repair malfunctions.</li></ul>
      <h2 class="H22">Gameplay:</h2><ul>
      <li>Monitor cameras to track animatronic movement.</li>
      <li>Repair systems like ventilation, audio, and cameras to prevent malfunctions.</li>
      <li>Conserve limited resources and stay vigilant against jump-scares.</li>
      <br>
      <li>Winning: Survive all five nights to uncover the secrets within Fazbear's Fright.</li></ul>
      <br>
        Prepare for intense suspense and terrifying challenges in Five Nights at Freddy’s 3!
      `,
      rating:[4.4,1210,114],  
    },
    {
     date: "2024-08-12",
     brand: "playcutegames",
     orientation: 0, 
     title: "Glam And Glossy Summer Look - playcutegames.com",
     name: ["Glam And Glossy Summer Look", "glam-and-glossy-summer-look"],
     thumb: "https://cdn.cutedressup.in/games/cutedressup_new/glam-and-glossy-summer-look/250x142.png",
     thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/glam-and-glossy-summer-look/520x295.png",
     // video:"https://cdn.cutedressup.in/games/cutedressup-video/rivals-whos-gonna-be-my-girlfriend.mp4",
     path: ["/game/glam-and-glossy-summer-look", "https://playcutegames.com"],
     iframe: "https://games.cutedressup.net/glam-and-glossy-summer-look/",
     Walkthrough: "https://www.youtube.com/embed/lOhcTxMCn68",
     s_dis:
     "Glam And Glossy Summer Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
     l_dis:
       "Glam And Glossy Summer Look is your go-to game for creating stunning summer styles! Dive into a wardrobe filled with trendy outfits, shimmering accessories, and sun-kissed makeup. Mix and match to craft the perfect glossy summer look, whether it’s for a beach day, a pool party, or a chic city outing. Show off your fashion sense and shine bright all summer long!",
       tag: ["Black Girl","Summer","Girl games","Dress up","Exclusive"],
     hint: `Use your left mouse button `,
     rating:[4.4,1210,114],  
   },
   {
    date: "2024-03-05",
    brand: "playcutegames",
    orientation: 0,
    title: "Nightmare Couple Halloween Party - playcutegames.com",
    name: ["Nightmare Couple Halloween Party", "nightmare-couple-halloween-party"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/nightmare-couple-halloween-party/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/nightmare-couple-halloween-party/520x295.png",
    path: ["/game/nightmare-couple-halloween-party", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/nightmare-couple-halloween-party/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Nightmare Couple Halloween Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `
      Nightmare Couple Halloween Party is a spooky and stylish dress-up game where you can help the ultimate nightmare couple prepare for their eerie Halloween bash. Select from a variety of creepy costumes, haunting accessories, and makeup styles to give the couple their perfect spine-chilling look. Unleash your creativity and fashion sense to make this Halloween party an unforgettable one for the nightmare couple!
      <br><br>
      Are you ready to dive into the spooky world of Halloweentown and join Jack Skellington on a frightful fashion adventure? Our brand-new H5 Nightmare Couple Halloween 2D Dress-Up game invites you to create terrifyingly stylish outfits for Jack and Sally, the iconic couple from Tim Burton's beloved film, The Nightmare Before Christmas.
      <br> Unleash Your Inner Nightmare
      <h2 class="H2">Customize Your Characters</h2>
       With a vast array of costumes, accessories, and makeup options, you can customize Jack and Sally to your heart's desire. From classic Halloween looks to more creative and outlandish designs, the possibilities are endless. Experiment with different combinations to create truly unique and terrifying outfits.
      <h2 class="H2">Key Features:</h2>
       <ul>
       <li><b>Extensive Wardrobe:</b>Choose from a wide selection of costumes, including traditional Halloween attire, spooky monsters, and even whimsical characters from other Tim Burton films.</li>
       <li><b>Accessories Galore:</b>Add the finishing touches to your outfits with a variety of accessories, such as hats, masks, weapons, and more.</li>
       <li><b>Makeup Magic:</b>Apply different makeup styles to create a variety of spooky and sinister looks.</li>
       <li><b>Save and Share:</b>Save your favorite creations and share them with friends and family on social media.</li>
       </ul>
      <h2 class="H2">Join the Halloween Fun</h2>
       Whether you're a fan of The Nightmare Before Christmas or simply love Halloween, our dress-up game offers a fun and creative way to celebrate the spooky season. So, gather your friends and family, fire up your device, and get ready to unleash your inner nightmare!
      
      `,
      tag: ["Love","Halloween","Dress up","Exclusive","Music","Story","Role","Girl games"],
    hint: `Use your left mouse button and keyboard 
    
    <div class="Empty"></div>
    <h2 class="H2">The Nightmare Before Christmas</h2>
    The Nightmare Before Christmas is a beloved stop-motion animated musical film directed by Henry Selick and produced by Tim Burton. The film follows Jack Skellington, the "Pumpkin King" of Halloweentown, who becomes bored with the same old Halloween routine. He stumbles upon Christmas Town and becomes fascinated with the holiday, leading him to attempt to take over Christmas.
    <br><br>
    The film is known for its unique blend of Halloween and Christmas themes, as well as its memorable characters and catchy songs. It has become a classic for both children and adults and continues to be celebrated every holiday season.`,
    rating:[4.4,1210,114],  
  },
    {
      date: "2024-05-24",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Besties Reunion Party - playcutegames.com",
      name: ["Besties Reunion Party", "besties-reunion-party"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/besties-reunion-party/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/besties-reunion-party/520x295.png",
      path: ["/game/besties-reunion-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/besties-reunion-party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Make memories in the Besties Reunion Party game at PlayCuteGames! Play online for free and choose the best styles for a fun-filled reunion with friends!",
      l_dis:
        "Besties Reunion Party – Join the ultimate Besties Reunion Party! Plan an unforgettable celebration with your closest friends, filled with laughter, memories, and stylish outfits. Choose the perfect attire, decorate the venue, and capture every special moment. Whether it’s a night out or a cozy get-together, this party is all about friendship and fun. Reunite with your besties and make this event a night to remember with fabulous fashion and unique party ideas!",
      tag: ["Barbie","Dress up","Party","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    
    {
      date: "2024-06-06",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Baby Zara Home Cleaning - playcutegames.com",
      name: ["Baby Zara Home Cleaning", "baby-zara-home-cleaning"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/baby-zara-home-cleaning/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/baby-zara-home-cleaning/520x295.png",
      path: ["/game/baby-zara-home-cleaning", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/baby-zara-home-cleaning/",
      Walkthrough: "https://www.youtube.com/embed/Snhv7g8P478",
      s_dis:
      "Baby Zara Home Cleaning Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Baby Zara Home Cleaning. Join Baby Zara in her Home Cleaning adventure! Help Zara tidy up her home by organizing toys, sweeping floors, and wiping windows. This fun and interactive game teaches kids the importance of cleanliness while keeping them entertained. Get ready for a sparkling clean home with Baby Zara!",
      tag: ["Baby Zara","Cleaning","Party","Exclusive","Girl games"],
      hint: `Use your left mouse button`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-05-16",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Lovie Chic’s Insta Glam Squad - playcutegames.com",
      name: ["Lovie Chic’s Insta Glam Squad", "lovie-chics-insta-glam-squad"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-insta-glam-squad/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-insta-glam-squad/520x295.png",
      path: ["/game/lovie-chics-insta-glam-squad", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-insta-glam-squad/",
      Walkthrough: "https://www.youtube.com/embed/r3uDcghe8bQ",
      s_dis:
      "Lovie Chic’s Insta Glam Squad Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the world of glamour with Lovie Chic’s Insta Glam Squad! Join the squad and explore the latest trends in fashion, makeup, and styling. From chic streetwear to red carpet looks, unleash your inner fashionista and create stunning Insta-worthy outfits. With endless options for customization and photo shoots, become the ultimate style influencer and take the fashion world by storm with your Insta Glam Squad!",
      tag: ["Lovie Chic","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-05-06",
      brand: "playcutegames",
      orientation: 0,
      title: "Celebrity Last Fling Before The Ring - playcutegames.com",
      name: ["Celebrity Last Fling Before The Ring", "celebrity-last-fling-before-the-ring"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-last-fling-before-the-ring/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-last-fling-before-the-ring/520x295.png",
      path: ["/game/celebrity-last-fling-before-the-ring", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-last-fling-before-the-ring/",
      Walkthrough: "https://www.youtube.com/embed/YXvdhpOqVfI",
      s_dis:
      "Celebrate the best style in Celebrity Last Fling Before The Ring! Play this online game at PlayCuteGames and design stunning prom looks with chic dresses.",
      l_dis:
        "Step into the spotlight with Celebrity Last Fling Before The Ring! Explore a dazzling array of dresses, accessories, and hairstyles to create your perfect prom look. From elegant ball gowns to chic cocktail dresses, we have everything you need to shine on your special night. Get ready to dance the night away in style and make memories that will last a lifetime!",
      tag: ["Celebrity","Dress up","Wedding","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-08-02",
      brand: "Yandex",
     
      orientation: 0, 
      title: "Secret Agents Mission to Save Earth - playcutegames.com",
      name: ["Secret Agents Mission to Save Earth", "secret-agents-mission-to-save-earth"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/secret-agents-mission-to-save-earth/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/secret-agents-mission-to-save-earth/520x295.png",
      path: ["/game/secret-agents-mission-to-save-earth", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/secret-agents-mission-to-save-earth/",
      Walkthrough: "https://www.youtube.com/embed/FIYRQOu95Hc",
      s_dis:
      "Secret Agents Mission to Save Earth Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `“Secret Agents Mission to Save Earth” is an action-packed adventure game where players take on the role of elite secret agents tasked with protecting the planet. Navigate through intense missions, solve challenging puzzles, and battle formidable foes to thwart a global threat. Utilize high-tech gadgets, stealth tactics, and your wits to uncover conspiracies and save Earth from imminent danger. Embark on this thrilling journey and prove you have what it takes to be a top secret agent!`,
      tag: ["Story Games","Dress up","Girl","Exclusive"],
      hint: `Immerse yourself in the world of cross stitching and feel like a master of embroidery in Cross Stitch Masters! Color by number, relax, enjoy and have fun! Create fun and colorful pictures and share with your friends, easy and stress free. This game is for adults and kids any age who loves cross-stitching, knitting, puzzles, nonograms, jigsaws and coloring books. Join us and unleash your creativity!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-05-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s Glamorous Prom Party - playcutegames.com",
      name: ["Lovie Chic’s Glamorous Prom Party", "lovie-chics-glamorous-prom-party"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-glamorous-prom-party/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-glamorous-prom-party/520x295.png",
      path: ["/game/lovie-chics-glamorous-prom-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-glamorous-prom-party/",
      Walkthrough: "https://www.youtube.com/embed/9QVo6j0Z-Bo",
      s_dis:
      "Step into the spotlight with Lovie Chic's glamorous prom party. Explore a dazzling array of dresses and accessories to make your prom night unforgettable.",
      l_dis:
        "Step into the spotlight with Lovie Chic’s Glamorous Prom Party! Explore a dazzling array of dresses, accessories, and hairstyles to create your perfect prom look. From elegant ball gowns to chic cocktail dresses, we have everything you need to shine on your special night. Get ready to dance the night away in style and make memories that will last a lifetime!",
      tag: ["Lovie Chic","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    
    {
      date: "2024-04-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFF’s Hot Summer Style - playcutegames.com",
      name: ["BFF’s Hot Summer Style", "bffs-hot-summer-style"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-hot-summer-style/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-hot-summer-style/520x295.png",
      path: ["/game/bffs-hot-summer-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-hot-summer-style/",
      Walkthrough: "https://www.youtube.com/embed/bMP66CtLGcg",
      s_dis:
      "BFF’s Hot Summer Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Get ready to sizzle in the sun with BFF’s Hot Summer Style! Dive into a world of vibrant colors, breezy fabrics, and trendy silhouettes, curated to keep you cool and stylish all season long. From chic sundresses to playful rompers, our collection offers endless options to slay your summer look. Whether you’re lounging by the pool or hitting the beach, make a splash with BFF’s Hot Summer Style!",
        tag: ["Dress up","Summer","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-04-10",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s Holographic Trends - playcutegames.com",
      name: ["Lovie Chic’s Holographic Trends", "lovie-chics-holographic-trends"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-holographic-trends/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-holographic-trends/520x295.png",
      path: ["/game/lovie-chics-holographic-trends", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-holographic-trends/",
      Walkthrough: "https://www.youtube.com/embed/WipPwffGnpw",
      s_dis:
      "Lovie Chic’s Holographic Trends Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the future of fashion with Lovie Chic’s Holographic Trends! Embrace the mesmerizing allure of holographic fabrics and accessories, where every outfit radiates a futuristic glow. From shimmering dresses to iridescent accessories, our collection offers a dazzling array of options to elevate your style game. Stand out from the crowd and make a bold fashion statement with Lovie Chic’s Holographic Trends!",
        tag: ["Lovie Chic","Dress up","Holographic","Trends","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-04-10",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Casual Trendy Look - playcutegames.com",
      name: ["Celebrity Casual Trendy Look", "celebrity-casual-trendy-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-casual-trendy-look/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-casual-trendy-look/520x295.png",
      path: ["/game/celebrity-casual-trendy-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-casual-trendy-look/",
      Walkthrough: "https://www.youtube.com/embed/WipPwffGnpw",
      s_dis:
      "Celebrity Casual Trendy Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Explore the world of casual chic with our “Celebrity Casual Trendy Look” collection! Discover effortless yet stylish outfits curated for a relaxed yet fashionable vibe. Embrace the latest trends, mix and match pieces, and elevate your everyday style with ease. Whether you’re running errands or grabbing coffee with friends, rock your look like a celebrity!",
        tag: ["Celebrity","Dress up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-04-10",
      brand: "playcutegames",
     
      orientation: 0,
      title: "From Nerd To School Popular - playcutegames.com",
      name: ["From Nerd To School Popular", "from-nerd-to-school-popular"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/from-nerd-to-school-popular/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/from-nerd-to-school-popular/520x295.png",
      path: ["/game/from-nerd-to-school-popular", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/from-nerd-to-school-popular/",
      Walkthrough: "https://www.youtube.com/embed/-wk5sCz9vRc",
      s_dis:
      "Transform from a nerd to a school icon in From Nerd To School Popular! Guide your friend through high school challenges in this free online game!",
      l_dis:
        "Embark on a transformation journey “From Nerd To School Popular”! Help our protagonist navigate high school life, overcome challenges, and discover their true potential. With your guidance, they’ll undergo a stylish makeover, gain confidence, and rise to the top of the social ladder. Experience the ultimate high school makeover story and watch as our hero transforms from a shy outsider to a beloved school icon!",
        tag: ["Make Up","Nerd","Dress up","Exclusive","Decoration","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-03-27",
      brand: "Yandex",
     
      orientation: 1,
      title: "Jump in Rhythm to the Hit! Cat Disco! - playcutegames.com",
      name: ["Jump in Rhythm to the Hit! Cat Disco!", "jump-in-rhythm-to-the-hit-cat-disco"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/jump-in-rhythm-to-the-hit-cat-disco/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/jump-in-rhythm-to-the-hit-cat-disco/520x295.png",
      path: ["/game/jump-in-rhythm-to-the-hit-cat-disco", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/272485?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Jump in Rhythm to the Hit! Cat Disco! Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `🎵 The game "Jump in Rhythm to the Hit! Cat Disco!" offers a unique combination of cute cat meows and exciting pop music rhythms. Play along to a variety of popular tunes, including worldwide hits, and enjoy gorgeous visuals and kawaii design! ⭐ Main Features ⭐ - A large selection of popular hits! - Remixes of popular tracks with a fun "meow"! - Easy one-touch screen control! - Bright colors and fantastic design! - A variety of kawaii cats ready for adventure! 🎁 Don't miss out on the surprises we have prepared for you! Start the game "Jump in Rhythm to the Hit! Cat Disco!" and plunge into the fun world of musical adventures right now! 🌈🐾🎮 💕Don't forget to support us by leaving a review and comment if you liked the game! If you have any comments about the game, please describe the problem in as much detail as possible and we will try to fix it quickly!`,
        tag: ["kids","Rhythm","Music","Girl games"],
      hint: `🕹️The game controls are very simple. To control a character: On a smartphone📱 - move your finger across the screen left and right. On a computer💻 - move the mouse with the left button pressed. 🎯The main goal of the game is to reach the end of the level by jumping on the tiles without falling. - Guide your cat by helping him jump on the tiles to the beat of the music. - Beware of missing tiles - your task is to complete as many levels as possible and unlock all the songs. - Earn coins to unlock new cute cats and new popular tracks!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-04-08",
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Bonnie Fitness Frenzy - playcutegames.com",
      name: ["Bonnie Fitness Frenzy", "bonnie-fitness-frenzy"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bonnie-fitness-frenzy/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bonnie-fitness-frenzy/520x295.png",
      path: ["/game/bonnie-fitness-frenzy", "https://playcutegames.com"],
      iframe: "https://www.dl-girls.com/content/folder_1710606259/",
      Walkthrough: "https://www.youtube.com/embed/5sg-J7fi_cU",
      s_dis:
      "Bonnie Fitness Frenzy Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Bonnie: Fitness Frenzy - an exhilarating game for girls, where Bonnie decides to sculpt her figure into perfect form for the summer season. Join Bonnie on her adventures in the gym, where you will perform a variety of exercises by clicking the mouse to help her burn off those extra calories. After intense workouts, Bonnie will focus on applying makeup. Help her create a vibrant and stylish look for her upcoming beach outing. Choose from a plethora of cosmetics and create a unique appearance for Bonnie. And finally, it's time to dress up! Assist Bonnie in selecting fashionable and elegant sportswear to accentuate her fitness style. Choose from a variety of t-shirts, shorts, and accessories to craft a distinctive look for Bonnie. Join Bonnie on her exciting journey to achieve the ideal figure and style in the game «Bonnie Fitness Frenzy",
        tag: ["Barbie","Easter","Dress up","Dl-Girls","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Pink Barbie Dollhouse - The Ultimate Snake Game Experience",
      name: ["Pink Barbie Dollhouse", "pink-barbie-dollhouse"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/pink-barbie-dollhouse/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/pink-barbie-dollhouse/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/pink-barbie-dollhouse.mp4",
      path: ["/game/pink-barbie-dollhouse", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/282825?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "A pink Barbie dollhouse in a Minecraft-like world will open up a life simulation. Choose a character, and go live in a block world.",
      l_dis:
        `A pink Barbie dollhouse in a Minecraft-like world will open up a life simulation for you in a mansion. Choose a character, pick a skin that suits you, and go live in a block world. In the pink dollhouse mansion, you will have a lot of fun, eat, drink, and complete quests. Complete all tasks and remember that your character needs to be full of strength and energy; without it, they won’t complete any mission. Everything you need can be found in the pink dollhouse.
`,
        tag: ["Roblox","Barbie"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Barbie Pajama Party - The Ultimate Snake Game Experience",
      name: ["Barbie Pajama Party", "barbie-pajama-party"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/barbie-pajama-party/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/barbie-pajama-party/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/barbie-pajama-party.mp4",
      path: ["/game/barbie-pajama-party", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/pajama_party/",
      Walkthrough: "",
      s_dis:
      "A pink Barbie dollhouse in a Minecraft-like world will open up a life simulation. Choose a character, and go live in a block world.",
      l_dis:
        `Welcome to the Barbie Pajama Party game. It’s finally the weekend and you are heading over to the most epic slumber party of the season. Time to pick out the cutest pajamas, because who doesn’t want to relax and sleep in style? Join with barbie and have fun!
`,
        tag: ["Barbie","Dress up"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-04-08",
      brand: "cgspot",
     
      orientation: 0,
      title: "From Zombie To Glam A Spooky - playcutegames.com",
      name: ["From Zombie To Glam A Spooky", "from-zombie-to-glam-a-spooky"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/from-zombie-to-glam-a-spooky/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/from-zombie-to-glam-a-spooky/520x295.png",
      path: ["/game/from-zombie-to-glam-a-spooky", "https://playcutegames.com"],
      iframe: "https://html5.gamemonetize.co/f8i7q0hmeirg49gv2mjhfcqgw1l0vs6f/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "From Zombie To Glam A Spooky Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the game “From Zombie To Glam A Spooky”. Embark on a spooktacular journey with Ellie in From Zombie to Glam: A Spooky Transformation! Help her shed her undead appearance and embrace a new, ordinary life. Dive into levels of makeover, hand cleaning, makeup, nail decor, and dress-up for an epic Halloween makeover!",
        tag: ["Zombie","halloween","Dress up","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-04-08",
      brand: "Dl-Girls",
      orientation: 0,
      title: "Celebrity Face Dance - playcutegames.com",
      name: ["Celebrity Face Dance", "celebrity-face-dance"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-face-dance/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-face-dance/520x295.png",
      path: ["/game/celebrity-face-dance", "https://playcutegames.com"],
      iframe: "https://www.dl-girls.com/content/folder_1709396015/",
      Walkthrough: "https://www.youtube.com/embed/SMlulpm_O64",
      s_dis:
      "Celebrity Face Dance Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Celebrity Face Dance - an exciting game for girls that combines makeup and dancing. In this game, you can transform into real stars and experience being a makeup artist and dancer at the same time. Three celebrities await you - Ariana Grande, Taylor Swift, and Billie Eilish, each with their unique style and image. Choose your favorite star and start creating a unique look for her. Use various cosmetic products to enhance beauty and individuality girls. Then, when the makeup is ready, move on to the most exciting part - the dance! Follow the instructions on the screen and mimic the celebrity's moves to become a true dance floor star. Improve your skills and become the best in Celebrity Face Dance",
        tag: ["Celebrity","Dance","Dress up","Dl-Girls","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
   
    {
      date: "2024-03-27",
      brand: "playcutegames",
     
      orientation: 1,
      title: "BFF Easter Photobooth Party - playcutegames.com",
      name: ["BFF Easter Photobooth Party", "bff-easter-photobooth-party"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bff-easter-photobooth-party/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bff-easter-photobooth-party/520x295.png",
      path: ["/game/bff-easter-photobooth-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff-easter-photobooth-party/",
      Walkthrough: "https://www.youtube.com/embed/SPLZdUVIGQI",
      s_dis:
      "BFF Easter Photobooth Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the whimsical world of the “BFF Easter Photobooth Party” and celebrate the joyous holiday with your best friends! Join in on the festive fun as you dress up in vibrant Easter-themed outfits, pose for adorable photos in the photobooth, and create lasting memories together. With colorful props, festive decorations, and endless laughter, this is the perfect way to capture the spirit of Easter and enjoy a delightful day with your BFFs. ",
        tag: ["Barbie","Easter","Dress up","Exclusive","Craft","Decoration","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-03-23",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chick’s Preppy Look - playcutegames.com",
      name: ["Lovie Chick’s Preppy Look", "lovie-chicks-preppy-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chicks-preppy-look/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chicks-preppy-look/520x295.png",
      path: ["/game/lovie-chicks-preppy-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chicks-preppy-look/",
      Walkthrough: "https://www.youtube.com/embed/i9Z8eO93HlQ",
      s_dis:
      "Lovie Chick’s Preppy Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Explore the enchanting fusion of nostalgia and celebrity flair with Lovie Chick’s Preppy Look’. Dive into a whimsical realm where childhood memories inspire vibrant, playful fashion choices. Discover quirky outfits and accessories that blend retro charm with modern celebrity style. Embrace your inner child and express yourself in a fun, creative way! ",
        tag: ["Lovie Chic","Preppy","Dress up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-03-14",
      brand: "playcutegames",
     
      orientation: 1,
      title: "St Patrick’s Day Tic-Tac-Toe - Play at playcutegames!",
      name: ["St Patrick’s Day Tic-Tac-Toe", "st-patricks-day-tic-tac-toe"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/st-patricks-day-tic-tac-toe/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/st-patricks-day-tic-tac-toe/520x295.png",
      path: ["/game/st-patricks-day-tic-tac-toe", "https://playcutegames.com"],
      iframe:"https://games.cutedressup.net/bestgamespot/st-patricks-day-tic-tac-toe/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "St Patrick’s Day Tic-Tac-Toe Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Bring luck to your game with ‘St. Patrick’s Day Tic-Tac-Toe’! Play on vibrant themed boards, featuring shamrocks and pots of gold. Challenge friends or AI, and enjoy the festive twist on a classic. Whether you’re an X or an O, may the luck of the Irish be with you. Elevate your St. Patrick’s Day celebration with this charming, strategy-filled game. Play now and let the fun, luck, and greenery unfold in every move!`,
        tag: ["Puzzle","Action","Patricks","Tic Tac Toe","Exclusive"],
      hint: `Click or Tap`,
      rating:[4.4,1210,114],
    },
    {
      date: "2024-03-18",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Kidcore Aesthetic - playcutegames.com",
      name: ["Celebrity Kidcore Aesthetic", "celebrity-kidcore-aesthetic"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-kidcore-aesthetic/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-kidcore-aesthetic/520x295.png",
      path: ["/game/celebrity-kidcore-aesthetic", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-kidcore-aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/riL8mMHsoc0",
      s_dis:
      "Celebrity Kidcore Aesthetic Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Explore the enchanting fusion of nostalgia and celebrity flair with ‘Celebrity Kidcore Aesthetic’. Dive into a whimsical realm where childhood memories inspire vibrant, playful fashion choices. Discover quirky outfits and accessories that blend retro charm with modern celebrity style. Embrace your inner child and express yourself in a fun, creative way! ",
        tag: ["Lovie Chic","Kidcore","Dress up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    
    {
      date: "2024-03-14",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chics Spring Break Fashion - Play at playcutegames!",
      name: ["Lovie Chics Spring Break Fashion", "lovie-chics-spring-break-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-spring-break-fashion/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-spring-break-fashion/520x295.png",
      path: ["/game/lovie-chics-spring-break-fashion", "https://playcutegames.com"],
      iframe:"https://games.cutedressup.net/lovie-chics-spring-break-fashion/",
      Walkthrough: "https://www.youtube.com/embed/T1YYciNmyu0",
      s_dis:
      "Lovie Chics Spring Break Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Lovie Chics Spring Break Fashion” is your ultimate destination for chic and trendy outfits perfect for the spring break season! Explore our curated collection of vibrant dresses, stylish swimwear, and must-have accessories designed to elevate your style and make a statement wherever you go. Whether you’re hitting the beach, lounging by the pool, or exploring new destinations, we have everything you need to look and feel fabulous all spring break long. Embrace the sunny vibes and shop our collection now`,
        tag: ["Puzzle","Action","Spring","Exclusive"],
      hint: `Click or Tap`,
      rating:[4.4,1210,114],
    },
    {
      date: "2024-03-07",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Mr Hacker: The Museum Hunt - Play free at playcutegames!",
      name: ["Mr Hacker: The Museum Hunt", "mr-hacker-the-museum-hunt"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mr-hacker-the-museum-hunt/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mr-hacker-the-museum-hunt/520x295.png",
      path: ["/game/mr-hacker-the-museum-hunt", "https://playcutegames.com"],
      iframe:"https://games.cutedressup.net/bestgamespot/mr-hacker-the-museum-hunt/",
      Walkthrough: "https://www.youtube.com/embed/xhIu5t0EaZo",
      s_dis:
      "Mr Hacker: The Museum Hunt Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Join the thrilling heist in ‘Mr Hacker: The Museum Hunt.’ As a hired hacker, your mission is to crack 150 vaults in a high-stakes museum break-in. Unleash your skills, avoid security, and uncover precious diamonds. Are you up for the challenge? Play now and immerse yourself in the world of cunning schemes and high-tech heists.`,
        tag: ["Puzzle","Action","Hacker","Exclusive"],
      hint: `Click `,
      rating:[4.4,1210,114],
    },
    {
      date: "2024-02-28",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Lovie Chic’s St Patricks Day Costumes - playcutegames.com",
      name: ["Lovie Chic’s St Patricks Day Costumes", "lovie-chics-st-patricks-day-costumes"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-st-patricks-day-costumes/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-st-patricks-day-costumes/520x295.png",
      path: ["/game/lovie-chics-st-patricks-day-costumes", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-st-patricks-day-costumes/",
      Walkthrough: "https://www.youtube.com/embed/14fQ5xgHd88",
      s_dis:
      "Lovie Chic’s St Patricks Day Costumes Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Join the festive fun with Lovie Chic’s St Patricks Day Costumes! Explore a collection of vibrant green ensembles, lucky accessories, and Irish-inspired styles. Elevate your St. Patrick’s Day look with chic outfits that capture the spirit of the celebration. Join Lovie Chic in setting trends for this lively holiday. From parades to parties, embrace the charm of St. Patrick’s Day with fashion that brings luck and style to your wardrobe. Let the green magic unfold in every outfit with Lovie Chic’s expert inspiration!",
        tag: ["Lovie Chic","Festivale","Dress up","Patricks","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-02-14",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Kendel 7 Days 7 Styles - playcutegames.com",
      name: ["Kendel 7 Days 7 Styles", "kendel-7-days-7-styles"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/kendel-7-days-7-styles/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/kendel-7-days-7-styles/520x295.png",
      path: ["/game/kendel-7-days-7-styles", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/kendel-7-days-7-styles/",
      Walkthrough: "https://www.youtube.com/embed/bIivHyNJbVg",
      s_dis:
      "Kendel 7 Days 7 Styles Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into a week-long fashion adventure with ‘Kendel 7 Days 7 Styles’! Explore a daily wardrobe transformation, from casual chic to elegant evenings. Join Kendel in setting trends and elevating your style each day. Immerse yourself in the excitement of diverse fashion, unlock new looks, and make every day a stylish statement. From Monday to Sunday, embrace the thrill of reinventing your style with Kendel’s expert inspiration. Elevate your wardrobe and step into a week of endless fashion possibilities!",
        tag: ["Puzzle","Board","Dress up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-02-02",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Cupid Valentine Tic-Tac-Toe - playcutegames.com",
      name: ["Cupid Valentine Tic-Tac-Toe", "cupid-valentine-tic-tac-toe"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cupid-valentine-tic-tac-toe/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/cupid-valentine-tic-tac-toe/520x295.png",
      path: ["/game/cupid-valentine-tic-tac-toe", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/cupid-valentine-tic-tac-toe/",
      Walkthrough: "https://www.youtube.com/embed/mUMDiViGM90",
      s_dis:
      "Cupid Valentine Tic-Tac-Toe Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Cupid Valentine Tic-Tac-Toe. Dive into the Island Princess’s world of style! Explore tropical ensembles, beachy accessories, and exotic looks. Join the fashion adventure as she sets trends in paradise. Elevate your style and embrace the magic of Island Princess’s wardrobe. From casual strolls to beach parties, let the island inspire your fashion journey!",
        tag: ["Puzzle","Board","Dress up","Exclusive","Valentine","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },{
      date: "2024-02-17",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity Spring Manicure Design - playcutegames.com",
      name: ["Celebrity Spring Manicure Design", "celebrity-spring-manicure-design"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-spring-manicure-design/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-spring-manicure-design/520x295.png",
      path: ["/game/celebrity-spring-manicure-design", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-spring-manicure-design/",
      Walkthrough: "https://www.youtube.com/embed/DpdeySxpyt4",
      s_dis:
      "Celebrity Spring Manicure Design Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the glamour of ‘Celebrity Spring Manicure Design’! Explore a world of trendy nail art, vibrant colors, and chic designs. Join celebrities in setting the trend for spring nails. Elevate your style with creative manicures, from floral elegance to modern motifs. Immerse yourself in the art of nail fashion and transform your fingertips into a canvas of spring-inspired beauty. Express your unique style, celebrate the season, and make your nails a fashion statement with Celebrity Spring Manicure Design!",
        tag: ["Celebrity","Fantasy","Dress up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-02-02",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Island Princess All Around The Fashion - playcutegames.com",
      name: ["Island Princess All Around The Fashion", "island-princess-all-around-the-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/island-princess-all-around-the-fashion/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/island-princess-all-around-the-fashion/520x295.png",
      path: ["/game/island-princess-all-around-the-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/island-princess-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/mUMDiViGM90",
      s_dis:
      "Island Princess All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Island Princess All Around The Fashion. Dive into the Island Princess’s world of style! Explore tropical ensembles, beachy accessories, and exotic looks. Join the fashion adventure as she sets trends in paradise. Elevate your style and embrace the magic of Island Princess’s wardrobe. From casual strolls to beach parties, let the island inspire your fashion journey!",
        tag: ["Princess","Dress up","Exclusive","Fashion","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-02-07",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Valentine Nail Salon - playcutegames.com",
      name: ["Valentine Nail Salon", "valentine-nail-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/valentine-nail-salon/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/valentine-nail-salon-300.jpg",
      path: ["/game/valentine-nail-salon", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/valentine-nail-salon/",
      Walkthrough: "https://www.youtube.com/embed/ibv37Ce1T98",
      s_dis:
      "Valentine Nail Salon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Valentine Nail Salon. You own this chic nail salon where many girls come to have their manicures done. As today is Valentine’s day, you have to create a sweet romantic manicure for your client who wants to look wonderful on such an important day. So, prove what a good nail artist you are by making her hands look glamorous. Choose the length and the shape of the nails you want and then begin painting them with style. Choose the nail polish color you want to use and color the nails as fancy as you can. You can give her a very trendy french manicure or a classic one, you can use a single color or multiple ones. There are also some cool nail accessories and fancy rings you can choose from to make her hands look perfect.",
      tag: ["Nail","Girl games","Valentine","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2024-02-09",
      brand: "yandex",
     
      orientation: 0,
      title: "Jungle Bubble Shooter - playcutegames.com",
      name: ["Jungle Bubble Shooter", "jungle-bubble-shooter"],
      thumb: "https://avatars.mds.yandex.net/get-games/1881371/2a0000018630e1ad4ea7994006afd18d0a83/icon1",
      thumb_big: "https://avatars.mds.yandex.net/get-games/1881371/2a0000018630e1ad4ea7994006afd18d0a83/icon1",
      path: ["/game/jungle-bubble-shooter", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/214728?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Jungle Bubble Shooter Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Aim and match bubbles and collect 3 or more identical bubbles.<br>
         Use the props in the game.<br>
        Its use makes it easy for you to pass the game.<br>
        The less bubbles used to complete the game, the higher the score.
        `,
        tag: ["Bubble","kids"],
      hint: `A client will come into your salon and ask for a dream haircut.
      Fulfill her wishes and make the girl happy!<br>
      And for this, carefully choose the shape of the haircut, hair color, jewelry and create a stylish image.<br>
      Accumulate money and do not forget to improve your salon and make cool repairs in it!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-02-09",
      brand: "yandex",
     
      orientation: 0,
      title: "Bubble Shooter Classic - playcutegames.com",
      name: ["Bubble Shooter Classic", "bubble-shooter-classic"],
      thumb: "https://avatars.mds.yandex.net/get-games/1881371/2a0000017c288b4ae5bcfcae80d8d4cc1b30/icon1",
      thumb_big: "https://avatars.mds.yandex.net/get-games/1881371/2a0000017c288b4ae5bcfcae80d8d4cc1b30/icon1",
      path: ["/game/bubble-shooter-classic", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/177601?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "All you need is to shoot bubbles across the colored field to make combinations of 3 or more bubbles of the same color. The more bubbles you shoot down in one shot, the more points you get. For the dropped bubbles, you get twice as many points! The playing field will go down one row if you fail to collect a combination.",
      l_dis:
        `"- Use the Sight to hit the target clearly!<br>
        - Use the Bomb to shoot down all the bubbles around!<br>
        - Drop bubbles to get twice as many points.<br>
        - Collect as many points as possible and become the best player on bestgame spot!"
        `,
        tag: ["Bubble","kids"],
      hint: `A client will come into your salon and ask for a dream haircut.
      Fulfill her wishes and make the girl happy!<br>
      And for this, carefully choose the shape of the haircut, hair color, jewelry and create a stylish image.<br>
      Accumulate money and do not forget to improve your salon and make cool repairs in it!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-06-17",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Panda Tropical Wedding Story - playcutegames.com",
      name: ["Panda Tropical Wedding Story", "panda-tropical-wedding-story"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/panda-tropical-wedding-story/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/panda-tropical-wedding-story-300.jpg",
      path: ["/game/panda-tropical-wedding-story", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/panda-tropical-wedding-story/",
      Walkthrough: "https://www.youtube.com/embed/yTCfJXZFzm0",
      s_dis:
      " Panda Tropical Wedding Story Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the vibrant world of the Panda Tropical Wedding Story! Unleash your creativity as you style the adorable pandas in stunning tropical wedding outfits. Mix and match glamorous dresses, stylish accessories, and beautiful hairstyles to make the pandas look their best on their special day. Get ready for a fashion-filled adventure!",
      tag: ["Rainbow Girls","Dress up","Girl games","Pastel","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-02-07",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Avatar The Way Of Love - playcutegames.com",
      name: ["Avatar The Way Of Love", "avatar-the-way-of-love"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/avatar-the-way-of-love/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/avatar-the-way-of-love-300.jpg",
      path: ["/game/avatar-the-way-of-love", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/avatar-the-way-of-love/",
      Walkthrough: "https://www.youtube.com/embed/EMu97EhaAz0",
      s_dis:
      "Avatar The Way Of Love Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Avatar The Way Of Love. Can you guess what day it is? Okay, maybe we can give you a clue. Love is in the air tonight. Yeah, it’s Valentine’s Day alright. These Avatar couples love to spend time together this valentine’s in the city. They plan to wear new trendy clothes.",
      tag: ["Avatar","Dress up","Girl games","Fashion","Valentine","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "TB World - playcutegames.com",
      name: ["TB World", "tb-world"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/tb-world/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/tb-world/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/tb-world.mp4",
      path: ["/game/tb-world", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/364679?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play TB World, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Discover the fascinating world of TB World, where creativity and joy await you! Here, you can bring your fantasies to life by designing stylish looks for characters and creating cozy interiors. The design process becomes a true pleasure: choose from a wide range of clothing, accessories, and decor items to make each look and space unique. Transform your characters and their surroundings, crafting a distinctive atmosphere. This game is your personal creativity workshop where you create your own stories. Start your exciting journey into the design world today and let your imagination run free, creating unique looks and cozy interiors!
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca Build a 5-Star Hotel - playcutegames.com",
      name: ["Toca Boca Build a 5-Star Hotel", "toca-boca-build-a-5-star-hotel"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-build-a-5-star-hotel/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-build-a-5-star-hotel/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toca-boca-build-a-5-star-hotel.mp4",
      path: ["/game/toca-boca-build-a-5-star-hotel", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/291344?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/zyqfolATmFk",
      s_dis:
      "Toca Boca Build a 5-Star Hotel is a popular construction game where players can create their own world and control various characters.",
      l_dis:
        `“Toca Boca Build a 5-Star Hotel” is a popular construction game where players can create their own world and control various characters. In this game, characters visit a 5-star hotel, which has many interesting places and activities. One of the main places in a 5-star hotel is the 3-story hotel. The player can furnish the three floors of the hotel with furniture according to their taste. Control the characters of the Toca Boca world.
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Miga World Hospital - playcutegames.com",
      name: ["Miga World Hospital", "miga-world-hospital"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/miga-world-hospital/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/miga-world-hospital/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/miga-world-hospital.mp4",
      path: ["/game/miga-world-hospital", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/260751?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Miga World Hospital is a popular construction game where players can create their own world and control various characters.",
      l_dis:
        `Miga World Hospital is a fun and informative adventure that introduces players to the world of health care and emphasizes the importance of caring for their own health. The game was created especially for children and teenagers who wish to learn more about the various aspects of a hospital and learn first aid skills.
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toka Boca World: Night City - playcutegames.com",
      name: ["Toka Boca World: Night City", "toka-boca-world-night-city"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toka-boca-world-night-city/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toka-boca-world-night-city/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toka-boca-world-night-city.mp4",
      path: ["/game/toka-boca-world-night-city", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/329680?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Toka Boca World: Night City lets kids explore, create characters, and design spaces in a virtual city with locations like restaurants, hospitals, and shops.",
      l_dis:
        `Toka Boca World: Night City is a digital puppet game in which players can explore a virtual night city and create their own stories. The game has various locations to choose from, including a restaurant, a hospital, an aquarium, a courtroom, a prison, a bank, an office and shops. Players can also create their own characters and change the interior design. The game is designed to encourage children’s imagination and creativity. It allows them to create their own stories and play the way they want.
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toсa Boсa clothing store - playcutegames.com",
      name: ["Toсa Boсa clothing store", "toсa-boсa-clothing-store"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toсa-boсa-clothing-store/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toсa-boсa-clothing-store/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toсa-boсa-clothing-store.mp4",
      path: ["/game/toсa-boсa-clothing-store", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/350569?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Welcome to Toca Boca clothing store! Move characters, change outfits, and create your own fashion avatar. Take selfies and explore fun, unique costumes!",
      l_dis:
        `Welcome to Toсa Boсa clothing store. Move the characters around the location of the clothing store with a mouse or finger on the phone screen. Take a selfie by pressing the camera button. The characters can change their clothes. Try unique costumes for each character and make your own fashion avatar.
      <br><br>
        In the game you will be waiting for:<br><br>
        - Creating unique looks;<br>
        - Hair coloring, creating hairstyles;<br>
        - A large closet with a unique selection of clothes;<br>
        - Choosing emotions for the character.<br>
        - Currency Earning;<br>
        - Upgrade currency per click;<br>
        - Cool mini-game “Left or Right?”<br> 
      
      `,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca Cooking - playcutegames.com",
      name: ["Toca Boca Cooking", "toca-boca-cooking"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-cooking/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-cooking/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toca-boca-cooking.mp4",
      path: ["/game/toca-boca-cooking", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/257197?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Welcome to Toca Boca Cooking! Serve food from your truck, earn money, and grow into a cafe. Enjoy 50+ levels, kitchen upgrades, and exciting game modes!",
      l_dis:
        `Welcome to Toca Boca Cooking. The goal of the game is to serve food to all the customers visiting your food truck. Earn more money and build your small food truck to a big cafe in the center of the airport. What awaits you: – More than 50 large levels – Various kitchen equipment upgrades – Several game modes
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Avatar Life: Hacking - playcutegames.com",
      name: ["Avatar Life: Hacking", "avatar-life-hacking"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/avatar-life-hacking/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/avatar-life-hacking/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/avatar-life-hacking.mp4",
      path: ["/game/avatar-life-hacking", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/306718?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Avatar Life: Hacking is a fun simulation game set in a vibrant world. Explore unique locations, interact with characters, customize interiors, and control your space!",
      l_dis:
        `“Avatar Life: Hacking” is an entertaining simulation game. A colorful and amazing Avatar world awaits you.  There are several unique locations available in the game. Each of them contains different items and characters. You can move between locations using the main map in the game. There are many characters and items available to you. Each zone has its own content. Interact with him and control the space. You can create your own unique interior. All characters interact with objects and surroundings.
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca and the Circus: mix - playcutegames.com",
      name: ["Toca Boca and the Circus: mix", "toca-boca-and-the-circus-mix"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-and-the-circus-mix/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-and-the-circus-mix/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toca-boca-and-the-circus-mix.mp4",
      path: ["/game/toca-boca-and-the-circus-mix", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/278329?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Toca Boca and the Circus: Mix is a fun simulation game where you explore two worlds, transform characters and objects, and create unique photos using portals!",
      l_dis:
        `“Toca Boca and the Circus: mix” is an exciting simulation game. You will discover two amazing worlds: the Current Side and the amazing digital circus. The game is divided into three zones. Each of the zones includes two different worlds. What kind of world you build depends on you! Incredible transformations of the main characters and objects are available. Just transfer objects from one world to another through the portal. Control characters, objects and space! All characters change, move and interact with objects. There will be a portal waiting for you in each location that will help you change the world. Have fun and create unique photos.
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca and Avatar Mix - playcutegames.com",
      name: ["Toca Boca and Avatar Mix", "toca-boca-and-avatar-mix"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-and-avatar-mix/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-and-avatar-mix/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toca-boca-and-avatar-mix.mp4",
      path: ["/game/toca-boca-and-avatar-mix", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/339461?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Get creative with Toca Boca and Avatar Mix at PlayCuteGames! Play this free online game to design the best avatars and enjoy endless customization fun.",
      l_dis:
        `‘Toca Boca and Avatar Mix’ is an exciting simulation game where you will encounter two amazing worlds: Avatar and Toca Boca. The game offers six unique locations with different content and mechanics. You can design your own interior, compare the two worlds through a portal or modify existing interiors. The choice is yours! A variety of items and characters will captivate you. Control them as you wish. All of them interact with each other and move around. You can take a snapshot of your achievements at any time during the game. Manage space and characters to create unique photos!
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca High School - playcutegames.com",
      name: ["Toca Boca High School", "toca-boca-high-school"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-high-school/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-high-school/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toca-boca-high-school.mp4",
      path: ["/game/toca-boca-high-school", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/307054?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Toca Boca High School is a fun simulation game where you explore two worlds, design interiors, control characters, and capture unique photos using a portal!",
      l_dis:
        `‘Toca Boca High School’ is an exciting simulation game where you will encounter two amazing worlds: Avatar and Toca Boca. The game offers six unique locations with different content and mechanics. You can design your own interior, compare the two worlds through a portal or modify existing interiors. The choice is yours! A variety of items and characters will captivate you. Control them as you wish. All of them interact with each other and move around. You can take a snapshot of your achievements at any time during the game. Manage space and characters to create unique photos!
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca Everything Unlocked - playcutegames.com",
      name: ["Toca Boca Everything Unlocked", "toca-boca-everything-unlocked"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-everything-unlocked/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-everything-unlocked/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toca-boca-everything-unlocked.mp4",
      path: ["/game/toca-boca-everything-unlocked", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/335526?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Toca Boca Everything Unlocked lets you create and decorate homes with furniture and accessories. No hacks needed—just launch, design, and enjoy arranging characters!",
      l_dis:
        `Toca Boca Everything Unlocked – is a game in which you can create a new house and decorate it with furniture, decorations, and accessories. Hacking is no longer required. Launch the latest version and start creating! Arrange furniture and characters, arrange a photo shoot, and find friendly neighbors for your characters.
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca Housewarming - playcutegames.com",
      name: ["Toca Boca Housewarming", "toka-boka-housewarming"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toka-boka-housewarming/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toka-boka-housewarming/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toka-boka-housewarming.mp4",
      path: ["/game/toka-boka-housewarming", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/278077?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Toca Boca Housewarming lets you design homes for friends, dress up characters, choose emotions, and care for cute pets. Host the most fun housewarming party!",
      l_dis:
        `Toka Boka Housewarming is your own story, in which you can comfortably arrange new homes for friends. Features of the game: – a variety of clothing and accessories options for the characters; – the possibility of a unique design using furniture and decor; – options for emotions in your characters;- cute pets that can be fed. Arrange the most fun housewarming party with your friends!
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 0,
      title: "Mahjong Mania - playcutegames.com",
      name: ["Mahjong Mania", "mahjong-mania"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-mania/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-mania/250x142.png",
      path: ["/game/mahjong-mania", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-mania.mp4",
      iframe: "https://yandex.com/games/app/96479?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mahjong Mania Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Mahjong Mania! With three difficulty levels, dynamic graphics and increasingly tricky levels to enjoy, you can pick up a game whenever you fancy, but don’t forget that not all matches are what the immediately appear to be. In mahjong, seasonal tiles can be matched with each other, even if the pictures do not match. A fun alternative to solitaire, tile-matching games can’t always be completed, but you can use the shuffle option to maximize your chances.
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 1,
      title: "Mahjong Russian - playcutegames.com",
      name: ["Mahjong Russian", "mahjong-russian"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-russian/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-russian/250x142.png",
      path: ["/game/mahjong-russian", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-russian.mp4",
      iframe: "https://yandex.com/games/app/258375?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mahjong Russian Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Mahjong Russian! The rules are classic: you need to remove identical pairs of tiles (pictures). To do this, you need to find and select two identical pictures. Tiles are laid out in a certain way, and some of the upper tiles cover the lower ones. Therefore, you first need to find and remove the top tiles, and then access to the bottom tiles will open.
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 0,
      title: "Mahjong Daddy - playcutegames.com",
      name: ["Mahjong Daddy", "mahjong-daddy"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-daddy/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-daddy/250x142.png",
      path: ["/game/mahjong-daddy", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-daddy.mp4",
      iframe: "https://yandex.com/games/app/241515?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mahjong Daddy Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Mahjong Daddy! Beautiful, colorful mahjong, created for true connoisseurs of this game. There are no restrictions here: neither by time, nor by levels, nor by meaningless tasks. Only the game itself, only mahjong! A fun alternative to solitaire, tile-matching games can’t always be completed, but you can use the shuffle option to maximize your chances.
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 0,
      title: "Mahjong Butterflies - playcutegames.com",
      name: ["Mahjong Butterflies", "mahjong-butterflies"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-butterflies/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-butterflies/250x142.png",
      path: ["/game/mahjong-butterflies", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-butterflies.mp4",
      iframe: "https://yandex.com/games/app/303288?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mahjong Butterflies Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Mahjong Butterflies! Enjoy the captivating gameplay, known to all fans of the Mahjong series. Play in full screen and collect all the tiles by matching their pairs according to the rules of classic Mahjong. Imerse yourself in the unique world of Mahjong in full screen and dive into the depths of this captivating puzzle!
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 0,
      title: "Mahjong Tiles - playcutegames.com",
      name: ["Mahjong Tiles", "mahjong-tiles"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-tiles/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-tiles/250x142.png",
      path: ["/game/mahjong-tiles", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-tiles.mp4",
      iframe: "https://yandex.com/games/app/328023?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mahjong Tiles Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Mahjong Tiles! Remove identical mahjong tiles from the field. You can only remove identical tiles that do not border others with at least one side. You can choose the type of tiles in the game. The playing field can be moved around the screen, enlarged or reduced. Shuffling and hint options are available.
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 0,
      title: "Mahjong Connect - playcutegames.com",
      name: ["Mahjong Connect", "mahjong-connect"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-connect/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-connect/250x142.png",
      path: ["/game/mahjong-connect", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-connect.mp4",
      iframe: "https://yandex.com/games/app/328023?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mahjong Connect Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Mahjong Connect – an exciting puzzle game where you can play for free, without registration, and without time limits. Enjoy the captivating gameplay, known to all fans of the Mahjong series. Play in full screen and collect all the tiles by matching their pairs according to the rules of classic Mahjong. Imerse yourself in the unique world of Mahjong in full screen and dive into the depths of this captivating puzzle!
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 0,
      title: "Mahjong Competitions - playcutegames.com",
      name: ["Mahjong Competitions", "mahjong-competitions"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-competitions/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-competitions/250x142.png",
      path: ["/game/mahjong-competitions", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-competitions.mp4",
      iframe: "https://yandex.com/games/app/260145?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Play Mahjong Competitions, a fun tile-matching game with three difficulty levels. Match seasonal tiles, use shuffle for new chances, and enjoy dynamic, challenging gameplay!",
      l_dis:
        `Mahjong Competitions is one of the most popular board games in the world. With three difficulty levels, dynamic graphics and increasingly tricky levels to enjoy, you can pick up a game whenever you fancy, but don’t forget that not all matches are what the immediately appear to be. In mahjong, seasonal tiles can be matched with each other, even if the pictures do not match. A fun alternative to solitaire, tile-matching games can’t always be completed, but you can use the shuffle option to maximize your chances.
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 1,
      title: "Fluffy Mahjong - playcutegames.com",
      name: ["Fluffy Mahjong", "fluffy-mahjong"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/fluffy-mahjong/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fluffy-mahjong/250x142.png",
      path: ["/game/fluffy-mahjong", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/fluffy-mahjong.mp4",
      iframe: "https://yandex.com/games/app/361067?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Play Fluffy Mahjong, a fun tile-matching game with three difficulty levels. Match seasonal tiles, use shuffle for new chances, and enjoy dynamic, challenging gameplay!",
      l_dis:
        `Fluffy Mahjong is a puzzle game where you will need to find pairs of fuzzy chips. With three difficulty levels, dynamic graphics and increasingly tricky levels to enjoy, you can pick up a game whenever you fancy, but don’t forget that not all matches are what the immediately appear to be. In mahjong, seasonal tiles can be matched with each other, even if the pictures do not match. A fun alternative to solitaire, tile-matching games can’t always be completed, but you can use the shuffle option to maximize your chances
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 1,
      title: "Magic Mahjong - playcutegames.com",
      name: ["Magic Mahjong", "magic-mahjong"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/magic-mahjong/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/magic-mahjong/250x142.png",
      path: ["/game/magic-mahjong", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/magic-mahjong.mp4",
      iframe: "https://yandex.com/games/app/311912?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Play Magic Mahjong, a fun tile-matching game with three difficulty levels. Match seasonal tiles, use shuffle for new chances, and enjoy dynamic, challenging gameplay!",
      l_dis:
        `Magic Mahjong is an enchanting twist on the classic tile-matching game that offers both relaxation and challenge. With three difficulty levels, stunning graphics, and increasingly tricky levels, you'll find hours of entertainment. But beware—not all tile matches are as they seem! In this magical version of Mahjong, seasonal tiles can be paired even if their images don’t match, adding a unique strategy to the game. Whether you're looking for a quick game or a longer session, Magic Mahjong is a fun and rewarding way to spend your time. Use the shuffle feature to enhance your chances and master the board in this engaging alternative to solitaire!
        `,
        tag: ["Mahjong"],
      hint: `
      <b>Start the Game:</b>
      <ul>
      <li>Select from three difficulty levels: Easy, Medium, or Hard depending on your experience and mood.</li>
      </ul>
      <b>Gameplay:</b>
      <ul>
      <li>The objective is to remove all the tiles from the board by matching pairs of identical tiles.</li>
      <li>Seasonal tiles can be matched even if their pictures differ, but they must belong to the same season or group.</li>
      <li>Only free tiles, which are not covered and have at least one side open, can be matched.</li>
      </ul>
      <b>Strategy:</b>
      <ul>
      <li>Look ahead and plan your moves carefully to avoid getting stuck.</li>
      <li>If no matches are available, use the Shuffle option to reshuffle the remaining tiles and create new opportunities.</li>
      </ul>
      <b>Winning:</b>
      <ul>
      <li>Successfully match all the tiles to clear the board and complete the level.</li>
      <li>Keep improving your skills and aim to complete harder difficulty levels for an extra challenge!</li>
      </ul>
      <b>Additional Features:</b>
      <ul>
      <li>Enjoy dynamic visuals and tile designs that enhance the magical atmosphere of the game.</li>
      <li>Replay levels to improve your time and score, or simply relax and enjoy the process.</li>
      </ul>
      `,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Aesthetic World of Obby - The Ultimate Snake Game Experience",
      name: ["Aesthetic World of Obby", "aesthetic-world-of-obby"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/aesthetic-world-of-obby/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/aesthetic-world-of-obby/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/aesthetic-world-of-obby.mp4",
      path: ["/game/aesthetic-world-of-obby", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/360454?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Aesthetic World of Obby, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Aesthetic World of Obby is a beautiful adventure game similar to the popular parkour game Roblox. Enjoy the nice aesthetic environment, jump on colorful platforms and go through parkour maps. 72 options for customizing the obby-avatar, Open world with 10 locations with parkour, Beautiful aesthetic graphics of the levels and Convenient controls.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Pink City - The Ultimate Snake Game Experience",
      name: ["Pink City", "pink-city"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/pink-city/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/pink-city/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/pink-city.mp4",
      path: ["/game/pink-city", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/350228?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Pink City, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Pink City is a vibrant, open-world adventure where exciting tasks and endless opportunities await. Explore the colorful city, make new friends, and take on their unique challenges. Earn rewards as you complete tasks, unlock new areas, and uncover hidden secrets. Dive into a world full of surprises and build lasting friendships along the way. Ready to explore Pink City? Adventure and fun are just around the corner!
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Obby: Tower of Hell - The Ultimate Snake Game Experience",
      name: ["Obby: Tower of Hell", "obby-tower-of-hell"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/obby-tower-of-hell/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/obby-tower-of-hell/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/obby-tower-of-hell.mp4",
      path: ["/game/obby-tower-of-hell", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/290656?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Obby: Tower of Hell, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Obby: Tower of Hell is a parody of the obby mode from Roblox, where you need to escape from prison. Go through difficult challenges, climb through the ventilation and try to get out of the sewer! Great variety of maps! Go through many unique rainbow obstacles. Your cellmate will always be with you! Play and have fun!
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Obby Online With Friends: Draw And Jump - The Ultimate Snake Game Experience",
      name: ["Obby Online With Friends: Draw And Jump", "obby-online-with-friends-draw-and-jump"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/obby-online-with-friends-draw-and-jump/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/obby-online-with-friends-draw-and-jump/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/obby-online-with-friends-draw-and-jump.mp4",
      path: ["/game/obby-online-with-friends-draw-and-jump", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/289722?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Obby Online With Friends: Draw And Jump at PlayCuteGames! Enjoy the best online obstacle courses with friends. Draw paths, jump, and win now!",
      l_dis:
        `Welcome to Obby online with friends: Draw and Jump! Dive into the exciting world of parkour platforming with Roblox’s Obby in this online multiplayer game! Incredible adventures await you, full of breathtaking jumps and logical challenges. In this game, you can not only show off your parkour skills but also solve various puzzles with your friends, creating your own platforms and overcoming traps. Collect Robux and take part in exciting adventures with friends online!
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Obby: Mini-Games - The Ultimate Snake Game Experience",
      name: ["Obby: Mini-Games", "obby-mini-games"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/obby-mini-games/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/obby-mini-games/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/obby-mini-games.mp4",
      path: ["/game/obby-mini-games", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/335573?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Obby: Mini-Games, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `“Obby: Mini-Games” is a dynamic and fun-packed experience where you compete in a variety of mini-games and obstacle courses. Each mini-game presents a unique challenge, from navigating tricky parkour levels to solving puzzles under pressure. Perfect for players of all ages, this game offers endless entertainment and the chance to show off your skills. Can you master all the mini-games and emerge victorious? Dive in and find out!
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Obby: Easy Parkour - The Ultimate Snake Game Experience",
      name: ["Obby: Easy Parkour", "obby-easy-parkour"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/obby-easy-parkour/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/obby-easy-parkour/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/obby-easy-parkour.mp4",
      path: ["/game/obby-easy-parkour", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/240398?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Obby: Easy Parkour, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `“Obby: Easy Parkour” is a fun and beginner-friendly parkour adventure where you jump, run, and climb through a series of exciting obstacle courses. With simple controls and vibrant levels, it’s perfect for both new players and parkour enthusiasts. Challenge yourself to complete each stage and improve your skills as you navigate various obstacles. Ready to master the art of parkour? Start your adventure now in this easy yet thrilling obby!
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Obby Blox: Leela and the Tower of Hell - The Ultimate Snake Game Experience",
      name: ["Obby Blox: Leela and the Tower of Hell", "obby-blox-leela-and-the-tower-of-hell"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/obby-blox-leela-and-the-tower-of-hell/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/obby-blox-leela-and-the-tower-of-hell/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/obby-blox-leela-and-the-tower-of-hell.mp4",
      path: ["/game/obby-blox-leela-and-the-tower-of-hell", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/350687?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Obby Blox: Leela and the Tower of Hell, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Welcome to Obby Blox: Leela and the Tower of Hell. Navigate the obstacle course to climb to the top of the Tower of Hell. Help Leela collect all the coins on the way to the top. Solve puzzles to advance further! The grand prize awaits you at the end! Jump across platforms, trying not to fall. Overcome various obstacles. Sometimes you need to think a bit to solve a puzzle and reach the top of the Tower of Hell.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Noob: Obby On A Bike - The Ultimate Snake Game Experience",
      name: ["Noob: Obby On A Bike", "noob-obby-on-a-bike"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/noob-obby-on-a-bike/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/noob-obby-on-a-bike/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/noob-obby-on-a-bike.mp4",
      path: ["/game/noob-obby-on-a-bike", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/265196?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Noob: Obby On A Bike, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Welcome to Noob: Obby On A Bike. Immerse yourself in the racing world, where you will have to test your parkour skills. In this game you have to jump on colorful objects, overcoming difficulties and obstacles on your way. Your goal is to reach the finish line and become a winner!
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "My Sandbox - The Ultimate Snake Game Experience",
      name: ["My Sandbox", "my-sandbox"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-sandbox/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/my-sandbox/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/my-sandbox.mp4",
      path: ["/game/my-sandbox", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/279366?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play My Sandbox, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Welcome to My Sandbox! Immerse yourself in our exciting role-playing game My Sandbox, where life is literally in your hands. Control your virtual phone: create stylish worlds, elegant cars and start your adventure! Get a cozy home, host friends and enjoy the simple joys of life. Drive a car, explore the surroundings.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Katnap Survival And Morphs - The Ultimate Snake Game Experience",
      name: ["Katnap Survival And Morphs", "katnap-survival-and-morphs"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/katnap-survival-and-morphs/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/katnap-survival-and-morphs/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/katnap-survival-and-morphs.mp4",
      path: ["/game/katnap-survival-and-morphs", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/288891?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Katnap Survival And Morphs, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Welcome to Katnap Survival And Morphs. If you like the Poppy Play Time universe, then be sure to enter the new adventure. Find the most batteries and become a leader, all players will see your nickname in the top. Earn coins and buy new smile skins. Find animals and try on the Catnap, Nightmare Huggy, Miss Delight or Dog Day morphs.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Escape the Lava: Obby - The Ultimate Snake Game Experience",
      name: ["Escape the Lava: Obby", "escape-the-lava-obby"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/escape-the-lava-obby/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/escape-the-lava-obby/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/escape-the-lava-obby.mp4",
      path: ["/game/escape-the-lava-obby", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/264786?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Escape the Lava: Obby, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `“Escape the Lava: Obby” is a thrilling arcade game where brave players embark on an ordeal filled with exciting challenges and incredible obstacles. In this exciting world where every step can be decisive, participants fight for survival by climbing towering platforms, avoiding the stream of red-hot lava that rises mercilessly from below. Players will have to show their agility, stamina and strategic thinking to overcome challenging obstacles.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Easy Robby Parkour Online - The Ultimate Snake Game Experience",
      name: ["Easy Robby Parkour Online", "easy-robby-parkour-online"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/easy-robby-parkour-online/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/easy-robby-parkour-online/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/easy-robby-parkour-online.mp4",
      path: ["/game/easy-robby-parkour-online", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/313692?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Easy Robby Parkour Online, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Welcome to Easy Robby Parkour Online. The game is similar to Roblox. You have to go through a long streak of challenges, but the game has Checkpoints (saves), so you don’t have to start all over again if you fall. Play with your friends! Communicate with others through emotes! This game has special features like Colorful skins and different character models. If the game seems difficult, activate a double jump or any other boost to complete the difficult stage.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Blox Obby: Tower of Hell - The Ultimate Snake Game Experience",
      name: ["Blox Obby: Tower of Hell", "blox-obby-tower-of-hell"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/blox-obby-tower-of-hell/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/blox-obby-tower-of-hell/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/blox-obby-tower-of-hell.mp4",
      path: ["/game/blox-obby-tower-of-hell", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/232356?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Blox Obby: Tower of Hell is live at PlayCuteGames! Play this free online game and tackle obstacle courses to earn the golden crown and unlock unique skins",
      l_dis:
        `Blox Obby: Tower of Hell is a parody of the Obby mode where you have to navigate through obstacle courses. Reach the end and be the first to obtain the golden crown! Huge maps! Conquer all the challenges and overcome obstacles. Collect coins to unlock all the skins. Create a unique look! Complete the obstacle course faster than anyone else and make it onto the leaderboard.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Aesthetic Obby - The Ultimate Snake Game Experience",
      name: ["Aesthetic Obby", "aesthetic-obby"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/aesthetic-obby/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/aesthetic-obby/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/aesthetic-obby.mp4",
      path: ["/game/aesthetic-obby", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/251031?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Aesthetic Obby, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Welcome to Aesthetic Obby is a game about a small beautiful adventure, similar to the popular parkour games from Roblox. Complete the game the fastest or just enjoy the pleasant environment! In the game you will find: Interesting and challenging tasks, Beautiful, well-designed graphics, and Rest area at the end of your journey.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
     
      orientation: 1,
      title: "Hair Coloring - playcutegames.com",
      name: ["Hair Coloring", "hair-coloring"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/hair-coloring/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/hair-coloring/250x142.png",
      path: ["/game/hair-coloring", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/263610?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Hair Coloring Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Change the image, style, color and length of your hair. Make everyone beautiful!
        Open the most fashionable beauty salon in the city and make cool repairs in it.<br>
        
        - Make awesome haircuts and hairstyles<br>
        - We'll have to wash the clients' hair. Gently apply shampoo and conditioner.<br>
        - Don't forget to blow dry your hair, straighten and curl it.
        `,
        tag: ["Girl games","kids",],
      hint: `A client will come into your salon and ask for a dream haircut.
      Fulfill her wishes and make the girl happy!<br>
      And for this, carefully choose the shape of the haircut, hair color, jewelry and create a stylish image.<br>
      Accumulate money and do not forget to improve your salon and make cool repairs in it!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 1,
      title: "Color Snake - Slithering Train Game in a 3D World",
      name: ["Color Snake", "color-snake"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/color-snake/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/color-snake/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/color-snake.mp4",
      path: ["/game/color-snake", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/365228?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Guide your snake through a colorful forest, eating fruits and avoiding creatures in Color Snake. Fun music and playful challenges for kids!",
      l_dis:
        `If you like retro games, reflex-based games and fast-paced action, Color Snake will take you down memory lane and allow you to experience what prime mobile gaming used to look like about 27 years ago! The arcade-style sound effects and pixelated graphics are quite effective, and the gameplay is gorgeously simple yet unexpectedly challenging.
        As the snake eats the cubes across the box, it gets bigger, and the game's objective is to grow as big as possible. You can slither through the map’s dashed boundaries, but if you crash into the snake’s body or crash into the border, the game is over, and you’re forced to retry. How long do you think you can survive?<br>
`,
        tag: ["Snake"],
      hint: `The game is quite easy in the beginning, but as the snake feeds, its very length gradually becomes an ever-growing issue. Practice passing through the walls to devour cubes more efficiently, stopping on a dime, and making quick U-turns. These skills are however very important especially at the later stages of the game when the room for movement begins to shrink.
Once the snake’s body occupies a large portion of the playing field, it’s important to always have a preplanned escape route ready. Do not surround the head of the snake because you may often find yourself in situations that you cannot get out of.<br>
 <h2 class="H2">What are the controls for Color Snake?</h2>
 <ul> 
 <li>You can play Color Snake on PC and mobile devices alike.</li>
 <li>Use <b>WASD</b> or arrow keys to move if playing on a PC</li>
 <li><b>swipe</b> to turn in the desired direction if you prefer playing on mobile.</li>
 </ul>
 <h2 class="H2">Features:</h2>
 <ul> 
 <li>An immersive 2D retro color snake game</li>
 <li>Arcade-styled sound effects and pixelated graphics</li>
 <li>Gorgeously simple yet highly challenging gameplay</li>
 </ul>
`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 0,
      title: "Cute Animals - Slithering Train Game in a 3D World",
      name: ["Cute Animals", "cute-animals"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cute-animals/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/cute-animals/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/cute-animals.mp4",
      path: ["/game/cute-animals", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/205223?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Guide your snake through a colorful forest, eating fruits and avoiding creatures in Cute Animals. Fun music and playful challenges for kids!",
      l_dis:
        `Meet Cute Animals – a mesmerizing snake IO game that brings you to the world of cute animals and real competition. In this colourful environment, you are a cute snake that has to swallow different objects on the map to become bigger than the opponent. It doesn’t matter if you’re a pro or if this is your first time playing a game in this genre – this game will be very interesting!
`,
        tag: ["Snake"],
      hint: `To start your journey, all you need to do is to move your snake through the green field using your mouse pointer or your finger if you are using a touch screen device. The more food you take, the longer and stronger your snake becomes which increases the chances of you ruling the leaderboard. Nevertheless, pay attention to other participants—running into bigger snakes will result in your loss! This game requires good response time and strategy to beat the enemies.
       <h2 class="H2">Features:</h2>
       <ul>
       <li><b>Charming Customization:</b> Personalize your snake with adorable animal heads like bunnies, kittens, and more for a unique gameplay experience.</li>
       <li><b>Multiplayer Mayhem:</b> Compete against players worldwide in real-time battles, climbing the leaderboard to become the biggest snake!</li>
       <li><b>Engaging Environments:</b> Explore a variety of colorful landscapes filled with delicious food items and playful animations that enhance your gaming experience.</li>
       <li><b>Exciting Gameplay:</b> Enjoy the fast-paced action with quick reflexes and strategic movement, including speed boosts for those critical moments.</li>
       </ul>
       <h2 class="H2">Why Play Cute Animals?:</h2>
       This game is perfect for animal lovers and fans of the snake IO genre alike! With its blend of competitive gameplay, cute visuals, and easy-to-learn mechanics, Cute Animals promises hours of fun and excitement. Gather your friends and challenge each other in this delightful game, where every moment is packed with action and charm.<br><br>
       Cute Animals is waiting for you right now, so get ready to slither to the top while adorable animals are watching you! If you are interested in spending some time with your friends, playing games with no stress or if you are interested in an intense competition this game is designed for you. Sign up to the fun now and find out how long your snake will become!`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 1,
      title: "Merge Snake War - Slithering Train Game in a 3D World",
      name: ["Merge Snake War", "merge-snake-war"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/merge-snake-war/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/merge-snake-war/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/merge-snake-war.mp4",
      path: ["/game/merge-snake-war", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/266004?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Guide your snake through a colorful forest, eating fruits and avoiding creatures in Merge Snake War. Fun music and playful challenges for kids!",
      l_dis:
        `Welcome to the new game called Merge Snake War – where tactics and shooting are combined in a great game! In this fascinating game, you lead a team of snakes and combine them to create strong partners while moving through bright territories with both obstacles and opportunities. Do you think that you are up to the task of fusing and merging and take your snakes to the top?
          <br><br>
          In Merge Snake War, the objective is simple yet engaging: combine two snakes of the same kind to get a more powerful kind. However, it is not only about merging—sophisticated planning is important! In the next stages, you will be competing with players who are equally as willing to take over. Will you be able to outcompete them and be the winner?`,
        tag: ["Snake"],
      hint: `Lead your snakes in this warzone where you have to gather resources and combine them to create your dream team. Each time you merge you get specific abilities that can help you win the battle in your favor. Strategy and wit will be your two greatest friends when it comes to choosing which snakes should be combined and when they should attack competitors.
      <h2 class="H2">Why Choose Merge Snake War?</h2>
      <ul>
      <li><b>Strategic Merging:</b> Get the excitement of putting snakes together to make incredible beasts, which possess their own abilities. What new ability will you gain after the merges?</li>
      <li><b>Dynamic Battles:</b> Experience instant fast paced real-time combat where every action matters. On this game, can you be smart enough to beat all your competitors?</li>
      <li><b>Variety of Snakes:</b> Meet a great number of snakes of different characteristics. Which of these skills will complement the other and become your winning strategy against stronger enemies?</li>
      <li><b>Exciting Upgrades:</b> Receive in-game currency and advance your snakes, which will cause them to gain new abilities that will help you during the game. How good can your team get?</li>
      <li><b>Captivating Graphics:</b> There are nice graphics that make each combat exciting, and you can plunge into the colorful world of Merge Snake War.</li>
      </ul>
      <br>
      Are you prepared for this merging kind of journey? Merge Snake War is suitable for anybody who enjoys strategy with a touch of action all in one. Sign up today and show us your merging skills in the battle! Is it possible to gain the position of the supreme commander of snakes and become the real boss of a snake organization? The stage is set for your management—integrate, plan, and triumph!
      `,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 0,
      title: "Forest Slither Snake - Slithering Train Game in a 3D World",
      name: ["Forest Slither Snake", "forest-slither-snake"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/forest-slither-snake/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/forest-slither-snake/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/forest-slither-snake.mp4",
      path: ["/game/forest-slither-snake", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/177171?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Guide your snake through a colorful forest, eating fruits and avoiding creatures in Forest Slither Snake. Fun music and playful challenges for kids!",
      l_dis:
        `Forest Slither Snake is a new version of the regular snake game that takes place in a forest and your snake is out on the mission of making it bigger. While navigating through the forest, you are to collect fruits and food to make your snake bigger and score better. Just remember though, hitting larger snakes or other objects will cause your run to be over so skill and timing are important.
`,
        tag: ["Snake"],
      hint: `First, choose an animal for your snake, then, using the mouse or finger, guide your snake through the forest. To grow bigger, eat the scattered fruits and food items and to avoid other snakes. It is also possible to run at a high speed with a simple double click or swipe, which can be useful for avoiding threats or reaching prey. The longer the snake becomes, the more points you receive, but if you run into a longer snake, the game is over.

<h2 class="H2">Game Modes and Graphics:</h2>
Among the two positive aspects of Forest Slither Snake, the first one is the presence of game modes. You can play in single player and in this a person has to complete the levels in a fixed time as well as with a particular score targets. Or you can go into the multiplayer mode where you are a player against other players from all over the world in a real and very fierce battle for survival and supremacy. The general appearance of the game is colorful and cartoonish and this makes it more attractive to children and anyone else who loves games with bright graphics.
<h2 class="H2">Customization Options:</h2>
You can even give your snake a more of an individual look by selecting from a variety of cute animal heads such as a cat, dog, fox, rabbit, or even a lion. These customization options make the games unique in a way as it lets you play as your favorite animal as you slither through the forest.
<h2 class="H2">Other Features:</h2>
One standout feature of Forest Slither Snake is the ability to increase your snake’s speed, helping you outmaneuver opponents or quickly collect food. The dynamic gameplay, combined with the bright, engaging graphics, ensures players remain entertained, whether they’re completing challenges in single-player or dominating in multiplayer.
With its exciting game modes, customizable snakes, and captivating forest setting, Forest Slither Snake offers a fresh and fun twist on the traditional snake game, making it perfect for players of all ages.


`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 0,
      title: "SlitherCraft.io - Slithering Train Game in a 3D World",
      name: ["SlitherCraft.io", "slithercraft-io"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/slithercraft-io/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/slithercraft-io/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/slithercraft-io.mp4",
      path: ["/game/slithercraft-io", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/248811?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Experience the thrilling world of SlitherCraft.io, a slithering train game set in a 3D environment. Eat other trains, collect screws and toolboxes, and grow longer to become the grand train!",
      l_dis:
        `Welcome to SlitherCraft.io! This is where the excitement of Slither.io collides with the creative world of Minecraft, offering a one-of-a-kind gaming experience that will keep you hooked for hours. Picture this: a world full of colorful Minecraft blocks just waiting to be consumed, all while you maneuver your snake through intense competition with other players. Are you ready to dive in?
        <h2 class="H2">What Makes SlitherCraft.io Special?</h2> 
        In SlitherCraft.io, you control a lively snake that grows by munching on various Minecraft blocks scattered across a vibrant map. Your journey starts by gobbling up everything in sight, but beware—other snakes are on the hunt too! The bigger you grow, the more you’ll have to watch your back.
        `,
        tag: ["Snake"],
      hint: `<ul>
      <li><b>Collect and Grow:</b> Start your adventure by eating different Minecraft blocks. Each block adds length to your snake, making it stronger and more formidable in battles. Watch out for those diamond blocks—they're your key to scoring big!</li>
      <li><b>Defeat Opponents:</b> Use strategy to outsmart other players. Throw TNT at rival snakes to eliminate them and claim their blocks as your own. Remember, it’s not just about growing; it’s about surviving!</li>
      <li><b>Stay Safe:</b> Protect yourself by curling your tail around your body. This clever move can keep you safe from enemy attacks while you plot your next move.</li>
      </ul>
       <h2 class="H2">Customize Your Snake</h2> 
      What’s better than just playing? Personalizing your snake! As you advance in the game, you can unlock various skins to make your snake truly unique. Whether you prefer a fiery design or a cool, calm look, the choice is yours.
      <h2 class="H2">Why You’ll Love SlitherCraft.io</h2> 
      <ul>
      <li><b>Endless Fun:</b> The dynamic gameplay means there’s always something new to experience, whether you're playing solo or challenging friends.</li>
      <li><b>Engaging Community:</b>  Join a vibrant community of players from around the world and show off your skills on the leaderboard.</li>
      <li><b>Charming Aesthetics:</b>  Enjoy the playful blend of Minecraft graphics and snake gameplay, making each session visually appealing and fun</li>
      </ul>

      `,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 0,
      title: "Trains.io 3D - Slithering Train Game in a 3D World",
      name: ["Trains.io 3D", "trains-io-3d"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/trains-io-3d/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/trains-io-3d/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/trains-io-3d.mp4",
      path: ["/game/trains-io-3d", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/170221?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Experience the thrilling world of Trains.io 3D, a slithering train game set in a 3D environment. Eat other trains, collect screws and toolboxes, and grow longer to become the grand train!",
      l_dis:
        `Do you like snake games? Do you like train games? Trains.io 3D brings these two worlds together in a fast-paced, action-packed game where you grow your train and dominate the tracks. This game is perfect for fans of multiplayer snake games and anyone who loves the thrill of train simulators with a competitive twist. Every second counts as you navigate tight spaces, collect resources, and avoid crashing into other players' trains.
        <h2 class="H2">Why Trains.io 3D Stands Out</h2> 
        <h3 class="H22">1. Snake Game Mechanics with a Train Theme</h3><br>
        <p>Just like classic snake games, you grow as you eat, but here, you control a train. As you collect resources scattered across the map, your train becomes longer and more challenging to maneuver. Your mission is simple: keep growing while avoiding collisions. One wrong move, and it’s game over!</p>
        <br><h3 class="H22">2. Competitive Multiplayer Mode</h3><br>
        <p>Play with players from around the globe in real-time multiplayer mode. Outsmart your opponents by using clever tactics to trap them with your ever-growing train. The larger your train, the more control you have over the tracks, but remember, other players are aiming to do the same! Show off your skills and become the ultimate champion.</p>
        <br><h3 class="H22">3. Stunning 3D Graphics and Smooth Gameplay</h3><br>
        <p>Trains.io 3D offers impressive 3D visuals that make the gameplay feel immersive. The colorful, dynamic environment is not only visually appealing but also adds to the intensity of the game. With intuitive controls and smooth animation, every move feels responsive and exciting, perfect for players who enjoy a seamless experience.</p>
        <h2 class="H2">Top Tips for Winning in Trains.io 3D</h2> 
        <ul>
        <li><b>Strategize Your Moves:</b> As your train grows longer, space becomes tight. Plan your moves ahead to avoid getting stuck.</li>
        <li><b>Use Your Train's Length:</b> The bigger your train, the more you can block opponents. Use your length to control the game.</li>
        <li><b>Stay on Your Toes:</b> This game is fast-paced, and opponents can strike at any moment. Stay alert and adapt quickly to the changing environment.</li>
        </ul>
        <h2 class="H2">Why Trains.io 3D is a Must-Play</h2> 
If you love snake games, train games, or both, this game brings an entirely new experience to the table. With its unique combination of growth mechanics, strategy, and multiplayer action, Trains.io 3D offers endless fun for players looking for a challenging yet entertaining game.<br>
Are you ready to compete and grow the longest train on the tracks? Join Trains.io 3D now and start your journey to victory!
        `,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 0,
      title: "Worm.io - Slithering io Snake Game with a Paper Theme",
      name: ["Worm.io", "worm-io"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/worm-io/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/worm-io/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/worm-io.mp4",
      path: ["/game/worm-io", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/167116?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Worm.io, an exciting slithering io snake game with a paper theme. Grow your snake by eating others and become the biggest on the map. Join the battle now!",
      l_dis:
        `Welcome to the world of Worm.io – where the population is constantly growing and one can become a prey literally in several minutes. As a small worm you have to eat as much as you can and become the big worm in the arena. But do not let the simplicity impress you because the show is filled with dangerous moments at every turn. Are you able to slide your way to the win or will you be devoured by a bigger opponent?
        <h2 class="H2">Game Modes</h2>
        <ul>
        <li><b>Endless Mode:</b>Play until you can no longer survive. There’s no time limit—just focus on growing and surviving as long as possible.</li>
        <li><b>Timed Mode:</b>This fast-paced mode puts you against the clock. Race through a 7-minute game, where every second counts as you gather food and score points.</li>
        <li><b>Treasure Hunter Mode:</b>Explore the arena for hidden treasures while competing against other worms for the ultimate rewards.</li>
        </ul>
        <h2 class="H2">Exciting Power-Ups</h2>
        In Worm.io, it's not just about getting big—using the right strategy is key to dominating the arena. Look for power-ups that will give you an advantage:
        <br>
        <br>
        <ul>
        <li><b>Speed Boost:</b>Get an edge in tight situations by speeding past enemies or escaping tricky traps.</li>
        <li><b>Zoom Out:</b>Gain a broader view of the arena, spotting food and avoiding dangerous worms from afar.</li>
        <li><b>Food Magnet:</b>Collect food from a greater distance, making it easier to grow without putting yourself at risk.</li>
        </ul>
        <br>
        These power-ups bring an exciting layer of strategy to the game, ensuring every match is different.
        <h2 class="H2">Key Features</h2>
        <ul>
        <li><b>Addictive Gameplay:</b>Easy to learn, hard to master, Woerm.io offers simple controls but intense competition.</li>
        <li><b>Multiplayer Snake Game:</b>Play against real players from around the world in this massive online battle arena.</li>
        <li><b>Dynamic Power-Ups:</b>Find and use power-ups to outsmart your opponents and gain an advantage.</li>
        <li><b>Real-Time Leaderboard:</b>Compete to rank on the global leaderboard and prove you’re the ultimate worm!</li>
        </ul>
        <br>
        Ready to take on the competition? Join the battle in Worm.io, where only the smartest and quickest worms survive. Grow, strategize, and become the biggest worm in this fast-paced multiplayer snake game. Challenge yourself and climb the ranks today!
        `,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 1,
      title: "Bullet Snake - A Unique and Explosive Snake Game Experience",
      name: ["Bullet Snake", "bullet-snake"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bullet-snake/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bullet-snake/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/bullet-snake.mp4",
      path: ["/game/bullet-snake", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/282379?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Bullet Snake, a unique and exciting twist on snake games where you control a snake made of bullets. Destroy obstacles, enemies, and merge bullets for powerful combos!",
      l_dis:
        `The game offers a unique gameplay in which the player has to control a snake consisting of bullets. The main goal of the game is to destroy obstacles and enemies on the way using the power of your bullets. The trick of the game is the possibility of mixing bullets. The player can connect their bullets with each other, creating stronger and more effective bullets. The unique margin mechanics allow the player to create a variety of combos and strategies to win.
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 1,
      title: "Snake Evolution - A Unique Twist on Snake Games",
      name: ["Snake Evolution", "snake-evolution"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/snake-evolution/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/snake-evolution/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/snake-evolution.mp4",
      path: ["/game/snake-evolution", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/274872?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Snake Evolution, an exciting twist on classic snake games. Control snakes and animals, eat others to grow stronger, and become the ultimate predator!",
      l_dis:
        `Snake Evolution, is an exciting game that combines the mechanics of the well-known snake with cute and diverse animals and snakes, offering a unique and fun experience. Simple and Fun Mechanics: Use your skills to eat other animals and become stronger. Becoming the strongest predator is your goal!.
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 1,
      title: "Snake Run 2048 - Overcome Obstacles and Grow Your Snake!",
      name: ["Snake Run 2048", "snake-run-2048"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/snake-run-2048/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/snake-run-2048/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/snake-run-2048.mp4",
      path: ["/game/snake-run-2048", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/210954?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Snake Run 2048, an engaging game where you control a constantly growing snake, collect 2048 cubes to increase your size and earn rewards. Suitable for all ages!",
      l_dis:
        `You need to overcome obstacles and collect 2048 cubes in order to increase your size and get more rewards at the end of the level. You control a snake that is constantly growing in size, but be careful, you may accidentally lose all the accumulated cubes.  You are waiting for a huge number of different obstacles. Each obstacle is unique in its own way. This game is suitable for people of any age and gender.
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Cute Snake io - The Ultimate Snake Game Experience",
      name: ["Cute Snake io", "cute-snake-io"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cute-snake-io/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/cute-snake-io/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/cute-snake-io.mp4",
      path: ["/game/cute-snake-io", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/153846?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Cute Snake io, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `In Cute Snake IO, you take control of an adorable snake with a unique twist—the head of a cat that loves snacking on sweets. Your goal is to help it grow larger by eating food scattered across the map, while avoiding collisions with other players and obstacles. The game’s charming graphics, featuring cute animals and adorable animations make it stand out from typical snake games, creating a fun and competitive multiplayer experience. Despite the presence of adorable faces in snakes, don’t let it fool you, competition in this multiplayer game is fierce, and you’ll need to stay sharp to survive and grow. The multiplayer format adds to the thrill, as you'll be battling players from around the world in real time. Each match is unpredictable, making every move count as you strive to become the biggest snake in the arena.
`,
        tag: ["Snake"],
      hint: `Navigate your snake using your mouse or finger, guiding it across the grass to collect fruits. Grow bigger, but be careful—colliding with other snakes or obstacles can end your run.
        <h2 class="H2">How to Increase Your Snake’s Size:</h2>
        The key to success in Cute Snake IO is simple: eat as much food as possible to grow. Start small by collecting fruits scattered throughout the map, and as your snake grows, you can begin to outmaneuver and bump into other snakes to eliminate them. The larger you become, the more powerful and dominant you’ll be in battles, but remember, there’s always a bigger snake lurking around! Keep moving and stay cautious, as one wrong move could send you back to the start.
        <h2 class="H2">Customization Options:</h2>
        Make your snake even cuter by choosing from a variety of animal heads, including a cat, dog, fox, bunny, tiger, or even a panda. Each customization adds a unique personality to your snake, making the gameplay experience even more fun and personal.
With its engaging multiplayer mode and endless customization, Cute Snake IO offers an exciting and competitive snake game experience with a cute twist.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Snake Zone - The Ultimate Snake Game Experience",
      name: ["Snake Zone", "snake-zone"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/snake-zone/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/snake-zone/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/snake-zone.mp4",
      path: ["/game/snake-zone", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/265700?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Snake Zone, an addictive snake game where you control a snake, eat food to grow, and customize your snake with unique skins.",
      l_dis:
        `Snake Zone is an addictive game where the player controls a snake while trying to eat as much food as possible and become the biggest snake on the field. Players can choose different skins for their snake to personalize their gaming experience. Each skin has a unique design and style, allowing players to express their personality.
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Apple Snake: Reach the Target Gate",
      name: ["Apple Snake", "apple-snake"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/apple-snake/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/apple-snake/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/apple-snake.mp4",
      path: ["/game/apple-snake", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/226916?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Guide the worm to the safe target gate by using arrow keys and feeding it apples to make it longer and reach higher and farther places.",
      l_dis:
        `<p class="P_tag">Your mission in <strong>Worm Adventure</strong> is simple yet exciting: bring the worm to the safe target gate by navigating through various obstacles and collecting apples along the way. The more apples you feed your worm, the longer its body grows, allowing it to reach higher and farther places as you progress through the game.</p>

    <h2 class="H22">Control the Worm</h2>
    <p class="P_tag">Use the virtual arrow keys to guide the worm through different levels filled with challenges. Whether it’s dodging obstacles or climbing to new heights, your precise movements will determine the success of your journey. The longer the worm, the more difficult the task becomes, so stay sharp and plan your moves carefully!</p>

    <h2 class="H22">Feed the Worm</h2>
    <p class="P_tag">Throughout each level, you’ll come across delicious apples. Feeding your worm these apples will increase its length, allowing it to reach higher platforms and navigate more complex environments. Be strategic about when and where you choose to feed the worm to ensure you can reach the target gate safely.</p>

    <h2 class="H22">Reach New Heights</h2>
    <p class="P_tag">With a longer body, the worm can access previously unreachable areas, which adds new dimensions to each level. By mastering the movement and growth of the worm, you'll be able to explore hidden pathways and find shortcuts to the target gate.</p>

    <h2 class="H22">Features of Worm Adventure</h2>
    <ul>
        <li><strong>Exciting Puzzle Adventure:</strong> Navigate your worm through various levels, growing longer and more powerful as you collect apples.</li>
        <li><strong>Simple Controls:</strong> Use virtual arrow keys to move your worm through challenging environments.</li>
        <li><strong>Length Matters:</strong> The more apples you collect, the longer your worm becomes, helping you access higher and farther areas.</li>
        <li><strong>Strategic Gameplay:</strong> Plan your moves to avoid obstacles and guide your worm safely to the target gate.</li>
        <li><strong>Multiple Levels:</strong> Each level presents new challenges and exciting ways to test your worm-control skills.</li>
    </ul>

    <p class="P_tag">Ready to guide the worm to victory? Play <strong>Worm Adventure</strong> now, collect apples, and reach the target gate in this fun and challenging puzzle game!</p>
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Mine: Snake - A Thrilling Block Snake Adventure",
      name: ["Mine: Snake", "mine-snake"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mine-snake/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mine-snake.mp4",
      path: ["/game/mine-snake", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/308217?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Mine: Snake - the latest snake game where the goal is to become the largest creature by carefully eating food while avoiding collisions with yourself and other snakes.",
      l_dis:
        `
    <p class="P_tag">Welcome to <strong>Mine: Snake</strong>, an exciting arcade game that brings a fresh twist to the classic snake game genre. Get ready to plunge into a world full of obstacles, traps, and adventure as you control a unique block snake through various challenging levels. Your mission is to collect coins, avoid collisions, and unlock new levels while trying to survive as long as possible!</p>

    <h2 class="H22">Classic Mode: Go the Distance</h2>
    <p class="P_tag">Mine: Snake features a thrilling <strong>Classic Mode</strong>, where your primary goal is to travel as far as possible without hitting obstacles. As you move forward, your snake increases in length, adding more challenge and excitement to the gameplay. The farther you go, the more points you score, and the higher you can climb on the leaderboard. But watch out—each new level brings trickier paths and more obstacles to overcome!</p>

    <h2 class="H22">Break Blocks and Collect Coins</h2>
    <p class="P_tag">One of the unique elements in Mine: Snake is the ability to destroy blocks along your path. As you slither through the levels, you'll encounter blocks that can be broken for extra experience points. The more blocks you break, the more experience you gain, and the higher your score. Don't forget to collect coins along the way, as they are key to unlocking new levels and upgrading your snake for even more fun.</p>

    <h2 class="H22">Unlock New Levels</h2>
    <p class="P_tag">As you progress through Mine: Snake, new levels will be unlocked, each filled with its own set of challenges, obstacles, and rewards. With every level you complete, the difficulty increases, requiring quick reflexes and strategic planning to keep your snake safe from traps and obstacles. Can you unlock all the levels and become the ultimate Mine: Snake master?</p>

    <h2 class="H22">Compete on the Leaderboard</h2>
    <p class="P_tag">Do you have what it takes to reach the top? In Mine: Snake, you can compete with players from around the world for the highest score. With each block destroyed and coin collected, you’ll earn valuable points that can push you to the top of the leaderboard. The more you play, the better your chances of claiming the number one spot!</p>

    <h2 class="H22">Features of Mine: Snake</h2>
    <ul>
        <li><strong>Classic Arcade Adventure:</strong> Control your block snake through a series of increasingly challenging levels.</li>
        <li><strong>Break Blocks for Experience:</strong> Destroy blocks along the way to gain experience points and increase your score.</li>
        <li><strong>Collect Coins:</strong> Gather coins to unlock new levels and upgrades.</li>
        <li><strong>Leaderboards:</strong> Compete with friends and other players worldwide to become the top scorer.</li>
        <li><strong>Exciting Challenges:</strong> Each level brings new obstacles and challenges to overcome.</li>
    </ul>

    <p class="P_tag">Are you ready to dive into the thrilling adventure of <strong>Mine: Snake</strong>? Control your snake, break blocks, collect coins, and race to the top of the leaderboard. Play now and see how far you can go!</p>
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Snake Blast: The Ultimate Snake Game Experience",
      name: ["Snake Blast", "snake-blast"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/snake-blast/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/snake-blast.mp4",
      path: ["/game/snake-blast", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/103596?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Snake Blast - the latest snake game where the goal is to become the largest creature by carefully eating food while avoiding collisions with yourself and other snakes.",
      l_dis:
        ` 
    <p>Welcome to <strong>Snake Blast</strong>, the latest and most thrilling addition to the classic snake game genre! If you're a fan of simple yet highly addictive games, then Snake Blast is perfect for you. With its intuitive controls and exciting gameplay, you’ll find yourself immersed in a world where your primary objective is to grow bigger, become stronger, and avoid obstacles at every turn.</p>

    <h2 class="H2">Objective: Become the Largest Creature</h2>
    <p>In Snake Blast, your main goal is to become the largest creature in the game. Starting as a tiny snake, you’ll traverse the game arena, gobbling up food scattered throughout the environment. Each piece of food you consume will make your snake grow longer and stronger. But beware—growing bigger comes with its own set of challenges! The larger you get, the more difficult it becomes to navigate through tight spaces.</p>

    <h2 class="H2">Eat Your Food Carefully</h2>
    <p>As simple as the game may sound, Snake Blast requires careful strategy and quick reflexes. You’ll need to think on your feet to navigate the arena while avoiding traps and collisions. Every move counts. Eating food increases your size, but positioning yourself in the right place to consume food without bumping into obstacles can be tricky. This adds a layer of excitement and challenge as you advance through the game.</p>

    <h2 class="H2">Avoid Self-Collisions and Other Snakes</h2>
    <p>The key to survival in Snake Blast is avoiding collisions. If you crash into yourself or other snakes, your game ends. As your snake grows longer, maneuvering without getting tangled up becomes increasingly difficult. Similarly, the arena is filled with other snakes competing for space and food. Keep your distance from them, and make sure you don’t accidentally touch another snake, or it’s game over!</p>

    <h2 class="H2">Tips for Success</h2>
    <ul>
        <li><strong>Plan Your Moves:</strong> As your snake grows, think ahead before making sharp turns or attempting to grab food.</li>
        <li><strong>Use Space Wisely:</strong> Try to keep to the open areas when possible to avoid running into yourself or other snakes.</li>
        <li><strong>Stay Patient:</strong> Don’t rush for every piece of food—sometimes, waiting for the right moment to grab it is the smarter move.</li>
        <li><strong>Outmaneuver Opponents:</strong> If other snakes are in your way, use your agility to outsmart and trap them while staying safe.</li>
    </ul>

    <h2 class="H2">A Modern Twist on a Classic Genre</h2>
    <p>Snake Blast brings a fresh and modern twist to the classic snake game we all know and love. With updated graphics, more complex challenges, and a competitive edge, this game is sure to keep players hooked for hours. Whether you're a seasoned snake game veteran or a newcomer looking for a fun and engaging experience, Snake Blast offers something for everyone.</p>

    <p>Are you ready to take on the challenge? Navigate, grow, and prove that you have what it takes to become the largest creature in the arena. Play Snake Blast now and test your skills!</p>

        `,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 1,
      title: "Dinosaurs Merge Master - playcutegames.com",
      name: ["Dinosaurs Merge Master", "dinosaurs-merge-master"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaurs-merge-master/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/dinosaurs-merge-master.mp4",
      path: ["/game/dinosaurs-merge-master", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/195590?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Dinosaurs Merge Master blends chess-like tactics with auto-battles. Command dinosaurs and warriors, merge units to unlock powerful monsters, and defeat foes!",
      l_dis:
        "Dinosaurs Merge Master is a thrilling strategy experience that mixes chess-like tactics and fast-paced auto-battling combat. In this game, you will command troops of pre-historic dinosaurs and warriors, battling waves of menacing adversaries, including dragons, monsters, T-rex, and other kinds of dangerous dinosaurs. Your finesse and quick thinking skill will be the deciding factor that leads to victory. Unlock new powerful monsters by merging existing units. Use smart strategy to mow through enemies and reach higher challenges.",
        tag: ["Dino"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
     {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 1,
      title: "Merge Master: Dinosaurs War 🕹️ Play on Playcutegames",
      name: ["Merge Master: Dinosaurs War", "merge-master-dinosaurs-war"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/merge-master-dinosaurs-war/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/merge-master-dinosaurs-war/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/merge-master-dinosaurs-war.mp4",
      path: ["/game/merge-master-dinosaurs-war", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/264296?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
        "Merge Master: Dinosaurs War is a real-time strategy game where you merge dragons, monsters, and archers to battle dinosaurs. Enjoy epic dino clashes and win the fight!",
      l_dis:
        "Merge Master: Dinosaurs War is a cool real-time strategy, dinosaur epic battle simulator like monster legends and merge dragons, for everyone that want to merge dragons and monsters, join and clash dinosaurs, merge archers and enjoy the dino bash and win the fight.",
        tag: ["Dino"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],
    },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 1,
    title: "Dinosaur Attack 🕹️ Play on Playcutegames",
    name: ["Dinosaur Attack", "dinosaur-attack"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-attack/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-attack/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dinosaur-attack.mp4",
    path: ["/game/dinosaur-attack", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/230518?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Dive into the world of dinosaurs! Collect eggs to transform into powerful warriors and battle rivals. Choose your fighter wisely and dominate the arena with epic battles!",
    l_dis:
      "Welcome to the exciting world of dinosaurs! In this thrilling arcade game, you will find yourself in the times of dinosaurs, where you have to collect as many dinosaur eggs as possible! After you gather a sufficient amount of eggs, your dinosaurs will transform into mighty warriors, and you will battle against other dinosaurs. Choose your fighter wisely, as each of them possesses unique abilities and characteristics. Become the bravest dinosaur, collect all types of eggs, and prove your dominance in epic battles in the arena!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 1,
    title: "Merge: Dinosaur History 🕹️ Play on Playcutegames",
    name: ["Merge: Dinosaur History", "merge-dinosaur-history"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/merge-dinosaur-history/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/merge-dinosaur-history/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/merge-dinosaur-history.mp4",
    path: ["/game/merge-dinosaur-history", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/366201?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Join Merge: Dinosaur History at PlayCuteGames! Collect and merge fossils to unlock all dinosaur species, including the mighty T-Rex. Play for free online!",
    l_dis:
      "Immerse yourself in the captivating world of ancient dinosaurs! In our app, fossils drop into the game area and merge when they match! Collect all dinosaur species and unlock the legendary T-Rex. Compete with other players on the leaderboard! Each new fossil earns you more points, and combos give bonus points. Score the most points and claim the top spot on the leaderboard!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },{
    date: "2024-04-25",
    brand: "Yandex",
    orientation: 0,
    title: "Jurassic Park: Dino Island Idle Tycoon 🕹️ Play on Playcutegames",
    name: ["Jurassic Park: Dino Island Idle Tycoon 3D", "jurassic-park-dino-island-idle-tycoon-3d"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/jurassic-park-dino-island-idle-tycoon-3d/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/jurassic-park-dino-island-idle-tycoon-3d.mp4",
    path: ["/game/jurassic-park-dino-island-idle-tycoon-3d", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/232987?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Manage your dino-park, grow dinosaurs from eggs to giants, and expand to attract visitors. Improve your park, create hybrids, and surprise the world!",
    l_dis:
      "You are the new manager of the dino-park, congratulations! Your task will be to grow dinosaurs from a small egg to huge reptiles! Of course you will have assistants, but no one will work for you for free, so you need to expand the park and attract more visitors who will give everything just to see these big dinosaurs up close! You will also be able to improve your park to earn more money and grow new hybrids that will help you to surprise the world!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 0,
    title: "Little Archaeologists: Search for Dinosaurs 🕹️ Play on Playcutegames",
    name: ["Little Archaeologists: Search for Dinosaurs", "little-archaeologists-search-for-dinosaurs"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/little-archaeologists-search-for-dinosaurs/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/little-archaeologists-search-for-dinosaurs/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/little-archaeologists-search-for-dinosaurs.mp4",
    path: ["/game/little-archaeologists-search-for-dinosaurs", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/367680?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Play Little Archaeologists: Search for Dinosaurs! Explore deserts as a fearless archaeologist and uncover the remains of the oldest dinosaurs. Discover hidden secrets!",
    l_dis:
      "There are all kinds of secrets hidden underground in the deserts, and we need fearless explorers to help us uncover the past. Become a real archaeologist and go on an expedition with us in search of the remains of the oldest dinosaurs.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 0,
    title: "Colored Dino: Run and Jump! 🕹️ Play on Playcutegames",
    name: ["Colored Dino: Run and Jump!", "colored-dino-run-and-jump"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/colored-dino-run-and-jump/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/colored-dino-run-and-jump/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/colored-dino-run-and-jump.mp4",
    path: ["/game/colored-dino-run-and-jump", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/251781?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Play Colored Dino: Run and Jump! Guide a dinosaur through the desert, jump over cacti, and duck pterodactyls. Score high to climb the leaderboard and beat your friends!",
    l_dis:
      "Colored Dino: Run and Jump! is an interesting and addictive browser arcade game in which you have to play as a dinosaur running through the desert. Jump over cacti and crouch under pterodactyls to survive! Score as many points as you can to climb the leaderboard and overtake your friends!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 0,
    title: "CR Dino Run 🕹️ Play on Playcutegames",
    name: ["CR Dino Run", "cr-dino-run"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cr-dino-run/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/cr-dino-run/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/cr-dino-run.mp4",
    path: ["/game/cr-dino-run", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/193944?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Play CR Dino Run, a classic dino run game! Score 10 points to level up and increase speed. Dodge cacti and flying dinosaurs, unlock 5 dino characters, and use the dino menu!",
    l_dis:
      "Each time you score 10 points, the level is increased by one and the game goes faster. There are cacti and flying dinosaurs between the obstacles. There are five dinosaur characters you can play within the game. You can unlock these characters by scoring what you need to unlock. You can go to the dinosaur menu by the dinosaur button on the main menu.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 0,
    title: "Dinosaur evolution simulator Online 🕹️ Play on Playcutegames",
    name: ["Dinosaur evolution simulator Online", "dinosaur-evolution-simulator-online"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-evolution-simulator-online/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-evolution-simulator-online/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dinosaur-evolution-simulator-online.mp4",
    path: ["/game/dinosaur-evolution-simulator-online", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/370735?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Hunt dinosaurs to evolve and upgrade your skills. Grow into a powerful predator, but beware of other players and their ferocious dinosaurs in this competitive world!",
    l_dis:
      "Hunt other dinosaurs to grow and develop. Each successful hunt is a step in your evolution. Upgrade your characteristics and turn into a stronger and more ferocious predator. But do not forget, you are not alone! This world is also inhabited by other players who control their own dinosaurs, battles with them are dangerous, so be careful.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 1,
    title: "Dinosaur Eggs Pop 🕹️ Play on Playcutegames",
    name: ["Dinosaur Eggs Pop", "dinosaur-eggs-pop"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-eggs-pop/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-eggs-pop/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dinosaur-eggs-pop.mp4",
    path: ["/game/dinosaur-eggs-pop", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/191040?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Travel back to the Jurassic era with Dinosaur Eggs Pop! Join the cutest dinosaur babysitter on a relaxing bubble shooting adventure. No downloads needed—just jump in and play!",
    l_dis:
      "Get ready to travel back to the Jurassic era with Dinosaur Eggs Pop! Join Dinosaur, the cutest dinosaur babysitter of the Bubble Jungle, on an endless and relaxing bubble shooting adventure. With no downloads necessary, it's easy to jump right into the fun.Get ready to travel back to the Jurassic era with Dinosaur Eggs Pop! Join Dinosaur, the cutest dinosaur babysitter of the Bubble Jungle, on an endless and relaxing bubble shooting adventure. With no downloads necessary, it's easy to jump right into the fun.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 1,
    title: "Dragons 2048 🕹️ Play on Playcutegames",
    name: ["Dragons 2048", "dragons-2048"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dragons-2048/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dragons-2048/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dragons-2048.mp4",
    path: ["/game/dragons-2048", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/351804?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Immerse in a magical dragon world with a game like 2048 but featuring colorful dragons. Unlock all dragons for a fun and intriguing collecting experience!",
    l_dis:
      "Immerse yourself in the magical world of dragons with a game that resembles 2048, but instead of numbers there are cute and colorful dragons.The main goal is to unlock all the dragons, which adds an element of collecting and intrigue.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 1,
    title: "Dinopark: survive in the maze 🕹️ Play on Playcutegames",
    name: ["Dinopark: survive in the maze", "dinopark-survive-in-the-maze"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinopark-survive-in-the-maze/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dinopark-survive-in-the-maze/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dinopark-survive-in-the-maze.mp4",
    path: ["/game/dinopark-survive-in-the-maze", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/311447?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Dinopark: Survive in the Maze is a fun puzzle game that challenges your wits. Explore a labyrinth, defeat dinosaurs, and navigate rooms to escape the maze!",
    l_dis:
      "Dinopark: survive in the maze is a fun puzzle game that will help develop the battle of wits. In this educational game you will explore a labyrinth while defeating dinosaurs. To get out of the maze, you need to choose rooms with a lower number than yours and avoid dinosaurs of a higher level.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 0,
    title: "Dinosaur Shifting Run 🕹️ Play on Playcutegames",
    name: ["Dinosaur Shifting Run", "dinosaur-shifting-run"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-shifting-run/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-shifting-run/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dinosaur-shifting-run.mp4",
    path: ["/game/dinosaur-shifting-run", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/361174?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Sprint across deserts and transform into a dinosaur to smash through obstacles in lush jungles. Dinosaur Shifting Run offers unique challenges and fresh gameplay in every level!",
    l_dis:
      "Do you love mini dinos? Dream of becoming a veterinarian? Save the dinos in the new game Save pet Dino vet clinic! Develop your veterinary clinic, hire assistants, buy new equipment and become the best vet in the dino world! Treat cute mini dinos: herbivores and predators, cheerful and funny. Diagnose, give medicine, catch patients and much more. Enjoy colorful graphics and addictive gameplay.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 0,
    title: "Save Pet Dino Vet Clinic 🕹️ Play on Playcutegames",
    name: ["Save Pet Dino Vet Clinic", "save-pet-dino-vet-clinic"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/save-pet-dino-vet-clinic/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/save-pet-dino-vet-clinic/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/save-pet-dino-vet-clinic.mp4",
    path: ["/game/save-pet-dino-vet-clinic", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/310611?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Love mini dinos? Become a vet in Save Pet Dino Vet Clinic! Develop your clinic, hire staff, and treat cute dinos. Diagnose, medicate, and enjoy colorful graphics!",
    l_dis:
      "Do you love mini dinos? Dream of becoming a veterinarian? Save the dinos in the new game Save pet Dino vet clinic! Develop your veterinary clinic, hire assistants, buy new equipment and become the best vet in the dino world! Treat cute mini dinos: herbivores and predators, cheerful and funny. Diagnose, give medicine, catch patients and much more. Enjoy colorful graphics and addictive gameplay.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-04-25",
    brand: "Yandex",
    orientation: 1,
    title: "Dinosaurs.io 🕹️ Play on Playcutegames",
    name: ["Dinosaurs.io", "dinosaurs-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaurs-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaurs-io/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dinosaurs-io.mp4",
    path: ["/game/dinosaurs-io", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/240032?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Grow the biggest Tyrannosaurus! Eat humans, gain mass, and attack other dinosaurs to become the ultimate winner. Dominate the prehistoric world!",
    l_dis:
      "Grow the biggest Tyrannosaurus! Eat humans, gain mass, attack other dinosaurs and become the winner!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-04-25",
    brand: "Yandex",
    orientation: 0,
    title: "Dino Survival: 3D Simulator 🕹️ Play on Playcutegames",
    name: ["Dino Survival: 3D Simulator", "dino-survival-3d-simulator"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dino-survival-3d-simulator/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dino-survival-3d-simulator/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dino-survival-3d-simulator.mp4",
    path: ["/game/dino-survival-3d-simulator", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/350747?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Survive Jurassic Park! Build your base, collect resources, fight dinosaurs, raid enemy bases, and explore mysterious locations. Raft down rivers and become stronger!",
    l_dis:
      "Survive Jurassic Park! Build your base! Go on a journey to different locations. Collect resources, fight dinosaurs. Go on raids on enemy bases. Infiltrate, become stronger, build buildings and transport. Raft down the river! Explore this world full of mysteries!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-04-25",
    brand: "Yandex",
    orientation: 1,
    title: "Mini Dino Park 🕹️ Play on Playcutegames",
    name: ["Mini Dino Park", "mini-dino-park"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mini-dino-park/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mini-dino-park/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/mini-dino-park.mp4",
    path: ["/game/mini-dino-park", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/241508?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Mini Dino Park is a business simulation game where you expand your island, build dinosaur nests, and attract visitors. Upgrade facilities, guide tourists, and cruise for more income!",
    l_dis:
      "Mini Dino Park is a business simulation game. You need to earn revenue by entertaining customers and selling tickets. In the game, you need to expand the area of the island, build dinosaur nests, build rides and unlock new dinosaurs to attract more visitors. Upgrading park facilities and visitor levels can help you earn more income. Guide lost tourists and return lost wallets to get unexpected gifts. Cruise on the sea to get more income. Come and build your dinosaur park!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-04-25",
    brand: "Yandex",
    orientation: 1,
    title: "Merge Dinosaur: Jurassic World - playcutegames.com",
    name: ["Merge Dinosaur: Jurassic World", "merge-dinosaur-jurassic-world"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/merge-dinosaur-jurassic-world/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/merge-dinosaur-jurassic-world.mp4",
    path: ["/game/merge-dinosaur-jurassic-world", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/228988?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Merge Dinosaur: Jurassic World is a real-time strategy game. Combine dinosaurs and warriors to defeat dragons and monsters. Use strategy to win battles!",
    l_dis:
      "Merge Dinosaur: Jurassic World is a cool real—time strategy game that is suitable for everyone. The main goal of the game is to defeat all enemies by combining your warriors or dinosaurs. Your enemies will be dragons, monsters, tyrannosaurs or other dinosaurs, so it won't be easy. React and think fast. Use your strategy and tactics to win the battle and advance to the next level. Fight the final boss by combining all your creatures! At each stage of this test, an increasingly powerful monster will be waiting for you! Apply your strategy in real time and determine the correct drawing order. ",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],  
  },
  {
    date: "2024-04-25",
    brand: "Yandex",
    orientation: 0,
    title: "Dino Dodge - playcutegames.com",
    name: ["Dino Dodge", "dino-dodge"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dino-dodge/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dino-dodge.mp4",
    path: ["/game/dino-dodge", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/354567?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Dino Dodge is an exciting 2D endless runner. Sprint as fast as you can while dodging deadly monsters, ugly creatures, flying goblins, and dragons!",
    l_dis:
      "Dino Dodge is an exciting 2D endless running game where have to run continuously as fast as you can while dodging through the deadly monsters, ugly creatures, flying goblins, and dragons.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],  
  },
  {
    date: "2024-04-25",
    brand: "Yandex",
   
    orientation: 1,
    title: "Merge Dinosaur Fight - playcutegames.com",
    name: ["Merge Dinosaur Fight", "merge-dinosaur-fight"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/merge-dinosaur-fight/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/merge-dinosaur-fight.mp4",
    path: ["/game/merge-dinosaur-fight", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/343585?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
    "Strategize and fuse dinosaurs and warriors in epic battles! Combine troops to defeat enemies in this thrilling Jurassic dinosaur game. Win with skill!",
    l_dis:
      "Your quest is simple...use your strategy to combine the dinosaurs or warriors on your battlefield and fight against the enemies! You must make a fusion of your troops and merge animals quickly if you want to win in this jurassic dinosaur epic battle simulator in one of coolest dinosaur games.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],  
  },
  
    {
      date: "2024-01-27",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Cat Puzzle: Draw to Kitten - playcutegames.com",
      name: ["Cat Puzzle: Draw to Kitten", "cat-puzzle-draw-to-kitten"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/cat-puzzle-draw-to-kitten/250x142.png",
      thumb_big:"https://cdn.cutedressup.in/games/cutedressup_new/cat-puzzle-draw-to-kitten-300.jpg",
      path: ["/game/cat-puzzle-draw-to-kitten", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/278373?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Cat Puzzle: Draw to Kitten Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `The game is controlled using the mouse or your finger (if playing on a mobile device). Press the right mouse button or use your finger to drag and drop items to their desired locations.
  
        The game offers various locations and levels, each of which may present different challenges. You'll need to sort items of the same type, find matching items, and try to fit the required quantity into a box.
        
        Hints will accompany you throughout the game to ensure you don't get stuck and always know what to do!
        `,
        tag: ["Arcade","Puzzles","Girl games"],
      hint: `1. Drag your finger over the cat and start drawing a line towards the kittens;<br>
      2. Be careful, avoid enemies and obstacles on the way;<br>
      3. Make sure all cats take their kittens home safely!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Organizer master - playcutegames.com",
      name: ["Organizer master", "organizer-master"],
      thumb: "https://avatars.mds.yandex.net/get-games/2977039/2a000001896479d16029063c1603dd7bc4e3/icon1",
      thumb_big: "https://avatars.mds.yandex.net/get-games/2977039/2a000001896479d16029063c1603dd7bc4e3/icon1",
      path: ["/game/organizer-master", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/235877?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Organizer master Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "An engaging 3D puzzle with diverse levels. Find a pair, arrange objects so that everything fits, and sort items by their type. Develop spatial thinking and solve challenges. Enjoy high-quality 3D graphics in an interesting game. A set of levels of varying difficulty will provide you with hours of captivating entertainment. Immerse yourself in the exciting world of puzzles and develop your skills!",
        tag: ["Arcade","Puzzles","Girl games"],
      hint: `The game is controlled using the mouse or your finger (if playing on a mobile device). Press the right mouse button or use your finger to drag and drop items to their desired locations.
  
      The game offers various locations and levels, each of which may present different challenges. You'll need to sort items of the same type, find matching items, and try to fit the required quantity into a box.
      
      Hints will accompany you throughout the game to ensure you don't get stuck and always know what to do!`,
      rating:[4.4,1210,114],  
    },{
      date: "2024-02-20",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Ellie And Friends Floral Outfits - playcutegames.com",
      name: ["Ellie And Friends Floral Outfits", "ellie-and-friends-floral-outfits"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-floral-outfits/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-floral-outfits/520x295.png",
      path: ["/game/ellie-and-friends-floral-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/ellie-and-friends-floral-outfits/",
      Walkthrough: "https://www.youtube.com/embed/T5xfaOEuitc",
      s_dis:
      "Ellie And Friends Floral Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dress in the beauty of nature with ‘Ellie and Friends Floral Outfits’! Explore a blossoming collection of stylish ensembles and vibrant accessories. Elevate your look with the freshness of floral fashion in this exciting adventure. Join Ellie and Friends as they set trends with petals and patterns, making every outfit a garden of style. From casual strolls to garden parties, embrace the allure of floral elegance in your wardrobe. Let the fashion bloom!",
        tag: ["Barbie","Spring","Floral","Dress up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s Trendy Off Shoulder Look - playcutegames.com",
      name: ["Lovie Chic’s Trendy Off Shoulder Look", "lovie-chics-trendy-off-shoulder-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-trendy-off-shoulder-look/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-trendy-off-shoulder-look/520x295.png",
      path: ["/game/lovie-chics-trendy-off-shoulder-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-trendy-off-shoulder-look/",
      Walkthrough: "https://www.youtube.com/embed/tqcQnr8B31Q",
      s_dis:
      "Lovie Chic’s Trendy Off Shoulder Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the chic world of Lovie Chic’s Trendy Off Shoulder Look! Explore fashionable ensembles, stylish accessories, and the allure of the off-shoulder trend. Join Lovie Chic on a fashion journey where every look exudes sophistication. Elevate your style with off-shoulder elegance and let Lovie Chic be your guide to the ultimate trendy wardrobe. From casual outings to glamorous evenings, discover the charm of the off-shoulder look with Lovie Chic’s expert inspiration!",
        tag: ["Lovie Chic","Dress up","Exclusive","Fashion","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-01-22",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Biker Vogue - playcutegames.com",
      name: ["Celebrity Biker Vogue", "celebrity-biker-vogue"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-biker-vogue/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-biker-vogue/520x295.png",
      path: ["/game/celebrity-biker-vogue", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-biker-vogue/",
      Walkthrough: "https://www.youtube.com/embed/2YemTZgukf8",
      s_dis:
      "Celebrity Biker Vogue Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Rev up your style with ‘Celebrity Biker Vogue’! Explore the fusion of edgy and chic as celebrities redefine biker fashion. From leather jackets to sleek helmets, witness the clash of high fashion on the open road. Join the ride, elevate your style, and embrace the rebellious glamour of celebrity biker vogue. Let the stars guide you on a fashionable journey where the road becomes a runway, and every outfit is a statement. Gear up for a stylish adventure in the world of Celebrity Biker Vogue!",
        tag: ["Celebrity","Dress up","Exclusive","Travel","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    
    {
      date: "2024-01-24",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s #CandyLand Fashion - playcutegames.com",
      name: ["Lovie Chic’s #CandyLand Fashion", "lovie-chics-candyland-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-candyland-fashion/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-candyland-fashion/520x295.png",
      path: ["/game/lovie-chics-candyland-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-candyland-fashion/",
      Walkthrough: "https://www.youtube.com/embed/jDgvI6RqPcg",
      s_dis:
      "Lovie Chic’s #CandyLand Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the sweet world of Lovie Chic’s #CandyLand Fashion! Explore sugary-sweet ensembles, vibrant accessories, and candy-inspired styles. Join the chic adventure as Lovie Chic sets trends in the colorful Candy Land. Elevate your style with whimsical fashion, from candy-coated hues to playful patterns. Let the sugary magic inspire your wardrobe and make a bold, sweet statement in every look. Indulge in the delightful journey of fashion with Lovie Chic in #CandyLand!",
        tag: ["Lovie Chic","Dress up","Exclusive","Fashion","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-01-12",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Snow White All Around The Fashion - playcutegames.com",
      name: ["Snow White All Around The Fashion", "snow-white-all-around-the-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/snow-white-all-around-the-fashion/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/snow-white-all-around-the-fashion/520x295.png",
      path: ["/game/snow-white-all-around-the-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/snow-white-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/yYQZy68u5KU",
      s_dis:
      "Snow White All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the enchanting world of ‘Snow White All Around The Fashion’! Explore a magical wardrobe with styles ranging from princess elegance to modern chic. Join Snow White on a fashion adventure, discover the latest trends, and elevate your style with a touch of fairy-tale glamour. With a mix of classic and contemporary ensembles, embrace the magic of fashion in the fairy-tale realm. From the forest to the ballroom, let Snow White inspire your wardrobe with timeless charm!",
        tag: ["Princess","Dress up","Exclusive","Fashion","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-01-04",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Mermaid All Around The Fashion - playcutegames.com",
      name: ["Mermaid All Around The Fashion", "mermaid-all-around-the-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mermaid-all-around-the-fashion/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mermaid-all-around-the-fashion/520x295.png",
      path: ["/game/mermaid-all-around-the-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/mermaid-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/jflKIGiVgQc",
      s_dis:
      "Mermaid All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the diverse world of ‘Mermaid All Around The Fashion! Explore a myriad of styles, from casual chic to elegant evening looks. Join the Mermaid Princess fashion journey, discover the latest trends, and elevate your style game. With an array of outfits and accessories, the possibilities are endless. Unleash your creativity and make a statement in every ensemble. From day to night, Mermaid Princess has you covered in the ever-evolving world of fashion!",
        tag: ["Princess","Dress up","Exclusive","Fashion","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-01-19",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Valentine Hidden Heart - playcutegames.com",
      name: ["Valentine Hidden Heart", "valentine-hidden-heart"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/valentine-hidden-heart/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/valentine-hidden-heart/520x295.png",
      path: ["/game/valentine-hidden-heart", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bestgamespot/valentine-hidden-heart/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Valentine Hidden Heart Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Dive into a world of love and discovery with "Valentine Hidden Heart" - the ultimate romantic hidden object adventure! Unleash your inner cupid as you embark on a delightful quest to find the 10 hidden hearts in each of the 15 enchanting levels. `,
        tag: ["Hidden","Valentine","Girl games","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-11",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Fruit Merge Reloaded - playcutegames.com",
      name: ["Fruit Merge Reloaded", "fruit-merge-reloaded"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/fruit-merge-reloaded/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fruit-merge-reloaded/520x295.png",
      path: ["/game/fruit-merge-reloaded", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bestgamespot/fruit-merge-reloaded/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Fruit Merge Reloaded Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the juicy and colorful world of Fruit Merge Reloaded, a captivating merging game that combines the addictive gameplay of 2048 with the sweetness of your favorite fruits! Your task is to merge identical fruits to create new and exotic varieties, aiming to reach the ultimate fruit fusion.",
        tag: ["Marge","Matching","Puzzle"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-04",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity In Venice Carnival - playcutegames.com",
      name: ["Celebrity In Venice Carnival", "celebrity-in-venice-carnival"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-in-venice-carnival/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-in-venice-carnival/520x295.png",
      path: ["/game/celebrity-in-venice-carnival", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-in-venice-carnival/",
      Walkthrough: "https://www.youtube.com/embed/p78SmfCqh5k",
      s_dis:
      "Celebrity In Venice Carnival Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Experience the enchantment of the ‘Celebrity in Venice Carnival’! Immerse yourself in the allure of iconic masks, glamorous costumes, and star-studded celebrations. Join celebrities as they navigate the romantic canals in style. From elegant gowns to mysterious masks, witness the clash of high fashion in the heart of Venice. Elevate your fashion game, embrace the carnival spirit, and let the stars guide you through this glamorous Venetian adventure!",
        tag: ["Celebrity","Dress up","Exclusive","Venice Carnival","Festival","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    
    {
      date: "2023-12-27",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bffs Fabulous Winter Look - playcutegames.com",
      name: ["Bffs Fabulous Winter Look", "bffs-fabulous-winter-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-fabulous-winter-look/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-fabulous-winter-look/520x295.png",
      path: ["/game/bffs-fabulous-winter-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-fabulous-winter-look/",
      Walkthrough: "https://www.youtube.com/embed/LCfiBeabXXg",
      s_dis:
      "Transform the BFFs into winter fashion icons in the BFFs Fabulous Winter Look game at PlayCuteGames! Play for free and showcase your best styles today!",
      l_dis:
        "Dive into winter glamour with BFFs Fabulous Winter Look! Explore chic ensembles, cozy layers, and trendy accessories. Join the BFFs as they set the trend for the perfect winter look. Elevate your style and embrace the magic of the season with the ultimate BFFs winter fashion inspiration. From festive gatherings to snowy adventures, let the BFFs guide you to a fabulously fashionable winter!",
        tag: ["Barbie","Dress up","Exclusive","Winter","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },{
      date: "2023-01-17",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Ellie And Friends Get Ready For First Date - playcutegames.com",
      name: ["Ellie And Friends Get Ready For First Date", "ellie-and-friends-get-ready-for-first-date"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-get-ready-for-first-date/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-get-ready-for-first-date/520x295.png",
      path: ["/game/ellie-and-friends-get-ready-for-first-date", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/ellie-and-friends-get-ready-for-first-date/",
      Walkthrough: "https://www.youtube.com/embed/qWZ5BW50u8I",
      s_dis:
      "Ellie And Friends Get Ready For First Date Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Join Ellie And Friends Get Ready For First Date! Explore chic ensembles, trendy accessories, and glamorous styles to make a lasting impression. Dive into the excitement of the first date as these fashion-forward friends set the trend for the perfect look. Elevate your style game, embrace the thrill of romance, and let Ellie and Friends guide you through a fashionable journey to a memorable first date. Make every moment stylish and unforgettable!",
        tag: ["Barbie","Dress up","Exclusive","Valentine","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-12-20",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Winter Ice Skating Look - playcutegames.com",
      name: ["BFFs Winter Ice Skating Look", "bffs-winter-ice-skating-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-winter-ice-skating-look/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-winter-ice-skating-look/520x295.png",
      path: ["/game/bffs-winter-ice-skating-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-winter-ice-skating-look/",
      Walkthrough: "https://www.youtube.com/embed/ExpM8AzpD4U",
      s_dis:
      "BFFs Winter Ice Skating Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the frosty enchantment of BFFs Winter Ice Skating Look! Explore chic winter ensembles, cozy layers, and stylish accessories for the perfect ice-skating adventure. Join the BFFs as they glide through the season in fashion-forward attire. Elevate your winter style and embrace the magic of frosty fashion with the ultimate Ice Skating Look inspiration. Let the chilly breeze be your runway!",
        tag: ["Lovie Chic","Dress up","Exclusive","Winter","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
   
    {
      date: "2023-12-12",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Warm And Chic Look - playcutegames.com",
      name: ["Celebrity Warm And Chic Look", "celebrity-warm-and-chic-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-warm-and-chic-look/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-warm-and-chic-look/520x295.png",
      path: ["/game/celebrity-warm-and-chic-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-warm-and-chic-look/",
      Walkthrough: "https://www.youtube.com/embed/tefsFgEh1Vo",
      s_dis:
      "Celebrity Warm And Chic Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Experience Celebrity Warm and Chic Look! Immerse yourself in the cozy elegance of star-inspired winter fashion. From luxurious coats to trendy accessories, discover the secrets behind celebrities’ stylish warmth. Join the fashion journey and elevate your cold-weather wardrobe with the perfect blend of comfort and glamour. Embrace winter in celebrity style!",
        tag: ["Celebrity","Dress up","Exclusive","Winter","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    
    {
      date: "2023-12-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrities Night Out Outfits - playcutegames.com",
      name: ["Celebrities Night Out Outfits", "celebrities-night-out-outfits"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrities-night-out-outfits/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrities-night-out-outfits/520x295.png",
      path: ["/game/celebrities-night-out-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrities-night-out-outfits/",
      Walkthrough: "https://www.youtube.com/embed/ET7ZZotyQzc",
      s_dis:
      "Celebrities Night Out Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Explore the glitz and glamour of Celebrities Night Out Outfits! Step into the world of high fashion as your favorite stars showcase their iconic styles. From red carpet elegance to chic street looks, discover the secrets behind their stunning night-out ensembles. Join the fashion parade and get inspired to elevate your own evening wardrobe. Uncover the allure of celebrity fashion and make every night out a memorable style statement!",
        tag: ["Celebrity","Dress up","Exclusive","Holiday","Christmas","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2022-12-07",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Perfect Christmas Costumes - playcutegames.com",
      name: ["My Perfect Christmas Costumes", "my-perfect-christmas-costumes"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-christmas-costumes/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-christmas-costumes/250x142.png",
      path: ["/game/my-perfect-christmas-costumes", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/my-perfect-christmas-costumes/",
      Walkthrough: "https://www.youtube.com/embed/9n0PamZ81IA",
      s_dis:
      "Play My Perfect Christmas Costumes online for free at PlayCuteGames! Create festive outfits and enjoy fun holiday dress-up moments in this cheerful game.",
      l_dis:
        "Welcome to My Perfect Christmas Costumes. Ellie and her friends are excited for Christmas celebrations. They want to try cute Christmas outfits with crazy makeovers. Browse their wardrobe for cute outfits and pick some matching accessories. Join these girls and enjoy this Christmas a most memorable one. Have fun!",
      tag: ["Barbie","Dress up","Christmas","Exclusive","Girl games","Holiday","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },{
      date: "2023-12-27",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Retro Time Travel Fashion - playcutegames.com",
      name: ["BFFs Retro Time Travel Fashion", "bffs-retro-time-travel-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-retro-time-travel-fashion/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-retro-time-travel-fashion/520x295.png",
      path: ["/game/bffs-retro-time-travel-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-retro-time-travel-fashion/",
      Walkthrough: "https://www.youtube.com/embed/i93tAKNBYDg",
      s_dis:
      "BFFs Retro Time Travel Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Take a stylish journey through time with BFFs Retro Time Travel Fashion! Explore vintage ensembles, iconic trends, and timeless accessories. Join the BFFs as they navigate the fashion eras, setting trends in every decade. Elevate your style with a retro twist and let the BFFs be your guides in the ultimate time-traveling fashion adventure. Rediscover the charm of bygone styles and create a timeless wardrobe with the BFFs!",
        tag: ["Barbie","Dress up","Exclusive","Retro","Fashion","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-12-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Fashionista Christmas Eve Party - playcutegames.com",
      name: ["Fashionista Christmas Eve Party", "fashionista-christmas-eve-party"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/fashionista-christmas-eve-party/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashionista-christmas-eve-party/520x295.png",
      path: ["/game/fashionista-christmas-eve-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/fashionista-christmas-eve-party/",
      Walkthrough: "https://www.youtube.com/embed/AN2M6L95z2c",
      s_dis:
      "Fashionista Christmas Eve Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Indulge in the Fashionista Christmas Eve Party extravaganza! Discover glamorous holiday ensembles, festive accessories, and the perfect blend of chic for a stylish celebration. Join the fashion-forward festivities and elevate your Christmas Eve style to new heights. Unwrap the magic of the season with Fashionista, where every outfit is a statement and every moment is a fashion triumph. Let the glamour of Christmas Eve unfold.",
        tag: ["Fashionista","Dress up","Exclusive","Holiday","Christmas","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-28",
      brand: "Gamezop",
     
      orientation: 1,
      title: "Fruity Fiesta - playcutegames.com",
      name: ["Fruity Fiesta", "fruity-fiesta"],
      thumb: "https://static.gamezop.com/mKZZW9NXW/square.png",
      thumb_big: "https://static.gamezop.com/mKZZW9NXW/square.png",
      path: ["/game/fruity-fiesta", "https://playcutegames.com"],
      iframe: "https://8033.play.gamezop.com/g/mKZZW9NXW",
      Walkthrough: "https://www.youtube.com/embed/Z1PT6M-E-6w",
      s_dis:
      "It's time for a juicy, jivy Fiesta of Fruits. Merge the fruits to get the elusive Melons. Collect them all to become the fruit baron!",
      l_dis:
        `Get ready for a fruity adventure on Play Cute Games! In this colorful puzzle game you’ll enter a world where juicy fruits are waiting to be matched and collected. Each level brings new challenges as you connect fruits, create sweet combos to complete your goals. Whether it’s strawberries, oranges or apples every move counts in this puzzle game.
As you progress the puzzles get harder, fun obstacles will be introduced to keep you on your toes. But don’t worry along the way you’ll find tasty rewards and bonuses to help you through those extra tough levels. With bright graphics and happy music Fruity Fiesta is a game that’s as fun to look at as it is to play!
Whether you're a puzzle enthusiast or just looking to unwind, Fruity Fiesta guarantees a delightful experience. Jump in and see how far you can go in this juicy adventure!`,
        tag: ["Gamezop","Matching","Match-3","Girl games"],
      hint: `Use your left mouse or touch`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-28",
      brand: "Gamezop",
     
      orientation: 1,
      title: "Pop Soap - playcutegames.com",
      name: ["Pop Soap", "pop-soap"],
      thumb: "https://static.gamezop.com/SJX7TGkDq1X/square.png",
      thumb_big: "https://static.gamezop.com/SJX7TGkDq1X/square.png",
      path: ["/game/pop-soap", "https://playcutegames.com"],
      iframe: "https://8033.play.gamezop.com/g/SJX7TGkDq1X",
      Walkthrough: "https://www.youtube.com/embed/Z1PT6M-E-6w",
      s_dis:
      "Classic Match-3 game in Endless Mode and an Achievements mode! Remember, if there's 2 or more of the same kind, you can burst them!",
      l_dis:
        `Get ready for a burst of fun with Pop Soap games on Play Cute Games! Have a blast popping bright bubbles and cracking fun puzzles in a lively, colorful world. Match sparkling soaps and use your strategy to clear levels while enjoying dynamic gameplay. Each stage presents a new challenge that will keep you hooked as you progress through an array of cheerful, soapy adventures.
Uncover special power-ups that add an exciting twist to your game and help you achieve high scores. Whether you’re looking to beat your own record or just have a good time, Pop Soap has something for everyone. Share your soapy achievements with friends and see who can master the most challenging levels. The fun never stops in Pop Soap—jump in and start popping today!`,
        tag: ["Gamezop","Matching","Match-3","Girl games"],
      hint: `Use your left mouse or touch`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-01-06",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Winter Wonderland Clash - playcutegames.com",
      name: ["Celebrity Winter Wonderland Clash", "celebrity-winter-wonderland-clash"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-winter-wonderland-clash/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-winter-wonderland-clash-300.jpg",
      path: ["/game/celebrity-winter-wonderland-clash", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-winter-wonderland-clash/",
      Walkthrough: "https://www.youtube.com/embed/jflKIGiVgQc",
      s_dis:
      "Celebrity Winter Wonderland Clash Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the Celebrity Winter Wonderland Clash! Explore the glamorous clash of styles as celebrities navigate the snowy season. From cozy layers to high-end fashion, witness the stars setting trends in a winter wonderland. Join the clash, elevate your winter style, and immerse yourself in the magic of celebrity fashion. Let the snowy backdrop be the canvas for a clash of elegance and glamour. Bundle up and embrace the chic side of winter with your favorite celebrities!",
        tag: ["Celebrity","Dress up","Exclusive","Winter","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-29",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrities Get Ready For Christmas - playcutegames.com",
      name: ["Celebrities Get Ready For Christmas", "celebrities-get-ready-for-christmas"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrities-get-ready-for-christmas/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrities-get-ready-for-christmas/520x295.png",
      path: ["/game/celebrities-get-ready-for-christmas", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrities-get-ready-for-christmas/",
      Walkthrough: "https://www.youtube.com/embed/DmeOmLJOjnE",
      s_dis:
      "Celebrities Get Ready For Christmas Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the world of ‘Celebrities Get Ready for Christmas’! Join your favorite stars as they prepare for the festive season. Explore dazzling holiday outfits, glamorous decorations, and witness the magic of Christmas through the eyes of celebrities. Get ready for a star-studded holiday celebration! Play and have fun!",
        tag: ["Celebrity","Dress up","Exclusive","Holiday","Christmas","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-10-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Thanksgiving Squad Style - playcutegames.com",
      name: ["Thanksgiving Squad Style", "thanksgiving-squad-style"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/thanksgiving-squad-style/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/thanksgiving-squad-style-300.jpg",
      path: ["/game/thanksgiving-squad-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/thanksgiving-squad-style/",
      Walkthrough: "https://www.youtube.com/embed/_WfHISbh1II",
      s_dis:
      "Thanksgiving Squad Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the world of Thanksgiving Squad Style! Explore a fusion of cozy elegance and festive fashion as the squad celebrates the season. From warm layers to stylish accents, discover the perfect ensemble to make this Thanksgiving unforgettable. Join the squad and embrace the spirit of gratitude and style!",
        tag: ["Winx","Dress up","Thanksgiving","Exclusive","Holiday","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-18",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Fashionista Rainy Day Edition - playcutegames.com",
      name: ["Fashionista Rainy Day Edition", "fashionista-rainy-day-edition"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/fashionista-rainy-day-edition/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashionista-rainy-day-edition/520x295.png",
      path: ["/game/fashionista-rainy-day-edition", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/fashionista-rainy-day-edition/",
      Walkthrough: "https://www.youtube.com/embed/lVs_lIc1bTk",
      s_dis:
      "Fashionista Rainy Day Edition Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the world of Fashionista Rainy Day Edition! Embrace the charm of rainy days with our curated collection of chic ensembles, stylish rainwear, and trendy accessories. Join the fashion journey as we redefine rainy day fashion, proving that even in drizzles, your style can shine. Elevate your wardrobe with weather-appropriate yet fabulous choices. Stay dry, stay fabulous, and make a splash with your rainy day looks!",
        tag: ["Fashionista","Dress up","Fashion","Fall","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-11-21",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s Stylish Maxi Look - playcutegames.com",
      name: ["Lovie Chic’s Stylish Maxi Look", "lovie-chics-stylish-maxi-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-stylish-maxi-look/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-stylish-maxi-look/520x295.png",
      path: ["/game/lovie-chics-stylish-maxi-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-stylish-maxi-look/",
      Walkthrough: "https://www.youtube.com/embed/Z8RTINQ9qqY",
      s_dis:
      "Lovie Chic’s Stylish Maxi Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into Lovie Chic’s Stylish Maxi Look! Elevate your fashion game with chic maxi dresses, trendy accessories, and expert styling tips. Embrace comfort and glamour in every step. Help the chic to pick up a perfect maxi outfit. Join the style revolution and discover your perfect maxi ensemble with Lovie Chic!",
        tag: ["Lovie Chic","Dress up","Exclusive","Holiday","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-10",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Cold Weather Style - playcutegames.com",
      name: ["Celebrity Cold Weather Style", "celebrity-cold-weather-style"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-cold-weather-style/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-cold-weather-style/520x295.png",
      path: ["/game/celebrity-cold-weather-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-cold-weather-style/",
      Walkthrough: "https://www.youtube.com/embed/rJDSz1xzp8M",
      s_dis:
      "Celebrity Cold Weather Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into Celebrity Cold Weather Style! Discover how your favorite stars rock winter fashion with cozy layers, chic outerwear, and trendy accessories. Get inspired to elevate your cold-weather wardrobe and stay fashion-forward all season. Join us in exploring the glamour of celebrity winter fashion! Elevate your seasonal wardrobe with inspiration from the glamorous world of celebrities.",
        tag: ["Lovie Chic","Dress up","Winter","Style","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-15",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Lisa Fashion Club #Style Challenge - playcutegames.com",
      name: ["Lisa Fashion Club #Style Challenge", "lisa-fashion-club-style-challenge"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lisa-fashion-club-style-challenge/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lisa-fashion-club-style-challenge/520x295.png",
      path: ["/game/lisa-fashion-club-style-challenge", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lisa-fashion-club-style-challenge/",
      Walkthrough: "https://www.youtube.com/embed/fqRGY__OIg8",
      s_dis:
      "Lisa Fashion Club #Style Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Join the Lisa Fashion Club #Style Challenge! Unleash your creativity and explore the latest trends. Share your unique looks, discover fashion inspiration, and be part of a vibrant community that celebrates individual style. Elevate your fashion game with Lisa and make every outfit a statement! Unleash your creativity, and explore new trends.",
        tag: ["Lisa","Dress up","Fashion","Style","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-10-13",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Lucy All Season Fashionista - playcutegames.com",
      name: ["Lucy All Season Fashionista", "lucy-all-season-fashionista"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lucy-all-season-fashionista/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lucy-all-season-fashionista-300.jpg",
      path: ["/game/lucy-all-season-fashionista", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lucy-all-season-fashionista/",
      Walkthrough: "https://www.youtube.com/embed/RycnDXDHsnA",
      s_dis:
      "Lucy All Season Fashionista Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Discover Lucy All-Season Fashionista, who effortlessly masters every seasonal trend. From cozy winter chic to breezy summer styles, follow her fashion journey through all seasons. Get inspired to elevate your wardrobe with Lucy’s expert tips and versatile fashion choices. Embrace a style that transcends every season!",
        tag: ["Lucy","Dress up","Season","Exclusive","Autumn","Winter","Summer","Fall","Spring","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-08",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s Fall Dress Up - playcutegames.com",
      name: ["Lovie Chic’s Fall Dress Up", "lovie-chics-fall-dress-up"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-fall-dress-up/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-fall-dress-up/520x295.png",
      path: ["/game/lovie-chics-fall-dress-up", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-fall-dress-up/",
      Walkthrough: "https://www.youtube.com/embed/9FnPeY7-Fx4",
      s_dis:
      "Lovie Chic’s Fall Dress Up Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into Lovie Chic’s Fall Dress Up, a world of autumnal elegance and trendy ensembles. Explore cozy layers, stylish textures, and chic accessories, embracing the spirit of the season. Join the fashion journey and elevate your fall style with Lovie Chic’s expert guidance. Discover your perfect autumn look today!",
        tag: ["Lovie Chic","Dress up","Fall", "Autumn","Exclusive","Holiday","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-10-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFF’s Day Of Dead Celebration - playcutegames.com",
      name: ["BFF’s Day Of Dead Celebration", "bffs-day-of-dead-celebration"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-day-of-dead-celebration/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-day-of-dead-celebration-300.jpg",
      path: ["/game/bffs-day-of-dead-celebration", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-day-of-dead-celebration/",
      Walkthrough: "https://www.youtube.com/embed/9Lhvd0bFlVY",
      s_dis:
      "BFF’s Day Of Dead Celebration Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the vibrant BFF’s Day of the Dead Celebration! Experience the rich cultural festivities, colorful traditions, and lively celebrations. Join the BFFs as they honor loved ones with intricate altars, face paintings, and traditional rituals. Immerse yourself in the spirit of togetherness and remembrance on this special occasion!",
        tag: ["Angela","Dress up","Festivel","Exclusive","Holiday","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-10-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chics Black Friday Shopping - playcutegames.com",
      name: ["Lovie Chics Black Friday Shopping", "lovie-chics-black-friday-shopping"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-black-friday-shopping/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-black-friday-shopping-300.jpg",
      path: ["/game/lovie-chics-black-friday-shopping", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-black-friday-shopping/",
      Walkthrough: "https://www.youtube.com/embed/0q-wcsoLb-k",
      s_dis:
      "Lovie Chics Black Friday Shopping Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Indulge in the ultimate Lovie Chics Black Friday Shopping spree! BFF girls are excited to go out for shopping on Black Friday. Explore exclusive deals, trendy fashion finds, and must-have accessories. Elevate your style and make the most of this exciting shopping extravaganza. Join us for a day of style and savings!",
        tag: ["Lovie Chic","Dress up","Black Friday","Exclusive","Holiday","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-12-27",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Ellie And Friends Ski Fashion - playcutegames.com",
      name: ["Ellie And Friends Ski Fashion", "ellie-and-friends-ski-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-ski-fashion/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-ski-fashion/520x295.png",
      path: ["/game/ellie-and-friends-ski-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/ellie-and-friends-ski-fashion/",
      Walkthrough: "https://www.youtube.com/embed/hGS9EVvz0k4",
      s_dis:
      "Ellie And Friends Ski Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Embark on a chic alpine journey with Ellie and Friends Ski Fashion! Explore trendy winter ensembles, cozy layers, and stylish accessories. Join the fashionable slope adventure as Ellie and friends set the trend for the perfect ski look. Elevate your winter style and hit the slopes in glamour. Unleash the magic of ski fashion with Ellie and friends as your stylish guides. Glide through the season in fashion-forward attire!",
        tag: ["Barbie","Dress up","Exclusive","Winter","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-10-18",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Trip To International Balloon Fiesta - playcutegames.com",
      name: ["Trip To International Balloon Fiesta", "trip-to-international-balloon-fiesta"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/trip-to-international-balloon-fiesta/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/trip-to-international-balloon-fiesta-300.jpg",
      path: ["/game/trip-to-international-balloon-fiesta", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/trip-to-international-balloon-fiesta/",
      Walkthrough: "https://www.youtube.com/embed/ZNxVR0uktk8",
      s_dis:
      "Trip To International Balloon Fiesta Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Trip to International Balloon Fiesta! Join the BFFs as they plan a trip to embrace the spirit of adventure. Get ready to experience vibrant festivities and magical hot air balloons in style. Elevate your travel wardrobe and create picture-perfect memories with your best friends! Play and have fun!",
        tag: ["Rainbow High","Dress up","Festival","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-10-04",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Monster Girls Back To School - playcutegames.com",
      name: ["Monster Girls Back To School", "monster-girls-back-to-school"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/monster-girls-back-to-school/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/monster-girls-back-to-school-300.jpg",
      path: ["/game/monster-girls-back-to-school", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/monster-girls-back-to-school/",
      Walkthrough: "https://www.youtube.com/embed/0PQeSr1bD6o",
      s_dis:
      "Monster Girls Back To School Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Monster Girls Back To School. Join the Monster Girls as they prep for school! Assist them in selecting the ideal outfits for a stylish return. Dive into their supernatural world, full of fashion choices and exciting back-to-school adventures. Get ready to be their fashion guide and have fun!",
        tag: ["Monster High","Dress up","School","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-11-21",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s Black Vs Pink Style - playcutegames.com",
      name: ["Lovie Chic’s Black Vs Pink Style", "lovie-chics-black-vs-pink-style"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-black-vs-pink-style/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-black-vs-pink-style/520x295.png",
      path: ["/game/lovie-chics-black-vs-pink-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-black-vs-pink-style/",
      Walkthrough: "https://www.youtube.com/embed/H0mD-N4keBw",
      s_dis:
      "Lovie Chic’s Black Vs Pink Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the world of Lovie Chic’s Black Vs Pink Style! Explore a fusion of cozy elegance and festive fashion as the squad celebrates the season. From warm layers to stylish accents, discover the perfect ensemble to make this Thanksgiving unforgettable. Join the squad and embrace the spirit of gratitude and style!",
        tag: ["Lovie Chic","Dress up","Exclusive","Holiday","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      id: 202203001,
      brand: "lagged",
     
      orientation: 1,
      title: "Dr. Animal Teeth - playcutegames.com",
      name: ["Dr. Animal Teeth", "dr-animal-teeth"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/dr-animal-teeth/250x142.png",
      path: ["/game/dr-animal-teeth", "https://playcutegames.com"],
      iframe:
        "https://lagged.com/games/dr-animal-teeth/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Dr. Animal Teeth Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Time to sit down in the dentist's chair and clean some dirty mouths. You have 3 different animals who have not kept up with good teeth hygiene. Use your tools to remove cavities, brush the teeth and clean all of the mouths in this fun online dentist game.",
        tag: ["Doctor","Animal","Girl Games"],
      hint: 0,
      platform:"both",
    },
    
    {
      date: "2022-10-18",
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Perfect Halloween Costume - playcutegames.com",
      name: ["My Perfect Halloween Costume", "my-perfect-halloween-costume"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-halloween-costume/250x142.png",
      path: ["/game/my-perfect-halloween-costume", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/my-perfect-halloween-costume/",
      Walkthrough: "https://www.youtube.com/embed/era5WKrPocI",
      s_dis:
      "My Perfect Halloween Costume Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Perfect Halloween Costume. Bff Girls decided to celebrate this spooky holiday, just like when they were kids! This means that these princesses will go trick-or-treating dressed up in great costumes! This means that you can help them find the best costumes out there, which is not easy! As you will see, there are so many choices. Open the wardrobe and see it yourself.",
        tag: ["Winx","Dress up","Halloween","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-10-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Cute Melon My Dream Profession - playcutegames.com",
      name: ["Cute Melon My Dream Profession", "cute-melon-my-dream-profession"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-melon-my-dream-profession/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-melon-my-dream-profession-300.jpg",
      path: ["/game/cute-melon-my-dream-profession", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/cute-melon-my-dream-profession/",
      Walkthrough: "https://www.youtube.com/embed/vNulEfnyKDg",
      s_dis:
      "Cute Melon My Dream Profession Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the enchanting world of ‘Cute Melon My Dream Profession,’ where kids embark on a journey to uncover their dream careers. Dress up kids in exciting professions and have fun while sparking their ambition!” Join us in fostering the next generation’s dreams and aspirations through educational and delightful experiences!",
        tag: ["Melon","Kids","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-11-28",
      brand: "Gamezop",
     
      orientation: 1,
      title: "Don't Touch the Walls - playcutegames.com",
      name: ["Don't Touch the Walls", "dont-touch-the-walls"],
      thumb: "https://static.gamezop.com/rybx12amqCB/square.png",
      thumb_big: "https://static.gamezop.com/rybx12amqCB/square.png",
      path: ["/game/dont-touch-the-walls", "https://playcutegames.com"],
      iframe: "https://8033.play.gamezop.com/g/rybx12amqCB",
      Walkthrough: "https://www.youtube.com/embed/KmNYWJ09d3k",
      s_dis:
      "Be quick with your moves and navigate the maze without touching the walls! The longer you play without touching any of the walls, the more you score. Watch out for the turns!",
      l_dis:
        "Be quick with your moves and navigate the maze without touching the walls! The longer you play without touching any of the walls, the more you score. Watch out for the turns!",
        tag: ["Gamezop","Puzzle","Adventure","Logic","Girl games"],
      hint: `Use your left mouse or touch`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-28",
      brand: "Gamezop",
     
      orientation: 1,
      title: "Juicy Dash - playcutegames.com",
      name: ["Juicy Dash", "juicy-dash"],
      thumb: "https://static.gamezop.com/H1lZem8hq/square.png",
      thumb_big: "https://static.gamezop.com/H1lZem8hq/square.png",
      path: ["/game/juicy-dash", "https://playcutegames.com"],
      iframe: "https://8033.play.gamezop.com/g/H1lZem8hq",
      Walkthrough: "https://www.youtube.com/embed/i4G5ncMgpJI",
      s_dis:
      "Juicy Dash is a classic match3 game. Prove your skills and match as many fruits as possible. Create combos of 4 or 5 fruits to get boni and collect as many points as you can. Hurry up! Time is limited!",
      l_dis:
        `Get set for a fruity adventure with Juicy Dash on Play Cute Games! In this colourful match-3 puzzle game you’ll be swapping and matching bananas, strawberries and oranges to solve the puzzles. The aim is to connect at least three of the same fruit to clear them from the board and score big. As you progress through the levels clever moves will help you make delicious combinations and unlock special treats like extra time, juice and jam.
Every level is a new challenge so every game is a new experience. With bright graphics and fun sound effects Juicy Dash is for fruit puzzle fans and colour lovers. Share your scores and achievements with friends and see who can get to the most levels. Get started now!`,
        tag: ["Gamezop","Puzzle","Match-3","Logic","Girl games"],
      hint: `Use your left mouse or touch`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-09-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Luma The Fashion Stylist - playcutegames.com",
      name: ["Luma The Fashion Stylist", "luma-the-fashion-stylist"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/luma-the-fashion-stylist/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/luma-the-fashion-stylist-300.jpg",
      path: ["/game/luma-the-fashion-stylist", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/luma-the-fashion-stylist/",
      Walkthrough: "https://www.youtube.com/embed/N2T1j5xU2EE",
      s_dis:
      "Luma The Fashion Stylist Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Luma The Fashion Stylist Game. Introducing Luma, the fashion stylist extraordinaire. With a keen eye for trends, Luma effortlessly navigates styles like casual chic, minimalist, feminine, urban, tomboy, and hip-hop. Elevate your fashion skills and discover your unique style for Luma with your expert guidance. Unleash the fashionista in you!",
        tag: ["Luma","Fashion","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-09-19",
      brand: "cutedressup",
     
      orientation: 1,
      title: "Ice Princess All Around The Fashion - playcutegames.com",
      name: ["Ice Princess All Around The Fashion", "ice-princess-all-around-the-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ice-princess-all-around-the-fashion/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/ice-princess-all-around-the-fashion-300.jpg",
      path: ["/game/ice-princess-all-around-the-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ice-princess-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Ice Princess All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Ice Princess All Around The Fashion. Enter the enchanting world of the Ice Princess, where fashion knows no bounds! Explore a realm of icy elegance and timeless style. From regal ball gowns to chic modern looks, discover a wardrobe fit for a princess. Join her on a journey through the world of fashion, where every outfit tells a story. Unleash your inner fashionista and embrace the magic of the Ice Princess’s style!",
        tag: ["Princess","Fashion","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },{
      date: "2023-10-04",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Halloween Costumes - playcutegames.com",
      name: ["Celebrity Halloween Costumes", "celebrity-halloween-costumes"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-halloween-costumes/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-halloween-costumes-300.jpg",
      path: ["/game/celebrity-halloween-costumes", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-halloween-costumes/",
      Walkthrough: "https://www.youtube.com/embed/BoS07BubpUA",
      s_dis:
      "Celebrity Halloween Costumes Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Halloween Costume Adventure! Your favorite celebrities are planning the perfect Halloween outfits together. Get ready for a star-studded Halloween experience as they choose and showcase their ultimate costumes. Unleash your inner celebrity with the hottest Halloween trends!",
        tag: ["Celebrity","Dress up","Halloween","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-18",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Royal Couple Halloween Party - playcutegames.com",
      name: ["Royal Couple Halloween Party", "royal-couple-halloween-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/royal-couple-halloween-party/250x142.png",
      path: ["/game/royal-couple-halloween-party", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/royal-couple-halloween-party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Royal Couple Halloween Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Royal Couple Halloween Party. Halloween is behind the corner and your favorite Royal couples are getting ready to go trick-or-treating in their great fantasy world. Browse their wardrobe for perfect costumes for each couple. Come and join the girls and see what highly original Halloween costumes you can create for each of them. Have fun, ladies!",
        tag: ["Princess","Dress up","Halloween","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
  
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Happy Halloween Party - playcutegames.com",
      name: ["Princess Happy Halloween Party", "princess-happy-halloween-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-happy-halloween-party/250x142.png",
      path: [
        "/game/princess-happy-halloween-party",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess-happy-halloween-party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Happy Halloween Party. Princess Anna and Elsa arrange a Halloween party at Arendelle.",
      l_dis:
        `Welcome to the Princess Happy Halloween Party. Princess Anna and Elsa arrange a Halloween party at Arendelle. They invite their friends Jasmine and Moana to the party. Before the party started, they need to be in traditional halloween costumes. Join and help the girls to pick a perfect party costumes. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Princess",
        "Halloween",
        "Anna",
        "Elsa",
        "Jasmine",
        "Moana",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Halloween Makeup Trend - playcutegames.com",
      name: ["Halloween Makeup Trends", "halloween-makeup-trends"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/halloween-makeup-trends/250x142.png",
      path: ["/game/halloween-makeup-trends", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/75c443a1-5540-5bcf-996e-948d68272909/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Halloween Makeup Trends. If you’re still seeking inspiration for your Halloween look, then stop right here.",
      l_dis:
        "Welcome to the Halloween Makeup Trends. If you’re still seeking inspiration for your Halloween look, then stop right here. Our social media influencers bring three jaw-dropping makeup looks that are going to help you steal the spotlights during the spookiest night of the year. In the Halloween Makeup Trends game for girls, you can learn from Kiki how to create a unicorn makeup look to complete your Halloween look with, from gorgeous Riri how to obtain a colorful Dia de Los Muertes inspired makeup look, and from Regina the secrets of a sophisticated fantasy makeup. Play today and have fun!",
      tag: ["Dress up", "Make Up", "Halloween", "Decoration", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls Halloween Salon - playcutegames.com",
      name: ["Rainbow Girls Halloween Salon", "rainbow-girls-halloween-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-halloween-salon/250x142.png",
      path: ["/game/rainbow-girls-halloween-salon", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/rainbow-girls-halloween-salon/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Rainbow Girls Halloween Salon game. Rainbow High school girls Skyler, Sunny, Ruby, and Violet are planning to celebrate Halloween",
      l_dis:
        `Welcome to the Rainbow Girls Halloween Salon game. Rainbow High school girls Skyler, Sunny, Ruby, and Violet are planning to celebrate Halloween in the traditional way. Join as a costume expert and browse their wardrobe to choose a cute scary outfit with a beautiful mask. Play and have fun with this cute Halloween game
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "Rainbow High", "Halloween", "Salon"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Pumpkin Carving - playcutegames.com",
      name: ["Pumpkin Carving", "pumpkin-carving"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pumpkin-carving/250x142.png",
      path: ["/game/pumpkin-carving", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1634745709/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Dry pumpkin carving is a common Halloween craft. This fall, together with the Harlequin Princess, we will decorate our yard with pumpkins using the carving technique.",
      l_dis:
        "Dry pumpkin carving is a common Halloween craft. This fall, together with the Harlequin Princess, we will decorate our yard with pumpkins using the carving technique. With a knife and a spoon, take out the pulp of the pumpkin, trying to make the walls thinner, no more than 2 cm. Now we cut out the outline of the large details of the picture. It remains to install the pumpkin and put a candle inside. Just a fabulous glow and incredibly spectacular decoration!",
      tag: ["Dress up", "Decoration", "Dl-Girls", "Kids", "Halloween"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      
      date: "2023-09-12",
      brand: "Vi games",
     
      orientation: 1,
      title: "Winter Holiday Puzzles - playcutegames.com",
      name: ["Winter Holiday Puzzles", "winter-holiday-puzzles"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/winter-holiday-puzzles/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/winter-holiday-puzzles-300.jpg",
      path: ["/game/winter-holiday-puzzles", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/29ba56c74fd24143af0af946187c57c1/?gd_sdk_referrer_url=https://playcutegames.com/game/winter-holiday-puzzles",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Winter Holiday Puzzles Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "A gorgeous selection of snow scenes, landscapes, snowmen and all things winter in a game called Winter Holiday Puzzles. Winter Holiday Puzzles is perfect online jigsaw game for those people that love winter and those who just want to pass the time to get through it! Start playing and have fun.",
        tag: ["Puzzle","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-09-12",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Angela Multiverse Fashionista - playcutegames.com",
      name: ["Angela Multiverse Fashionista", "angela-multiverse-fashionista"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-multiverse-fashionista/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-multiverse-fashionista-300.jpg",
      path: ["/game/angela-multiverse-fashionista", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/angela-multiverse-fashionista/",
      Walkthrough: "https://www.youtube.com/embed/buGjMuGUKNQ",
      s_dis:
      "Angela Multiverse Fashionista Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into Angela Multiverse Fashionista world, where style transcends dimensions! Explore her diverse wardrobe spanning galaxies, eras, and realms. From futuristic space chic to timeless historical elegance, unlock fashion inspiration that knows no bounds. Join Angela on a journey through the multiverse of couture and express your unique style across the cosmos. Your fashion adventure begins here!",
        tag: ["Angela","Multiverse","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      
      date: "2023-09-08",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Insta Celebrity Hashtag Goals - playcutegames.com",
      name: ["Insta Celebrity Hashtag Goals", "insta-celebrity-hashtag-goals"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/insta-celebrity-hashtag-goals/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/insta-celebrity-hashtag-goals-300.jpg",
      path: ["/game/insta-celebrity-hashtag-goals", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/insta-celebrity-hashtag-goals/",
      Walkthrough: "https://www.youtube.com/embed/A3gndSkZBog",
      s_dis:
      "Insta Celebrity Hashtag Goals Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Unlock your inner star with Insta Celebrity Hashtag Goals! Celebrities plan to elevate their fashionista in social media. Join and help them to complete their goal at each level. Mix and match the outfit on each level. Take a picture and post it on social media to earn more points. Have fun!",
        tag: ["Celebrity","Instagram","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      
      date: "2023-09-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Summer Firecamp Adventure - playcutegames.com",
      name: ["Summer Firecamp Adventure", "summer-firecamp-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/summer-firecamp-adventure/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/summer-firecamp-adventure-300.jpg",
      path: ["/game/summer-firecamp-adventure", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/summer-firecamp-adventure/",
      Walkthrough: "https://www.youtube.com/embed/A3gndSkZBog",
      s_dis:
      "Summer Firecamp Adventure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Summer Firecamp Adventure. A little girl planned to have a summer fire camp near the lake. Help her prepare for the trip, to reach the destination, and also help her in fishing and preparing s’mores. Sounds fun right?? Join and make this summer more unforgettable for her. Have fun!",
        tag: ["Decoration","Summer","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2022-10-29",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Little Lily Halloween Prep - playcutegames.com",
      name: ["Little Lily Halloween Prep", "little-lily-halloween-prep"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/little-lily-halloween-prep/250x142.png",
        thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/little-lily-halloween-prep-300.jpg",
      path: ["/game/little-lily-halloween-prep", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/little-lily-halloween-prep/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Little Lily Halloween Prep Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Little Lily Halloween Prep. It’s time to celebrate Halloween with Little Lily! Enjoy a memorable Halloween night with Lily and play fun-packed Halloween games and activities. Help the lily to complete her makeover, then help her with Halloween face painting. Browse her wardrobe and pick cute outfits and costumes. Finally, help her with cake decoration. A lot more to enjoy and explore this Halloween season with Little Lily. Happy Halloween!",
        tag: ["Little Lily","Dress up","Halloween","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-07-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Monster Girls High School Squad - playcutegames.com",
      name: ["Monster Girls High School Squad", "monster-girls-high-school-squad"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-girls-high-school-squad/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-girls-high-school-squad-300.jpg",
      path: ["/game/monster-girls-high-school-squad", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/monster-girls-high-school-squad/",
      Walkthrough: "https://www.youtube.com/embed/tAGWj5xle3o",
      s_dis:
      " Monster Girls High School Squad Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Unleash your inner fashionista with the Monster Girls High School Squad dress-up game! Mix and match captivating outfits, accessorize with magical charms, and create spellbinding looks for supernatural students. Dive into a world of style, friendship, and enchantment as you explore the fantastic wardrobe options and dress up the alluring characters. Join the squad and let your creativity run wild in this thrilling fashion adventure!",
        tag: ["Monster High","Dress up","Girl games","Fashion","Wednesday","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },  
    
    {
      date: "2023-07-28",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Trendy Prom Look - playcutegames.com",
      name: ["Celebrity Trendy Prom Look", "celebrity-trendy-prom-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-trendy-prom-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-trendy-prom-look-300.jpg",
      path: ["/game/celebrity-trendy-prom-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-trendy-prom-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      " Celebrity Trendy Prom Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Steal the spotlight at prom with our Celebrity Trendy Prom Look! Embrace A-list elegance with chic dresses, glamorous hairstyles, and stunning accessories. Get inspired by the red carpet fashion and create a show-stopping ensemble that will leave everyone in awe. Make your prom night a celebrity-worthy affair and feel like a star on your special evening!",
        tag: ["Celebrity","Dress up","Girl games","Fashion","Prom","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
  {
    date: "2023-07-15",
    brand: "VI",
   
    orientation: 0,
    title: "Bratz Hidden Stars - playcutegames.com",
    name: ["Bratz Hidden Stars", "bratz-hidden-stars"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/bratz-hidden-stars/250x142.png",
    thumb_big: 
      "https://cdn.cutedressup.in/games/cutedressup_new/bratz-hidden-stars-300.jpg",
    path: ["/game/bratz-hidden-stars", "https://playcutegames.com"],
    iframe:
      "https://html5.gamemonetize.co/47xp4h7w45yz0uv7dc3kz72tcoz58oe4/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    " Bratz Hidden Stars Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Bratz Hidden Stars is super fun and free to play online game for kids. Bratz Hidden Stars where you need to find all hidden stars on the stage to enter new level. Grab the loop and start looking for the stars hiiden on the Bratz images. Have fun playing.",
      tag: ["Bratz","Puzzle","Girl games","Hidden"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.7,1510,1455],
    platform:"both",
  },
  
  {
      
    date: "2023-08-16",
    brand: "playcutegames",
   
    orientation: 1,
    title: "Ellie All Around The Fashion - playcutegames.com",
    name: ["Ellie All Around The Fashion", "ellie-all-around-the-fashion"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/ellie-all-around-the-fashion/250x142.png",
    thumb_big: 
      "https://cdn.cutedressup.in/games/cutedressup_new/ellie-all-around-the-fashion-300.jpg",
    path: ["/game/ellie-all-around-the-fashion", "https://playcutegames.com"],
    iframe:
      "https://games.cutedressup.net/ellie-all-around-the-fashion/",
    Walkthrough: "https://www.youtube.com/embed/lCjzE5YGNhI",
    s_dis:
    "Ellie All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Immerse yourself in the world of Ellie All Around The Fashion! From casual chic to red-carpet glam, join Ellie’s style journey. Discover versatile outfits, trendy accessories, and expert fashion tips. Whether it’s a day out or a special event, find inspiration for every occasion. Unleash your inner fashionista and elevate your style with Ellie as your guide. Explore, experiment, and embrace a world of endless fashion possibilities.",
      tag: ["Barbie","Dress up","Girl games","Fashion","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.7,1510,1455],
    platform:"both",
  }, 
  {
    date: "2023-07-15",
    brand: "playcutegames",
   
    orientation: 0,
    title: "Celebrity AfroPunk Street Style - playcutegames.com",
    name: ["Celebrity AfroPunk Street Style", "celebrity-afropunk-street-style"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-afropunk-street-style/250x142.png",
    thumb_big: 
      "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-afropunk-street-style-300.jpg",
    path: ["/game/celebrity-afropunk-street-style", "https://playcutegames.com"],
    iframe:
      "https://games.cutedressup.net/celebrity-afropunk-street-style/",
    Walkthrough: "https://www.youtube.com/embed/n3y9Oi3uJ0s",
    s_dis:
    " Celebrity AfroPunk Street Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Step into the world of Celebrity AfroPunk Street Style and discover a vibrant fusion of fashion, music, and self-expression. Explore captivating looks, bold outfits, and cutting-edge trends as celebrities showcase their unique personal style. Immerse yourself in this electrifying fashion movement and get inspired to make a statement of your own.",
      tag: ["Celebrity","Dress up","Girl games","Fashion","Afro","Core","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.7,1510,1455],
    platform:"both",
  },
    {
      date: "2023-07-11",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Ellie Bachelorette Party - playcutegames.com",
      name: ["Ellie Bachelorette Party", "ellie-bachelorette-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-bachelorette-party/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-bachelorette-party-300.jpg",
      path: ["/game/ellie-bachelorette-party", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ellie-bachelorette-party/",
      Walkthrough: "https://www.youtube.com/embed/wmp_ZhL1dBE",
      s_dis:
      " Ellie Bachelorette Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Ellie Bachelorette Party! It’s time to celebrate with her closest friends before the big day. Get ready for a night of laughter, dancing, and unforgettable memories. From fun games to fabulous surprises, this party is all about showering Ellie with love and creating an evening she’ll treasure forever. Join us and let’s make this celebration truly extraordinary!",
        tag: ["Barbie","Dress up","Girl games","Fashion","Party","Bachelor","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-07-28",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Holographic Futuristic Fashion - playcutegames.com",
      name: ["BFFs Holographic Futuristic Fashion", "bffs-holographic-futuristic-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-holographic-futuristic-fashion/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-holographic-futuristic-fashion-300.jpg",
      path: ["/game/bffs-holographic-futuristic-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-holographic-futuristic-fashion/",
      Walkthrough: "https://www.youtube.com/embed/Cng2bSteVWQ",
      s_dis:
      " BFFs Holographic Futuristic Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into a dazzling world of BFFs Holographic Futuristic Fashion! Explore a realm of shimmering outfits, holographic accessories, and futuristic styles. Join these best friends on their fashion journey, blending technology and trend to create mesmerizing looks. Get inspired, mix and match, and embrace the cutting-edge allure of holographic fashion!",
        tag: ["LOL Surprise","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-07-07",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity BarbieCore Aesthetic Look - playcutegames.com",
      name: ["Celebrity BarbieCore Aesthetic Look", "celebrity-barbiecore-aesthetic-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-barbiecore-aesthetic-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-barbiecore-aesthetic-look-300.jpg",
      path: ["/game/celebrity-barbiecore-aesthetic-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-barbiecore-aesthetic-look/",
      Walkthrough: "https://www.youtube.com/embed/VBkmMcijSOA",
      s_dis:
      "Celebrity BarbieCore Aesthetic Look is live! Play for free at PlayCuteGames and style six stars in fabulous BarbieCore outfits and stunning accessories!",
      l_dis:
        "Welcome to Celebrity BarbieCore Aesthetic Look. Step into the glamorous world of celebrity fashion with this game! Six famous stars are inspired by BarbieCore’s iconic style and are eager to try her trends. From dazzling red carpet gowns to chic streetwear, they’ll explore the diverse range of BarbieCore aesthetics. Experiment with stunning hairstyles, flawless makeup, and luxurious accessories. Become their personal stylist and guide them to embody the essence of BarbieCore’s fashion-forward allure. Unleash your creativity and make them shine like true style icons!",
        tag: ["Celebrity","Barbie","Dress up","Girl games","Fashion","Core","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
  
    {
      date: "2023-07-04",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs High School First Date Look - playcutegames.com",
      name: ["BFFs High School First Date Look", "bffs-high-school-first-date-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-high-school-first-date-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-high-school-first-date-look-300.jpg",
      path: ["/game/bffs-high-school-first-date-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-high-school-first-date-look/",
      Walkthrough: "https://www.youtube.com/embed/IzmLYMPI94E",
      s_dis:
      " BFFs High School First Date Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs High School First Date Look. Get ready to style BFFs for their exciting high school first date! Mix and match trendy outfits, hairstyles, and accessories to create the perfect look that will make them feel confident and impress their date. Can you help them to find the ultimate outfit that captures their unique style and personality? Let the fashion fun begin!",
        tag: ["Barbie","Dress up","Girl games","Fashion","School","Date","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-07-05",
      brand: "GD",
     
      orientation: 0,
      title: "Jewels Kyodai Mahjong - playcutegames.com",
      name: ["Jewels Kyodai Mahjong", "jewels-kyodai-mahjong"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/jewels-kyodai-mahjong/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/jewels-kyodai-mahjong-300.jpg",
      path: ["/game/jewels-kyodai-mahjong", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/6fcd1f12261346e5bb6302fa08b1de8d/?gd_sdk_referrer_url=https://playcutegames.com/game/jewels-kyodai-mahjong",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      " Jewels Kyodai Mahjong Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Jewels Kyodai Mahjong is one of the most anticipated mahjong tile match game releases of this year! It combines the popular mahjong tile match gameplay with the mystical theme of the legendary Jewels Blitz Match 3 Game. Jewels Kyodai Mahjong features a library of more than 60 beautiful puzzles together with a a puzzle selector which lets you choose any puzzle you like or explore new puzzles with the 'Shuffle mode'! Play Jewels Kyodai Mahjong every day to sharpen your mind and focus!`,
        tag: ["Skill","Puzzle"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-07-05",
      brand: "GD",
     
      orientation: 1,
      title: "DRAW 2 SAVE - STICKMAN RESCUE - playcutegames.com",
      name: ["DRAW 2 SAVE - STICKMAN RESCUE", "draw-2-save-stickman-rescue"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/draw-2-save-stickman-rescue/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/draw-2-save-stickman-rescue-300.jpg",
      path: ["/game/draw-2-save-stickman-rescue", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/32f46c72af894eafbe5ef1400c0b066a/?gd_sdk_referrer_url=https://playcutegames.com/game/draw-2-save-stickman-rescue",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      " DRAW 2 SAVE - STICKMAN RESCUE Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Draw 2 Save - Stickman Rescue - draw one line to save the Stickman from the threat! Are you a good draftsman or Do you want to test your creativity? Are you a puzzle lover? Now you have a good chance! Draw a line to help the little man and protect him from swords, bullets, bombs... and many other life-threatening attacks! You are free to draw any shelters and defenses to help the little man survive. Learn to draw lines creatively, improve your logic and develop your brain!s",
        tag: ["Skill","Puzzle"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2024-08-12",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Ellie And Friends Art Hoe Aesthetic - playcutegames.com",
      name: ["Ellie And Friends Art Hoe Aesthetic", "ellie-and-friends-art-hoe-aesthetic"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-art-hoe-aesthetic/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-art-hoe-aesthetic/520x295.png",
      path: ["/game/ellie-and-friends-art-hoe-aesthetic", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/ellie-and-friends-art-hoe-aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/LPXMCq2iwAg",
      s_dis:
      "Ellie And Friends Art Hoe Aesthetic Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Ellie And Friends Art Hoe Aesthetic is all about exploring creativity through unique fashion and art! Dress them up in colorful outfits, mix and match funky accessories, and dive into the world of artistic expression. From painting and drawing to choosing the perfect look, this game lets you celebrate individuality and artistic flair with Ellie and her friends. Get ready to unleash your inner artist!",
        tag: ["Barbie","Ellie","Girl games","Dress up","Fashion","Exclusive"],
      hint: `Use your left mouse button `,
      rating:[4.4,1210,114],  
    },{
      date: "2023-07-28",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Monster Girls Glam Goth Style - playcutegames.com",
      name: ["Monster Girls Glam Goth Style", "monster-girls-glam-goth-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-girls-glam-goth-style/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-girls-glam-goth-style-300.jpg",
      path: ["/game/monster-girls-glam-goth-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/monster-girls-glam-goth-style/",
      Walkthrough: "https://www.youtube.com/embed/4cc9a6Ou9Aw",
      s_dis:
      "Monster Girls Glam Goth Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Discover the dark allure of Monster Girls Glam Goth Style! Embrace gothic charm, mystical fashion, and edgy elegance as enchanting characters flaunt their unique looks. Dive into a world of captivating outfits, hauntingly beautiful accessories, and mesmerizing aesthetics. Unleash your inner goth and explore a fashion realm like no other, where the mystical meets the glamorous. Join the bewitching squad and express your dark and stylish side with this new game.",
        tag: ["Monster High","Dress up","Girl games","Fashion","Prom","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      
      date: "2023-09-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BTS Signature Fashion Style - playcutegames.com",
      name: ["BTS Signature Fashion Style", "bts-signature-fashion-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bts-signature-fashion-style/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bts-signature-fashion-style-300.jpg",
      path: ["/game/bts-signature-fashion-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bts-signature-fashion-style/",
      Walkthrough: "https://www.youtube.com/embed/jr3PcLAgCvY",
      s_dis:
      "BTS Signature Fashion Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the world of BTS Signature Fashion Style. Explore the iconic looks of the global superstars and discover how to incorporate their trendsetting fashion into your own wardrobe. From stage outfits to everyday streetwear, get inspired and elevate your style with a touch of BTS’s unparalleled flair. Join us on a fashion journey that transcends borders and celebrates individuality with the influence of these K-pop icons.",
        tag: ["BTS","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
     {
      date: "2022-10-11",
      brand: "GD",
     
      orientation: 1,
      title: "Tasty Drop - playcutegames.com ",
      name: ["Tasty Drop", "tasty-drop"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/tasty-drop/250x142.png",
      path: ["/game/tasty-drop", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/89f190bce5de4a6f9831f6a16d721b74/?gd_sdk_referrer_url=https://playcutegames.com/game/tasty-drop",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Tasty Drop Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Tasty Drop is a fun casual game where all you have to do is to drop the last element of a delicious dish. The game concept might look simple, but it is actually quite challenging. Experience the unique physics system that provides the unique look and feel of the game.`,
      tag: ["Puzzle","Skill","Girl","Simulation"],
      hint: `
      <ul class="gamelist">
      <li class="para description">Tasty Drop is a simulation game that's great for players of all ages. Help two mice construct an outdoor oven before they bake a few pizzas in it.</li>
      </ul>
      `,
      rating:[4.4,1210,114],
    },
     {
      date: "2022-10-11",
      brand: "Babygames",
     
      orientation: 1,
      title: "Makeup Slime Cooking Master 2 - playcutegames.com ",
      name: ["Makeup Slime Cooking Master 2", "makeup-slime-cooking-master-2"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/makeup-slime-cooking-master-2/250x142.png",
      path: ["/game/makeup-slime-cooking-master-2", "https://playcutegames.com"],
      iframe:
        "https://www.babygames.com/games/Makeup-Slime-Cooking-Master-2/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Makeup Slime Cooking Master 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Makeup Slime Cooking Master 2 is an online game that you can play on Yiv for free. Hello, young chefs! Are you craving delicious fast food? Are you craving delicious fast food? Come and play Happy Kids Burger Maker. Make your own burgers, fries chips, and cold drinks in the kitchen. Practice your cooking skills, learn new cooking techniques and become the best junior head chef in this fun kitchen cooking game.`,
      tag: ["Cooking","Camp","Skill","Girl","Simulation"],
      hint:  `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
     {
      date: "2022-10-11",
      brand: "Babygames",
     
      orientation: 1,
      title: "Happy Kids Burger Maker - playcutegames.com ",
      name: ["Happy Kids Burger Maker", "happy-kids-burger-maker"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/happy-kids-burger-maker/250x142.png",
      path: ["/game/happy-kids-burger-maker", "https://playcutegames.com"],
      iframe:
        "https://www.babygames.com/games/Happy-Kids-Burger-Maker/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Happy Kids Burger Maker Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Happy Kids Burger Maker is an online game that you can play on Yiv for free. Hello, young chefs! Are you craving delicious fast food? Are you craving delicious fast food? Come and play Happy Kids Burger Maker. Make your own burgers, fries chips, and cold drinks in the kitchen. Practice your cooking skills, learn new cooking techniques and become the best junior head chef in this fun kitchen cooking game.`,
      tag: ["Cooking","Camp","Skill","Girl","Simulation"],
      hint:  `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },{
      
      date: "2023-08-30",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Ellie And Friends Pre Fall Outfit - playcutegames.com",
      name: ["Ellie And Friends Pre Fall Outfit", "ellie-and-friends-pre-fall-outfit"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-pre-fall-outfit/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-pre-fall-outfit-300.jpg",
      path: ["/game/ellie-and-friends-pre-fall-outfit", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ellie-and-friends-pre-fall-outfit/",
      Walkthrough: "https://www.youtube.com/embed/k4DMuC3rw4o",
      s_dis:
      "Ellie And Friends Pre Fall Outfit Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Transition into fall with the Ellie and Friends Pre-Fall Outfit collection. Explore a fusion of style and comfort as they curate trendy ensembles featuring cozy layers, versatile accessories, and seasonal hues. Embrace the upcoming season with fashion-forward choices inspired by the experts themselves! Join and have fun!",
        tag: ["Barbie","Fall","Autumn","Girl games","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-07-15",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Monster High Signature Style - playcutegames.com",
      name: ["Monster High Signature Style", "monster-high-signature-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-high-signature-style/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-high-signature-style-300.jpg",
      path: ["/game/monster-high-signature-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/monster-high-signature-style/",
      Walkthrough: "https://www.youtube.com/embed/hQK2XzacAeQ",
      s_dis:
      " Monster High Signature Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Embrace the supernatural allure of Monster High Signature Style! Dive into a world where ghoulish fashion takes center stage. Discover unique outfits, hauntingly beautiful accessories, and the signature style of your favorite monster characters. Unleash your inner monster and join the stylish haunting fun!",
        tag: ["Monster High","Dress up","Girl games","Fashion","Signature","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-06-29",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Barbie Summer Spa And Beauty Salon - Barbie Spa Game",
      name: ["Ellie Summer Spa And Beauty Salon", "ellie-summer-spa-and-beauty-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-summer-spa-and-beauty-salon/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-summer-spa-and-beauty-salon-300.jpg",
      path: ["/game/ellie-summer-spa-and-beauty-salon", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ellie-summer-spa-and-beauty-salon/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Discover Barbie Summer Spa & Beauty Salon, a fun beauty game! Pamper clients with soothing massages and refreshing facials in a vibrant, creative world!",
      l_dis:
        `
        In Barbie Summer Spa & Beauty Salon, you’ll immerse yourself in a vibrant world filled with creativity and style. As the owner of this fabulous spa and beauty salon, your mission is to pamper your clients and help them look their best. From soothing massages to refreshing facials, you’ll offer a variety of luxurious treatments designed to rejuvenate and relax.
        <br><br>
        But that's not all! Get ready to unleash your inner stylist by crafting stunning makeovers. Choose from a plethora of hairstyles, makeup options, and fashionable outfits to create unique looks for each client. Whether it's a beach-ready style or a glamorous evening ensemble, the possibilities are endless!
        <br><br>
        The game offers intuitive controls that make it easy to navigate through different beauty treatments and styling options. Complete various challenges to earn rewards and unlock new products for your salon. As you progress, you can expand your spa services and elevate your salon to new heights.
        <br><br>
        With its colorful graphics, engaging gameplay, and the beloved Barbie brand, this game is perfect for players of all ages. Dive into the glamorous world of Barbie Summer Spa & Beauty Salon and become the ultimate beauty guru today!
        `,
        tag: ["Barbie","Dress up","Girl games","Fashion","Salon","Hair","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2022-08-05",
      brand: "Crazy games",
      orientation: 0,
      title: "UNO Online - playcutegames.com",
      name: ["UNO Online", "uno-online"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/uno-online/250x142.png",
      path: ["/game/uno-online", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/uno-online/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "UNO Online Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Uno Online lets you play the popular Uno card game for free in your web browser. The aim of the game is to be the first player with no cards, similar to other Crazy Eights style card games. Play Uno Online with 2, 3, or 4 players.",
      tag: ["Crazy games","Multiplayer","Card","Puzzle","Challenge","Online games"],
      hint: `
      There are various strategies you can use to maximize your chances of winning at Uno. The main principle is to get rid of all your cards before everyone else. You can do that by matching the color or number of the card placed down before yours. If you don't have a playable card, you must draw cards from the pile until you do.
      <br><br>When you have one card left, press the "1" button, otherwise you will be punished with two penalty cards!`,
      rating:[4.2,110,14],
    },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Durak classic - playcutegames.com",
    name: ["Durak classic", "durak-classic"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/durak-classic/250x142.png",
    path: ["/game/durak-classic", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/174460?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/durak-classic.mp4",
    Walkthrough: "",
    s_dis: "Play the most popular card game against real opponents, challenge other players and prove that you are the real winner!",
    l_dis:`The most popular card game in the world, classic Klondike Solitaire, by Conifer is the new best way to play the game you know and love for free！Is a great way to relax and train your brain. With beautiful graphics, fun animations, and offline play, Solitaire is the best game to pass the time.`,
    tag: ["Card","Board"],
    hint: `
    The goal of the game is to be the first to get rid of all your cards. The last player who does not get rid of the cards remains in the \"fools\".\n\nThe player with the lowest trump card goes first, then clockwise. You can move any number of cards of the same rank.\n​✔️​The first \"hang up\" is 5 cards.\n​✔️​The first round cannot be transferred.\n\nThe player under whom they go can cover the cards either with the highest card of the same suit, or with a card of the trump suit.\n\nThe walking player can flip cards that match the value of any card on the table. You cannot throw more cards than the batting player has left and more than six cards, even if the player has more cards in his hand.\n\nIf the player under whom they go fought back, then he goes next, but first all players get up to 6 cards, starting with the player who went first and ending with the player who fought back.\n\nIf the player could not fight back, then he accepts all the cards, and the player on the left goes next.
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Solitaire - playcutegames.com",
    name: ["Solitaire", "solitaire"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/solitaire/250x142.png",
    path: ["/game/solitaire", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/182305?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis: "Solitaire Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`The most popular card game in the world, classic Klondike Solitaire, by Conifer is the new best way to play the game you know and love for free！Is a great way to relax and train your brain. With beautiful graphics, fun animations, and offline play, Solitaire is the best game to pass the time.`,
    tag: ["Card","Board"],
    hint: `
    FEATURES:\r\n\r\nOriginal Classic Solitaire: \r\n♥️ Play Klondike solitaire draw 1 & draw 3 modes\r\n♥️ Choose scoring mode: Standard or Vegas solitaire scoring\r\n\r\nDaily Challenge:\r\n♠️ Challenge yourself with the hard mode\r\n♠️ Receive special mark everyday\r\n♣️ Record every achievement you made\r\n♣️ Stay Sharp and see every improvement\r\n\r\nUser Friendly Experience:\r\n♦️ Enjoy clean and comfortable visual design\r\n♦️ Use new props to make your gameplay more easy\r\n♦️ Play Offline: Play random deals everywhere\r\n♦️ Supports left-handed play
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Kings & Queens - playcutegames.com",
    name: ["Kings & Queens", "kings-and-queens"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/kings-and-queens/250x142.png",
    path: ["/game/kings-and-queens", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/173184?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/kings-and-queens.mp4",
    Walkthrough: "",
    s_dis: "Kings & Queens Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`It’s a grand time to harvest some solitaire fun! If you enjoy card games and the words Pyramid, Klondike, Spider mean something to you, or even if you’re just on the verge of discovering solitaire puzzles, Kings & Queens welcome you on your journey! This beautiful tripeaks saga has a lot to offer.
<br><br>
Become an honorable member of Kings & Queens club and enjoy its never-ending perks: – Savor picturesque landscapes while solving challenging puzzles! – Complete countless tri peaks levels to become the solitaire master! – Learn the sophisticated art of playing the wildcards and use it to your advantage! – Try to keep up with mischievous magic cards and to pick enchanted locks! – Uncover hidden golden treasures and collect priceless rubies on your journey!
<br><br>
It’s easy to learn and hard to let go of! Get ready for your journey into the world of tripeaks solitaire puzzles together with Kings & Queens!`,
    tag: ["Card","Board"],
    hint: `
    Beat levels to collect cards and open new locations!<br>
To complete a level collect a card one higher or lower than the one in the deck.<br>
Receive stars for each completed level and use them to unlock different picturesque sights.<br>
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Durak - playcutegames.com",
    name: ["Durak", "durak"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/durak/250x142.png",
    path: ["/game/durak", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/99290?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/durak.mp4",
    Walkthrough: "",
    s_dis: "Durak Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`Durak means fool and it is the most popular card game in Russia and in many post-Soviet states. The object of the game is to avoid being the last player with cards.`,
    tag: ["Card","Board"],
    hint: `
    Begin by playing any card from hand.\nTo beat off the attack the defender will have to beat this and all subsequent attacking cards. A card which is not a trump can be beaten by playing a higher card of the same suit, or by any trump. A trump card can only be beaten by playing a higher trump.\nIn the Transfer Durak while no cards are beaten the defender can transfer defender role to the next player by playing a card with the same rank as the cards on the table.\nCard under deck determines trump suit.\nThe attacker can continue the attack by playing another card, which is of the same rank as some card already played during the current bout – either an attack card or a card played by the defender.\nWhen an attack is beaten off, the defender becomes the attacker for the next bout, and the player to the new attacker's left is the new defender. Otherwise defender's left is the new defender.\nThe last player left holding cards is the loser.
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "4 Colors World Tour - playcutegames.com",
    name: ["4 Colors World Tour", "4-colors-world-tour"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/4-colors-world-tour/250x142.png",
    path: ["/game/4-colors-world-tour", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/269910?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/4-colors-world-tour.mp4",
    video:"",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "4 Colors World Tour Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Pack your bags and go in search of cute mascots around the world in this special version of the game Uno!\n\nPlay alone or challenge up other players from around the world!
  
      `,
    tag: ["Card","Board"],
    hint: `
    Match cards by color or number and get rid of them first. \n\nRemember, when you only have one card left, otherwise you will have to draw two penalty cards.
    
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Spider Solitaire 2024 - playcutegames.com",
    name: ["Spider Solitaire 2024", "spider-solitaire-2024"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/spider-solitaire-2024/250x142.png",
    path: ["/game/spider-solitaire-2024", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/269910?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/spider-solitaire-2024.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Spider Solitaire 2024 is here! Play the best online card game for free at PlayCuteGames. Arrange the deck from king to ace and master the challenge!",
    l_dis:
      `Spider Solitaire 2024 is a well-known card game played by one person. You need to arrange all the cards in a 52-card deck in a certain way. The first should be the king, then the queen, etc. to reduce the card's rank. The last to be placed is the ace.
      <br><br>
      Rules of the game: The game uses a deck of 52 cards. These cards must be laid out in a certain sequence. The king is placed first, then the queen, etc. to reduce the card's rank. The last to be placed is the ace. In this case, the cards must be of the same suit, so that the collected stack of cards is considered collected and moved to the base. But in the process of collecting cards, they can be placed on other suits, the main thing is to respect the seniority of card values. Those. A 9 can only be placed on a ten, and not vice versa.
      <br><br>
      In the game you can customize the background color, card back, card type and some other functions.
  
      `,
    tag: ["Card","Board"],
    hint: `
    Select the difficulty level you want, it's best to start with a simple difficulty level. Especially if you haven't played this game before to understand the essence. Next, to move the map, double-click on it with the mouse button or tap on the smartphone screen. You can also move a card by holding a tap on this card and moving it around the screen.
    
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Solitaire: classic - playcutegames.com",
    name: ["Solitaire: classic", "solitaire-classic"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/solitaire-classic/250x142.png",
    path: ["/game/solitaire-classic", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/191706?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/solitaire-classic.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Solitaire: classic Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Solitaire is a great opportunity to train your brain, improve your problem-solving skills, relax and have fun! Return to the timeless PC classic as it comes to your phone so that you can play it anytime, anywhere!
      <br>
      Play card game anyway at anytime
      <h2 class="H2">Featuring:</h2>
      <ul>
      <li>Detailed player statistics</li>
      <li>Random or Winnable solitaire decks</li>
      <li>Tablet support</li>
      <li>Beautiful HD Graphics</li>
      </ul>
      `,
    tag: ["Card","Board"],
    hint: `
    <ul>
    <li>To solve the Klondike deal, you need to tap, drag or drop to sort cards of 4 suits in ascending order, Ace 2, ... Jack, Queen, King.</li>
    <li>You will have 7 card column options where you can move one card or consecutive cards from one column to another.</li>
    <li>King or any consecutive cards starting with K can be moved to the empty columns.</li>
    <li>Click the draw pile and find the card you want if you get stuck.</li>
    </ul>
    
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Solitaire Classic Klondike - playcutegames.com",
    name: ["Solitaire Classic Klondike", "solitaire-classic-klondike"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/solitaire-classic-klondike/250x142.png",
    path: ["/game/solitaire-classic-klondike", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/180471?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/solitaire-classic-klondike.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Rediscover Solitaire Classic Klondike at PlayCuteGames! Enjoy the best online card game with stunning visuals and unique challenges. Play for free now!",
    l_dis:
      `Solitaire Classic Klondike is a game most of us have played at some time - here we have versions of this game made with beautiful game boards and eye-catching pieces. We've carefully designed the game to have a smooth and modern feel to it and threw in some classic, Solitaire Classic Klondike game elements we all love. FEATURE- Undo moves - Track your moves and time - Enjoy the clean design, animations, and sounds - Enjoy nice & smooth gameplay that'll keep you coming for more - Enjoy special challenges & surprises - Play on your mobile phone, tablet or desktop - And many more! Enjoy a game by yourself, or save your score to play card games with friends!
      `,
    tag: ["Card","Board"],
    hint: `
    Remove all cards from the field.<br>
Decreasing sequences of the same suit from king to ace are removed from the field.<br>
On the card, you can put a card on a unit of lower value, without taking into account the suit, along with the cards lying on it.<br>
The descending sequence of cards of the same suit can be dragged.<br>
You can put any card in an empty cell.<br>
A click on the deck lays out another row of cards.
If at least one game cell is free, a new row of cards cannot be laid out. 
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Pastaria - playcutegames.com",
    name: ["Papa’s Pastaria", "papas-pastaria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-pastaria/250x142.png",
    path: ["/game/papas-pastaria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-pastaria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-pastaria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Take charge in Papa’s Pastaria at PlayCuteGames! Play online for free and craft mouthwatering pasta dishes while managing your bustling restaurant!",
    l_dis:
      `Papa’s Pastaria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own pasta restaurant. Create delicious pasta dishes by cooking pasta, adding sauces, and customizing them with various toppings. Serve your customers quickly to earn tips and upgrade your restaurant with new equipment and ingredients. Can you become the ultimate pasta master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Cupcakeria - playcutegames.com",
    name: ["Papa’s Cupcakeria", "papas-cupcakeria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-cupcakeria/250x142.png",
    path: ["/game/papas-cupcakeria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-cupcakeria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-cupcakeria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Cupcakeria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Cupcakeria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own cupcake bakery. Create delicious cupcakes by mixing batter, baking them in the oven, frosting them with various flavors, and decorating them with toppings. Serve your customers quickly to earn tips and upgrade your bakery with new equipment and ingredients. Can you become the ultimate cupcake master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Hot Doggeria - playcutegames.com",
    name: ["Papa’s Hot Doggeria", "papas-hot-doggeria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-hotdoggeria/250x142.png",
    path: ["/game/papas-hot-doggeria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-hotdoggeria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-hot-doggeria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Hot Doggeria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Hot Doggeria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own hot dog stand. Create delicious hot dogs by grilling the sausages, adding toppings, and customizing them with various condiments. Serve your customers quickly to earn tips and upgrade your stand with new equipment and ingredients. Can you become the ultimate hot dog master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Wingeria - playcutegames.com",
    name: ["Papa’s Wingeria", "papas-wingeria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-wingeria/250x142.png",
    path: ["/game/papas-wingeria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-wingeria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-wingeria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Wingeria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Wingeria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own chicken wing restaurant. Create delicious chicken wings by frying them to perfection, adding sauces, and customizing them with various toppings. Serve your customers quickly to earn tips and upgrade your restaurant with new equipment and ingredients. Can you become the ultimate chicken wing master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Pancakeria - playcutegames.com",
    name: ["Papa’s Pancakeria", "papas-pancakeria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-pancakeria/250x142.png",
    path: ["/game/papas-pancakeria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-pancakeria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-pancakeria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Whip up delicious pancakes in Papa’s Pancakeria! Play this exciting online game at PlayCuteGames for free, and prove you’re the best pancake chef!",
    l_dis:
      `Papa’s Pancakeria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own pancake restaurant. Create delicious pancakes by flipping batter on the griddle, adding toppings, and drizzling syrups. Serve your customers quickly to earn tips and upgrade your restaurant with new equipment and ingredients. Can you become the ultimate pancake master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Scooperia - playcutegames.com",
    name: ["Papa’s Scooperia", "papas-scooperia"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-scooperia/250x142.png",
    path: ["/game/papas-scooperia", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-scooperia/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-scooperia.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Scooperia Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Scooperia is a fun and addictive time management game where you take on the role of a young entrepreneur running your own ice cream shop. Create delicious ice cream sundaes by scooping ice cream, adding toppings, and drizzling sauces. Serve your customers quickly to earn tips and upgrade your shop with new equipment and ingredients. Can you become the ultimate ice cream master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Donuteria - playcutegames.com",
    name: ["Papa’s Donuteria", "papas-donuteria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-donuteria/250x142.png",
    path: ["/game/papas-donuteria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-donuteria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-donuteria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Manage your own donut shop in Papa’s Donuteria at PlayCuteGames! Play this free online game and craft the best donuts to earn tips and grow your shop!",
    l_dis:
      `Papa’s Donuteria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own donut shop. Create delicious donuts by frying dough, adding fillings, and frosting them with various glazes and toppings. Serve your customers quickly to earn tips and upgrade your shop with new equipment and ingredients. Can you become the ultimate donut master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Cheeseria - playcutegames.com",
    name: ["Papa’s Cheeseria", "papas-cheeseria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-cheeseria/250x142.png",
    path: ["/game/papas-cheeseria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-cheeseria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-cheeseria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Cheeseria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Cheeseria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own cheese shop. Create delicious cheese sandwiches, grilled cheese, and cheese platters by selecting different types of cheese, adding toppings, and customizing them with various condiments. Serve your customers quickly to earn tips and upgrade your shop with new equipment and ingredients. Can you become the ultimate cheese master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Bakeria - playcutegames.com",
    name: ["Papa’s Bakeria", "papas-bakeria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-bakeria/250x142.png",
    path: ["/game/papas-bakeria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-bakeria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-bakeria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Bakeria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Bakeria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own bakery. Create delicious baked goods like bread, pastries, and cakes by mixing dough, shaping it, baking it in the oven, and frosting or decorating them with various toppings. Serve your customers quickly to earn tips and upgrade your bakery with new equipment and ingredients. Can you become the ultimate baker master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Sushiria - playcutegames.com",
    name: ["Papa’s Sushiria", "papas-sushiria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-sushiria/250x142.png",
    path: ["/game/papas-sushiria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-sushiria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-sushiria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Sushiria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Sushiria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own sushi restaurant. Create delicious sushi rolls by selecting different types of fish, adding rice and seaweed, and customizing them with various toppings and sauces. Serve your customers quickly to earn tips and upgrade your restaurant with new equipment and ingredients. Can you become the ultimate sushi master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Spider Solitaire (1, 2, and 4 suits) - playcutegames.com",
    name: ["Spider Solitaire (1, 2, and 4 suits)", "spider-solitaire-1-2-and-4-suits"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/spider-solitaire-1-2-and-4-suits/250x142.png",
    path: ["/game/spider-solitaire-1-2-and-4-suits", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/96447?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/spider-solitaire-1-2-and-4-suits.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Spider Solitaire (1, 2, and 4 suits) Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `The game contains a choice of three Spider variants with 1, 2 and 4 suits. Win and increase your grade in every variant.
      `,
    tag: ["Card"],
    hint: `
    Remove all cards from the field.<br>
Decreasing sequences of the same suit from king to ace are removed from the field.<br>
On the card, you can put a card on a unit of lower value, without taking into account the suit, along with the cards lying on it.<br>
The descending sequence of cards of the same suit can be dragged.<br>
You can put any card in an empty cell.<br>
A click on the deck lays out another row of cards.
If at least one game cell is free, a new row of cards cannot be laid out. 
    `,
    rating:[4.2,110,14],
  }, 
     {
      date: "2022-10-11",
      brand: "Yiv",
     
      orientation: 0,
      title: "Princess Dream Bakery - playcutegames.com ",
      name: ["Princess Dream Bakery", "princess-dream-bakery"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/princess-dream-bakery/250x142.png",
      path: ["/game/princess-dream-bakery", "https://playcutegames.com"],
      iframe:
        "https://www.yiv.com/games/Princess-Dream-Bakery/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Princess Dream Bakery Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Princess Dream Bakery is an online game that you can play on Yiv for free. Little princess loves cooking desserts. One day her parents are going to travel. The little princess decides to run the cake bakery shop on her own. As her friend, come on and help her cooking delicious desserts. You will experience the cooking processes of the cakes, swiss rolls and fruit glutinous rice balls in this game. Don't forget to decorate the desserts with cute accessories. Enjoy!`,
      tag: ["Cooking","Camp","Skill","Girl","Simulation"],
      hint:  `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
     {
      date: "2022-10-11",
      brand: "GD",
     
      orientation: 0,
      title: "Funny Cooking Camp - playcutegames.com ",
      name: ["Funny Cooking Camp", "funny-cooking-camp"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/funny-cooking-camp/250x142.png",
      path: ["/game/funny-cooking-camp", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/492bf68e9e054b1da34b25e5cbc24a40/?gd_sdk_referrer_url=https://playcutegames.com/game/funny-cooking-camp",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Looking for a fun and interesting fishing game? Try Funny Cooking Camp Game and experience the excitement of catching a variety of fish species in different locations.",
      l_dis:
        `The adorable mice in Funny Cooking Camp love pizza almost as much as they love the great outdoors! Tag along with them in this cute camping game.
  
        The mice will be building an outdoor oven out of bricks at their campsite in this cooking game. They'll definitely need your help while they put it together. Once that's done, it'll be time to get started on the pizzas!`,
      tag: ["Cooking","Camp","Skill","Girl","Simulation"],
      hint: `
      <ul class="gamelist">
      <li class="para description">Funny Cooking Camp is a simulation game that's great for players of all ages. Help two mice construct an outdoor oven before they bake a few pizzas in it.</li>
      <li class="para description">LEFT CLICK to select tools, prepare the pizzas, and more.</li>
      </ul>
      `,
      rating:[4.4,1210,114],
    },
     {
      date: "2022-10-11",
      brand: "GD",
     
      orientation: 0,
      title: "Pizza Real Life Cooking - playcutegames.com ",
      name: ["Pizza Real Life Cooking", "pizza-real-life-cooking"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/pizza-real-life-cooking/250x142.png",
      path: ["/game/pizza-real-life-cooking", "https://playcutegames.com"],
      iframe:
        "https://html5.GameDistribution.com/120092e1e29c48d8ba1c7d6fc74d277a/?gd_sdk_referrer_url=https://playcutegames.com/game/pizza-real-life-cooking",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Looking for a fun and interesting fishing game? Try Pizza Real Life Cooking Game and experience the excitement of catching a variety of fish species in different locations.",
      l_dis:
        `You can bake a virtual pizza in this realisitic cooking game.All of the ingredients are waiting for you in the kitchen. Chop up toppings like olives, peppers and tomatoes before you put them on the pizza and place it in the oven.`,
      tag: ["Cooking","Pizza","Skill","Girl"],
      hint: `
      <ul class="gamelist">
      <li class="para description">Use the mouse to play</li>
      </ul>
      `,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-07-22",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Wednesday Addams Style - playcutegames.com",
      name: ["Celebrity Wednesday Addams Style", "celebrity-wednesday-addams-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-wednesday-addams-style/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-wednesday-addams-style-300.jpg",
      path: ["/game/celebrity-wednesday-addams-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-wednesday-addams-style/",
      Walkthrough: "https://www.youtube.com/embed/Sbp6Xhy3qqs",
      s_dis:
      " Celebrity Wednesday Addams Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Indulge in the iconic and bewitching Celebrity Wednesday Addams Style! Embrace the darkly chic aesthetics, explore mesmerizing outfits, and channel your inner gothic elegance. Discover how celebrities rock the macabre charm of Wednesday Addams with a modern twist. Unleash your inner darkness and redefine elegance with a touch of the mysterious.",
        tag: ["Celebrity","Dress up","Girl games","Fashion","Wednesday","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
     {
      date: "2022-10-11",
      brand: "GD",
     
      orientation: 0,
      title: "Grandma Recipe Nigiri Sushi - playcutegames.com",
      name: ["Grandma Recipe Nigiri Sushi", "grandma-recipe-nigiri-sushi"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/grandma-recipe-nigiri-sushi/250x142.png",
      path: ["/game/grandma-recipe-nigiri-sushi", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/4d3061a523dc4753b7cc45b9d1e68be3/?gd_sdk_referrer_url=https://playcutegames.com/game/grandma-recipe-nigiri-sushi",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Looking for a fun and interesting fishing game? Try Grandma Recipe Nigiri Sushi Game and experience the excitement of catching a variety of fish species in different locations.",
      l_dis:
        `Grandma Recipe: Nigiri Sushi is a fun cooking game in which you get to prepare some delicious sushi using grandma’s expert recipes.
  
        The main character has invited some of her friends over for dinner. She wants to prepare a meal that will really wow them. What better dish to choose than Grandma’s best nigiri sushi?`,
      tag: ["Cooking","Recipe","Girl"],
      hint: `
      <ul class="gamelist">
      <li class="para description">Use the mouse to play</li>
      <li class="para description">Follow the directions and trace the arrows on the screen</li>
      <li class="para description">This game is divided into six levels. Grandma is on the phone to guide you through the process of making nigiri sushi.</li>
      <li class="para description">Nigiri sushi is the type of sushi that consists of a ball of rice pressed into an oval shape, with raw fish, grilled eel, or another topping arranged on top. You’ll also be making some gunkan maki (boat-shaped sushi) with sea urchin on top.</li>
      <li class="para description">First, you’ll need to find all the ingredients and utensils in the kitchen. Cut the seafood and scorch the eel with your blowtorch. Preparing and slicing all your ingredients before you start cooking is what chefs call ‘mise en place’.</li>
      <li class="para description">Now you can start assembling and decorating the sushi plates with soy sauce, wasabi, mayonaise, salmon roe, ginger, and other toppings. Don’t forget to choose a cute outfit before your guests arrive!</li>
      </ul>
      `,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-06-24",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Fashion Sequin Addict - playcutegames.com",
      name: ["BFFs Fashion Sequin Addict", "bffs-fashion-sequin-addict"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-fashion-sequin-addict/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-fashion-sequin-addict-300.jpg",
      path: ["/game/bffs-fashion-sequin-addict", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-fashion-sequin-addict/",
      Walkthrough: "https://www.youtube.com/embed/mm0Jy-3PkzE",
      s_dis:
      " BFFs Fashion Sequin Addict Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs Fashion Sequin Addict. BFFs want to try cute sparkling sequin outfits! Mix and match glamorous dresses, tops, bottoms, and accessories from their wardrobe, and create stunning looks for the runway. Show off your fashion sense and become the ultimate sequin addict! Join and help the girls.",
        tag: ["Winx","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-06-17",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Black And Pink Fashionista - playcutegames.com",
      name: ["BFFs Black And Pink Fashionista", "bffs-black-and-pink-fashionista"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-black-and-pink-fashionista/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-black-and-pink-fashionista-300.jpg",
      path: ["/game/bffs-black-and-pink-fashionista", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-black-and-pink-fashionista/",
      Walkthrough: "https://www.youtube.com/embed/ZFtXJuXCa5Y",
      s_dis:
      " BFFs Black And Pink Fashionista Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "In BFFs Black and Pink Fashionista, BFFs are planning a style extravaganza, embracing the power of black and pink. They aim to create head-turning outfits by combining sleek black pieces with vibrant pink accents. With their impeccable taste and creative flair, they’re set to redefine fashion boundaries and inspire others to embrace the boldness of this stunning color combination. Get ready for a fashion revolution that celebrates the perfect blend of edgy and feminine styles.",
        tag: ["Winx","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-06-08",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Perfect Pastel Looks - playcutegames.com",
      name: ["My Perfect Pastel Looks", "my-perfect-pastel-looks"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-pastel-looks/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-pastel-looks-300.jpg",
      path: ["/game/my-perfect-pastel-looks", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-perfect-pastel-looks/",
      Walkthrough: "https://www.youtube.com/embed/647kNKeDeyU",
      s_dis:
      " My Perfect Pastel Looks Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Perfect Pastel Looks. Bffs excited to try pastel fashion. Join and help them to create stunning pastel outfits and explore a world of soft hues, delicate textures, and dreamy aesthetics. Mix and match trendy clothing, accessories, and hairstyles for the perfect pastel ensemble. Unleash your creativity in this fashion-forward game!",
      tag: ["Rainbow Girls","Dress up","Girl games","Pastel","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    }, {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Perfect Prom Night Look - playcutegames.com",
      name: ["Perfect Prom Night Look", "perfect-prom-night-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/perfect-prom-night-look/250x142.png",
      path: ["/game/perfect-prom-night-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/perfect_prom_night_look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Perfect Prom Night Look game. Princess Anna, Elsa, Ariel, Moana, Jasmine, and SnowWhite are getting ready for their dream day.",
      l_dis:
        `Welcome to the Perfect Prom Night Look game. Princess Anna, Elsa, Ariel, Moana, Jasmine, and SnowWhite are getting ready for their dream day. Yes! It’s a Prom Night!. They are so excited and have confused to pick a perfect costume.  Join and help the girls to choose perfect outfits and accessories.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Prom",
        "Anna",
        "Elsa",
        "Elsa",
        "Jasmine",
        "Moana",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    
    {
      date: "2023-06-08",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Candy Fashion Look - playcutegames.com",
      name: ["BFFs Candy Fashion Look", "bffs-candy-fashion-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-candy-fashion-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-candy-fashion-look-300.jpg",
      path: ["/game/bffs-candy-fashion-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-candy-fashion-look/",
      Walkthrough: "https://www.youtube.com/embed/tGlSDfIPbV8",
      s_dis:
      " BFFs Candy Fashion Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs Candy Fashion Look. Bffs want to try sweet and vibrant candy-inspired outfits. Mix and match colorful clothes, accessories, and hairstyles to create the most fabulous and delicious fashion looks. Let your creativity shine in this sugary fashion adventure! Unleash your creativity and create the most fabulous looks in this sweet and stylish game. Get ready for a mouthwatering fashion experience!",
      tag: ["LOL Surprise","Dress up","Girl games","Beach","Festival","Summer","Exclusive","Season"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-24",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Biker Fashion - playcutegames.com",
      name: ["BFFs Biker Fashion", "bffs-biker-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-biker-fashion/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-biker-fashion-300.jpg",
      path: ["/game/bffs-biker-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-biker-fashion/",
      Walkthrough: "https://www.youtube.com/embed/YUxXRA6C6dg",
      s_dis:
      "Rev up your style in BFFs Biker Fashion at PlayCuteGames! Play for free and create trendy biker looks with stylish outfits and leather jackets!",
      l_dis:
        "Get ready to hit the road in style with BFFs Biker Fashion! Choose from a wide range of trendy outfits, leather jackets, boots, and accessories to create the perfect biker look. Show off your fashion skills and transform these best friends into the ultimate biker babes! Play and have fun!",
        tag: ["Barbie","Dress up","Fashion","Girl games","Exclusive","Ellie"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-24",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity Kendel All Around The Fashion - playcutegames.com",
      name: ["Celebrity Kendel All Around The Fashion", "celebrity-kendel-all-around-the-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-kendel-all-around-the-fashion/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-kendel-all-around-the-fashion-300.jpg",
      path: ["/game/celebrity-kendel-all-around-the-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-kendel-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/kpF6FbJK6wQ",
      s_dis:
      "Celebrity Kendel All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Kendel All Around The Fashion. Kendel All, the sensational celebrity, takes the fashion world by storm in this exciting game! Join her on a glamorous journey as she conquers the runway, designs stunning outfits, and transforms into a style icon. Explore luxurious locations, attend exclusive events, and compete against fashion elites to become the ultimate trendsetter. With endless customization options and a captivating storyline, Kendel All Around The Fashion is a must-play for fashion enthusiasts!",
        tag: ["Celebrity","Dress up","Fashion","Girl games","Exclusive","Season"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-15",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Sunflower Shine Looks - playcutegames.com",
      name: ["Celebrity Sunflower Shine Looks", "celebrity-sunflower-shine-looks"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-sunflower-shine-looks/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-sunflower-shine-looks-300.jpg",
      path: ["/game/celebrity-sunflower-shine-looks", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-sunflower-shine-looks/",
      Walkthrough: "https://www.youtube.com/embed/rNT08ap5Zq8",
      s_dis:
      "Celebrity Sunflower Shine Looks Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Sunflower Shine Looks. These celebrities are more fond of sunflower pattern outfits. They already filled the latest collection in their wardrobe. Explore a variety of dresses, accessories, and more, in their wardrobe. Mix and match different pieces to create the perfect ensemble that captures the essence of these beautiful flowers.",
        tag: ["Celebrity","Dress up","Flower","Girl games","Summer","Exclusive","Season"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-18",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Wild Animal Care And Salon - playcutegames.com",
      name: ["Wild Animal Care And Salon", "wild-animal-care-and-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/wild-animal-care-and-saloon/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/wild-animal-care-and-salon-300.jpg",
      path: ["/game/wild-animal-care-and-salon", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/wild-animal-care-and-saloon/",
      Walkthrough: "https://www.youtube.com/embed/PwlohL2Fxlo",
      s_dis:
      "Wild Animal Care And Salon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "“Wild Animal Care And Salon” is an interactive simulation game where players take on the role of caretaker and stylist for a variety of wild animals. Players will be responsible for giving treatment, feeding, grooming, and playing with the animals to keep them healthy and happy. As they progress through the game, players will unlock new animals to care for, each with their own unique needs and personalities.",
      tag: ["Docter","Animal","Treatment","Dress up","Mobile","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      
      date: "2023-08-22",
      brand: "playcutegames",
     
      orientation: 0,
      title: "V And N Pizza Cooking Game - playcutegames.com",
      name: ["V And N Pizza Cooking Game", "v-and-n-pizza-cooking-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/v-and-n-pizza-cooking-game/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/v-and-n-pizza-cooking-game-300.jpg",
      path: ["/game/v-and-n-pizza-cooking-game", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/v-and-n-pizza-cooking-game/",
      Walkthrough: "https://www.youtube.com/embed/HvO3LEDT8RI",
      s_dis:
      "V And N Pizza Cooking Game Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Experience the ultimate virtual pizza-making adventure with the V And N Pizza Cooking Game! Craft delicious pizzas from scratch, choose mouthwatering toppings, and master the art of baking. From classic favorites to innovative creations, unleash your inner chef and create culinary masterpieces. With immersive gameplay and endless combinations, satisfy your pizza cravings and become a virtual pizzaiolo extraordinaire. Get ready to cook, create, and enjoy the taste of success in this exciting cooking game!",
        tag: ["Cooking","V&N","Girl games","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-15",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity Swifty All Around The Fashion - playcutegames.com",
      name: ["Celebrity Swifty All Around The Fashion", "celebrity-swifty-all-around-the-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-swifty-all-around-the-fashion/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-swifty-all-around-the-fashion-300.jpg",
      path: ["/game/celebrity-swifty-all-around-the-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-swifty-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/2LkRE1h9Wh0",
      s_dis:
      "Celebrity Swifty All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Swifty All Around The Fashion. Celebrity Swifty plans to try different fashion trends. She needs some help to choose the perfect costumes and outfits. Browse her wardrobe and choose the perfect outfits for each fashion trend. Play and have fun with this new Celebrity game for girls!",
        tag: ["Celebrity","Dress up","Girl games","Fashion","Exclusive","Season"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-09",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Classic Summer Stripes - playcutegames.com",
      name: ["Celebrity Classic Summer Stripes", "celebrity-classic-summer-stripes"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-classic-summer-stripes/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-classic-summer-stripes-300.jpg",
      path: ["/game/celebrity-classic-summer-stripes", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-classic-summer-stripes/",
      Walkthrough: "https://www.youtube.com/embed/CmPYK0yR4vA",
      s_dis:
      "Celebrity Classic Summer Stripes Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Classic Summer Stripes. Let’s style your favorite celebrities in summer stripes fashion. Browse their wardrobe and choose from a selection of striped tops, bottoms, dresses, and accessories to create the perfect summer look for them. You can mix and match different pieces to create a one-of-a-kind look. So, let your imagination run wild and create the ultimate summer stripes look for your favorite celebrity!",
        tag: ["LOL Surprise","Dress up","Girl games","Beach","Festival","Summer","Exclusive","Season"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-08",
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Movie Star Daily Routine - playcutegames.com",
      name: ["Movie Star Daily Routine", "movie-star-daily-routine"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/movie-star-daily-routine/250x142.png",
      path: ["/game/movie-star-daily-routine", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1680542863/",
      Walkthrough: "https://www.youtube.com/embed/vAcIbxcKiNA",
      s_dis:
      "Movie Star Daily Routine Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Movie Star Daily Routine. Let’s join the day in the Life of a movie star stream. We will spend the whole day with a famous actress from the series. The morning begins with cosmetic procedures and skin care. See how the skin of a movie star shines!",
      tag: ["Dl-Girls","Make up","Kids","Girl","Dress up","Wednesday"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-05-06",
      brand: "colorifyme",
     
      orientation: 1,
      title: "Cat Coloring - playcutegames.com",
      name: ["Cat Coloring", "cat-coloring-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cat-coloring-game/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/cat-coloring-game/520x295.png",
      path: ["/game/cat-coloring-game", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/colorifyme/cat-coloring-game/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Cat Coloring Game Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Purrfect your coloring skills with our Cat Coloring Game! Choose from a variety of cute cat images and use your artistic flair to color them in with bright colors. Great for all ages, this game is a fun way to unwind and express your creativity. With easy-to-use controls and endless color combinations, you’ll never get bored. You can also experiment with different shades of the same color to add depth and texture to your design. If you make a mistake or want to change a color, you can simply select the eraser tool or undo the button to remove the color and start again. Once you have finished coloring, you can save the finished design or print it out to display.",
      tag: ["Coloring","Education","Cat","Pet","Animal"],
      hint: `Click and apply`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Summer Beach Vibes - playcutegames.com",
      name: ["BFFs Summer Beach Vibes", "bffs-summer-beach-vibes"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-summer-beach-vibes/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-summer-beach-vibes-300.jpg",
      path: ["/game/bffs-summer-beach-vibes", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-summer-beach-vibes/",
      Walkthrough: "https://www.youtube.com/embed/XyK06GpZ59I",
      s_dis:
      "BFFs Summer Beach Vibes Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "BFFs Summer Beach Vibes is a fun and exciting game that lets you dress up four best friends for a day at the beach. You get to choose from a variety of trendy outfits and accessories to create the perfect beach look. They are excited to spend the day soaking up the sun and having fun at the beach. Join and help them pick out their outfits for the day. You can choose from a variety of beach outfits, sunglasses, and hats to create a stylish beach look for each friend.",
        tag: ["LOL Surprise","Dress up","Girl games","Beach","Festival","Summer","Exclusive","Season"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-27",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Boho Summer Style - playcutegames.com",
      name: ["Celebrity Boho Summer Style", "celebrity-boho-summer-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-boho-summer-style/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-boho-summer-style-300.jpg",
      path: ["/game/celebrity-boho-summer-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-boho-summer-style/",
      Walkthrough: "https://www.youtube.com/embed/StPWgVoc8uY",
      s_dis:
      "Celebrity Boho Summer Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Celebrity Boho Summer Style is a summer fashion dress-up game. In this game, Celebrities plan to have a party on the beach with bohemian-inspired outfits. Browse their wardrobe to mix and match different clothing items, accessories, and hairstyles to create a unique look. Join to play with your favorite celebrities and have fun!",
        tag: ["Celebrity","Dress up","Girl games","Fashion","Summer","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-127",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bffs Cherry Blossom Festival Look - playcutegames.com",
      name: ["Bffs Cherry Blossom Festival Look", "bffs-cherry-blossom-festival-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-cherry-blossom-festival-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-cherry-blossom-festival-look-300.jpg",
      path: ["/game/bffs-cherry-blossom-festival-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-cherry-blossom-festival-look/",
      Walkthrough: "https://www.youtube.com/embed/t1z5b8FTFR0",
      s_dis:
      "Bffs Cherry Blossom Festival Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "“The BFFs Cherry Blossom Festival Look dress-up game is a fun and interactive way to plan your perfect outfit for a springtime festival with your best friend. In this game, Bffs plan to get ready for the cherry blossom festival. You have to help them to choose clothing options, including pastel dresses, floral prints, and other spring-inspired pieces to create an ideal look.",
      tag: ["Winx","Dress up","Girl games","Fashion","Festival","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Coachella Festival Outfits - playcutegames.com",
      name: ["My Coachella Festival Outfits", "my-coachella-festival-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-coachella-festival-outfits/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/my-coachella-festival-outfits-300.jpg",
      path: ["/game/my-coachella-festival-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-coachella-festival-outfits/",
      Walkthrough: "https://www.youtube.com/embed/RaGnPgnJ2h4",
      s_dis:
      "My Coachella Festival Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Coachella Festival Outfits. Ellie and her friends are more excited to attend the Coachella Festival this year. Browse their wardrobe to mix and match different clothing items, accessories, hairstyles, and makeup to create unique and trendy outfits for them. With a wide variety of options to choose from, you can experiment with different styles and fashion trends, including bohemian, grunge, retro, and more.",
        tag: ["Barbie","Dress up","Girl games","Festival","Summer","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-19",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Trendy Oversized Outfits Street Style - playcutegames.com",
      name: ["My Trendy Oversized Outfits Street Style", "my-trendy-oversized-outfits-street-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-trendy-oversized-outfits-street-style/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/my-trendy-oversized-outfits-street-style-300.jpg",
      path: ["/game/my-trendy-oversized-outfits-street-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-trendy-oversized-outfits-street-style/",
      Walkthrough: "https://www.youtube.com/embed/lwjxtxhyIzE",
      s_dis:
      "My Trendy Oversized Outfits Street Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Trendy Oversized Outfits Street Style. Oversized Outfits… so trendy these days! But it’s not easy building up a suitable outfit! This BFF will wear one today, and they need a bit of help to pick a perfect one.  Help them out! They have plenty of cool Oversized Outfits in their wardrobe which you can mix and match however you like, and check out the accessories too; those are amazing! ",
      tag: ["Winx","Girl games","Dress up","Oversized","Fashion","Dress up","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-17",
      brand: "colorifyme",
     
      orientation: 1,
      title: "Seahorse Coloring - playcutegames.com",
      name: ["Seahorse Coloring", "seahorse-coloring-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/seahorse-coloring-game/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/seahorse-coloring-game/520x295.png",
      path: ["/game/seahorse-coloring-game", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/colorifyme/seahorse-coloring-game/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Seahorse Coloring Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Do you like coloring games that let your creativity run wild? Check out the free online Seahorse Coloring Game on Play Cute Games! This fun and engaging game lets you bring adorable seahorses to life in a vibrant, underwater world. With a wide selection of colors and creative tools at your fingertips, you can design unique patterns and make each seahorse stand out in its own special way.
Enjoy a relaxing and artistic experience as you craft beautiful sea scenes and add your personal touch to each design. Whether you’re creating calming ocean views or bold, colorful designs, this game provides endless opportunities for imaginative fun. Share your colorful masterpieces with friends and see who can come up with the most stunning sea creature creations. Dive in and let your artistic talents shine with hours of playful and creative coloring fun!`,
      tag: ["Coloring","Education","Exclusive"],
      hint: `Click and apply`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2022-10-11",
      brand: "GD",
     
      orientation: 1,
      title: "Enjoy the Thrill of the Catch with Tiny Fishing Game",
      name: ["Tiny Fishing", "tiny-fishing"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/tiny-fishing/250x142.png",
      path: ["/game/tiny-fishing", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/5ee4b0222a474e44880b6c6d9f96754d/?gd_sdk_referrer_url=https://playcutegames.com/game/tiny-fishing",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Looking for a fun and interesting fishing game? Try Tiny Fishing Game and experience the excitement of catching a variety of fish species in different locations.",
      l_dis:
        `Welcome to the world of the <a href="/game/tiny-fishing">Tiny Fishing</a> Game, where you can experience the excitement of fishing without leaving your home! Imagine a miniature world filled with clear blue waters, tiny boats, and fishing rods that fit in the palm of your hand.`,
      tag: ["Puzzle","Skill","Kids","Arcade"],
      hint: `
      <ol class="gamelist">
      <li class="para description">Start by clicking on the "play" button to begin the game.</li>
      <li class="para description">Once the game starts, you will see a lake with fish swimming around in it.</li>
      <li class="para description">Use your mouse to move your fishing rod around the lake until you find a spot with fish.</li>
      <li class="para description">Click and hold the left mouse button to cast your line into the water.</li>
      <li class="para description">Wait for a fish to bite and then quickly click the left mouse button to reel it in.</li>
      <li class="para description">Keep reeling in the fish by clicking the left mouse button until it reaches the surface of the water.</li>
      <li class="para description">Once the fish is on the surface, click the left mouse button again to catch it.</li>
      <li class="para description">steps 3-7 to catch as many fish as possible within the time limit.</li>
      <li class="para description">At the end of the game, your score will be displayed based on the number and size of fish you caught.</li>
      </ol>
      `,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-04-17",
      brand: "colorifyme",
     
      orientation: 1,
      title: "Bee Coloring - playcutegames.com",
      name: ["Bee Coloring", "bee-coloring-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bee-coloring-game/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bee-coloring-game/520x295.png",
      path: ["/game/bee-coloring-game", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/colorifyme/bee-coloring-game/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Bee Coloring Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Buzz into creativity with the Bee Coloring Game on Play Cute Games! It is a beautiful game in which you are allowed to choose the colors that will create the life of cute bees and the environments they live in  the gardens. And since these paints are bright and cheerful it is up to you what you paint these buzzing bees and the environment in which they live. Whether it is dazzling patterns or different hues of color each stroke transforms your creation into an exciting work of art.
Take a pleasant and aesthetic journey as you paint beautiful pictures with wonderful elements such as bees and blooming flowers. You are welcome to come up with beautiful and creative bee filled environments as you play the game. After that, make your own picture perfect and show it to friends and observe how they will react. Join the Bee Coloring Game and find a way to have fun and be relaxed coloring bees!`,
      tag: ["Coloring","Education","Exclusive"],
      hint: `Click and apply`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-14",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Spring Fashion Trends - playcutegames.com",
      name: ["Celebrity Spring Fashion Trends", "celebrity-spring-fashion-trends"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-spring-fashion-trends/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-spring-fashion-trends-300.jpg",
      path: ["/game/celebrity-spring-fashion-trends", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-spring-fashion-trends/",
      Walkthrough: "https://www.youtube.com/embed/8d17wp6UDlA",
      s_dis:
      "Celebrity Spring Fashion Trends Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Spring Fashion Trends. Hello little girls. Are you ready for the latest spring fashion collection? Follow the latest fashion trends with these celebrities and create your own fashion clothes for next spring season. Browse their wardrobe to pick suitable outfits and costumes. Join and help them to welcome their favorite season.",
      tag: ["Celebrity","Girl games","Dress up","Spring","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-07",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Dreamy Flora Fashion Look - playcutegames.com",
      name: ["My Dreamy Flora Fashion Look", "my-dreamy-flora-fashion-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-dreamy-flora-fashion-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/my-dreamy-flora-fashion-look-300.jpg",
      path: ["/game/my-dreamy-flora-fashion-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-dreamy-flora-fashion-look/",
      Walkthrough: "https://www.youtube.com/embed/rnJMcyk0HdQ",
      s_dis:
      "My Dreamy Flora Fashion Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Dreamy Flora Fashion Look. Girls! You know that spring is on its way when you see the trees starting to bud and bloom, and the bulbs you planted last fall start to sprout out of the ground. Join with Ellie and their friends to celebrate the most beautiful and colorful season.",
      tag: ["Barbie","Girl games","Dress up","Spring","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-07",
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Weird Dance on Wednesday - playcutegames.com",
      name: ["Weird Dance on Wednesday", "weird-dance-on-wednesday"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/weird-dance-on-wednesday/250x142.png",
      path: ["/game/weird-dance-on-wednesday", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1680542863/",
      Walkthrough: "https://www.youtube.com/embed/kyspCtnvGnQ",
      s_dis:
      "Weird Dance on Wednesday Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Weird Dance on Wednesday. A goth girl is going to a school prom. She wants to make a splash with her new dance. Rogue rehearsed this dance for a whole month. She came up with it herself, inspired by classic films. The gothic girl has prepared a gorgeous black dress, but something is missing. Certainly! How can you go to a ball without makeup? Help the goth girl to choose make-up that reflects her inner world. Use bold and dark shades to emphasize the eyes and lips. A few freckles will make the look a little more romantic. Go to the ball – have fun and dance a weird dance on Wednesday!",
      tag: ["Make up","Kids","Girl","Dress up","Dl-Girls","Wednesday"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-03-28",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Spring Haute Couture Season 1 - playcutegames.com",
      name: ["Spring Haute Couture Season 1", "spring-haute-couture-season-1"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/spring-haute-couture-season-1/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/spring-haute-couture-season-1-300.jpg",
      path: ["/game/spring-haute-couture-season-1", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/spring-haute-couture-season-1/",
      Walkthrough: "https://www.youtube.com/embed/3yBQ8THHzNU",
      s_dis:
      "Spring Haute Couture Season 1 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Spring Haute Couture Season 1. These celebrities get more excited to be a part of Spring Haute Couture. Join and browse their wardrobe to pick the perfect outfits and costumes. Don’t forget to pick a unique hairstyle. Are you ready to try the perfect one for the girls? Play and have fun!",
      tag: ["Celebrity","Girl games","Dress up","Spring","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-03-03",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Casual Winter Look - playcutegames.com",
      name: ["Celebrity Casual Winter Look", "celebrity-casual-winter-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-casual-winter-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-casual-winter-look-300.jpg",
      path: ["/game/celebrity-casual-winter-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-casual-winter-look/",
      Walkthrough: "https://www.youtube.com/embed/5Xi6N4cL-y4",
      s_dis:
      "Celebrity Casual Winter Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Casual Winter Look. Winter is usually the coldest season of the year, but this doesn’t mean this celebrity can’t be stylish during these less sunny days of the year. Browse the wardrobe and pick suitable outfits and costumes to welcome this winter! Play and have fun!",
      tag: ["Celebrity","Dress up","Girl games","Fashion","Winter","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-02-24",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Super Girls Ripped Jeans Outfits - playcutegames.com",
      name: ["Super Girls Ripped Jeans Outfits", "super-girls-ripped-jeans-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-ripped-jeans-outfits/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-ripped-jeans-outfits-300.jpg",
      path: ["/game/super-girls-ripped-jeans-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/super-girls-ripped-jeans-outfits/",
      Walkthrough: "https://www.youtube.com/embed/5DAF1zHjls8",
      s_dis:
      "Super Girls Ripped Jeans Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Super Girls Ripped Jeans Outfits. Hello Girls! Are you feeling crafty today? Let’s try ripped-pattern jeans with different cool colors. Help the girls to browse and pick suitable jeans from their wardrobe. Try some matching accessories and different hairstyles for a perfect look.",
      tag: ["Winx","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-02-17",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity Ari All Around The Fashion - playcutegames.com",
      name: ["Celebrity Ari All Around The Fashion", "celebrity-ari-all-around-the-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-ari-all-around-the-fashion/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-ari-all-around-the-fashion-300.jpg",
      path: ["/game/celebrity-ari-all-around-the-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-ari-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/veoylS8faT0",
      s_dis:
      "Celebrity Ari All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Ari All Around The Fashion. Celebrity Ari plans to try different fashion trends. She needs some help to choose the perfect costumes and outfits. Browse her wardrobe and choose the perfect outfits for each fashion trend. Play and have fun with this new game!",
      tag: ["Celebrity","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-03-28",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Easter Fashionista - playcutegames.com",
      name: ["Celebrity Easter Fashionista", "celebrity-easter-fashionista"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-easter-fashionista/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-easter-fashionista-300.jpg",
      path: ["/game/celebrity-easter-fashionista", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-easter-fashionista/",
      Walkthrough: "https://www.youtube.com/embed/R5tpiMQFxUA",
      s_dis:
      "Celebrity Easter Fashionista Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Easter Fashionista. Do you celebrate Easter? These celebrities are going to celebrate Easter together doing all kinds of fun and creative activities. They are going to get ready for the Easter celebration! Browse their wardrobe and pick the perfect outfit for them. Come and join your favorite celebrities.",
      tag: ["Celebrity","Easter","Girl games","Festival","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-03-13",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bff St Patrick’s day Look - playcutegames.com",
      name: ["Bff St Patrick’s day Look", "bff-st-patricks-day-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-st-patricks-day-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-st-patricks-day-look-300.jpg",
      path: ["/game/bff-st-patricks-day-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bff-st-patricks-day-look/",
      Walkthrough: "https://www.youtube.com/embed/QLNPD3IJ5XI",
      s_dis:
      "Bff St Patrick’s day Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Bff St Patrick’s day Look. Have you ever heard about St. Patrick’s Day? Prepare your girlfriends for the holiday party in the game BFF St. Patricks Day game. The main color of the holiday is green, so the outfits are mainly made in this color. First, select a unique outfit, hat, hairstyle, and stylish accessories.",
      tag: ["LOL Surprise","Dress up","Girl games","Festival","Patricks","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-02-17",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bff Stylish Off Shoulder Outfits - playcutegames.com",
      name: ["Bff Stylish Off Shoulder Outfits", "bff-stylish-off-shoulder-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-stylish-off-shoulder-outfits/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-stylish-off-shoulder-outfits-300.jpg",
      path: ["/game/bff-stylish-off-shoulder-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bff-stylish-off-shoulder-outfits/",
      Walkthrough: "https://www.youtube.com/embed/2lrTcYfdTAA",
      s_dis:
      "Bff Stylish Off Shoulder Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Bff Stylish Off Shoulder Outfits. The off-shoulder top never gets out of style! This clothing piece is so cute and feminine, perfect for a day! These BFFs can’t wait to fill their closets with as many dresses as possible. This can mean only one thing, they need to go shopping and you could help them find the perfect one.",
      tag: ["Nail","Girl games","Valentine","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-02-13",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bffs Venice Carnival Celebrations - playcutegames.com",
      name: ["Bffs Venice Carnival Celebrations", "bffs-venice-carnival-celebration"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-venice-carnival-celebration/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-venice-carnival-celebration-300.jpg",
      path: ["/game/bffs-venice-carnival-celebration", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-venice-carnival-celebrations/",
      Walkthrough: "https://www.youtube.com/embed/B-gNz5blTAM",
      s_dis:
      "Bffs Venice Carnival Celebrations Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs Venice Carnival Celebrations. Bffs are planning a trip to Italy to join the Venice carnival. Sounds fun right? Help the girls with the quick preparation. Browse their wardrobe and pick suitable costumes and hairstyles. Don’t forget to choose the mask for the party. Join these girls and have fun!",
      tag: ["Dress up","Barbie","Girl games","Venice","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-01-27",
      brand: "bestgamespot",
     
      orientation: 1,
      title: "Help the couple - playcutegames.com",
      name: ["Help the couple", "help-the-couple"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/help-the-couple/250x142.png",
      path: ["/game/help-the-couple", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/help-the-couples/",
      Walkthrough: "https://www.youtube.com/embed/XIl5uw0o22k",
      s_dis:
      "Help the couple Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Help the couple. Help the couple is a slide puzzle game. And which contains 30 tricky puzzles level. Here your task is to slide pieces around to get them in the right spot and create a way to meet the couple. Play and have fun.",
      tag: ["Puzzle","Arcade","Girl","Slide","Kids","Exclusive"],
      hint: `Click and Drag to slide the pieces`,
      rating:[4.4,1210,114],
    },
    
    {
      date: "2023-03-16",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Little Lily St.Patrick’s Day Photo Shoot - playcutegames.com",
      name: ["Little Lily St.Patrick’s Day Photo Shoot", "little-lily-st-patricks-day-photo-shoot"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/little-lily-st-patricks-day-photo-shoot/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/little-lily-st-patricks-day-photo-shoot-300.jpg",
      path: ["/game/little-lily-st-patricks-day-photo-shoot", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/little-lily-st-patricks-day-photo-shoot/",
      Walkthrough: "https://www.youtube.com/embed/9aDdPpImRSI",
      s_dis:
      "Little Lily St.Patrick’s Day Photo Shoot Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Little Lily St.Patrick’s Day Photo Shoot. St. Patrick’s Day is just around the corner, it’s time to celebrate the rich culture and heritage of Ireland! This festive day is a time to wear green! Help Little Lily to get ready for this wonderful day! Browse her wardrobe and pick a cute outfit with matching accessories.",
      tag: ["Little Lily","Dress up","Girl games","Festival","Patricks","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-01-19",
      brand: "playcutegames",
     
      orientation: 1,
      title: "All Seasons Nail Salon - playcutegames.com",
      name: ["All Seasons Nail Salon", "all-seasons-nail-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/all-seasons-nail-salon/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/all-seasons-nail-salon-300.jpg",
      path: ["/game/all-seasons-nail-salon", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/all-seasons-nail-salon/",
      Walkthrough: "https://www.youtube.com/embed/13dLbahfazA",
      s_dis:
      "All Seasons Nail Salon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to All Seasons Nail Salon. It is manicure madness over here and it’s your time to become the greatest Nail Salon of the year! All you need to do is choose the shape, paint, polish, and also help to pick beautiful accessories and tattoos. Play and have fun!",
      tag: ["Nail","Winter","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-01-19",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity Selen All Around The Fashion - playcutegames.com",
      name: ["Celebrity Selen All Around The Fashion", "celebrity-selen-all-around-the-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-selena-all-around-the-fashion/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-selen-all-around-the-fashion-300.jpg",
      path: ["/game/celebrity-selen-all-around-the-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-selena-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Celebrity Selen All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Selen All Around The Fashion. Celebrity Selen plans to try different fashion trends. She needs some help to choose the perfect costumes and outfits. Browse her wardrobe and choose the perfect outfits for each fashion trend. Play and have fun with this new game!",
      tag: ["Celebrity","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-01-13",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Sundance Film Festival - playcutegames.com",
      name: ["Celebrity Sundance Film Festival", "celebrity-sundance-film-festival"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-sundance-film-festival/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-sundance-film-festival-300.jpg",
      path: ["/game/celebrity-sundance-film-festival", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-sundance-film-festival/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Celebrity Sundance Film Festival Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Celebrity Sundance Film Festival. Celebrity invited to the Sundance event. They are excited to participate in this event. Browse her wardrobe and pick cute outfits and costumes for the event. Help them and have fun!",
      tag: ["Celebrity","Dress up","Festival","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-31",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Dark Academia Winter Outfits - playcutegames.com",
      name: ["BFFs Dark Academia Winter Outfits", "bffs-dark-academia-winter-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-dark-academia-winter-outfits/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-dark-academia-winter-outfits/250x142.png",
      path: ["/game/bffs-dark-academia-winter-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/bffs-dark-academia-winter-outfits/",
      Walkthrough: "https://www.youtube.com/embed/qt8HwRZsnuM",
      s_dis:
      "BFFs Dark Academia Winter Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs Dark Academia Winter Outfits. BFFs are getting ready for school. There’s a new cool fashion style they want to try on; dark academia. Mix and match the clothes to create unique outfits and complete the look with amazing makeup. Help the girls to pick cute outfits.",
      tag: ["LOL Surprise","Dress up","Winter","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-31",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity RiRi All Around The Fashion - playcutegames.com",
      name: ["Celebrity RiRi All Around The Fashion", "celebrity-riri-all-around-the-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-riri-all-around-the-fashion/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-riri-all-around-the-fashion/250x142.png",
      path: ["/game/celebrity-riri-all-around-the-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/celebrity-riri-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Celebrity RiRi All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity RiRi All Around The Fashion. Celebrity RiRi plans to try different fashion trends. She needs some help to choose the perfect costumes and outfits. Browse her wardrobe and choose the perfect outfits for each fashion trend. Play and have fun with this new game!",
      tag: ["LOL Surprise","Dress up","Winter","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-31",
      brand: "GD",
     
      orientation: 0,
      title: "Uphill Rush 11 - playcutegames.com",
      name: ["Uphill Rush 11", "uphill-rush-11"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/uphill-rush-11/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/uphill-rush-11/250x142.png",
      path: ["/game/uphill-rush-11", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/4705e37793174bcc82972bd2871e08a4/?gd_sdk_referrer_url=https://playcutegames.com/game/uphill-rush-11",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Uphill Rush 11 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Explore a series of wild and wacky waterslides in Uphill Rush 11, a new action game in the Uphill Rush series. After surviving the dangerous online car racing game of the previous episode, it’s time for a vacation on a cruise ship… A cruise ship crammed with crazy waterslides, that is! Choose an inflatable ring or even a waterscooter and speed through the tube slides. Perform incredible jumps before plunging into the pool. There are other swimmers in the pool, but they’ll just have to make way!",
      tag: ["Racing","3D","Winter","Hypercasual","Io"],
      hint: `Use the arrow keys to keep your balance. If you capsize, you’ll have to start over! When the lightning icon is loaded, you can activate a speed boost by tapping the space bar. Collect coins to upgrade your swimmers and your float’s power stats.`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-03-19",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Aqua Fish Dental Care - playcutegames.com",
      name: ["Aqua Fish Dental Care", "aqua-fish-dental-care"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/aqua-fish-dental-care/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/aqua-fish-dental-care-300.jpg",
      path: ["/game/aqua-fish-dental-care", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/aqua-fish-dental-care/",
      Walkthrough: "https://www.youtube.com/embed/4Exz1LUzB2I",
      s_dis:
      "Aqua Fish Dental Care Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Aqua Fish Dental Care. Aquatic organisms need a dentist! Come and join as a dental expert and treat all the species with care. It’s time to explore the amazing underwater world and give dental treatment to all the cute species! Each level has different species and you have to use all the equipment to complete the process.",
      tag: ["Docter","Dental","Girl games","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-31",
      brand: "GD",
     
      orientation: 1,
      title: "DinoMatch: Mahjong Pairs - playcutegames.com",
      name: ["DinoMatch: Mahjong Pairs", "dinomatch-mahjong-pairs"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dinomatch-mahjong-pairs/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/dinomatch-mahjong-pairs/250x142.png",
      path: ["/game/dinomatch-mahjong-pairs", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/0d033a94592845d8bbe814039cba3e57/?gd_sdk_referrer_url=https://playcutegames.com/game/dinomatch-mahjong-pairs",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "DinoMatch: Mahjong Pairs Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "The goal of the game is to connect pairs of identical tiles.? ? You need to have time to clear the entire field of how the time runs out.⏰ Connect more distant tiles to get more stars.⭐ For a certain number of stars, a reward is given - a chest.? Over time, new beautiful tiles are unlocked and level configurations become more complicated.?",
      tag: ["Racing","3D","Winter","Hypercasual","Io"],
      hint: `The goal of the game is to connect pairs of identical tiles You need to have time to clear the entire field of how the time runs out. Connect more distant tiles to get more stars.`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-31",
      brand: "GD",
     
      orientation: 1,
      title: "Snowball Racing - playcutegames.com",
      name: ["Snowball Racing", "snow-ball-racing"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/snow-ball-racing/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/snow-ball-racing/250x142.png",
      path: ["/game/snow-ball-racing", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/85d40b1334dd42fb8c9416e42fb46bb4/?gd_sdk_referrer_url=https://playcutegames.com/game/snow-ball-racing",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Snowball Racing Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Grow your snowball as big as possible! Try one of the most entertaining winter race games. Roll the snowball bigger and bump the rivals. Snowball Racing is a super fun race game. Become a real Snowball Master!",
      tag: ["Racing","3D","Winter","Hypercasual","Io"],
      hint: `Swerve to control the movements and grow the snowball.`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-02-24",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Super Girls Magical Fairy land - playcutegames.com",
      name: ["Super Girls Magical Fairy land", "super-girls-magical-fairy-land"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-magical-fairy-land/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-magical-fairy-land-300.jpg",
      path: ["/game/super-girls-magical-fairy-land", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/super-girls-magical-fairy-land/",
      Walkthrough: "https://www.youtube.com/embed/aiKd8LbN--0",
      s_dis:
      "Super Girls Magical Fairy land Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Super Girls Magical Fairy land. These cute Bffs are looking for some fairytale-inspired outfit ideas for the upcoming fairyland ball of the season! To create a perfect look, you will first need to browse through the wardrobe to check out the dresses and costumes we put together! There are many different looks you can go for, from a delicate fairy princess, a powerful warrior princess, a flower or season fairy to an oriental empress look. You will find amazing hairdo ideas and accessories with cute fairy wings.",
      tag: ["Winx","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-03-28",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Ellie Easter Adventure - playcutegames.com",
      name: ["Ellie Easter Adventure", "ellie-easter-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-easter-adventure/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-easter-adventure-300.jpg",
      path: ["/game/ellie-easter-adventure", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ellie-easter-adventure/",
      Walkthrough: "https://www.youtube.com/embed/AgFc8O4o424",
      s_dis:
      "Ellie Easter Adventure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Ellie Easter Adventure. Ellie wants to celebrate in style! But she needs your help to get everything prepared! But first things first, you have to help her to find the easter egg and paint it with cute colors. Next, give treatment to the bunny to heal his wound. Finally, help Ellie to pick cute makeup and outfits to celebrate Easter.",
      tag: ["Barbie","Easter","Girl games","Festival","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2022-12-30",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Winter Cozy Outfits - playcutegames.com",
      name: ["My Winter Cozy Outfits", "my-winter-cozy-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-winter-cozy-outfits/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-winter-cozy-outfits/250x142.png",
      path: ["/game/my-winter-cozy-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/my-winter-cozy-outfits/",
      Walkthrough: "https://www.youtube.com/embed/CbOTH5duCPU",
      s_dis:
      "My Winter Cozy Outfits brings you the best winter fun! Help your besties choose cozy outfits and cute hairstyles in this online game at PlayCuteGames!",
      l_dis:
        "Welcome to My Winter Cozy Outfits. Winter is not a season, It’s a celebration. Enjoy this winter with these cute besties. BFF wants to try something special this winter. Join and help the girls to pick cute outfits and accessories. Don’t forget to choose a matching hairstyle! Play and have fun!",
      tag: ["Barbie","Dress up","Winter","Girl games","Season","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-30",
      brand: "playcutegames",
     
      orientation: 1,
      title: "99 Balls - playcutegames.com",
      name: ["99 Balls", "99-balls"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/99-balls/250x142.png",
      thumb_big:
        "",
      path: ["/game/99-balls", "https://playcutegames.com"],
      iframe:
        "https://yandex.com/games/app/207515?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "99 Balls Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Are you tired of ordinary bubble shooter games? Try 99 Balls, an innovate game that mixes gameplay from Bubble Shooter and Breakout! In this game, you need to destroy numbered balls before they reach the bottom of the screen. Each ball contains a number which indicates the number of hits it requires to be destroyed. Collect circles to increase the number of weapons you can throw at once. Collect stars to unlock new weapons! The further you proceed into the game, the higher the numbers on the balls become.",
      tag: ["Casual","Puzzle","Balls","Skill","Bubble","Yiv"],
      hint: `Drag the left mouse button backwards to aim, release to shoo`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-30",
      brand: "playcutegames",
     
      orientation: 0,
      title: "LOLBeans.io - playcutegames.com",
      name: ["LOLBeans.io", "lolbeans-io"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lolbeans-io/250x142.png",
      thumb_big:
        "",
      path: ["/game/lolbeans-io", "https://playcutegames.com"],
      iframe:
        "https://lolbeans.io/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "LOLBeans.io Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "LOLBeans.io is a multiplayer racing game where you have to avoid obstacles. Race to the end of the obstacle course in battle-royale-style elimination gameplay. Move through the levels until you’re the only bean left standing.",
      tag: ["Arcade","Io","Boys","Multiplayer","Survival"],
      hint: `WASD or arrow keys to move`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-16",
      brand:"Poki games",
     
      orientation: 1,
      title: "Egg Wars - playcutegames.com",
      name: ["Egg Wars", "egg-wars"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/egg-wars/250x142.png",
      path: ["/game/egg-wars", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/851bce8bb62243eb92dd8b8f7f7b774a/?gd_sdk_referrer_url=https://playcutegames.com/game/egg-wars",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Egg Wars is the best online strategy game! Play for free on PlayCuteGames, protect your eggs, and battle to win in this exciting challenge.",
      l_dis:`
      Egg Wars invites you to transform into a formidable egg soldier in a battle for supremacy. This dynamic multiplayer shooter challenges you to use your skills, strategy, and courage as you navigate stunning 3D landscapes filled with intricate details, treacherous traps, and towering structures. Engage in intense combat as you aim for victory, taking out enemies with precision and flair.
      <br><br>
      With various gameplay modes, including exciting Team Player versus Player (PvP) encounters and the heart-pounding Battle Royale format, your objective is clear: become the ultimate survivor. Egg Wars masterfully combines strategic gameplay with exhilarating shooting action, appealing to fans of both genres.
      <br><br>
      The game’s graphics are visually striking, enhanced by immersive sound effects that create an atmosphere brimming with tension and excitement. Every moment in this battlefield is charged with suspense, whether you're scouting for loot or engaging in fierce firefights against other players.
      <br><br>
      Your destiny as a leader and top shooter awaits! In this relentless environment, only the strongest prevail. Prepare yourself for an action-packed journey with Egg Wars, where every match promises to test your skills and determination. Ready to make your mark in the realm of multiplayer first-person shooters? Join the fight and claim your place among the best in this riveting battle royale experience!

      `,
      tag: ["Poki","Fight","Action","Shooting","Io","Arcade"],
      hint: `ASWD - Moves R - Reload Left mouse click - Shooting`,
      rating:[4.9,350,18],
      platform:"both",
    },
   
    {
      date: "2022-12-16",
      brand:"GD",
     
      orientation: 1,
      title: "Play Idle Fashion Shop Game Online at Playcutegames",
      name: ["Shopping Mall Tycoon", "shopping-mall-tycoon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/shopping-mall-tycoon/250x142.png",
      path: ["/game/shopping-mall-tycoon", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/63d57726031a4a8b9a666b27b2a0d323/?gd_sdk_referrer_url=https://playcutegames.com/game/shopping-mall-tycoon",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Shopping Mall Tycoon is an addictive online simulation game With challenging gameplay and cute, cartoon graphics, Shopping Mall Tycoon is sure to provide hours of fun.",
      l_dis:`Try to be the owner of all the shopping mall here, find the first bucket of gold and you can finally become the world-famous business tycoon!Buy a small plot of land, build your first store, manage and try to expand it. As long as you hold enough money,
      `,
      tag: ["Shopaholic","Simulation","Dressup","Arcade"],
      hint: `Use mouse`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-16",
      brand:"GD",
     
      orientation: 0,
      title: "Play Rachel Shopping Day Game Online at Playcutegames",
      name: ["Rachel Shopping Day", "rachel-shopping-day"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rachel-shopping-day/250x142.png",
      path: ["/game/rachel-shopping-day", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/1be69b1b98734c1fb1c4b03a639cb30a/?gd_sdk_referrer_url=https://playcutegames.com/game/rachel-shopping-day",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Rachel Shopping Day is an addictive online simulation game With challenging gameplay and cute, cartoon graphics, Rachel Shopping Day is sure to provide hours of fun.",
      l_dis:`Rachel wants to take advantage of the season sales and buy a new outfit. She wants get dressed in new clothes from head to toe! The cute girl could use some expert fashion tips in order to make the best choices. Join her on a fun shopping spree right away! The staff of the store will show you the most stunning clothes and you can decide what looks best on Rachel. Complete her look with some stylish high heels and some elegant accessories. Show off your creative and unique fashion ideas in this new dress up game!Rewarded
      `,
      tag: ["Shopaholic","Simulation","Dressup","Arcade"],
      hint: `Use mouse`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Crazy games",
     
      orientation: 0,
      title: "Mega Factory - playcutegames.com",
      name: ["Mega Factory", "mega-factory"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/mega-factory/250x142.png",
      path: ["/game/mega-factory", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/mega-factory/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mega Factory Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Mega Factory is a business simulation game where you run a factory and process these raw materials to create products. Process and pack the products, and send them using delivery vans to fulfill orders. Build more machines, unlock new items, upgrade your workers, and expand your delivery team. Go Big! Go Huge!",
      tag: ["Crazy games","Managment","Simulation","3D"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Cookie Crush Christmas 2 - playcutegames.com",
      name: ["Cookie Crush Christmas 2", "cookie-crush-christmas-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cookie-crush-christmas-2/250x142.png",
      path: ["/game/cookie-crush-christmas-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/3a9f80702dc74fa5a947e1de5e37c24b/?gd_sdk_referrer_url=https://playcutegames.com/game/cookie-crush-christmas-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Cookie Crush Christmas 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Cookie Crush Christmas 2 is the latest and the greatest Cookie Crush match-3 game ever released. Come and join this incredible journey through a dream-like Christmas land where you will enjoy 3,000+ levels packed with cookies, cakes, donuts, chests full of coins and more Christmas fun!",
      tag: ["Match3","Girl games","Kids","Cute"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2023-01-06",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity’s Chinese New Year Look - playcutegames.com",
      name: ["Celebrity’s Chinese New Year Look", "celebrity-chinese-new-year-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-chinese-new-year-look/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-chinese-new-year-look/250x142.png",
      path: ["/game/celebrity-chinese-new-year-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/celebrity-chinese-new-year-look/",
      Walkthrough: "https://www.youtube.com/embed/OljV-UGqhfI",
      s_dis:
      "Celebrity’s Chinese New Year Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity’s Chinese New Year Look. Celebrities are getting ready to celebrate the Chinese New Year in style. Care to help them prepare for it? In this online for girls, you can help your favorite Celebrity, choose new outfits and statement makeup looks for the upcoming festive days. What do you think they should wear on this occasion?",
      tag: ["Celebrity","Dress up","Rihanna","Chinese","New year","Chinese New year","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      id: 20211125,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Perfect New Year's Eve Party Look - playcutegames.com",
      name: [
        "Perfect New Year's Eve Party Look",
        "perfect-new-years-eve-party-look",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/perfect-new-years-eve-party-look/250x142.png",
      path: [
        "/game/perfect-new-years-eve-party-look",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/perfect-new-years-eve-party-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Perfect New Year’s Eve Party Look. Rainbow High girls Skyler, Sunny, Ruby, and Violet get ready for the New year’s eve party.",
      l_dis:
        "Welcome to Perfect New Year’s Eve Party Look. Rainbow High girls Skyler, Sunny, Ruby, and Violet get ready for the New year’s eve party. Browse their wardrobe and choose the best New year outfit. And add some cute makeup to each one. Surprise them with your skill and make this New year the most memorable one. Join and help them. Have fun!",
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "New year",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Cake Master Shop - playcutegames.com",
      name: ["Cake Master Shop", "cake-master-shop"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cake-master-shop/250x142.png",
      path: ["/game/cake-master-shop", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/34c4f80214474ee988ec493a563562bd/?gd_sdk_referrer_url=https://playcutegames.com/game/cake-master-shop",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Cake Master Shop Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"We all like sweets and delicious cakes, which cake do you want to make today? Challenge levels and unlock more sweet cakes! Many ingredients for cake cooking. Put them into bowls, and blend it, the next step is bake. Pick some fresh fruits. Over 15 kitchen tools, help you to make sweet and yummy cakes",
      tag: ["Cooking","Cake","Yiv","Decoration","Girl games"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Hatch Your Unicorn Idol - playcutegames.com",
      name: ["Hatch Your Unicorn Idol", "hatch-your-unicorn-idol"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/hatch-your-unicorn-idol/250x142.png",
      path: ["/game/hatch-your-unicorn-idol", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/07c7b5e533114aa0966789e630a03be8/?gd_sdk_referrer_url=https://playcutegames.com/game/hatch-your-unicorn-idol",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Hatch Your Unicorn Idol Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"A sparkling mix of kids' favorite things: kawaii unicorns, virtual pet care, of course, tons of rainbows! Hatch pony to form the cutest music band ever! Each little pony has a unique style, but all of them love to sing and dance.",
      tag: ["Pony","Kids","Unicorn","Yiv","Cute","Girl games","Simulation"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Crazy games",
     
      orientation: 0,
      title: "Pet Healer: Vet Hospital - playcutegames.com",
      name: ["Pet Healer: Vet Hospital", "pet-healer-vet-hospital"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pet-healer-vet-hospital/250x142.png",
      path: ["/game/pet-healer-vet-hospital", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/pet-healer---vet-hospital/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Pet Healer - Vet Hospital Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Pet Healer - Vet Hospital is a casual game where you help treat animals and become the best pet doctor. Collect coins, buy new equipment, unlock new medicine and learn to cure different diseases in your new pet clinic!",
      tag: ["Crazy games","Managment","Simulation","Doctor","3D"],
      hint: `WASD or arrow keys or hold left mouse button = move<br>( Tap on mobile)`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Save the Panda - playcutegames.com",
      name: ["Save the Panda", "save-the-panda"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/save-the-panda/250x142.png",
      path: ["/game/save-the-panda", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/53263014d861457dadefa13e5bde6dcb/?gd_sdk_referrer_url=https://playcutegames.com/game/safe-the-panda",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Save the Panda Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Puzzle Box includes three leisure games: Save Panda, Joy Draw and Puzzle game. All three games are very fun to kill time and exercise your brain. It will be more fun to play with your family!",
      tag: ["Arcade","Kids","Boys","Puzzle","Poki"],
      hint: ` Click or drag with the mouse to complete`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Archery - playcutegames.com",
      name: ["Archery", "archery-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/archery-2/250x142.png",
      path: ["/game/archery-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/0cfb140bc86741528fd8e6a5cc96f8a1/?gd_sdk_referrer_url=https://playcutegames.com/game/archery-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Archery Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "This is a super fun archery shooting experience Archery Tournament completely simulates this ancient to modern sports. Different target distances and still/moving targets will add difficulties to the different bow games. Practice make it perfect Just like archery in the real world, it is a sport that is easy to learn, and hard to master. But you will receive endless fun!",
      tag: ["Arcade","Sports","Kids","Boys","Puzzle","Archery","Shooting","Crazy games"],
      hint: `Hold and aim, and release to shoot.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Cook and Match: Sara's Adventure - playcutegames.com",
      name: ["Cook and Match: Sara's Adventure", "cook-and-match-saras-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cook-and-match-saras-adventure/250x142.png",
        thumb_big:
          "https://cdn.cutedressup.in/games/cutedressup_new/cook-and-match-saras-adventure-323.png",
      path: ["/game/cook-and-match-saras-adventure", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/a853fd25dae64d8f8536b577ac08042b/?gd_sdk_referrer_url=https://playcutegames.com/game/cook-and-match-sara's-adventure",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Cook and Match: Sara's Adventure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Chef Sara needs your help to complete the tile-matching puzzles and collect the ingredients she wants. Make the onions, bell peppers, and cheese wedges swap places, and line up three or more of the same icons to create matches. Bigger combinations will unlock powerful bonus items such as chef’s knives, blenders, and pressure cookers. The objectives for each level will change, bringing a variety of new challenges as you progress through the game.",
      tag: ["Match3","Girl games","Kids","Puzzle"],
      hint: `Tap or click on an icon and drag it to move it to a new tile. You can make two adjacent items swap places, but every move must result in a valid combination of three or more of the same ones. Keep an eye on Sara’s shopping list to see which items you need.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    
    {
      date: "2022-12-12",
      brand:"Crazy games",
     
      orientation: 0,
      title: "Winter Clash 3D - playcutegames.com",
      name: ["Winter Clash 3D", "winter-clash-3d"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/winter-clash-3d/250x142.png",
      path: ["/game/winter-clash-3d", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/winter-clash-3d/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Winter Clash 3D Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Winter Clash 3D is a business simulation game where you run a factory and process these raw materials to create products. Process and pack the products, and send them using delivery vans to fulfill orders. Build more machines, unlock new items, upgrade your workers, and expand your delivery team. Go Big! Go Huge!",
      tag: ["Crazy games","Fight","Shooting","Action","Santa"],
      hint: `
       <ul class="gamelist">
       <li class="para description">WASD or arrow keys = move</li>
       <li class="para description">C = crouch</li>
       <li class="para description">Space = jump</li>
       <li class="para description">Tab = score table</li>
       <li class="para description">Tab = score table</li>
       <li class="para description">Right-click = aim</li>
       <li class="para description">Left-click = shoot</li>
     </ul>`,
      rating:[4.9,350,18],
      platform:"both",
    },
    // Fnf Games
    {
      date: "2024-10-19",
      brand: "Yandex",
      orientation: 0, 
      title: "FNF Indie Cross – Epic Rhythm Battles with Iconic Characters!",
      name: ["FNF Indie Cross", "fnf-indie-cross"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/fnf-indie-cross/250x142.png",
      path: ["/game/fnf-indie-cross", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/199145?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      video: "https://cdn.cutedressup.in/games/cutedressup-video/fnf-indie-cross.mp4",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Welcome to FNF Indie Cross! Dive into the popular Friday Night Funkin mod and test your rhythm skills against iconic characters. Join the fun now!",
      l_dis:
      `Welcome to FNF Indie Cross! If you’re a fan of rhythm and reaction speed games, this popular Friday Night Funkin mod is just what you’ve been looking for. With its exorbitant quality and engaging gameplay, Indie Cross takes the fun to a whole new level, featuring a thrilling lineup of characters from beloved indie games!
       <br><br>
       In this action-packed mod, you’ll face off against iconic characters like Cuphead, Sans, and Bendy. Each character brings their unique style and challenges, ensuring an exhilarating experience as you navigate through catchy tunes and intense rhythm battles. The game is rich with cutscenes that enhance the storyline and add depth to your musical journey.
       <br><br>
       Choose from three difficulty levels: easy, normal, or difficult. This flexibility makes the game accessible to players of all skill levels, whether you’re just starting your rhythm game adventure or you’re a seasoned expert looking for a serious challenge.
       <br><br>
       Your ultimate goal is to achieve the highest score on the game’s ranking leaderboard. Keep your timing sharp, hit those notes in sync with the music, and show off your skills to dominate your opponents.
       <br><br>
       With stunning visuals, captivating music, and a plethora of exciting battles, FNF Indie Cross guarantees that you’ll never experience a dull moment. Jump in and prove you have what it takes to become the ultimate rhythm master!
      `,
      tag: ["FNF"],
      hint: `Use your keybord`,
      rating:[4.9,350,18],
    }, 
    {
      date: "2024-10-19",
      brand: "Yandex",
      orientation: 0, 
      title: "FNF vs Sonic.EXE – Thrilling Rhythm Battles Await! | Fnf Games",
      name: ["FNF vs Sonic.EXE", "fnf-vs-sonic-exe"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/fnf-vs-sonic-exe/250x142.png",
      path: ["/game/fnf-vs-sonic-exe", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/207584?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      video: "https://cdn.cutedressup.in/games/cutedressup-video/fnf-vs-sonic-exe.mp4",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Join FNF vs Sonic.EXE! Face menacing foes in intense rhythm showdowns. Choose Easy, Normal, or Hard and prove your skills on the leaderboard!",
      l_dis:
      `Welcome to FNF vs Sonic.EXE! If you enjoy rhythm and reaction speed games, then this popular Friday Night Funkin’ mod is tailor-made for you. Get ready to face off against the menacing Sonic.EXE and other formidable villains in an adrenaline-pumping musical showdown!
       <br><br>
       In this thrilling mod, you'll immerse yourself in an eerie atmosphere filled with heart-pounding beats and intense challenges. Your mission is to defeat Sonic.EXE, who is determined to put your skills to the ultimate test. As you navigate through catchy tracks, you’ll encounter various villains, each bringing their unique style and flair to the rhythm battles.
       <br><br>
       Choose your preferred difficulty level: easy, normal, or difficult. This ensures that players of all skill levels can join in on the fun, whether you’re new to rhythm games or a seasoned pro looking for a serious challenge.
       <br><br>
       Your objective is to achieve the highest score on the game’s ranking leaderboard. Keep your timing precise and hit those notes perfectly in sync with the music to outshine your opponents and secure your place at the top.
       <br><br>
       With engaging gameplay, vibrant graphics, and a thrilling soundtrack, FNF vs Sonic.EXE promises a captivating experience that will keep you entertained for hours. Dive in and show Sonic.EXE that you have what it takes to become the ultimate rhythm champion!
      `,
      tag: ["FNF"],
      hint: `Use your keybord`,
      rating:[4.9,350,18],
    }, 
    {
      date: "2024-10-19",
      brand: "Yandex",
      orientation: 0,
      title: "Friday Night Dustin – Defeat Sans and Papyrus in Rhythm Battles!",
      name: ["Friday Night Dustin", "friday-night-dustin"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/friday-night-dustin/250x142.png",
      path: ["/game/friday-night-dustin", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/221169?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      video: "https://cdn.cutedressup.in/games/cutedressup-video/friday-night-dustin.mp4",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Join Friday Night Dustin! Test your rhythm skills against Sans and Papyrus. Choose Easy, Normal, or Hard mode, and aim for the highest score in the rankings!",
      l_dis:
      `Welcome to Friday Night Dustin! If you like rhythm and reaction games, then the popular Friday Night Dustin mod is for you. Defeat Sans and Papyrus from UnderTale! Choose this game and you won’t be bored! Choose which mode you want to play in: Easy, Normal or Hard. Achieve the highest score in the game ranking.
      `,
      tag: ["FNF"],
      hint: `
      `,
      rating:[4.9,350,18],
    }, 
    {
      date: "2024-10-19",
      brand: "Yandex",
      orientation: 0, 
      title: "Friday Night Funkin vs Whitty - Epic Rap Battles Await! No Download Need",
      name: ["Friday Night Funkin vs Whitty", "friday-night-funkin-vs-whitty"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/friday-night-funkin-vs-whitty/250x142.png",
      path: ["/game/friday-night-funkin-vs-whitty", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/176204?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      video: "https://cdn.cutedressup.in/games/cutedressup-video/friday-night-funkin-vs-whitty.mp4",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Dive into Friday Night Funkin vs Whitty! Choose Easy, Normal, or Hard mode and test your rhythm skills. Aim for the highest score on the game leaderboard!",
      l_dis:
      `Welcome to Friday Night Funkin vs Whitty! Step into the shoes of the Guy, a determined wannabe rapper and college dropout, who is on a quest to win the heart of his beloved Girlfriend. However, their relationship faces a significant obstacle—her former rock star father, Dear Daddy, who is fiercely protective of his daughter. He doesn’t approve of the Guy's intentions and challenges him to a rap duel, testing his skills and determination.
      <br><br>
      In this thrilling rhythm-based game, players must hit the right notes in time with the music to defeat their opponents and prove their worth. As the Guy, you'll face off against Dear Daddy and other formidable foes who will stop at nothing to derail your progress. Even if you manage to impress Girlfriend’s father, be prepared for more challenges ahead, as others will also try to interfere in your pursuit of love.
      <br><br>
      With catchy beats, vibrant graphics, and a variety of unique characters, Friday Night Funkin Classic delivers an engaging and entertaining experience. Will you be able to prove your worth through music and win over Girlfriend, or will the obstacles prove too great? Jump into the world of Friday Night Funkin Classic and show everyone that love can conquer all—one rap battle at a time!

      `,
      tag: ["FNF"],
      hint: ``,
      rating:[4.9,350,18],
    }, 
    {
      date: "2024-10-19",
      brand: "Yandex",
      orientation: 0, 
      title: "Friday Night Funkin Classic – Epic Rhythm Battles Await!",
      name: ["Friday Night Funkin Classic", "friday-night-funkin-classic"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/friday-night-funkin-classic/250x142.png",
      path: ["/game/friday-night-funkin-classic", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/165389?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      video: "https://cdn.cutedressup.in/games/cutedressup-video/friday-night-funkin-classic.mp4",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Dive into Friday Night Funkin Classic! Choose Easy, Normal, or Hard mode and test your rhythm skills. Aim for the highest score on the game leaderboard!",
      l_dis:
      `Welcome to Friday Night Funkin vs Whitty! If you like rhythm and reaction speed games, then the popular FNF vs White mod is for you. Choose this game and it will not be boring! Choose which mode you want to play: easy, normal or difficult. Achieve the highest result in the rating of the game.
      `,
      tag: ["FNF"],
      hint: ``,
      rating:[4.9,350,18],
    }, 
    {
      date: "2024-10-19",
      brand: "Yandex",
      orientation: 0, 
      title: "Friday Night Funkin vs Sarvente - Play free online Rhythm Games",
      name: ["Friday Night Funkin vs Sarvente", "friday-night-funkin-vs-sarvente"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/friday-night-funkin-vs-sarvente/250x142.png",
      path: ["/game/friday-night-funkin-vs-sarvente", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/179666?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      video: "https://cdn.cutedressup.in/games/cutedressup-video/friday-night-funkin-vs-sarvente.mp4",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Dive into Friday Night Funkin Classic! Choose Easy, Normal, or Hard mode and test your rhythm skills. Aim for the highest score on the game leaderboard!",
      l_dis:
      `Welcome to Friday Night Funkin vs Sarvente! If you’re a fan of rhythm and reaction speed games, this popular FNF mod is perfect for you. Step into the vibrant world of Sarvente, a unique character who adds a fresh twist to the classic FNF gameplay.
       <br><br>
       In this exhilarating mod, you can choose from three difficulty levels: easy, normal, or difficult. Each mode offers a different challenge, allowing players of all skill levels to enjoy the game. Whether you’re just starting or are a seasoned pro, there’s a setting that’s right for you!
       <br><br>
       As you navigate through catchy tracks and engaging battles, your goal is to achieve the highest score on the game’s ranking leaderboard. Timing and precision are key—hit the notes in sync with the rhythm to outshine your opponents and impress Sarvente.
       <br><br>
       With its colorful graphics, catchy music, and challenging gameplay, Friday Night Funkin vs Sarvente ensures that your gaming experience will never be boring. Dive into this rhythm-filled adventure and see if you can conquer each level, proving your skills in the ultimate musical showdown. Will you rise to the top of the leaderboard and earn your place as a rhythm master? Play now and find out!
      `,
      tag: ["FNF"],
      hint: `Use your keybord`,
      rating:[4.9,350,18],
    }, 
    {
      date: "2024-10-19",
      brand: "Yandex",
      orientation: 0, 
      title: "Friday Night Funkin vs Triki – Play free Epic Rhythm Showdown Awaits!",
      name: ["Friday Night Funkin vs Triki", "friday-night-funkin-vs-triki"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/friday-night-funkin-vs-triki/250x142.png",
      path: ["/game/friday-night-funkin-vs-triki", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/184176?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      video: "https://cdn.cutedressup.in/games/cutedressup-video/friday-night-funkin-vs-triki.mp4",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Jump into Friday Night Funkin vs Triki! Choose Easy, Normal, or Hard mode and showcase your rhythm skills to achieve the highest score on the game leaderboard!",
      l_dis:
      `Welcome to Friday Night Funkin vs Triki! If you love rhythm and reaction speed games, this popular FNF mod is just what you need. Step into the vibrant world of Triki, a character that brings unique challenges and exciting beats to the classic FNF experience.
      <br><br>
      <br><br> In this thrilling mod, you can choose your preferred difficulty level: easy, normal, or difficult. Each mode offers a distinct challenge, catering to players of all skill levels. Whether you're a newcomer looking for some fun or an experienced player seeking a serious test of your rhythm skills, there’s a mode that fits your style!
      <br><br> 
      <br><br> As you groove through catchy tracks and face off against Triki, your objective is to achieve the highest score possible on the game's ranking leaderboard. Perfect your timing and hit the notes in sync with the rhythm to outshine your opponents and secure your place at the top.
      <br><br> 
       With its lively graphics, infectious music, and engaging gameplay, Friday Night Funkin vs Triki guarantees that your gaming experience will never be dull. So get ready to dance to the beat and showcase your skills! Will you rise to the challenge and dominate the scoreboard? Play now and find out!
      `,
      tag: ["FNF"],
      hint: `Use your keybord`,
      rating:[4.9,350,18],
    }, 
    {
      date: "2024-10-19",
      brand: "Yandex",
      orientation: 0, 
      title: "Friday Night Funkin vs Hex – Electrifying Rhythm Battles Await!",
      name: ["Friday Night Funkin vs Hex", "friday-night-funkin-vs-hex"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/friday-night-funkin-vs-hex/250x142.png",
      path: ["/game/friday-night-funkin-vs-hex", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/180663?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      video: "https://cdn.cutedressup.in/games/cutedressup-video/friday-night-funkin-vs-hex.mp4",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Join Friday Night Funkin vs Hex! Play for free with no download needed. Get ready to dance and rap your way to the top of the leaderboard!",
      l_dis:
      `Welcome to Friday Night Funkin vs Hex! If you’re a fan of rhythm and reaction speed games, this popular FNF mod is the perfect choice for you. Dive into the electrifying world of Hex, a character that adds a unique twist to the classic FNF gameplay.
       <br><br>
       In this exciting mod, you can select your preferred difficulty level: easy, normal, or difficult. Each mode presents its own set of challenges, ensuring a fun experience for players of all skill levels. Whether you're new to the rhythm game genre or a seasoned pro, there's a setting that will suit your style and help you improve your skills!
       <br><br>
       As you battle through catchy tracks against Hex, your goal is to achieve the highest score on the game’s ranking leaderboard. Keep your timing sharp and hit the notes perfectly in sync with the music to outshine your competition and secure your spot at the top.
       <br><br>
       With vibrant graphics, infectious music, and engaging gameplay, Friday Night Funkin vs Hex promises to keep you entertained for hours. Get ready to dance, rap, and challenge yourself in this rhythmic showdown! Will you rise to the challenge and claim your place among the best? Jump in and find out!
      `,
      tag: ["FNF"],
      hint: `Use your keybord`,
      rating:[4.9,350,18],
    }, 
    
    {
      date: "2022-12-12",
      brand:"Others",
     
      orientation: 1,
      title: "Children Doctor Dentist 2 - playcutegames.com",
      name: ["Children Doctor Dentist 2", "children-doctor-dentist-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/children-doctor-dentist-2/250x142.png",
      path: ["/game/children-doctor-dentist-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/225102bed76847d99248bfe52a2fc17d/?gd_sdk_referrer_url=https://playcutegames.com/game/children-doctor-dentist-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Children Doctor Dentist 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"In our new exciting game, you will manage a real dental hospital. And every day you will have to see many patients - funny animals. All of them have the only problem - their teeth hurt. And all of them need to accept and provide medical care. And actually you have such a unique opportunity. Using modern medical tools, you can help your little patients.",
      tag: ["Doctor","Kids","Dentist","Yiv"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-07",
      brand: "cutedressup",
     
      orientation: 0,
      title: "Super Girls My Rainy Day Outfits - playcutegames.com",
      name: ["Super Girls My Rainy Day Outfits", "super-girls-my-rainy-day-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-my-rainy-day-outfits/250x142.png",
      path: ["/game/super-girls-my-rainy-day-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/super-girls-my-rainy-day-outfits/",
      Walkthrough: "https://www.youtube.com/embed/hb8jHgGvrJ8",
      s_dis:
      "Super Girls My Rainy Day Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Super Girls My Rainy Day Outfits. Who said that you can’t look perfect on a rainy day? A bit of rain shouldn’t stop us from looking fabulous! And don’t even worry about the hair, some curls under a hat look absolutely perfect! How would your perfect rainy day outfit look like? Try creating it while helping these princesses dress up in warmer clothes and rain boots. Accessorize their look with a hat, scarf, and a cute umbrella.",
      tag: ["Winx","Dress up","Atumn","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-05",
      brand:"GD",
     
      orientation: 1,
      title: "Instadiva Nikke Dress Up Tutorial - playcutegames.com",
      name: ["Instadiva Nikke Dress Up Tutorial", "instadiva-nikke-dress-up-tutorial"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/instadiva-nikke-dress-up-tutorial/250x142.png",
      path: ["/game/instadiva-nikke-dress-up-tutorial", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/9a0800df78ba450a92f77b3cbd0ac998/?gd_sdk_referrer_url=https://playcutegames.com/game/instadiva-nikke-dress-up-tutorial",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Instadiva Nikke Dress Up Tutorial Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "It's time to dress up Nikki in this fun dress up game. What're you feeling today? Pick a hairstyle that exhibits Nikki's style. Choose a set of beautiful eyes with thick lashes. Pick a bold top to show how you're feeling. Pants, jeans, skirts, we have them all. And of course, SHOES! We love shoes. Once you're done, it's time to move to the photo studio. Execute a variety of chic poses for the camera and start capturing insta worthy photos!",
      tag: ["Dress up","Instagram","Girl games"],
      hint: `Lights, camera, action! Dress up Nikki on her way to stardom.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-05",
      brand:"GD",
     
      orientation: 0,
      title: "Instagirls: Christmas Dress Up - playcutegames.com",
      name: ["Instagirls: Christmas Dress Up", "instagirls-christmas-dress-up"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/instagirls-christmas-dress-up/250x142.png",
      path: ["/game/instagirls-christmas-dress-up", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/6adbde2ee60748d388c98d41dceef9af/?gd_sdk_referrer_url=https://playcutegames.com/game/instagirls-christmas-dress-up",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Instagirls: Christmas Dress Up Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Instagirls: Christmas Dress Up is a fun fashion game with a cool Christmas theme. In this game, you must help your lovely Instagram Girls dress up for Christmas! You must choose outfits carefully to match with the current trending fashion styles for this holiday season.",
      tag: ["Dress up","Christmas","Girl games","Crazy games"],
      hint: `Press left mouse button.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Crazy games",
     
      orientation: 0,
      title: "Crazy Flips 3D - playcutegames.com",
      name: ["Crazy Flips 3D", "crazy-flips-3d"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/crazy-flips-3d/250x142.png",
      path: ["/game/crazy-flips-3d", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/crazy-flips-3d/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Crazy Flips 3D Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`Crazy Flips 3D is an awesome sports game to do perfect flips, such as frontflips, backflips, and gainer tricks from high cliffs.<br><br>
      Launch into the excitement of Crazy Flips 3D on Play Cute Games! This awesome game lets you do flips and stunts as you launch off big cliffs and try to land on tiny targets. With real 3D physics and dynamic character movement, every flip and somersault is a timing and skill challenge.
As you progress through the levels, you’ll face obstacles and tricky landings that will test your abilities. Master the art of timing your jumps and flips to score big and tackle everything in your path. Show off your flying skills and compare scores with friends for bragging rights. Go wild and let your flipping begin in Crazy Flips 3D!`,
      tag: ["Crazy games","Skill","Kids","3D"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Dream Life - playcutegames.com",
      name: ["Dream Life", "dream-life"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dream-life/250x142.png",
      path: ["/game/dream-life", "https://playcutegames.com"],
      iframe:"https://html5.gamedistribution.com/2d67f0ecb6a84c5cbe1c37a9364843b8/?gd_sdk_referrer_url=https://playcutegames.com/game/dream-life",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Dream Life Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dream Life is a brand new and fun match 3 game. In the game, you can change the positions of the objects to match them and remove these objects, beat the match 3 levels to win the rewards. The game has rich and varied match-3 levels and cute and vivid level elements, allowing you to experience an interesting match-3 adventure journey.",
      tag: ["Doctor","Surgery","Dress up","Christmas","Santa"],
      hint: `Match 3 same colored items to collect them! Match 4 same colored items in a line to create a ROCKET! Match the same colored items in L-shape to create a BOMB! Match 4 same colored items in a square to create a MISSILE! Match 5 same colored items in a line to create a RAINBOW BALL! You can select these boosters to start the level with them! Beat the level, participate in tournament challenge, and win great rewards`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Lagged",
     
      orientation: 0,
      title: "Funny Throat Surgery - playcutegames.com",
      name: ["Funny Throat Surgery", "funny-throat-surgery"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/funny-throat-surgery/250x142.png",
      path: ["/game/funny-throat-surgery", "https://playcutegames.com"],
      iframe:
        "https://lagged.com/api/play2/funny-throat-surgery/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Funny Throat Surgery Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Perform surgery on your patients in this super funny game. Use a variety of different tools to clean up the throats of each kid who goes into your doctors office. Play as a Doctor as you help out patients who are in serious needs of your services.",
      tag: ["Doctor","Kids","Dentist","Lagged"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 0,
      title: "Doc Darling Santa Surgery - playcutegames.com",
      name: ["Doc Darling Santa Surgery", "doc-darling-santa-surgery"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/doc-darling-santa-surgery/250x142.png",
      path: ["/game/doc-darling-santa-surgery", "https://playcutegames.com"],
      iframe:"https://html5.gamedistribution.com/86243dc5144b4782a08a3cc6771e17e5/?gd_sdk_referrer_url=https://playcutegames.com/game/doc-darling-santa-surgery",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Doc Darling Santa Surgery Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Christmas is coming but Santa had an accident on his way! He needs your help to fix Santa's sleight and find his deer. Also bring him to Doc Darling to take care of all injuries and prepare for an emergency surgery. Complete all treatments and dress him up till he gets ready to deliver the gifts for Christmas night!",
      tag: ["Doctor","Surgery","Dress up","Christmas","Santa"],
      hint: `
      Use MOUSE to play the game.`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Others",
     
      orientation: 1,
      title: "Dental Care Game - playcutegames.com",
      name: ["Dental Care Game", "dental-care-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dental-care-game/250x142.png",
      path: ["/game/dental-care-game", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/bac933bb80014eca8b5ec99b0f3e0d3e/?gd_sdk_referrer_url=https://playcutegames.com/game/dental-care-game",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Become the best dentist in the Dental Care Game! Play online for free at PlayCuteGames, where you'll diagnose and treat lively patients with real emotions",
      l_dis:"Boys and girls welcome to the Dentist, Here you can not only turn yourself into a professional dentist but also diagnose and cure different patients, make it you're dreaming one! In the game, patients are not models who just open their mouths with a poker face, they are so vivid with laughers and tears!",
      tag: ["Doctor","Kids","Dentist","Yiv"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Jewel Christmas Story - playcutegames.com",
      name: ["Jewel Christmas Story", "jewel-christmas-story"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/jewel-christmas-story/250x142.png",
      path: ["/game/jewel-christmas-story", "https://playcutegames.com"],
      iframe:"https://html5.gamedistribution.com/c3ce9bfdcdba45248f0267adbb65c6de/?gd_sdk_referrer_url=https://playcutegames.com/game/jewel-christmas-story",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Jewel Christmas Story Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "The most anticipated holiday of the year is here! Christmas is just around the corner and lots of holiday fun full of gifts and sweets is waiting for you in this new match3 game. Collect game currency, achieve combos, and use boosters in various missions.",
      tag: ["Matching","Puzzle","Christmas","Santa","Jewel"],
      hint: `Use your quick-thinking mind and boosters to beat the levels. Match at least three identical items and reach the goal of the missions. Collect game currency, which you can use to buy boosters in the shop and in loot box. Unlock your path to move forward.`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 0,
      title: "Dog Puzzle Story 2 - playcutegames.com",
      name: ["Dog Puzzle Story 2", "dog-puzzle-story-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dog-puzzle-story-2/250x142.png",
      path: ["/game/dog-puzzle-story-2", "https://playcutegames.com"],
      iframe:"https://html5.gamedistribution.com/9921e655b971470fbfa730f30866aae8/?gd_sdk_referrer_url=https://playcutegames.com/game/dog-puzzle-story-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Dog Puzzle Story 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dog Puzzle Story 2 is back together with your much beloved friend, the cute puppy Charlie. Join him on another adventurous journey through colourful Match 3 puzzles full of toys, dog pillows and treats scattered all over the garden! Help Charlie to put all the items in the right place and dig up all the bones! This is going to be so much fun!",
      tag: ["Matching","Puzzle","Girl games"],
      hint: `
      Combine three items of the same kind to collect them or combine four or more to create mighty power-ups. Reach the level goals to earn stars and to advance to the next level. Complete Daily Missions and Challenges to earn additional rewards that will help Charlie solve tricky puzzles. Join Charlie on this magical adventure and enjoy countless hours of Match 3 puzzle fun!`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-03",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Trendy Ball Gown - playcutegames.com",
      name: ["My Trendy Ball Gown", "my-trendy-ball-gown"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-trendy-ball-gown/250x142.png",
      path: ["/game/my-trendy-ball-gown", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/my-trendy-ball-gown/",
      Walkthrough: "https://www.youtube.com/embed/c6cLz_X4Sg8",
      s_dis:
      "My Trendy Ball Gown Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Trendy Ball Gown. Rainbow Girls are going to be styled by you for the prom, but before that help them to choose the best outfit, also accessorize it using earrings, necklaces, and purses, and you can pick the hairstyle you think will suit the girl the best, simple as that. Enjoy!",
      tag: ["Rainbow Girls","Dress up","Black Friday","Holiday","Exclusive","Girl games", "Thanksgiving","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-11-30",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Black Friday Collection - playcutegames.com",
      name: ["BFFs Black Friday Collection", "bffs-black-friday-collection"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-black-friday-collection/250x142.png",
      path: ["/game/bffs-black-friday-collection", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/bffs-black-friday-collection/",
      Walkthrough: "https://www.youtube.com/embed/XKnZGw9Ux-M",
      s_dis:
      "BFFs Black Friday Collection Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs Black Friday Collection. Bffs already filled the closet with a new Black Friday collection. Help them to pick the perfect costume. Don’t forget to pick a fabulous hairstyle. They are all excited like us for this day. Join and help the girls with your skill. Play and have fun!",
      tag: ["Winx","Dress up","Black Friday","Holiday","Exclusive","Girl games", "Thanksgiving","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-11-26",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Ellie Thanksgiving Day - playcutegames.com",
      name: ["Ellie Thanksgiving Day", "ellie-thanksgiving-day"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-thanksgiving-day/250x142.png",
      path: ["/game/ellie-thanksgiving-day", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/ellie-thanksgiving-day/",
      Walkthrough: "https://www.youtube.com/embed/axGgjVHwHOY",
      s_dis:
      "Ellie Thanksgiving Day Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Ellie Thanksgiving Day. It’s Thanksgiving Day, and like every year, young Ellie is in charge of the Thanksgiving dinner. Her friends will start arriving soon, and there are still a few things that need her attention. Will you help her with the last preparations? Join and help beautiful Ellie to surprise her guests with a perfect dinner!",
      tag: ["Barbie","Dress up","Cooking","Holiday","Exclusive","Girl games", "Thanksgiving","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      id: 20211125,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Ellie And Ben Christmas Preparation - playcutegames.com",
      name: [
        "Ellie And Ben Christmas Preparation",
        "ellie-and-ben-christmas-preparation",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-ben-christmas-preparation/250x142.png",
      path: [
        "/game/ellie-and-ben-christmas-preparation",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.cutedressup.net/ellie-and-ben-christmas-preparation/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Ellie And Ben Christmas Preparation. Ellie and Ben are the new couples going to celebrate the upcoming Christmas together.",
      l_dis:
        `Welcome to the Ellie And Ben Christmas Preparation. Ellie and Ben are the new couples going to celebrate the upcoming Christmas together. They have some plans to do!. Like decorate the interior with beautiful Christmas things and decorate their Christmas Tree. Join and Help the couples to make this Christmas the most memorable one!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Barbie",
        "Dress up",
        "Make Up",
        "Christmas",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Lagged",
     
      orientation: 1,
      title: "Doctor Teeth 3 - playcutegames.com",
      name: ["Doctor Teeth 3", "doctor-teeth-3"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/doctor-teeth-3/250x142.png",
      path: ["/game/doctor-teeth-3", "https://playcutegames.com"],
      iframe:
        "https://lagged.com/games/play2/doctorteeth34/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Doctor Teeth 3 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Brush, clean, pull and drill teeth in this funny hygiene game! Use the plethora of tools available to remove plaque, tartar and even sometimes teeth! Be sure to read the patient's checklist so that you are not performing any unnecessary procedures on innocent paying customers!",
      tag: ["Doctor","Kids","Dentist","Lagged"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      id: 202111265,
  
      brand: "playcutegames",
     
      orientation: 0,
      title: "3D Christmas Island Tree Decor - playcutegames.com",
      name: ["3D Christmas Island Tree Decor", "3d-christmas-island-tree-decor"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/3d-christmas-island-tree-decor/250x142.png",
      path: [
        "/game/3d-christmas-island-tree-decor",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/3d-christmas-island-tree-decor/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the 3D Christmas Island Tree Decor game. Hey kids! Happy Christmas to everyone! Enjoy playing this new 3d Christmas game and have wonderful Christmas! This game consists of two levels.",
      l_dis:
        "Welcome to the 3D Christmas Island Tree Decor game. Hey kids! Happy Christmas to everyone! Enjoy playing this new 3d Christmas game and have wonderful Christmas! This game consists of two levels. First, start with Christmas tree Decoration and then you have to decor the room for Christmas Celebration. Both decor level has a variety of items to decorate. Have fun!",
      tag: ["Christmas", "3D Games", "Winter","Decoration", "playcutegames", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211125,
  
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bff Christmas Cookie Challenge - playcutegames.com",
      name: ["Bff Christmas Cookie Challenge", "bff-christmas-cookie-challenge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-christmas-cookie-challenge/250x142.png",
      path: [
        "/game/bff-christmas-cookie-challenge",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/bff-christmas-cookie-challenge/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Bff Christmas Cookie Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home.",
      l_dis:
        "Welcome to the Rainbow Girls Christmas Outfits. Rainbow girls Skyler, Sunny, Ruby, and Violet get ready for Christmas celebrations. They want to try cute Christmas outfits with crazy makeovers. Browse their wardrobe for cute outfits and pick some matching accessories. Join with these girls and enjoy this Christmas a most memorable one. Have fun!",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Christmas",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      date: "2022-11-08",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Unique Prom Look - playcutegames.com",
      name: ["My Unique Prom Look", "my-unique-prom-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-unique-prom-look/250x142.png",
      path: ["/game/my-unique-prom-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/my-unique-prom-look/",
      Walkthrough: "https://www.youtube.com/embed/mX-gSB16lJs",
      s_dis:
      "My Unique Prom Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Unique Prom Look. The prom night is tomorrow, and these girls are so excited! They planned to meet up and prepare for the prom together. They also decided to call up a stylist to get help with their makeup and hair. Therefore, here is your chance to be their stylist! Prove your fashion and makeup skills by creating their lovely prom look. Select which dress each girl is going to wear and accessorize it. The wardrobe is filled with the most breathtaking gowns!",
      tag: ["LOL Surprise","Dress up","Fashion","Prom","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-11-10",
      brand: "bestgamespot",
     
      orientation: 0,
      title: "Angry Flocks - playcutegames.com",
      name: ["Angry Flocks", "angry-flocks"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angry-flocks/250x142.png",
      path: ["/game/angry-flocks", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/angry-flocks/",
      Walkthrough: "https://www.youtube.com/embed/w9J9yEaE64E",
      s_dis:
      "Angry Flocks Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Angry Flocks. Join the Angry Flocks to destroy all aliens and save the world. Master the slingshot to take on the aliens. Use the special powers to destroy every shelter and complete all levels. Defeat Bosses and help the little heroes to unlock new power-ups in this amazing fantasy journey. Enjoy these tricky 40 levels and have fun !",
      tag: ["Action","Skill","Puzzle","Kids","Arcade","Exclusive","Girl games"],
      hint: ``,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-12-12",
      brand:"Lagged",
     
      orientation: 1,
      title: "Doctor Teeth - playcutegames.com",
      name: ["Doctor Teeth", "doctor-teeth"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/doctor-teeth/250x142.png",
      path: ["/game/doctor-teeth", "https://playcutegames.com"],
      iframe:
        "https://play.famobi.com/doctor-teeth/A-0D1OG",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Doctor Teeth Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`Put on your dental gloves and get ready to have some fun with Doctor Teeth Game on Play Cute Games! In this game you’ll do all sorts of dental procedures like cleaning teeth, filling cavities and using real tools to give your patients the perfect smile. Each task from polishing to filling cavities will test your skills as you fix your patients’ dental health. With colorful graphics and interactive gameplay you’ll learn about dental care while having fun. Try different treatments, solve dental problems and watch your patients leave with brighter healthier smiles. It’s a mix of creativity and problem solving wrapped up in a fun dental adventure!`,
      tag: ["Doctor","Kids","Dentist","Lagged"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-11-08",
      brand: "playcutegames",
     
      orientation: 0,
      title: "PFW Fall Ready To Wear Season 1 - playcutegames.com",
      name: ["PFW Fall Ready To Wear Season 1", "pfw-fall-ready-to-wear-season-1"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pfw-fall-ready-to-wear-season-1/250x142.png",
      path: ["/game/pfw-fall-ready-to-wear-season-1", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/pfw-fall-ready-to-wear-season-1/",
      Walkthrough: "https://www.youtube.com/embed/jll4JIO39AY",
      s_dis:
      "PFW Fall Ready To Wear Season 1 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to PFW Fall Ready To Wear Season 1. Celebrity girls are ready for their next fashion adventure. Yes! It’s Paris Fashion Week. Help them to be more unique in their look for this wonderful event. Browse their wardrobe and select the fabulous outfits for this event. Don’t forget to choose the matching accessories. Play and have fun!",
        tag: ["PFW","Dress up","Fashion","Celebrity","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-08-05",
      brand: "GD",
     
      orientation: 1,
      title: "Garden Tales - playcutegames.com",
      name: ["Garden Tales", "garden-tales"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/garden-tales/250x142.png",
      path: ["/game/garden-tales", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/fa29efc197d04cb1bb3074b8255f817f/?gd_sdk_referrer_url=https://playcutegames.com/games/garden-tales",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Garden Tales Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "The colorful match 3 game Garden Tales takes you to a beautifully landscaped garden. Combine and collect flowers, fruit, and mushrooms in over 700 exciting levels. The more flowers and fruits of the same kind you combine, the cooler the extras you get, making the game easier for you.Help the garden gnome Willy keep the beds accurate and free from weeds. Remove ice, puddles, stones and other debris, collect fruit baskets or go treasure hunting with your spade. But beware of the moles, they will ruin your whole garden! Complete the daily missions or the daily challenge to earn additional coins and other gifts. Enjoy the catchy soundtrack and challenging levels. Play Garden Tales now for free.",
      tag: ["Match3","Brain","Puzzle","Challenge","Kids","Girl games"],
      hint: `
      Travel around the map by completing the levels.<br><br> Use your mouse or the touch controls to swap the fruits and vegetables.<br><br> Match at least three same items to clear the board and match 4 or more to get boosters.`,
      rating:[4.2,110,14],
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 0,
      title: "My Pony Designer - playcutegames.com",
      name: ["My Pony Designer", "my-pony-designer"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-pony-designer/250x142.png",
      path: ["/game/my-pony-designer", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/41708b4a3a864c5a85505cc6d59f0d23/?gd_sdk_referrer_url=https://playcutegames.com/game/my-pony-designer",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Pony Designer Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Pony creator game where you can create your magic pony. The design is all yours, choose head shape, tail, body, wings and a nice garment. Color your pony as you wish, and add some stickers for final touch. At the end of the game you can take a photo of your pony and download it. Show your creation to your friends and family.",
      tag: ["Pony","Unicorn","Kids","Girl games","Pet"],
      hint: `Use your mouse to play, or tap the screen.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Pony Pet Salon - playcutegames.com",
      name: ["Pony Pet Salon", "pony-pet-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pony-pet-salon/250x142.png",
      path: ["/game/pony-pet-salon", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/7076a9f1d7d94c729b56f648fcbad84f/?gd_sdk_referrer_url=https://playcutegames.com/game/pony-pet-salon",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Pony Pet Salon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Even ponies like to look their best! Can you help these stylish ones get all cleaned up at the awesome pet salon in this online game? You can give them a bath, comb their hair, and more!",
      tag: ["Pony","Unicorn","Kids","Salon","Beauty","Girl games","Pet"],
      hint: `Tap to play`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 0,
      title: "Unicorn Beauty Salon - playcutegames.com",
      name: ["Unicorn Beauty Salon", "unicorn-beauty-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/unicorn-beauty-salon/250x142.png",
      path: ["/game/unicorn-beauty-salon", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/ce629d0a5cb8483aafe599539511be50/?gd_sdk_referrer_url=https://playcutegames.com/game/unicorn-beauty-salon",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Unicorn Beauty Salon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Unicorn Beauty Salon is a fun makeover for unicorn game with amazing graphics and customizations. Customize your unicorn for his best look! Play Unicorn Beauty Salon for great fun!",
      tag: ["Pony","Unicorn","Kids","Salon","Beauty","Girl games","Cute"],
      hint: `Tap to play`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 0,
      title: "Unicorn Beauty Salon 1 - playcutegames.com",
      name: ["Unicorn Beauty Salon 1", "unicorn-beauty-salon-1"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/unicorn-beauty-salon-1/250x142.png",
      path: ["/game/unicorn-beauty-salon-1", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/rvvASMiM/f68b4b4d812f4aa888c949c2d22426cc/index.html?",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Unicorn Beauty Salon 1 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Adventures can get rough sometimes and this magical unicorn just returned from one that scrapped her up a bit. Can you help her feel better while she gets a makeover at the enchanted salon in this online game for girls?",
      tag: ["Pony","Unicorn","Kids","Salon","Beauty","Girl games","Cute"],
      hint: ` Click or drag with the mouse to complete`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 0,
      title: "Pony Friendship - playcutegames.com",
      name: ["Pony Friendship", "pony-friendship"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pony-friendship/250x142.png",
      path: ["/game/pony-friendship", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/09400b9f9e704dc88b4878777f1faa2a/?gd_sdk_referrer_url=https://playcutegames.com/game/pony-friendship",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Pony Friendship Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Pony Friendship is a logic game where little ponies get lost in the forest. To help them, it is necessary, observing the rules of mirror reflection, to meet in the center of the location. -Easy logic game -You can improve the result every time",
      tag: ["Pony","Unicorn","Kids","Girl games","Cute","3D"],
      hint: ` `,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Unicorn Dress Up: Girls Games - playcutegames.com",
      name: ["Unicorn Dress Up: Girls Games", "unicorn-dress-up-girls-games"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/unicorn-dress-up-girls-games/250x142.png",
      path: ["/game/unicorn-dress-up-girls-games", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/68cd9d769660434db66b1600e4a1a173/?gd_sdk_referrer_url=https://playcutegames.com/game/unicorn-dress-up-girls-games",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Unicorn Dress Up: Girls Games Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Girl games with unicorns - magical horses with a horn on their head. In dress up unicorn games for little girls you can create a huge number of pony outfits from over 180 fantasy items. A real feast to all cute horses, glitter fashion clothing, animal outfits and fairy tales lovers! It’s your perfect time to be a fashion designer. Enter the wonderful pony dress-up world full of super clothes. Children love dressing up such mythical animals as dragons, elves, fairies, mermaid dolls and, of course, colorful unicorns! Perhaps before going to bed you listened to fairy tales about them or read books about how beautiful these creatures are? In fact, if you dress up little ponies and attach a long stylish horn to their forehead, you can make your own unicorn! All you need is adorable ? games: make as many fantasy characters and decorations as you want, create your unique rainbow pony, become the cutest unicorn makeover artist.",
      tag: ["Pony","Unicorn","Kids","Dress up","Girl games","Racing","Cute"],
      hint: `Use left mouse to change outfit.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Crazy games",
     
      orientation: 0,
      title: "My Pony: My Little Race - playcutegames.com",
      name: ["My Pony: My Little Race", "my-pony-my-little-race"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-pony-my-little-race/250x142.png",
      path: ["/game/my-pony-my-little-race", "https://playcutegames.com"],
      iframe:
        "https://playzool.com/games/myPonyMLR/My-Pony-My-Little-Race",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Pony: My Little Race Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"My Pony: My Little Race is a fun pony racing game for kids. Ride your pony and avoid obstacles to reach the finish line as fast as possible.",
      tag: ["Pony","Unicorn","Kids","Girl games","Racing","Cute","Crazy games"],
      hint: ` <ul class="gamelist">
      <li class="para description"> Right arrow to start the race/speed up</li>
      <li class="para description">Up arrow to jump</li>
       </ul>
      `,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-11-04",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Paris Fashion Week Fall Couture - playcutegames.com",
      name: ["Paris Fashion Week Fall Couture", "paris-fashion-week-fall-couture"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/paris-fashion-week-fall-couture/250x142.png",
      path: ["/game/paris-fashion-week-fall-couture", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/paris-fashion-week-fall-couture/",
      Walkthrough: "https://www.youtube.com/embed/jll4JIO39AY",
      s_dis:
      "Paris Fashion Week Fall Couture is here! Team up with your BFFs to pick amazing outfits and showcase the latest trends. Play free at PlayCuteGames!",
      l_dis:
        "Welcome to Paris Fashion Week Fall Couture. Join with Bffs in their awesome journey in Paris Fashion Week and prepare yourself to be amazed by the designers, models, and trends gracing the runways. Help the girls to choose the perfect fashion costumes. Have fun!",
        tag: ["LOL Surprise","Dress up","Fashion","Autumn","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-11-03",
      brand: "playcutegames",
     
      orientation: 0,
      title: "NYFW Street Style - playcutegames.com",
      name: ["NYFW Street Style", "nyfw-street-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/nyfw-street-style/250x142.png",
      path: ["/game/nyfw-street-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/nyfw-street-style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "NYFW Street Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to NYFW Street Style. Rainbow girls are excited to try some really amazing street-style fashion outfits and accessories! So, play it and get inspired for your own wardrobe! Check out all those fabulous items and choose your favorites to dress the beautiful model up with style! She should look amazing in the end, so make sure you pick up the perfect outfit for her!",
      tag: ["Rainbow High","Dress up","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    // Poki Ga
    // Yad Games
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Helicopter Rescue - playcutegames.com",
      name: ["Helicopter Rescue", "helicopter-rescue"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/helicopter-rescue/250x142.png",
      path: ["/game/helicopter-rescue", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/35cd27c173ba4d31b3845365a65e891e/?gd_sdk_referrer_url=https://playcutegames.com/game/helicopter-rescue ",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Helicopter Rescue Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Helicopter Rescue is a third-person shooter. If you like to play shooting games, then Helicopter Escape 3D you can't miss! Your mission is to rescue the hostages from the enemy and then let them escape on the plane without any problem. Concentrate on your mission and shoot the enemies quickly to avoid them catching up with the hostages. Come join us and start your rescue journey!",
      tag: ["Yad","Shooting","Fight","Action","3D","RPG","Kids"],
      hint: `Slide to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Agent Alpha - playcutegames.com",
      name: ["Agent Alpha", "agent-alpha"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/agent-alpha/250x142.png",
      path: ["/game/agent-alpha", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/57c1f7d03843411a938072a75e3295a6/?gd_sdk_referrer_url=https://playcutegames.com/game/agent-alpha",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Agent Alpha Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Agent Alpha is a casual shooting game designed for boys. If you like shooting games, you can't go wrong with Squad Alpha! The gameplay is very simple, slide the screen to move and defeat all the enemies to successfully enter the next level. More cool guns are waiting for you to unlock! Join now, press the trigger wildly and feel the fun of shooting the game you! Have fun!",
      tag: ["Yad","Shooting","Fight","Action","3D","RPG","Kids"],
      hint: `Slide to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Dressing Up Rush - playcutegames.com",
      name: ["Dressing Up Rush", "dressing-up-rush"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dressing-up-rush/250x142.png",
      path: ["/game/dressing-up-rush", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/6658a5b1bb434026a76a6c9e3b0d7aa4/?gd_sdk_referrer_url=https://playcutegames.com/game/dressing-up-rush",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Dressing Up Rush Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "I'm going to recommend you a new fantastic arcade game. It is named Dress Up Run! You won't choose your own style or clothes anymore. In this game, you need to dress up the predetermined clothes to match at the end. The better the match, the easier it is to win. So you have to be careful when dressing up or collecting. I would say that you will like this game! Try it out now! Enjoy it!",
      tag: ["Yad","Girl games","Dress up","Running","Fashion","3D","Relaxing","Collecting"],
      hint: `Slide to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Couple Rich Rush - playcutegames.com",
      name: ["Couple Rich Rush", "couple-rich-rush"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/couple-rich-rush/250x142.png",
      path: ["/game/couple-rich-rush", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/06b590c358c9443d99bc1e8daf1c674a/?gd_sdk_referrer_url=https://playcutegames.com/game/couple-rich-rush",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Couple Rich Rush Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Couple Rich Rush is a couple of parkour arcade games. Your task is to play as the couple and help them make money together and increase their wealth. The couple can pass money to each other and increase their money through the green and front doors. Don't spend your money and don't eat or drink a lot. The money you save can be used to furnish the house and dress up your house in a gorgeous way!",
      tag: ["Yad","Girl games","Kids","Running","Fashion","3D","Relaxing","Collecting"],
      hint: `Slide to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Catwalk Girl Challenge - playcutegames.com",
      name: ["Catwalk Girl Challenge", "catwalk-girl-challenge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/catwalk-girl-challenge/250x142.png",
      path: ["/game/catwalk-girl-challenge", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/c8d558321b454bc79f32e8ec30580daf/?gd_sdk_referrer_url=https://playcutegames.com/game/catwalk-girl-challenge",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Catwalk Girl Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Catwalk Girl Challenge is a unique fashion walk casual game. Do you usually like watching fashion shows? Come and join us to experience the fun of the catwalk! Choose your clothes according to the theme of each level and test your reflexes and aesthetic skills. In the end, the judges will score and the party with the highest score will win. Enjoy the glory of being in the spotlight!",
      tag: ["Yad","Girl games","Skill","Running","Fashion","3D","challenge","Collecting"],
      hint: `Slide to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Bridge Water Rush - playcutegames.com",
      name: ["Bridge Water Rush", "bridge-water-rush"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bridge-water-rush/250x142.png",
      path: ["/game/bridge-water-rush", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/6d1c6b3a959b461587ebcac0b4d8fc56/?gd_sdk_referrer_url=https://playcutegames.com/game/bridge-water-rush",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Bridge Water Rush Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Bridge Water Rush is an addictive water bridge-building race casual entertainment game. Build your own bridge on the water by competing with others! Collect the purple wooden strips and transport them to the lanes to build automatically. The fastest player wins. Can you finish first with your speed? Take the challenge! Good luck!",
      tag: ["Yad","Kids","Skill","Running","Ball","3D","Relaxing","Collecting"],
      hint: `Slide to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "Yad",
     
      orientation: 1,
      title: "Going Balls - playcutegames.com",
      name: ["Going Balls", "going-balls"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/going-balls/250x142.png",
      path: ["/game/going-balls", "https://playcutegames.com"],
      iframe:
        "https://www.yad.com/games/Going-Balls/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Going Balls Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Going Balls is a fun army combat game. As a great army commander, it's time to lead your army into battle! Build turrets, unlock soldiers and defend against the enemy invasion. Collect as much gold as possible to unlock weapons and soldiers. Gather your army and defend your base well. Don't forget to raise your rank, expelling the enemy is your only goal. Good luck!",
      tag: ["Yad","Kids","Skill","Running","Ball","3D","Relaxing"],
      hint: `Drag to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "Yad",
     
      orientation: 1,
      title: "Army Defender - playcutegames.com",
      name: ["Army Defender", "army-defender"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/army-defender/250x142.png",
      path: ["/game/army-defender", "https://playcutegames.com"],
      iframe:
        "https://www.yad.com/games/Army-Defender/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Army Defender Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Army Defender is a fun army combat game. As a great army commander, it's time to lead your army into battle! Build turrets, unlock soldiers and defend against the enemy invasion. Collect as much gold as possible to unlock weapons and soldiers. Gather your army and defend your base well. Don't forget to raise your rank, expelling the enemy is your only goal. Good luck!",
      tag: ["Yad","Fight","Action","Strategy","RPG"],
      hint: `Drag to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-11-01",
      brand: "GD",
     
      orientation: 0,
      title: "Dino Rush Hypercasual Runner - playcutegames.com",
      name: ["Dino Rush Hypercasual Runner", "dino-rush-hypercasual-runner"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dino-rush-hypercasual-runner/250x142.png",
      path: ["/game/dino-rush-hypercasual-runner", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/e8d75668b9e74d578537e073f78168bf/?gd_sdk_referrer_url=https://playcutegames.com/game/dino-rush-hypercasual-runner",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Dino Rush Hypercasual Runner Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "The world of Red Dead Redemption is embodied in a hypercasual Dinosaur game. Escape from the police, enjoy the world of the Wild West. Dino rush as ARK will only allow you to play light and relax while making races.",
      tag: ["Arcade","Running","Hypercasual","Kids","Action","Adventure","Poki"],
      hint: `Click left mouse buttone to play Swerve to move Run, run, run`,
      rating:[4.9,350,18],
    },
    
    {
      date: "2022-10-27",
      brand: "Gamesnacks",
     
      orientation: 1,
      title: "Aliens Gone Wild - playcutegames.com",
      name: ["Aliens Gone Wild", "aliens-gone-wild"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/aliens-gone-wild/250x142.png",
      path: ["/game/aliens-gone-wild", "https://playcutegames.com"],
      iframe:
        "https://cdn.gamesnacks.com/aliensgonewild1/gameCode/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Aliens Gone Wild Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Aliens Gone Wild. Aliens have gone wild and invaded the Wild West. Play as a cowboy out to take revenge and destroy all the alien invaders in his land. But Aliens will not let you come either! They are in position and ready to shoot and defend the wild west. Dodge their shots and aim your auto-shooting guns at them. Grab power-ups to improve your firepower! and heart to increase your health and, allies to help you combat the aliens!",
      tag: ["Shooting","Skill","Arcade","Action","Poki"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-27",
      brand: "GD",
     
      orientation: 1,
      title: "Rabbids Wild Race - playcutegames.com",
      name: ["Rabbids Wild Race", "rabbids-wild-race"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rabbids-wild-race/250x142.png",
      path: ["/game/rabbids-wild-race", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/1a353bd608a84d7598621034b3e94f50/?gd_sdk_referrer_url=https://playcutegames.com/game/rabbids-wild-race/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Rabbids Wild Race Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Rabbids Wild Race. Fly as far as you can and collect stars before the other naughty Rabbids! Dress-up and ready your Jetpack, it’s time to Race! Challenge your friends to a wacky race! Avoid enemies and obstacles! Collect coins and unlock crazy outfits! Use naughty tricks to annoy other players!",
      tag: ["Multiplayer","Io","Kids","Action","Racing","Crazy games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-12",
      brand: "cutedressup",
     
      orientation: 0,
      title: "Half And Half Celebrity Style - playcutegames.com",
      name: ["Half And Half Celebrity Style", "half-and-half-celebrity-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/half-and-half-celebrity-style/250x142.png",
      path: ["/game/half-and-half-celebrity-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/half-and-half-celebrity-style/",
      Walkthrough: "https://www.youtube.com/embed/kmW84YTKi9c",
      s_dis:
      "Half And Half Celebrity Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Half And Half Celebrity Style. Celebrity wants to try brand new half & half-style. It’s now popular all over social media. Get them ready for a night out and mix and match the clothes to create unique half & half looks. Use your fashion skill and help the celebrity for this new fashion.",
        tag: ["Celebrity","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-12",
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFFs Welcome Fall Look - playcutegames.com",
      name: ["BFFs Welcome Fall Look", "bffs-welcome-fall-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-welcome-fall-look/250x142.png",
      path: ["/game/bffs-welcome-fall-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/bffs-welcome-fall-look/",
      Walkthrough: "https://www.youtube.com/embed/I7vVzKwdABQ",
      s_dis:
      "BFFs Welcome Fall Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs Welcome Fall Look. Autumn is a beautiful season. Bffs is going to throw a party in the forest. They invited other friends to the party. Open the closet and dress up for the Bffs and her friends. An accessory is a must for a great look. Enjoy the game called BFFs Welcome Fall Look!",
        tag: ["Autumn","Princess","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-11",
      brand: "bestgamespot",
     
      orientation: 1,
      title: "Spiny maze puzzle - playcutegames.com",
      name: ["Spiny maze puzzle", "spiny-maze-puzzle"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/spiny-maze-puzzle/250x142.png",
      path: ["/game/spiny-maze-puzzle", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/spiny-maze-puzzle/",
      Walkthrough: "https://www.youtube.com/embed/dYvj9QbPvhE",
      s_dis:
      "Spiny maze puzzle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Spiny maze puzzle. In this game, you have to put the tiny ball into the hole. But it's not easy you can rotate the circle maze using an Arrow key or touch the screen left half screen to rotate the left side and the right half screen to the rotate right side. The spiny maze puzzle contains 30 challenging levels. play and have fun.",
      tag: ["Skill","Kids","Arcade","Exclusive"],
      hint: `Rotate the circle maze using an Arrow key or touch the screen left half screen to rotate the left side and the right half screen to the rotate right side.`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-11",
      brand: "Gamesnacks",
     
      orientation: 1,
      title: "Panda Pizza Parlor - playcutegames.com",
      name: ["Panda Pizza Parlor", "panda-pizza-parlor"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/panda-pizza-parlor/250x142.png",
      path: ["/game/panda-pizza-parlor", "https://playcutegames.com"],
      iframe:
        "https://cdn.gamesnacks.com/pandapizzaparlor/gameCode/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Panda Pizza Parlor Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Panda Pizza Parlor. This cute panda is now ready to manage a pizza business and serve yummy pizza to its customers! Move like a busy bee and prepare pizza dough, and sauce and cook it before serving it hot to your hungry customers! Help the panda serve as many customers as he can for the day. The pizza menu has new offers as the level progresses. Have fun playing this fun pizza management game here! ",
      tag: ["Puzzle","Skill","Kids","Arcade","Exclusive"],
      hint: ``,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-06",
      brand: "cutedressup",
     
      orientation: 1  ,
      title: "Stylish Fashion Challenge - playcutegames.com",
      name: ["Stylish Fashion Challenge", "stylish-fashion-challenge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/stylish-fashion-challenge/250x142.png",
      path: ["/game/stylish-fashion-challenge", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/stylish-fashion-challenge/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Stylish Fashion Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Stylish Fashion Challenge.  A stylish Fashionista plans to show her fashion skill to her opponent. Join and play as a fashion designer for her. Help her to pick a nice costume for each event and compare her costumes with other players. Browse her wardrobe and pick outfits and costumes which is suitable for the events. Play and have fun!",
        tag: ["Multiplayer","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-06",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Funny Rescue Sumo - playcutegames.com",
      name: ["Funny Rescue Sumo", "funny-rescue-sumo"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/funny-rescue-sumo/250x142.png",
      path: ["/game/funny-rescue-sumo", "https://playcutegames.com"],
      iframe:
        "https://www.crazygames.com/embed/funny-rescue-sumo",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Play Funny Rescue Sumo online at PlayCuteGames! Help sumo wrestlers in hilarious situations. Enjoy the best rescue game and have fun with every play!",
      l_dis:
      "Funny Rescue Sumo is a fun game where you must take care of the sumo that looks messy after his battle. Rescue him immediately. Help him recover from the wounds, and don't forget to give him some makeovers.",
      tag: ["Doctor","Skill","Kids","Crazy games"],
      hint: `Use the left mouse button to interact with the in-game UI.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-10-06",
      brand: "Crazy games",
     
      orientation: 1,
      title: "Flip The Box - playcutegames.com",
      name: ["Flip The Box", "flip-the-box"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/flip-the-box/250x142.png",
      path: ["/game/flip-the-box", "https://playcutegames.com"],
      iframe:
        "https://www.crazygames.com/embed/flip-the-box",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Flip The Box Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Flip The Box is a puzzle game where you must paint all the squares on the floor with colors using a block. This game comes with different and unique mechanics. Embark on this challenging game!",
      tag: ["Puzzle","Skill","Kids","Crazy games"],
      hint: `Use the WASD / arrow keys / hold and slide the left mouse button to move the box.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-10-06",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Scary Horror Escape Room - playcutegames.com",
      name: ["Scary Horror Escape Room", "scary-horror-escape-room"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/scary-horror-escape-room/250x142.png",
      path: ["/game/scary-horror-escape-room", "https://playcutegames.com"],
      iframe:
        "https://www.crazygames.com/embed/scary-horror-escape-room-games",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Scary Horror Escape Room Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Scary Horror Escape Room is a creepy escape room game set in a mysterious place. You need to solve tricky puzzles and escape the place alive!",
      tag: ["Puzzle","Skill","Kids","Crazy games"],
      hint: `Use the left mouse button to interact with the objects and follow the in-game tutorial.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-10-06",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Color Galaxy - playcutegames.com",
      name: ["Color Galaxy", "color-galaxy"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/color-galaxy/250x142.png",
      path: ["/game/color-galaxy", "https://playcutegames.com"],
      iframe:
        "https://www.crazygames.com/embed/color-galaxy",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Color Galaxy Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Color Galaxy is a fun arcade-style .io game where you must create a territory as big as possible. Choose the coolest car, a pattern of your territory, and also a cute sticker to make your appearance even more interesting! Have fun driving!",
      tag: ["Arcade","3D","Skill","Kids","Multiplayer","Io","Girl games","Crazy games"],
      hint: `Drag the left mouse button to move the car.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-10-01",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Super Girls Fall Fashion trends - playcutegames.com",
      name: ["Super Girls Fall Fashion trends", "super-girls-fall-fashion-trends"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-fall-fashion-trends/250x142.png",
      path: ["/game/super-girls-fall-fashion-trends", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/super-girls-fall-fashion-trends/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Super Girls Fall Fashion trends Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Super Girls Fall Fashion trends. If you want to stay trendy this autumn play this new game called Super Girls Fall Fashion trends to dress up the super girls. These cute girls are real fashion addicts and they have just renewed their wardrobe. The girls are ready for autumn so hurry up and explore their wardrobe to see what kind of fashionable clothing pieces they hide there.",
        tag: ["Winx","Dress up","Bff","Autumn","Fall","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Ready For Christmas - playcutegames.com",
      name: ["Princess Ready For Christmas", "princess-ready-for-christmas"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-ready-for-christmas/250x142.png",
      path: ["/game/princess-ready-for-christmas", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_ready_for_christmas/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this cute game Princess Ready For Christmas, Princess Anna, and Elsa get ready for their Christmas celebrations. The excitement always is at a peak in the palace.",
      l_dis:
        `In this cute game Princess Ready For Christmas, Princess Anna, and Elsa get ready for their Christmas celebrations. The excitement always is at a peak in the palace. The celebrations are round the corner with everyone gearing up towards funny dances, cute dress up, and long party nights. Being a princess is even more fun.Let’s help the princess wear a cool party outfit for Christmas Eve and then you will need to help her with the dresses because this is the biggest event in the Palace. Last but not least, help her decorate her Christmas tree with a majestic look.At first, help princess Elsa to choose cute Christmas costumes with cute hairstyles and accessories. Now its a turn for Princess Anna, Help her to choose outfit, hairstyles and accessories.Finally it time to decorate their Christmas tree. Decorate with cute colors and decorative things.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Christmas",
        "Decoration",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      date: "2022-10-01",
      brand: "cutedressup",
     
      orientation: 1,
      title: "Cat Girl Fashion Challenge - playcutegames.com",
      name: ["Cat Girl Fashion Challenge", "cat-girl-fashion-challenge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cat-girl-fashion-challenge/230x131.png",
      path: ["/game/cat-girl-fashion-challenge", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/cat-girl-fashion-challenge/",
      Walkthrough: "https://www.youtube.com/embed/WkiQ7kEdKc4",
      s_dis:
      "Cat Girl Fashion Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Cat Girl Fashion Challenge. Cat Girl is more conscious of her fashion. She plans to compete with all other fashionistas. Join and play as a fashion designer for cat girl. Help her to pick a nice costume for each event and compare her costumes with other players. Browse her wardrobe and pick outfits and costumes which is suitable for the events. Play and have fun!",
        tag: ["Angela","Dress up","Multiplayer","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-01",
      brand: "Y8",
     
      orientation: 1,
      title: "Happy Filled Glass 2 - playcutegames.com",
      name: ["Happy Filled Glass 2", "happy-filled-glass-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/happy-filled-glass-2/250x142.png",
      path: ["/game/happy-filled-glass-2", "https://playcutegames.com"],
      iframe:
        "https://storage.y8.com/y8-studio/html5/madpuffers/Football_2019_new/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Happy Filled Glass 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Happy Filled Glass 2. Happy Filled Glass 2 is a game in which you need to draw with a pencil the optimal way to move water to fill a glass, on your way you will encounter various obstacles such as burning platforms, water-accelerating platforms, as well as rotating platforms, and much more.",
      tag: ["Puzzle","Sports","Multiplayer","Skill","Arcade","Crazy games","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-10-01",
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Chibi Sup Color - playcutegames.com",
      name: ["Chibi Sup Color", "chibi-sup-color"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/chibi-sup-color/250x142.png",
      path: ["/game/chibi-sup-color", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1664471631/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Chibi Sup Color Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Chibi Sup Color. Hello, young artist. The mysterious Halloween holiday is approaching. Take the opportunity to create a unique greeting card with your favorite characters for your friends. Cute chibi superheroes and funny animals will not leave anyone unconcerned. Take a screenshot and share the result with your friends!",
      tag: ["Skill","Kids","Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-01",
      brand: "GD",
     
      orientation: 0,
      title: "Aquapark IO - playcutegames.com",
      name: ["Aquapark IO", "aquapark-io"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/aquapark-io/250x142.png",
      path: ["/game/aquapark-io", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/ce67b95f0db44d66b8b0dc48ade7ce1a/?gd_sdk_referrer_url=https://playcutegames.com/game/aquaparkio",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Aquapark IO Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Aquapark IO. AquaPark.io is a crazy water slide racing game with fun mechanics. Overtake other players by jumping off the track and landing further down or simply kill them by pushing them off. Aquapark.io is an exciting and competitive io-type casual game. In a giant water slide, many different characters have to glide forward and compete against each other. Play and have fun with this new IO game.",
      tag: ["Multiplayer","Io","Kids","Poki"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
  {
    date: "2022-09-30",
    brand: "GD",
   
    orientation: 1,
    title: "Love Pins - playcutegames.com",
    name: ["Love Pins", "love-pins"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/love-pins/250x142.png",
    path: ["/game/love-pins", "https://playcutegames.com"],
    iframe:
      "https://html5.gamedistribution.com/14be4a167d814f8f8ad8e8b3e72e835d/?gd_sdk_referrer_url=https://playcutegames.com/game/love-pins",
    Walkthrough: "https://www.youtube.com/embed/WkiQ7kEdKc4",
    s_dis:
    "Love Pins Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Welcome to Love Pins. This should be a happy day because this couple of lovers will have a date. But there is a problem now, so many roadblocks on their road. Use the tools to kill bad guys and help them to meet their lover. Use your brain to solve these problems. Can you help them to meet each other? Have a try in the Love Pins!",
    tag: ["crazyGames","Skill","Puzzle","Kids","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
  },
  {
    date: "2022-09-22",
    brand: "playcutegames",
   
    orientation: 0,
    title: "Celebrity Valentino Pink Collections - playcutegames.com",
    name: ["Celebrity Valentino Pink Collections", "celebrity-valentino-pink-collections"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-valentino-pink-collections/250x142.png",
    path: ["/game/celebrity-valentino-pink-collections", "https://playcutegames.com"],
    iframe:
      "https://games.cutedressup.net/celebrity-valentino-pink-collections/",
    Walkthrough: "https://www.youtube.com/embed/gX3DRC9m1qY",
    s_dis:
    "Celebrity Valentino Pink Collections Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Welcome to Celebrity Valentino Pink Collections. Celebrity girls are more famous for their fashion trends. Now they planned to add a new collection to this series. Yes! It’s the Valentino Pink collection. They already filled the collection in their wardrobe. Browse and pick a suitable costume for each girl. Join and help the girls in their fashion preparation. Have fun!",
    tag: ["Celebrity","Fashion","Valentino","Dress up","Kids","Girl games","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
  },
    {
      date: "2022-09-22",
      brand: "bestgamespot",
     
      orientation: 1,
      title: "Crazy Egg Catch - playcutegames.com",
      name: ["Crazy Egg Catch", "crazy-egg-catch"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/crazy-egg-catch/250x142.png",
      path: ["/game/crazy-egg-catch", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/crazy-egg-catch/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Crazy Egg Catch Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Crazy Egg Catch Endless. In this game, you have to transport the egg into the correct conveyor belt. If you waste the egg. The chicken will have angry with you and note the egg color to change the portal color. play and have fun.",
      tag: ["Puzzle","Clicker","Skill","Kids","Arcade","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-09-22",
      brand: "Y8",
     
      orientation: 1,
      title: "Football Legends - playcutegames.com",
      name: ["Football Legends", "football-legends"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/football-legends/250x142.png",
      path: ["/game/football-legends", "https://playcutegames.com"],
      iframe:
        "https://storage.y8.com/y8-studio/html5/madpuffers/Football_2019_new/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Football Legends Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Football Legends. Choose your favorite team and join a tournament where only the best fight for the title. Control your favorite player! You can play solo against AI opponents or play with or against your friend.",
      tag: ["Puzzle","Sports","Multiplayer","Skill","Arcade","Crazy games","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-22",
      brand: "GD",
     
      orientation: 1,
      title: "Fish Love - playcutegames.com",
      name: ["Fish Love", "fish-love"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/fish-love/250x142.png",
      path: ["/game/fish-love", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/714caa9b970d47c78729c961faa998f8/?gd_sdk_referrer_url=https://playcutegames.com/game/fish-love",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Fish Love Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Fish Love Game. Save fish from danger, bombs, lava, and crab, and give the fish just love. They cannot live without each other. Beautiful and pleasant music, sound effects, and bright and colorful graphics accompany you through all the puzzles of this game. Start your adventure now. Clear all the puzzles and let two fishes finally meet each other for love. Good luck in love !!!",
      tag: ["Puzzle","Skill","Arcade","Crazy games","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-22",
      brand: "GD",
     
      orientation: 0,
      title: "Snail Bob 5: Love Story - playcutegames.com",
      name: ["Snail Bob 5: Love Story", "snail-bob-5-love-story"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/snail-bob-5-love-story/250x142.png",
      path: ["/game/snail-bob-5-love-story", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/7e2e56736eef49f78220605798903354/?gd_sdk_referrer_url=https://playcutegames.com/game/snail-bob-5-html5",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Snail Bob 5: Love Story Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Snail Bob 5: Love Story. This is a story about love! The famous snail is setting out on another adventure. You need to help Bob the snail going to meet his girlfriend, but it seems that the way is far from his house. Help him to avoid a trap and reach the next door.",
      tag: ["Puzzle","Skill","Arcade","Crazy games","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-22",
      brand: "Y8",
     
      orientation: 0,
      title: "Noughts and Crosses - playcutegames.com",
      name: ["Noughts and Crosses", "noughts-and-crosses"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/noughts-and-crosses/250x142.png",
      path: ["/game/noughts-and-crosses", "https://playcutegames.com"],
      iframe:
        "https://storage.y8.com/y8-studio/html5/akeemywka/noughts_and_crosses_v2/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Noughts and Crosses Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Noughts and Crosses. A simple game of ‘tic tac toe’ on a chalkboard theme. You can play alone or with a friend in two-player modes. This is a classic game that is easily playable with paper. Now you can play using a computer.",
      tag: ["Puzzle","Skill","Arcade","Crazy games","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-22",
      brand: "GD",
     
      orientation: 0,
      title: "Moto X3M - playcutegames.com",
      name: ["Moto X3M", "moto-x3m"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/moto-x3m/250x142.png",
      path: ["/game/moto-x3m", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/5b0abd4c0faa4f5eb190a9a16d5a1b4c/?gd_sdk_referrer_url=https://playcutegames.com/game/moto-x3m-bike-race-game",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Moto X3M Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Moto X3M. Moto X3M is a cool motocross stunt game. With 22 challenging, yet addicting levels. Put on your helmets, revv your motorcycle and take off to beat the time on all the amazing stages. Perform tricks, like flips, to decrease your time.",
      tag: ["Racing","Action","Crazy games","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-15",
      brand: "cutedressup",
     
      orientation: 0,
      title: "PFW-The Big Shoulder Couture - playcutegames.com",
      name: ["PFW-The Big Shoulder Couture", "pfw-the-big-shoulder-couture"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pfw-the-big-shoulder-couture/250x142.png",
      path: ["/game/pfw-the-big-shoulder-couture", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/pfw-the-big-shoulder-couture/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "PFW-The Big Shoulder Couture Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to PFW-The Big Shoulder Couture. Celebrity girls are getting ready for another action. Yes! it’s Paris Fashion Week! They are excited to try big Shoulder Couture outfits. Browse their wardrobe and pick suitable outfits and accessories. Don’t forget to give unique hairstyles. Join them as fashion expert and help the girls to show their stunning fashion looks!",
      tag: ["Celebrity","Fashion","PFW","Dress up","Kids","Girl games","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-09-15",
      brand: "GD",
     
      orientation: 1,
      title: "Idle Fashion Shop - playcutegames.com",
      name: ["Idle Fashion Shop", "idle-fashion-shop"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/idle-fashion-shop/250x142.png",
      path: ["/game/idle-fashion-shop", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/76495354bb344d3f925e1ebfd4b55cc3/?gd_sdk_referrer_url=https://gamedistribution.com/game/idle-fashion-shop",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Idle Fashion Shop Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Idle Fashion Shop is a casual entertainment game with fashion as its theme. Congratulations! You are now a clothing store owner and you need to invest in unlocking pits to make more money. Of course, initially, you don't have a lot of money, you need to organize your own clothes and checkout, when you have the money you can hire cashiers and sales clerks. You can also open shoe stores, lingerie stores, etc. Become a millionaire just around the corner!",
      tag: ["Clicker","Arcade","Crazy games","Girl games","Kids"],
      hint: `Slide to move`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-15",
      brand: "GD",
      orientation: 1,
      title: "Nine Blocks: Block Puzzle Game - playcutegames.com",
      name: ["Nine Blocks: Block Puzzle Game", "nine-blocks-block-puzzle-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/nine-blocks-block-puzzle-game/250x142.png",
      path: ["/game/nine-blocks-block-puzzle-game", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/50513f5fb5594ab99f62a2912abaec84/?gd_sdk_referrer_url=https://playcutegames.com/game/nine-blocks-block-puzzle-game",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Nine Blocks: Block Puzzle Game Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "It’s a simple yet challenging free block puzzle you won’t be able to put down. Merge cube blocks on the 9x9 grid, which should be familiar to all sudoku fans, to build lines and squares. Strategically stack sudoku blocks made up of cubes on the board to destroy them and keep the board clean.",
      tag: ["Puzzle","Arcade","Crazy games","Matching","Kids"],
      hint: `Match blocks to remove them by completing lines and cubes. Keep the board clean and beat your high score in this block puzzle!`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-15",
      brand: "GD",
     
      orientation: 1,
      title: "SmileyWorld Bubble Shooter - playcutegames.com",
      name: ["SmileyWorld Bubble Shooter", "smileyworld-bubble-shooter"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/smileyworld-bubble-shooter/250x142.png",
      path: ["/game/smileyworld-bubble-shooter", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/f0ecdfa546ca4fc39d52b1af0f08af3f/?gd_sdk_referrer_url=https://playcutegames.com/game/smileyworld-bubble-shooter",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "SmileyWorld Bubble Shooter Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Pop cute smiley bubbles and travel across the SmileyWorld® Bubble Shooter game. The smiley bubbles are just waiting for you to have fun and smile with them. Collect all the little animals and characters and explore hundreds of levels. Match at least three bubbles and pop them, but don't run out of them. Use the extra booster smiley faces to pop any three smiley balls.",
      tag: ["Puzzle","Arcade","Crazy games","Bubble","Kids"],
      hint: `How to play SmileyWorld® Bubble Shooter game. - Tap/click and hold to aim, then release to shoot and pop the smiley bubble. - Try to group 3 or more bubbles to pop them. - Clear all the bubbles on the screen to level up. Try to get 3 stars on each level. - Collect extra characters by popping all the bubbles around them. - Use the Extra Booster smileys to complete the levels.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-14",
      brand: "bestgamespot",
     
      orientation: 1,
      title: "Catch The Thief - playcutegames.com",
      name: ["Catch The Thief", "catch-the-thief"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/catch-the-thief/250x142.png",
      path: ["/game/catch-the-thief", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/catch-the-thief",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Catch The Thief Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Catch The Thief is a very addictive game. The task is very simple need to collide the police and the thief. But is not easy to keep police and thief inside the game layout and a lot of obstacles also there if an obstacle collides with the police or thief you fail. Also, catch the thief alive.",
      tag: ["Puzzle","Clicker","Skill","Kids","Arcade","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-09-13",
      brand: "GD",
     
      orientation: 1,
      title: "Kitten Hide And Seek - playcutegames.com",
      name: ["Kitten Hide And Seek", "kitten-hide-and-seek"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/kitten-hide-and-seek/250x142.png",
      path: ["/game/kitten-hide-and-seek", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/b78553bf5db34812972452aab68f88c0/?gd_sdk_referrer_url=https://playcutegames.com/game/kitten-hide-and-seek",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Kitten Hide And Seek Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Kitten Hide And Seek is a puzzle online game of hide-and-seek for boys for free. In this game, your goal is to help the little girl escape successfully and get the props she needs, while avoiding being found by the kitten's search. If you love Tamagotchi or green light red light, this game is totally for you. You also can decorate your room with props won by beating the level. Have fun with it!",
      tag: ["Puzzle","Skill","Kids","Yiv"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-13",
      brand: "GD",
     
      orientation: 1,
      title: "Match 20 Challenge - playcutegames.com",
      name: ["Match 20 Challenge", "match-20-challenge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/match-20-challenge/250x142.png",
      path: ["/game/match-20-challenge", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/a065b77e1f29400dac2cdd4e0e84928c/?gd_sdk_referrer_url=https://playcutegames.com/game/match-20-challenge",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Match 20 Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Match 20 Challenge is a free colorful puzzle online game for boys. The game starts at 5, looks easy until 10, gets fiendish at 15, and challenges you to get to 20. Anyone can learn it, but you'll have to concentrate to make it all the way. TIt is a very good skill-based game that tests both reaction time and dexterity. Can you get to Twenty?",
      tag: ["Puzzle","Match3","Kids"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-13",
      brand: "GD",
     
      orientation: 0,
      title: "Sumo.io - playcutegames.com",
      name: ["Sumo.io", "sumo.io"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/sumo-io/250x142.png",
      path: ["/game/sumo.io", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/5ad56ded64df44118b4e7dc922606492/?gd_sdk_referrer_url=https://playcutegames.com/game/sumo.io",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Sumo.io Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Push your opponents out of the playing field and don't let yourself be pushed out! Develop your character and buy useful gear! Different skins and arenas are waiting for you. Become the King of the Hill!",
      tag: ["Puzzle","Action","Io","Kids","Multiplayer","Yiv"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-09",
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFFs E Girl Vs Soft Girl - playcutegames.com",
      name: ["BFFs E Girl Vs Soft Girl", "bffs-e-girl-vs-soft-girl"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-e-girl-vs-soft-girl/250x142.png",
      path: ["/game/bffs-e-girl-vs-soft-girl", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-e-girl-vs-soft-girl/",
      Walkthrough: "https://www.youtube.com/embed/3ETVdmS5o64",
      s_dis:
      "BFFs E Girl Vs Soft Girl Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to BFFs E Girl Vs Soft Girl. Ellie and her friends love to try a new fashions. Everyone is talking about the Soft Girl and the E-girl style nowadays, which are the trending fashion styles preferred by teens worldwide. Both are trendy and have opposite aesthetics. The Soft Girl style embraces the girly girl look, sweet and cute, wearing lots of pinks. Ellie and her friends will explore both styles and you must help them out by creating their Soft Girl versus E-girl look!",
      tag: ["Barbie","Dress up","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.7,210,20],
    },
    {
      date: "2022-09-07",
      brand: "bestgamespot",
     
      orientation: 1,
      title: "Bouncy King - playcutegames.com",
      name: ["Bouncy King", "bouncy-king"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bouncy-king/250x142.png",
      path: ["/game/bouncy-king", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/bouncy-king/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Bouncy King Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Bounce king. In this game, you will enjoy tricky puzzles of 30 levels. the game goal is very simple need put the ball into the hole. But it's a bit difficult to do that because you need to arrange some wood and other things too.",
      tag: ["Puzzle","Clicker","Skill","Kids","Arcade","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-09-06",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bffs Fall Fashion Trends - playcutegames.com",
      name: ["Bffs Fall Fashion Trends", "bffs-fall-fashion-trends"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-fall-fashion-trends/250x142.png",
      path: ["/game/bffs-fall-fashion-trends", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-fall-fashion-trends/",
      Walkthrough: "https://www.youtube.com/embed/3ETVdmS5o64",
      s_dis:
      "Bffs Fall Fashion Trends Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Bffs Fall Fashion Trends. Fall is quite an exciting season when it comes to clothing and fashion, primarily because the harsher weather allows for more layering and combinations of clothes, which you can’t really do in the summer because it is too hot. Your favorite LOL girls want to rock looks inspired by the fall season, so you are here to give them this makeover they really need and have fun!",
      tag: ["LOL Surprise","Dress up","Bff","Autumn","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.7,210,20],
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 0,
      title: "Raymans Incrediballs Dodge - playcutegames.com",
      name: ["Raymans Incrediballs Dodge", "raymans-incrediballs-dodge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/raymans-incrediballs-dodge/250x142.png",
      path: ["/game/raymans-incrediballs-dodge", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/cce037d8ac83427e870620b66f51ed3d/?gd_sdk_referrer_url=https://playcutegames.com/game/raymans-incrediballs-dodge",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Raymans Incrediballs Dodge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Raymans Incrediballs Dodge. Anarchy in the Jungle! Choose one of more than 20 hilarious Incrediballs and take advantage of their unique properties to escape the hazards of the jungle for as long as you can. Dodge left, dodge right, and push other players around, it’s the law of the jungle! Play and have fun with this new puzzle game for kids!",
      tag: ["Puzzle","Action","Action","Kids","Skill","Multiplayer","Poki"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      id: 202111265,
      brand: "Prinxy",
     
      orientation: 0,
      title: "TikTok Braided Hairstyles - playcutegames.com",
      name: ["TikTok Braided Hairstyles", "tiktok-braided-hairstyles"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-braided-hairstyles/250x142.png",
      path: ["/game/tiktok-braided-hairstyles", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/a4cfdc35-2fdb-5074-a667-932c2b324f92/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In the TikTok Braided Hairstyles, we featured all your favorite games from 2021. It’s been a great year for fashion, and in it, you are going to find a bit of everything.",
      l_dis:
        `Engage in styling beautiful braids, experiment with elegant patterns and try to put on interesting accessories to create great images. If you’re aiming for a casual outfit or something more formal, do not worry because there are lots of looks to try out. Try new braid combinations, discover new looks and show off your hair dressing skills. Grow and style fabulous braided looks and enjoy them with friends in this fashionable and entertaining braiding game!`,
      tag: ["Hair", "Tiktok", "Fashion", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Fashion Box: Glam Diva - playcutegames.com",
      name: ["Fashion Box: Glam Diva", "fashion-box-glam-diva"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/fashion-box-glam-diva/250x142.png",
      path: ["/game/fashion-box-glam-diva", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/a4cfdc35-2fdb-5074-a667-932c2b324f92/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In the Fashion Box: Glam Diva, we featured all your favorite games from 2021. It’s been a great year for fashion, and in it, you are going to find a bit of everything.",
      l_dis:
        "Bonnie is one of the first influencers to try the life in plastic fashion challenge, and she could use your help to nail the look. She's going to go for a glam diva look, and she needs your help to look stunning. Are you ladies ready for a one-kind dress-up game? Then let's get started with the Fashion Box: Glam Diva dress-up game for girls and see what chic look you can put together for our beautiful fashionista.",
      tag: ["Makeup", "Dress up", "Fashion", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 0,
      title: "Basketball King - playcutegames.com",
      name: ["Basketball King", "basketball-king"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/basketball-king/250x142.png",
      path: ["/game/basketball-king", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/d57292e2177f4ec19fed58b0d21553e5/?gd_sdk_referrer_url=https://playcutegames.com/game/basketball-king",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Basketball King Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Basketball King. Most realistic physics provides you with the best and most realistic ball shooting experience. Train yourself anywhere and anytime. Beat all records and all opponents. Play with your friends in be the Basketball King! Gameplay is very simple, Swipe to throw the ball! sounds fun! right? Play and have fun with this new puzzle game for kids!",
      tag: ["Puzzle","Kids","Skill","Crazy games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 1,
      title: "Merge Block Raising - playcutegames.com",
      name: ["Merge Block Raising", "merge-block-raising"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/merge-block-raising/250x142.png",
      path: ["/game/merge-block-raising", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/5fc965b3a1534a4aaa1181b98acf7238/?gd_sdk_referrer_url=https://playcutegames.com/game/merge-block-raising",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Merge Block Raising Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Merge Block Raising. Merge Wood: Block Puzzle is a brand new puzzle game! A new type of brain training puzzle, entertain your brain for minutes or hours. Just drag the block onto another one with the same number! Merge them into a bigger number!! ",
      tag: ["Puzzle","Kids","Skill","Crazy games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 0,
      title: "Emergency Ambulance Simulator - playcutegames.com",
      name: ["Emergency Ambulance Simulator", "emergency-ambulance-simulator"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/emergency-ambulance-simulator/250x142.png",
      path: ["/game/emergency-ambulance-simulator", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/8de6c56bcafb40cf841069f4defd722f/?gd_sdk_referrer_url=https://playcutegames.com/game/emergency-ambulance-simulator",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Emergency Ambulance Simulator Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Emergency Ambulance Simulator. Here is a typical ambulance driving rescue simulation game with 3D game art animations. All levels have accidents, your mission is to transport the injured people from the accident area to the hospital as fast as you can. Keep calm and step on the gas! Play and have fun to play this new simulator game.",
      tag: ["Racing","Puzzle","Kids","Skill","Crazy games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 0,
      title: "Laser Cannon - playcutegames.com",
      name: ["Laser Cannon", "laser-cannon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/laser-cannon/250x142.png",
      path: ["/game/laser-cannon", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/b02d2e71e5ea45aca38dc93960acdc04/?gd_sdk_referrer_url=https://playcutegames.com/game/laser-cannon",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Laser Cannon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Laser Cannon. The new game in the “Laser Cannon” series. New locations, new monsters, new gear. Kill’em all! Laser Cannon 3 is finally back and now the funny physics-based shooting game goes into the third round. It’s up to you to save our planet one more time against a bunch of annoying alien creatures. Clear each stage using your mighty cannon and try to shoot them all with as less shots as possible.",
      tag: ["Arcade","Action","Shooting","Puzzle","Casual","Kids","Skill","Crazy games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 1,
      title: "Draw Love Story - playcutegames.com",
      name: ["Draw Love Story", "draw-love-story"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/draw-love-story/250x142.png",
      path: ["/game/draw-love-story", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/b863a161456243f1804b24406bd8c2cf/?gd_sdk_referrer_url=https://playcutegames.com/game/draw-love-story",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Draw Love Story Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Draw Love Story. Draw Love Story is a drawing puzzle online game for boys for free, which brings you a lot of fun. You need to deduce the meaning that the author wants to express through the scene and draw to complete the task. Draw different elements in the game to end the love story and create a happy ending for the couple. There are hundreds of different levels waiting for you, Have a great time!",
      tag: ["Arcade","Crazy games","Puzzle","Casual","Kids","Skill","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 0,
      title: "Cursed Treasure - playcutegames.com",
      name: ["Cursed Treasure", "cursed-treasure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cursed-treasure-1/250x142.png",
      path: ["/game/cursed-treasure", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/5d0c9c0ceeb54f4d9c238e82d35dea96/?gd_sdk_referrer_url=https://playcutegames.com/game/cursed-treasure-1",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Cursed Treasure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Cursed Treasure 1. Once again the gems of the evil forces are in danger! After a decade of peace, the good heroes come again to steal the last 3 gems carefully saved by the evil Overlord. Your duty is to defend what was granted by centuries of nefarious deeds. Build and upgrade towers, cast powerful spells, and learn useful skills to protect the gems and smash all these ninjas, angels, and lizard riders to pieces!",
      tag: ["Arcade","Puzzle","Casual","Kids","Skill","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bffs Kidcore Outfits - playcutegames.com",
      name: ["Bffs Kidcore Outfits", "bffs-kidcore-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-kidcore-outfits/250x142.png",
      path: ["/game/bffs-kidcore-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-kidcore-outfits/",
      Walkthrough: "https://www.youtube.com/embed/YVviaWqMxJs",
      s_dis:
      "Bffs Kidcore Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Bffs Kidcore Outfits. Kidcore is an aesthetic that centers around bright colors, icons from the 90s, and kiddy themes. These young girls are very excited to try this fashion. Join as a fashion expert and browse the wardrobe for cute outfits. Pick a suitable one for each girl and give a unique hairstyle to them.",
      tag: ["Barbie","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.7,210,20],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 0,
      title: "Bubble Shooter Candy 2 - playcutegames.com",
      name: ["Bubble Shooter Candy 2", "bubble-shooter-candy-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bubble-shooter-candy-2/250x142.png",
      path: ["/game/bubble-shooter-candy-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/8587b3c7418e485ea1b5d6d8f2e72c56/?gd_sdk_referrer_url=https://playcutegames.com/game/bubble-shooter-candy-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Bubble Shooter Candy 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Bubble Shooter Candy 2 is the long-awaited second instalment of the legendary Bubble Shooter game. The unique combination of the best Bubble Shooter game with the nicest and cutest candies will make you fall in love with this game from the first pop! What is the highest score you can achieve? Enjoy Bubble Shooter Candy 2 now and make the candies pop!",
      tag: ["Match3","Puzzle","Casual","Kids","Girl games","Poki"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 0,
      title: "Forest Match 2 - playcutegames.com",
      name: ["Forest Match 2", "forest-match-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/forest-match-2/250x142.png",
      path: ["/game/forest-match-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/be2946e7efea47b8bf3feb542369251c/?gd_sdk_referrer_url=https://playcutegames.com/game/forest-match-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Forest Match 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Forest Match 2 is one of greatest Match 3 games of all time! Because of the huge popularity of its predecessor, the players simply asked us for more. Forest Match 2 features incredible 3000+ levels. The biggest adventure in a beautiful magical forest there has ever been: more fruits to collect, more chests of coins to discover and a saga map packed with surprises for you to enjoy!",
      tag: ["Match3","Puzzle","Casual","Kids","Girl games","Poki"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
      orientation: 0,
      title: "Daily Solitaire Blue - playcutegames.com",
      name: ["Daily Solitaire Blue", "daily-solitaire-blue"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/daily-solitaire-blue/250x142.png",
      path: ["/game/daily-solitaire-blue", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/e28872a3009d42d7b5580c95a71192fe/?gd_sdk_referrer_url=https://playcutegames.com/game/daily-solitaire-blue",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Daily Solitaire Blue Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Daily Solitaire Blue brings you a new klondike solitaire game every day! Enjoy different challenges and difficulty levels depending on the day of the week. Practice makes masters! Play every day and increase your chance to earn monthly rewards! Combined with the highly polished graphics that Softgames Solitaire Games are known for, Daily Solitaire Blue guarantees fun and brain teasing for hours to come! Enjoy this card game masterpiece!",
      tag: ["Card","Puzzle","Casual","Poki"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 0,
      title: "The Mergest Kingdom - playcutegames.com",
      name: ["The Mergest Kingdom", "the-mergest-kingdom"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/the-mergest-kingdom/250x142.png",
      path: ["/game/the-mergest-kingdom", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/b5203bfef51242c39690c761321d769f/?gd_sdk_referrer_url=https://playcutegames.com/game/the-mergest-kingdom",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "The Mergest Kingdom Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Do you believe in fairytales? How else would you explain the story that is about to unfold!Merge together hundreds of objects in a land full of challenging quests, collect and harvest resources, design your own island by making it look the way YOU want it! Find the best ways to match and merge various pieces to erect the biggest buildings and cultivate the most fruitful plants! Help rebuild the Mergest Kingdom and make it the most magical one in the Seventh Realm!What is The Mergest Kindom like?YOUR WORLD! Only here can you find the most amazing objects that you will ever merge! YOUR CHOICE! You can merge dragons, trees, gems, and virtually anything you find on your journey of discovery! YOUR RULES! The islands full of merge magic and merge craft enabling you to make the biggest merges the way YOU want!Quests and challengesTake part in DAILY QUESTS to collect coins and gems, Mine various RESOURCES to build, Explore vast MAGIC territories full of mysterious creatures, bedazzling characters, and captivating objects populating this infinite world!If you love merge games this mergical experience is going to be a perfect fit for you!",
      tag: ["Match3","Puzzle","Casual","Kids","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GM",
     
      orientation: 0,
      title: "Island Puzzle - playcutegames.com",
      name: ["Island Puzzle", "island-puzzle"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/island-puzzle/250x142.png",
      path: ["/game/island-puzzle", "https://playcutegames.com"],
      iframe:
        "https://html5.gamemonetize.co/ono1u6bmtkk11a03q9ulcy4hjzdcpxc6/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Island Puzzle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Island Puzzle. Start an exciting adventure in the cutest jungle puzzle matching game! Help the brave pilot and charming cat Tom survive on a desert island. Restore and decorate different areas on the island, get to the bottom of the secrets it holds, and enjoy the company of amusing in-game characters. What are you waiting for? Indulge yourself and become the hero of a brand new story. Build your dream island for free! Search for rare and unique artifacts by playing with curious pets, connecting them together in lines as long as possible. Play with little Tom cat, get powerful boosters, and help collect precious items!",
      tag: ["Match3","Puzzle","Casual","Kids","Skill","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 1,
      title: "Gold Diggers - playcutegames.com",
      name: ["Gold Diggers", "gold-diggers"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/gold-diggers/250x142.png",
      path: ["/game/gold-diggers", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/2d98c70ec8af4702a7b7ce59a618877f/?gd_sdk_referrer_url=https://playcutegames.com/game/gold-diggers",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Gold Diggers Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Dig your way to GOLD! Avoid obstacles as you try to collect as much precious gold as possible. Can you get enough gold to unlock all 9 carts? Let's find out!",
      tag: ["Match3","Puzzle","Casual","Kids","Girl games","Poki"],
      hint: `Click or tap on the sand to dig.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 1,
      title: "Tasty Match - playcutegames.com",
      name: ["Tasty Match", "tasty-match"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tasty-match/250x142.png",
      path: ["/game/tasty-match", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/224cba30a91a42debb44da41b7ff1866/?gd_sdk_referrer_url=https://playcutegames.com/game/tasty-match",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Tasty Match Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "A colorful and addictive game to relax and unwind! The goal of the game is to connect pairs of identical tiles. You need to have time to clear the entire field before the time runs out. Connect more distant tiles to get more stars. For a certain number of stars, a reward is given - a chest. Over time, new beautiful tiles are unlocked and level configurations become more complicated.",
      tag: ["Match3","Puzzle","Casual","Kids","Girl games"],
      hint: `The goal of the game is to connect pairs of identical tiles. You need to have time to clear the entire field of how the time runs out. Connect more distant tiles to get more stars. For a certain number of stars, a reward is given - a chest.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 1,
      title: "Hide and Seek | The Original HNS Stickman Game - playcutegames.com",
      name: ["Hide and Seek | The Original HNS Stickman Game", "hide-and-seek-or-the-original-hns-stickman-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/hide-and-seek-or-the-original-hns-stickman-game/250x142.png",
      path: ["/game/hide-and-seek-or-the-original-hns-stickman-game", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/239942ce1a1349f6bcc9d312b32c5c3b/?gd_sdk_referrer_url=https://playcutegames.com/game/hide-and-seek-or-the-original-hns-stickman-game",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Hide and Seek | The Original HNS Stickman Game Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Find or be found. Up to you. The good old classic hide & seek with stickman. Play either as a seeker or as a hider stick and build your shelters from cars or office desks, hide in the water, in the hay pile, in the cornfield, in the boss' office and most importantly, push others in the seeker's vision field. Try to be kind though. Very addictive and easy to play hyper casual game Hide 'n seek - the original hns game",
      tag: ["Arcade","Puzzle","Casual","Kids","Poki"],
      hint: `Swipe across the screen or use WASD to move Find other stickman or hide from them`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 1,
      title: "Human Wheel - playcutegames.com",
      name: ["Human Wheel", "human-wheel"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/human-wheel/250x142.png",
      path: ["/game/human-wheel", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/a9917a0fabf748bea8615c2aa4589e4a/?gd_sdk_referrer_url=https://playcutegames.com/game/people-wheel",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Human Wheel Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "GATHER THE BIGGEST HUMAN WHEEL Start running alone and gather people on your way to collect a massive wheel. Lead your team through all sorts of moving, rotating, and expanding obstacles. Calculate your moves on the run and save as many members of the wheel as possible.",
      tag: ["Arcade","Puzzle","Casual","Kids","Poki"],
      hint: `Calculate your moves on the run and save as many members of the wheel as possible.`,
      rating:[4.5,202,10],
    },
    {
      date: "2022-08-26",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Couple Red Carpet Fashion - playcutegames.com",
      name: ["Celebrity Couple Red Carpet Fashion", "celebrity-couple-red-carpet-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-couple-red-carpet-fashion/250x142.png",
      path: ["/game/celebrity-couple-red-carpet-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-couple-red-carpet-fashion/",
      Walkthrough: "https://www.youtube.com/embed/Nd2NI3wmxPI",
      s_dis:
      "Celebrity Couple Red Carpet Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Celebrity Couple Red Carpet Fashion. A Celebrity couple was invited to a Hollywood award function. They are excited to join this event. At this event, all celebrities are welcomed on a traditional red carpet, so they planned to wear the best outfits and show themself as perfect couples. Join and play this new game! Have fun!",
      tag: ["Celebrity","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.7,210,20],
    },
    {
      date: "2022-08-26",
      brand: "playcutegames",
     
      orientation: 0,
      title: "FNF Girlfriend Multiverse Fashion - playcutegames.com",
      name: ["FNF Girlfriend Multiverse Fashion", "fnf-girlfriend-multiverse-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/fnf-girlfriend-multiverse-fashion/250x142.png",
      path: ["/game/fnf-girlfriend-multiverse-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/fnf-girlfriend-multiverse-fashion/",
      Walkthrough: "https://www.youtube.com/embed/egQcNjiRH8c",
      s_dis:
      "FNF Girlfriend Multiverse Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to FNF Girlfriend Multiverse Fashion. Friday Night Funkin Girl seems bored of trying music. She has a plan for something different other than music. Yes, she wants to try multiverse fashion! Sounds fun! right? Join and pick a different fashion outfit. At each level, her wardrobe is filled with different costumes. Help them to choose the best one!",
      tag: ["FNF","Dress up","Bff","Kids","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.7,210,20],
    },
    {
      date: "2022-08-25",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Snow Patrol - playcutegames.com",
      name: ["Snow Patrol", "snow-patrol"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/snow-patrol/250x142.png",
      path: ["/game/snow-patrol", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/snow-patrol/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Snow Patrol Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Snow Patrol is an arcade game where you must pick as many objects as possible to cover the hole in front of you and pass by the road. There are hundreds of levels available with dozens of different challenges, cities, and objects to collect. This game is fun and satisfying!",
      tag: ["Arcade","3D","Skill","Kids","Girl games","Crazy games"],
      hint: `Hold and drag the left mouse button to collect objects.`,
      rating:[4.9,350,18],
    },
    
    {
      date: "2022-08-25",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Layers Roll - playcutegames.com",
      name: ["Layers Roll", "layers-roll"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/layers-roll/250x142.png",
      path: ["/game/layers-roll", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/layers-roll/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Layers Roll Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Layers Roll is an arcade game where you must roll over on strips of the same color as your wheel. Make the wheel grow and become bigger to achieve a higher score. Don't forget to collect coins along your way and use the coins to buy new characters.",
      tag: ["Arcade","3D","Skill","Kids","Girl games","Crazy games"],
      hint: `Drag the left mouse button to the left and right to move.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-08-25",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Funny Zoo Emergency - playcutegames.com",
      name: ["Funny Zoo Emergency", "funny-zoo-emergency"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/funny-zoo-emergency/250x142.png",
      path: ["/game/funny-zoo-emergency", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/funny-zoo-emergency/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Funny Zoo Emergency Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Funny Zoo Emergency is a beauty game where you must take care of these baby animals. Cure their sickness, treat injured animals, clean all the dirt, dress them up, and make them perfect again.",
      tag: ["Dress up","Bff","Kids","Doctor","Girl games","Crazy games"],
      hint: `Use the left mouse button to interact with the objects.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-08-20",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Friday Night Funkin First Date - playcutegames.com",
      name: ["Friday Night Funkin First Date", "friday-night-funkin-first-date"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/friday-night-funkin-first-date/250x142.png",
      path: ["/game/friday-night-funkin-first-date", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/friday-night-funkin-first-date/",
      Walkthrough: "https://www.youtube.com/embed/NcY7JGu75PA",
      s_dis:
      "Friday Night Funkin First Date Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Friday Night Funkin First Date. FNF girl and his boyfriend plan for a date. They want to be perfect in their looks. Pick up suitable night party outfits for them. Their wardrobe has already been filled with a new collection. Don’t forget to pick unique hairstyles and different costumes. Join and help them to make this date a more memorable one in their life.",
      tag: ["FNF","Dress up","Bff","Kids","Dating","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-08-20",
      brand: "Y8",
     
      orientation: 0,
      title: "Catapultz IO - playcutegames.com",
      name: ["Catapultz IO", "catapultz-io"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/catapultz-io/250x142.png",
      path: ["/game/catapultz-io", "https://playcutegames.com"],
      iframe:
        "https://storage.y8.com/y8-studio/html5/akeemywka/catapultz_io_v2/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Catapultz IO Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Catapultz IO. Drag, aim, and release! Launch your catapult at your opponents. The first to reach 20 kills wins the round. A fun IO game, guaranteed to keep players coming back for more. Features: – Simple tutorial to quickly learn how to play – Fast game-play – Choose your own avatar and username – Clever bots to balance the game-play.",
      tag: ["Arcade","Multiplayer","Kids","Io","Girl games","Crazy games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.5,1110,118],
    },
    {
      date: "2022-08-20",
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Blondie Reload - playcutegames.com",
      name: ["Blondie Reload", "blondie-reload"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/blondie-reloaded/250x142.png",
      path: ["/game/blondie-reload", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1660943844/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Blondie Reload Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Blondie Reload. Look who’s back! This is the charming Blondie and her boyfriend Kenny. They are a great stylish couple. Just take a look at their wonderful outfits inspired by the fashion of the 80s. Charge yourself with the radiant energy of Blondie and Kenny, and plunge into the world of high fashion with your lovers on the sunny coast and among the lights of the night city. Dance, have fun, and roller-skate through the streets of the metropolis or along the promenade.",
      tag: ["Dress up","Fashion","Kids","Dl-Girls","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.5,1110,118],
    },
    {
      date: "2022-08-17",
      brand: "cutedressup",
     
      orientation: 0,
      title: "Super Girls Ready To Adventure - playcutegames.com",
      name: ["Super Girls Ready To Adventure", "super-girls-ready-to-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-ready-to-adventure/250x142.png",
      path: ["/game/super-girls-ready-to-adventure", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/super-girls-ready-to-adventure/",
      Walkthrough: "https://www.youtube.com/embed/oFl6ruDP9QE",
      s_dis:
      "Super Girls Ready To Adventure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      `Welcome to Super Girls Ready To Adventure. Winx Girls loves magical and adventurous fashion. They planned to participate in superheroes cosplay events. Help them to pick some interesting Outfits. Browse their wardrobe for cute outfits and pick a suitable outfit for each one. Don’t forget try unique hairstyles and accessories. Play and have fun!
      <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Winx","Dress up","Bff","Kids","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,1110,118],
    },
    {
      date: "2022-08-17",
      brand: "GD",
     
      orientation: 1, 
      title: "Save The Girl 2 - playcutegames.com",
      name: ["Save The Girl 2", "save-the-girl-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/save-the-girl-2/250x142.png",
      path: ["/game/save-the-girl-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/3594e310e0ee4be7ba5705cdd6032c25/?gd_sdk_referrer_url=https://playcutegames.com/game/save-the-girl-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Save The Girl 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Save The Girl 2. Save the girl 2 is a sequel to Save the girl, it is funnier than the last one. It still adopts the quiz play mode. But this time the bad guy is, an alien! It is so horrible, we can not let her be caught. Choose the right answer to save our cute girl! She will love you! ",
      tag: ["Kids","Puzzle","Skill","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.5,1110,118],
    },
    {
      date: "2022-08-17",
      brand: "GD",
     
      orientation: 1, 
      title: "Save The Girl - playcutegames.com",
      name: ["Save The Girl", "save-the-girl"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/save-the-girl/250x142.png",
      path: ["/game/save-the-girl", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/ecfd8df63b2146d4861294e92cdf00fa/?gd_sdk_referrer_url=https://playcutegames.com/game/save-the-girl",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Save The Girl Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Save The Girl. A girl will encounter various kinds of dangers in the game Save The Girl. You need some props or articles to help the little girl to carry out a task of escape and survival. The system will let the player choose one of several props given. You can’t give it wrong, or she will be caught by bad guys.",
      tag: ["Kids","Puzzle","Skill","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.5,1110,118],
    },
    {
      date: "2022-08-17",
      brand: "Winx",
     
      orientation: 1, 
      title: "Winx Club Magic Match - playcutegames.com",
      name: ["Winx Club Magic Match", "winx-club-magic-match"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/winx-club-magic-match/250x142.png",
      path: ["/game/winx-club-magic-match", "https://playcutegames.com"],
      iframe:
        "https://www.winxclub.com//sites/default/games/html5/36/Game.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Winx Club Magic Match Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Winx Club Magic Match. The Winx Club Magic Match game is a match 3 game and it has a tricky puzzle level. To get more fun complete all levels  ",
      tag: ["Match3","Kids","Winx","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.5,1110,118],
    },
    {
      date: "2022-08-17",
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Rise Minions Maker - playcutegames.com",
      name: ["Rise Minions Maker", "rise-minions-maker"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rise-minions-maker/250x142.png",
      path: ["/game/rise-minions-maker", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1660327027/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Rise Minions Maker Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Rise Minions Maker. A minion is a devoted servant of his master. Since ancient times, minions have been looking for a ruler worthy of their service. Create your own minions and become their master! Choose hairstyles, clothes, and accessories to your liking. Experiment with colors and accessories, you are only limited by your imagination. Which of the minions is worthy to take a place in your great corporation? You decide!",
      tag: ["Dress up","Fashion","Kids","Dl-Girls","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.5,1110,118],
    },
    {
      date: "2022-08-12",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Colorful Denim Days - playcutegames.com",
      name: ["My Colorful Denim Days", "my-colorful-denim-days"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-colorful-denim-days/250x142.png",
      path: ["/game/my-colorful-denim-days", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-colorful-denim-days/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Colorful Denim Days Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to My Colorful Denim Days. Hey Girls! Are you ready for the coolest outfits? The style for today is #denim! Barbie and his friends are curious to know which style suits them best. Mix and match the clothes and create awesome outfits for the girls. Browse and pick suitable costumes for them. Play and Have fun!",
      tag: ["Barbie","Dress up","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,1110,118],
    },
    {
      date: "2022-08-09",
      brand: "GD",
     
      orientation: 1,
      title: "Zen Cube 3D - playcutegames.com",
      name: ["Zen Cube 3D", "zen-cube-3d"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/zen-cube-3d/250x142.png",
      path: ["/game/zen-cube-3d", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/2a1b01b50c6b45a69350ec6ee1ff9ad3/?gd_sdk_referrer_url=https://playcutegames.com/game/zen-cube-3d",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Zen Cube 3D Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Zen Cube 3D is a relaxation match 3d puzzle game. Not like classic match games, Zen Triple 3D not only a time-killing game but also is a zen & relaxation matching puzzle game which is easy for everyone to play.",
      tag: ["Crazy games","Match3","Kids","Puzzle","Challenge","Skill","Online games"],
      hint: `
      <ul> 
      <li>You need to choose 3 same 3D cubes.Use props at the right time to help you go more smoothly.</li>
      <li>Swipe to rotate the 3D cube, you can find more matching tile pairs.</li><li>Pay attention to the collecting bar, do not fill it up.</li>
      <li>Use props at the right time to help you go more smoothly.</li><li>Focus on your senses, clear all tiles within limited time.</li>
      </ul>`,
      rating:[4.5,110,7],
    },
    {
      date: "2022-08-09",
      brand: "GD",
     
      orientation: 1,
      title: "Match Tile 3D - playcutegames.com",
      name: ["Match Tile 3D", "match-tile-3d"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/match-tile-3d/250x142.png",
      path: ["/game/match-tile-3d", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/e020c9ffd5f24d8b9383392418def0f2/?gd_sdk_referrer_url=https://playcutegames.com/game/match-tile-3d",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Match Tile 3D Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Match Tile 3D is an addictive puzzle, not only connect your brain to logical thinking but also is a fun triple matching game which is easy for everyone to play!",
      tag: ["Crazy games","Match3","Kids","Puzzle","Challenge","Skill","Online games"],
      hint: `
      <ul> 
      <li>A huge amount of candy crush, cute animals, cool toys, exciting emoji’s, appetizing food, yummy fruits and etc..</li>
      <li>Catchy sounds and vivid 3D visual effects</li><li>Pause it whenever you want</li><li>Well-designed trainer to power your brain up</li>
      </ul>`,
      rating:[4.7,150,5],
    },
    {
      date: "2022-08-09",
      brand: "cutedressup",
     
      orientation: 0,
      title: "Celebrity Dark Gothic Fashion - playcutegames.com",
      name: ["Celebrity Dark Gothic Fashion", "celebrity-dark-gothic-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-dark-gothic-fashion/250x142.png",
      path: ["/game/celebrity-dark-gothic-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-dark-gothic-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Celebrity Dark Gothic Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      `Welcome to Celebrity Dark Gothic Fashion. Celebrity girls plan something unique in fashion. They heard about Dark gothic fashion and were excited to try it. But they have some confusion to choose the best outfit. Browse the wardrobe and pick suitable costumes and outfits for girls. Join with girls and so your creative skill.
      <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Celebrity","Dress up","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.2,110,18],
    },
    {
      date: "2022-08-05",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Sweet Lolita Dress - playcutegames.com",
      name: ["My Sweet Lolita Dress", "my-sweet-lolita-dress"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-sweet-lolita-dress/250x142.png",
      path: ["/game/my-sweet-lolita-dress", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-sweet-lolita-dress/",
      Walkthrough: "https://www.youtube.com/embed/uWbgeiIUmFQ",
      s_dis:
      "My Sweet Lolita Dress Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      " Welcome toMy Sweet Lolita Dress. LOL Surprise girls plan to try their sweet lolita fashion. Lolita fashion is a cute stylish way to dress up in puffy skirts, lacy blouses, and accessories, perfect to create your own colorful trendy fashion. Mix classical, sweet, and gothic lolita styles for a unique spin on the Japanese trend. Browse and find cute outfits for our girls. Join as a fashion expert and show your skill. ",
      tag: ["LOL Surprise","Dress up","Bff","Summer","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,1110,18],
    },
    {
      date: "2022-08-05",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Like a King - playcutegames.com",
      name: ["Like a King", "like-a-king"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/like-a-king/250x142.png",
      path: ["/game/like-a-king", "https://playcutegames.com"],
      iframe:
        "https://ext.minijuegosgratis.com/like-a-king/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Like a King Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Like a King is a tower defense and deck-building game created by LAK Games. You are a king who must battle other kings using and combining the units in your collection of cards. Each card is a unique unit you can use on the battlefield. Some of them attack the enemy, but some of them mine gold and even lay out traps for the enemy unit. Complete quests, build a strong deck, and most importantly, strategize wisely to be the one true king that stands!",
      tag: ["Crazy games","Multiplayer","Strategy","Tower Defense","Challenge","Online games"],
      hint: `
      Tap on the empty tile to spawn a unit. Draw a line from the unit to the enemy unit you want to attack. Revise your tactics and destroy all enemy units before your units are destroyed`,
      rating:[4.8,1110,18],
    },
    {
      date: "2022-08-04",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Gold Digger FRVR - playcutegames.com",
      name: ["Gold Digger FRVR", "gold-digger-frvr"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/gold-digger-frvr/250x142.png",
      path: ["/game/gold-digger-frvr", "https://playcutegames.com"],
      iframe:
        "https://golddigger.frvr.com/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Gold Digger FRVR Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Gold Digger FRVR is a 2D adventure game by FRVR Games. You are a miner with a shovel and you are discovering the underground world. Shovelling your way through mud, earth and rock, you can collect money to improve your skills and buy better tools. The game is an ongoing adventure in which you can improve your character step by step.",
      tag: ["Crazy games","Digger games","Arcade","Adventure"],
      hint: "Walk around with the miner to collect as much gold as you can.",
      rating:[4.2,110,14],
    },
    {
      date: "2022-08-03",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Ellie And Ben Insta Fashion - playcutegames.com",
      name: ["Ellie And Ben Insta Fashion", "ellie-and-ben-insta-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-ben-insta-fashion/250x142.png",
      path: ["/game/ellie-and-ben-insta-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ellie-and-ben-insta-fashion/",
      Walkthrough: "https://www.youtube.com/embed/74ZZ-u_QNxE",
      s_dis:
        "Ellie And Ben Insta Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Ellie And Ben Insta Fashion. Help Ellie and her boyfriend Ben to choose an outstanding outfit to show their fashion skill on Instagram. Browse their wardrobe and pick costumes based on their moods. You can make their looks better with the help of other accessories. Join and play with your favorite couples. Have fun!",
        tag: ["Barbie","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      date: "2022-08-04",
      brand: "GD",
     
      orientation: 1,
      title: "Draw Car Road - playcutegames.com",
      name: ["Draw Car Road", "draw-car-road"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/draw-car-road/250x142.png",
      path: ["/game/draw-car-road", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/3240a82dceb24b8f8f61fcfe2c5fb209/?gd_sdk_referrer_url=https://playcutegames.com/games/draw-car-road",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Draw Car Road Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "This game is actually not a car avoiding arcade game but a bridge drawing puzzle game with 3D cartoon game art animations. It is managed for you to draw a bridge for the yellow car to overpass all kinds of terrains and the red flag on the platform track at each level. Glad you will complete all levels and have fun!",
      tag: ["Puzzle","Clicker","Car games","Arcade","Yiv"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.2,110,14],
    },
    {
      date: "2022-08-03",
      brand: "bestgamespot",
     
      orientation: 0,
      title: "Super Hoops Basketball - playcutegames.com",
      name: ["Super Hoops Basketball", "super-hoops-basketball"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-hoops-basketball/250x142.png",
      path: ["/game/super-hoops-basketball", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/super-hoops-basketball/",
      Walkthrough: "https://www.youtube.com/embed/o6qwEccxtqc",
      s_dis:
      "Super Hoops Basketball Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Super Hoops Basketball is a puzzle game. In this game, you have to deliver a Basketball to that Hoop. Collect the stars along the way but be careful not to turn the platforms too much or else it’ll be game over! ",
      tag: ["Puzzle","Clicker","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-07-25",
      brand: "crazy Games",
     
      orientation: 0,
      title: "Slap Castle - playcutegames.com",
      name: ["Slap Castle", "slap-castle"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/slap-castle/250x142.png",
      path: ["/game/slap-castle", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/slap-castle/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Slap Castle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Slap Castle is a funny arcade game to slap all the enemies and the king to conquer the kingdom!",
      tag: ["Crazy games","Action","Fight","Skill"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
   
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity Fashion Battle - playcutegames.com",
      name: ["Celebrity Fashion Battle", "celebrity-fashion-battle"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-fashion-battle/250x142.png",
      path: ["/game/celebrity-fashion-battle", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-fashion-battle/",
      Walkthrough: "https://www.youtube.com/embed/7nrf-_0vVvo",
      s_dis:
        "Celebrity Fashion Battle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Fashion Battle. Celebrities always follow the latest trends in the fashion world. Meet the new fashion collection Spring-Summer 2022! The trend is bright flashy colors and a crazy combination of shades that will not leave anyone indifferent. Every celebrity wants to be inimitable. Which girl is ready to concede in this fashionable battle? Or will they shine until the last accessory? Don’t give your opponents a chance. Time for bold decisions and new experiences. Try on all these dresses, suits, skirts, pants, tops, shorts, and shoes! Show your creative nature and prove to everyone that you are a cool stylist.",
        tag: ["Celebrity","Dress up","Multiplayer","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 1,
      title: "Galaxzynos - playcutegames.com",
      name: ["Galaxzynos", "galaxzynos"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/galaxzynos/250x142.png",
      path: ["/game/galaxzynos", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/b22155b2751b420f8c3c1f86cb1ff8e2/?gd_sdk_referrer_url=https://playcutegames.com/games/galaxzynos",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Galaxzynos Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Galaxzynos is a space shooter game, make your way through the galaxy, fight with different types of enemy spaceships, grab different types of power ups by exploding the asteroids such as bullet shield, laser beam, health gain and spaceship upgrade.",
      tag: ["Action","Arcade","Shooting","Kids","Poki"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
      
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 1,
      title: "Erase One Part - playcutegames.com",
      name: ["Erase One Part", "erase-one-part"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/erase-one-part/250x142.png",
      path: ["/game/erase-one-part", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/af219f4790cb4dc2868e9925a41e904b/?gd_sdk_referrer_url=https://playcutegames.com/games/erase-one-part",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Erase One Part Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "What will you discover and what will you remove in Erase One Part? Grab a magical eraser and get started in this truly creative game. Your new eraser has some pretty amazing powers. It can erase stuff on paper, needless to say, but that's not all! It can also clean magic lamps, activate enchanted mirrors, and even help baby chicks get out of their eggs! Find out what else it can do in this puzzle game that's great for players of all ages.",
        tag: ["Puzzle","Skill","Multiplayer","Kids","Yad"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Perfect Weekend Outfits - playcutegames.com",
      name: ["My Perfect Weekend Outfits", "my-perfect-weekend-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-weekend-outfits/250x142.png",
      path: ["/game/my-perfect-weekend-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-perfect-weekend-outfits/",
      Walkthrough: "https://www.youtube.com/embed/5mjB5xH4HqM",
      s_dis:
        "My Perfect Weekend Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Perfect Weekend Outfits. Are you ready for a crazy weekend with these Rainbow Girls? Do you know what they have planned? Play this game and help these girls get ready for a weekend full of adventures, but most importantly, help them get ready for it. They need to look absolutely fabulous so you need to help them find the perfect outfit and accessories, as well as the hairstyle!",
        tag: ["Rainbow High","Dress up","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "K-Pop Stars Inspired Look - playcutegames.com",
      name: ["K-Pop Stars Inspired Look", "k-pop-stars-inspired-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/k-pop-stars-inspired-look/250x142.png",
      path: ["/game/k-pop-stars-inspired-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/k-pop-stars-inspired-look/",
      Walkthrough: "https://www.youtube.com/embed/vVVXtVptilk",
      s_dis:
        "K-Pop Stars Inspired Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to K-Pop Stars Inspired Look. These girls love Korean pop music. They absolutely adore the world of k-pop, and they’re going to a nightclub where a DJ will be playing music by their favorite groups all night long! Help them choose awesome outfits and fast! You can also lend them a hand while they do their makeup in this beauty game.",
        tag: ["Winx","Dress up","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Aquapark Shark - playcutegames.com",
      name: ["Aquapark Shark", "aquapark-shark"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/aquapark-shark/250x142.png",
      path: ["/game/aquapark-shark", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/bf2f6bcba5f84f28b8d92ccf5da654f2/?gd_sdk_referrer_url=https://playcutegames.com/game/aquapark-shark",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Aquapark Shark Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the fun in Aquapark Shark water slide pool racing adventure where dreams are born and people come to beat the summer heat! Have fun at the aqua slides, uphill rush crazy rides adventure park racing games! Play all kinds of water pool race activities in an uphill rush water park racing game to make this summer the best fun-filled summer with water splash fun aqua racing adventure!",
        tag: ["Skill","Arcade","Summer","Poki"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Best Friend DIY - playcutegames.com",
      name: ["Best Friend DIY", "best-friend-diy"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/best-friend-diy/250x142.png",
      path: ["/game/best-friend-diy", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1657210355/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Best Friend DIY Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Best Friend DIY. Galya is a very modest girl. It is difficult for her to meet new people and make friends. One day she decided to create her own best friend with her own hands! Galya works as a scientist in a laboratory. Join her in her research and help make the discovery of the century. Choose your ingredients wisely, otherwise, you might end up with a monster instead of a best friend!",
      tag: ["Puzzle Games","Skill","Bff Games","Summer","Dl-Girls","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Summer Aesthetic - playcutegames.com",
      name: ["BFFs Summer Aesthetic", "bffs-summer-aesthetic"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-summer-aesthetic/250x142.png",
      path: ["/game/bffs-summer-aesthetic", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-summer-aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/8Cgd1PEAiTM",
      s_dis:
        "BFFs Summer Aesthetic Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the BFFs Summer Aesthetic. Summer is here, and these 6 BFFs are already enjoying it in style with amazing trips around the world! This year, each of them picked a unique destination for the summer holiday, but they keep themselves updated by sending each other lots of pictures. Join them in, and get ready to discover the most beautiful colors of summer.",
        tag: ["Winx","Dress up","Bff","Summer","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedresssup",
     
      orientation: 0,
      title: "Celebrity E-Girl Fashion - playcutegames.com",
      name: ["Celebrity E-Girl Fashion", "celebrity-e-girl-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-e-girl-fashion/250x142.png",
      path: ["/game/celebrity-e-girl-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-e-girl-fashion/",
      Walkthrough: "https://www.youtube.com/embed/ekZmVhGP75M",
      s_dis:
        "Celebrity E-Girl Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Celebrity E-Girl Fashion. These Celebrities were definitely among the very first online fashionistas to fall into this highly popularized trend, so get ready to be amazed by their edgy fashion style and awesome makeup. Today our e-girls have decided to upgrade their looks with something new and for that, they are going to need your expert advice. Join and help the girls!",
        tag: ["Celebrity","Dress up","E-girl","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bff Summer Shine Look - playcutegames.com",
      name: ["Bff Summer Shine Look", "bff-summer-shine-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-summer-shine-look/250x142.png",
      path: ["/game/bff-summer-shine-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bff-summer-shine-look/",
      Walkthrough: "https://www.youtube.com/embed/MThaX0FR0io",
      s_dis:
        "Bff Summer Shine Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFF Summer Shine Look. Bffs are planning for the weekend summer party at the resort. They are bored of trying old fashion summer costumes. Browse their wardrobe for a new collection of outfits and pick a suitable one for each one. Choose matching accessories and unique hairstyles for them. Help them and make this summer party a more memorable one. ",
        tag: ["Barbie","Dress up","Make Up","Summer","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Jetpack Joyride Online - playcutegames.com",
      name: ["Jetpack Joyride Online", "jetpack-joyride-online"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/jetpack-joyride-online/250x142.png",
      path: ["/game/jetpack-joyride-online", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/fa2ecd1d2d18473b96b9103e564117ec/?gd_sdk_referrer_url=https://playcutegames.com/game/jetpack-rusher",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Jetpack Joyride Online Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Jetpack Rusher. Strap on a bullet-powered jetpack to dodge laser’s electric fields and all the missiles that come your way Get a boost along the way Collect coins and buy new suits for Clarry. Join play this new game for kids. Have fun!",
      tag: ["Crazy Games","Skill","Kids","Poki"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Tom And Angela Insta Fashion - playcutegames.com",
      name: ["Tom And Angela Insta Fashion", "tom-and-angela-insta-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tom-and-angela-insta-fashion/250x142.png",
      path: ["/game/tom-and-angela-insta-fashion", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/197c2e8d3c59467e96730a0128e279f2/?gd_sdk_referrer_url=https://playcutegames.com/game/tom-and-angela-insta-fashion",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Tom And Angela Insta Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Tom And Angela Insta Fashion. Help Tom & Angela to become Instagram famous. Buy and choose clothes, hairstyles, or accessories by following their moods. Make them look stunning on every occasion, take beautiful pictures to post on Instagram, and receive lots of likes. Get their insta-ready outfits and have fun!",
      tag: ["Angela","Tom","Kids","Dress up"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Super Friday Night Funkin - playcutegames.com",
      name: ["Super Friday Night Funkin", "super-friday-night-funkin"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-friday-night-funkin/250x142.png",
      path: ["/game/super-friday-night-funkin", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/800958c9ee814a389b0bdc01392c9bc5/?gd_sdk_referrer_url=https://playcutegames.com/game/super-friday-night-funki",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Super Friday Night Funkin Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Have fun with this new game Super Friday Night Funkin a new game in this fabulous saga !! Play the ritual of each music, press the game buttons at the right time and accumulate the points to show that you are the best.",
      tag: ["Friday Night Funkin","FNF","Skill","Kids","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedresssup",
     
      orientation: 0,
      title: "Ocean Kids Back To school - playcutegames.com",
      name: ["Ocean Kids Back To school", "ocean-kids-back-to-school"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ocean-kids-back-to-school/250x142.png",
      path: ["/game/ocean-kids-back-to-school", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ocean-kidz-back-to-school/",
      Walkthrough: "https://www.youtube.com/embed/cATCCaxiEHw",
      s_dis:
        "Ocean Kids Back To school Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Ocean Kids Back to school. It’s back to school time for these Kids. Can you help them get ready for their first day of classes? They’ll need to pick some cool outfits, new hairstyles, accessories, and school supplies too in this online dress-up and design game for girls. After you’re all done, help them to repair their vespa.",
        tag: ["Barbie","Dress up","Summer","Bff","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Choose My summer Style - playcutegames.com",
      name: ["Choose My summer Style", "choose-my-summer-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/choose-my-summer-style/250x142.png",
      path: ["/game/choose-my-summer-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/choose-my-summer-style/",
      Walkthrough: "https://www.youtube.com/embed/04EQx-8mkEQ",
      s_dis:
        "Choose My summer Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Choose My summer Style game. Summer is just seconds away. These girls can’t wait for sun tan lines, longer days, and good times with friends during vacation. It’s time to freshen up their wardrobe for vacation. They’re excited to finally get out and enjoy the sun. Help them to choose their style for this summer.",
      tag: ["Princess","Dress up","Make Up","Summer","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Beach Rainbow Season - playcutegames.com",
      name: ["Beach Rainbow Season", "beach-rainbow-season"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/beach-rainbow-season/250x142.png",
      path: ["/game/beach-rainbow-season", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1655919910/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Beach Rainbow Season Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Beach Rainbow Season. It’s time to open the summer beach season with your BFF. Time to swim, sunbathe, surf, and just have fun. Help these trendy influencers choose the perfect beach party outfits! Girls prefer bright swimsuits and tops, and a sports outfit or shorts are suitable for a guy. Don’t forget to complete your look with top accessories from well-known brands!",
      tag: ["Rainbow High","Dress up","Make Up","Summer","Dl-Girls","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Kiz10",
     
      orientation: 1,
      title: "Super Friday Night vs Neon - playcutegames.com",
      name: ["Super Friday Night vs Neon", "super-friday-night-vs-neon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-friday-night-vs-neon/250x142.png",
      path: ["/game/super-friday-night-vs-neon", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/d0a85081f8df45f5b33931f1521d3b9f/?gd_sdk_referrer_url=https://playcutegames.com/game/super-friday-night-vs-neon",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Super Friday Night vs Neon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Super Friday Night vs Neon. Have fun with this new game Super Friday Night Funkin vs Neon a new game in this fabulous saga !! Play the ritual of each music, with a new 3D animated background improvement, press the game buttons at the right time and accumulate the points to show that you are the best. All your favorite characters are here Huggy, Sonik and many others are waiting for you. Enjoy the new 3D backgrounds and music with new characters and more levels from easy to hard.",
      tag: ["Friday Night Funkin","FNF","Skill","Kids","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Celebrity First Date Adventure - playcutegames.com",
      name: ["Celebrity First Date Adventure", "celebrity-first-date-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-first-date-adventure/250x142.png",
      path: ["/game/celebrity-first-date-adventure", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-first-date-adventure/",
      Walkthrough: "https://www.youtube.com/embed/l6TYgm8ev8Y",
      s_dis:
        "Celebrity First Date Adventure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Celebrity First Date Adventure. Our favorite Celebrity couple planned for an adventure date. Both are excited and want to be more attractive. They need some expert advice. Browse their wardrobe and pick a cute outfit for them. Help the couple and make their date the most memorable one!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
        tag: ["Celebrity","Dress up","Make Up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFF Art Hoe Fashion - playcutegames.com",
      name: ["BFF Art Hoe Fashion", "bff-art-hoe-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-art-hoe-fashion/250x142.png",
      path: ["/game/bff-art-hoe-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bff-art-hoe-fashion/",
      Walkthrough: "https://www.youtube.com/embed/4e1FBScw-AQ",
      s_dis:
        "BFF Art Hoe Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFF Art Hoe Fashion. Ellie and her friends are weary of trying the same fashion. Today they planned to try something new. Yes! It’s Art Hoe fashion!. You join as a fashion expert and give a company to these girls in choosing their perfect outfit. Browse their wardrobe and pick a suitable one. Don’t forget to help them with their makeover. Play and have fun!",
      tag: ["Barbie","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Jump Ball - playcutegames.com",
      name: ["Jump Ball", "jump-ball"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/jump-ball/250x142.png",
      path: ["/game/jump-ball", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/4de31de2e65644139a577c8b1e95ff2e/?gd_sdk_referrer_url=https://playcutegames.com/game/jump-ball",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Jump Ball Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Om Nom Bounce. Remember ‘Om Nom’ from the Cut the Rope mobile games? Our little green friend rolled into a new adventure in ‘On Nom Bounce.’ He faces some fierce enemies and bosses which can be eliminated by shooting candy right at them! The aim, shoot and bounce your way through 40 challenging levels and 4 different environments. Upgrade your abilities along the way to defeat even the toughest enemies!
       `,
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Om Nom Bounce - playcutegames.com",
      name: ["Om Nom Bounce", "om-nom-bounce"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/om-nom-bounce/250x142.png",
      path: ["/game/om-nom-bounce", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/c60593d7d3434def9ac80eed7f315208/?gd_sdk_referrer_url=https://playcutegames.com/game/om-nom-bounce",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "om-nom-bounce Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Om Nom Bounce. Remember ‘Om Nom’ from the Cut the Rope mobile games? Our little green friend rolled into a new adventure in ‘On Nom Bounce.’ He faces some fierce enemies and bosses which can be eliminated by shooting candy right at them! The aim, shoot and bounce your way through 40 challenging levels and 4 different environments. Upgrade your abilities along the way to defeat even the toughest enemies!
      `,
      tag: ["Puzzle","Girl games","Poki"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "crazygames",
     
      orientation: 0,
      title: "Friday Night Funkin game - playcutegames.com",
      name: ["Friday Night Funkin", "friday-night-funkin"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/friday-night-funkin/250x142.png",
      path: ["/game/friday-night-funkin", "https://playcutegames.com"],
      iframe:
        "https://v6p9d9t4.ssl.hwcdn.net/html/2876359-359162/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Friday Night Funkin Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Friday Night Funkin is a musical rhythm game where you compete in freestyle music battles. Press the arrow keys in time with the music to outdo your opponents and enjoy the cool beats!",
      tag: ["Friday Night Funkin","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"pc",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFFs Grunge Minimalist Fashion - playcutegames.com",
      name: ["BFFs Grunge Minimalist Fashion", "bffs-grunge-minimalist-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-grunge-minimalist-fashion/250x142.png",
      path: ["/game/bffs-grunge-minimalist-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-grunge-minimalist-fashion/",
      Walkthrough: "https://www.youtube.com/embed/mwqvKFzf9GE",
      s_dis:
        "BFFs Grunge Minimalist Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to BFFs Grunge Minimalist Fashion. The grunge look is a style based on the grunge music scene and these BFFs absolutely love it! The girls are always ready for a new fashion challenge and today they are exploring the grunge style! Are you ready to join in and help them find the perfect outfit?
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,    tag: ["LOL Surprise","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Fruit Ninja - playcutegames.com",
      name: ["Fruit Ninja", "fruit-ninja"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/fruit-ninja/250x142.png",
      path: ["/game/fruit-ninja", "https://playcutegames.com"],
      iframe:
        "https://html5.distributegames.com/lrllu2q2wli15xal5rlmnkiguxqxj6s5/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Fruit Ninja Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Fruit Ninja. Fruit Ninja is an addictive arcade game where you slash fruit to reach a high score. Make sure you avoid slicing bombs, though! This classic game was originally a mobile app and VR title. You’ll be slashing your way through watermelons, pineapples, and more as they fly through the air. Unfortunately, there’s also a few bombs that have been tossed into the mix! Avoid those while you focus on the fruit and try to earn a high-score`,
      tag: ["Action","Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Sand Balls Online - playcutegames.com",
      name: ["Sand Balls Online", "sand-balls-online"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/sand-balls/250x142.png",
      path: ["/game/sand-balls-online", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/4e41d5619e4b490a9f7d12b83b90490d/?gd_sdk_referrer_url=https://playcutegames.com/game/sand-balls-online/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Sand Balls Online Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Sand Balls Online. Make a path for your balls by moving your finger. Avoid and crash obstacles. Get as many balls to the finish line as you can. Use your creativity to dig through the floor, making water reach the underground flower to see her smile again. Try to find the best way to complete each level using your own solution, so be creative and dont be afraid to think out of the box!`,
      tag: ["Action","Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "YAD",
     
      orientation: 1,
      title: "Line Color 3D - playcutegames.com",
      name: ["Line Color 3D", "line-color-3d"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/line-color-3D/250x142.png",
      path: ["/game/line-color-3d", "https://playcutegames.com"],
      iframe:
        "https://www.yad.com/games/Line-Color-3d/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Line Color 3D Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Line Color 3D. This is an online Kids game, it’s playable on all smartphones or tablets, such as iPhone, iPad, Samsung, and other Apple and android systems. Line Color 3D is an interesting arcade game. It can test your reflection and help you kill your time. It’s a hard thing to pass all levels, but I believe you can do it! Avoid those obstacles on your road and try to complete more challenges.
         `,
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Undine Match The Pic - playcutegames.com",
      name: ["Undine Match The Pic", "undine-match-the-pic"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/undine-match-the-pic/250x142.png",
      path: ["/game/undine-match-the-pic", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1654878306/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Undine Match The Pic Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Undine Match The Pic. In the summer you want to go to the sea, swim and sunbathe! And it’s time to play another fun game. You need to find differences in two almost identical pictures. You will meet cute little mermaids and sea creatures, and train your mindfulness. Go ahead, hurry up before the time runs out. If you can’t find all the differences the first time, don’t be discouraged, try again, and you will definitely succeed!
         `,
      tag: ["Puzzle","Mermaid","Dl-Girls","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Core Fashion Battle - playcutegames.com",
      name: ["Celebrity Core Fashion Battle", "celebrity-core-fashion-battle"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-core-fashion-battle/250x142.png",
      path: ["/game/celebrity-core-fashion-battle", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-core-fashion-battle/",
      Walkthrough: "https://www.youtube.com/embed/WpSXmkgQhhw",
      s_dis:
        "Celebrity Core Fashion Battle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Core Fashion Battle. These celebrities want to try a core fashion. Each one of them wants to try a different core style. Help them to select a unique style for each one of them by choosing a fashion card. Then browse the wardrobe and pick cute outfits which shoe their fashion style.",
      tag: ["Celebrity","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Beauty Run Run - playcutegames.com",
      name: ["Beauty Run Run", "beauty-run-run"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/beauty-run-run/250x142.png",
      path: ["/game/beauty-run-run", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/60767897915e4e339f07e769f35a7cb9/?gd_sdk_referrer_url=https://playcutegames.com/game/beauty-run-run",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Beauty Run Run Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Beauty Run Run. There is no standard for beauty, everyone can be beautiful! In this game, you have the opportunity to try out various professions! Beauty Race is a fun casual game where you can choose the activity you are interested in. You can become a model or a firefighter. Or maybe you want to go skiing or to a water park?
        `,
      tag: ["Puzzle","Girl games","Poki"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Drink Master - playcutegames.com",
      name: ["Drink Master", "drink-master"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/drink-master/250x142.png",
      path: ["/game/drink-master", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/0093d9f049a941c0b9eb7e96146aebf5/?gd_sdk_referrer_url=https://playcutegames.com/games/drink-master",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Drink Master Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Have you always dreamt of working as a bartender? Do you consider perfectly poured cocktails and drinks to be your life goal? Do you always pour beer perfectly into a glass? Then Drink Master is the game for you! Become a Drink Master. Pour the liquor and show everyone that you can create the perfect cocktail! Don't forget to add an ice cube.",
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Aqua Blitz 2 - playcutegames.com",
      name: ["Aqua Blitz 2", "aquablitz-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/aquablitz-2/250x142.png",
      path: ["/game/aquablitz-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/ddfa9c1d2b474b7ab5d0ca26322bb9fd/?gd_sdk_referrer_url=https://playcutegames.com/games/aquablitz-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Aqua Blitz 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Aquablitz 2 - the newest iteration of the beloved underwater match-3 game that comes with fresh new graphics, a catchy tune and lots of improvements! Meet your new hostess, Pearl, the little mermaid. She will be pleased to guide you on your adventure through more than 700 splashy levels and teach you all the tricks you need to know to solve even the most complicated puzzles. Complete Daily Missions to earn extra coins and Boosters, or play the Daily Challenge to earn extra stars that might help you unlock treasure chests and gates to new levels. Make sure to come back and play every day to receive your Daily Rewards. Take part in regular Treasure Hunt events to collect sunken pirate treasures. Play Aquablitz 2 now for free and enjoy tons of addictive match-3 challenges.",
      tag: ["Puzzle","Girl games","Mermaid"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Influencers Aesthetic Fashion Challenge - playcutegames.com",
      name: ["Influencers Aesthetic Fashion Challenge", "influencers-aesthetic-fashion-challenge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/influencers-aesthetic-fashion-challenge/250x142.png",
      path: ["/game/influencers-aesthetic-fashion-challenge", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/influencers-aesthetic-fashion-challenge/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Influencers Aesthetic Fashion Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to the Influencers Aesthetic Fashion Challenge. Omg, the BFFs are going to participate in a new Influencer challenge. Complete the Look is the latest trend and you will have to pick cute makeup and a fabulous outfit for them. Pick a unique costume for all the girls. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Barbie","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Jewels Blitz 5 - playcutegames.com",
      name: ["Jewels Blitz 5", "jewels-blitz-5"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/jewels-blitz-5/250x142.png",
      path: ["/game/jewels-blitz-5", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/0dd92ae2a7214f289608049167f2ebd4/?gd_sdk_referrer_url=https://playcutegames.com/games/jewels-blitz-5",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Jewels Blitz 5 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Jewels Blitz 5, the long-awaited 5th installment of the legendary Match 3 game that takes you deep into the mystery of ancient cultures of Central America. You will explore Mayan Temples and try to solve their secrets and mysteries. Enjoy hundreds of levels, Daily Missions and Weekly Challenges. Earn gold and magical items!",
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Pinafore Fashion - playcutegames.com",
      name: ["BFFs Pinafore Fashion", "bffs-pinafore-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-pinafore-fashion/250x142.png",
      path: ["/game/bffs-pinafore-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-pinafore-fashion/",
      Walkthrough: "https://www.youtube.com/embed/1RuyAuKAfWc",
      s_dis:
        "BFFs Pinafore Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the BFFs Pinafore Fashion. Bffs planned to try new fashion with their old collection in their wardrobe. Yes! its Pinafore. Help them to put together a cute pinafore dress by first choosing a shape for the dress. Then decide if the dress should be in a single color or in one of the pre-made prints. Then you have to choose if the fabric of the dress should have a texture and add-ons like a zipper or buttons. After you’ve designed the dress, it’s time to dress up and style the model!",
      tag: ["LOL Surprise","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Crazy Games",
     
      orientation: 0,
      title: " Cups Water Sort Puzzle - playcutegames.com",
      name: [" Cups Water Sort Puzzle", "cups-water-sort-puzzle"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cups-water-sort-puzzle/250x142.png",
      path: ["/game/cups-water-sort-puzzle", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/cups---water-sort-puzzle/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Cups Water Sort Puzzle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Cups – Water Sort Puzzle is a fun puzzle game where you sort colored liquids into glass cups. Sort all the colors neatly together to move to the next level! Move the colors from one container to the next until each glass is filled to the top with one color. You can only pour liquid into another glass if it is the same color.",
      tag: ["Puzzle","Crazy Games","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Crazy Games",
     
      orientation: 0,
      title: "Funny Heroes Emergency - playcutegames.com",
      name: ["Funny Heroes Emergency", "funny-heroes-emergency"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/funny-heroes-emergency/250x142.png",
      path: ["/game/funny-heroes-emergency", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/funny-heroes-emergency/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Funny Heroes Emergency Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Funny Heroes Emergency is a dress-up game to makeover the heroes after their fight to save the world. Take care of all of their injuries to get them back into fighting! Many heroes after returning home after fights with criminals look very bad. Today in the game Funny Heroes Emergency you will have to help them clean up.",
      tag: ["Doctor","Crazy Games","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Influencers Girly Vs Tomboy - playcutegames.com",
      name: ["Influencers Girly Vs Tomboy", "influencers-girly-vs-tomboy"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/influencers-girly-vs-tomboy/250x142.png",
      path: ["/game/influencers-girly-vs-tomboy", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/influencers-girly-vs-tomboy/",
      Walkthrough: "https://www.youtube.com/embed/J7kEGLn0yr0",
      s_dis:
        "Influencers Girly Vs Tomboy Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to the Influencers Girly Vs Tomboy. What should these celebrities choose today? A cute, girly-girl outfit or maybe a tomboy one? It’s going to be your task to help them out decide on that! Join them in getting this amazing dress-up game started, run your fingers through their amazing wardrobe, and help them to decide on the style that best suits their mood today.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Celebrity","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Yummy Donut Factory - playcutegames.com",
      name: ["Yummy Donut Factory", "yummy-donut-factory"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/yummy-donut-factory/250x142.png",
      path: ["/game/yummy-donut-factory", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/d2d01e50efef4117895d07f85785bcd5/?gd_sdk_referrer_url=https://playcutegames.com/games/yummy-donut-factory",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Yummy Donut Factory Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Yummy Donut Factory. Let’s be a donut chef! Mix all ingredients by following the receipt to make the dough. Create different shapes and bake the donuts. Decorate and throw on some sweet goodies, such as candies, sprinkles, frosting, and many more. You’ll be the best donut baker ever!",
      tag: ["Cooking","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 1,
      title: "BFFs Flowers Inspired Fashion - playcutegames.com",
      name: ["BFFs Flowers Inspired Fashion", "bffs-flowers-inspired-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-flowers-inspired-fashion/250x142.png",
      path: ["/game/bffs-flowers-inspired-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-flowers-inspired-fashion/",
      Walkthrough: "https://www.youtube.com/embed/zUnsVCiPiho",
      s_dis:
        "Welcome to the BFFs Flowers Inspired Fashion. hese Bffs are true trendsetters, who love to enjoy the latest fashion. They love nature and natural styles in fashion.",
      l_dis:
        "Welcome to the BFFs Flowers Inspired Fashion. These Bffs are true trendsetters, who love to enjoy the latest fashion. They love nature and natural styles in fashion. They are obsessed with floral designs. Browse their wardrobe and select their favorite one. Then pick suitable costumes and hairstyles which match their outfits.",
      tag: ["Princess","Dress up","Make Up","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Escape Out - playcutegames.com",
      name: ["Escape Out", "escape-out"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/escape-out/250x142.png",
      path: ["/game/escape-out", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/1a8a9cbaed0f4fd58cbaf8a68656b6fa/?gd_sdk_referrer_url=https://playcutegames.com/game/escape-out/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Escape Out Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Escape Out. The escape adventures have never been so fun. Save yourself and your mates from unexpected traps and tricky obstacles. Dig the right way out and enjoy your freedom. He’s determined to sneak out of his cell and start digging a tunnel while the guard is distracted. He’ll need to work fast in order to get to his escape vehicle in this prison adventure game.",
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    
    {
      id: 202203001,
      brand: "Y8",
     
      orientation: 1,
      title: "Boat Hitting Out - playcutegames.com",
      name: ["Boat Hitting Out", "boat-hitting-out"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/boat-hitting-out/250x142.png",
      path: ["/game/boat-hitting-out", "https://playcutegames.com"],
      iframe:
        "https://download-cdn21.vigoo.com/boathittingout-download/index.html?key=y8&value=default",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Boat Hitting Out Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Boat Hitting Out. You are on the wooden raft on a sea beach, and the main task is to rescue the stickmen in the water on your raft. In addition to your boat, there will be others of different shapes and sizes who are your rivals. Bring them down, and then collect all those who fell into the water on their raft. The task is to score points due to the number of saved passengers. Do not miss the bonus umbrella.",
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Celebrity Social Media Adventure - playcutegames.com",
      name: ["Celebrity Social Media Adventure", "celebrity-social-media-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-social-media-adventure/250x142.png",
      path: ["/game/celebrity-social-media-adventure", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-social-media-adventure-game/",
      Walkthrough: "https://www.youtube.com/embed/Py6zUnrUyu8",
      s_dis:
        "Welcome to the Celebrity Social Media Adventure. Celebrity girls planned to try some adventure fashion over social media. Each one tries different fashions and posts them",
      l_dis:
        `Welcome to the Celebrity Social Media Adventure. Celebrity girls planned to try some adventure fashion over social media. Each one tries different fashions and posts them on social media and finds who trends the most. Sounds fun! right? Help the girls by browsing their wardrobes and picking the suitable costumes in each fashion. And then pick cute accessories and hairstyles.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Celebrity","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Wood Block Puzzle 2 - playcutegames.com",
      name: ["Wood Block Puzzle 2", "wood-block-puzzle-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/wood-block-puzzle-2/250x142.png",
      path: ["/game/wood-block-puzzle-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/cc846d5943ad4f769f22e87f7d00b14d/?gd_sdk_referrer_url=https://playcutegames.com/game/funny-kitty-care",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "The second sequel to the most addictive puzzle game of all-time! This puzzle game will exercise your brain for hours, days and months to come. It boasts a beautiful",
      l_dis:
        "The second sequel to the most addictive puzzle game of all-time! This puzzle game will exercise your brain for hours, days and months to come. It boasts a beautiful wooden design with carefully crafted puzzle pieces and a game board. Are you ready to become a master?",
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Funny Kitty Care - playcutegames.com",
      name: ["Funny Kitty Care", "funny-kitty-care"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/funny-kitty-care/250x142.png",
      path: ["/game/funny-kitty-care", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/d4736455671a43afae19e231ac08332e/?gd_sdk_referrer_url=https://playcutegames.com/game/funny-kitty-care",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Meow! Let's take care of an adorable stray kitty! You play as the rescuer who save a cute little kitty. Clean its dirty fur and treat the wounds until the kitty",
      l_dis:
        "Meow! Let's take care of an adorable stray kitty! You play as the rescuer who save a cute little kitty. Clean its dirty fur and treat the wounds until the kitty is completely healthy. Try dressing your kitty with various cute clothes and accessories. Enjoy Funny Kitty Care!",
      tag: ["Doctor","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 1,
      title: "Pop It Master - playcutegames.com",
      name: ["Pop It Master", "pop-it-master"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pop-it-master/250x142.png",
      path: ["/game/pop-it-master", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/pop-it-master/",
      Walkthrough: "https://www.youtube.com/embed/xWBH7Diyw2Y",
      s_dis:
        "Pop It Master is a relaxing online puzzle game. The game is based on the famous Pop It fidget toys. In this game, your objective is to press the popits to pop them and unlock",
      l_dis:
        "Pop It Master is a relaxing online puzzle game. The game is based on the famous Pop It fidget toys. In this game, your objective is to press the popits to pop them and unlock a colorful toy. Pay attention to pop every bubble and leave nothing behind! Play and have fun!",
      tag: ["Puzzle","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    // Add here 
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "My Dolphin Show 9 - playcutegames.com",
      name: ["My Dolphin Show 9", "my-dolphin-show-9"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-dolphin-show-9/250x142.png",
      path: ["/game/my-dolphin-show-9", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/a06295d0201144198cc53faf85505e52/?gd_sdk_referrer_url=https://playcutegames.com/game/my-dolphin-show-9",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Dolphin Show 9 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `
        This gorgeous facility was inspired by the art and culture of several Asian nations. Team up with your dolphin and wow the crowd with some really impressive flips and other tricks in this installment of the popular My Dolphin Show series. You’ll earn tons of coins you can trade in for waterproof kimonos, mawashis, and other really cool outfits!`,
      tag: ["My dolphin show","Animal","Dolphin",],
      hint: `PRESS THE LEFT AND RIGHT ARROWS to swim. PRESS THE UP ARROW to rise or leap. PRESS THE DOWN ARROW to dive.`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "My Dolphin Show 8 - playcutegames.com",
      name: ["My Dolphin Show 8", "my-dolphin-show-8"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-dolphin-show-8/250x142.png",
      path: ["/game/my-dolphin-show-8", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/e610689a14a64646b4189abe3fc76e3c/?gd_sdk_referrer_url=https://playcutegames.com/game/my-dolphin-show-8",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Dolphin Show 8 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to a paradise of aquatic sea animals! This ever popular show is unstoppable and offers a splashing 126 levels to play in 6 different worlds. Perform amazing tricks like doughnut jumping, splashing the audience and even score a soccer goal! Play various mini-games to keep your dolphin motivated. Are you ready to jump in and dive into a world of fun with your cute dolphin? Endless splashing`,
      tag: ["My dolphin show","Animal","Dolphin",],
      hint: `PRESS THE LEFT AND RIGHT ARROWS to swim. PRESS THE UP ARROW to rise or leap. PRESS THE DOWN ARROW to dive.`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "My Dolphin Show 7 - playcutegames.com",
      name: ["My Dolphin Show 7", "my-dolphin-show-7"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-dolphin-show-7/250x142.png",
      path: ["/game/my-dolphin-show-7", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/c0fca7ba3ffe4139afdb9f5ee8bbd584/?gd_sdk_referrer_url=https://playcutegames.com/game/my-dolphin-show-7",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Dolphin Show 7 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `The dolphin trainer and her dolphin are ready to impress the audience with a specular show. This is not a small aquarium, but a big show like you would see in Sea World or any other water park. New Jungle World with 18 levels.`,
      tag: ["My dolphin show","Animal","Dolphin",],
      hint: `please use your mouse to play`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "My Dolphin Show 6 - playcutegames.com",
      name: ["My Dolphin Show 6", "my-dolphin-show-6"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-dolphin-show-6/250x142.png",
      path: ["/game/my-dolphin-show-6", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/fed6eed89207405d93880eec4c547dbd/?gd_sdk_referrer_url=https://playcutegames.com/game/my-dolphin-show-6",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Dolphin Show 6 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `If you are into animal simulation games then try My Dolphin Show 4 available at Play Cute Games where you teach dolphins some impressive tricks to impress an awaiting crowd. Make special performances and travel through bright and beautiful underwater worlds to chase the limelight and demonstrate mind-blowing tricks. From jumping through hoops to splashing in style, every show is your opportunity to entertain. It is also possible to have a change of costume for the dolphins, making it an extra thrill for the crowds. There is always something new to do and explore, and the creativity available will keep you coming back for the fun and enjoyment. Assemble your guests, get your dolphin ready for the performance, and leap into a world of happiness and laughter with My Dolphin Show 4!`,
      tag: ["My dolphin show","Animal","Dolphin",],
      hint: `please use your mouse to play`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "My Dolphin Show 5 - playcutegames.com",
      name: ["My Dolphin Show 5", "my-dolphin-show-5"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-dolphin-show-5/250x142.png",
      path: ["/game/my-dolphin-show-5", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/02d11195e64540dcb743722c313fe739/?gd_sdk_referrer_url=https://playcutegames.com/game/my-dolphin-show-5",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Dolphin Show 5 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Play cute games has the fifth part of My Dolphin Show where you can actually teach dolphins to perform stunts and entertain the happy audience. Get into the water as you are able to make your dolphins perform other stunts and dress them up in trendy apparels that suits their performances. Every performance is an opportunity to produce special moments on stage, and to ‘wow’ the audience with jumps, flips and dives. Choose from a range of spectacular and colourful stages and underwater worlds, all of which presents varied and entertaining obstacles. The fact is that the more frequently the dancer performs, the more inventive he/she can be with the choreography, thus having a different show every time. The graphics of My Dolphin Show 5 are bright and childish, the gameplay is funny and entertaining, and that is why you should enjoy the Dolphin Show. Prepare yourself to have countless of hours of fun as you work hand in hand with your dolphins to be performers!`,
      tag: ["My dolphin show","Animal","Dolphin",],
      hint: `Arrow to move`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "My Dolphin Show 4 - playcutegames.com",
      name: ["My Dolphin Show 4", "my-dolphin-show-4"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-dolphin-show-4/250x142.png",
      path: ["/game/my-dolphin-show-4", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/ff1e3ccf2733433fa5815ab9bb5b4354/?gd_sdk_referrer_url=https://playcutegames.com/game/my-dolphin-show-4",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Dolphin Show 4 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Play My Dolphin Show 4 on Cute Games: teach your dolphins the most spectacular stunts so that the viewers would demand more! Create your own performances that shows off your dolphins style while at the same time incorporating the latest trinkets to make them appear fashionable. Every time there is a new performance, new problems are created and new possibilities to pull out new spectacular stunts. As if the underwater environment is alive and changing, it becomes easier to come up with more different ideas. Perform for the energetic audience as they respond to your dolphin’s stunts making each performance unique entertainment. Welcome to our underwater world, an amazing journey in which you can freely explore and dream!`,
      tag: ["My dolphin show","Animal","Dolphin",],
      hint: `Arrow to move`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Shopaholic Wedding Models - playcutegames.com",
      name: ["Shopaholic: Wedding Models", "shopaholic-wedding-models"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/shopaholic-wedding-models/250x142.png",
      path: ["/game/shopaholic-wedding-models", "https://playcutegames.com"],
      iframe:
        "https://games.cdn.spilcloud.com/1559569463_Shopaholic_Wedding_Models_html5_20150127-https/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Shopaholic Wedding Models Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Get ready to hit the streets of Rio for a great vacation filled with shopping and parties galore!`,
      tag: ["Shopaholic","Dress up","Make Up","Wedding","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Shopaholic: Rio - playcutegames.com",
      name: ["Shopaholic: Rio", "shopaholic-rio"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/shopaholic-rio/250x142.png",
      path: ["/game/shopaholic-rio", "https://playcutegames.com"],
      iframe:
        "https://games.cdn.spilcloud.com/1559567225_shopaholic_rio_mario-https/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Shopaholic: Rio Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `The big wedding is only a few days away. Check out these adorable boutiques and shop for the perfect dress.`,
      tag: ["Shopaholic","Dress up","Wedding","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Shopaholic: Tokyo - playcutegames.com",
      name: ["Shopaholic: Tokyo", "shopaholic-tokyo"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/shopaholic-tokyo/250x142.png",
      path: ["/game/shopaholic-tokyo", "https://playcutegames.com"],
      iframe:
        "https://games.cdn.spilcloud.com/1559568820_Shopaholic_Tokyo_08-04-https/S-T/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Shopaholic: Tokyo Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Hit the streets of Tokyo for an awesome shopping spree but don’t forget about your budget!`,
      tag: ["Shopaholic","Dress up","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Shopaholic: Beach Models - playcutegames.com",
      name: ["Shopaholic: Beach Models", "shopaholic-beach-models"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/shopaholic-beach-models/250x142.png",
      path: ["/game/shopaholic-beach-models", "https://playcutegames.com"],
      iframe:
        "https://www8.agame.com/mirror/html5/s/Shopaholic+Beach+Models/en_US/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Shopaholic: Beach Models Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Sun, sand and shops! It’s all here in this fun shopping game...time to find that perfect dress!`,
      tag: ["Shopaholic","Dress up","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
  
  
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Frozen Sisters Dream Wedding - playcutegames.com",
      name: ["Frozen Sisters Dream Wedding", "frozen-sisters-dream-wedding"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/frozen-sisters-dream-wedding/250x142.png",
      path: ["/game/frozen-sisters-dream-wedding", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/frozen-sisters-dream-wedding/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Frozen Sisters Dream Wedding. Princesses are more excited about their dream day with their beloved ones. Yes, It’s their wedding. Help them to get ready for this occasion",
      l_dis:
        `Welcome to the Frozen Sisters Dream Wedding. Princesses are more excited about their dream day with their beloved ones. Yes, It’s their wedding. Help them to get ready for this occasion. And make this occasion a more memorable one in her life! Browse their wardrobe and pick suitable outfits and costumes. Help them to decorate the cake and invitation card. Join them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess","Dress up","Make Up","Wedding","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Hawaii Match 3 - playcutegames.com",
      name: ["Hawaii Match 3", "hawaii-match-3"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/hawaii-match-3/250x142.png",
      path: ["/game/hawaii-match-3", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/24529a72b4d34329befd83c4352c6ddc/?gd_sdk_referrer_url=https://playcutegames.com/game/hawaii-match-3",
      Walkthrough: "https://www.youtube.com/embed/S4C1vaL1BmY",
      s_dis:
        "This beautiful Match 3 Game takes you on a journey through the beautiful nature of Hawaii. Combine and collect exotic fruits and flowers in over 2000+ Exciting Levels!",
      l_dis:
        "This beautiful Match 3 Game takes you on a journey through the beautiful nature of Hawaii. Combine and collect exotic fruits and flowers in over 2000+ Exciting Levels! Complete the Daily Missions and Daily Challenges to earn additional coins and other gifts!",
      tag: ["Princess","Dress up","Make Up","Wedding","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Celebrity Foodie Style - playcutegames.com",
      name: ["Celebrity Foodie Style", "celebrity-foodie-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-foodie-style/250x142.png",
      path: ["/game/celebrity-foodie-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-foodie-style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Celebrity Foodie Style. These Celebrity girls are more obsessed with both food and fashion. They planned to try this food and fashion in new trends.",
      l_dis:
        `Welcome to the Celebrity Foodie Style. These Celebrity girls are more obsessed with both food and fashion. They planned to try this food and fashion in new trends. Yes! It’s a foodie fashion. Browse the wardrobe and pick a suitable costume for each girl. Give a unique costume for them and prepare them to show their stunning look.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Celebrity","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Bubble Shooter Pro 2 - playcutegames.com",
      name: ["Bubble Shooter Pro 2", "bubble-shooter-pro-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bubble-shooter-pro-2/250x142.png",
      path: ["/game/bubble-shooter-pro-2", "https://playcutegames.com"],
      iframe:"https://html5.gamedistribution.com/4083ecb5efb64504a7904361c7def2ab/?gd_sdk_referrer_url=https://playcutegames.com/game/bubble-shooter-pro-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Bubble Shooter Pro 2 is the highly anticipated sequel to one of the most successful casual games of all time!! More bubbles, more boosters, brand new design and endless",
      l_dis:
        "Bubble Shooter Pro 2 is the highly anticipated sequel to one of the most successful casual games of all time!! More bubbles, more boosters, brand new design and endless hours of fun!",
      tag: ["Bubble","Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Beautician Princess - playcutegames.com",
      name: ["Beautician Princess", "beautician-princess"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/beautician-princess/250x142.png",
      path: ["/game/beautician-princess", "https://playcutegames.com"],
      iframe:
        "https://www.dl-girls.com/content/folder_1651246142/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Beautician Princess game. Modern technologies and materials make our lives better. Test yourself as a beautician.",
      l_dis:
        "Welcome to the Beautician Princess game. Modern technologies and materials make our lives better. Test yourself as a beautician. Help our beautiful princess to become even more beautiful. Get rid of dark circles under the eyes with hyaluronic acid. Straighten brow line. Make a tattoo of arrows near the eyes. In addition, make your lips a little bigger. Complete the look with light makeup and accessories!",
      tag: ["Princess","Dress up","Fashion","Dl-Girls","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Burger Mania - playcutegames.com",
      name: ["Burger Mania", "burger-mania"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/burger-mania/250x142.png",
      path: ["/game/burger-mania", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/burger-mania/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Burger Mania. Are you ready to build an incredibly tasty burger? Can you make tasty meals for your customers? They’ll have high expectations",
      l_dis:
        "Welcome to the Burger Mania. Are you ready to build an incredibly tasty burger? Can you make tasty meals for your customers? They’ll have high expectations in this restaurant game. Keep piling the toppings as high as they’ll go until it collapses under the weight of that mighty burger party!",
      tag: ["Celebrity","Cooking","Exclusive",""],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Sisters Ice Vs Flame - playcutegames.com",
      name: ["Sisters Ice Vs Flame", "sisters-ice-vs-flame"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/sisters-ice-vs-flame/250x142.png",
      path: ["/game/sisters-ice-vs-flame", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/sisters-ice-vs-flame/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Sisters Ice Vs Flame. Princesses have a debate on Ice Vs Flames fashion trends. Help the girls and have fun!",
      l_dis:
        `Welcome to the Sisters Ice Vs Flame. Princesses have a debate on Ice Vs Flames fashion trends. So they planned to try both and compete with each other with their stunning look. Help the princesses by browsing their wardrobes and picking a suitable outfit for each one of them. Try different hairstyles and accessories. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Spring Nail Art - playcutegames.com",
      name: ["Spring Nail Art", "spring-nail-art"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/spring-nail-art/250x142.png",
      path: ["/game/spring-nail-art", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1650642171/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Spring Nail Art. The long-awaited spring has arrived! Time to freshen up your look. Use the latest fashion trends and make a fabulous manicure. You can use",
      l_dis:
        "Welcome to Spring Nail Art. The long-awaited spring has arrived! Time to freshen up your look. Use the latest fashion trends and make a fabulous manicure. You can use a solid color or a fun spring pattern. Apply Mehendi to your hand and decorate it with rings and bracelets! Have fun!",
      tag: ["LOL Surprise","Decoration","Spring","Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Ellie Fashion Fever - playcutegames.com",
      name: ["Ellie Fashion Fever", "ellie-fashion-fever"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-fashion-fever/250x142.png",
      path: ["/game/ellie-fashion-fever", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ellie-fashion-fever/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Ellie Fashion Fever. Ellie is a supermodel who is inspired more by the latest fashion trends. She tired of trying old fashion and plan try something unique.",
      l_dis:
        "Welcome to Ellie Fashion Fever. Ellie is a supermodel who is inspired more by the latest fashion trends. She tired of trying old fashion and plan try something unique. Help her to try different trends by browsing her wardrobe and picking cute outfits in each fashion style. And don’t forget to try different hairstyles in each fashion.",
      tag: ["Barbie","Dress up","Make Up","Fashion","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Funny Kitty Haircut - playcutegames.com",
      name: ["Funny Kitty Haircut", "funny-kitty-haircut"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/funny-kitty-haircut/250x142.png",
      path: ["/game/funny-kitty-haircut", "https://playcutegames.com"],
      iframe: "https://html5.gamedistribution.com/79bd31d3b0124a7ca4ade79058f82d40/?gd_sdk_referrer_url=https://playcutegames.com/game/funny-kitty-haircut",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Funny Kitty Haircut. Have you ever dreamed of becoming the best hairdresser in your own hair salon? Help the kitties in this new haircut game! Run the best",
      l_dis:
        "Welcome to Funny Kitty Haircut. Have you ever dreamed of becoming the best hairdresser in your own hair salon? Help the kitties in this new haircut game! Run the best kitty’s salon and take care of your virtual kitties. Wash, color, and brush their hair, then create beautiful hairstyles. Don’t forget to dress up and accessorize them to become more fashionable!",
      tag: ["Girl games","Make Up","Hairstyle"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Design With Me SuperHero Tutu Outfits - playcutegames.com",
      name: ["Design With Me SuperHero Tutu Outfits", "design-with-me-superhero-tutu-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/design-with-me-superhero-tutu-outfits/250x142.png",
      path: ["/game/design-with-me-superhero-tutu-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/design-with-me-superhero-tutu-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Design With Me SuperHero Tutu Outfits. We all love superheroes very much! Likewise, our favorite princesses are inspired more by superhero tutu fashion.",
      l_dis:
        `Welcome to Design With Me SuperHero Tutu Outfits. We all love superheroes very much! Likewise, our favorite princesses are inspired more by superhero tutu fashion. They planned to organize the fashion events under this theme. Join and help each princess to pick a suitable superhero tutu outfit. Play them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess","Dress up","Make Up","Fashion","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Yiv",
     
      orientation: 1,
      title: "Tiles Hop - playcutegames.com",
      name: ["Tiles Hop", "tiles-hop"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/tiles-hop/250x142.png",
      path: ["/game/tiles-hop", "https://playcutegames.com"],
      iframe: "https://www.yiv.com/games/Tiles-Hop/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Tiles Hop Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      `Tiles Hop
      Tiles Hop is an online HTML5 game presented by playcutegames.com, it's playable in browsers such as safari and chrome. You can play the game on smartphone and tablet (iPhone, iPad, Samsung, Android devices and Windows Phone). Heard About Tiles Hop? With Tiles Hop, you can play various types of music, from beautiful Piano, Guitar songs to Rock and EDM masterpieces. Tiles Hop will be your best EDM game to relax.`,
      tag: ["Clicker","Arcade","Puzzle","Skill","Crazy games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Roller Ski Queen - playcutegames.com",
      name: ["Roller Ski Queen", "roller-ski-queen"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/roller-ski-queen/250x142.png",
      path: ["/game/roller-ski-queen", "https://playcutegames.com"],
      iframe: "https://html5.gamedistribution.com/df68abf5b8fa4a26942a9035e8cb5668/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Roller Ski Queen Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Become the most dazzling skating queen! Roller Ski Queen is an excellent ice skating game. Collect sharp blades to destroy obstacles and use coins to buy nice ski clothes! Are you ready to become the brightest star on the field? Come and try it out! Good luck and have fun!",
      tag: ["Clicker","Girl games","Arcade","Running","Hypercasual"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Enchantment - playcutegames.com",
      name: ["Enchantment", "enchantment"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/enchantment/250x142.png",
      path: ["/game/enchantment", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1650039357/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Enchantment. Help Mira put her face in order. Eliminate dark circles under the eyes, even out the tone of the face and the line of the eyebrows.Play and have fun",
      l_dis:
        "Welcome to Enchantment. Help Mira put her face in order. Eliminate dark circles under the eyes, even out the tone of the face and the line of the eyebrows. Pick a cute hairstyle and accessories. Then choose a bright traditional or modern outfit for Mira. Now our heroine is charm itself! Hurry up and play this wonderful game.",
      tag: ["LOL Surprise","Dress up","Make Up","Fashion","Spring","Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "LOL Surprise Fresh Spring Look - playcutegames.com",
      name: ["LOL Surprise Fresh Spring Look", "lol-surprise-fresh-spring-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lol-surprise-fresh-spring-look/250x142.png",
      path: ["/game/lol-surprise-fresh-spring-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/lol-surprise-fresh-spring-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Spring is here! Join the LOL Surprise Fresh Spring Look game at PlayCuteGames. Play for free and help the LOL Surprise Girls pick the best outfits",
      l_dis:
        `Welcome to the LOL Surprise Fresh Spring Look.  LOL Suprise Girls have some plans for welcoming their favorite season spring. They already filled their cupboard with new spring fashion. But they have a dilemma is to choose the best outfits. Browse the wardrobe and pick the suitable outfits for each one.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["LOL Surprise","Dress up","Make Up","Fashion","Spring","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Princess Villains - playcutegames.com",
      name: ["Princess Villains", "princess-villains"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-villains/250x142.png",
      path: ["/game/princess-villains", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1649431796/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Villains. Let’s fantasize a little and imagine our favorite princesses in the form of fabulous villains. Evil queens love dark colors.",
      l_dis:
        "Welcome to the Princess Villains. Let’s fantasize a little and imagine our favorite princesses in the form of fabulous villains. Evil queens love dark colors. Pick up luxurious outfits with gold and red decorations for the princesses. Makeup villains are bright and dark! Create the image of a vamp lady. Do not forget about the main accessories of the villains – magic staves, potions, magic wands, wings, and a cloak. Look at the usual things from a different angle, playing the game",
      tag: ["Princess","Dress up", "Make Up", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Princess Girls Steampunk Rivalry - playcutegames.com",
      name: ["Princess Girls Steampunk Rivalry", "princess-girls-steampunk-rivalry"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-girls-steampunk-rivalry/250x142.png",
      path: ["/game/princess-girls-steampunk-rivalry", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/princess-girls-steampunk-rivalry/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Girls Steampunk Rivalry. Princess Anna, Elsa, Jasmine, Ariel, Moana, and Snow White are getting ready to attend a fun-themed party. Play and have fun!",
      l_dis:
        "Welcome to the Princess Girls Steampunk Rivalry. Princess Anna, Elsa, Jasmine, Ariel, Moana, and Snow White are getting ready to attend a fun-themed party and they need your expert helping hand to properly get ready for it. The theme is steampunk and only you can help them slay some statement looks – are you ready to begin the fun? Come and join the Disney girls and have fun!",
      tag: ["Princess","Dress up","Make Up","Fashion","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Happy Easter - playcutegames.com",
      name: ["Princess Happy Easter", "princess-happy-easter"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-happy-easter/250x142.png",
      path: ["/game/princess-happy-easter", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_happy_easter/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Happy Easter game. Princess Elsa, Anna, and Ariel are best friends. After a long time, they met each other and planned to organize a get-together party for Easter.",
      l_dis:
        `Welcome to the Princess Happy Easter game. Princess Elsa, Anna, and Ariel are best friends. After a long time, they met each other and planned to organize a get-together party for Easter. They plan to try cute outfits and decorate the cookies with their own style to make this easter most memorable one. Join and help the princess with your ideas. Start with Elsa’s makeup, Help her pick cute pink shades of lipsticks and blushes. And give some purple or pink color of eyeshadows. Then browse her wardrobe and pick cute outfits and hairstyles with matching accessories. Do the same for other princesses. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Easter",
        "Anna",
        "Elsa",
        "Ariel",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Easter Sunday - playcutegames.com",
      name: ["Princess Easter Sunday", "princess-easter-sunday"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-easter-sunday/250x142.png",
      path: ["/game/princess-easter-sunday", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_easter_sunday/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Play Princess Easter Sunday, the best online game where you dress up princesses and decorate unique Easter eggs. Enjoy free fun now on PlayCuteGames!",
      l_dis:
        `Princess Easter Sunday is the most awaited celebration weekend in spring. The princesses have planned to build some awesome easter eggs and decorate them to look great.  And of course, we will have easter special dress up and style to excite the princesses & make them look great. Hope you have a lot of fun this season with the princesses.Before start decorating their eggs. They need to choose the perfect costume for this celebration. Help each princess with the dress-up part, try some cool outfits and accessories for each princess. And try different hairstyles for each one.Finally, help them to decorate their eggs on unique styles. In egg decoration part we can color for an egg, add some pattern. And also we add some hats and art in their eggs. So please help the princess and make this easter the most memorable one in their life.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Easter",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bffs Spring Break Fashionista - playcutegames.com",
      name: ["Bffs Spring Break Fashionista", "bffs-spring-break-fashionista"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-spring-break-fashionista/250x142.png",
      path: ["/game/bffs-spring-break-fashionista", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-spring-break-fashionista/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bffs Spring Break Fashionista. Spring break is here. Barbie and their friends are can’t wait to have some fun together. Spring break since the beginning",
      l_dis:
        `Welcome to the Bffs Spring Break Fashionista. Spring break is here. Barbie and their friends are can’t wait to have some fun together. They’ve been waiting for the spring break since the beginning of the year. Help them prepare their spring break outfits! They can finally wear skirts and dresses since it’s really warm outside now, so make sure to create a cute look! Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Barbie","Dress up","Make Up","Fashion","Spring","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Gala Prep - playcutegames.com",
      name: ["Celebrity Gala Prep", "celebrity-gala-prep"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-gala-prep/250x142.png",
      path: ["/game/celebrity-gala-prep", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-gala-prep/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Celebrity Gala Prep game. Ariana, Selena, Rihanna, Kendel Jenner, and Taylor Swift are planning a grand party. Did you have any idea of the theme?",
      l_dis:
        "Welcome to the Celebrity Gala Prep game. Ariana, Selena, Rihanna, Kendel Jenner, and Taylor Swift are planning a grand party. Did you have any idea of the theme? Yes, it’s a Gala party! Join and help the girls to choose the best outfit and accessories. Browse their wardrobe and pick a suitable one. Play and have fun!",
      tag: ["Celebrity","Dress up","Make Up","Fashion","Spring","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Disney Girls Spring Blossoms - playcutegames.com",
      name: ["Disney Girls Spring Blossoms", "disney-girls-spring-blossoms"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/disney-girls-spring-blossoms/250x142.png",
      path: ["/game/disney-girls-spring-blossoms", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/disney-girls-spring-blossoms/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Disney Girls Spring Blossoms. The Season of blossom has just begun. Princesses Anna. Elsa, Rapunzel, and Ariel are eagerly waiting for this season so long.",
      l_dis:
        `Welcome to the Disney Girls Spring Blossoms. The Season of blossom has just begun. Princesses Anna. Elsa, Rapunzel, and Ariel are eagerly waiting for this season so long. They have already renewed their spring wardrobe with cherry blossom collections. Browse the wardrobe and pick the best one for them. Help the girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess","Dress up","Make Up","Fashion","Spring","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Wonderful High Heels 3D - playcutegames.com",
      name: ["Wonderful High Heels 3D", "wonderful-high-heels-3d"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/wonderful-high-heels-3d/250x142.png",
      path: ["/game/wonderful-high-heels-3d", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/2e1b78022f93454793c1980302bd35c6/?gd_sdk_referrer_url=https://playcutegames.com/games/wonderful-high-heels-3d",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "There are so many levels that the difficulty of the game increases as the level goes on, and it is still fun to play and relatively challenging.",
      l_dis:
        "There are so many levels that the difficulty of the game increases as the level goes on, and it is still fun to play and relatively challenging. 2. Break the shackles and conquer more unknown places in the world, enjoy the fun of opening up more mechanisms waiting for you to crack, inside the track will change. 3. When you successfully complete a level, you get a partial reward here, which is great for buying various items.",
      tag: ['Addictive','3D',"GD"],
      hint: 2,
      platform:"both",  },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Merge Dreams - playcutegames.com",
      name: ["Merge Dreams", "merge-dreams"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/merge-dreams/250x142.png",
      path: ["/game/merge-dreams", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/f2aa0eeb79bc46e48a9c67a1b01a0d3e/?gd_sdk_referrer_url=https://playcutegames.com/games/merge-dreams",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Discover the magic of Merge Dreams! Combine items, build structures, and complete orders to earn Gold and unlock new adventures in this best online game!",
      l_dis:
        "Merge & Build a wonderland full of interesting classic characters and stories! You can combine everything into better and more powerful items for your journey. Build & upgrade all kinds of buildings to produce all kinds of useful products. Use these products to complete the orders to earn more Gold and get more experience points. The more experience points, the higher the level you are, and you can unlock more interesting characters, stories, lands, etc.",
      tag: ["Hypercasual",'Merge',"GD"],
      hint: 2,
      platform:"both",  },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Icy Purple Head 3 - playcutegames.com",
      name: ["Icy Purple Head 3", "icy-purple-head-3"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/icy-purple-head-3/250x142.png",
      path: ["/game/icy-purple-head-3", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/7514d8c628d14e7287f880a37f3a9ea4/?gd_sdk_referrer_url=https://playcutegames.com/games/icy-purple-head-3",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Icy Purple Head is back. Help post man to deliver parcels in time. Run jump and slide through levels. Unlock fun hats to make Icy loo even cooler! Be careful with the post birds and match the right color to hit them.",
      l_dis:
        "Icy Purple Head is back. Help post man to deliver parcels in time. Run jump and slide through levels. Unlock fun hats to make Icy loo even cooler! Be careful with the post birds and match the right color to hit them.",
      tag: ["Addictive", "Physics", "GD"],
      hint: 2,
      platform:"both",  },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Yummy Tales 2 - playcutegames.com",
      name: ["Yummy Tales 2", "yummy-tales-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/yummy-tales-2/250x142.png",
      path: ["/game/yummy-tales-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/629855c7b1d94998885d5af49942fc3c/?gd_sdk_referrer_url=https://playcutegames.com/games/yummy-tales-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Yummy Tales 2 is here with its new and amazing match 3 puzzles! Meet Oscar, your best friend and a clever little dog! He'll help you in feeding and caring for your farm animals.",
      l_dis:
        "Yummy Tales 2 is here with its new and amazing match 3 puzzles! Meet Oscar, your best friend and a clever little dog! He'll help you in feeding and caring for your farm animals. To remove them from the board, you must match three or more fruits or vegetables of the same kind. Continue mixing and matching fruits and vegetables until your animals are completely saturated. If you don't finish the level before you run out of moves, you'll have to start over! Play through over 900 different levels of puzzles. Complete Daily Missions and Challenges to earn fantastic rewards and gifts such as coins and boosters to help you beat even the hardest levels. Stop wasting time and play Yummy Tales 2 for free right now!",
      tag: ["Match-3", "Puzzles", "GD","Poki"],
      hint: 2,
      platform:"both",  },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Bubble Fight IO - playcutegames.com",
      name: ["Bubble Fight IO", "bubble-fight-io"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bubble-fight-io/250x142.png",
      path: ["/game/bubble-fight-io", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/1af7162fcd7347c580350c8c34ea5f7b/?gd_sdk_referrer_url=https://playcutegames.com/games/bubble-fight-io",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Bubble Fight IO is an online multiplayer Bubble Shooter game that you can play ANYTIME, ANYWHERE! The bubbles are stacked at the center of the arena and you take turn shooting them down.",
      l_dis:
        "Bubble Fight IO is an online multiplayer Bubble Shooter game that you can play ANYTIME, ANYWHERE! The bubbles are stacked at the center of the arena and you take turn shooting them down. The more bubbles you shoot down, the more damage you inflict to your opponent! Are you our next Bubble Fight IO champion?",
      tag: ["Multiplayer", ".IO", "GD", "Bubble"],
      hint: 2,
      platform:"both",  },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Sky Roller - playcutegames.com",
      name: ["Sky Roller", "sky-roller-1"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/sky-roller-1/250x142.png",
      path: ["/game/sky-roller-1", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/1f207255de1443d1ade622ad7fb184c3/?gd_sdk_referrer_url=https://playcutegames.com/games/sky-roller-1",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Your goal: to spread your legs so as not to crash into obstacles. Be agile to complete all the levels Get on the ROLLER right now!",
      l_dis:
        "Your goal: to spread your legs so as not to crash into obstacles. Be agile to complete all the levels Get on the ROLLER right now! Swipe your finger left-right to avoid obstacles and reach the finish line quickly! Can you NEVER crash? Is there enough skill? Unique controls - Control your legs and push them apart to choose the right path. Be agile and reach the BONUS level.",
      tag: ["Runner", "GD", "Hypercasual"],
      hint: 1,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Angela Design With Me Winter Sweater - playcutegames.com",
      name: [
        "Angela Design With Me Winter Sweater",
        "angela-design-with-me-winter-sweater",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-design-with-me-winter-sweater/250x142.png",
      path: [
        "/game/angela-design-with-me-winter-sweater",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.cutedressup.net/angela-design-with-me-winter-sweater",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Angela Design With Me Winter Sweater. At the beginning of the winter, Angela bought and renewed their wardrobe with cute sweaters. Now she tried everything and plan to design her own.",
      l_dis:
        "Welcome to Angela Design With Me Winter Sweater. At the beginning of the winter, Angela bought and renewed their wardrobe with cute sweaters. Now she tried everything and plan to design her own. Join and help Angela to design the better one. First, start choosing the shape of the sweater and then decorate this with beautiful color and pattern. Play and have fun!",
      tag: [
        "Angela",
        "Dress up",
        "Make Up",
        "Winter",
        "Decoration",
        "playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFFs Travelling Vibes - playcutegames.com",
      name: ["BFFs Travelling Vibes", "bffs-travelling-vibes"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-travelling-vibes/250x142.png",
      path: ["/game/bffs-travelling-vibes", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-travelling-vibes",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Get in the travel mood with BFFs Travelling Vibes! Assist the princesses in choosing their getaway by spinning the destination wheel. Play for free online",
      l_dis:
        `Welcome to the BFFs Travelling Vibes. Princesses Anna, Elsa, Moana, Ariel, Jasmine, and Snow White are in a travelling mood. But they have a dilemma to choose the destination. Help the girls to find out the perfect location for each one of them. It’s simple! Just spin the wheel to find the destination. Sounds fun right! Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Homecoming Party - playcutegames.com",
      name: ["BFFs Homecoming Party", "bffs-homecoming-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-homecoming-party/250x142.png",
      path: ["/game/bffs-homecoming-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-homecoming-party",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the BFFs Homecoming Party. After college days, Barbie and their friends are living from different locations. They planned to gather for a gettogether party.",
      l_dis:
        "Welcome to the BFFs Homecoming Party. After college days, Barbie and their friends are living from different locations. They planned to gather for a gettogether party. Help the girls to get ready with stunning outfits and matching accessories. And give some pleasant makeover for the girls. Play and have fun!",
      tag: [
        "Barbie",
        "Dress up",
        "Make Up",
        "party",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Perfect Tokyo Street Style - playcutegames.com",
      name: ["Perfect Tokyo Street Style", "perfect-tokyo-street-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/perfect-tokyo-street-style/250x142.png",
      path: ["/game/perfect-tokyo-street-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/perfect-tokyo-street-style",
      Walkthrough: "https://www.youtube.com/embed/dq7qLktSQho",
      s_dis:
        "Welcome to the Perfect Tokyo Street Style. Princess Elsa, Anna, Moana, Ariel, Snow White, and Jasmine are the best friends. They planned to throw a surprise weekend fashion party.",
      l_dis:
        `Welcome to the Perfect Tokyo Street Style. Princess Elsa, Anna, Moana, Ariel, Snow White, and Jasmine are the best friends. They planned to throw a surprise weekend fashion party. Everyone agreed to try Tokyo street style Fashion. join and browse the princesses wardrobe for the best collection of outfits and costumes. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs City Chic Fashion - playcutegames.com",
      name: ["BFFs City Chic Fashion", "bffs-city-chic-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-city-chic-fashion/250x142.png",
      path: ["/game/bffs-city-chic-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-city-chic-fashion",
      Walkthrough: "https://www.youtube.com/embed/iXxD-fenMtk",
      s_dis:
        "Welcome to the BFFs City Chic Fashion. Rainbow Girls Sunny, Skyler, Ruby, and Violet have an idea to try Plus-sized clothing. Yes! Its city chic fashion.",
      l_dis:
        "Welcome to the BFFs City Chic Fashion. Rainbow Girls Sunny, Skyler, Ruby, and Violet have an idea to try Plus-sized clothing. Yes! Its city chic fashion. Browse the wardrobe and find a cute set of outfits for the girls. Join and play these new dress-up games for girls. Have fun!",
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Kimono Fashion - playcutegames.com",
      name: ["BFFs Kimono Fashion", "bffs-kimono-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-kimono-fashion/250x142.png",
      path: ["/game/bffs-kimono-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-kimono-fashion",
      Walkthrough: "https://www.youtube.com/embed/6ARcIoGSLr0",
      s_dis:
        "Welcome to BFFs Kimono Fashion. Princesses Anna, Elsa, Moana, Ariel, Snow White, and Jasmine are planning to try Japanese traditional fashion.",
      l_dis:
        "Welcome to BFFs Kimono Fashion. Princesses Anna, Elsa, Moana, Ariel, Snow White, and Jasmine are planning to try Japanese traditional fashion. Yes, it’s Kimino fashion. Girls are so excited to try it out to show their stunning look. Help the girls by browsing their wardrobe and picking suitable costumes for each one. Play and have fun!",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "March Coloring Book - playcutegames.com",
      name: ["March Coloring Book", "march-coloring-book"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/march-coloring-book/250x142.png",
      path: ["/game/march-coloring-book", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1646578242/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the March Coloring Book. Hello, young artist. The best season of the year is approaching - spring.",
      l_dis:
        "Welcome to the March Coloring Book. Hello, young artist. The best season of the year is approaching – spring. This coloring game is dedicated to the first month of spring – March. Flowers and cute animals will not leave anyone indifferent! Enjoy playing this new collection of games with your friends. Have fun!",
      tag: ["Coloring Games", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "LOL Surprise Preppy Fashion - playcutegames.com",
      name: ["LOL Surprise Preppy Fashion", "lol-surprise-preppy-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lol-surprise-preppy-fashion/250x142.png",
      path: ["/game/lol-surprise-preppy-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lol-surprise-preppy-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to LOL Surprise Preppy Fashion. LOL Suprise doll plans to try a new stylish fashion.",
      l_dis:
        "Welcome to LOL Surprise Preppy Fashion. LOL Suprise doll plans to try a new stylish fashion. Yes! It’s preppy fashion. Browse and choose the best set of outfits for them. Show your fashion skill and give them a stunning look.",
      tag: ["LOL Surprise", "Dress up", "Fashion", "PlaycuteGames", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "St Patrick's Day Princess Challenge - playcutegames.com",
      name: [
        "St Patrick's Day Princess Challenge",
        "st-patricks-day-princess-challenge",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/st-patricks-day-princess-challenge/250x142.png",
      path: [
        "/game/st-patricks-day-princess-challenge",
        "https://playcutegames.com",
      ],
      iframe: "https://dressupwho.net/html5/St_Patricks_Day_Princess_Challenge/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the St Patrick’s Day Princess Challenge. St. Patrick’s Day symbolizes the adoption of Christianity in Ireland and is also a celebration of the cultural heritage of Ireland as a whole.",
      l_dis:
        "Welcome to the St Patrick’s Day Princess Challenge. St. Patrick’s Day symbolizes the adoption of Christianity in Ireland and is also a celebration of the cultural heritage of Ireland as a whole. Celebrations on St. Patrick’s Day usually include parades and festivals, performing Kelli dances, and wearing green robes or shamrocks. Three princesses – Tiana, Ariel, and Harley Quinn decided to dress up properly for this holiday and arrange a small challenge. Harley Quinn invites you to learn holiday makeup, Ariel opens a hair salon and Tiana is ready to share a recipe for a festive cocktail.",
      tag: ["Princess", "Dress up", "Fashion", "Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "Princess Gypsy Woodstock - playcutegames.com",
      name: ["Princess Gypsy Woodstock", "princess-gypsy-woodstock"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-gypsy-woodstock/250x142.png",
      path: ["/game/princess-gypsy-woodstock", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/princess-gypsy-woodstock/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Gypsy Woodstock. Gypsy Princess Woodstock invited Disney Princess Elsa and her friends to enjoy the beautiful scenery of Latin American countries.",
      l_dis:
        "Welcome to the Princess Gypsy Woodstock. Gypsy Princess Woodstock invited Disney Princess Elsa and her friends to enjoy the beautiful scenery of Latin American countries. They need to prepare some local style clothing. Because of the very hotness near the equator, they chose to have a bohemian and subtropical style. In the style of the long dress, Princess Elsa also brought an acoustic guitar. She hopes to spread Disney’s music to Latin America. Come and choose the right clothes and accessories for the princesses. Don’t forget to bring that acoustic guitar!",
      tag: ["LOL Surprise", "Dress up", "Fashion", "PlaycuteGames"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Celebrity Love Candy Outfits - playcutegames.com",
      name: ["Celebrity Love Candy Outfits", "celebrity-love-candy-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-love-candy-outfits/250x142.png",
      path: ["/game/celebrity-love-candy-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-love-candy-outfits",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Celebrity Love Candy Outfits. Celebrity girls Ariana Grande, Rihanna, Selena Gomez, Taylor Swift, and Kendell Jenner are best friends.",
      l_dis:
        `Welcome to Celebrity Love Candy Outfits. Celebrity girls Ariana Grande, Rihanna, Selena Gomez, Taylor Swift, and Kendell Jenner are best friends. They usually admire new trends. Now they plan to try candy fashion. Browse the wardrobe for each girl and pick a cute outfit for each one. Make sure everything is unique and different from other ones. Help the girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Celebrity",
        "Dress up",
        "Make Up",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Prinxy Dress Up Rewind - playcutegames.com",
      name: ["Prinxy Dress Up Rewind", "prinxy-dress-up-rewind"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/prinxy-dress-up-rewind/250x142.png",
      path: ["/game/prinxy-dress-up-rewind", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/af32f294-b25d-5d25-b4fe-1938b7e80d7d/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In the Prinxy Dress Up Rewind, we featured all your favorite games from 2021. It’s been a great year for fashion, and in it, you are going to find a bit of everything.",
      l_dis:
        "In the Prinxy Dress Up Rewind, we featured all your favorite games from 2021. It’s been a great year for fashion, and in it, you are going to find a bit of everything. From the titles inspired by the trending TikTok fashion to holiday-themed games, our exclusive fashion game is going to keep you entertained for hours. Now let’s get it started!",
      tag: ["Barbie", "Dress up", "Fashion", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Princess Runway Fashion Look - playcutegames.com",
      name: ["Princess Runway Fashion Look", "princess-runway-fashion-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-runway-fashion-look/250x142.png",
      path: ["/game/princess-runway-fashion-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess-runway-fashion-look",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Runway Fashion Look.  Princesses Anna, Elsa, Moana, Ariel, Snow White, and Jasmine are eagerly waiting for an upcoming fashion event.",
      l_dis:
        "Welcome to the Princess Runway Fashion Look.  Princesses Anna, Elsa, Moana, Ariel, Snow White, and Jasmine are eagerly waiting for an upcoming fashion event. They planned to show their fashion skill with their stunning look to attract everyone. Help the princess browse the wardrobe and pick a perfect outfit for each one of them.",
      tag: ["Princess", "Dress up", "Make Up", "Fashion", "Playcutegames"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "Bonnie Gucci Vs Prada - playcutegames.com",
      name: ["Bonnie Gucci Vs Prada", "bonnie-gucci-vs-prada"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bonnie-gucci-vs-prada/250x142.png",
      path: ["/game/bonnie-gucci-vs-prada", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/bonnie-gucci-prada/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bonnie Gucci Vs Prada. All you fashionistas gather up! Your sister here is facing a huge fashion dilemma:",
      l_dis:
        "Welcome to the Bonnie Gucci Vs Prada. All you fashionistas gather up! Your sister here is facing a huge fashion dilemma: she really can’t decide which label better fits her style and she could use your expert advice! So come and join her in getting this brand new Bonnie Game for girls started, check out your options and help her decide. The Italians Gucci and Prada are the two luxury brands causing her a hard time, so which one will you pick for her? Before deciding on that, you first have to do her makeup and then you get to browse through Prada’s latest collection and see which items you could use to dress up cute Bonnie with.",
      tag: ["Barbie", "Dress up", "Fashion", "Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Dotted Girl New Era - playcutegames.com",
      name: ["Dotted Girl New Era", "dotted-girl-new-era"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dotted-girl-new-era/250x142.png",
      path: ["/game/dotted-girl-new-era", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1645568804/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Dotted Girl New Era. Meet the SuperHeroine in a new look! Today she is an ordinary girl who lives an everyday life, trying on modern outfits and dresses.",
      l_dis:
        "Welcome to the Dotted Girl New Era. Meet the SuperHeroine in a new look! Today she is an ordinary girl who lives an everyday life, trying on modern outfits and dresses. Tomorrow she is a Superhero in a stylish jumpsuit and mask. Use a combination of different costume elements to create not only a unique hero but also a SuperVillain! In addition, in this wonderful game, you can choose and design a cute helper – kwami. Enjoy the new adventures of Dotted Girl by playing this gameś.",
      tag: ["Marinette", "Dress up", "Fashion", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Celebrity Future Fashion - playcutegames.com",
      name: ["Celebrity Future Fashion", "celebrity-future-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-future-fashion/250x142.png",
      path: ["/game/celebrity-future-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-future-fashion",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Celebrity Future Fashion. Taylor Swift, Kendell Jenner, Rihanna, Ariana Grande, and Selena Gomez are the celebrity plans to try something new fashion.",
      l_dis:
        `Welcome to the Celebrity Future Fashion. Taylor Swift, Kendell Jenner, Rihanna, Ariana Grande, and Selena Gomez are the celebrity plans to try something new fashion. They got an idea to try future fashion. Yes! it’s an upcoming fashion will trend over the fashion industry. But they need some expert advice to pick the right one for them. Play and help the girl!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Celebrity", "Dress up", "Make Up", "Fashion", "Cutedressup"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
  
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Tactical Princess - playcutegames.com",
      name: ["Tactical Princess", "tactical-princess"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tactical-princess/250x142.png",
      path: ["/game/tactical-princess", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1645116636/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Tactical Princess. A holiday dedicated to the defenders of the Fatherland is approaching. The princesses decided to celebrate it with a paintball match.",
      l_dis:
        "Welcome to the Tactical Princess. A holiday dedicated to the defenders of the Fatherland is approaching. The princesses decided to celebrate it with a paintball match. Train your memory and help the princesses collect equipment for the tournament. Which of the girls will become the most stylish tactical princess?",
      tag: ["Princess", "Dress up", "Fashion", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
  
      brand: "playcutegames",
     
      orientation: 0,
      title: "LOL Surprise Insta Party Divas - playcutegames.com",
      name: ["LOL Surprise Insta Party Divas", "lol-surprise-insta-party-divas"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lol-surprise-insta-party-divas/250x142.png",
      path: [
        "/game/lol-surprise-insta-party-divas",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/lol-surprise-insta-party-divas",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the LOL Surprise Insta Party Divas. LOL girls heard about the surprise Insta party. They planned to try some new trends.",
      l_dis:
        "Welcome to the LOL Surprise Insta Party Divas. LOL girls heard about the surprise Insta party. They planned to try some new trends. But they need some hands to be perfect. Browse the girl’s wardrobe for perfect outfits and costumes. Before that pick some cute makeover for the girls. Play and have fun! ",
      tag: ["LOL Surprise", "Dress up", "Make Up", "Fashion", "Playcutegames"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFFs Trendy Squad Fashion - playcutegames.com",
      name: ["BFFs Trendy Squad Fashion", "bffs-trendy-squad-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-trendy-squad-fashion/250x142.png",
      path: ["/game/bffs-trendy-squad-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-trendy-squad-fashion",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the BFFs Trendy Squad Fashion.  Barbie and her friends have a discussion on the weekend plan. And they come up with an idea to get ready for an upcoming fashion event.",
      l_dis:
        `Welcome to the BFFs Trendy Squad Fashion.  Barbie and her friends have a discussion on the weekend plan. And they come up with an idea to get ready for an upcoming fashion event. It’s a “Squad Fashion”. They all get excited to be show their fashion skill. Join and help the girls in preparation. Have fun! 
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Barbie", "Dress up", "Make Up", "Fashion", "Cutedressup"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "Your Favorite Royal Couple - playcutegames.com",
      name: ["Your Favorite Royal Couple", "your-favorite-royal-couple"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/your-favorite-royal-couple/250x142.png",
      path: ["/game/your-favorite-royal-couple", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/YourFavoriteRoyalCouple/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Your Favorite Royal Couple Choose your pair of favorites for Valentine’s Day! Who will it be? Maybe a wonderful pair of wizards – Princess Eliza and Jack Frost?",
      l_dis:
        "Your Favorite Royal Couple Choose your pair of favorites for Valentine’s Day! Who will it be? Maybe a wonderful pair of wizards – Princess Eliza and Jack Frost? Or the enamored from distant Agroba – an oriental princess and Prince Ali? Or maybe the rulers of great sea power – Prince Eric and the Little Mermaid? Rather, prepare the participants for the fashion show. Choose luxurious dresses for princesses and gorgeous outfits for princes. Makeup, hair, and accessories complete the look. Join with Your Favorite Royal Couple and have fun!",
      tag: ["Dress up", "Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Romantic Valentine Story - playcutegames.com",
      name: ["My Romantic Valentine Story", "my-romantic-valentine-story"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-romantic-valentine-story/250x142.png",
      path: ["/game/my-romantic-valentine-story", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-romantic-valentine-story",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Romantic Valentine Story game. Barbie’s family went out for a marriage function and they will return after two days. She was alone at home.",
      l_dis:
        "Welcome to the My Romantic Valentine Story game. Barbie’s family went out for a marriage function and they will return after two days. She was alone at home. So she invited Ken to her house and have a surprise plan for him. But the barbie house looks so messy. She needs some hands to clean before Ken arrives. Play and have fun!",
      tag: [
        "Barbie",
        "Dress up",
        "Make Up",
        "Cleaning",
        "Decoration",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Angela Perfect Valentine - playcutegames.com",
      name: ["Angela Perfect Valentine", "angela-perfect-valentine"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-perfect-valentine/230x131.png",
      path: ["/game/angela-perfect-valentine", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/angela-perfect-valentine/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Angela Perfect Valentine game. Angela and Tom love to spend time together on this valentine. They plan to wear new clothes and post them on social media.",
      l_dis:
        `Welcome to the Angela Perfect Valentine game. Angela and Tom love to spend time together on this valentine. They plan to wear new clothes and post them on social media. Tom has a surprise plan for Angela. Browse their wardrobe for perfect clothes. Play and help the couples. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Angela", "Dress up", "Decoration", "cutedressup","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Marie Prepares Treat for Valentine's Day - playcutegames.com",
      name: [
        "Marie Prepares Treat for Valentine's Day",
        "marie-prepares-treat-for-valentines-day",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/marie-prepares-treat-for-valentines-day/250x142.png",
      path: [
        "/game/marie-prepares-treat-for-valentines-day",
        "https://playcutegames.com",
      ],
      iframe: "https://dl-girls.com/content/folder_1644512126/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Marie Prepares Treat for Valentine’s Day. Princess Marie loves to cook. Holidays are approaching and the girl decided to cook her boyfriend a delicious surprise.",
      l_dis:
        "Welcome to the Marie Prepares Treat for Valentine’s Day. Princess Marie loves to cook. Holidays are approaching and the girl decided to cook her boyfriend a delicious surprise. Join us and find out the recipe for delicious handmade chocolates. Use different types of chocolate: dark, white, and milk. Add nuts and a lot of culinary decorations: sprinkles, fruit, lollipops, and much more! Then comes the turn of baking. Bake cupcakes. Decorate them with cream, fruit, and sprinkles. It remains to choose a festive dress and a cocktail. The threat is ready!",
      tag: ["Dress up", "Cooking", "Decoration", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Fairies Heart Style - playcutegames.com",
      name: ["Fairies Heart Style", "fairies-heart-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/fairies-heart-style/250x142.png",
      path: ["/game/fairies-heart-style", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1643819167/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Fairies Heart Style. Valentine’s Day is coming! Two BFF’s decided to have a festive challenge and dress up as cupid fairies.",
      l_dis:
        "Welcome to Fairies Heart Style. Valentine’s Day is coming! Two BFF’s decided to have a festive challenge and dress up as cupid fairies. Join the girls and help them choose light, airy outfits, luxurious fabulous dresses, heart-themed accessories, incredible hairstyles and charming makeup. A wide variety of cosmetics and outfits will not leave anyone indifferent.",
      tag: ["Dress up", "Make Up", "Fashion", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
  
      brand: "playcutegames",
     
      orientation: 0,
      title: "Angela Trendy Fashionista - playcutegames.com",
      name: ["Angela Trendy Fashionista", "angela-trendy-fashionista"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-trendy-fashionista/250x142.png",
      path: ["/game/angela-trendy-fashionista", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/angela-trendy-fashionista/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Angela Trendy Fashionista. Angela is more conscious of her fashion. She plans to prepare herself for different events.",
      l_dis:
        "Welcome to Angela Trendy Fashionista. Angela is more conscious of her fashion. She plans to prepare herself for different events. She already has a to-do list. Join and help the angela to be perfect on each occasion. Browse her wardrobe and pick outfits and costumes which is suitable for the events. Play and have fun! ",
      tag: ["Angela", "Dress up", "Fashion", "playcutegames"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "DressUpWho",
     
      orientation: 0,
      title: "Bonnie and BFFs Valentine Day Party - playcutegames.com",
      name: [
        "Bonnie and BFFs Valentine Day Party",
        "bonnie-and-bffs-valentine-day-party",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bonnie-and-bffs-valentine-day-party/250x142.png",
      path: [
        "/game/bonnie-and-bffs-valentine-day-party",
        "https://playcutegames.com",
      ],
      iframe: "https://dressupwho.net/html5/Bonnie_and_BFFs_Valentine_Day_Party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Bonnie and BFFs Valentine Day Party. Bonnie and her BFFs are getting ready for Valentine’s Day party. This is one of the most romantic holidays of the year, it is celebrated on February 14 in many countries of the world.",
      l_dis:
        "Welcome to Bonnie and BFFs Valentine Day Party. Bonnie and her BFFs are getting ready for Valentine’s Day party. This is one of the most romantic holidays of the year, it is celebrated on February 14 in many countries of the world. Immerse yourself in the magical world of pink colors! Choose from a variety of luxurious Valentine’s Day special edition outfits. Hearts, sparkles, and floral textures will not leave anyone indifferent! Use pink, red, gold tones for festive make-up, style girls’ hair in cute romantic hairstyles using holiday paraphernalia. Get in the holiday spirit with Bonnie by playing this awesome Bonnie and BFFs Valentine Day Party game on playcutegame.com.",
      tag: ["Dress up", "Make Up", "Valentine", "DressUpWho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Soft Grunge Looks - playcutegames.com",
      name: ["Princess Soft Grunge Looks", "princess-soft-grunge-looks"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-soft-grunge-looks/250x142.png",
      path: ["/game/princess-soft-grunge-looks", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess-soft-grunge-looks/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Soft Grunge Looks. Bffs Ariel, Moana, Snow White, and Jasmine are more fashioned princesses planning to try some unique fashion ideas.",
      l_dis:
        `Welcome to the Princess Soft Grunge Looks. Bffs Ariel, Moana, Snow White, and Jasmine are more fashioned princesses planning to try some unique fashion ideas. Princess Moana comes up with an idea to try Soft Grunge fashion. They all get excited and look forward to trying it out. Join and help the girls to pick a suitable costume. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess", "Dress up", "Fashion", "Cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Prinxy Winterella - playcutegames.com",
      name: ["Prinxy Winterella", "prinxy-winterella"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/prinxy-winterella/250x142.png",
      path: ["/game/prinxy-winterella", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/d1791122-0c90-5114-b709-b3a43f91b476/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Prinxy Winterella. If you’re still seeking inspiration for your upcoming winter ball, then look no further Our social media influencers bring a selection of jaw-dropping makeup looks that are going to help",
      l_dis:
        "Welcome to Prinxy Winterella. If you’re still seeking inspiration for your upcoming winter ball, then look no further Our social media influencers bring a selection of jaw-dropping makeup looks that are going to help you steal the spotlights during the most glamorous night of the year. In the Prinxy Winterella game for girls, you can discover the secrets of a sophisticated fantasy makeup look in icy cold colors. Play today and have fun!",
      tag: ["Dress up", "Make Up", "Winter", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Best FF Outfits - playcutegames.com",
      name: ["My Best FF Outfits", "my-best-ff-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-best-ff-outfits/250x142.png",
      path: ["/game/my-best-ff-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-best-ff-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Best FF Outfits. Princesses Anna, Elsa, Snowwhite, and Ariel are more font FF game. They always love to wear the FF costumes and accessories.",
      l_dis:
        "Welcome to the My Best FF Outfits. Princesses Anna, Elsa, Snowwhite, and Ariel are more font FF game. They always love to wear the FF costumes and accessories. So they planned to try FF outfits. Join and help the girls to pick a suitable outfit for each one of them. Try to choose unique costumes and hairstyles. Play and have fun!",
      tag: ["Princess", "Dress up", "Fashion", "Playcutegames", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "DressUpWho",
     
      orientation: 0,
      title: "Bonnie Rocker Chick - playcutegames.com",
      name: ["Bonnie Rocker Chick", "bonnie-rocker-chick"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bonnie-rocker-chick/250x142.png",
      path: ["/game/bonnie-rocker-chick", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/barbie-rocker-chick/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bonnie Rocker Chick. Bonnie and her friends have recently discovered rock music, so dress them all up in lovely outfits and bring out their edgy side.",
      l_dis:
        "Welcome to the Bonnie Rocker Chick. Bonnie and her friends have recently discovered rock music, so dress them all up in lovely outfits and bring out their edgy side. Start with Bonnie and add some black striped in her hair, dress her up with a pink crop top with white skull prints, a black and pink striped sports jacket, a ruffled black tulle skirt over a pair of pink leggings, black and pink boots, a bow in her hair with a skull badge and silver and black jewelry. Have a great time playing this exciting game!",
      tag: ["Barbie", "Dress up", "Make Up", "DressUpWho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls Space Core Aesthetic - playcutegames.com",
      name: [
        "Rainbow Girls Space Core Aesthetic",
        "rainbow-girls-space-core-aesthetic",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-space-core-aesthetic/250x142.png",
      path: [
        "/game/rainbow-girls-space-core-aesthetic",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/rainbow-girls-space-core-aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Rainbow Girls Space Core Aesthetic. Who loves space adventure? Everyone right! Rainbow Girls Skyler, Sunny, Ruby, and Violet are adventurer high school girls planning to try space aesthetic outfits.",
      l_dis:
        `Welcome to the Rainbow Girls Space Core Aesthetic. Who loves space adventure? Everyone right! Rainbow Girls Skyler, Sunny, Ruby, and Violet are adventurer high school girls planning to try space aesthetic outfits. Browse their wardrobe and pick a cute and suitable outfit for them. Join with the girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow high",
        "Dress up",
        "Make Up",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Crazy BFF Party - playcutegames.com",
      name: ["Crazy BFF Party", "crazy-bff-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/crazy-bff-party/250x142.png",
      path: ["/game/crazy-bff-party", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1642699140/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Crazy BFF Party. Two best friends – Princess Harlequin and Princess Jen decided to have a party of the century.",
      l_dis:
        "Welcome to the Crazy BFF Party. Two best friends – Princess Harlequin and Princess Jen decided to have a party of the century. These two girls are absolutely reckless funny brutes. They love daring informal clothing, bright colors, fishnet tights, tattoos, and unusual fashion combinations. Join the unbridled fun of the princesses, experiment with makeup and hairstyles. Choose incredible accessories for the girl’s original costumes. Time to go wild with the game.",
      tag: ["Dress up", "Make Up", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Princesses As Ancient Warriors - playcutegames.com",
      name: ["Princesses As Ancient Warriors", "princesses-as-ancient-warriors"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-as-ancient-warriors/250x142.png",
      path: [
        "/game/princesses-as-ancient-warriors",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess-as-ancient-warriors/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princesses As Ancient Warriors. Princesses Anna, Elsa, Ariel, and Moana are the best friends, they love to be ancient warriors in feature.",
      l_dis:
        "Welcome to the Princesses As Ancient Warriors. Princesses Anna, Elsa, Ariel, and Moana are the best friends, they love to be ancient warriors in feature. But these girls don’t want to wait as long. They planned to try cool warrior costumes and be like them now. Help the girls to browse their wardrobe. Join and have fun!",
      tag: ["Princess", "Dress up", "Fashion", "Playcutegames", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "LOL Soft Girls Aesthetic - playcutegames.com",
      name: ["LOL Soft Girls Aesthetic", "lol-soft-girls-aesthetic"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lol-soft-girls-aesthetic/250x142.png",
      path: ["/game/lol-soft-girls-aesthetic", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/lol-soft-girls/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the LOL Soft Girls Aesthetic. As you may already know, the LOL Dolls are committed to trying all the fashion trends from TikTok and today they are about to discover the #softgirlaesthetic style.",
      l_dis:
        "Welcome to the LOL Soft Girls Aesthetic. As you may already know, the LOL Dolls are committed to trying all the fashion trends from TikTok and today they are about to discover the #softgirlaesthetic style. Join with these three best friends. Help them to pick lovely pastel outfits and girly-girl accessories. Have fun!",
      tag: ["LOL Surprise", "Dress up", "Make Up", "Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Winter Knit Fashion - playcutegames.com",
      name: ["My Winter Knit Fashion", "my-winter-knit-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-winter-knit-fashion/250x142.png",
      path: ["/game/my-winter-knit-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-winter-knit-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Winter Knit Fashion. Winter is here! Hairdorable girls Dee dee, Willow, and Noah are more adorable in fashion. They planned to try knit fashion.",
      l_dis:
        `Welcome to the My Winter Knit Fashion. Winter is here! Hairdorable girls Dee dee, Willow, and Noah are more adorable in fashion. They planned to try knit fashion. Browse the wardrobe and help the girls to pick cute winter knit outfits. Join and play with the girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Hairdorables doll",
        "Dress up",
        "Make Up",
        "Winter",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Korean Supermodel Makeup - playcutegames.com",
      name: ["Korean Supermodel Makeup", "korean-supermodel-makeup"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/korean-supermodel-makeup/250x142.png",
      path: ["/game/korean-supermodel-makeup", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1642249015/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Korean Supermodel Makeup. The South Korean model became the star of the catwalks and won the love of millions of compatriots.",
      l_dis:
        "Welcome to Korean Supermodel Makeup. The South Korean model became the star of the catwalks and won the love of millions of compatriots. Deciding to learn a new profession, Jeong made her debut on the silver screen and gained worldwide popularity and many times more fans. The model opened her professional biography on the catwalk at the age of 16. In those years, the aspiring model did not even have an agent, however, when she appeared at Seoul Fashion Week, the girl impressed the representatives of the fashion industry with her fiery red hairdo, cheeky smile, and strong-willed character. Let’s learn the role of makeup artist and stylist for a young model. Pick up trendy makeup, hairstyle and accessories for Jeong. Take a photo for Instagram and get likes!",
      tag: ["Dress up", "Make Up", "Fashion", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Soft Girls Winter Aesthetics - playcutegames.com",
      name: ["Soft Girls Winter Aesthetics", "soft-girls-winter-aesthetics"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/soft-girls-winter-aesthetics/250x142.png",
      path: ["/game/soft-girls-winter-aesthetics", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/ba47d3b8-fac7-5019-a6bd-a4ada498352e/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Soft Girls Winter Aesthetics. Hop on a plane and spend your winter vacation traveling around the world together with your favorite Disney princesses.",
      l_dis:
        "Welcome to the Soft Girls Winter Aesthetics. Hop on a plane and spend your winter vacation traveling around the world together with your favorite Disney princesses. Discover fun winter activities and practice your fashion advisor skills while helping them choose their outfits of the day in the Soft Girls Winter Aesthetics for girls! Have a blast!",
      tag: ["Princess", "Dress up", "Make Up", "Winter", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Princess Look Like A Supermodel - playcutegames.com",
      name: [
        "Princess Look Like A Supermodel",
        "princess-look-like-a-supermodel",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-look-like-a-supermodel/250x142.png",
      path: [
        "/game/princess-look-like-a-supermodel",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess-look-like-a-supermodel/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Look Like A Supermodel. Princess Anna, Elsa, Moana, and Snow White are the BFFs who admired more on modeling.",
      l_dis:
        "Welcome to the Princess Look Like A Supermodel. Princess Anna, Elsa, Moana, and Snow White are the BFFs who admired more on modeling. They planned to be a supermodel in upcoming fashion events. Browse their wardrobe and help the princesses to be a supermodel.",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
  
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Cute Tiger Cub Care - playcutegames.com",
      name: ["Cute Tiger Cub Care", "cute-tiger-cub-care"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-tiger-cub-care/250x142.png",
      path: ["/game/cute-tiger-cub-care", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1641837336/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Take care of an adorable tiger cub in Cute Tiger Cub Care at PlayCuteGames! Play this free online game and give your cub the best love and attention",
      l_dis:
        "Welcome to the Cute Tiger Cub Care. Everyone knows that the symbol of this year is the Blue Water Tiger! The blue color symbolizes impermanence, water is moving, and the tiger is one of the most unpredictable and energetic animals. Therefore, those people who are not afraid of changes, lead an active lifestyle, and like to be constantly on the move will be on the horse. In traditional Chinese astrology, the Water Tiger is a big fan of purposeful and hardworking people. Let’s help this year’s mascot – a cute tiger cub. He’s a little sick. We need to cure him and prepare him for the new active year! Choose cute outfits and accessories for the tiger cub so that he pleases his fans all year round!",
      tag: ["Doctor", "Dress up", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls Perfect Winter Outfits - playcutegames.com",
      name: [
        "Rainbow Girls Perfect Winter Outfits",
        "rainbow-girls-perfect-winter-outfits",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-perfect-winter-outfits/250x142.png",
      path: [
        "/game/rainbow-girls-perfect-winter-outfits",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.cutedressup.net/rainbow-girls-perfect-winter-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Rainbow Girls Perfect Winter Outfits. Winter is not a season, It’s a celebration. Enjoy this winter with these cute rainbow girls.",
      l_dis:
        `Welcome to Rainbow Girls Perfect Winter Outfits. Winter is not a season, It’s a celebration. Enjoy this winter with these cute rainbow girls. These rainbow girls want to try something special this winter. Join and help the girls to pick cute outfits and accessories. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Winter",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Signature Styles - playcutegames.com",
      name: ["Celebrity Signature Styles", "celebrity-signature-styles"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-signature-styles/250x142.png",
      path: ["/game/celebrity-signature-styles", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-signature-styles/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Celebrity Signature Styles. Our favorite celebrities Selena Gomez, Taylor Swift, Rihanna, Ariana Grande, and Kendel Jenner are gathering together for celebrity events.",
      l_dis:
        "Welcome to the Celebrity Signature Styles. Our favorite celebrities Selena Gomez, Taylor Swift, Rihanna, Ariana Grande, and Kendel Jenner are gathering together for celebrity events. They planned to appear in their signature style. Join and help your favorite celebrities with your fashion skill. Pick a suitable outfit for the event. Have fun!",
      tag: [
        "Celebrity",
        "Dress up",
        "Make Up",
        "Fashion",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "GM",
     
      orientation: 0,
      title: "Princess Little Mermaid - playcutegames.com",
      name: ["Princess Little Mermaid", "princess-little-mermaid"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-little-mermaid/250x142.png",
      path: ["/game/princess-little-mermaid", "https://playcutegames.com"],
      iframe: "https://html5.gamemonetize.co/uhikvkzq4anzzaejqkh83fjy4x6xrn7y/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Princess Little Mermaid is a very addictive and cute makeup game. Princess little mermaid lives in the kingdom deep down the ocean.",
      l_dis:
        "Princess Little Mermaid is a very addictive and cute makeup game. Princess little mermaid lives in the kingdom deep down the ocean. She likes beautiful dresses and pretty makeup just like you. She has a best friend. They always stay together and put on makeup and enjoy their beautiful outfits. Do you want to experience her makeup process? In this game, there are many carefully selected pretty makeups for you to pick. And many lovely dresses, hairstyles and beautiful shoes for your to choose from. Come and play this game customized just for you!",
      tag: ["Princess", "Mermaid","Dress up", "Make Up"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Tiktok Musical Fest - playcutegames.com",
      name: ["Tiktok Musical Fest", "tiktok-musical-fest"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-musical-fest/250x142.png",
      path: ["/game/tiktok-musical-fest", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/tiktok-musical-fest/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Tiktok Musical Fest. Rainbow Girls Skyler and Sunny planned to participate in the TikTok musical fest along with their friends Ruby and Violet.",
      l_dis:
        `Welcome to the Tiktok Musical Fest. Rainbow Girls Skyler and Sunny planned to participate in the TikTok musical fest along with their friends Ruby and Violet. Join and help the girls with preparation. Pick a suitable costume and outfit for the music fest. Don’t forget to choose the best musical instrument. Join and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211121,
      brand: "GM",
     
      orientation: 0,
      title: "Diana City Fashion and beauty - playcutegames.com",
      name: ["Diana City Fashion and beauty", "diana-city-fashion-beauty"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/diana-city-fashion-beauty/250x142.png",
      path: ["/game/diana-city-fashion-beauty", "https://playcutegames.com"],
      iframe: "https://html5.gamemonetize.co/0xg5wnh53nprv6f4lejfnf2nrs91bph8/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Diana City Fashion & beauty. Love, Diana Dress-up Mobile Game. In the new room, Diana has nothing the little girl needs. She is upset.",
      l_dis:
        "Welcome to Diana City Fashion & beauty. Love, Diana Dress-up Mobile Game. In the new room, Diana has nothing the little girl needs. She is upset. Dad and Roma brings Diana dresses, jewelry and makeup toys. Pick a nice costumes and outfits for Diana and also select cute pet for her! Have fun!",
      tag: ["Dress up", "Fashion"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
  
      brand: "playcutegames",
     
      orientation: 0,
      title: "Rainbow Girls Neon Fashion - playcutegames.com",
      name: ["Rainbow Girls Neon Fashion", "rainbow-girls-neon-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-neon-fashion/250x142.png",
      path: ["/game/rainbow-girls-neon-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/rainbow-girls-neon-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Rainbow Girls Neon Fashion. Girls! Neon is always special and fun to wear! These Rainbow high girls are more fashionable in the latest trends.",
      l_dis:
        "Welcome to the Rainbow Girls Neon Fashion. Girls! Neon is always special and fun to wear! These Rainbow high girls are more fashionable in the latest trends. They planned to try this neon fashion. Join and help the girls with your creative ideas. Browse each wardrobe and try unique costumes for each girl! Have fun!",
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211121,
      brand: "Prinxy",
     
      orientation: 0,
      title: "K Pop New Year's Concert 2 - playcutegames.com",
      name: ["K Pop New Year's Concert 2", "k-pop-new-years-concert-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/k-pop-new-years-concert-2/250x142.png",
      path: ["/game/k-pop-new-years-concert-2", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/61f4128e-3907-5802-b771-83e28a45340a/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Prepare for the K-pop night in K Pop New Year's Concert 2! Help the girls create BlackPink-inspired looks for the concert. Play this free online game!",
      l_dis:
        "Welcome to the K-Pop New Year’s Concert 2. Right after midnight, the most famous K-pop band of the moment is going to entertain the gathering with their cool songs, and these best friends girls can’t wait to see the BlackPink girls performing. They’ve got the tickets, and they just need the right looks, and here is where you can help them out! Get ready to prove your stylist skills, ladies, and get them the right BlackPink inspired looks while playing the K-Pop New Year’s Concert 2dress up game for girls online!",
      tag: ["Dress up", "Make Up", "Fashion", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Get Ready For Winter - playcutegames.com",
      name: ["Princess Get Ready For Winter", "princess-get-ready-for-winter"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-get-ready-for-winter/250x142.png",
      path: ["/game/princess-get-ready-for-winter", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess-get-ready-for-winter/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Get Ready For Winter. Winter is almost there! Princess Anna, Elsa, Moana, Ariel, Jasmine, and Snow White are the best friends.",
      l_dis:
        `Welcome to the Princess Get Ready For Winter. Winter is almost there! Princess Anna, Elsa, Moana, Ariel, Jasmine, and Snow White are the best friends. They are more passionate about the latest trends. But these princesses still have a dilemma in welcoming the most lovable season. Join and help the princesses to pick cute outfits and costumes. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        ,
        "Winter",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211121,
  
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "New Year's Kigurumi - playcutegames.com",
      name: ["New Year's Kigurumi", "new-years-kigurumi"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/new-years-kigurumi/250x142.png",
      path: ["/game/new-years-kigurumi", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1640627705/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to New Year’s Kigurumi. What does it take to make this New Year the coziest holiday? Everything is very simple: instead of a tedious choice of outfit, you can dress in funny home pajamas",
      l_dis:
        "Welcome to New Year’s Kigurumi. What does it take to make this New Year the coziest holiday? Everything is very simple: instead of a tedious choice of outfit, you can dress in funny home pajamas – Kigurumi! It will be a real New Years’ pajama party! Join princesses Eliza and Jacqueline, do some fun New Year’s makeup, and choose your favorite pajamas. It can be a Kigurumi-tiger, a Christmas tree, or Santa Claus, or maybe you want to be a funny unicorn or a dragon? Put on your pajamas, cute slippers, and pour some hot chocolate! Celebrate the New Year comfortably by playing the game New Year’s Kigurumi.",
      tag: ["Princess", "Dress up", "Make Up", "New year", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },

  {
    date: "2024-01-31",
    brand: "playcutegames",
   
    orientation: 0,
    title: "Celebrity Lunar New Year - playcutegames.com",
    name: ["Celebrity Lunar New Year", "celebrity-lunar-new-year"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-lunar-new-year/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-lunar-new-year/520x295.png",
    path: ["/game/celebrity-lunar-new-year", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/celebrity-lunar-new-year/",
    Walkthrough: "https://www.youtube.com/embed/9wubQ4LgpFI",
    s_dis:
    "Celebrity Lunar New Year Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Step into the star-studded glamour of ‘Celebrity Lunar New Year’! Explore festive ensembles, elegant accessories, and the magic of the lunar celebration. Join celebrities as they set the trend for the perfect Lunar New Year look. Elevate your style and embrace the cultural richness with the ultimate celebrity-inspired fashion. From traditional elegance to modern chic, let the stars guide you in creating a memorable and stylish Lunar New Year wardrobe. Celebrate in glamour with Celebrity Lunar New Year!",
      tag: ["Celebrity","Dress up","Exclusive","New year","Chinese","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
    {
      id: 202111265,
      brand: "Kawaii",
     
      orientation: 0,
      title: "Kawaii Witch School Dress Up Game - playcutegames.com",
      name: [
        "Kawaii Witch School Dress Up Game",
        "kawaii-witch-school-dress-up-game",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/kawaii-witch-school-dress-up-game/250x142.png",
      path: [
        "/game/kawaii-witch-school-dress-up-game",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.kawaiigames.net/jogos/kawaii-witch-school-dress-up-game/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Kawaii Witch School Dress Up Game. Dress up your anime-style witch however you want. She will start at magic school and needs to be in the look that matches her personality!",
      l_dis:
        "Welcome to Kawaii Witch School Dress Up Game. Dress up your anime-style witch however you want. She will start at magic school and needs to be in the look that matches her personality! Choose from different types of hair and bangs, from short to long hairstyles. You can choose fantastic dresses or create interesting combinations with blouses, skirts, shorts, and pants for a more laid-back look. You can select from cute sneakers with laces and charming boots. Put on glasses to see beyond the reach and wear your magic hat, a must-have for every witch. And a powerful sorceress is accompanied by extraordinary animals. ",
      tag: ["Dress up", "Fashion", "Kawaii"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Sweet Candy Outfits - playcutegames.com",
      name: ["My Sweet Candy Outfits", "my-sweet-candy-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-sweet-candy-outfits/250x142.png",
      path: ["/game/my-sweet-candy-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-sweet-candy-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Sweet Candy Outfits. Rainbow high girls Skyler, Sunny, Ruby, and Violet are planning a weekend party. But a party without a theme is not fun!",
      l_dis:
        `Welcome to the My Sweet Candy Outfits. Rainbow high girls Skyler, Sunny, Ruby, and Violet are planning a weekend party. But a party without a theme is not fun! So they planned to be in Sweet candy themes. Help the girls to browse the wardrobe and pick the cute candy outfit for them. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Rainbow High", "Dress up", "Make Up", "cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211121,
      brand: "Yiv",
     
      orientation: 0,
      title: "Superhero Dentist - playcutegames.com",
      name: ["Superhero Dentist", "superhero-dentist"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/superhero-dentist/250x142.png",
      path: ["/game/superhero-dentist", "https://playcutegames.com"],
      iframe: "https://www.yiv.com/games/Superhero-Dentist-1/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Be the best dentist in Superhero Dentist online! Use tweezers, sprays, and braces to treat heroes. Play this free game now on playcutegames.",
      l_dis:
        "Welcome to the Superhero Dentist game.  Be the best dentist at this Superhero clinic! All our patient is Superhero, who is dedicated to fighting crime, protecting the public, and usually battling supervillains, so their whereabouts are to be kept secret. Treat our patients with crazy tools like mouth spray, dental tweezers, and braces!",
      tag: ["Doctor", "Yiv","Dentist"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    
    {
      id: 20211164,
  
      brand: "Yiv",
     
      orientation: 0,
      title: "Makeup Artist Fashion Salon - playcutegames.com",
      name: ["Makeup Artist Fashion Salon", "makeup-artist-fashion-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/makeup-artist-fashion-salon/250x142.png",
      path: ["/game/makeup-artist-fashion-salon", "https://playcutegames.com"],
      iframe: "https://html5.gamemonetize.co/zc22nrlaspi1nv9ikvypvex7uhohezop/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Makeup Artist Fashion Salon. Why not become a makeup artist? Makeup with your style and pick your favorite match. There are many types of cosmetics that can be used here. It’s very interesting.",
      l_dis:
        "Welcome to Makeup Artist Fashion Salon. Why not become a makeup artist? Makeup with your style and pick your favorite match. There are many types of cosmetics that can be used here. It’s very interesting. You can use foundation, eye makeup, lipstick, hairstyle, necklace, and earrings to dress up. Tons of different designs for you to discover, cloud, butterfly, rainbow, Christmas, and Halloween, everything in trend can be an inspiration for your style. Come and challenge your fashion skill now!",
      tag: ["Make Up", "Yiv"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111263,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Winter Ice Skating Outfits - playcutegames.com",
      name: [
        "Princess Winter Ice Skating Outfits",
        "princess-winter-ice-skating-outfits",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-winter-ice-skating-outfits/250x142.png",
      path: [
        "/game/princess-winter-ice-skating-outfits",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.cutedressup.net/princess-winter-ice-skating-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess Winter Ice Skating Outfits. Girls enjoy this winter with your favorite princess. Princesses Anna, Elsa, Moana, Ariel, Jasmine, and Snow White are planning to go for Ice skating.",
      l_dis:
        `Welcome to Princess Winter Ice Skating Outfits. Girls enjoy this winter with your favorite princess. Princesses Anna, Elsa, Moana, Ariel, Jasmine, and Snow White are planning to go for Ice skating. But before that, they need perfect outfits. Help the princesses with their outfits with matching boots. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Winter",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211121,
      brand: "cutedressup",
     
      orientation: 0,
      title: "TikTok Inspired Outfits - playcutegames.com",
      name: ["TikTok Inspired Outfits", "tiktok-inspired-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-inspired-outfits/250x142.png",
      path: ["/game/tiktok-inspired-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/tiktok-inspired-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the TikTok Inspired Outfits. Princesses Elsa, Anna, Moana, and Jasmine are big fans of Tiktok fashion. They always fill their wardrobe with new TikTok collections.",
      l_dis:
        `Welcome to the TikTok Inspired Outfits. Princesses Elsa, Anna, Moana, and Jasmine are big fans of Tiktok fashion. They always fill their wardrobe with new TikTok collections. Join and help the princess to pick the fabulous outfit from their collections. Before that try some crazy makeup to show a gorgeous look. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "TikTok",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211210,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Eliza Winter Coronation - playcutegames.com",
      name: ["Eliza Winter Coronation", "eliza-winter-coronation"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/eliza-winter-coronation/250x142.png",
      path: ["/game/eliza-winter-coronation", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1639673873/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Eliza Winter Coronation. Princess Elisa assumes the throne this winter. She's getting ready to be queen. This is a very significant event for a girl.",
      l_dis:
        "Welcome to Eliza Winter Coronation. Princess Elisa assumes the throne this winter. She's getting ready to be queen. This is a very significant event for a girl. A princess must be irresistible at her coronation. Eliza's favorite season is winter, so the holiday will take place with the onset of cold weather. Let's travel to Eliza's magical ice kingdom and help her prepare for her coronation. ",
      tag: ["Princess", "Dress up", "Make up", "Winter", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211210,
      brand: "dressupwho",
     
      orientation: 0,
      title: "Baby Princesses Wonderful Christmas - playcutegames.com",
      name: [
        "Baby Princesses Wonderful Christmas",
        "baby-princesses-wonderful-christmas",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/baby-princesses-wonderful-christmas/250x142.png",
      path: [
        "/game/baby-princesses-wonderful-christmas",
        "https://playcutegames.com",
      ],
      iframe: "https://dressupwho.net/html5/Baby_Princesses_Wonderful_Christmas/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Baby Princesses Wonderful Christmas Little princesses Eliza, Jasmin, Tiara, Rosehip, Cinderella, Mermaid getting ready for the most wonderful holiday of the year - Christmas!",
      l_dis:
        "Baby Princesses Wonderful Christmas Little princesses Eliza, Jasmin, Tiara, Rosehip, Cinderella, Mermaid getting ready for the most wonderful holiday of the year - Christmas! Let's help the little ones choose cute trendy outfits for the Christmas ball. Just look how many sweetie dresses, skirts, shoes, and blouses the princesses have in their wardrobe. All outfits sparkle and shimmer in the light of festive garlands. Don't forget to choose an evening hairstyle for each princess. Have a lot of fun playing the game Baby Princesses Wonderful Christmas.",
      tag: ["Princess", "Dress up", "Christmas", "DressupWho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211125,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Angela Insta Fashion Stories - playcutegames.com",
      name: ["Angela Insta Fashion Stories", "angela-insta-fashion-stories"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-insta-fashion-stories/250x142.png",
      path: ["/game/angela-insta-fashion-stories", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/angela-insta-fashion-stories/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Angela Insta Fashion Stories. Angela feels about her popularity on social media. She has plans to attract the people on her side.",
      l_dis:
        `Welcome to the Angela Insta Fashion Stories. Angela feels about her popularity on social media. She has plans to attract the people on her side. She decided to share posts and stories among the popular hashtags. But she needs some experts like you. Come and join with Angela and make an attractive post. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Angela", "Dress up", "cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211124,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Kiki's Pink Christmas - playcutegames.com",
      name: ["Kiki's Pink Christmas", "kikis-pink-christmas"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/kikis-pink-christmas/250x142.png",
      path: ["/game/kikis-pink-christmas", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/54c1ac04-ec82-543f-b7de-4dc86f9a835c/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Kiki’s Pink Christmas. Come and help gorgeous Kiki get her holiday outfits ready in time for Christmas Day.",
      l_dis:
        "Welcome to Kiki’s Pink Christmas. Come and help gorgeous Kiki get her holiday outfits ready in time for Christmas Day. She needs four statement looks and you are invited to help her out. In this brand-new Christmas dress-up game for girls called Kiki’s Pink Christmas you dress up our girl in comfy chic homeware, fashionable kitchen outfits, glam festive outfits, and colorful outdoor outfits. Go through all the options and create four unique Christmas looks for cute Kiki. Don’t forget to also deal with her makeup look. Have a blast!",
      tag: ["Princess", "Dress up", "Christmas", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    
    {
      id: 20211124,
  
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Angelcore Princess - playcutegames.com",
      name: ["Angelcore Princess", "angelcore-princess"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angelcore-princess/250x142.png",
      path: ["/game/angelcore-princess", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1638849435/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Angelcore Princess. Angelcore is a fashion inspired by images of angels. This style is intended to depict the same unearthly beauty with which European angels",
      l_dis:
        "Welcome to the Angelcore Princess. Angelcore is a fashion inspired by images of angels. This style is intended to depict the same unearthly beauty with which European angels are described and portrayed, regardless of whether it is done using modern or old methods of depiction. Angelcore is based on the tenderness, gentleness, and purity of angels in modern culture. Pastel colors and a nostalgic vintage vibe are also hallmarks of this style. Join princesses Eliza and Tiara to experience this amazing style of Angelcore together. Choose a bow from a variety of delicate, airy outfits – dresses, blouses, skirts. Try one of two hairstyles for each princess, as well as a choice of makeup. This unearthly style will not leave anyone indifferent!",
      tag: ["Princess", "Dress up", "Make Up", "Decoration", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211123,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls Christmas Outfits - playcutegames.com",
      name: [
        "Rainbow Girls Christmas Outfits",
        "rainbow-girls-christmas-outfits",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-christmas-outfits/250x142.png",
      path: [
        "/game/rainbow-girls-christmas-outfits",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/rainbow-girls-christmas-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Rainbow Girls Christmas Outfits. Rainbow girls Skyler, Sunny, Ruby, and Violet get ready for Christmas celebrations.",
      l_dis:
        `Welcome to the Rainbow Girls Christmas Outfits. Rainbow girls Skyler, Sunny, Ruby, and Violet get ready for Christmas celebrations. They want to try cute Christmas outfits with crazy makeovers. Browse their wardrobe for cute outfits and pick some matching accessories. Join with these girls and enjoy this Christmas a most memorable one. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Christmas",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211122,
  
      brand: "GM",
     
      orientation: 0,
      title: "Foot Doctor 3D Game - playcutegames.com",
      name: ["Foot Doctor 3D Game", "foot-doctor-3d-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/foot-doctor-3d-game/250x142.png",
      path: ["/game/foot-doctor-3d-game", "https://playcutegames.com"],
      iframe: "https://html5.gamemonetize.co/yzekeqiukej2cjdfb3ed029npxtk9cz2/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Foot Doctor 3D Game. HELP! Doctor, I hurt my feet, ouch! It is so painful! Could you please help me to cure my feet? I know you have the best medical tools here! Foot Doctor features:",
      l_dis:
        "Welcome to the Foot Doctor 3D Game. HELP! Doctor, I hurt my feet, ouch! It is so painful! Could you please help me to cure my feet? I know you have the best medical tools here! Foot Doctor features: ♥ Select adorable patients. ♥ Lots of Free medical tools designed. ♥ Placate patients with free gifts.",
      tag: ["Doctor"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211202,
      brand: "dressupwho",
     
      orientation: 0,
      title: "Cute Couple Ariana - playcutegames.com",
      name: ["Cute Couple Ariana", "cute-couple-ariana"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-couple-ariana/250x142.png",
      path: ["/game/cute-couple-ariana", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/cute-couple-ariana/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Cute Couple Ariana. Ariana Grande and Dalton Gomez together in the Cute Couple dress-up routine! Go with them to a party, school, the dance studio, outside and others.",
      l_dis:
        "Welcome to the Cute Couple Ariana. Ariana Grande and Dalton Gomez together in the Cute Couple dress-up routine! Go with them to a party, school, the dance studio, outside and others. Enjoy playing these new dress up games for girls. Join and help Ariana and Dalton with their makeover and have fun!",
      tag: ["Celebrity", "Dress up", "Make Up", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211201,
      brand: "babygames",
     
      orientation: 0,
      title: "Dress Up Unicorn - playcutegames.com",
      name: ["Dress Up Unicorn", "dress-up-unicorn"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dress-up-unicorn/250x142.png",
      path: ["/game/dress-up-unicorn", "https://playcutegames.com"],
      iframe: "https://www.babygames.com/games/Dress-Up-Unicorn/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Dress Up Unicorn. Unicorns are magical horses with a horn on their head. Have you ever dreamed to own a pet unicorn? In this game, you can create a huge number of outfits for four kinds of unicorns.",
      l_dis:
        "Welcome to Dress Up Unicorn. Unicorns are magical horses with a horn on their head. Have you ever dreamed to own a pet unicorn? In this game, you can create a huge number of outfits for four kinds of unicorns. Let’s dress up and take care of the unicorn. Charming style starts",
      tag: ["Dress up", "Make Up", "Decoration", "babygames"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211121,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bffs Rainbow Fashion Addict - playcutegames.com",
      name: ["Bffs Rainbow Fashion Addict", "bffs-rainbow-fashion-addict"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-rainbow-fashion-addict/250x142.png",
      path: ["/game/bffs-rainbow-fashion-addict", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-rainbow-fashion-addict/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bffs Rainbow Fashion Addict. Bffs Skyler, Sunny, Ruby, and Violet are more interested to try rainbow fashion outfits.",
      l_dis:
        `Welcome to the Bffs Rainbow Fashion Addict. Bffs Skyler, Sunny, Ruby, and Violet are more interested to try rainbow fashion outfits. They are more attractive, elegant, and beautiful to wear. These girls already fulfilled their wardrobe with the latest collection, but they have been confused to choose the better one. Join as a design expert and help the girls to give their stunning look. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211120,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Riddles of Squid - playcutegames.com",
      name: ["Riddles of Squid", "riddles-of-squid"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/riddles-of-squid/250x142.png",
      path: ["/game/riddles-of-squid", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1638164149/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Riddles of Squid. Introducing an exciting new game based on the acclaimed Korean TV series. You have to play as a brave girl who wants to win the main prize.",
      l_dis:
        "Welcome to Riddles of Squid. Introducing an exciting new game based on the acclaimed Korean TV series. You have to play as a brave girl who wants to win the main prize. Use your luck and skills to win. And at the end you will receive a reward – you can choose cool outfits, hairstyles and makeup for your heroine. Hurry up – play this addicting game and solve all the Riddles Of Squid!",
      tag: ["Dress up", "Make Up", "Celebrity", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211119,
      brand: "dressupwho",
     
      orientation: 0,
      title: "So Different Zendaya - playcutegames.com",
      name: ["So Different Zendaya", "so-different-zendaya"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/so-different-zendaya/250x142.png",
      path: ["/game/so-different-zendaya", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/So_Different_Zendaya/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to So Different Zendaya game. We have often seen Zendaya on the red carpet, where she showcases form-fitting outfits from famous designers and shows her perfect figure.",
      l_dis:
        "Welcome to So Different Zendaya game. We have often seen Zendaya on the red carpet, where she showcases form-fitting outfits from famous designers and shows her perfect figure. But, in fact, she is a very versatile girl. Zendaya has been dancing since childhood. At the age of 8, she was a member of the hip-hop troupe of the California Theater, where her mother worked. Zendaya began to go to strength training when contracts for films appeared, where you need to perform physically difficult stunts. The actress goes to the gym 3-4 times a week and loves to make classes a joy. And, of course, she, like any girl, loves to walk and go shopping. Let’s help Zendaya get ready for any daily routine.",
      tag: ["Dress up", "Make Up", "Celebrity", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211118,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFF Lovely Kawaii Outfits - playcutegames.com",
      name: ["BFF Lovely Kawaii Outfits", "bff-lovely-kawaii-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-lovely-kawaii-outfits/250x142.png",
      path: ["/game/bff-lovely-kawaii-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff-lovely-kawaii-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to BFF Lovely Kawaii Outfits game. Princesses Elsa, Anna, Moana, and Jasmine are more fascinated about Kawaii Outfits.",
      l_dis:
        `Welcome to BFF Lovely Kawaii Outfits game. Princesses Elsa, Anna, Moana, and Jasmine are more fascinated about Kawaii Outfits. They are very eager to browse the new collection of Kawaii outfits. Start helping with each princess for their outfits and matching costumes by browse their wardrobe. Play these cute girl games and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Kawaii",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211117,
      brand: "dressupwho",
     
      orientation: 0,
      title: "LOL Surprise Coachella - playcutegames.com",
      name: ["LOL Surprise Coachella", "lol-surprise-coachella"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lol-surprise-coachella/250x142.png",
      path: ["/game/lol-surprise-coachella", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/lol-surprise-coachella/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to LOL Surprise Coachella. Our LOL Surprise fashionistas are getting ready for their own Coachella festival in this brand-new dress-up game",
      l_dis:
        "Welcome to LOL Surprise Coachella. Our LOL Surprise fashionistas are getting ready for their own Coachella festival in this brand-new dress-up game for girls called LOL Surprise Coachella! It’s a fun warmup session for the real gig which will take place in October this year but that doesn’t mean they won’t pay great attention to the outfits they are going to wear. Join and have fun!",
      tag: ["Dress up", "Make Up", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211116,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Black Friday Collections - playcutegames.com",
      name: [
        "Princess Black Friday Collections",
        "princess-black-friday-collections",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-black-friday-collections/250x142.png",
      path: [
        "/game/princess-black-friday-collections",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess-black-friday-collections/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess Black Friday Collections. Princess Elsa, Anna, Jasmine, Snow White, Moana, and Ariel are ready for shopping! Is anything special, all the princesses are going together? Yes,",
      l_dis:
        `Welcome to Princess Black Friday Collections. Princess Elsa, Anna, Jasmine, Snow White, Moana, and Ariel are ready for shopping! Is anything special, all the princesses are going together? Yes, It’s Black Friday. They are all excited like us for this day. Join and help the princesses with your skill. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Festival",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211115,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Blondy in Pink - playcutegames.com",
      name: ["Blondy in Pink", "blondy-in-pink"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/blondy-in-pink/250x142.png",
      path: ["/game/blondy-in-pink", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1637507149/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Blondy in Pink. Meet everyone’s favorite – Blondy! She is a real fashionista and a very sweet girl. And, like all girls, Blondy loves pink! Let’s spend one day with this adorable blonde.",
      l_dis:
        "Welcome to Blondy in Pink. Meet everyone’s favorite – Blondy! She is a real fashionista and a very sweet girl. And, like all girls, Blondy loves pink! Let’s spend one day with this adorable blonde. First, let’s devote a few minutes to facial treatment. We need to apply cream, get rid of acne and dark circles under the eyes. Then we will choose bright stylish makeup for Blondy with all kinds of shades of pink. After the make-up is the turn of the great hairstyle and sparkly jewelry. Finally, choose from a variety of sparkling, colorful outfits for our girl. Don’t forget to complement your clothes with fashionable accessories. Now, take your pet with you and go for a walk with the Blondy.",
      tag: ["Barbie", "Dress up", "Make Up", "Festival", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211114,
      brand: "dressupwho",
     
      orientation: 0,
      title: "Princess Black Friday Chaos - playcutegames.com",
      name: ["Princess Black Friday Chaos", "princess-black-friday-chaos"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-black-friday-chaos/250x142.png",
      path: ["/game/princess-black-friday-chaos", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/princess-black-friday/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess Black Friday Chaos. It is the day after thanksgiving and the princesses need some retail therapy!",
      l_dis:
        "Welcome to Princess Black Friday Chaos. It is the day after thanksgiving and the princesses need some retail therapy! They need some new clothes for the new year! Help Elsa, Moana, and Sleeping Beauty grab all the sale items. This Black Friday is going to be insane so you need to have quick hands. Let us see who can grab the most items! Have fun and tell us your scores in the comments!Start with princess dress up, choose a pair of cute tops and bottom or perfect outfits. Pick a long hairstyle and sparkling accessories. Do the same for all the princesses with different costumes. Play and have fun!",
      tag: ["Princess", "Dress up", "Make Up", "Festival", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211113,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Cute Puppies Puzzle - playcutegames.com",
      name: [
        "Cute Puppies Puzzle",
        "cute-puppies-puzzle",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-puppies-puzzle/250x142.png",
      path: [
        "/game/cute-puppies-puzzle",
        "https://playcutegames.com",
      ],
      iframe: "https://dl-girls.com/content/folder_1652459191/",
      Walkthrough: "https://www.youtube.com/embed/bv5L7eMkFFQ",
      s_dis:
        "Welcome to Cute Puppies Puzzle. We can not always afford to buy expensive goods. Black Friday is a real holiday or a day of fulfillment of wishes,",
      l_dis:
        "Welcome to Cute Puppies Puzzle. It’s time for puzzles! Just look at these cute and funny puppies. They love to eat delicious food, fly into space and love ice cream. Test your wits. Drag and Drop the puzzle pieces in the right place to complete the levels. Collect all the pictures with these wonderful puppies and have fun!",
      tag: ["Puzzle", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211113,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Marinette Freaky Black Friday Sale - playcutegames.com",
      name: [
        "Marinette Freaky Black Friday Sale",
        "marinette-freaky-black-friday-sale",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/marinette-freaky-black-friday-sale/250x142.png",
      path: [
        "/game/marinette-freaky-black-friday-sale",
        "https://playcutegames.com",
      ],
      iframe: "https://dl-girls.com/content/folder_1606411483/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Marinette Freaky Black Friday Sale. We can not always afford to buy expensive goods. Black Friday is a real holiday or a day of fulfillment of wishes,",
      l_dis:
        "Welcome to Marinette Freaky Black Friday Sale. We can not always afford to buy expensive goods. Black Friday is a real holiday or a day of fulfillment of wishes, when clothes, equipment, and other types of goods can be purchased at a pleasantly low price. Let’s go shopping with Marinet in Paris. This fashion center is a paradise for girls. Choose bright outfits, shoes, accessories, and branded cosmetics! And gifts are waiting for you!",
      tag: ["Princess", "Dress up", "Make Up", "Festival", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211111,
      brand: "dressupwho",
     
      orientation: 0,
      title: "Harley and BFF PJ Party - playcutegames.com",
      name: ["Harley and BFF PJ Party", "harley-and-bff-pj-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/harley-and-bff-pj-party/250x142.png",
      path: ["/game/harley-and-bff-pj-party", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/harley-and-bff-pj-party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Harley and BFF PJ Party. Harley and her friends Mel and Cruella decided to have a pajama party. Hooligan girls also love casual entertainment.",
      l_dis:
        "Welcome to Harley and BFF PJ Party. Harley and her friends Mel and Cruella decided to have a pajama party. Hooligan girls also love casual entertainment. What could be better than getting together with your friends, doing each other’s makeup, fooling around, having a pillow fight, and watching your favorite movie together? Choose the right pajamas or dressing gown for each character. Have fun with the girl-hooligans at Harley’s Pajama Party",
      tag: ["Harley", "Dress up", "Make Up", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211110,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bff Weekend Style - playcutegames.com",
      name: ["Bff Weekend Style", "bff-weekend-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-weekend-style/250x142.png",
      path: ["/game/bff-weekend-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff-weekend-style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bff Weekend Style. Hairdorable Girls Dee dee, Noah, and Willow decided to visit some new places on weekend. They planned to try a new style of outfits and costumes to attract everyone.",
      l_dis:
        `Welcome to the Bff Weekend Style. Hairdorable Girls Dee dee, Noah, and Willow decided to visit some new places on weekend. They planned to try a new style of outfits and costumes to attract everyone. Help the girls to pick an outfit from the wardrobe and make them more pretty as you like. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Hairdorables doll",
        "Bff",
        "Dress up",
        "Make Up",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211109,
      brand: "Prinxy",
     
      orientation: 0,
      title: "BFFs Black Friday Shopping - playcutegames.com",
      name: ["BFFs Black Friday Shopping", "bffs-black-friday-shopping"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-black-friday-shopping/250x142.png",
      path: ["/game/bffs-black-friday-shopping", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/df74e08c-fa18-59e8-8992-0dfe6afa832a/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this online game for girls called BFFs Black Friday Shopping, you help five friends get their looks ready to hit the mall and shop till they drop.",
      l_dis:
        "In this online game for girls called BFFs Black Friday Shopping, you help five friends get their looks ready to hit the mall and shop till they drop. Brian and the girls are heading to the mall for a fun shopping spree on Black Friday, and you girls should join the shopaholics. Get started with this online dress-up game for girls and see what stunning looks you can find for each of them. Don’t forget to accessorize their final looks with designer purses and sparkly jewelry! Have fun!",
      tag: ["Bff", "Dress up", "Shopping", "Festival", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211107,
      brand: "dressupwho",
     
      orientation: 0,
      title: "Burning Man Stay at Home - playcutegames.com",
      name: ["Burning Man Stay at Home", "burning-man-stay-at-home"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/burning-man-stay-at-home/250x142.png",
      path: ["/game/burning-man-stay-at-home", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/burning-man-stay-home/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Burning Man Stay at Home. The famous Burning Man festival is approaching, but it is canceled due to quarantine. What should young fashionistas do who are missing entertainment and want to show everyone",
      l_dis:
        "Welcome to the Burning Man Stay at Home. The famous Burning Man festival is approaching, but it is canceled due to quarantine. What should young fashionistas do who are missing entertainment and want to show everyone their costumes? Princesses Elsa, Jasmine, and Merida take matters into their own hands. They had a great idea – to put on a fashion show online. They gathered all their colorful outfits and put on a real festival without leaving their homes! Using famous social media, the girls show off their costumes for Burning Man.",
      tag: ["Princess", "Dress up", "Make Up", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211106,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Festival Dia de Muertos - playcutegames.com",
      name: ["Festival Dia de Muertos", "festival-dia-de-muertos"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/festival-dia-de-muertos/250x142.png",
      path: ["/game/festival-dia-de-muertos", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1636743257/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Festival Dia de Muertos. The Dia de Muertos is a joyful celebration of life: love, memory, and reverence are the main characters of the event.",
      l_dis:
        "Welcome to the Festival Dia de Muertos. The Dia de Muertos is a joyful celebration of life: love, memory, and reverence are the main characters of the event. Dia de Los Muertos is the acceptance of death without mourning and the real glory of life. The Day of the Dead is celebrated in Mexico and in the United States, in places where large Mexican communities live. The holiday lasts for three days, starting on October 31. On this day, women clean houses and cook food. Men build altars out of clay. This year, princesses the Little Mermaid, Lisa, and Jacqueline are also participating in the festival. Help the girls make impressive makeup for the Day of the Dead, create a luxurious hairstyle with braids, and, of course, choose decent outfits for such a holiday. ",
      tag: ["Princess", "Dress up", "Make Up", "Festival", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211106,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Raya Multiverse Fashion - playcutegames.com",
      name: ["Raya Multiverse Fashion", "raya-multiverse-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/raya-multiverse-fashion/250x142.png",
      path: ["/game/raya-multiverse-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/raya-multiverse-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Raya Multiverse Fashion. Princess Raya had a dream about a multiverse. There she is in a different trendy style.",
      l_dis:
        `Welcome to the Raya Multiverse Fashion. Princess Raya had a dream about a multiverse. There she is in a different trendy style. She loves to be the same in real life also. So she plans to try multiverse fashion with her own style. Join Raya and help her to pick a cute trendy outfit. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Fashion",
        "Raya",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211106,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "Kim K Busy Day - playcutegames.com",
      name: ["Kim K Busy Day", "kim-k-busy-day"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/kim-k-busy-day/250x142.png",
      path: ["/game/kim-k-busy-day", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/kim-k-busy-day/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Kim K Busy Day. Ever wanted to know what it’s like to be Kim Kardashian’s personal fashion adviser?",
      l_dis:
        "Welcome to the Kim K Busy Day. Ever wanted to know what it’s like to be Kim Kardashian’s personal fashion adviser? Well, you get to prove your amazing skills today as this famous socialite has four major events on her agenda and she needs one jaw-dropping look for each of them. Feel free to join her in getting started this brand-new celebrity dress-up game for girls and see if you can keep up with all the fashion challenges she has prepared for her assistant today.",
      tag: ["Celebrity", "Dress up", "Make Up", "Supermodel", "Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211106,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "Strike Force Heroine RPG - playcutegames.com",
      name: ["Strike Force Heroine RPG", "strike-force-heroine-rpg"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/strike-force-heroine-rpg/250x142.png",
      path: ["/game/strike-force-heroine-rpg", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/strike-force-heroine-rpg/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Strike Force Heroine RPG. Do you love online games? Then this game is especially for you! Enjoy special magical Genshin-themed costumes.",
      l_dis:
        "Welcome to the Strike Force Heroine RPG. Do you love online games? Then this game is especially for you! Enjoy special magical Genshin-themed costumes. Create your own beautiful warrior or powerful sorceress. Magnificent airy dresses and original costumes will not leave you indifferent. Lots of hairstyles and accessories will help create an absolutely unique heroine. Don’t forget about cute ears and wings! Create a whole fighting squad of three girls. Have fun",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Kawaii",
        "Harley Quinn",
        "Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211105,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "Kawaii Princess At Comic Con - playcutegames.com",
      name: ["Kawaii Princess At Comic Con", "kawaii-princess-at-comic-con"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/kawaii-princess-at-comic-con/250x142.png",
      path: ["/game/kawaii-princess-at-comic-con", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/kawaii-princess-at-comic-con/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Kawaii Princess At Comic Con. Our favorite princesses – Harley, Eliza, and Jasmina – are going to the biggest cosplay event this fall",
      l_dis:
        "Welcome to Kawaii Princess At Comic Con. Our favorite princesses – Harley, Eliza, and Jasmina – are going to the biggest cosplay event this fall – Comic Con. ComicCon is a new event that will showcase all the best from the world of films, TV series, computer, and board games, entertainment literature, and, of course, popular comics, anime, and manga. This is a great holiday for everyone who is not alien to modern pop culture in any of its manifestations. Choose incredible kawaii hairstyles and princess makeup. Don’t forget about cute pastel-colored costumes and tons of accessories! Have fun!",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Kawaii",
        "Harley Quinn",
        "Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Turned Into Mermaid - playcutegames.com",
      name: ["Princess Turned Into Mermaid", "princess-turned-into-mermaid"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-turned-into-mermaid/250x142.png",
      path: ["/game/princess-turned-into-mermaid", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess-turned-into-mermaid/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess Turned Into Mermaid. Princess Ariel throws a party at her place. She invited her friends Princess Elsa, Moana, and Jasmine.",
      l_dis:
        `Welcome to Princess Turned Into Mermaid. Princess Ariel throws a party at her place. She invited her friends Princess Elsa, Moana, and Jasmine. They are all happy to visit Ariel’s place and excited to be a mermaid. But they need perfect costumes for the party. Browse Ariel’s wardrobe to help other princesses with mermaid outfits. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess", "Dress up", "Mermaid","Fashion", "cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211104,
      brand: "Prinxy",
     
      orientation: 0,
      title: "TikTok Fall Fashion - playcutegames.com",
      name: ["TikTok Fall Fashion", "tiktok-fall-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-fall-fashion/250x142.png",
      path: ["/game/tiktok-fall-fashion", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/019008a0-5961-528c-9a74-804e7ec132e5/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this online game for girls called TikTok Fall Fashion, you help three beautiful best friends get their daily looks updated to the latest fashion trends.",
      l_dis:
        "In this online game for girls called TikTok Fall Fashion, you help three beautiful best friends get their daily looks updated to the latest fashion trends. Try bold colors for their makeup looks, and then mix and match sparkly sequins with cozy knits into jaw-dropping outfits. Don’t forget to accessorize their final looks with designer purses and sparkly jewelry! Have fun!",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Fall",
        "Autumn",
        "Prinxy Games"
      ]
  ,
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211103,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Princess Sweet Kawaii Fashion - playcutegames.com",
      name: ["Princess Sweet Kawaii Fashion", "princess-sweet-kawaii-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-sweet-kawaii-fashion/250x142.png",
      path: ["/game/princess-sweet-kawaii-fashion", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1636127432/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Sweet Kawaii Fashion. Princess Eliza travels to Japan to study teenage kawaii style.",
      l_dis:
        "Welcome to the Princess Sweet Kawaii Fashion. Princess Eliza travels to Japan to study teenage kawaii style. All kinds of bows, lace, frills are used as decorations, which mothers loved to supply their princesses within childhood. The kawaii style also has an unusual hair color. And makeup is limited only by your imagination. There is no place for boring shades, only gentle tones and sparkles! Create your own unique kawaii look with Princess Eliza! Have fun!",
      tag: ["Princess", "Dress up", "Make Up", "Fashion", "Kawaii", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Magical Fairy Fashion Look - playcutegames.com",
      name: ["Magical Fairy Fashion Look", "magical-fairy-fashion-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/magical-fairy-fashion-look/250x142.png",
      path: ["/game/magical-fairy-fashion-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/magical-fairy-fashion-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Magical Fairy Fashion Look. Skyler and Sunny love things about fairies. Fairies always look pretty, wings and magical powers.",
      l_dis:
        `Welcome to the Magical Fairy Fashion Look. Skyler and Sunny love things about fairies. Fairies always look pretty, wings and magical powers. They love to look like them. But they have dilemma to choose perfect costumes. Help them to pick a cute outfit, pretty accessories, and wings. Play and have fun with our cute games.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "dressupwho",
     
      orientation: 0,
      title: "Harajuku Princess - playcutegames.com",
      name: ["Harajuku Princess", "harajuku-princess"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/harajuku-princess/250x142.png",
      path: ["/game/harajuku-princess", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/harajuku-princess/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Harajuku Princess. Are you ready for another cute-themed dress-up game? Today you are invited to help Jasmine, Tiana, and Elsa discover the colorful",
      l_dis:
        "Welcome to Harajuku Princess. Are you ready for another cute-themed dress-up game? Today you are invited to help Jasmine, Tiana, and Elsa discover the colorful Harajuku fashion style. Harajuku fashion is a mix of all the well-known Japanese sub-styles. There are no rules to follow while creating a Harajuku outfit… so feel free to cherry pick whatever you fancy the most. And guess what? Your selected items do not have to match but the more utrageous, the better. Have a blast! Play and have fun!",
      tag: ["Princess", "Dress up", "Make Up", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Design With Me Fall Sweater - playcutegames.com",
      name: ["Design With Me Fall Sweater", "design-with-me-fall-sweater"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/design-with-me-fall-sweater/250x142.png",
      path: ["/game/design-with-me-fall-sweater", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/design-with-me-fall-sweater/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Design With Me Fall Sweater. Princess Elsa, Anna, and Ariel are the best friends who love more to do creative.",
      l_dis:
        `Welcome to Design With Me Fall Sweater. Princess Elsa, Anna, and Ariel are the best friends who love more to do creative. For this Autumn they planning to create and design their own fall sweater. Help the girls to pick and design a perfect sweater. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Decoration",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "VSCO Girl Aesthetic - playcutegames.com",
      name: ["VSCO Girl Aesthetic", "vsco-girl-aesthetic"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/vsco-girl-aesthetic/250x142.png",
      path: ["/game/vsco-girl-aesthetic", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/vsco-girl-aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the VSCO Girl Aesthetic. Rainbow High school girls Sunny, Skyler, Ruby, and Violet are the trendy fashion girls.",
      l_dis:
        `Welcome to the VSCO Girl Aesthetic. Rainbow High school girls Sunny, Skyler, Ruby, and Violet are the trendy fashion girls. They planned to try VSCO fashion which is trending all over social media. Join and browse their wardrobe to pick some suitable outfits for the girls. Play and Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Aesthetic",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Matryoshka Maker - playcutegames.com",
      name: ["Matryoshka Maker", "matryoshka-maker"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/matryoshka-maker/250x142.png",
      path: ["/game/matryoshka-maker", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1635354705/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Matryoshka Maker. Matryoshka is popular all over the world. It has a fascinating history. These toys are produced in huge quantities",
      l_dis:
        "Welcome to Matryoshka Maker. Matryoshka is popular all over the world. It has a fascinating history. These toys are produced in huge quantities, and in some cities, there are entire museums dedicated to this unusual doll.  Let’s create our own unique nesting doll! Choose colors and patterns for different parts of the matryoshka – body, apron, stand, shirt, and scarf.",
      tag: ["Dress up", "Make Up", "Decoration", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "dressupwho",
     
      orientation: 0,
      title: "Black and White Halloween - playcutegames.com",
      name: ["Black and White Halloween", "black-and-white-halloween"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/black-and-white-halloween/250x142.png",
      path: ["/game/black-and-white-halloween", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/black-and-white-halloween/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Black and White Halloween. Black and white colors are in trend today. And still very close to everyone’s favorite holiday – Halloween.",
      l_dis:
        "Welcome to Black and White Halloween. Black and white colors are in trend today. And still very close to everyone’s favorite holiday – Halloween. Let’s mix these two fashion trends! Meet your favorite princesses – Mulan, Elsa, and Ariel in black and white witch outfits. Black and white colors have always been a model of style, their simplicity and elegance can hardly be overestimated. Create a unique look of stylish witches this Halloween!",
      tag: ["Princess", "Dress up", "Make Up", "Halloween", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Secret College Crush - playcutegames.com",
      name: ["My Secret College Crush", "my-secret-college-crush"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-secret-college-crush/250x142.png",
      path: ["/game/my-secret-college-crush", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-secret-college-crush/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Secret College Crush. Ellie started College with Ben. She has a crush on Ben. But Ben wouldn’t even look at Ellie.",
      l_dis:
        `Welcome to the My Secret College Crush. Ellie started College with Ben. She has a crush on Ben. But Ben wouldn’t even look at Ellie.  She decided to start with the simplest: a little change of style is always a good idea! Can you help her with new looks? And in case they are lucky enough to be asked out for a date, you are going to help her with choosing outing outfits, too!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "College",
        "Ellie",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Autumn Bright Outfits - playcutegames.com",
      name: ["My Autumn Bright Outfits", "my-autumn-bright-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-autumn-bright-outfits/250x142.png",
      path: ["/game/my-autumn-bright-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-autumn-bright-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Autumn Bright Outfits game. Dee dee, Willow, and Noah are the best friends who admire more on fashion trends.",
      l_dis:
        `Welcome to the My Autumn Bright Outfits game. Dee dee, Willow, and Noah are the best friends who admire more on fashion trends. They heard about autumn is near. So they planned to welcome the most beautiful season with their latest fashion trends. Join and help them to pick a cute outfits. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "hairdorables doll",
        "Autumn",
        "Season",
        "Willow",
        "Dee dee",
        "Noah",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Raya Back To Kumandra - playcutegames.com",
      name: ["Raya Back To Kumandra", "raya-back-to-kumandra"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/raya-back-to-kumandra/250x142.png",
      path: ["/game/raya-back-to-kumandra", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/raya-back-to-kumandra/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Raya Back To Kumandra game. Raya and Namaari are close friends who moved to cities for their high school.",
      l_dis:
        `Welcome to Raya Back To Kumandra game. Raya and Namaari are close friends who moved to cities for their high school. Now they got an invite to their native Kumandra. They are more excited to back to Kumandra and planned to be there in a more traditional way. Come and join them. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "Princess", "Namari", "Raya"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Cute Witch Princess - playcutegames.com",
      name: ["Cute Witch Princess", "cute-witch-princess"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-witch-princess/250x142.png",
      path: ["/game/cute-witch-princess", "https://playcutegames.com"],
      iframe: "https://www.dl-girls.com/content/folder_1633580503/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Cute Witch Princess. Everyone’s favorite holiday is approaching – Halloween. What will the funny princesses come up with this time? The girls decided to change their traditional outfits",
      l_dis:
        "Welcome to Cute Witch Princess. Everyone’s favorite holiday is approaching – Halloween. What will the funny princesses come up with this time? The girls decided to change their traditional outfits, turning them into a witch dress! Do you want to know what your favorite princess will look like in the form of a witch? Then rather play this wonderful game called Cute Witch Princess.",
      tag: ["Dress up", "Make Up", "Princess", "Witch", "Halloween", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Ellie And Ben Fall Date - playcutegames.com",
      name: ["Ellie And Ben Fall Date", "ellie-and-ben-fall-date"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-ben-fall-date/250x142.png",
      path: ["/game/ellie-and-ben-fall-date", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/ellie-and-ben-fall-date/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Ellie And Ben Fall Date. Ben asked for a date for Ellie. She is so excited and she wants to be more attractive.",
      l_dis:
        `Welcome to the Ellie And Ben Fall Date. Ben asked for a date for Ellie. She is so excited and she wants to be more attractive. She needs some expert advice. Browse her wardrobe and pick a cute outfit for her. Join and help her to choose perfect outfits.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Barbie",
        "Ellie",
        "Date",
        "fall",
        "autumn",
        "couple",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "TikTok VSCO Girls - playcutegames.com",
      name: ["TikTok VSCO Girls", "tiktok-vsco-girls"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-vsco-girls/250x142.png",
      path: ["/game/tiktok-vsco-girls", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/d58eb532-88df-5444-aa90-6ef9a6e18e5c/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to TikTok VSCO Girls. Play this fun dress-up game for girls called TikTok VSCO Girls, and help the two BFFs discover the VSCO aesthetics as you dress them up.",
      l_dis:
        "Welcome to TikTok VSCO Girls. Play this fun dress-up game for girls called TikTok VSCO Girls, and help the two BFFs discover the VSCO aesthetics as you dress them up. Then do their makeup, change their hairstyles, and accessorize their final looks with hydro flasks and girly-girl photo cameras. Have a great time playing our online TikTok Game for girls!",
      tag: ["Dress up", "Make Up", "Tiktok Trends", "VSCO Girls", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Baby Boss Back In Business - playcutegames.com",
      name: ["Baby Boss Back In Business", "baby-boss-back-in-business"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/baby-boss-back-in-business/250x142.png",
      path: ["/game/baby-boss-back-in-business", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/baby-boss-back-in-business/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Baby Boss Back In Business game. After a small vacation Baby Boss is back into his business.",
      l_dis:
        `Welcome to the Baby Boss Back In Business game. After a small vacation Baby Boss is back into his business. It’s gonna be a fresh start after a small gap. He needs to be perfect in look. Pick a fabulous suit for him. Join and help the boss. Have fun! Enjoy playing these new cute games for girls.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Decoration", "Baby Boss", "Kids"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Iconic Celebrity Look - playcutegames.com",
      name: ["Iconic Celebrity Look", "iconic-celebrity-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/iconic-celebrity-look/250x142.png",
      path: ["/game/iconic-celebrity-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/iconic-celebrity-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Iconic celebrity Look. These princesses sure have their favorite celebs and they just want to dress up like them. In this game, you will discover the most iconic celebrity outfits!",
      l_dis:
        `Welcome to the Iconic celebrity Look. These princesses sure have their favorite celebs and they just want to dress up like them. In this game, you will discover the most iconic celebrity outfits! You will have the chance to dress up these princesses as your favorite celebs. Play them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Rainbow girls",
        "Celebrity",
        "Violet",
        "Skyler",
        "Sunny",
        "Ruby",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Trendy Plaid Outfits - playcutegames.com",
      name: ["My Trendy Plaid Outfits", "my-trendy-plaid-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-trendy-plaid-outfits/250x142.png",
      path: ["/game/my-trendy-plaid-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-trendy-plaid-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Trendy Plaid Outfits. Plaid is back on the top list when it comes to fashion trends! And who else should know more about Plaid outfit",
      l_dis:
        `Welcome to the My Trendy Plaid Outfits. Plaid is back on the top list when it comes to fashion trends! And who else should know more about Plaid outfit if not Princess Ariel, Moana and Jasmine? This game is all about wearing the Plaid outfit the right way! There are many great ways to wear the timeless Plaid print, but you need to know how to match it with the rest of the outfit to avoid a fashion disaster. Are you ready to explore different outfits with us?
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Princess",
        "Jasmine",
        "Moana",
        "Ariel",
        "Plaid outfits",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Insta Girls Fruity Fashion - playcutegames.com",
      name: ["Insta Girls Fruity Fashion", "insta-girls-fruity-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/insta-girls-fruity-fashion/250x142.png",
      path: ["/game/insta-girls-fruity-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/insta-girls-fruity-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Insta Girls Fruity Fashion. Dee Dee arranges a party at her home. Yes! It’s a fruity fashion Party.",
      l_dis:
        `Welcome to the Insta Girls Fruity Fashion. Dee Dee arranges a party at her home. Yes! It’s a fruity fashion Party. She invites her friends Willow and Noah to the party. Help them to browse their wardrobe to pick the best outfit. Come and Join the girls and have fun! Enjoy playing these new cute games for girls. Have a great time with our cute games. If you are looking for the best makeup games, then look no further. Playcutegames.com has a cute collection of games for makeup. Create your unique styles of outfits and costumes with an excellent collection of dresses and hairstyles.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "Instagram", "hairdorables doll"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Zentangle Coloring Book - playcutegames.com",
      name: ["Zentangle Coloring Book", "zentangle-coloring-book"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/zentangle-coloring-book/250x142.png",
      path: ["/game/zentangle-coloring-book", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1632759992/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Zentangle Coloring Book. All children love to draw and color. It develops the brain and calms the nerves. Meet the original coloring in the Zentagle",
      l_dis:
        "Welcome to Zentangle Coloring Book. All children love to draw and color. It develops the brain and calms the nerves. Meet the original coloring in the Zentagle technique! This style was invented in 2006. Drawings in this style usually consist of some kind of repeating pattern. Many patterns can be combined in one drawing, and the technique itself is quite free and intuitive. Play and color on Playcutegames.com. Take a screenshot and share the result with your friends!",
      tag: ["Kids", "Dl-Girls", "Coloring Games", "Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Highschool Mean Girls 2 - playcutegames.com",
      name: ["Highschool Mean Girls 2", "highschool-mean-girls-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/highschool-mean-girls-2/250x142.png",
      path: ["/game/highschool-mean-girls-2", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/29be8b59-6722-5fb5-9d9c-eb1bad17f4cd/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Highschool Mean Girls 2. The mean girls are back to school, this time in a brand-new formula. You can join them in our online dress-up game for girls called Highschool Mean Girls 2",
      l_dis:
        "Welcome to Highschool Mean Girls 2. The mean girls are back to school, this time in a brand-new formula. You can join them in our online dress-up game for girls called Highschool Mean Girls 2 and help them style up their looks for the first day of school. Get it started, and have a great time creating jaw-dropping outfits for these 2 groups of BFFs competing in the high school fashion challenge. Only one group will be the next fashion queen, but who will be our winners? The supermodels or the singers?",
      tag: [
        "Dress up",
        "Celebrity",
        "Fashion",
        "Highschool",
        "Prinxy Games",
        "Selena Gomez",
        "Kendall Jenner",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Popular 80's Fashion Trends - playcutegames.com",
      name: ["Popular 80's Fashion Trends", "popular-80s-fashion-trends"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/popular-80s-fashion-trends/250x142.png",
      path: ["/game/popular-80s-fashion-trends", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/popular-80s-fashion-trends/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Popular 80’s Fashion Trends. Rainbow high school girls are in love with the 80’s style. And they are going to dress up accordingly.",
      l_dis:
        `Welcome to the Popular 80’s Fashion Trends. Rainbow high school girls are in love with the 80’s style. And they are going to dress up accordingly. Who else loves it? Have you ever created an 80’s style outfit? Here is your chance to create four different ones, one for each girl. The wardrobe is stacked with tones of clothes waiting for you, so have fun dressing them up!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Anna",
        "Elsa",
        "Snow White",
        "Jasmine",
        "Moana",
        "Ariel",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Incredible Princess Eye Art 2 - playcutegames.com",
      name: ["Incredible Princess Eye Art 2", "incredible-princess-eye-art-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/incredible-princess-eye-art-2/250x142.png",
      path: ["/game/incredible-princess-eye-art-2", "https://playcutegames.com"],
      iframe: "https://www.dl-girls.com/content/folder_1631636380/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to an Incredible Princess Eye Art 2 Game. This is a tutorial from a fabulous makeup artist. Try your hand at makeup on the beautiful eyes of cute princesses.",
      l_dis:
        "Welcome to an Incredible Princess Eye Art 2 Game. This is a tutorial from a fabulous makeup artist. Try your hand at makeup on the beautiful eyes of cute princesses. Use extraordinary artistic solutions to create a striking and non-standard look. Use new trends: angel makeup, rhinestones, animal prints, neon effects. Grab your makeup brush and get started! Try out new art options for the eyes.",
      tag: ["Dress up", "Make Up", "Decoration", "Faceart", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Love Pinky Outfits - playcutegames.com",
      name: ["Princess Love Pinky Outfits", "princess-love-pinky-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-love-pinky-outfits/250x142.png",
      path: ["/game/princess-love-pinky-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess-love-pinky-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess Love Pinky Outfits. Princess Anna, Elsa, Moana, Jasmine, Snow White, and Ariel met after a long time. They are Planned to throw a weekend party with their beloved theme called Pinky Outfits.",
      l_dis:
        `Welcome to Princess Love Pinky Outfits. Princess Anna, Elsa, Moana, Jasmine, Snow White, and Ariel met after a long time. They are Planned to throw a weekend party with their beloved theme called Pinky Outfits. Browse the wardrobe for each princess and pick cute pinky outfits. Join with girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Anna",
        "Elsa",
        "Snow White",
        "Jasmine",
        "Moana",
        "Ariel",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Blondy Extra - playcutegames.com",
      name: ["Blondy Extra", "blondy-extra"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/blondy-extra/250x142.png",
      path: ["/game/blondy-extra", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1631034618/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Blondy Extra game. Blondie Extra’s style is bold fashion and bright colors, as well as a loud statement! Each girl has her own unique playful",
      l_dis:
        "Welcome to Blondy Extra game. Blondie Extra’s style is bold fashion and bright colors, as well as a loud statement! Each girl has her own unique playful and cool style. And their pets – all different and all adorable – also have a subtle personality! A fun and playful look showcase their confident style – take a look at these pink denim shorts with teddy bear prints paired with a matching jacket with super fluffy sleeves. And here’s a ruffle top in lavender with stars paired with frayed-edged shorts and a neon faux fur coat. Blondie’s accessories – cell phone wallet, sunglasses, jewelry, green socks, and brown shoes – add personality!",
      tag: ["Dress up", "Make Up", "Fashion", "Blondie", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Stylish Ball Gown - playcutegames.com",
      name: ["My Stylish Ball Gown", "my-stylish-ball-gown"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-stylish-ball-gown/250x142.png",
      path: ["/game/my-stylish-ball-gown", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-stylish-ball-gown/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Stylish Ball Gown game. Adorable girls Dee dee, Willow, and Noah are BFFs planned for a get-together party. Yesss! it’s a stylish ball gown party.",
      l_dis:
        `Welcome to the My Stylish Ball Gown game. Adorable girls Dee dee, Willow, and Noah are BFFs planned for a get-together party. Yesss! it’s a stylish ball gown party. Help the girls to pick a perfect gown and costumes for the party. Join with a girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "hairdorables doll",
        "Willow",
        "Dee dee",
        "Noah",
        "Ball Gown",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Summer Festivals Fashion - playcutegames.com",
      name: ["Summer Festivals Fashion", "summer-festivals-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/summer-festivals-fashion/250x142.png",
      path: ["/game/summer-festivals-fashion", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/b09741c1-e218-5170-87b1-329e10286d49/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Summer Festivals Fashion. This summer, festivals are back in trend, and you are going to have a blast attending 3 famous parties in our dress-up game for girls called",
      l_dis:
        "Welcome to Summer Festivals Fashion. This summer, festivals are back in trend, and you are going to have a blast attending 3 famous parties in our dress-up game for girls called Summer Festivals Fashion. Get it started, and have a great time creating jaw-dropping #ootd for these 6 BFFs getting ready to dance, dance, dance while looking in style. Have a blast!",
      tag: [
        "Dress up",
        "Prinxy Games",
        "Princess",
        "Festival",
        "Summer",
        "Celebrity",
        "Ariana",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Cyberpunk City Fashion - playcutegames.com",
      name: ["Cyberpunk City Fashion", "cyberpunk-city-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cyberpunk-city-fashion/250x142.png",
      path: ["/game/cyberpunk-city-fashion", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/f0229414-aa9d-5652-97d3-e3442fe4e606/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Cyberpunk City Fashion game. Belle, Ariel, Moana, and Elsa are willing to give up their stunning princess dress and have the full cyberpunk experience in this Prinxy",
      l_dis:
        "Welcome to the Cyberpunk City Fashion game. Belle, Ariel, Moana, and Elsa are willing to give up their stunning princess dress and have the full cyberpunk experience in this Prinxy.app game for girls. Surrounded by futuristic neon lights, the royal BFFs wait for you to join the fun and help them decide on the winning looks. Are you ready to join them in the Cyberpunk City Fashion game?",
      tag: [
        "Dress up",
        "Prinxy Games",
        "Fashion",
        "Princess",
        "Elsa",
        "Belle",
        "Moana",
        "Ariel",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Insta Makeup: Bride - playcutegames.com",
      name: ["Insta Makeup: Bride", "insta-makeup-bride"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/insta-makeup-bride/250x142.png",
      path: ["/game/insta-makeup-bride", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/577755f1-d6ca-54d1-bf32-2aa8091a99e6/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Insta Makeup: Bride. Sophia’s wedding day is almost here, but luckily she still has some time left to experiment with her bridal makeup look.",
      l_dis:
        "Welcome to the Insta Makeup: Bride. Sophia’s wedding day is almost here, but luckily she still has some time left to experiment with her bridal makeup look. In the Insta Makeup: Bride, you play the role of a super talented makeup artist in charge of our gorgeous girl’s wedding makeup look. Get it started, select your favorite shades, and create three unique makeup looks for every kind of bride. Natural, bold, or glam? Which bridal makeup suits her best?",
      tag: ["Dress up", "Instagram", "Make Up", "Wedding", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Fabulous Vintage Look - playcutegames.com",
      name: ["My Fabulous Vintage Look", "my-fabulous-vintage-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-fabulous-vintage-look/250x142.png",
      path: ["/game/my-fabulous-vintage-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-fabulous-vintage-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to My Fabulous Vintage Look. Princess Anna, Elsa, and Moana are planned for the weekend Fashion. They decided to try vintage fashion outfits.",
      l_dis:
        `Welcome to My Fabulous Vintage Look. Princess Anna, Elsa, and Moana are planned for the weekend Fashion. They decided to try vintage fashion outfits. Play as a fashion designer and help them to pick a unique costume for each one. Enjoy playing this new game and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "vintage", "Fashion", "Princess"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Monster Popsy Dolls - playcutegames.com",
      name: ["Monster Popsy Dolls", "monster-popsy-dolls"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-popsy-dolls/250x142.png",
      path: ["/game/monster-popsy-dolls", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1630514497/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Monster Popsy Dolls game. Meet incredible monster-popsy. These toys are both beautiful and terrible! Choose from a variety of hairstyles and outfits",
      l_dis:
        "Welcome to Monster Popsy Dolls game. Meet incredible monster-popsy. These toys are both beautiful and terrible! Choose from a variety of hairstyles and outfits to create your own unique doll. Use your makeup artist skills to choose the right makeup for your popsy. Try on dresses, tops, leather jackets, boots, and fun jewelry. Have a lot of fun playing Monster Popsy Dolls!",
      tag: ["Dress up", "Make Up", "Dl-Girls", "monster high"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "TikTok Trends: Color Block - playcutegames.com",
      name: ["TikTok Trends: Color Block", "tiktok-trends-color-block"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-trends-color-block/250x142.png",
      path: ["/game/tiktok-trends-color-block", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/213692ab-2021-5312-8b8d-9e58aeb690dc/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to TikTok Trends: Color Block game. In this online dress-up game for girls called TikTok Trends: Color Block, you help our beautiful fashionista create bold outfits",
      l_dis:
        "Welcome to TikTok Trends: Color Block game. In this online dress-up game for girls called TikTok Trends: Color Block, you help our beautiful fashionista create bold outfits for the seven days of color block fashion challenge. She has a busy agenda this week, and sometimes you’ll find it challenging to follow the rules of this fashion challenge. Are you ready to get it started?",
      tag: ["Decoration", "Prinxy Games", "Trends", "Tiktok"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Summer Floral Prints - playcutegames.com",
      name: ["Summer Floral Prints", "summer-floral-prints"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/summer-floral-prints/250x142.png",
      path: ["/game/summer-floral-prints", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/28abdc6b-62bf-5912-b8e5-6392e6f3f2c2/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Welcome to Summer Floral Prints game. This summer, floral prints are back in trends, and you are going to have a blast discovering this feminine and playful",
      l_dis:
        "Welcome to Summer Floral Prints game. This summer, floral prints are back in trends, and you are going to have a blast discovering this feminine and playful fashion trend in our dress-up game for girls called Summer Floral Prints. Get it started, and have fun creating jaw-dropping #ootd for these 5 BFFs getting ready to conquer the crowdest boulevard looking in style. Have a blast!",
      tag: ["Dress up", "Princess", "Make Up", "Summer", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "babygames",
     
      orientation: 0,
      title: "Princess First Aid In Mermaid Kingdom - playcutegames.com",
      name: [
        "Princess First Aid In Mermaid Kingdom",
        "princess-first-aid-in-mermaid-kingdom",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-first-aid-in-mermaid-kingdom/250x142.png",
      path: [
        "/game/princess-first-aid-in-mermaid-kingdom",
        "https://playcutegames.com",
      ],
      iframe:
        "https://www.babygames.com/games/Princess-First-Aid-In-Mermaid-Kingdom/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess First Aid In Mermaid Kingdom. Sunny days are good days to go out to the sea. Elsa and Belle think so too. They went out to sea in good weather to enjoy the holiday.",
      l_dis:
        "Welcome to Princess First Aid In Mermaid Kingdom. Sunny days are good days to go out to the sea. Elsa and Belle think so too. They went out to sea in good weather to enjoy the holiday. Suddenly there was a storm. Elsa and Belle fell into the bottom of the sea, in a very dangerous situation. Please go call Ariel to save her two friends. Can Elsa and Belle be out of danger? Have fun in this game!",
      tag: ["Dress up", "Princess", "Mermaid", "Doctor","Mermaid", "babygames"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Cute Unicorn Fashion Dress Up - playcutegames.com",
      name: [
        "My Cute Unicorn Fashion Dress Up",
        "my-cute-unicorn-fashion-dress-up",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-cute-unicorn-fashion-dress-up/250x142.png",
      path: [
        "/game/my-cute-unicorn-fashion-dress-up",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/my-cute-unicorn-fashion-dress-up/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Cute Unicorn Fashion Dress Up. Skyler, Sunny, Ruby, and Violet are the Rainbow high school girls who have planned for a weekend party.",
      l_dis:
        `Welcome to the My Cute Unicorn Fashion Dress Up. Skyler, Sunny, Ruby, and Violet are the Rainbow high school girls who have planned for a weekend party. They decided to throw a party in Unicorn fashion. Join as a design expert and help the girls to get ready. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Rainbow High",
        "Unicorn",
        "Fashion",
        "babygames",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Ibiza Pool Party - playcutegames.com",
      name: ["Ibiza Pool Party", "ibiza-pool-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ibiza-pool-party/250x142.png",
      path: ["/game/ibiza-pool-party", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/f176a50f-c881-5939-9bcd-cecdb4eb8eaf/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Ibiza Pool Party. In this online makeover game, Elsa and Ariel have prepared some girly-girl summer activities that you are going to love.",
      l_dis:
        "Welcome to Ibiza Pool Party. In this online makeover game, Elsa and Ariel have prepared some girly-girl summer activities that you are going to love. Are you ladies ready to embark on a fun trip to Ibiza? Then simply get started with the Ibiza Pool Party, and have a blast together with your favorite Disney princesses while you help them prep their looks for the most-awaited pool party of the year.",
      tag: ["Dress up", "Princess", "Make Up", "Summer", "Prinxy Games", "Party"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Rainbow Insta Girls - playcutegames.com",
      name: ["Rainbow Insta Girls", "rainbow-insta-girls"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-insta-girls/250x142.png",
      path: ["/game/rainbow-insta-girls", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1628875523/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Rainbow Insta Girls. Get to know the students of the Rainbow School better. These girls love trendy clothes and bright hairstyles.",
      l_dis:
        "Welcome to Rainbow Insta Girls. Get to know the students of the Rainbow School better. These girls love trendy clothes and bright hairstyles. This helps them become Instagram stars. The friends even managed to draw the guys into this fashion adventure. Join the fun and try on outfits with the girls. An incredible rainbow whirlpool and a colorful extravaganza await you!",
      tag: [
        "Dress up",
        "Princess",
        "Make Up",
        "Instagram",
        "Rainbow",
        "Dl-Girls",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "dressupwho",
     
      orientation: 0,
      title: "School Girl Makeover - playcutegames.com",
      name: ["School Girl Makeover", "school-girl-makeover"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/school-girl-makeover/250x142.png",
      path: ["/game/school-girl-makeover", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/schoolgirl-makeover/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to School Girl Makeover game. At school, you are known as the girl who is always studying and not ever paid attention to.",
      l_dis:
        "Welcome to School Girl Makeover game. At school, you are known as the girl who is always studying and not ever paid attention to. Now, you want to change that by getting the ultimate makeover to soon become the most popular girl in school and impress all the people around you. Join and have fun!",
      tag: ["School", "Make Up", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Get Ready With Me Summer Picnic - playcutegames.com",
      name: [
        "Get Ready With Me Summer Picnic",
        "get-ready-with-me-summer-picnic",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/get-ready-with-me-summer-picnic/250x142.png",
      path: [
        "/game/get-ready-with-me-summer-picnic",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/get-ready-with-me-summer-picnic/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Get Ready With Me Summer Picnic game. Skyler, Sunny, Ruby, and Violet are the Rainbow high school girls who have planned for vacation.",
      l_dis:
        `Welcome to Get Ready With Me Summer Picnic game. Skyler, Sunny, Ruby, and Violet are the Rainbow high school girls who have planned for vacation. They decided to have a picnic to get rid of this summer. They need to get ready to heads out. Join and help them to get ready. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "Rainbow High", "Vacation", "Summer"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Insta Trends: Galaxy Fashion - playcutegames.com",
      name: ["Insta Trends: Galaxy Fashion", "insta-trends-galaxy-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/insta-trends-galaxy-fashion/250x142.png",
      path: ["/game/insta-trends-galaxy-fashion", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/2ccf9dda-e046-5d4d-a092-c8aa7c5b38fb/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Insta Trends: Galaxy Fashion. Embark on a fashionable trip to the center of the Universe together with your favorite Disney Princesses while playing the Insta Trends: Galaxy Fashion dress-up game",
      l_dis:
        "Welcome to Insta Trends: Galaxy Fashion. Embark on a fashionable trip to the center of the Universe together with your favorite Disney Princesses while playing the Insta Trends: Galaxy Fashion dress-up game for girls. Elsa, Anna, Moana, Ariel, and Rapunzel recently found Planet Her, and they are super excited to share their new home with you. Fashion on this amazing planet is galaxy colored and centered around fashionable suits and out-of-this-world accessories. You can discover them all while helping the girls choose their outfits for the day! Have fun!",
      tag: [
        "Dress up",
        "Make Up",
        "Princess",
        "Fashion",
        "Prinxy Games",
        "Anna",
        "Elsa",
        "Rapunzel",
        "Ariel",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Angela All Season Fashion - playcutegames.com",
      name: ["Angela All Season Fashion", "angela-all-season-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-all-season-fashion/250x142.png",
      path: ["/game/angela-all-season-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/angela-all-season-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Angela All Season Fashion game. Angela feels so creative and wants something more fun! She planned to do seasonal fashion outfits and post on social media to create new trends.",
      l_dis:
        `Welcome to the Angela All Season Fashion game. Angela feels so creative and wants something more fun! She planned to do seasonal fashion outfits and post on social media to create new trends. Help her to pick an cute outfits from the wardrobe for each season. Come and join with Angela. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Fashion",
        "Winter",
        "Summer",
        "Autumn",
        "Season",
        "Angela",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Quarantine Glam Look - playcutegames.com",
      name: ["My Quarantine Glam Look", "my-quarantine-glam-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-quarantine-glam-look/250x142.png",
      path: ["/game/my-quarantine-glam-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-quarantine-glam-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to My Quarantine Glam Look games. Rainbow Girls Skyler, Sunny, Ruby, and Violet are got bored being home in this Quarantine! They planned to do some funny indoor fashion activities.",
      l_dis:
        `Welcome to My Quarantine Glam Look games. Rainbow Girls Skyler, Sunny, Ruby, and Violet are got bored being home in this Quarantine! They planned to do some funny indoor fashion activities. Come and join with the girls and show your expert skills. Pick cute pink shades of blushes, eyeshades, eye color, and lip colors. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Rainbow High",
        "Quarantine",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Summer Aesthetics - playcutegames.com",
      name: ["Summer Aesthetics", "summer-aesthetics"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/summer-aesthetics/250x142.png",
      path: ["/game/summer-aesthetics", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/34db646a-72f3-5988-8880-e19d5e749e32/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Summer Aesthetics game. Hop on a plane and spend your summer vacation traveling around the world together with your favorite Disney princesses.",
      l_dis:
        "Welcome to Summer Aesthetics game. Hop on a plane and spend your summer vacation traveling around the world together with your favorite Disney princesses. Discover beautiful sceneries and practice your fashion advisor skills while helping them choose their outfits of the day in the Summer Aesthetics Dress Up Game for girls! Have a blast!",
      tag: [
        "Dress up",
        "Princess",
        "Summer",
        "Aesthetic",
        "Prinxy Games",
        "Belle",
        "Merida",
        "Aurora",
        "Moana",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "GD",
     
      orientation: 0,
      title: "Rachel Holmes - playcutegames.com",
      name: ["Rachel Holmes", "rachel-holmes"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rachel-holmes/250x142.png",
      path: ["/game/rachel-holmes", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/120653a94b0a48cf9050fff4a258f5cf/?gd_sdk_referrer_url=https://playcutegames.com/games/rachel-holmes",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Rachel Holmes. Strange things keep happening all over the world! Someone is taking pictures in different places and challenges people to find the originals as fast as possible.",
      l_dis:
        "Welcome to Rachel Holmes. Strange things keep happening all over the world! Someone is taking pictures in different places and challenges people to find the originals as fast as possible. Rachel Holmes is already on the case but she needs your help! Together you can spot the differences while looking at thousands of pictures and competing with other detectives. Travel the world, find all the differences online, and aid Rachel with solving this mystery. Hurry up! Everyone wants to become the best and get there first. This game is perfect for you: …if you like to look around and appreciate the world in its every detail, …if you prefer to keep your brain active and agile while having fun, …if you live to challenge yourself and compete with other enthusiasts, …if you just want to relax and do something enjoyable. The list goes on but you don’t have time. Rachel Holmes is already waiting for you in the game. Play now!",
      tag: ["Puzzle", "Kids", "Difference", "Find Out","Yad"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Design With Me Trendy Pencil Skirt - playcutegames.com",
      name: [
        "Design With Me Trendy Pencil Skirt",
        "design-with-me-trendy-pencil-skirt",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/design-with-me-trendy-pencil-skirt/250x142.png",
      path: [
        "/game/design-with-me-trendy-pencil-skirt",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/design-with-me-trendy-pencil-skirt/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Design With Me Trendy Pencil Skirt. Hairdorable girls Dee dee, Willow, and Noah are getting ready for a holiday.",
      l_dis:
        `Welcome to Design With Me Trendy Pencil Skirt. Hairdorable girls Dee dee, Willow, and Noah are getting ready for a holiday. They have decided to design their own trendy pencil skirt and get a unique look for this holiday. Help out the girls to choose a perfect skirt model, the color and the pattern of the fabric, add a cute inscription and finally match it with nice tops and accessories. Join and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Decoration",
        "Skirt",
        "Design",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Ariana Wedding Prep - playcutegames.com",
      name: ["Ariana Wedding Prep", "ariana-wedding-prep"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ariana-wedding-prep/250x142.png",
      path: ["/game/ariana-wedding-prep", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/8b6a9c91-68cb-5abf-a76e-664eb2a33b2a/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Ariana Wedding Prep. Join your favorite singer in getting started with this free-to-play celebrity game for girls called Ariana Wedding Prep, and step by step, help her wow her fiancee with a stunning bridal look.",
      l_dis:
        "Welcome to Ariana Wedding Prep. Join your favorite singer in getting started with this free-to-play celebrity game for girls called Ariana Wedding Prep, and step by step, help her wow her fiancee with a stunning bridal look. Choose her wedding dress, style up her hair, and do her makeup as you complete all the steps in the beautifying process. Next, you can help her bridesmaids, Kendall Jenner and Gigi Hadid, choose their wedding looks as well. Have fun!",
      tag: [
        "Wedding",
        "Dress up",
        "Make Up",
        "Bride",
        "Ariana",
        "Kendall Jenner",
        "Design",
        "Prinxy Games"]
  ,
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "dressupwho",
     
      orientation: 0,
      title: "Blondie Summer Friends Fashion Show - playcutegames.com",
      name: [
        "Blondie Summer Friends Fashion Show",
        "blondie-summer-friends-fashion-show",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/blondie-summer-friends-fashion-show/250x142.png",
      path: [
        "/game/blondie-summer-friends-fashion-show",
        "https://playcutegames.com",
      ],
      iframe: "https://dressupwho.net/html5/blondie_friends_summer_fashion_show/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Blondie Summer Friends Fashion Show. Blondie and her BFF decided to have a high fashion week. It is summer and warm weather outside.",
      l_dis:
        "Welcome to Blondie Summer Friends Fashion Show. Blondie and her BFF decided to have a high fashion week. It is summer and warm weather outside. This means that the time has come for long, flying skirts and dresses. Elegant floral prints are all the rage now. Mix and match a variety of blouses, jackets, skirts, and trousers to create a stylish ensemble. Don’t forget to put on trendy makeup and have a summer runway show.",
      tag: ["Princess", "Dress up", "Make Up", "Summer", "Blondie", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Royal Wedding - playcutegames.com",
      name: ["Princess Royal Wedding", "princess-royal-wedding"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-royal-wedding/250x142.png",
      path: ["/game/princess-royal-wedding", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess-royal-wedding/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the princess royal wedding game. Princess Anna and Elsa are getting ready for their wedding with Kristoff and Jack.",
      l_dis:
        `Welcome to the princess royal wedding game. Princess Anna and Elsa are getting ready for their wedding with Kristoff and Jack. They waiting so long for this occassion. They need some hand to get ready. Come and join the girls! And make this occassion most beautiful one in their life!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Wedding",
        "Anna",
        "Elsa",
        "Kristoff",
        "Jack Frost",
        "Date",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Lovely Wedding Date - playcutegames.com",
      name: ["Lovely Wedding Date", "lovely-wedding-date"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lovely-wedding-date/250x142.png",
      path: ["/game/lovely-wedding-date", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovely-wedding-date/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Hey Girls, Welcome to the Lovely Wedding Date game. Skyler is more excited about her dream day with Collin. Yes, It’s their wedding.",
      l_dis:
        `Hey Girls, Welcome to the Lovely Wedding Date game. Skyler is more excited about her dream day with Collin. Yes, It’s their wedding. Help them to get ready for this occassion. And make this occasion a more memorable one in her life! Join with them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Wedding",
        "Date",
        "Rainbow High",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Sweet Strawberry Outfits - playcutegames.com",
      name: ["My Sweet Strawberry Outfits", "my-sweet-strawberry-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-sweet-strawberry-outfits/250x142.png",
      path: ["/game/my-sweet-strawberry-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-sweet-strawberry-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Hey girl, welcome to the My Sweet Strawberry Outfits game. Rainbow high girls Skyler, Sunny, Ruby, and Violet are planned to do something special on the weekend.",
      l_dis:
        `Hey girl, welcome to the My Sweet Strawberry Outfits game. Rainbow high girls Skyler, Sunny, Ruby, and Violet are planned to do something special on the weekend. They have an idea to design their own strawberry outfit. Help them with the clothes to create a cute outfit, and complete the look with makeup. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Rainbow High", "Dress up", "Make Up", "cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Pride Rainbow Fashion - playcutegames.com",
      name: ["Pride Rainbow Fashion", "pride-rainbow-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pride-rainbow-fashion/250x142.png",
      path: ["/game/pride-rainbow-fashion", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/31e09f63-1f81-545b-a953-b97b3278c217/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Pride Rainbow Fashion game. In this free-to-play fashion game for girls called Pride Rainbow Fashion, you help six of your favorite Disney",
      l_dis:
        "Welcome to the Pride Rainbow Fashion game. In this free-to-play fashion game for girls called Pride Rainbow Fashion, you help six of your favorite Disney Princesses get ready to attend the annual Pride Parade looking in style. Each girl’s wardrobe is colored in a rainbow-inspired shade, and to discover what color each princess took, you are challenged to advance from one unit to another. Be ready to discover our impressive selection of summer dresses, flower printed tops, shorts, skirts, and jeans as you dress up each Disney girl in one bright shade inspired by the colors of the rainbow.Mix and match your favorite clothing pieces in jaw-dropping pride outfits. And then accessorize the winning outfit with a summery hairstyle, colorful accessories, floral headbands, and a striped flag. Next, capture a photo of your creation and share it with your friends! Spread the love!",
      tag: [
        "Princess",
        "Dress up",
        "Fashion",
        "Prinxy Games",
        "Rainbow Girls",
        "Anna",
        "Elsa",
        "Elsa",
        "Tiana",
        "Moana",
        "Merida",
        "Rapunzel",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Little Cute Summer Fairies Puzzle - playcutegames.com",
      name: [
        "Little Cute Summer Fairies Puzzle",
        "little-cute-summer-fairies-puzzle",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/little-cute-summer-fairies-puzzle/250x142.png",
      path: [
        "/game/little-cute-summer-fairies-puzzle",
        "https://playcutegames.com",
      ],
      iframe: "https://www.dl-girls.com/content/folder_1623255644/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Little Cute Summer Fairies Puzzle game. It’s time for puzzles! Just look at these lovely and pretty fairies. They are so different, but all charming in their own way.",
      l_dis:
        "Welcome to the Little Cute Summer Fairies Puzzle game. It’s time for puzzles! Just look at these lovely and pretty fairies. They are so different, but all charming in their own way. Try to collect all the puzzles in the shortest possible time; set records and compete with your friends. Collect all the pictures of these wonderful fairies and have fun!",
      tag: ["Princess", "Summer", "Puzzle", "Fairy", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Design With Me Cute Tie Dye Tops - playcutegames.com",
      name: [
        "Design With Me Cute Tie Dye Tops",
        "design-with-me-cute-tie-dye-tops",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/design-with-me-cute-tie-dye-tops/250x142.png",
      path: [
        "/game/design-with-me-cute-tie-dye-tops",
        "https://playcutegames.com",
      ],
      iframe: " https://games.cutedressup.net/design_with_me_cute_tie_dye_tops/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Design With Me Cute Tie Dye Tops. Rainbow girls Sunny, Skyler and Ruby are getting ready for a holiday. They have decided to design their own crop tops and get a unique look for this holiday.",
      l_dis:
        `Welcome to Design With Me Cute Tie Dye Tops. Rainbow girls Sunny, Skyler and Ruby are getting ready for a holiday. They have decided to design their own crop tops and get a unique look for this holiday. Help out the girls to choose a crop top model, the color and the pattern of the fabric, add a cute inscription and finally match it with a nice skirt or shorts. Join and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Decoration",
        "Rainbow High",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "Prinxy",
     
      orientation: 0,
      title: "Graduation Hairstyles - playcutegames.com",
      name: ["Graduation Hairstyles", "graduation-hairstyles"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/graduation-hairstyles/250x142.png",
      path: ["/game/graduation-hairstyles", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/6f2264e1-f61d-57f2-b5f8-a8cd1fa7063d/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Help Princess Belle in Graduation Hairstyles! Pamper her with a stunning updo and dress her in a chic outfit for her big day in this best online game!",
      l_dis:
        "Welcome to the Graduation Hairstyles game. You help Princess Belle prepare herself for graduation day! This day comes to complete Belle’s beautiful journey through high school, and it’s surely one day she will not forget any time soon. Surrounded by close friends and family, she will head to the high school for the last time, but not before getting herself pampered by your skillful hands. Let’s start with some hair care! Then, follow the step-by-step instructions to recreate an elegant updo for the day, dress her up in a colorful summer dress or in a chic two pieces outfit, and customize her graduation robe and cap in bright shades and cool stickers. Have fun playing the Graduation Hairstyles game for girls!",
      tag: ["Dress up", "Make Up", "Graduation", "Salon", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
   
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Baddie Billie Evolution - playcutegames.com",
      name: ["Baddie Billie Evolution", "baddie-billie-evolution"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/baddie-billie-evolution/250x142.png",
      path: ["/game/baddie-billie-evolution", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/880e62c9-845a-54f4-918f-4922ffc39685/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Baddie Billie Evolution. Join your favorite singer in getting started with this free-to-play celebrity and have a blast recreating outfits inspired by the fashion Billie, wore while having a certain hair color.",
      l_dis:
        "Welcome to Baddie Billie Evolution. Join your favorite singer in getting started with this free-to-play celebrity and have a blast recreating outfits inspired by the fashion Billie, wore while having a certain hair color. Are you curious to discover the four iconic looks we featured in our game?",
      tag: ["Dress up", "Manicure", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFF Summer Vibes - playcutegames.com",
      name: ["BFF Summer Vibes", "bff-summer-vibes"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-summer-vibes/250x142.png",
      path: ["/game/bff-summer-vibes", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_summer_vibes/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the BFF Summer Vibes games. Summer is here and these rainbow high school girls need to get ready for it! How about you, are you ready for summer? These girls need a summer touch when it comes to their wardrobe and it would be nice if you could help them out.",
      l_dis:
        `Welcome to the BFF Summer Vibes games. Summer is here and these rainbow high school girls need to get ready for it! How about you, are you ready for summer? These girls need a summer touch when it comes to their wardrobe and it would be nice if you could help them out. So check out the latest summer trends and then turn these princesses into some real divas! You have a whole wardrobe stacked with dresses, tops, skirts and so many accessories at your disposal. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Bff",
        "Summer",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Cottagecore Aesthetic Look - playcutegames.com",
      name: ["My Cottagecore Aesthetic Look", "my-cottagecore-aesthetic-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-cottagecore-aesthetic-look/250x142.png",
      path: ["/game/my-cottagecore-aesthetic-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my_cottagecore_aesthetic_look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In My Cottagecore Aesthetic Look, join Elsa, Anna, and Ariel to create the cutest outfits! My Cottagecore Aesthetic Look in this best free online game!",
      l_dis:
        `Welcome to the My Cottagecore Aesthetic Look game. Cottagecore fashion is inspired by farm life, it is very impractical for doing chores around the farm! Princess Elsa, Anna, and Ariel are best friends planning to try something special. Get ready to explore the Cottagecore fashion style along with them to create the cutest looks!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Cottagecore",
        "Aesthetic",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Billie Blonde Transformation - playcutegames.com",
      name: ["Billie Blonde Transformation", "billie-blonde-transformation"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/billie-blonde-transformation/250x142.png",
      path: ["/game/billie-blonde-transformation", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/315cad52-4b3f-54d1-8770-e167d8e831d8/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Transform Billie Eilish in Billie Blonde Transformation! Help her change neon green roots to creamy ice blonde in this fun, free online game!",
      l_dis:
        "Welcome to the Billie Blonde Transformation game. Billie Eilish’s new look took over the internet during the last weeks, and all the craze around the news inspired us to create this fun makeover game for girls that covers the transformation process. In this game, you girls join Billie’s personal hairdresser and help together you transform her neon green roots and black lengths into a creamy ice blonde. Are you ready to get started with Billie’s total transformation process?",
      tag: ["Princess", "Dress up", "Make Up", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls Hollywood Story - playcutegames.com",
      name: ["Rainbow Girls Hollywood Story", "rainbow-girls-hollywood-story"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-hollywood-story/250x142.png",
      path: ["/game/rainbow-girls-hollywood-story", "https://playcutegames.com"],
      iframe: " https://games.cutedressup.net/rainbow_girls_hollywood_story/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Hey girls! Welcome to the new Rainbow Girls Hollywood Story game. Rainbow high school girls Skyler, Sunny, Ruby, and Violet are invited for a Hollywood-themed dress-up party.",
      l_dis:
        `Hey girls! Welcome to the new Rainbow Girls Hollywood Story game. Rainbow high school girls Skyler, Sunny, Ruby, and Violet are invited for a Hollywood-themed dress-up party. They are been so excited and planned to be the best at the party. Join the girls and have fun! Start with Skyler’s makeup part, help her to pick a cute pick shade of lip color, eyeshades, and blushes. Then start with dress-up, pick a cute outfit, and sparkly accessories. Do the same thing for the rest of the girls. Join and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Rapunzel",
        "Hollywood",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Incredible Princess Eye Art - playcutegames.com",
      name: ["Incredible Princess Eye Art", "incredible-princess-eye-art"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/incredible-princess-eye-art/250x142.png",
      path: ["/game/incredible-princess-eye-art", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1620318663/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Incredible Princess Eye Art game. Try your hand at makeup on the beautiful eyes of cute princesses.",
      l_dis:
        "Welcome to the Incredible Princess Eye Art game. Try your hand at makeup on the beautiful eyes of cute princesses. Use extraordinary artistic solutions to create a striking and non-standard look. What kind of princess will be today – a cute doll, a vamp-lady or a stylish cyber princess – you decide. Grab your makeup brush and get started! Try all the art for the eyes.",
      tag: ["Princess", "Make Up", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "High Fashion Runway Look - playcutegames.com",
      name: ["High Fashion Runway Look", "high-fashion-runway-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/high-fashion-runway-look/250x142.png",
      path: ["/game/high-fashion-runway-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/high_fashion_runway_look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the High Fashion Runway Look game. Dee dee, Noah, and Willow are the best friends planning to participate in a runway fashion. They have confusion to choose the best outfit in their wardrobes.",
      l_dis:
        `Welcome to the High Fashion Runway Look game. Dee dee, Noah, and Willow are the best friends planning to participate in a runway fashion. They have confusion to choose the best outfit in their wardrobes. Pick a unique style for each one of them. Join and help the girls with your expert skill. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "hairdorables doll",
        "Dress up",
        "Make Up",
        "Fashion",
        "Runway",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Oscar Red Carpet Fashion - playcutegames.com",
      name: ["Oscar Red Carpet Fashion", "oscar-red-carpet-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/oscar-red-carpet-fashion/250x142.png",
      path: ["/game/oscar-red-carpet-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/oscar_red_carpet_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Oscar Red Carpet Fashion game. The red carpet on Oscar fashion has been always the most exciting as everyone shows their gowns for the first time.",
      l_dis:
        `Welcome to the Oscar Red Carpet Fashion game. The red carpet on Oscar fashion has been always the most exciting as everyone shows their gowns for the first time. Bff’s from Rainbow high is preparing for their special weekend contest and they would very much appreciate it if you could take some time to give them some good tips and to make them shine on the red carpet. Would you like to be their personal stylist? Check out the special grows, jewelry, and hairstyles available and choose the ones you like best from all to give them a perfect look.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "TikTok Trends: Boyfriend Fashion - playcutegames.com",
      name: [
        "TikTok Trends: Boyfriend Fashion",
        "tiktok-trends-boyfriend-fashion",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-trends-boyfriend-fashion/250x142.png",
      path: [
        "/game/tiktok-trends-boyfriend-fashion",
        "https://playcutegames.com",
      ],
      iframe:
        "https://game.digitap.eu/4a455617-7730-5f4c-b08d-236ddc0956a8/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Play TikTok Trends: Boyfriend Fashion, the best online game for free. Explore trendy oversized outfits from your boyfriend’s wardrobe on PlayCuteGames!",
      l_dis:
        "Welcome to TikTok Trends: Boyfriend Fashion. In this online dress-up game for girls called TikTok Trends: Boyfriend Fashion, you go shopping with two of your favorite Disney princesses, and you help them pick the coolest boyfriend clothes and accessories available in stores. Are you ready to get started with their fashion transformation? The boyfriend trend is all about wearing oversized clothes, and sometimes it involves discreetly removing items from your boyfriend’s wardrobe and including them in your cool everyday outfits. Imagine long sweatshirts paired with cool boots, his white shirts transformed into summer dresses. Your boy’s hoodies paired with your favorite leggings, and his cool varsity jacket paired with your favorite flower printed dress.",
      tag: ["Princess", "Dress up", "Tiktok", "Fashion", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Cute Little Dragon Creator - playcutegames.com",
      name: ["Cute Little Dragon Creator", "cute-little-dragon-creator"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-little-dragon-creator/170x170.jpg",
      path: ["/game/cute-little-dragon-creator", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1618507402/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Create your dream dragon in Cute Little Dragon Creator! Choose colors, patterns, and accessories to design your unique dragon in this best online game!",
      l_dis:
        "Welcome to Cute Little Dragon Creator. Do you like dragons? Then this game is for you! Create your own unique cute little dragon; you can choose any color and many patterns and decorations, as well as eyes, wings, tail, and accessories. How did your little dragon turn out? Show the screenshot in the comments.",
      tag: ["Pet", "Dress up", "Kids", "Fashion", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Perfect Stylish Street Look - playcutegames.com",
      name: ["Perfect Stylish Street Look", "perfect-stylish-street-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/perfect-stylish-street-look/250x142.png",
      path: ["/game/perfect-stylish-street-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/perfect_stylish_street_look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Perfect Stylish Street Look game. Dee dee, Willow, and Noah are the best friends who planned to throw a weekend party. They have an idea to make this party more special.",
      l_dis:
        `Welcome to the Perfect Stylish Street Look game. Dee dee, Willow, and Noah are the best friends who planned to throw a weekend party. They have an idea to make this party more special. Noah comes with an idea to try stylish street outfits. But they need and expert help to choose the best outfit. Join the girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Hairdorables Doll",
        "Dress up",
        "Make Up",
        "Style",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Dragonstone Quest Adventure - playcutegames.com",
      name: ["Dragonstone Quest Adventure", "dragonstone-quest-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dragonstone-quest-adventure/250x142.png",
      path: ["/game/dragonstone-quest-adventure", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1617902716/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Dragonstone Quest Adventure. Brave Princess sets off in search of the dragon stone; she has a long journey in which many dangers lie in wait.",
      l_dis:
        "Welcome to Dragonstone Quest Adventure. Brave Princess sets off in search of the dragon stone; she has a long journey in which many dangers lie in wait. Help Princess find all the fragments of the dragon stone, reassemble it and bring peace back to the five kingdoms. And the magic dragon will help Princess in her search.",
      tag: ["Princess", "Dress up", "Raya", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFF Happy Spring - playcutegames.com",
      name: ["BFF Happy Spring", "bff-happy-spring"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-happy-spring/250x142.png",
      path: ["/game/bff-happy-spring", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_happy_spring/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bff Happy Spring games. Princesses Belle, Tiana, and Moana are best friends. They planned to organize a party to welcome the most adorable spring season.",
      l_dis:
        `Welcome to the Bff Happy Spring games. Princesses Belle, Tiana, and Moana are best friends. They planned to organize a party to welcome the most adorable spring season. They already filled their wardrobe with a cherry blossom collection of outfits. Being a design expert join and help them to choose the best outfit for them. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "BFF",
        "Spring",
        "Belle",
        "Tiana",
        "Moana",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Fresh Spring Style - playcutegames.com",
      name: ["Fresh Spring Style", "fresh-spring-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/fresh-spring-style/250x142.png",
      path: ["/game/fresh-spring-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/fresh_spring_style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Help the Rainbow high school girls Sunny, Skyler, Ruby, and Violet pick floral outfits for spring in Fresh Spring Style. Play this free online game now!",
      l_dis:
        `Welcome to the Fresh Spring Style game. Rainbow high school girls Sunny, Skyler, Ruby, and Violet are getting ready for the most awaited season who are absolutely mad about this season! They already filling the wardrobe with floral and blossom outfits. They need some expert help to look better. Come and join them. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Spring",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Maskne Face Care - playcutegames.com",
      name: ["Maskne Face Care", "maskne-face-care"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/maskne-face-care/250x142.png",
      path: ["/game/maskne-face-care", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/e8e228d9-111e-5722-89da-d0627bcef73d/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Maskne Face Care. Caused by a mix of sweat, cosmetics, and contact with a face mask that prevents the skin from breathing, maskne is the new acne, and it is here to complicate our lives even more.",
      l_dis:
        "Welcome to Maskne Face Care. Caused by a mix of sweat, cosmetics, and contact with a face mask that prevents the skin from breathing, maskne is the new acne, and it is here to complicate our lives even more. So if you’re also facing breakouts from wearing a mask, you’re not alone. Gorgeous Ellie has been facing it since last summer, but luckily she learned what beauty products and cosmetics to use and how to keep it under control. Get started with the Ellie: Maskne Face Care beauty game for girls, and step by step discover her  Korean-inspired minimalist skincare routine. Then be prepared to learn some of her makeup tricks too! Because of the coronavirus, she will continue wearing the mask every day. But she knows exactly how to pair her eyeshadow with the face masks she’s wearing. Are you curious to learn more?",
      tag: ["Princess", "Dress up", "Make Up", "Care", "Barbie", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Stylish Tiered Ruffle Addiction - playcutegames.com",
      name: [
        "Stylish Tiered Ruffle Addiction",
        "stylish-tiered-ruffle-addiction",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/stylish-tiered-ruffle-addiction/250x142.png",
      path: [
        "/game/stylish-tiered-ruffle-addiction",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/stylish_tiered_ruffle_addiction/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Hey girls! Welcome to the Stylish Tiered Ruffle Addiction game. Rainbow high school girls Skyler, Sunny, Violet, and Ruby planned to have a fashion meet.",
      l_dis:
        `Hey girls! Welcome to the Stylish Tiered Ruffle Addiction game. Rainbow high school girls Skyler, Sunny, Violet, and Ruby planned to have a fashion meet. Nothing will be fun when this will not align with a particular topic. Ruby suggested having a fashion meet with Ruffles design. Each girl needs expert help to make themself better. Join and help them. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Style",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bff Trendy K Fashion - playcutegames.com",
      name: ["Bff Trendy K Fashion", "bff-trendy-k-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-trendy-k-fashion/250x142.png",
      path: ["/game/bff-trendy-k-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_trendy_k_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bff Trendy K-Fashion game. Princess Anna, Elsa, and Ariel are the best friends who met after a long time. They planned to make a challenge between them.",
      l_dis:
        `Welcome to the Bff Trendy K-Fashion game. Princess Anna, Elsa, and Ariel are the best friends who met after a long time. They planned to make a challenge between them. Princess Ariel comes up with the idea of trying Korean fashion trends. On hearing that Elsa and Anna getting more excited to try this. Join and help the princesses with these k-fashion trends. Have fun! Start with princess Elsa’s make-up part, pick cute pink shades of lip color and eyeshades. And pick the cute color of eye color and blushes. Then browse her wardrobe to find the best outfit for her. Do the same for other princesses.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Anna",
        "Elsa",
        "Elsa",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Soft Girl Aesthetic - playcutegames.com",
      name: ["Soft Girl Aesthetic", "soft-girl-aesthetic"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/soft-girl-aesthetic/250x142.png",
      path: ["/game/soft-girl-aesthetic", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/soft_girl_aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Soft Girl Aesthetic game. Skyler, Sunny, Ruby, and violet are the rainbow high school girls. They are up to date with the latest trends.",
      l_dis:
        `Welcome to the Soft Girl Aesthetic game. Skyler, Sunny, Ruby, and violet are the rainbow high school girls. They are up to date with the latest trends. Now they hear about the soft girl’s hashtag trending on both Instagram and Tiktok. They love to change themselves with the latest trends. Join and help them. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Aesthetic",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Easter Glamping Trip - playcutegames.com",
      name: ["Easter Glamping Trip", "easter-glamping-trip"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/easter-glamping-trip/250x142.png",
      path: ["/game/easter-glamping-trip", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/46674736-e7a3-5f68-9baf-c0c5185ce160/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Easter Glamping Trip. This year, our Frozen sisters are going glamping in the mountains, where they plan to relax under the sun all day long.",
      l_dis:
        "Welcome to the Easter Glamping Trip. This year, our Frozen sisters are going glamping in the mountains, where they plan to relax under the sun all day long. For that, they booked the most Instagrammable glamping place in Norway, and now they need your help to get their outfits ready for the fancy trip. Come and join them in getting started with the Easter Glamping Trip game for girls, and see what jaw-dropping holiday looks you can put together for them. As their fashion adviser, you can choose from an impressive collection of bohemian dresses, embroidered tops, knitted blouses, jeans, shorts, and Aztec printed overtops. Go through all the options available at your disposal, and pick the perfect outfit of the day. Great job, ladies! Are you ready for more? Click next and get ready to discover new challenging tasks as you continue playing this holiday-themed game for girls! Have a blast!",
      tag: ["Princess", "Dress up", "Decoration", "Easter", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Mix And Match Fashion - playcutegames.com",
      name: ["Mix And Match Fashion", "mix-and-match-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/mix-and-match-fashion/250x142.png",
      path: ["/game/mix-and-match-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/mix_and_match_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Mix And Match Fashion Game. Noah, Dee Dee, and Willow are adorable girls who inspired more by the latest fashion trends. They heard about a new upcoming contest called Mix and Match Fashion.",
      l_dis:
        `Welcome to Mix And Match Fashion Game. Noah, Dee Dee, and Willow are adorable girls who inspired more by the latest fashion trends. They heard about a new upcoming contest called Mix and Match Fashion. Their wardrobes are already filled with the latest trends of outfits. But they need help to choose the better one. Join and help these girls. Start with girl’s makeup level, help them to have a cute color of eyeshades, lip and eye colors. Then Mix and Match the best outfits for each girl by browsing their wardrobe. Maybe outfit with a crop top with jeans or a top with a skirt. Join and Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Hairdorables doll",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Valentine's Matching Outfits - playcutegames.com",
      name: ["Valentine's Matching Outfits", "valentines-matching-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/valentines-matching-outfits/250x142.png",
      path: ["/game/valentines-matching-outfits", "https://playcutegames.com"],
      iframe: " https://games.cutedressup.net/valentines_matching_outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Valentine’s Matching Outfits. These cute couples from rainbow high are planned to celebrate this Valentine as a memorable one. So they decided to go for an outing and spend a whole day together on this occasion.",
      l_dis:
        `Welcome to Valentine’s Matching Outfits. These cute couples from rainbow high are planned to celebrate this Valentine as a memorable one. So they decided to go for an outing and spend a whole day together on this occasion. They make this even more special by trying the same matching outfits. Join and help these cute pairs from rainbow high. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Valentine",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Mega Dressup Seasons Best - playcutegames.com",
      name: ["Mega Dressup Seasons Best", "mega-dressup-seasons-best"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/mega-dressup-seasons-best/250x142.png",
      path: ["/game/mega-dressup-seasons-best", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/f810782b-9007-569c-be2f-1dcc6dda36a1/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Calling all fashion lovers! In the online game Mega Dressup Seasons Best, craft fabulous seasonal looks for princesses. Join the fun for free!",
      l_dis:
        "Welcome to Mega Dressup Seasons Best. Pretty clothes and unique styles, Mega Dressup will definitely bring out the fashionista in you! A vast array of outfits and accessories awaits you in this online dress-up game for girls, each to fit every season of the year, and the princesses are excited to welcome you into their trendy world. Are you ready to join the fun!",
      tag: [
        "Princess",
        "Dress up",
        "Winter",
        "Spring",
        "Spring",
        "Summer",
        "Autumn",
        "Prinxy Games"]
  ,
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Princess Homeland - playcutegames.com",
      name: ["Princess Homeland", "princess-homeland"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-homeland/150X150.jpg",
      path: ["/game/princess-homeland", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/ceafbe0b-8ec7-502c-b12e-02265e96d775/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Travel around the world in this online Cutedressup game for girls called Princess Homeland, and get ready to discover the traditional fashion of the different countries where your favorite Disney princesses live.",
      l_dis:
        "Travel around the world in this online Cutedressup game for girls called Princess Homeland, and get ready to discover the traditional fashion of the different countries where your favorite Disney princesses live. Elsa, Moana, Merida, and Esmeralda can’t wait to show off their stunning wardrobes. Are you curious to discover where you will be going first? Get started with the Princess Homeland Dress Up Game for girls, and be prepared to discover lots of fashionable clothing pieces and accessories as you travel from Scotland to France and from the sunny islands of Polynesia to Noway. Have a blast! ",
      tag: ["Princess", "Dress up", "Fashion", "Prinxy Games","Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Valentine's Crush - playcutegames.com",
      name: ["Princess Valentine's Crush", "princess-valentines-crush"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-valentines-crush/250x142.png",
      path: ["/game/princess-valentines-crush", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_valentines_crush/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess Valentine’s Crush game. Princess Elsa has a crush on Jack Frost. She Invited Jack to the valentine’s party. Both have a plan to do some makeovers and enjoy taking some photos to post on social media.",
      l_dis:
        `Welcome to Princess Valentine’s Crush game. Princess Elsa has a crush on Jack Frost. She Invited Jack to the valentine’s party. Both have a plan to do some makeovers and enjoy taking some photos to post on social media. Join and help the beautiful couples. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Valentine",
        "Jack Frost",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Lotta The Otter Rescue - playcutegames.com",
      name: ["Lotta The Otter Rescue", "lotta-the-otter-rescue"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lotta-the-otter-rescue/250x142.png",
      path: ["/game/lotta-the-otter-rescue", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/f8611bf0-acea-5663-a104-9ef96ea08c0e/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In Lotta The Otter Rescue, care for little Lotta! Nurse her injuries, give her a bubble bath, and create a cozy home in this online game. Play for free!",
      l_dis:
        "Welcome to Lotta The Otter Rescue. Discover little Lotta’s story in this free-to-play animal care game for girls called Lotta The Otter Rescue. Take the lost otter to your home, take care of her injuries, and then spoil her with a warm bubble bath. Next, dress her un in a fashionable outfit, and then help her build a new home. Decorate it with colorful hammocks and lots of green plants and so you get to turn Lotta’s new home into one cozy place.",
      tag: ["Dress up", "Decoration", "Pet", "Prinxy Games","Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Venice Carnival Party - playcutegames.com",
      name: ["Venice Carnival Party", "venice-carnival-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/venice-carnival-party/250x142.png",
      path: ["/game/venice-carnival-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/venice_carnival_party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Venice Carnival Party Game. Skyler planned to have a vacation in Venice. Because It’s Carnival time in Venice now. She eagerly waits for so long for this. Her friend Sunny would like to join with Skyler.",
      l_dis:
        `Welcome to Venice Carnival Party Game. Skyler planned to have a vacation in Venice. Because It’s Carnival time in Venice now. She eagerly waits for so long for this. Her friend Sunny would like to join with Skyler. They need to get ready for the carnival. But they have little confusion on which clothing will suit them perfectly. Come and join them. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Decoration",
        "Carnival",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Winter Aesthetic Look - playcutegames.com",
      name: ["Winter Aesthetic Look", "winter-aesthetic-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/winter-aesthetic-look/250x142.png",
      path: ["/game/winter-aesthetic-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/winter_aesthetic_look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Winter Aesthetic Look. On being home rainbow girls feels very bored. They decided to do some real fun activities.",
      l_dis:
        `Welcome to Winter Aesthetic Look. On being home rainbow girls feels very bored. They decided to do some real fun activities. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Winter",
        "Aesthetic",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "Prinxy",
     
      orientation: 0,
      title: "Harley Learns To Love - playcutegames.com",
      name: ["Harley Learns To Love", "harley-learns-to-love"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/harley-learns-to-love/250x142.png",
      path: ["/game/harley-learns-to-love", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/0fd26107-e64a-5107-9a3f-aea283396ab4/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Harley Learns To Love as she transforms! Help Harley Quinn trade her bold style for a lovely makeover and select a date in this free online game!",
      l_dis:
        "Welcome to Harley Learns To Love. The most famous villain of all time decided to give up dangerous activities and try a new lifestyle. This Valentine’s Day, Harley Quinn has decided to give dates a try, so she’s going to need your expertise. Will you help her make a good impression? Play this new holiday game for girls called Harley Learns To Love, you help Harley Quinn get a total makeover and transform herself into a sweet girl. Replace her bold makeup look with a new one in natural shades, remove the colors in her hair, dress her up in girly-girl outfits, and select her perfect date in this free to play Valentine’s Day game for girls! Have a blast!",
      tag: ["Harley Quinn", "Dress up", "Make Up", "Valentine", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls NYE Fashion - playcutegames.com",
      name: ["Rainbow Girls NYE Fashion", "rainbow-girls-nye-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-nye-fashion/250x142.png",
      path: ["/game/rainbow-girls-nye-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/rainbow_girls_nye_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Rainbow Girls NYE Fashion. Skyler, Sunny, Ruby, and Violet are the best friends. They are planning for the NYE fashion to welcome the upcoming year with their stunning look.",
      l_dis:
        `Welcome to Rainbow Girls NYE Fashion. Skyler, Sunny, Ruby, and Violet are the best friends. They are planning for the NYE fashion to welcome the upcoming year with their stunning look. But they need expert help to do better. Join and help them as a fashion designer for these girls. Have fun! In this first level of this Makeup level, help Skyler to pick cute colors of eyeshades, Blushes, lip, and eye color. Then choose a cute hairstyle which suits her. Then browse her wardrobe and find a perfect Outfit and jewelry for her. Once you did with Skyler, Now it’s time for Sunny. Help her with makeup and hairstyles. Browse something different outfit than Skyler. For Ruby, Pick cute red shades of eyeshades, blushes, and lip color. And give her perfect outfits and accessories which suit her hair color. Finally, it’s time for Violet to choose a cool color of makeup and give a perfect outfit and accessories for her.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "New year Games",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Popsy Surprise Valentines Day Prank - playcutegames.com",
      name: [
        "Popsy Surprise Valentines Day Prank",
        "popsy-surprise-valentines-day-prank",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/popsy-surprise-valentines-day-prank/250x142.png",
      path: [
        "/game/popsy-surprise-valentines-day-prank",
        "https://playcutegames.com",
      ],
      iframe: "https://dl-girls.com/content/folder_1612975569/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Popsy Surprise Valentines Day Prank. Valentine’s day is coming and the cute popsy-princess is very upset. Her boyfriend left her, he went to another girl on the eve of the holiday.",
      l_dis:
        `Welcome to Popsy Surprise Valentines Day Prank. Valentine’s day is coming and the cute popsy-princess is very upset. Her boyfriend left her, he went to another girl on the eve of the holiday. But don’t be sad! Our popsy is still a mischievous person. She decided to give the newly minted couple an unforgettable Valentine’s Day prank. Help the popsy-princess prepare a prank in the restaurant. Also, don’t forget that a princess should always be gorgeous. For this Valentine’s Day, the image of a devil is perfect for her. Choose a daring outfit, hairstyle, and makeup and be gorgeous with our heroine, no matter what!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "Valentine", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls Christmas Party - playcutegames.com",
      name: ["Rainbow Girls Christmas Party", "rainbow-girls-christmas-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-christmas-party/250x142.png",
      path: ["/game/rainbow-girls-christmas-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/rainbow_girls_christmas_party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Rainbow Girls Christmas Party game. Skyler and Sunny are best friends. They decided to attend the Christmas party at Rainbow High.",
      l_dis:
        "Welcome to the Rainbow Girls Christmas Party game. Skyler and Sunny are best friends. They decided to attend the Christmas party at Rainbow High. Both have a secret crush on Collin, who also come to the party. So the girls try to impress him with their stunning look. Join and help them. Have fun! ",
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Christmas",
        "Party",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Chinese New Year - playcutegames.com",
      name: ["Chinese New Year", "chinese-new-year"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/chinese-new-year/250x142.png",
      path: ["/game/chinese-new-year", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/84a26006-1f48-5bb1-9467-03f84cbb61bc/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Chinese New Year game. Play this awesome dress up game for girls and help princess Mulan celebrate the Chinese New Year in style! 2021 is the year of the Ox",
      l_dis:
        "Welcome to the Chinese New Year game. Play this awesome dress up game for girls and help princess Mulan celebrate the Chinese New Year in style! 2021 is the year of the Ox, and she wants to find herself wrapped in elegant white, silver, and aqua blue garments. Mulan’s fashion challenge for you is to help her look great in these colors during the upcoming sixteen days of celebration. In her wardrobe, you can find one exquisite collection of traditional outfits such as cheongsams and qipaos but with a modern twist. Browse through them all, have Mulan try on the ones you like the most, and then pick the winning one. Next, see how you can accessorize it with elegant shoes, embroidered purses, statement jewelry, and vintage hand fans. Do her makeup look as well. Test your decoration skills as you discover the third stage in this free to play the holiday-themed game for girls! Join and have fun! ",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "New year Games","Dressupwho",
        "Prinxy Games"]
  ,
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "New Lifestyle Minimalism - playcutegames.com",
      name: ["New Lifestyle Minimalism", "new-lifestyle-minimalism"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/new-lifestyle-minimalism/250x142.png",
      path: ["/game/new-lifestyle-minimalism", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/8a14266d-7699-59de-9d22-cfd01cd0fa52/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to New Lifestyle Minimalism. It’s the beginning of a new year, and princess Cinderella has decided to experience a new lifestyle.",
      l_dis:
        "Welcome to New Lifestyle Minimalism. It’s the beginning of a new year, and princess Cinderella has decided to experience a new lifestyle. In 2021 Ella wants to become a minimalist, and you can help her out! In this brand-new cleaning game for girls called New Lifestyle: Minimalism, you help one of your favorite Disney princesses to start a major tidying up session and get rid of the clutter. Are you ready to discover what it takes to become a minimalist?",
      tag: ["Princess", "Dress up", "Make Up", "Decoration", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Frozen Princess Christmas Celebration - playcutegames.com",
      name: [
        "Frozen Princess Christmas Celebration",
        "frozen-princess-christmas-celebration",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/frozen-princess-christmas-celebration/250x142.png",
      path: [
        "/game/frozen-princess-christmas-celebration",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.cutedressup.net/frozen_princess_christmas_celebration/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Frozen Princess Christmas Celebration. The Christmas season has just started. Princess Anna and Elsa planned to celebrate this Christmas a more memorable one.",
      l_dis:
        `Welcome to the Frozen Princess Christmas Celebration. The Christmas season has just started. Princess Anna and Elsa planned to celebrate this Christmas a more memorable one. They joined with their boyfriend Kristoff and Jack. Anna planned to decorate the snowman and Christmas tree along with Elsa. They already bought a cute collection of Christmas outfits for them and their boyfriends. Join and help them with decorations and choosing the perfect costumes for this Christmas. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Jack Frost",
        "Kristoff",
        "Anna",
        "Elsa",
        "Christmas",
        "Decoration",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Marinette Winter Vacation Hot and Cold - playcutegames.com",
      name: [
        "Marinette Winter Vacation Hot and Cold",
        "marinette-winter-vacation-hot-and-cold",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/marinette-winter-vacation-hot-and-cold/250x142.png",
      path: [
        "/game/marinette-winter-vacation-hot-and-cold",
        "https://playcutegames.com",
      ],
      iframe: "https://dl-girls.com/content/folder_1611419175/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Marinette Winter Vacation Hot and Cold. All the villains are defeated and Marinette has a week free from superhero affairs. Where should she go on vacation? Choose a vacation option for Marinet.",
      l_dis:
        "Welcome to Marinette Winter Vacation Hot and Cold. All the villains are defeated and Marinette has a week free from superhero affairs. Where should she go on vacation? Choose a vacation option for Marinet. You can send her to a ski resort to conquer steep slopes. Choose from stylish ski suits, skis or snowboard, hats, and goggles. Don’t forget about makeup and hair – you never know where you will meet your prince. Or maybe you prefer warmth? Then choose a beach holiday for Marinette. In this case, you should choose an elegant swimsuit, comfortable shoes – sneakers or sandals, a hat, sunglasses, and an inflatable ring. Cold or heat – the choice is yours, it won’t be boring!",
      tag: ["Princess", "Dress up", "Make Up", "Winter", "Superhero", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "Adorable Girls Valentino Fashion - playcutegames.com",
      name: [
        "Adorable Girls Valentino Fashion",
        "adorable-girls-valentino-fashion",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/adorable-girls-valentino-fashion/250x142.png",
      path: [
        "/game/adorable-girls-valentino-fashion",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/adorable_girls_valentino_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Adorable Girls Valentino Fashion. Willow, Dee Dee, and Noah are the BFFs. They hear about a new contest, so they planned to try something different. Willow got an idea to try Valentino fashion trends.",
      l_dis:
        `Welcome to Adorable Girls Valentino Fashion. Willow, Dee Dee, and Noah are the BFFs. They hear about a new contest, so they planned to try something different. Willow got an idea to try Valentino fashion trends. But they need some fashion tips to win the contest. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Hairdorables doll",
        "Dress up",
        "Make Up",
        "Fashion",
        "Superhero",
        "cutedressup",
        "Exclusive",
        "Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "Y8",
     
      orientation: 0,
      title: "Baby Cathy Ep 1: Newborn - playcutegames.com",
      name: ["Baby Cathy Ep 1: Newborn", "baby-cathy-ep-1-newborn"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/baby-cathy-ep-1-newborn/250x142.png",
      path: ["/game/baby-cathy-ep-1-newborn", "https://playcutegames.com"],
      iframe: "https://y8.com/embed/baby_cathy_newborn",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Baby Cathy Ep 1: Newborn. Cute Cathy gonna born this Christmas season. Her parents are eagerly waiting for her arrival. So let us help them for preparing all the required items for baby Cathy.",
      l_dis:
        "Welcome to Baby Cathy Ep 1: Newborn. Cute Cathy gonna born this Christmas season. Her parents are eagerly waiting for her arrival. So let us help them for preparing all the required items for baby Cathy. First, they need to prepare Cathy next decorate the room n the meanwhile baby Cathy’s mother has admitted n labor help them getting Cathy out with little surgery and let’s decorate her cute cradle and finally let’s clean her up and dress her in the latest dresses and make her and her parents happy. ",
      tag: ["Dress up", "Cooking", "Doctor"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "My Perfect Year Planner - playcutegames.com",
      name: ["My Perfect Year Planner", "my-perfect-year-planner"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-year-planner/250x142.png",
      path: ["/game/my-perfect-year-planner", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/1eb18483-f922-5ce8-b50f-74727d47c471/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to My Perfect Year Planner. Belle has decided to make 2021 her best year ever, so even from the first days of the new year, she made a shortlist of resolutions.",
      l_dis:
        "Welcome to My Perfect Year Planner. Belle has decided to make 2021 her best year ever, so even from the first days of the new year, she made a shortlist of resolutions. She has a perfect plan for the New Year, but she needs your help to stick to it. Will you help her out? Join her in getting started with My Perfect Year Planner dress up game for girls, and help princess Belle get into her new lifestyle as she discovers 12 unique activities that are listed on her planner. Tap the diary on the planning and wait for the wheel to spin. When it stops, you get to discover one of Belle’s resolutions and a wardrobe filled with all the clothes, jewelry, and accessories you might need to help her look great while learning a new skill. Then, browse through her wardrobe, mix and match the clothing pieces you like the most with the right accessories, and dress her up for the given activity. Don’t forget to style up her hair for a completely new look. Return to the wheel, spin again, and create another stunning #ootd for our beautiful princess. Don’t stop until you’ve managed to complete all the twelve looks in this online dress-up challenge! ",
      tag: ["Princess", "Dress up", "Make Up", "Fashion", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "black-friday-shopping-spree - playcutegames.com",
      name: ["Black Friday Shopping Spree", "black-friday-shopping-spree"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/black-friday-shopping-spree/250x142.png",
      path: ["/game/black-friday-shopping-spree", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/black_friday_shopping_spree/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Black Friday shopping spree. The Black Friday sale is here. Adorable Princess gets more excited about shopping on this day. She planned to purchase new brands of prom, urban and sport wears.",
      l_dis:
        `Welcome to the Black Friday shopping spree. The Black Friday sale is here. Adorable Princess gets more excited about shopping on this day. She planned to purchase new brands of prom, urban and sport wears. But she is in confusion about which suits her perfectly. Help her and have fun!She is in a shopping mall. She needs your help. The first shop is a prom, pick a nice prom dresses for her and also find pairs of shoes, necklace and cute pairs of earrings. Then you can find an urban shop. She has a lot of collections of urban dresses in their wardrobe, but they are in old trends. So she plans to buy a new one. Then it is a sports shop where you can find sports wears. Buy cute sports outfits and pairs of shoes. on the fourth shop is a hair salon, where you can find long lengthy hairs or short ones. Help her to choose the best one for her. Last but not least you find a photoshop. Help her to take pictures of the items she purchased in the rest of the shops.Create your unique styles of outfits and costumes with an excellent collection of dresses and hairstyles. Don’t forget to post them in your comments here or share them with your friends on social media. Our free online games for girls can be made much exciting with your comments and encouraging words.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Hairdorables doll",
        "Dress up",
        "Make Up",
        "Shopping",
        "cutedressup",
        "Exclusive",
        "Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Sorority Fall Fashion - playcutegames.com",
      name: ["Sorority Fall Fashion", "sorority-fall-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/sorority-fall-fashion/250x142.png",
      path: ["/game/sorority-fall-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/sorority_fall_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Sorority fall fashion. Princess Ariel, Cinderella, and Merida are the Best friends who admired for the latest fashion trends. They planned to welcome Autumn with their fashion style.",
      l_dis:
        `Welcome to Sorority fall fashion. Princess Ariel, Cinderella, and Merida are the Best friends who admired for the latest fashion trends. They planned to welcome Autumn with their fashion style. Join and help them.Start with Ariel makeup, pick cute eye lenses, eyeshadows, lipsticks, and blushes. Then browse her wardrobe to choose a cute outfit for her. And also pick nice accessories like chains and earrings. Don’t forget to try unique hairstyles. Do the same for Merida and Cinderella. Mix and match the outfits along with accessories that suit them. Finally, group all of them and welcome the autumn with this style. Play these new cute dress up games for girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Autumn",
        "cutedressup",
        "Exclusive",,"Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "haley-tries-heatless-curls - playcutegames.com",
      name: ["Haley Tries Heatless Curls", "haley-tries-heatless-curls"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/haley-tries-heatless-curls/250x142.png",
      path: ["/game/haley-tries-heatless-curls", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/525e603b-8046-57bc-960b-fac94d195e22/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Haley Tries Heatless Curls. There is a cool beauty hack trending around on TikTok, and Instagram for perfect no-heat curls, and in case you missed it",
      l_dis:
        "Welcome to Haley Tries Heatless Curls. There is a cool beauty hack trending around on TikTok, and Instagram for perfect no-heat curls, and in case you missed it, make sure you don’t skip this brand-new cute dress up game for girls! In the Haley Tries Heatless Curl’s game, we share with you four unique ways to curl your hair overnight without using heat. Four unique techniques featured in it, make sure you discover them all! You have to help her to do Bathrobe tie curls, socks curls, straw curls, and paper towels. These are the different hairstyles you are going to learn and help Haley. Have fun! If you are looking for the best makeup games, then look no further. Playcutegames.com has a cute collection of games for makeup.",
      tag: ["Princess", "Dress up", "Make Up", "Hairstyles", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bff Witchy Transformation - playcutegames.com",
      name: ["Bff Witchy Transformation", "bff-witchy-transformation"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-witchy-transformation/250x142.png",
      path: ["/game/bff-witchy-transformation", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_witchy_transformation/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Bff Witchy Transformation. Princess Ariel, Merida, and Cinderella are discussing their weekend plan. Being a princess they seem bored. So they decided to do something different.",
      l_dis:
        `Welcome to Bff Witchy Transformation. Princess Ariel, Merida, and Cinderella are discussing their weekend plan. Being a princess they seem bored. So they decided to do something different. They planned to try some evil or witchy costumes. Join them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Decoration",
        "Manicure",
        "cutedressup",
        "Exclusive",,"Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "K pop New Years Concert - playcutegames.com",
      name: ["K pop New Years Concert", "k-pop-new-years-concert"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/k-pop-new-years-concert/250x142.png",
      path: ["/game/k-pop-new-years-concert", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/62f6892c-868c-5aac-9f3c-5d636ef1a8af/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to K-pop New Years Concert. Right after midnight, the most famous K-pop band of the moment is going to entertain the gathering with their cool songs, and these girls can’t wait to see the BTS boys performing.",
      l_dis:
        "Welcome to K-pop New Years Concert. Right after midnight, the most famous K-pop band of the moment is going to entertain the gathering with their cool songs, and these girls can’t wait to see the BTS boys performing. They’ve got the tickets, and they just need the right looks, and here is where you can help them out! Get ready to prove your stylist skills, ladies, while playing the K-pop New Years Concert dress up game for girls online! Hit play, and meet the lucky girls who are attending the BTS concert on New Year’s! Two of your favorite Disney princesses and two famous villains are willing to attend the concert. But there isn’t enough room for two powerful forces to be in the same place.  We believe that on New Year’s Eve, villains and princesses can be friends. And we imagined this cool holiday game where they welcome the New Year together. And what a better way than to bond at the concert of beloved K-pop sensation, BTS?",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        " New year Games",
        "Prinxy Games","Dressupwho"
      ]
  ,
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Hello Halloween - playcutegames.com",
      name: ["Princess Hello Halloween", "princess-hello-halloween"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-hello-halloween/250x142.png",
      path: ["/game/princess-hello-halloween", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_hello_halloween/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Play Princess Hello Halloween online! Style Elsa and Anna with face art, outfits, and decorate pumpkins in this fun, free game on playcutegames.",
      l_dis:
        `Welcome to a new Halloween game called Princess Hello Halloween. Princess Anna and Elsa planned to welcome this Halloween with something special. So they decided to apply some Halloween paintings to their face and pick up the clothes which suit their Makeup. Finally, they decided to decorate the pumpkin with beautiful colors, patterns, and Hat and facial expressions.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Decoration",
        "Halloween",
        "Elsa",
        "Anna",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "Prinxy",
     
      orientation: 0,
      title: "Ellie: New Year's Eve - playcutegames.com",
      name: ["Ellie: New Year's Eve", "ellie-new-years-eve"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-new-years-eve/250x142.png",
      path: ["/game/ellie-new-years-eve", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/b3ac9eaa-c35f-575e-95f3-48abfd801958/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In Ellie: New Year’s Eve, help Ellie prepare for a glamorous night at home! Choose the best outfits and makeup in this exciting free online game.",
      l_dis:
        "Welcome to Ellie: New Year’s Eve game. Get into the winter holidays together cute Ellie. She is in charge of entertaining you this new year’s eve and surely she has some great ideas in mind. Like many of us, she’s also spending New Year’s at home. So she came up with a busy agenda for the 31st of December. Join her in getting started with New Year’s Eve, and learn from our girl how to rock New Year’s at home, looking glamorous in stunning dresses and the right makeup look!",
      tag: [
        "Ellie",
        "Dress up",
        "Make Up",
        "New Year games",
        "Decoration",
        "Prinxy Games","Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BigMax Happy Halloween - playcutegames.com",
      name: ["BigMax Happy Halloween", "bigmax-happy-halloween"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bigmax-happy-halloween/250x142.png",
      path: ["/game/bigmax-happy-halloween", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bigmax_happy_halloween/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to BigMax Happy Halloween Party. Tomago and Hiro planned to celebrate this Halloween a more memorable one. So they decided to try some crazy outfits for them and the BigMax. They arrange a party with Halloween decorations.",
      l_dis:
        `Welcome to BigMax Happy Halloween Party. Tomago and Hiro planned to celebrate this Halloween a more memorable one. So they decided to try some crazy outfits for them and the BigMax. They arrange a party with Halloween decorations. Help them and have fun! Start with Tomago, browse some cute outfits, and pick the perfect one for her. Choose some cool hairstyles and accessories. Don’t forget to choose a perfect hat that makes the Outfit suits for Halloween. Then start with Hiro, Do the same, and make him more different than Tomago. Finally, it’s time for Bigmax. Browse a wardrobe that has a collection of outfits like Pikachu, Zombie, Honey Bee and Big pumpkin, etc,…
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Halloween",
        "Decoration",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Ready For Adventure's Date - playcutegames.com",
      name: [
        "Princess Ready For Adventure's Date",
        "princess-ready-for-adventures-date",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-ready-for-adventures-date/250x142.png",
      path: [
        "/game/princess-ready-for-adventures-date",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess_ready_for_adventures_date/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to our new game Princess Ready For Adventure’s Date. Princess Moana is so excited. Maui invited her for a date at Te fiti. She needs your help to be prepare and to impress Maui with her stunning look.",
      l_dis:
        `Welcome to our new game Princess Ready For Adventure’s Date. Princess Moana is so excited. Maui invited her for a date at Te fiti. She needs your help to be prepare and to impress Maui with her stunning look. Join and help her. Start with boat ore decoration, help Moana to decorate her ore with beautiful colors and patterns. Then help her to choose a cute outfit for her date. Pick up beautiful accessories. Maui secretly planned to impress Moana in her way. Help Maui to decorate her hook with different colors and patterns. Then choose a nice outfit and costumes. Once you did, then help them to decorate a boat which they use to go to Te fiti. Choose cute colors and designs. Help Moana and Maui from this cute game, and make this date a more memorable one for both of them.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Make Up",
        "Decoration",
        "Summer",
        "Moana",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bff Attractive Autumn Style - playcutegames.com",
      name: ["Bff Attractive Autumn Style", "bff-attractive-autumn-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-attractive-autumn-style/250x142.png",
      path: ["/game/bff-attractive-autumn-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_attractive_autumn_style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this new game Bff Attractive Autumn Style, Princess Tiana, Belle, and Moana are the Best friends who inspired more in the latest fashion trends. Now they decided to welcome the upcoming season autumn with their fashion skill.",
      l_dis:
        `In this new game Bff Attractive Autumn Style, Princess Tiana, Belle, and Moana are the Best friends who inspired more in the latest fashion trends. Now they decided to welcome the upcoming season autumn with their fashion skill. Come and join them with the latest cute dress up games for girls. Even though they are experts in fashion they need some tips to fulfill them.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Make Up",
        "Decoration",
        "Autumn",
        "Belle",
        "Tiana",
        "Moana",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Makeover Salon - playcutegames.com",
      name: ["Princess Makeover Salon", "princess-makeover-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-makeover-salon/250x142.png",
      path: ["/game/princess-makeover-salon", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_makeover_salon/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this cute game Princess Makeover Salon, Princess Anna, and Elsa seem very sad. Because of this hot summer, their face looks dirty and infected with pimples. They planned to do some makeover to bring their skin back.",
      l_dis:
        `In this cute game Princess Makeover Salon, Princess Anna, and Elsa seem very sad. Because of this hot summer, their face looks dirty and infected with pimples. They planned to do some makeover to bring their skin back. But they need some expert help. Join and help them to recover.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess", "Make Up", "cutedressup", "Exclusive","Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Besties Summer Vacation - playcutegames.com",
      name: ["Besties Summer Vacation", "besties-summer-vacation"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/besties-summer-vacation/250x142.png",
      path: ["/game/besties-summer-vacation", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/besties_summer_vacation/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this cute game Besties Summer Vacation, Princess is planning for a cute summer vacation. They decided to arrange a trip to the beach with their old van. But they need some expert help to choose a cute makeup and perfect trip outfit.",
      l_dis:
        `In this cute game Besties Summer Vacation, Princess is planning for a cute summer vacation. They decided to arrange a trip to the beach with their old van. But they need some expert help to choose a cute makeup and perfect trip outfit. And also they need some help to modify and decorate their old van. Come and join our princesses and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess", "Make Up", "Summer", "cutedressup", "Exclusive","Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Superhero Violet Summer Excursion - playcutegames.com",
      name: [
        "Superhero Violet Summer Excursion",
        "superhero-violet-summer-excursion",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/superhero-violet-summer-excursion/250x142.png",
      path: [
        "/game/superhero-violet-summer-excursion",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/superhero_violet_summer_excursion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Superhero Violet Summer Excursion is just kicking in. Violet couldn’t stop thinking about the sea and beach so she decided to take a break and head to the closest beach she knew.",
      l_dis:
        `Superhero Violet Summer Excursion is just kicking in. Violet couldn’t stop thinking about the sea and beach so she decided to take a break and head to the closest beach she knew. But before anything else, she needs to properly prepare her complexion for the long sunbathing sessions she has in mind – care to help her out? Come and join her in getting started with this amazing makeover game for girls and first of all, take good care of her tiered complexion.Use a delicate cleanser and yummy looking face masks to deep clean her face. Apply fresh cucumber slices on her eyes to help to deal with the dark circles under her eyes, and make sure to also shape her eyebrows. Next, you get to put your designer skills to the test and cover her face in a cool face painting and then check out the hairstyle options as well. Pick a wavy hairstyle for Violet’s vacation at the beach and pair it with an easy breeze beach outfit. Finally, decorate her scooter with girly-girl stickers. Have an amazing time playing this brand-new summer-themed cute game for girls.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Summer",
        "Superhero",
        "Decoration",
        "Facial",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Delightful Summer - playcutegames.com",
      name: ["Princess Delightful Summer", "princess-delightful-summer"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-delightful-summer/250x142.png",
      path: ["/game/princess-delightful-summer", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_delightful_summer/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Princess Delightful Summer has just begun. Summer brings a lot of warmth and sunshine. Princess Cinderella and Ariel want to enjoy the bright days of summer. The long days with more light and comfort are what they have been waiting all these days.",
      l_dis:
        `Princess Delightful Summer has just begun. Summer brings a lot of warmth and sunshine. Princess Cinderella and Ariel want to enjoy the bright days of summer. The long days with more light and comfort are what they have been waiting all these days. But summer is aggressive this time and the princesses must wear the right dresses and costumes. Help them to choose the right outfits for an awesome summer.Start with princess Ariel, help them to choose a cute outfit for this summer with cool accessories and hairstyles. Accessories like earrings and necklaces.Do the same for princess Cinderella and make sure you try unique styles for each princess. The fun doesn’t end here, they planned to spend this summer on a vacation at the seaside.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Summer",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFF In Fairy Style - playcutegames.com",
      name: ["BFF In Fairy Style", "bff-in-fairy-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-in-fairy-style/250x142.png",
      path: ["/game/bff-in-fairy-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_in_fairy_style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this cute BFF In Fairy Style game, Princess Ariel, Cinderella, and Merida are the Bffst who likely to participate in a fairy cosplay contest. They are so excited about the contest.",
      l_dis:
        `In this cute BFF In Fairy Style game, Princess Ariel, Cinderella, and Merida are the Bffst who likely to participate in a fairy cosplay contest. They are so excited about the contest.But they are in a little bit of confusion to choose perfect outfits. So help the princess with the right outfit.First, start with Ariel by trying some cool collection of fairy dresses, and new hairstyles. And help them with a few fairy costumes. Followed by Ariel other girls Cinderella and Merida are waiting in queue for your help.Help them with your magical skill, And finally, don’t forget to add magical wings to these beautiful princesses which make them a real fairy in look.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Aesthetics",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Tulip Back To Home - playcutegames.com",
      name: ["Tulip Back To Home", "tulip-back-to-home"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tulip-back-to-home/250x142.png",
      path: ["/game/tulip-back-to-home", "https://playcutegames.com"],
      iframe: " https://games.cutedressup.net/tulip_back_to_home/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Tulip has had a great time at the factory with storks. Now it’s time for Tulip to get back to her home. With a lot of excitement and enthusiasm, Tulip is preparing herself to start her new journey.",
      l_dis:
        `Tulip has had a great time at the factory with storks. Now it’s time for Tulip to get back to her home. With a lot of excitement and enthusiasm, Tulip is preparing herself to start her new journey. Tulip wants to pack a collection of cool outfits for her home. Casual, Pastel, and denim are her all-time favorite. Help tulip to try different outfits and gather the best of her dresses for a happily ever after the journey.Before starting the journey she wants to try some clothes in a different style. She has more collection in each style. So she confused to choose a perfect one. Help her with your fashion skill.Start with casual and followed by choosing a cute collection in pastel and denim. Don’t forget to try some cool hairstyles and modern accessories.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Makeover Fashion Blog - playcutegames.com",
      name: ["Princess Makeover Fashion Blog", "princess-makeover-fashion-blog"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-makeover-fashion-blog/250x142.png",
      path: [
        "/game/princess-makeover-fashion-blog",
        "https://playcutegames.com",
      ],
      iframe: " https://games.cutedressup.net/princess_makeover_fashion_blog/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this new cute game Princess Makeover Fashion Blog, Princess Anna, and Elsa are decided to create a fashion blog. So they planned to make a photoshoot for their blog.",
      l_dis:
        `In this new cute game Princess Makeover Fashion Blog, Princess Anna, and Elsa are decided to create a fashion blog. So they planned to make a photoshoot for their blog.Face decor with photoshoot can never get more fascinating than this! They planned to try some cute face art with different lip colors. And also they decided to try different hairstyles. Princess confused about choosing a nice variety of butterflies, animals, and superhero face art. They need some expert advice to make a perfect blog.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Cheongsam Shanghai Fashion - playcutegames.com",
      name: [
        "Princess Cheongsam Shanghai Fashion",
        "princess-cheongsam-shanghai-fashion",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-cheongsam-shanghai-fashion/250x142.png",
      path: [
        "/game/princess-cheongsam-shanghai-fashion",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.cutedressup.net/princess_cheongsam_shanghai_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this new cute game Princess Cheongsam Shanghai Fashion, Princesses want to play and enjoy their time, making cute dress up styles. Being BFFs forever, they have tried all possible outfits.",
      l_dis:
        `In this new cute game Princess Cheongsam Shanghai Fashion, Princesses want to play and enjoy their time, making cute dress up styles. Being BFFs forever, they have tried all possible outfits. Curiosity helps them to think past the current century. As they grace through history, they decide to adopt Cheongsam Shanghai Fashion in Princess style. Anna and Elsa finally land upon interesting dress-up costumes in Qing-style tops, modern Qipao with high-slit cuts & mini dress. Help them choose the right color, dresses, and present them in majestic imperial fashion.Try some cool Cheongsam dresses and accessories for each princess. Don’t forget to checkout unique hairstyles for both princesses. They are so excited to try old-fashioned dresses. So don’t make them disappointed try something unique. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Superhero Violet Fashion Shoot - playcutegames.com",
      name: ["Superhero Violet Fashion Shoot", "superhero-violet-fashion-shoot"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/superhero-violet-fashion-shoot/250x142.png",
      path: [
        "/game/superhero-violet-fashion-shoot",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/superhero_violet_fashion_shoot/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this cute game Superhero Violet Fashion Shoot, Violet is an incredible teenage girl who is normally very shy and stays away from her peer group of girls.",
      l_dis:
        `In this cute game Superhero Violet Fashion Shoot, Violet is an incredible teenage girl who is normally very shy and stays away from her peer group of girls.So violet decides to surprise her best friends, parents, and boyfriend by putting up an exquisite photoshoot. It’s time to display an adorable fashion parade.Violet wants to share her superhero outfits and princess dress-up with her parents but keep them a secret !! Shhhh!Violet reserves the casual look and the prom makeover for her special friend. Saving the best for last, Violet wants to display extraordinary fashion in anime & social media trends and get a lot of followers.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Superhero",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Baby Boss Photo shoot - playcutegames.com",
      name: ["Baby Boss Photo shoot", "baby-boss-photo-shoot"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/baby-boss-photo-shoot/250x142.png",
      path: ["/game/baby-boss-photo-shoot", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/baby_boss_photo_shoot/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this cute Baby Boss Photo Shoot game, Boss Baby is thrilled about his photoshoot for upcoming candidacy. He wants to make a show by wearing some cool outfits. Beach baby or skater baby? ",
      l_dis:
        `In this cute Baby Boss Photo Shoot game, Boss Baby is thrilled about his photoshoot for upcoming candidacy. He wants to make a show by wearing some cool outfits. Beach baby or skater baby? Casual baby or a pro-style office baby? Hip-hop baby or a mini superhero? Boss is overwhelmed with choice. Help the baby boss choose and try different dress up and makeover outfits to enjoy delivering a stunning appearance.He needs your help because the little baby does not know entirely how to dress and how to match his clothes. So please guide him with your fashion skill. The game will be very fun and rather easy because dear kids you will just have to use your mouse for anything you want to do.Make sure that in the shortest time, you will manage to match all of his outfits, and even find the Batman and the Spy costumes. Boss Baby is also going to need a chic baby costume, a baby skateboarder costume, and a cool rock costume. That you can pull off using all the clothes that he has prepared in this new challenge.Create your unique styles for the Baby boss in fashion with an excellent collection of dresses and hairstyles. Don’t forget to post them in your comments here or share them with your friends on social media. Our free online games for girls can be made much exciting with your comments and encouraging words
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Boss Baby", "Dress up", "Fashion", "cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bff Princess Back To School - playcutegames.com",
      name: ["Bff Princess Back To School", "bff-princess-back-to-school"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-princess-back-to-school/250x142.png",
      path: ["/game/bff-princess-back-to-school", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_princess_back_to_school/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "It’s time for the BFF Princess to go back to school.A new academic year is about to begin!Can you help these Princess get ready for school? Each one wants to create the perfect look for their first day at school.",
      l_dis:
        `It’s time for the BFF Princess to go back to school.A new academic year is about to begin!Can you help these Princess get ready for school? Each one wants to create the perfect look for their first day at school. There are lots of hairstyles, outfits, and accessories for them to choose from, and they’re really in a hurry! They just can’t decide which ones to pick, so they’ll need your help!Being a fashion expert, you have to help each princess with different styles of dresses and costumes. Their wardrobe consists of casual, modern, and uniform outfits. Select the best one for their first-day school. Try some unique hairstyles for each princess. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "School",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess In Colourful Wonderland - playcutegames.com",
      name: [
        "Princess In Colourful Wonderland",
        "princess-in-colourful-wonderland",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-in-colourful-wonderland/250x142.png",
      path: [
        "/game/princess-in-colourful-wonderland",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess_in_colourful_wonderland/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In Princess in Colourful Wonderland game, Princesses grant wishes, make fairy play and enjoy themselves. Quickly they realize it’s all in their dreams.",
      l_dis:
        `In Princess in Colourful Wonderland game, Princesses grant wishes, make fairy play and enjoy themselves. Quickly they realize it’s all in their dreams.Princesses now want to make their dream a reality. The princesses are planning to wear some cool fairy outfits, bands, and crowns with a cute collection of fairy feathers. They want to make it happen in reality and grant their fairy wishes to each other. Enjoy this fun-filled experience on wonderland with our fairies! So help the princess have maximum fun!Start with Ariel, Choose the best dresses in their fairy wardrobes and also help him to choose accessories and magical wings. Then help cinderella with different dresses and costumes. Make sure to try with different unique hairstyles for both of them.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFF Candy Fever - playcutegames.com",
      name: ["BFF Candy Fever", "bff-candy-fever"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-candy-fever/250x142.png",
      path: ["/game/bff-candy-fever", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_candy_fever/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Princesses are now in BFF Candy fever mood. Princess Ariel, Merida, and Cinderella are BFFs forever. They want to enjoy a cute collection of tasty candy costumes.",
      l_dis:
        `Princesses are now in BFF Candy fever mood. Princess Ariel, Merida, and Cinderella are BFFs forever. They want to enjoy a cute collection of tasty candy costumes. Watch the Princesses travel to a Candy wonderland and experience a variety of candy makeovers and candy cream hair.  Decorate each princess with cute candy styles and help them to enjoy this candy saga.Start with Princess Ariel, try with candy costume like a lollipop and other sweet candies. Add a unique hairstyle for Princess Ariel. To add more fun, try hairstyles with candy style. Do the same for princess Cinderella and Merida. They are also so much excited and waiting for your help. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Pastel Fashion - playcutegames.com",
      name: ["Princess Pastel Fashion", "princess-pastel-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-pastel-fashion/250x142.png",
      path: ["/game/princess-pastel-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_pastel_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Princess Pastel Fashion is totally on in this season! That’s why these stylish princess Anna and Elsa have decided to design some awesome outfits that feature tons of pastel colors.",
      l_dis:
        `Princess Pastel Fashion is totally on in this season! That’s why these stylish princess Anna and Elsa have decided to design some awesome outfits that feature tons of pastel colors. They have already decorated their wardrobe with a set of cute pastel dresses. Help the princesses to choose the best.Help them to choose the cute dresses collection from pastel. Try some cool accessories and unique hairstyles for each one of them. They are so excited about this fashion contest. So show your fashion skill and make them happy. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFF St. Patricks Day Preparation - playcutegames.com",
      name: [
        "BFF St. Patricks Day Preparation",
        "bff-st-patricks-day-preparation",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-st-patricks-day-preparation/250x142.png",
      path: [
        "/game/bff-st-patricks-day-preparation",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/bff_st_patricks_day_preparation/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "BFF St Patricks Day Preparation styles are the best. The cute princesses are getting ready for Patrick’s day celebration.",
      l_dis:
        `BFF St Patricks Day Preparation styles are the best. The cute princesses are getting ready for Patrick’s day celebration. So help the princesses to prepare for the carnival by choosing the best matching outfits, hairstyles, and accessories.A very special and very interesting new dress up the challenge is appearing today right here. you will manage to have a great time helping three Disney princesses getting ready for St Patrick’s day.You will have to be very creative because dear kids you will see that Ariel, Anna, and Rapunzel are going to be the main characters. You will manage to help the three girls have cute St Patrick’s outfits to wear.Each of the Disney princesses will have different types of styles. Help the princess to be ready for St Patrick’s day. In this new challenge, you will have to be sure that the Disney princesses will be ready to wear cute hats, cute dresses. And new makeup and new accessories that the princesses can wear and match with their own style.The official color of the St Patrick’s day is green. So you have to help all princesses to wear the cutest and the most beautiful outfits for St Patrick’s day.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Stripes Vs Dots - playcutegames.com",
      name: ["Princess Stripes Vs Dots", "princess-stripe-vs-dots"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-stripes-vs-dots/250x142.png",
      path: ["/game/princess-stripe-vs-dots", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_stripe_vs_dots/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Princess Stripes vs Dots Competition is about to begin and make things more exciting. The two little princesses of Cutedressup simply adore the modern world and fashion.",
      l_dis:
        `Princess Stripes vs Dots Competition is about to begin and make things more exciting. The two little princesses of Cutedressup simply adore the modern world and fashion. They are trendsetters and best friends. But sometimes their taste in fashion is not the same. They need some experts to advise them to choose stripes or dots which one is best.Now they could use your expert helping hand to learn how to properly accessorize their new additions. Care to help them out? Come and join the little trendsetters getting started with this brand new cute dress up game for girls called Princess Stripes Vs Dots and see what playful, summery looks you can put together for them.First, you can dress each of them in a striped outfit, and then you can have them try on a polka-dotted combo as well. Next, make sure to accessorize each outfit that you choose with a new hairstyle, cute earrings, and matching necklaces.Have them try on all your favorite clothing pieces and accessories before deciding which fashion style best suits each girl.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Spring Fashion - playcutegames.com",
      name: ["Princess Spring Fashion", "princess-spring-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-spring-fashion/250x142.png",
      path: ["/game/princess-spring-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_spring_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Princess Spring Fashion is about to begin! Get ready for the most awaited season to spend with the two cute sisters who are absolutely mad about this season!.",
      l_dis:
        `Princess Spring Fashion is about to begin! Get ready for the most awaited season to spend with the two cute sisters who are absolutely mad about this season!. They have already renewed their spring wardrobe with cherry blossom collections. You should really check out their outfits and accessories for spring. Make sure to pick something chic and pretty. Have fun!.Help them to choose the cute floral dress collection from their wardrobe. Try some cool accessories and unique hairstyles for each one of them. They are so excited about this fashion contest. So show your fashion skill and make them happy. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Spring",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Trendy T Shirt - playcutegames.com",
      name: ["Princess Trendy T Shirt", "princess-trendy-t-shirt"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-trendy-t-shirt/250x142.png",
      path: ["/game/princess-trendy-t-shirt", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_trendy_tshirt/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Princess Trendy T-Shirt season is on. Princess Anna and Elsa are planning to challenge each other with different styles.",
      l_dis:
        `Princess Trendy T-Shirt season is on. Princess Anna and Elsa are planning to challenge each other with different styles. Dresses are available in plenty, the princesses are now in a state of confusion in choosing the best outfits to win over other princesses. They need some expert help. So please help the princess to choose the best t-shirt and give each one a perfect look with fashionable t-shirts.Start with princess Elsa and followed by Princess Anna. Try from the collections like stripes, Dots, pattern, Casual, retro, and pastel styles of t-shirts. Don’t forget to try some unique hairstyles and accessories. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive",
        "Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Winter Style - playcutegames.com",
      name: ["Princess Winter Style", "princess-winter-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-winter-style/250x142.png",
      path: ["/game/princess-winter-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_winter_style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Princess Winter Style has just begun. Princesses want to get out in the winter but are in dilemma in choosing the best outfit. Help them get cute with warm outfits and enjoy",
      l_dis:
        `Princess Winter Style has just begun. Princesses want to get out in the winter but are in dilemma in choosing the best outfit. Help them get cute with warm outfits and enjoy spending time with them in this cute new dress up game!At first, help the princess Anna to choose the best winter outfits, chain, earing, and stunning hairstyles. And then its time for Princess Elsa, to do the same. Help Elsa to choose perfect outfits, accessories, and hairstyles.Once you did helping both princesses, we have to decorate their new snowman. They decided to make it funnier, so let’s try some hats, scarves, bow tie and glove. Help them out and bring out the best in you.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Winter",
        "Decoration",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Valentine Preparation - playcutegames.com",
      name: ["Princess Valentine Preparation", "princess-valentine-preparation"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-valentine-preparation/250x142.png",
      path: [
        "/game/princess-valentine-preparation",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess_valentine_preparations",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "With valentine’s day around the corner, the Princess Valentine preparation has become exciting and thrilling. To celebrate Valentine’s day, the princess has decided to be in their best!",
      l_dis:
        `With valentine’s day around the corner, the Princess Valentine preparation has become exciting and thrilling. To celebrate Valentine’s day, the princess has decided to be in their best! Let’s check out their closet and look at our options. They have a few dresses in their colors of choice, both long and short, sometimes it’s more fun to mix it up a bit! You have the additional task of decorating their card and make them geared up for the royal date.Let’s start with Princess Elsa, try some cool date outfits and costumes. And then help Princess Anna to choose her outfits and accessories. Don’t forget to try some unique hairstyles for both of them.Once you help with dress-up part, then create unique valentine cards for both Princess. Decorate the card with cute emoji’s and cute valentine quotes.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Valentine",
        "Decoration",
        "cutedressup",
        "Exclusive",
        "Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    
  ];
